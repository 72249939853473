import moment from "moment";

export const ORIGIN_FIELD = 'Base.FromField'

export const TO_FIELD = 'Base.DestinationLocation'

export const XCODE_FIELD = 'Accommodation.XCode'

export const XCITY_FIELD = 'Base.XCity'

export const DATE_RANGE_FIELD = 'Base.DateRange'

export const BOARD_FIELD = 'Accommodation.XService'

export const RATING_FIELD = 'Accommodation.Category'

export const PRICE_FIELD = 'Base.Price'

export const PARTICIPANTS_FIELD = 'Base.Participants'

export const DURATION_FIELD = 'Base.Duration'

export const TRANSPORT_FIELD = 'Base.ComponentsCombinations'

export const Search_Form_Fields = [
    ORIGIN_FIELD,
    TO_FIELD,
    DATE_RANGE_FIELD,
    PARTICIPANTS_FIELD,
    DURATION_FIELD,
    XCITY_FIELD,
    XCODE_FIELD
]

export const Search_Filters_Fields = [
    BOARD_FIELD,
    RATING_FIELD,
    PRICE_FIELD,
    TRANSPORT_FIELD
]

export const PARTICIPANTS_DEFAULT = {
    adults: {value: 2, dates: undefined},
    kids: {value: 0, dates: []},
}

export const DURATION_DEFAULT = {
    min: 7,
    max: 14
}

const CUSTOM_DURATION = localStorage.getItem('LocalDuration')

const CUSTOM_PARTICIPANTS = localStorage.getItem('LocalParticipants')

JSON.parse(CUSTOM_PARTICIPANTS as any)?.kids?.dates.map((date: string, index: number) => {
    sessionStorage.setItem(`Pp.Kids.${index}.Date`, date)
})

export const Default_Search_Form_Values = {
    'Base.Duration': CUSTOM_DURATION ? JSON.parse(CUSTOM_DURATION as any) : DURATION_DEFAULT,
    'Base.Participants': CUSTOM_PARTICIPANTS ? JSON.parse(CUSTOM_PARTICIPANTS as any) : PARTICIPANTS_DEFAULT,
    'Base.FromField': [],
    'Base.DestinationLocation': [],
    'Base.DateRange': {
        startDate: moment().format('DD.MM.YYYY'),
        endDate: moment().add(2, 'week').format('DD.MM.YYYY')
    }
}