// @ts-ignore
import styled, {keyframes} from "styled-components";
import Text from "../../lib/UIBookBrd/Text";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const UserAccountTap = styled.div`
  user-select: none;
  cursor: pointer;
  margin: auto 0 auto 0;
  align-items: center;
  display: flex;
`;
const CustomText = styled.div`
  transition: 200ms;
  margin-right: 5px;
  font-size: 15px;
  color: ${(props: { theme: any; }) => props.theme.color};
`;

const Image__Holder = styled(FlexBox)`
  width: 17.5px;
  overflow: hidden;
`

const Image = styled.img`
  width: 35px;
  background-color: ${({theme}) => theme.backgroundColor};
  box-shadow: 0px 0px 5px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  height: 35px;
  transition: 500ms;
  border-radius: 50%;
`

const TopLayer = styled.div`
  transition: 500ms;
  display: flex;
  align-items: center;

  &:hover ${CustomText} {

  }

`
const DropDown = styled.div`
  transform-origin: top center;
  align-content: inherit;
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  align-content: inherit;
  min-width: 150px;
  max-width: 200px;
`;
const Texts = styled(Text)`
  cursor: pointer;
  font-size: 15px;
  padding: 4px;
  user-select: none;
  border-bottom: 1px solid lightgray;


  &:hover {

    background-color: ${(props: { theme: any; }) => props.theme.optionHoverColor};;
  }


  &:last-of-type {
    border-bottom: none;
  }
`

const UserPhoto = styled.div`
  transition: 500ms;
  margin: auto 0;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  border: solid 1px ${(props: { theme: any; }) => props.theme.borderColor};;
  border-radius: 25px;
  overflow: hidden;
  align-items: center;
  display: flex;
`;
const User__language__Holder = styled(FlexBox)`
  height: 35px;
  width: 35px;
  overflow: hidden;
    //box-shadow: 0px 0px 5px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  box-sizing: border-box;
  flex-wrap: nowrap;
  border-radius: 50%;
`
const User__Image__Holder = styled(FlexBox)`
  width: 17.5px;
  height: 35px;
  margin-top: -3px;
  rotate: 25deg;
`
const Language__Image__Holder = styled(FlexBox)`
  width: 17.5px;
  height: 35px;
  overflow: hidden;
  margin-top: 4px;
  rotate: 25deg;
`
const Language__Image = styled.img`
  width: 35px;
  height: 35px;
  margin-left: -17.5px;
  rotate: -25deg;
`
const Icon = styled(FontAwesomeIcon)`
  height: 14px;
  width: 14px;
  margin-right: 4px;
`
const No__Image__User = styled(FlexBox)<{ backgroundColor?: string }>`
  border-radius: 50%;
  background-color: ${({backgroundColor, theme}) => backgroundColor ?? theme.brdExclusiveColor};
  box-shadow: 0px 0px 5px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  margin: auto 0;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
`
const Not__Logged__User__Label = styled(Text)`
  margin: auto auto auto 0;
  font-size: 16px;
  font-weight: bold;
  font-family: HandWritenBrdStyle;
`
const Not__User__Icon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
  margin: auto;
  color: #fdfdfd
`
const No__Image__User__Label = styled(Text)`
  color: #fdfdfd;
  font-size: 18px;
  margin: auto;
  font-weight: bold;
`

export const Styled = {
    No__Image__User__Label,
    Not__User__Icon,
    Not__Logged__User__Label,
    No__Image__User,
    UserAccountTap,
    CustomText,
    TopLayer,
    DropDown,
    Texts,
    UserPhoto,
    Image,
    Image__Holder,
    User__language__Holder,
    User__Image__Holder,
    Language__Image__Holder,
    Language__Image,
    Icon
}