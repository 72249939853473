import {Styled} from "./CreateAccount.styles"
import {useTranslation} from "react-i18next";
import useNavigation from "../../../lib/UseHooksBookBrd/useNavigation";
import {CREATE_ACCOUNT} from "../../../lib/Routes";

const CreateAccount = () => {

    const {t} = useTranslation()
    const navigate = useNavigation()

    const onJoinClick = () => navigate(CREATE_ACCOUNT)


    return (
        <Styled.Create__Account__Holder>
            <Styled.Main__Title>
                {t('lbl_join_brd_exclusive')}
            </Styled.Main__Title>
            <Styled.Secondary__Title>
                {t('lbl_join_brd_exclusive_second')}
            </Styled.Secondary__Title>
            <Styled.Join__Community__Button
                onClick={onJoinClick}
                label={t('lbl_join_inner_circle')}
            />
        </Styled.Create__Account__Holder>
    )
}

export default CreateAccount