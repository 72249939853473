import {useTranslation} from "react-i18next";
import {useRef, useState} from "react";
import {Styled} from "../SearchTripLowerPart.styles";
import RecommendedCountryBox from "./RecommendedCountryBox";
import {includes} from "lodash-es";
import {isMobileOnly} from "react-device-detect";
import {defaultSliderState, SwiperContainer, SwiperSlide} from "../../../../lib/UIBookBrd/Swiper";
import {NextButton, PrevButton} from "../../../../lib/UIBookBrd/Swiper/Buttons";

type RecommendedCountryProps = {
    list: any[]
}

const RecommendedCountry = (
    {
        list
    }: RecommendedCountryProps
) => {

    const {t} = useTranslation();

    const [sliderState, setSliderState] = useState(defaultSliderState);

    const swiperRef = useRef<any>(null)

    const onPrevButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slidePrev();
    }

    const onNextButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slideNext();
    }

    const onActiveIndexChange = (swiper: any) => {
        setSliderState((state: any) => ({
            ...state,
            progress: swiper.progress,
            index: swiper?.activeIndex,
        }));
    };

    const WideScreenContent = (
        <Styled.Wide__Screen__Content__Holder>
            {list?.map((option: any, index: number) => {

                if (includes([0, 1], index)) {
                    return (
                        <Styled.Big__Country__Offer__Holder
                            key={`recomended_country_${option.label}`}
                        >
                            <RecommendedCountryBox
                                item={option}
                            />
                        </Styled.Big__Country__Offer__Holder>
                    )
                }

                return (
                    <Styled.Regular__Country__Offer__Holder
                        key={`recomended_country_${option.label}`}
                    >
                        <RecommendedCountryBox
                            item={option}
                        />
                    </Styled.Regular__Country__Offer__Holder>
                )
            })}
        </Styled.Wide__Screen__Content__Holder>
    )

    const SmallScreenContent = (
        <Styled.Recommended__Countries__Holder>
            {sliderState.index !== 0 && (
                <PrevButton
                    onClick={onPrevButtonClick}
                />
            )}
            <SwiperContainer
                onActiveIndexChange={onActiveIndexChange}
                ref={swiperRef}
            >
                {list?.map((option: any, index: number) => {
                    return (
                        <SwiperSlide
                            key={`recomended_country_${option.label}`}
                            style={{
                                maxWidth: isMobileOnly ? 'calc(100% - 2px)' : '300px',
                                height: isMobileOnly ? '50vw' : '190px',
                                margin: isMobileOnly || index === 0 ? '0' : '10px',
                                padding: '1px',
                            }}
                        >
                            <RecommendedCountryBox
                                item={option}
                            />
                        </SwiperSlide>
                    )
                })}
            </SwiperContainer>
            {sliderState.progress < 1 && (
                <NextButton
                    onClick={onNextButtonClick}
                />
            )}
        </Styled.Recommended__Countries__Holder>
    )

    return (
        <>
            <Styled.Main__Label>
                {t('lbl_best_countries')}
            </Styled.Main__Label>
            {WideScreenContent}
            {SmallScreenContent}
        </>
    )
}
export default RecommendedCountry