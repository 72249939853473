import {Styled} from "../SearchTripLowerPart.styles"
import usePricesHandler from "../../../../lib/UseHooksBookBrd/usePricesHandler";
import {useTranslation} from "react-i18next";
import {isUndefined} from "lodash-es";
import {recommendedOfferType} from "../../../../lib/Types";


type RecommendedOfferTypeBoxProps = {
    value: recommendedOfferType
}

const RecommendedOfferTypeBox = (
    {
        value
    }: RecommendedOfferTypeBoxProps
) => {

    const {getCurrentPrice} = usePricesHandler()

    const {t} = useTranslation()

    const {
        price,
        image,
        label,
    } = value

    const {
        amount,
        currency
    } = getCurrentPrice(Number(price?.amount), !isUndefined(price) ? price.currency : "PLN")

    return (
        <Styled.Recommended__Offer__Box>
            <Styled.Recommended__Offer__Type__Image
                src={image ?? ''}
            />
            <Styled.Recommended__Offer__Type__Label>
                {label}
            </Styled.Recommended__Offer__Type__Label>
            {value?.price && (
                <Styled.Recommended__Offer__Type__Price>
                    {t('price_starting_from')}
                    {': '}
                    {amount}
                    {' '}
                    {currency}
                </Styled.Recommended__Offer__Type__Price>
            )}
        </Styled.Recommended__Offer__Box>
    )
}

export default RecommendedOfferTypeBox