import styled from "styled-components";
import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../lib/UIBookBrd/Text";

const OfferDetailsHolder = styled(FlexBox)`
  flex-direction: column;
  padding: 0 10px;
  width: calc(100% - 20px);
  height: 100%;
`
const Section__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
`
const Section__Title__Label = styled(Text)`
  font-size: 22px;
  padding: 10px 0;
  font-weight: bold;
`

export const Styled = {
    Section__Holder,
    OfferDetailsHolder,
    Section__Title__Label
}