import {Styled} from "../Messages.styles"
import {tylerMessagesDirectionTypes} from "../../../../../lib/Types";
import useTylerBot from "../../../Handlers/Hook";

type TextMessageProps = {
    data: any
    type: tylerMessagesDirectionTypes
    value: string
}

const TextMessage = (
    {
        value,
        data,
        type
    }: TextMessageProps
) => {

    const {isNerdMode} = useTylerBot()

    const {LeisureCollected, SearchFormFields} = data ?? {}


    return (
        <Styled.Text__Message__Holder
            type={type}
        >
            <Styled.Text__Message__Text
                type={type}
            >
                {isNerdMode && type === tylerMessagesDirectionTypes.from && (
                    <Styled.Nerd__Mode__Message__Section__Label>
                        Message:{' '}
                    </Styled.Nerd__Mode__Message__Section__Label>
                )}
                {value.split('<BREAK>')?.map((textPart: any) => (
                    <>
                        {textPart}
                        <br/>
                    </>
                ))}
                {isNerdMode && type === tylerMessagesDirectionTypes.from && (
                    <>
                        <Styled.Nerd__Mode__Message__Section__Label>
                            Request Data:{' '}
                        </Styled.Nerd__Mode__Message__Section__Label>
                        <Styled.Section__Holder>
                            <Styled.Request__Data__Section__Name>
                                SearchFormFields: {'{'}
                            </Styled.Request__Data__Section__Name>
                            {Object.keys(SearchFormFields ?? {}).map((optionName: any) => {

                                return (
                                    <Styled.Request__Data__Section__Value>
                                        {optionName}{':  '}{JSON.stringify(SearchFormFields[optionName])}
                                    </Styled.Request__Data__Section__Value>
                                )
                            })}
                            <Styled.Request__Data__Section__Name>
                                {'}'}
                            </Styled.Request__Data__Section__Name>
                        </Styled.Section__Holder>
                        <Styled.Section__Holder>
                            <Styled.Request__Data__Section__Name>
                                LeisureCollected: {'{'}
                            </Styled.Request__Data__Section__Name>
                            {Object.keys(LeisureCollected ?? {}).map((optionName: any) => {

                                return (
                                    <Styled.Request__Data__Section__Value>
                                        {optionName}{':  '}{JSON.stringify(LeisureCollected[optionName])}
                                    </Styled.Request__Data__Section__Value>
                                )
                            })}
                            <Styled.Request__Data__Section__Name>
                                {'}'}
                            </Styled.Request__Data__Section__Name>
                        </Styled.Section__Holder>
                    </>
                )}
            </Styled.Text__Message__Text>
        </Styled.Text__Message__Holder>
    )
}
export default TextMessage