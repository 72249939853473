import {Styled} from "../../TripOffer.styles";
import {isEmpty} from "lodash-es";
import {faCameraSlash} from "@fortawesome/pro-light-svg-icons";
import {SwiperContainer, SwiperSlide} from "../../../../../../../../lib/UIBookBrd/Swiper";
import React, {useEffect, useRef, useState} from "react";
import NavigationButton, {buttonType} from "../../../../../../../../Components/Offers/Parts/Images/NavigationButtons";

type ImageAndTitleProps = {
    isLoadingImages?: boolean
    openFullImages: () => void
    selectedImage: number
    navigateImagesHandler: (type: 'previous' | 'next') => void
    pictures: any
    isOfferFocused: boolean
}

const defaultSliderState = {
    progress: 0,
    index: 0,
}

const Image = (
    {
        isLoadingImages,
        openFullImages,
        selectedImage,
        navigateImagesHandler,
        pictures,
        isOfferFocused,
    }: ImageAndTitleProps) => {

    const [sliderState, setSliderState] = useState(defaultSliderState);

    const swiperRef = useRef<any>(null)

    const onPrevButtonClick = (event: any) => {
        navigateImagesHandler('previous')
        event.stopPropagation()
        swiperRef.current.swiper.slidePrev();
    }

    const onNextButtonClick = (event: any) => {
        navigateImagesHandler('next')
        event.stopPropagation()
        swiperRef.current.swiper.slideNext();
    }

    const onActiveIndexChange = (swiper: any) => {
        setSliderState((state: any) => ({
            ...state,
            progress: swiper.progress,
            index: swiper?.activeIndex,
        }));
    };

    useEffect(() => {
        if (selectedImage !== 0 && selectedImage !== undefined) {
            swiperRef.current.swiper.slideTo(selectedImage, 0)
        }
    }, [])

    return (
        <>
            <Styled.TitleAndImage>
                <Styled.ObjectPhoto>
                    {isEmpty(pictures) ? (
                        <Styled.CameraIcon
                            icon={faCameraSlash}
                        />
                    ) : (
                        <Styled.Images__Holder
                            onClick={isOfferFocused ? openFullImages : () => false}
                        >
                            <NavigationButton
                                showButton={sliderState.index !== 0 && isOfferFocused && pictures?.length > 1}
                                type={buttonType.prev}
                                onClick={onPrevButtonClick}
                            />
                            <SwiperContainer
                                onActiveIndexChange={onActiveIndexChange}
                                ref={swiperRef}
                            >
                                {pictures.map((image: any, index: number) => (
                                    <SwiperSlide
                                        key={image}
                                        style={{
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <Styled.Custom__Image
                                            forceLoader={isLoadingImages}
                                            src={image}
                                        />
                                    </SwiperSlide>
                                ))}
                            </SwiperContainer>
                            <NavigationButton
                                showButton={sliderState.progress < 1 && isOfferFocused && pictures?.length > 1}
                                type={buttonType.next}
                                onClick={onNextButtonClick}
                            />

                        </Styled.Images__Holder>
                    )}
                </Styled.ObjectPhoto>
            </Styled.TitleAndImage>
        </>
    )
}
export default Image