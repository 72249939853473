import React, {forwardRef} from "react";
import {Styled} from "./Settings.styles";
import {useTranslation} from "react-i18next";
import SettingsFields from "./SettingsFields";
import {isMobileOnly} from "react-device-detect";
import Select from "../../lib/UIBookBrd/SelectBookBrd/Select";
import Drawer from "../../lib/UIBookBrd/Drawer";
import SettingsOptions from "../../lib/PageConfig";
import Switch from "../../lib/UIBookBrd/Switch";
import SettingsSection from "./SettingsSection";
import {
    faCalendarDays,
    faEuro,
    faFaceSunglasses,
    faLanguage,
    faLightbulb,
    faLightbulbOn,
    faPlaneDeparture,
    faRectangleAd,
    faRouter,
    faUsers,
    faWifiFair
} from "@fortawesome/pro-solid-svg-icons";
import ParticipantsPicker from "../ParticipantsPicker";
import useConfigureSite from "../../lib/UseHooksBookBrd/useConfigureSite";
import DurationPicker from "../DurationPicker";


type SettingsProps = {
    isSelectOpen: any
    setIsSelectOpen: any
    onCloseSettings: () => void
    ref: any
    showSettings: any
}


const Settings = forwardRef<HTMLDivElement, SettingsProps>((
    {
        isSelectOpen,
        setIsSelectOpen,
        onCloseSettings,
        showSettings,

    }, ref) => {

    const {
        Currency_Options,
        Language_Options,
        Preferred_Country_Options,
        Preferred_Continents_Options
    } = SettingsOptions()

    const {
        onLocalParticipantsChange,
        onLocalDurationChangeHandler,
        onAdvertisemenetChangeHandler,
        onLanguageChangeHandler,
        onAutomaticLowDataModeChangeHandler,
        onLowDataModeChangeHandler,
        onThemeChangeHandler,
        onCurrencyChangeHandler,
        onTylerToggleChangeHandler,
        onPreferredCountrySelectChangeHandler,
        localParticipants,
        localSettings,
        localDuration
    } = useConfigureSite()

    const {t} = useTranslation();


    const getValueForSelect = (newValue: any, list: any[]) => {
        return list.filter((item: any) => item.value === newValue)
    }

    return (
        <>
            <Drawer
                onClose={isSelectOpen ? undefined : () => {
                    onCloseSettings()
                }}
                ref={ref}
                title={t('lbl_settings')}
                isOpen={showSettings}
                position={"right"}
            >
                <Styled.SettingsHolder
                    isMobileOnly={isMobileOnly}
                >
                    <SettingsFields
                        label={'view_settings'}
                    >
                        <SettingsSection
                            sectionIcon={!!localSettings?.isLightTheme ? faLightbulbOn : faLightbulb}
                            sectionLabel={`${t('lbl_switch_theme')} ${t(`${!!localSettings?.isLightTheme ? 'lbl_dark_mode' : 'lbl_light_mode'}`)}`}
                        >
                            <Switch
                                isChecked={!localSettings?.isLightTheme}
                                setIsChecked={onThemeChangeHandler}
                            />
                        </SettingsSection>
                        <SettingsSection
                            sectionIcon={faLanguage}
                            sectionLabel={t('lbl_change_language')}
                        >
                            <Styled.Settings__Select__Holder>
                                <Select
                                    appendTo={document.querySelector('.drawer-root')}
                                    title={t('lbl_language')}
                                    isClearAble={false}
                                    isMulti={false}
                                    setAppliedList={onLanguageChangeHandler}
                                    appliedList={getValueForSelect(localSettings?.SelectedLanguage, Language_Options)}
                                    setIsSelectOpen={setIsSelectOpen}
                                    options={Language_Options}
                                />
                            </Styled.Settings__Select__Holder>
                        </SettingsSection>
                        <SettingsSection
                            sectionIcon={faFaceSunglasses}
                            isToolTip={true}
                            toolTipContent={t('lbl_tyler_show_desc')}
                            sectionLabel={!!localSettings.hideTylerToggle ? t('lbl_tyler_show') : t('lbl_tyler_hide')}
                        >
                            <Switch
                                isChecked={!localSettings?.hideTylerToggle}
                                setIsChecked={onTylerToggleChangeHandler}
                            />
                        </SettingsSection>
                    </SettingsFields>
                    <SettingsFields
                        label={'lbl_personalise_page'}
                    >
                        <SettingsSection
                            sectionIcon={faEuro}
                            isToolTip={true}
                            toolTipContent={t('lbl_change_currency_desc')}
                            sectionLabel={t('lbl_change_currency')}
                        >
                            <Styled.Settings__Select__Holder>
                                <Select
                                    appendTo={document.querySelector('.drawer-root')}
                                    title={t('lbl_currency')}
                                    isClearAble={false}
                                    isMulti={false}
                                    setAppliedList={onCurrencyChangeHandler}
                                    appliedList={getValueForSelect(localSettings?.PreferredCurrency, Currency_Options)}
                                    setIsSelectOpen={setIsSelectOpen}
                                    options={Currency_Options}
                                />
                            </Styled.Settings__Select__Holder>
                        </SettingsSection>
                        <SettingsSection
                            sectionIcon={faPlaneDeparture}
                            isToolTip={true}
                            toolTipContent={t('lbl_custom_outbound_desc')}
                            sectionLabel={t('lbl_custom_outbound_title')}
                        >
                            <Styled.Settings__Select__Holder>
                                <Select
                                    appendTo={document.querySelector('.drawer-root')}
                                    title={t('lbl_outbound')}
                                    isClearAble={false}
                                    isMulti={false}
                                    setAppliedList={onPreferredCountrySelectChangeHandler}
                                    appliedList={getValueForSelect(localSettings?.PreferredCountry, Preferred_Country_Options)}
                                    setIsSelectOpen={setIsSelectOpen}
                                    options={Preferred_Country_Options}
                                />
                            </Styled.Settings__Select__Holder>
                        </SettingsSection>
                        <SettingsSection
                            sectionIcon={faRectangleAd}
                            isToolTip={true}
                            toolTipContent={t('lbl_custom_advertisement_desc')}
                            sectionLabel={t('lbl_custom_advertisement_title')}
                        >
                            <Styled.Settings__Select__Holder>
                                <Select
                                    appendTo={document.querySelector('.drawer-root')}
                                    title={t('lbl_advertisement')}
                                    isClearAble={false}
                                    isMulti={false}
                                    setAppliedList={onAdvertisemenetChangeHandler}
                                    appliedList={getValueForSelect(localSettings?.AdvertisementLocation, Preferred_Continents_Options)}
                                    setIsSelectOpen={setIsSelectOpen}
                                    options={Preferred_Continents_Options}
                                />
                            </Styled.Settings__Select__Holder>
                        </SettingsSection>
                        <SettingsSection
                            sectionIcon={faUsers}
                            isToolTip={true}
                            toolTipContent={t('lbl_custom_participants_desc')}
                            sectionLabel={t('lbl_custom_participants_title')}
                        >
                            <Styled.Settings__Participants__Holder>
                                <ParticipantsPicker
                                    appendTo={document.querySelector('.drawer-root')}
                                    setIsSelectOpen={setIsSelectOpen}
                                    formikValue={localParticipants}
                                    setFormikValues={onLocalParticipantsChange}
                                />
                            </Styled.Settings__Participants__Holder>
                        </SettingsSection>
                        <SettingsSection
                            sectionIcon={faCalendarDays}
                            isToolTip={true}
                            toolTipContent={t('lbl_custom_duration_desc')}
                            sectionLabel={t('lbl_custom_duration_title')}
                        >
                            <Styled.Settings__Participants__Holder>
                                <DurationPicker
                                    appendTo={document.querySelector('.drawer-root')}
                                    setIsModalOpen={setIsSelectOpen}
                                    formikValue={localDuration}
                                    setFormikValues={onLocalDurationChangeHandler}
                                />
                            </Styled.Settings__Participants__Holder>
                        </SettingsSection>
                    </SettingsFields>
                    <SettingsFields
                        label={'lbl_app_settings'}
                    >
                        <>
                            <SettingsSection
                                sectionIcon={faWifiFair}
                                isToolTip={true}
                                toolTipContent={t('lbl_automatic_low_data_mode_desc')}
                                sectionLabel={t('lbl_automatic_low_data_mode')}
                            >
                                <Switch
                                    isChecked={!!localSettings?.AutomaticLowDataMode}
                                    setIsChecked={onAutomaticLowDataModeChangeHandler}
                                />
                            </SettingsSection>
                            <SettingsSection
                                sectionIcon={faRouter}
                                isToolTip={true}
                                toolTipContent={t(!!localSettings?.AutomaticLowDataMode ? 'lbl_isdisabled_low_data_mode_desc' : 'lbl_low_data_mode_desc')}
                                sectionLabel={t('lbl_low_data_mode')}
                            >
                                <Switch
                                    isChecked={localSettings?.LowDataMode}
                                    isDisabled={!!localSettings?.AutomaticLowDataMode}
                                    setIsChecked={onLowDataModeChangeHandler}
                                />
                            </SettingsSection>
                        </>
                    </SettingsFields>

                </Styled.SettingsHolder>
            </Drawer>
        </>
    )
})
export default Settings