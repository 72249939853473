import {listOfAvailableAttributes} from "../../../../../../Components/AccommodationAttributes";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {floor, includes, isEmpty} from "lodash-es";
import {Styled} from "./OfferDescription.styles";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";

type OfferAttributesProps = {
    isLoadingOfferDetails: boolean,
    Attributes: string[] | undefined
}


const OfferAttributes = (
    {
        isLoadingOfferDetails,
        Attributes,
    }: OfferAttributesProps
) => {

    const [attributes, setAttributes] = useState<any>({})

    const {t} = useTranslation();

    const availableAttributes = listOfAvailableAttributes(t)

    const hotelAttributes = [
        'facility_fireplace',
        'facility_barbecue',
        'facility_night_life',
        'facility_winter_equipment_rental',
        'facility_free_wifi',
        'facility_parking',
        'facility_for_families_with_childrens',
        'facility_air_conditioning',
        'facility_outdoor_pool',
        'facility_indoor_pool',
        'facility_fitness_or_gym',
        'facility_gay_friendly',
        'facility_wellness',
        'facility_for_adult',
        'facility_home_appliances',
        'facility_kindergarten',
        'facility_water_slides',
        'facility_facilities_for_disabled',
        'facility_pets',
        "facility_internet_access"

    ]

    const locationAttributes = [
        'location_directly_on_the_beach',
        'location_near_airport',
        'location_near_the_center',
        'location_city_breaks',
        'location_mountains',
        'location_near_the_slope',
        'location_exotic_offers',
        'location_ski_resorts',
        'location_lakes',
    ]

    const attractionsAttributes = [
        'activity_sport',
        'activity_bicycles',
        'activity_tennis',
        'activity_golf',
        'activity_minigolf',
        'activity_water_sports',
        'activity_diving',
        'activity_riding'
    ]


    useEffect(() => {

        const newHotel: string[] = []

        const newLocation: string[] = []

        const newAttractions: string[] = []

        Attributes?.map((name: string) => {
            if (includes(hotelAttributes, name)) {
                newHotel.push(name)
                return
            }
            if (includes(locationAttributes, name)) {
                newLocation.push(name)
                return
            }
            if (includes(attractionsAttributes, name)) {
                newAttractions.push(name)
                return;
            }
            setAttributes({
                hotelAttributes: newHotel,
                locationAttributes: newLocation,
                attractionAttributes: newAttractions,
            })
        })

    }, [Attributes])

    return !isLoadingOfferDetails ? (
        <>
            {Object.keys(attributes).map((type: string) => {
                const sectionValue = attributes[type]
                if (!isEmpty(sectionValue)) {
                    const availableAttributesList = availableAttributes?.filter((option: any) => includes(sectionValue, option.value))

                    return (
                        <Styled.Attribute__Section__Holder
                            key={`attribute_${type}`}
                        >
                            <Styled.Offer_Attributes_Section_Title>
                                {t(`lbl_${type}`)}
                            </Styled.Offer_Attributes_Section_Title>
                            {availableAttributesList?.map((attribute: any) => (
                                    <Styled.Attribute>
                                        <Styled.Chevron__Icon
                                            icon={faCheck}
                                        />
                                        <Styled.Attribute__Label>
                                            {attribute?.desc}
                                        </Styled.Attribute__Label>
                                    </Styled.Attribute>
                                )
                            )}
                        </Styled.Attribute__Section__Holder>
                    )

                }
                return null
            })}
        </>
    ) : (
        <>
            {Array.from({length: 3}).map((_: any, index: number) => {
                const randomTitleWidth = floor(Math.random() * (140 - 110) + 110)
                const randomBoxesNumber = floor(Math.random() * (8 - 4) + 4)

                return (
                    <Styled.Attribute__Section__Holder
                        key={`loading_attributes_${index}`}
                    >
                        <SkeletonBox width={`${randomTitleWidth}px`} height={'25px'} margin={'3px 4px'}/>
                        {Array.from({length: randomBoxesNumber}).map((_: any) => {
                            const randomWidth = floor(Math.random() * (70 - 40) + 40);
                            return (
                                <SkeletonBox margin={'2px 4px 2px 9px'} height={'12px'} width={`${randomWidth}px`}/>
                            )
                        })}
                    </Styled.Attribute__Section__Holder>
                )

            })
            }
        </>
    )
}
export default OfferAttributes