import styled from "styled-components";
import Text from "../../lib/UIBookBrd/Text";

const Content__Holder = styled(Text)`
  padding: 10px;
  max-height: 700px;
  user-select: none;
`
export const Styled = {
    Content__Holder
}