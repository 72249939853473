import {Styled} from "../../TripOffer.styles";
import CustomText from "./CustomText";
import {isEmpty} from "lodash-es";
import Pricing from "./Pricing";
import TransportField from "./TransportField";
import {GET_TRANSPORT_TYPE} from "../../../../../TripSearchFunctions";
import AccommodationAttributes from "../../../../../../../../Components/AccommodationAttributes";
import FlexBox from "../../../../../../../../lib/UIBookBrd/FlexBox";
import {useTranslation} from "react-i18next";
import useBookBrdPageFunctions from "../../../../../../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";

type TopLayerDescriptionProps = {
    isExlusive: boolean
    isOfferFocused: boolean
    offerData: any
}


const TopLayerDescription = (
    {
        isExlusive,
        isOfferFocused,
        offerData
    }: TopLayerDescriptionProps
) => {

    const {
        Base,
        Transport,
        Accommodation
    } = offerData

    const {onTourOperatorClick} = useBookBrdPageFunctions()

    const {t} = useTranslation();

    const onOperatorClick = (event: any) => {
        event.stopPropagation()
        onTourOperatorClick({name: Base.OperatorDesc, code: Base.Operator})
    }


    return (
        <Styled.TopOfferDescription>
            <Styled.TextHolder>
                {!isEmpty(Base.StartDate) && !isEmpty(Base.ReturnDate) && (
                    <TransportField
                        transport={Transport}
                        base={Base}
                        transportType={GET_TRANSPORT_TYPE(Transport)}
                    />
                )}
                <CustomText
                    type={'lbl_standard'}
                    value={`${Accommodation.Room.Name} • ${Accommodation.Service.Name ? Accommodation.Service.Name : t('lbl_board_not_specified')}`}
                />
                {!isEmpty(offerData.Base.XCountry?.Name) && (
                    <CustomText
                        type={'lbl_location'}
                        value={`${offerData.Base.XCountry?.Name} • ${offerData.Base.XRegion?.Name}`}
                    />
                )}
                <CustomText
                    type={'lbl_operator'}
                    onValueClick={onOperatorClick}
                    value={`${Base?.OperatorDesc}`}
                />
                <FlexBox
                    style={{
                        marginTop: '6px'
                    }}
                >
                    <AccommodationAttributes
                        maxNumberOfRows={1}
                        attributes={offerData?.Accommodation?.Attributes ? offerData?.Accommodation?.Attributes : []}
                    />
                </FlexBox>
                <FlexBox
                    style={{
                        marginTop: 'auto',
                        paddingTop: "5px",
                        marginBottom: "2px"
                    }}
                >
                    <Pricing
                        isExlusive={isExlusive}
                        pricing={Base.Price}
                    />
                </FlexBox>
            </Styled.TextHolder>
        </Styled.TopOfferDescription>
    )
}
export default TopLayerDescription