import {useContext} from "react";
import {Search_Proto} from "../Context/Context";
import {promiseRequest} from "../RequestBuilder";
import {CONTENT_SERVICE_URL, V5_DETAILS} from "../Configs/RequestConfig";
import useHandleOnlineRequestError from "./useHandleOnlineRequestError";


const UseSimilarOffersHandler = (SendRequestForOfferList: any) => {

    const [protoValue, setProtoValue] = useContext<any>(Search_Proto)

    const {getV5Error} = useHandleOnlineRequestError()

    const sendRequestsForSimilarOffers = async (setIsLoadingDetails: any, similarOfferDetails: any, setSimilarOffersDetails: any, setSimilarOffersList: any, setIsLoadingList: any, optionList: any) => {
        try {
            setIsLoadingDetails(true)
            const v5DataRequest = await promiseRequest(`${V5_DETAILS}${protoValue?.similarOffers?.offerId}`);
            SendRequestForOfferList(
                setIsLoadingList,
                setSimilarOffersList,
                'SimilarOffers',
                Number(protoValue?.similarOffers?.XCode),
                'SimilarOffers',
                optionList
            )

            setSimilarOffersDetails({
                ...similarOfferDetails,
                offer: v5DataRequest?.result,
                offerId: protoValue?.similarOffers?.offerId,
            })

        } catch (e) {
            setSimilarOffersDetails({
                ...similarOfferDetails,
                offer: {error: getV5Error(e)}
            })
        } finally {
            setIsLoadingDetails(false)
        }
    }

    const onCloseSimilarOffers = () => {
        let newProtoValue = protoValue

        delete newProtoValue?.similarOffers

        setProtoValue((state: any) => ({
            ...state,
            similarOffers: {}
        }))
    }

    const SimilarOffersHandler = async (value: any, setIsLoading?: any) => {

        setProtoValue((state: any) => ({
            ...state,
            similarOffers: {
                ...value,
                isDisplay: true
            }
        }))
    }

    const sendWCSRequest = async (setIsLoading: any, offer: any, offerId: string, setValues: any) => {
        try {
            if (setIsLoading) {
                setIsLoading(true)
            }
            const request = await promiseRequest(CONTENT_SERVICE_URL, {
                Sections: ['Pictures', 'Description'],
                Language: "PL",
                Operator: offer?.Base?.Operator,
                HotelXCode: offer?.Base?.XCode?.Id,
                offerId: offerId,
                HotelCode: `${offer?.Accommodation?.Code}`,
                PictureSizes: "full"
            });
            const {Sections} = request
            const {Pictures, Description} = Sections
            const {PictureUrlFull} = Pictures
            setValues({
                pictures: PictureUrlFull,
                description: Description
            })
        } catch (e) {
        } finally {
            if (setIsLoading) {
                setIsLoading(false)
            }
        }
    };


    return ({
        value: protoValue?.similarOffers,
        sendRequestsForSimilarOffers,
        isDisplay: !!protoValue?.similarOffers?.isDisplay,
        onCloseSimilarOffers,
        SimilarOffersHandler,
        sendWCSRequest
    })
}


export default UseSimilarOffersHandler