import useConfirmationPage from "../../../useConfirmationPage";
import {Styled} from "../../ManageContent.styles";
import {useTranslation} from "react-i18next";
import Button from "../../../../../lib/UIBookBrd/Button";

const Refund = () => {

    const {
        refund
    } = useConfirmationPage()

    const {t} = useTranslation()

    return refund?.isRefundAble ? (
        <Styled.Refund__Holder>
            <Styled.No__Refund__Label>
                {t('lbl_get_refund')}
            </Styled.No__Refund__Label>

            <Button label={t('lbl_get_refund')}/>

        </Styled.Refund__Holder>
    ) : (
        <Styled.Refund__Holder>
            <Styled.No__Refund__Label>
                {t('lbl_refund_unavailable')}
            </Styled.No__Refund__Label>
            <Styled.No__Refund__Description>
                {t('lbl_no_refund_description')}
            </Styled.No__Refund__Description>
        </Styled.Refund__Holder>
    )
}

export default Refund
