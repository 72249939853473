import FlexBox from "../../lib/UIBookBrd/FlexBox";
import SpecialInput from "./SpecialInput";
import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {Styled} from "./SpecialInput.styles";
import {isMobileOnly} from "react-device-detect";
import {Modal} from "../../lib/UIBookBrd/Modal/ModalsTypes";
import usePopperPositioning from "../../lib/UseHooksBookBrd/usePopperPositioning";
import {useTranslation} from "react-i18next";
import DateRangePicker from "../DateRangePicker";
import {isEmpty} from "lodash-es";
import Drawer from "../../lib/UIBookBrd/Drawer";
import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";
import GlobalStyle from "../../Styles/GlobalStyles";
import 'react-dates/initialize';
import useConfigureSite from "../../lib/UseHooksBookBrd/useConfigureSite";
//IMPORT AVAIL LANGUAGES
import 'moment/locale/de'
import 'moment/locale/pl'
import 'moment/locale/es'

type DatePickerProps = {
    formikValue: any
    appendTo?: Element | null
    setFormikValues: any
}

const sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}


const DatePicker = (
    {
        appendTo,
        formikValue,
        setFormikValues
    }: DatePickerProps) => {

    const [startDate, setStartDate] = useState<any>({label: formikValue?.startDate})

    const [endDate, setEndDate] = useState<any>({label: formikValue?.endDate})

    const [startDateChange, setStartDateChange] = useState<boolean>(false)

    const [endDateChange, setEndDateChange] = useState<boolean>(false)

    const [focusedInput, setFocusedInput] = useState<any[]>([])

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false)

    const [shownMonth, setShowMonth] = useState<any>(moment(startDate))

    useOnClickOutside(modalElement, () => isSelectOpen ? undefined : setFocusedInput([]));

    const {Language} = useConfigureSite()

    moment.locale(Language)

    const inputElement = useRef<HTMLDivElement>();

    const {t} = useTranslation();

    const onEndDateInputClickHandler = () => {
        setFocusedInput(['endDate'])
    }

    const onStartDateInputClickHAndler = () => {
        setFocusedInput(['startDate'])
    }

    const startDatePartChangeHandler = (event: any) => {
        const value = event.target.value
        if (value.length >= 4 && value.length > startDate.label.length) {
            setStartDateChange(false)
            sleep(100).then(() => {
                const newStartDate = DateChanger(value, moment().format('DD.MM.YYYY')) === 'Invalid date' ? moment().format('DD.MM.YYYY') : DateChanger(value, moment().format('DD.MM.YYYY'));
                setStartDate({label: newStartDate})
                if (moment.min(moment(newStartDate, 'DD.MM.YYYY'), moment(endDate.label, 'DD.MM.YYYY')).format('DD.MM.YYYY') === endDate.label) {
                    setEndDate({label: moment(newStartDate, 'DD.MM.YYYY').add(1, 'weeks').format('DD.MM.YYYY')})
                }
            })
        } else {
            setStartDateChange(true)
            setStartDate({label: value})
        }

    }

    const startDatePartInputBlur = () => {
        if (startDate.label.length !== 10) {
            setStartDateChange(false)
            setStartDate({label: moment().format('DD.MM.YYYY')});
        }
    }

    const endDatePartInputBlur = () => {
        if (endDate.label.length !== 10) {
            setEndDateChange(false)
            setEndDate({label: moment().format('DD.MM.YYYY')});
        }
    }

    const endDateChangeHandler = (event: any) => {
        const value = event.target.value
        if (value.length >= 4 && value.length > endDate.label.length) {
            setEndDateChange(false)
            sleep(100).then(() => {
                const newStartDate = DateChanger(value, moment().format('DD.MM.YYYY')) === 'Invalid date' ? moment().format('DD.MM.YYYY') : DateChanger(value, startDate.label);
                setEndDate({label: newStartDate})
            })
        } else {
            setEndDateChange(true)
            setEndDate({label: value})
        }

    }

    const DateChanger = (value: any, minDAte: any) => {
        const changedDate = moment(value, 'DD.MM.YYYY');
        const changedDateWithFormat = moment(value, 'DD.MM.YYYY').format('DD.MM.YYYY');
        const dateHigherThanMin = moment.max(changedDate, moment(minDAte, 'DD.MM.YYYY')).format('DD.MM.YYYY')
        if (dateHigherThanMin !== changedDateWithFormat) {
            const changedDate = moment(value, 'DD.MM.YYYY').format('DD.MM') as any;
            const newDate = moment(`${changedDate}.${moment(minDAte, 'DD.MM.YYYY').format('YYYY')}`, 'DD.MM.YYYY')
            const dateIsAvaiable = moment.max(moment(minDAte, 'DD.MM.YYYY'), moment(newDate, 'DD.MM.YYYY')).format('DD.MM.YYYY')
            if (dateIsAvaiable !== newDate.format('DD.MM.YYYY')) {
                return newDate.add(1, 'year').format('DD.MM.YYYY')

            } else {
                return newDate.format('DD.MM.YYYY')
            }
        } else {
            return dateHigherThanMin
        }


    }

    const NumberOfMonths = () => {
        const windowWidth = window.innerWidth
        if (windowWidth < 619) {
            return 1
        }
        if (windowWidth < 919) {
            return 2
        }
        if (windowWidth > 919) {
            return 3
        }


    }

    const ModalWidth = () => {
        const windowWidth = window.innerWidth
        if (windowWidth < 619) {
            return 319
        }
        if (windowWidth < 919) {
            return 619
        }
        if (windowWidth > 919) {
            return 919
        }
    }


    const {styles, attributes} = usePopperPositioning({
        targetElementRef: inputElement,
        popperElementRef: modalElement,
        applyMaxSizeEnabled: true,
        zIndex: 9999,
        padding: 15,
    });

    useEffect(() => {
        if (focusedInput[0] === undefined) {
            if (isEmpty(endDate.label)) {
                setEndDate({label: moment(startDate.label, 'DD.MM.YYYY').add(1, 'week').format('DD.MM.YYYY')})
            }
        }
    }, [focusedInput])

    useEffect(() => {
        if (!isEmpty(startDate) && !isEmpty(endDate) && moment.min(moment(endDate), moment(startDate)).format('DD.MM.YYYY') !== endDate)
            if (formikValue.startDate !== startDate.label || formikValue.endDate !== endDate.label) {
                setFormikValues('Base.DateRange', {startDate: startDate.label, endDate: endDate.label})
            }
    }, [endDate])

    useEffect(() => {
        if (!isEmpty(formikValue)) {
            setStartDate({label: formikValue?.startDate})
            setEndDate({label: formikValue?.endDate})
        }
    }, [formikValue])

    //Render
    const content = (
        <FlexBox
            style={{
                width: '100%',
                height: '100%',
                flexWrap: 'nowrap',
            }}
        >
            <GlobalStyle/>
            <DateRangePicker
                shownMonth={shownMonth}
                setShowMonth={setShowMonth}
                setIsSelectOpen={setIsSelectOpen}
                startDate={!startDateChange ? moment(startDate.label, "DD.MM.YYYY") : moment(null)}
                endDate={!endDateChange ? moment(endDate.label, "DD.MM.YYYY") : moment(null,)}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                focusedInput={focusedInput[0] as any}
                setFocusedInput={setFocusedInput}
                minDate={moment()}
                numberOfMonths={NumberOfMonths()}
                onClose={() => {
                }}
            />
        </FlexBox>
    )
    return (
        <FlexBox
            style={{
                minWidth: '100%',
                width: '100%',
                maxWidth: 'inherit',
                flexWrap: 'nowrap'
            }}>
            <Styled.InputHolder
                ref={inputElement as any}
            >
                <SpecialInput
                    placeholder={t('lbl_choose_date')}
                    readOnly={isMobileOnly}
                    onBlur={startDatePartInputBlur}
                    onClick={onStartDateInputClickHAndler}
                    onChange={startDatePartChangeHandler}
                    showIcon={false}
                    value={isEmpty(startDate.label) ? {label: ''} : {label: startDate.label}}
                    style={{
                        maxWidth: '50%',
                        marginRight: "0",
                        paddingRight: '0',
                        borderBottomRightRadius: '0',
                        borderTopRightRadius: "0",
                    }}
                />
                <SpecialInput
                    placeholder={t('lbl_choose_date')}
                    readOnly={isMobileOnly}
                    onBlur={endDatePartInputBlur}
                    onClick={onEndDateInputClickHandler}
                    onChange={endDateChangeHandler}
                    showIcon={false}
                    value={isEmpty(endDate.label) ? {label: ''} : {label: endDate.label}}
                    style={{
                        maxWidth: '50%',
                        marginLeft: "0",
                        paddingLeft: '0',
                        borderBottomLeftRadius: '0',
                        borderTopLeftRadius: "0"
                    }}
                />
            </Styled.InputHolder>
            {isMobileOnly ? (

                    <Drawer
                        // showOverlay={true}
                        position={'bottom'}
                        title={t(`departure_${focusedInput[0]}`)}
                        isOpen={!isEmpty(focusedInput)}
                        ref={setModalElement}
                        onClose={() => setFocusedInput([])}
                    >
                        <FlexBox
                            style={{minWidth: "100vw"}}
                        >
                            {content}
                        </FlexBox>
                    </Drawer>) :
                (
                    <>
                        <Modal
                            appendTo={appendTo}
                            showCloseIcon={false}
                            ref={setModalElement}
                            showOverlay={false}
                            isOpen={!isEmpty(focusedInput)}
                            width={ModalWidth()}
                            positionedByPopper
                            style={styles.popper}
                            {...attributes.popper}
                            // controls={controls}
                        >
                            {content}
                        </Modal>
                    </>
                )}
        </FlexBox>
    )

}

export default DatePicker