import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import React, {useEffect, useState} from "react";
import AddButton from "./AddButton";
import Text from "../../../lib/UIBookBrd/Text";
import {useTranslation} from "react-i18next";
import Select from "../../../lib/UIBookBrd/SelectBookBrd/Select";
import {isEmpty} from "lodash-es";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChild, faUser} from "@fortawesome/pro-solid-svg-icons";

type ParticipantPickerElementProps = {
    setIsSelectCalendarOpen: any
    formikValues?: any
    changeNumber?: any
    maxNumber?: any
    minNumber?: any
    label?: any
    defultNumber?: any
}
const defaultProps = {
    maxNumber: 4,
    minNumber: 1,
    label: 'Insert Name',
    defultNumber: 1,
}
const ParticipantPickerElement = ({
                                      setIsSelectCalendarOpen,
                                      formikValues,
                                      label,
                                      defultNumber,
                                      minNumber,
                                      changeNumber,
                                      maxNumber,
                                  }: ParticipantPickerElementProps) => {
    const {t} = useTranslation();

    const [number, setnumber] = useState(defultNumber)

    const [isDisabledLess, setisDisabledLess] = useState(false)

    const [isDisabledMore, setisDisabledMore] = useState(false)

    const [selectOptions, setSelectOptions] = useState<any[]>([])

    const icon = label === 'Adults' ? faUser : faChild

    const onClickAddReduceFunc = (typeofchange: any, newNumber: any) => {
        if (label === 'Adults') {
            changeNumber(
                {
                    adults: {
                        value: newNumber,
                    },
                    kids: {
                        value: formikValues.kids.value,
                        dates: formikValues.kids.dates
                    },
                }
            )
        }
        if (label === 'Kids') {
            const birthList: any[] = []
            Array.from({length: newNumber}).map((date: any, index: number) => {
                const oldDate = formikValues.kids.dates[index]
                const hasDate = !isEmpty(oldDate)
                birthList.push(hasDate ? oldDate : isEmpty(sessionStorage.getItem(`Pp.${label}.${index}.Date`)) ? moment().add(-(Math.floor(Math.random() * 9) + 5), 'years').format('DD.MM.YYYY') : sessionStorage.getItem(`Pp.${label}.${index}.Date`))
            });
            changeNumber(
                {
                    adults: {
                        value: formikValues.adults.value,
                    },
                    kids: {
                        value: newNumber,
                        dates: birthList
                    },
                }
            )
        }
    }

    const appliedListValue = (value: number) => {
        const newDate = moment().diff(moment(value, 'DD.MM.YYYY'), 'years')
        if (isEmpty(value)) {
            return {}
        }
        return {
            label: newDate,
            value: newDate
        }
    }

    const onClickMore = () => {
        setisDisabledLess(false)
        setnumber(number as number + 1)
    }

    const onClickLess = () => {
        setisDisabledMore(false)
        setnumber(number as number - 1)
    }

    useEffect(() => {
        const list: any[] = []
        for (let i = 0; i < 18; i++) {
            list.push({
                label: i,
                value: i
            })
        }
        setSelectOptions(list)
    }, [])

    useEffect(() => {
        onClickAddReduceFunc(label, number)
        if (number === maxNumber) {
            setisDisabledMore(true);
        }
        if (number === minNumber) {
            setisDisabledLess(true);
        }
    }, [number]);

    return (
        <>
            <FlexBox
                style={{display: 'block', userSelect: 'none', width: "100%",}}>
                <FlexBox style={{width: '100%', flexWrap: 'nowrap'}}>
                    <Text
                        style={{
                            margin: ' auto auto auto 5px',
                            fontSize: '18px'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={icon}
                            style={{
                                height: '18px',
                                width: '18px',
                                marginRight: '5px'
                            }}
                        />

                        {t(`${label}`)}
                    </Text>

                    <AddButton isDisabled={isDisabledLess} onClick={onClickLess} isAdd={false}/>
                    <FlexBox
                        style={{
                            width: '22px'
                        }}
                    >
                        <Text style={{margin: 'auto auto', width: 'auto', fontSize: '18px'}}>{number}</Text>
                    </FlexBox>
                    <AddButton isDisabled={isDisabledMore} onClick={onClickMore} isAdd={true}/>
                </FlexBox>
                {label === 'Kids' && (
                    <FlexBox style={{paddingBottom: '10px'}}>
                        {Array.from({length: number}).map((item, personIndex) => {
                            const setSelect = (value: any) => {
                                if (label === 'Kids') {
                                    const birthList: any[] = []
                                    Array.from({length: formikValues.kids.value}).map((date: any, index: number) => {
                                        const oldDate = formikValues.kids.dates[index]
                                        const formatedDate = moment().add(-value.value, 'years').format('DD.MM.YYYY')
                                        if (index === personIndex) {
                                            sessionStorage.setItem(`Pp.${label}.${index}.Date`, formatedDate)
                                            birthList.push(formatedDate)
                                        } else {
                                            birthList.push(oldDate)
                                        }

                                    });
                                    changeNumber(
                                        {
                                            adults: {
                                                value: formikValues.adults.value,
                                                dates: undefined
                                            },
                                            kids: {
                                                value: formikValues.kids.value,
                                                dates: birthList
                                            },
                                            rooms: {
                                                value: formikValues.rooms.value,
                                                dates: undefined
                                            },
                                        }
                                    )
                                }
                            }
                            return (
                                <>
                                    <FlexBox
                                        style={{
                                            flexDirection: 'column',
                                            width: '46%',
                                            maxWidth: '200px',
                                            margin: ' 4px 2% ',
                                        }}
                                    >
                                        <Select
                                            title={t('lbl_choose_kids_age')}
                                            options={selectOptions}
                                            placeholder={t('lbl_choose_age')}
                                            appliedList={[appliedListValue(formikValues.kids.dates[personIndex])]}
                                            setAppliedList={setSelect}
                                            isClearAble={false}
                                            setIsSelectOpen={setIsSelectCalendarOpen}
                                            isMulti={false}
                                        />
                                    </FlexBox>
                                </>
                            )
                        })}

                    </FlexBox>
                )}
            </FlexBox>
        </>
    )
}
ParticipantPickerElement.defaultProps = defaultProps;
export default ParticipantPickerElement