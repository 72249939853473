import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";

const RangePickerElementsHolder = styled(FlexBox)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px;
  width: 100%;
`

const LengthElement = styled(FlexBox)`
  background: ${(props: { theme: any; isSelected: boolean; isIncluded: boolean }) => props.isSelected ? props.theme.calendarSelectedDate : props.isIncluded ? props.theme.calendarFillDates : props.theme.backgroundColor};
  min-height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${(props: { theme: any; }) => props.theme.optionHoverColor};
  }

`
const LengthBasicPicker = styled(FlexBox)`
  padding: 5px;
  flex-direction: column;
`

const ElementText = styled(Text)`
  font-weight: bold;

`
const SelectHolder = styled(FlexBox)`
  flex-direction: column;
  padding: 10px 5px;
  border-bottom: 1px solid ${(props: { theme: any; }) => props.theme.separatorColor};
`

const CustomLabelText = styled(Text)`
  font-weight: bold;
  padding: 5px 0;

`

export const Styled = {
    CustomLabelText,
    ElementText,
    LengthBasicPicker,
    RangePickerElementsHolder,
    SelectHolder,
    LengthElement,

};