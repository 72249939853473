// @ts-ignore
import styled from "styled-components";
import Text from "../../../lib/UIBookBrd/Text";

const ParticipantPicker__Input = styled.div`
  z-index: 1000;
  position: relative;
  display: flex;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  flex-grow: 1;
  margin: 2px 4px;
  user-select: none;
  border: 1px solid ${(props: { theme: any; }) => props.theme.borderColor};

  &:hover {
    cursor: pointer;
    outline: ${(props: { theme: any; }) => `solid 1px ${props.theme.borderColor} `};
  }
`;
const FlexBox1 = styled.div`
  display: block;
  min-width: ${(props: { minWidth: any; }) => props.minWidth}px;
  height: auto;
  margin-top: 5px;
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
`;
const Customtext = styled.div`
;
  margin: 0 auto;
  font-size: 16px;
  color: ${(props: { theme: any; }) => props.theme.placeholderColor};

`;
const SelectError = styled(Text)`
  min-height: 13px;
  font-size: 11px;
  color: red;
`

export const Styled = {
    ParticipantPicker__Input,
    FlexBox1,
    SelectError,
    Customtext,
};