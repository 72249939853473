import useConfirmationPage from "../../../useConfirmationPage";
import OfferDetails from "../../../../TripSearch/TripOfferDetails/DedicatedComponent/OfferDetailsHolder/OfferDetails";

const OfferDetailsForConfimation = () => {

    const {offer} = useConfirmationPage()

    return (
        <>
            <OfferDetails
                onShowMap={() => false}
                offer={offer}
                isLoadingOfferDetails={false}
            />
        </>
    )
}
export default OfferDetailsForConfimation