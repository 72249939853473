import {Styled} from "../Offer.styles"

type LocationProps = {
    offerData: any
}

const Location = (
    {
        offerData
    }: LocationProps
) => {

    const {Base} = offerData

    return (
        <Styled.Location__Label>
            {Base?.XCountry?.Name}{' / '}{Base?.XCity?.Name}
        </Styled.Location__Label>
    )
}

export default Location