import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import styled from "styled-components";
import Text from "../../../../../lib/UIBookBrd/Text";

const OfferList = styled(FlexBox)`
  flex-wrap: nowrap;
  overflow-y: scroll;
  padding: 0 10px;
  flex-grow: 1;
  flex-direction: column;
`

const No_Offers_Found_Holder = styled(FlexBox)`
  border-radius: 15px;
  width: auto;
  margin: 0 auto;
  flex-direction: column;
  padding: 20px 40px;
  background-color: ${(props: { theme: any }) => props.theme.backgroundColor};;
  flex-wrap: nowrap;
`
const No_Offers_Found_Main_Label = styled(Text)`
  user-select: none;
  margin: auto auto 0 auto;
  max-width: max-content;
  font-weight: bold;
`
const No_Offers_Found_Desc_Label = styled(Text)`
  text-align: center;
  white-space: break-spaces;
  user-select: none;
  padding-top: 10px;
  margin: 0 auto auto auto;

`
const SkeletonHolder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
`
const No_More_Offer_Found = styled(FlexBox)`
  margin: 4px auto;
  padding: 2px 0;
  width: calc(100% - 16px);
  background-color: ${({theme}) => theme.backgroundColor};
  box-shadow: ${({theme}) => `0px 0px 6px 0px ${theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'}`};

  border-radius: 5px;
  min-width: 100%;
  flex-direction: column;
`
const No_More_Offer_Found_Title = styled(Text)`
  font-size: 15px;
  font-weight: bold;
  margin: 5px auto 5px auto;
`
const No_More_Offer_Found_Description = styled(Text)`
  margin: 0 auto 10px auto;
`


export const Styled = {
    No_More_Offer_Found_Description,
    No_More_Offer_Found_Title,
    No_More_Offer_Found,
    SkeletonHolder,
    OfferList,
    No_Offers_Found_Holder,
    No_Offers_Found_Main_Label,
    No_Offers_Found_Desc_Label
}