import {Styled} from "../SimilarOffersDrawer.styles"
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";

const SkeletonOffer = () => {
    return (
        <Styled.Similar__Offer
            isLoading={true}
        >
            <SkeletonBox
                width={'120px'}
                height={'14px'}
            />
            <SkeletonBox
                width={'250px'}
                height={'14px'}
            />
            <SkeletonBox
                width={'240px'}
                height={'14px'}
            />
            <SkeletonBox
                width={'150px'}
                height={'14px'}
                margin={'0 2px 2px auto'}
            />

        </Styled.Similar__Offer>
    )
}


export default SkeletonOffer