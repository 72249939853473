import {Styled} from "../OperatorDetails.styles"
import OffersView from "./OffersView";
import AboutView from "./AboutView";
import ContactView from "./ContactView";
import RecommendedOffer from "../../../Components/SearchTrip/SearchTripLowerPart/RecommendedOffer";

export enum Operator__Views__Types {
    offers = 'Offers',
    about = "About",
    contact = "Contact"
}

const tabsTypes = [
    Operator__Views__Types.about,
    Operator__Views__Types.contact,
]

type MainValuesProps = {
    values: any
    setValuesType: any
    selectedType: Operator__Views__Types
}

const object: any = [
    {
        "groupKeyValue": "1635",
        "Offer": {
            "Base": {
                "XCity": {
                    "Id": "20981",
                    "Name": "Malta"
                },
                "XCode": {
                    "Id": 3406,
                    "Name": "Porto Azzurro Aparthotel (St. Paul's Bay)"
                },
                "XCountry": {
                    "Id": "26",
                    "Name": "Malta"
                },
                "XRegion": {
                    "Id": "4100",
                    "Name": "Wyspa Malta"
                },
                "Availability": {
                    "base": "available",
                    "message": ""
                },
                "Catalog": "KAT",
                "DatasetInfo": {
                    "name": "",
                    "private": false
                },
                "DepartureLocation": {
                    "Id": "0x4b72616bc3b377",
                    "Name": "Kraków"
                },
                "Duration": 4,
                "NightsBeforeReturn": 3,
                "OfferId": "c54d044d4fa9d9b9f7266aeb9560ff5b7da0cae737b0abd360d968bb27b8d20e|ECCX|Mnx8",
                "Operator": "ECCX",
                "OperatorDesc": "Ecco HolidayX",
                "OriginalPrice": {
                    "FirstPerson": {
                        "amount": "1076.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2152.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Price": {
                    "FirstPerson": {
                        "amount": "1076.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2152.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Promotion": "XXXX",
                "ReturnDate": "2023-12-27",
                "StartDate": "2023-12-24",
                "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/ECCX/IMG/2814/main.webp",
                "Transfer": "yes",
                "UniqueObjectId": "c54d044d4fa9d9b9f7266aeb9560ff5b7da0cae737b0abd360d968bb27b8d20e"
            },
            "Accommodation": {
                "XCity": {
                    "Id": "20981",
                    "Name": "Malta"
                },
                "XCountry": {
                    "Id": "26",
                    "Name": "Malta"
                },
                "Attributes": [
                    "location_seaside_resorts",
                    "location_near_airport",
                    "location_without_passport_from_pl",
                    "facility_free_wifi",
                    "facility_parking",
                    "facility_for_families_with_childrens",
                    "facility_fitness_or_gym",
                    "facility_outdoor_pool",
                    "facility_indoor_pool",
                    "facility_kindergarten",
                    "facility_internet_access",
                    "facility_air_conditioning",
                    "activity_bicycles",
                    "activity_animation_for_adults",
                    "activity_sport",
                    "activity_water_sports",
                    "activity_diving"
                ],
                "Category": 3,
                "Code": "2814",
                "ExtTripAdvisor": {
                    "count": 1840,
                    "description": "https://www.tripadvisor.com/Hotel_Review-g608946-d499788-Reviews-Porto_Azzurro-St_Paul_s_Bay_Island_of_Malta.html?m=62467",
                    "image": "https://staticpict.merlinx.pl/tripadvisor/3.5-62467-5.svg",
                    "rating": 3.5,
                    "review": "https://www.tripadvisor.com/UserReview-g608946-d499788-Porto_Azzurro-St_Paul_s_Bay_Island_of_Malta.html?m=62467"
                },
                "Location": {
                    "Coords": [
                        35.947323,
                        14.382319
                    ],
                    "geoSearchDistance": 0
                },
                "Name": "Porto Azzurro Aparthotel",
                "Nights": 3,
                "Room": {
                    "Id": "1XU",
                    "Name": "studio standard"
                },
                "Service": {
                    "Id": "B",
                    "Name": "Śniadania"
                },
                "Type": {
                    "Id": "H",
                    "Name": "Hotel"
                },
                "XService": {
                    "Id": 3,
                    "Name": "Śniadanie"
                }
            },
            "Transport": {
                "Flight": {
                    "AirlineType": "lowcost",
                    "Luggage": "unspecified",
                    "Out": [
                        {
                            "Airline": {
                                "Code": "FR",
                                "Name": "RyanAir Ltd"
                            },
                            "Departure": {
                                "Code": "KRK",
                                "Date": "2023-12-24",
                                "Name": "Kraków",
                                "Time": "09:05"
                            },
                            "Destination": {
                                "Code": "MLA",
                                "Date": "2023-12-24",
                                "Name": "Luqa",
                                "Time": "11:45"
                            },
                            "FlightNo": "FR 7115"
                        }
                    ],
                    "Ret": [
                        {
                            "Airline": {
                                "Code": "FR",
                                "Name": "-"
                            },
                            "Departure": {
                                "Code": "MLA",
                                "Date": "2023-12-27",
                                "Name": "Luqa",
                                "Time": "05:55"
                            },
                            "Destination": {
                                "Code": "KRK",
                                "Date": "2023-12-27",
                                "Name": "Kraków",
                                "Time": "08:35"
                            },
                            "FlightNo": "FR 7114"
                        }
                    ],
                    "Stops": "no"
                }
            },
            "Online": {
                "actions": [
                    {
                        "action": "checkstatus",
                        "fieldList": [
                            "Base.Availability",
                            "Base.Price",
                            "Accommodation.AvailableRoomsCount",
                            "Transport.*"
                        ],
                        "timestamp": "2023-12-23T19:31:53+01:00"
                    }
                ]
            }
        },
        "sortKeyValue": [
            4018
        ]
    },
    {
        "groupKeyValue": "3895",
        "Offer": {
            "Base": {
                "XCity": {
                    "Id": "5010",
                    "Name": "Barcelona"
                },
                "XCode": {
                    "Id": 8915,
                    "Name": "Silken Sant Gervasi"
                },
                "XCountry": {
                    "Id": "15",
                    "Name": "Hiszpania"
                },
                "XRegion": {
                    "Id": "1474",
                    "Name": "Barcelona"
                },
                "Availability": {
                    "base": "available",
                    "message": ""
                },
                "Catalog": "CG-OPAQUE:102_____",
                "DatasetInfo": {
                    "name": "",
                    "private": false
                },
                "DepartureLocation": {
                    "Id": "0x232323574157",
                    "Name": "Warszawa"
                },
                "Duration": 3,
                "NightsBeforeReturn": 2,
                "OfferId": "XTOP3769b9X_wYAAHNOYVBwWQHtAQCNZepwCpABCgYIkPoGEAESBgjKzQgQARp-TVBVVzJHRjIzTjFWNkpNVzVLSUdTQTJXQ01MRUNJTFoyVlNaVURJNEQ5WEJUQjlLRFVPMEEzRURKVFkxMkpDWjBGMVpMSDEwWThMV1dETzQ0TUtOVFpFN1E3RlBWWjdaNU5WMjg4OUoxSTY4VEYxN1Q4SUNRUUNXTVVRS0NTCqMBCgUI-m8QAhIGCJ_PBBABGpEBTzU0NTQ0ODQyMmYzMTMzMzkzMzM0M2EzNDMzMzQzNzMyNjQzNDY2MzUzMDM0MzEzNTMxMzUzNTM0MzUzMzYxMzMzMTMzMzAzMzMyMmY0NDQyNGM1ZjUzNTQyZjUyNGYyZjMyMmYzMjMwMzIzMzMxMzIzMjM4MmYzMDMxMzIzMzM0MzUzNjM3MzgzOTMwMzEzMgocCgIQARICEAEaEkRTUlQtODItTk9UUkFOU0ZFUgogCgUImCoQARIFCIYzEAEaEERTUk0tODItNDZyMjA2NTkKHwoFCIgnEAESBQiiLxABGg9EU1JNLTgyLTJyMjA2NTgKGwoCEAESAhABGhFEU1JNLTgyLTQyNnIyMDY2MAohCgUI3AsQARIFCKYOEAEaEURTUlUtODItVWJlemJhc2ljGFIooRYyBgigjg4QATgC|XTOP|Mnx8",
                "Operator": "XTOP",
                "OperatorDesc": "XTOP",
                "OriginalPrice": {
                    "FirstPerson": {
                        "amount": "1156.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2312.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Price": {
                    "FirstPerson": {
                        "amount": "1156.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2312.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Resident": "local",
                "ReturnDate": "2023-12-30",
                "StartDate": "2023-12-28",
                "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/XTOP/IMG/8915/main.webp",
                "Transfer": "no",
                "UniqueObjectId": "XTOP3769b9X_wYAAHNOYVBwWQHtAQCNZepwCpABCgYIkPoGEAESBgjKzQgQARp-TVBVVzJHRjIzTjFWNkpNVzVLSUdTQTJXQ01MRUNJTFoyVlNaVURJNEQ5WEJUQjlLRFVPMEEzRURKVFkxMkpDWjBGMVpMSDEwWThMV1dETzQ0TUtOVFpFN1E3RlBWWjdaNU5WMjg4OUoxSTY4VEYxN1Q4SUNRUUNXTVVRS0NTCqMBCgUI-m8QAhIGCJ_PBBABGpEBTzU0NTQ0ODQyMmYzMTMzMzkzMzM0M2EzNDMzMzQzNzMyNjQzNDY2MzUzMDM0MzEzNTMxMzUzNTM0MzUzMzYxMzMzMTMzMzAzMzMyMmY0NDQyNGM1ZjUzNTQyZjUyNGYyZjMyMmYzMjMwMzIzMzMxMzIzMjM4MmYzMDMxMzIzMzM0MzUzNjM3MzgzOTMwMzEzMgocCgIQARICEAEaEkRTUlQtODItTk9UUkFOU0ZFUgogCgUImCoQARIFCIYzEAEaEERTUk0tODItNDZyMjA2NTkKHwoFCIgnEAESBQiiLxABGg9EU1JNLTgyLTJyMjA2NTgKGwoCEAESAhABGhFEU1JNLTgyLTQyNnIyMDY2MAohCgUI3AsQARIFCKYOEAEaEURTUlUtODItVWJlemJhc2ljGFIooRYyBgigjg4QATgC"
            },
            "Accommodation": {
                "XCity": {
                    "Id": "5010",
                    "Name": "Barcelona"
                },
                "XCountry": {
                    "Id": "15",
                    "Name": "Hiszpania"
                },
                "Attributes": [
                    "location_seaside_resorts",
                    "location_city_breaks",
                    "location_near_airport",
                    "location_without_passport_from_pl",
                    "facility_facilities_for_disabled",
                    "facility_internet_access",
                    "facility_air_conditioning",
                    "activity_sport"
                ],
                "Category": 3,
                "Code": "13934",
                "ExtTripAdvisor": {
                    "count": 656,
                    "description": "https://www.tripadvisor.com/Hotel_Review-g187497-d228595-Reviews-Hotel_Silken_St_Gervasi-Barcelona_Catalonia.html?m=62467",
                    "image": "https://staticpict.merlinx.pl/tripadvisor/4.0-62467-5.svg",
                    "rating": 4,
                    "review": "https://www.tripadvisor.com/UserReview-g187497-d228595-Hotel_Silken_St_Gervasi-Barcelona_Catalonia.html?m=62467"
                },
                "Location": {
                    "Coords": [
                        41.404655,
                        2.138865
                    ],
                    "geoSearchDistance": 0
                },
                "Name": "Silken Sant Gervasi",
                "Nights": 2,
                "Room": {
                    "Id": "DBL_ST",
                    "Name": "DWUOSOBOWY"
                },
                "Service": {
                    "Id": "RO",
                    "Name": "Room Only"
                },
                "Type": {
                    "Id": "H",
                    "Name": "Hotel"
                },
                "XService": {
                    "Id": 4,
                    "Name": "Bez wyżywienia"
                }
            },
            "Transport": {
                "Flight": {
                    "AirlineType": "unspecified",
                    "Luggage": "no",
                    "Out": [
                        {
                            "Airline": {
                                "Code": "W6",
                                "Name": "Wizz Air"
                            },
                            "Departure": {
                                "Code": "WAW",
                                "Date": "2023-12-28",
                                "Name": "Warszawa",
                                "Time": "06:30"
                            },
                            "Destination": {
                                "Code": "BCN",
                                "Date": "2023-12-28",
                                "Name": "Barcelona",
                                "Time": "09:40"
                            },
                            "FlightNo": "W6"
                        }
                    ],
                    "Ret": [
                        {
                            "Departure": {
                                "Code": "BCN",
                                "Date": "2023-12-30",
                                "Name": "Barcelona",
                                "Time": "19:40"
                            },
                            "Destination": {
                                "Code": "WAW",
                                "Date": "2023-12-30",
                                "Name": "Warszawa",
                                "Time": "22:45"
                            }
                        }
                    ],
                    "Stops": "no"
                }
            },
            "Online": {
                "actions": [
                    {
                        "action": "checkstatus",
                        "fieldList": [
                            "Base.Availability",
                            "Base.Price",
                            "Accommodation.AvailableRoomsCount",
                            "Transport.*"
                        ],
                        "timestamp": "2023-12-23T19:31:53+01:00"
                    }
                ]
            }
        },
        "sortKeyValue": [
            4023
        ]
    },
    {
        "groupKeyValue": "8594",
        "Offer": {
            "Base": {
                "XCity": {
                    "Id": "12691",
                    "Name": "St. Julian'S"
                },
                "XCode": {
                    "Id": 9499,
                    "Name": "St. Julian's Bay"
                },
                "XCountry": {
                    "Id": "26",
                    "Name": "Malta"
                },
                "XRegion": {
                    "Id": "4100",
                    "Name": "Wyspa Malta"
                },
                "Availability": {
                    "base": "available",
                    "message": ""
                },
                "Catalog": "BAR RO:120_____",
                "DatasetInfo": {
                    "name": "",
                    "private": false
                },
                "DepartureLocation": {
                    "Id": "0x232323574157",
                    "Name": "Warszawa"
                },
                "Duration": 4,
                "NightsBeforeReturn": 3,
                "OfferId": "XTOPbc2eb1X_wYAAHNOYVBwWQHxAQA5oJ6kCpABCgYI6KQHEAESBgjUgQkQARp-TVBVVzJHRjIzTjFWNkpNVzVLSUdTQTJXQ01MRUNJTFoyVlNaVURJNEFDMU0xTkRLWFpRWkdNRU1HRjZOT01RQzdUUVZSSzZCWlVER0ExWjhLMVVWRzY4UEU3NDJVNzVESTcwNzBNV1VRMVVUNVVUVzJSVFZVQUNWRElRQVNNCpkBCgUI52oQAhIGCIa0BBABGocBTzU0NTQ0ODQyMmYzMTMxMzgzODM1MzQzYTM0MzIzNDMxMzUzMjMyMzAzNTMyMzQ2NjMzNjEzMzMxMzMzMjMzMzAyZjQ0NDI0YzVmNTM1NDJmNTI0ZjJmMzMyZjMyMzAzMjMzMzEzMjMyMzYyZjMwMzEzMjMzMzQzNTM2MzczODM5MzAzMTMyCiIKBQigOBABEgUI0EQQARoSRFNSVC0xNTI0LVRSQU5TRkVSCh0KAhABEgIQARoTRFNSTS0xNTI0LTQyNnIyMDgxMwohCgUIhAcQARIFCMEIEAEaEURTUk0tMTUyNC03cjIwODEyCiEKBQiIJxABEgUIoi8QARoRRFNSTS0xNTI0LTJyMjA4MTEKIwoFCNAPEAESBQiIExABGhNEU1JVLTE1MjQtVWJlemJhc2ljGPQLKO0cMgYIwMYOEAE4Ag|XTOP|Mnx8",
                "Operator": "XTOP",
                "OperatorDesc": "XTOP",
                "OriginalPrice": {
                    "FirstPerson": {
                        "amount": "1192.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2384.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Price": {
                    "FirstPerson": {
                        "amount": "1192.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2384.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Resident": "local",
                "ReturnDate": "2023-12-29",
                "StartDate": "2023-12-26",
                "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/XTOP/IMG/9499/main.webp",
                "Transfer": "yes",
                "UniqueObjectId": "XTOPbc2eb1X_wYAAHNOYVBwWQHxAQA5oJ6kCpABCgYI6KQHEAESBgjUgQkQARp-TVBVVzJHRjIzTjFWNkpNVzVLSUdTQTJXQ01MRUNJTFoyVlNaVURJNEFDMU0xTkRLWFpRWkdNRU1HRjZOT01RQzdUUVZSSzZCWlVER0ExWjhLMVVWRzY4UEU3NDJVNzVESTcwNzBNV1VRMVVUNVVUVzJSVFZVQUNWRElRQVNNCpkBCgUI52oQAhIGCIa0BBABGocBTzU0NTQ0ODQyMmYzMTMxMzgzODM1MzQzYTM0MzIzNDMxMzUzMjMyMzAzNTMyMzQ2NjMzNjEzMzMxMzMzMjMzMzAyZjQ0NDI0YzVmNTM1NDJmNTI0ZjJmMzMyZjMyMzAzMjMzMzEzMjMyMzYyZjMwMzEzMjMzMzQzNTM2MzczODM5MzAzMTMyCiIKBQigOBABEgUI0EQQARoSRFNSVC0xNTI0LVRSQU5TRkVSCh0KAhABEgIQARoTRFNSTS0xNTI0LTQyNnIyMDgxMwohCgUIhAcQARIFCMEIEAEaEURTUk0tMTUyNC03cjIwODEyCiEKBQiIJxABEgUIoi8QARoRRFNSTS0xNTI0LTJyMjA4MTEKIwoFCNAPEAESBQiIExABGhNEU1JVLTE1MjQtVWJlemJhc2ljGPQLKO0cMgYIwMYOEAE4Ag"
            },
            "Accommodation": {
                "XCity": {
                    "Id": "12691",
                    "Name": "St. Julian'S"
                },
                "XCountry": {
                    "Id": "26",
                    "Name": "Malta"
                },
                "Attributes": [
                    "location_seaside_resorts",
                    "location_near_the_center",
                    "location_without_passport_from_pl",
                    "facility_free_wifi",
                    "facility_parking",
                    "facility_for_families_with_childrens",
                    "facility_fitness_or_gym",
                    "facility_facilities_for_disabled",
                    "facility_internet_access",
                    "facility_air_conditioning",
                    "facility_night_life",
                    "activity_sport"
                ],
                "Category": 3,
                "Code": "118854",
                "ExtTripAdvisor": {
                    "count": 1071,
                    "description": "https://www.tripadvisor.com/Hotel_Review-g227101-d1052587-Reviews-St_Julian_s_Bay_Hotel-Saint_Julian_s_Island_of_Malta.html?m=62467",
                    "image": "https://staticpict.merlinx.pl/tripadvisor/3.0-62467-5.svg",
                    "rating": 3,
                    "review": "https://www.tripadvisor.com/UserReview-g227101-d1052587-St_Julian_s_Bay_Hotel-Saint_Julian_s_Island_of_Malta.html?m=62467"
                },
                "Location": {
                    "Coords": [
                        35.9151674216464,
                        14.4930868596384
                    ],
                    "geoSearchDistance": 0
                },
                "Name": "St. Julian's Bay",
                "Nights": 3,
                "Room": {
                    "Id": "DBL_ST",
                    "Name": "DWUOSOBOWY"
                },
                "Service": {
                    "Id": "RO",
                    "Name": "Room Only"
                },
                "Type": {
                    "Id": "H",
                    "Name": "Hotel"
                },
                "XService": {
                    "Id": 4,
                    "Name": "Bez wyżywienia"
                }
            },
            "Transport": {
                "Flight": {
                    "AirlineType": "unspecified",
                    "Luggage": "no",
                    "Out": [
                        {
                            "Airline": {
                                "Code": "W6",
                                "Name": "Wizz Air"
                            },
                            "Departure": {
                                "Code": "WAW",
                                "Date": "2023-12-26",
                                "Name": "Warszawa",
                                "Time": "06:30"
                            },
                            "Destination": {
                                "Code": "MLA",
                                "Date": "2023-12-26",
                                "Name": "Luqa",
                                "Time": "09:35"
                            },
                            "FlightNo": "W6"
                        }
                    ],
                    "Ret": [
                        {
                            "Departure": {
                                "Code": "MLA",
                                "Date": "2023-12-29",
                                "Name": "Luqa",
                                "Time": "11:25"
                            },
                            "Destination": {
                                "Code": "WAW",
                                "Date": "2023-12-29",
                                "Name": "Warszawa",
                                "Time": "14:20"
                            }
                        }
                    ],
                    "Stops": "no"
                }
            },
            "Online": {
                "actions": [
                    {
                        "action": "checkstatus",
                        "fieldList": [
                            "Base.Availability",
                            "Base.Price",
                            "Accommodation.AvailableRoomsCount",
                            "Transport.*"
                        ],
                        "timestamp": "2023-12-23T19:31:53+01:00"
                    }
                ]
            }
        },
        "sortKeyValue": [
            4032
        ]
    },
    {
        "groupKeyValue": "8859",
        "Offer": {
            "Base": {
                "XCity": {
                    "Id": "288",
                    "Name": "Sliema"
                },
                "XCode": {
                    "Id": 14015,
                    "Name": "Sliema Chalet"
                },
                "XCountry": {
                    "Id": "26",
                    "Name": "Malta"
                },
                "XRegion": {
                    "Id": "4100",
                    "Name": "Wyspa Malta"
                },
                "Availability": {
                    "base": "available",
                    "message": ""
                },
                "DatasetInfo": {
                    "name": "",
                    "private": false
                },
                "DepartureLocation": {
                    "Id": "0x232323574157",
                    "Name": "Warszawa"
                },
                "Duration": 4,
                "NightsBeforeReturn": 3,
                "OfferId": "XTOP214ff8X_wYAAHNOYVBwWQEKAgDGqwYrCpABCgYI6KQHEAESBgjUgQkQARp-TVBVVzJHRjIzTjFWNkpNVzVLSUdTQTJXQ01MRUNJTFoyVlNaVURJNEFDMU0xTkRLWFpRWkdNRU1HRjZOT01RQzdUUVZSSzZCWlVER0ExWjhLMVVWRzY4UEU3NDJVNzVESTcwNzBNV1VRMVVUNVVUVzJSVFZVQUNWRElRQVNNCn4KBQiIVhACEgYIwcYDEAEabU81NDU0NGQ1MzJmNDE0ZDU0NTM0ZDU0MzIzMjQyNGQyZjUyNGQ1MzQ0MzAzMDMwMzAzMDMwMmY1MjRmMmYzMzJmMzIzMDMyMzMzMTMyMzIzNjJmMzAzMTMyMzMzNDM1MzYzNzM4MzkzMDMxMzIKXwoFCLQQEAISBQjSVhABGk9PNTQ1NDRkNTMyZjMxMzgzNzMzMmY0ZDRjNDEyZjJmMzMyZjMyMzAzMjMzMzEzMjMyMzYyZjMwMzEzMjMzMzQzNTM2MzczODM5MzAzMTMyCiAKBQiAMhABEgUIwDwQARoQRFNSTS03Ny04MHIyMDYzNAofCgUIiCcQARIFCKIvEAEaD0RTUk0tNzctMnIyMDYzMwobCgIQARICEAEaEURTUk0tNzctNDI2cjIwNjM1CiEKBQjQDxABEgUIiBMQARoRRFNSVS03Ny1VQkVaQkFTSUMYTSjwGTIGCPCdDhABOAI|XTOP|Mnx8",
                "Operator": "XTOP",
                "OperatorDesc": "XTOP",
                "OriginalPrice": {
                    "FirstPerson": {
                        "amount": "1166.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2332.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Price": {
                    "FirstPerson": {
                        "amount": "1166.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2332.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Resident": "local",
                "ReturnDate": "2023-12-29",
                "StartDate": "2023-12-26",
                "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/XTOP/IMG/14015/main.webp",
                "Transfer": "yes",
                "UniqueObjectId": "XTOP214ff8X_wYAAHNOYVBwWQEKAgDGqwYrCpABCgYI6KQHEAESBgjUgQkQARp-TVBVVzJHRjIzTjFWNkpNVzVLSUdTQTJXQ01MRUNJTFoyVlNaVURJNEFDMU0xTkRLWFpRWkdNRU1HRjZOT01RQzdUUVZSSzZCWlVER0ExWjhLMVVWRzY4UEU3NDJVNzVESTcwNzBNV1VRMVVUNVVUVzJSVFZVQUNWRElRQVNNCn4KBQiIVhACEgYIwcYDEAEabU81NDU0NGQ1MzJmNDE0ZDU0NTM0ZDU0MzIzMjQyNGQyZjUyNGQ1MzQ0MzAzMDMwMzAzMDMwMmY1MjRmMmYzMzJmMzIzMDMyMzMzMTMyMzIzNjJmMzAzMTMyMzMzNDM1MzYzNzM4MzkzMDMxMzIKXwoFCLQQEAISBQjSVhABGk9PNTQ1NDRkNTMyZjMxMzgzNzMzMmY0ZDRjNDEyZjJmMzMyZjMyMzAzMjMzMzEzMjMyMzYyZjMwMzEzMjMzMzQzNTM2MzczODM5MzAzMTMyCiAKBQiAMhABEgUIwDwQARoQRFNSTS03Ny04MHIyMDYzNAofCgUIiCcQARIFCKIvEAEaD0RTUk0tNzctMnIyMDYzMwobCgIQARICEAEaEURTUk0tNzctNDI2cjIwNjM1CiEKBQjQDxABEgUIiBMQARoRRFNSVS03Ny1VQkVaQkFTSUMYTSjwGTIGCPCdDhABOAI"
            },
            "Accommodation": {
                "XCity": {
                    "Id": "288",
                    "Name": "Sliema"
                },
                "XCountry": {
                    "Id": "26",
                    "Name": "Malta"
                },
                "Attributes": [
                    "location_seaside_resorts",
                    "location_near_airport",
                    "location_without_passport_from_pl",
                    "facility_free_wifi",
                    "facility_parking",
                    "facility_for_families_with_childrens",
                    "facility_facilities_for_disabled",
                    "facility_internet_access",
                    "facility_air_conditioning",
                    "activity_bicycles",
                    "activity_sport"
                ],
                "Category": 3,
                "Code": "AMTSMT22BM",
                "ExtTripAdvisor": {
                    "count": 33,
                    "description": "https://www.tripadvisor.com/Hotel_Review-g190327-d261108-Reviews-Sliema_Chalet_Hotel-Sliema_Island_of_Malta.html?m=62467",
                    "image": "https://staticpict.merlinx.pl/tripadvisor/3.0-62467-5.svg",
                    "rating": 3,
                    "review": "https://www.tripadvisor.com/UserReview-g190327-d261108-Sliema_Chalet_Hotel-Sliema_Island_of_Malta.html?m=62467"
                },
                "Location": {
                    "Coords": [
                        35.912751,
                        14.506701
                    ],
                    "geoSearchDistance": 0
                },
                "Name": "Sliema Chalet Hotel",
                "Nights": 3,
                "Room": {
                    "Id": "RMSD000000",
                    "Name": "pokój, standardowy"
                },
                "Service": {
                    "Id": "RO",
                    "Name": "Room Only"
                },
                "Type": {
                    "Id": "H",
                    "Name": "Hotel"
                },
                "XService": {
                    "Id": 4,
                    "Name": "Bez wyżywienia"
                }
            },
            "Transport": {
                "Flight": {
                    "AirlineType": "unspecified",
                    "Luggage": "no",
                    "Out": [
                        {
                            "Airline": {
                                "Code": "W6",
                                "Name": "Wizz Air"
                            },
                            "Departure": {
                                "Code": "WAW",
                                "Date": "2023-12-26",
                                "Name": "Warszawa",
                                "Time": "06:30"
                            },
                            "Destination": {
                                "Code": "MLA",
                                "Date": "2023-12-26",
                                "Name": "Luqa",
                                "Time": "09:35"
                            },
                            "FlightNo": "W6"
                        }
                    ],
                    "Ret": [
                        {
                            "Departure": {
                                "Code": "MLA",
                                "Date": "2023-12-29",
                                "Name": "Luqa",
                                "Time": "11:25"
                            },
                            "Destination": {
                                "Code": "WAW",
                                "Date": "2023-12-29",
                                "Name": "Warszawa",
                                "Time": "14:20"
                            }
                        }
                    ],
                    "Stops": "no"
                }
            },
            "Online": {
                "actions": [
                    {
                        "action": "checkstatus",
                        "fieldList": [
                            "Base.Availability",
                            "Base.Price",
                            "Accommodation.AvailableRoomsCount",
                            "Transport.*"
                        ],
                        "timestamp": "2023-12-23T19:31:53+01:00"
                    }
                ]
            }
        },
        "sortKeyValue": [
            4054
        ]
    },
    {
        "groupKeyValue": "11016",
        "Offer": {
            "Base": {
                "XCity": {
                    "Id": "4077",
                    "Name": "Rzym"
                },
                "XCode": {
                    "Id": 22676,
                    "Name": "Donatello (Rzym)"
                },
                "XCountry": {
                    "Id": "44",
                    "Name": "Włochy"
                },
                "XRegion": {
                    "Id": "1450",
                    "Name": "Rzym"
                },
                "Availability": {
                    "base": "available",
                    "message": ""
                },
                "Catalog": "KAT",
                "DatasetInfo": {
                    "name": "",
                    "private": false
                },
                "DepartureLocation": {
                    "Id": "0x4b72616bc3b377",
                    "Name": "Kraków"
                },
                "Duration": 3,
                "NightsBeforeReturn": 2,
                "OfferId": "9d2f8d54475264b617dcdc835e9ab3d886babc6477e2f940530f27226e5ee0a6|ECCX|Mnx8",
                "Operator": "ECCX",
                "OperatorDesc": "Ecco HolidayX",
                "OriginalPrice": {
                    "FirstPerson": {
                        "amount": "1148.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2296.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Price": {
                    "FirstPerson": {
                        "amount": "1148.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    },
                    "Total": {
                        "amount": "2296.00",
                        "currency": "PLN",
                        "details": {
                            "TFGIncluded": true
                        }
                    }
                },
                "Promotion": "XXXX",
                "ReturnDate": "2023-12-27",
                "StartDate": "2023-12-25",
                "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/ECCX/IMG/3437/main.webp",
                "Transfer": "no",
                "UniqueObjectId": "9d2f8d54475264b617dcdc835e9ab3d886babc6477e2f940530f27226e5ee0a6"
            },
            "Accommodation": {
                "XCity": {
                    "Id": "4077",
                    "Name": "Rzym"
                },
                "XCountry": {
                    "Id": "44",
                    "Name": "Włochy"
                },
                "Attributes": [
                    "location_seaside_resorts",
                    "location_city_breaks",
                    "location_near_airport",
                    "location_without_passport_from_pl",
                    "facility_for_families_with_childrens",
                    "facility_kindergarten",
                    "facility_internet_access",
                    "facility_air_conditioning"
                ],
                "Category": 3,
                "Code": "3437",
                "ExtTripAdvisor": {
                    "count": 869,
                    "description": "https://www.tripadvisor.com/Hotel_Review-g187791-d231286-Reviews-Hotel_Donatello-Rome_Lazio.html?m=62467",
                    "image": "https://staticpict.merlinx.pl/tripadvisor/3.5-62467-5.svg",
                    "rating": 3.5,
                    "review": "https://www.tripadvisor.com/UserReview-g187791-d231286-Hotel_Donatello-Rome_Lazio.html?m=62467"
                },
                "Location": {
                    "Coords": [
                        41.892254,
                        12.513202
                    ],
                    "geoSearchDistance": 0
                },
                "Name": "Donatello",
                "Nights": 2,
                "Room": {
                    "Id": "6LJ",
                    "Name": "POKÓJ DWUOSOBOWY STANDARD"
                },
                "Service": {
                    "Id": "U",
                    "Name": "Bez wyżywienia"
                },
                "Type": {
                    "Id": "H",
                    "Name": "Hotel"
                },
                "XService": {
                    "Id": 4,
                    "Name": "Bez wyżywienia"
                }
            },
            "Transport": {
                "Flight": {
                    "AirlineType": "lowcost",
                    "Luggage": "no",
                    "Out": [
                        {
                            "Airline": {
                                "Code": "W6",
                                "Name": "Wizz Air Hungary"
                            },
                            "Departure": {
                                "Code": "KRK",
                                "Date": "2023-12-25",
                                "Name": "Kraków",
                                "Time": "05:30"
                            },
                            "Destination": {
                                "Code": "FCO",
                                "Date": "2023-12-25",
                                "Name": "Rome",
                                "Time": "07:35"
                            },
                            "FlightNo": "W6 5061"
                        }
                    ],
                    "Ret": [
                        {
                            "Airline": {
                                "Code": "W6",
                                "Name": "-"
                            },
                            "Departure": {
                                "Code": "FCO",
                                "Date": "2023-12-27",
                                "Name": "Rome",
                                "Time": "08:20"
                            },
                            "Destination": {
                                "Code": "KRK",
                                "Date": "2023-12-27",
                                "Name": "Kraków",
                                "Time": "10:35"
                            },
                            "FlightNo": "W6 5062"
                        }
                    ],
                    "Stops": "no"
                }
            },
            "Online": {
                "actions": [
                    {
                        "action": "checkstatus",
                        "fieldList": [
                            "Base.Availability",
                            "Base.Price",
                            "Accommodation.AvailableRoomsCount",
                            "Transport.*"
                        ],
                        "timestamp": "2023-12-23T19:31:53+01:00"
                    }
                ]
            }
        },
        "sortKeyValue": [
            4013
        ]
    },
]


const MainValues = (
    {
        values,
        setValuesType,
        selectedType
    }: MainValuesProps
) => {

    const {
        Contact,
        About
    } = values

    const specialLowerBoxes = (
        <>
            <RecommendedOffer
                customTitle={'lbl_operator_highlighted_offers'}
                isLoadingRecommendedOffers={false}
                list={object}
            />
        </>
    )

    const getValue = () => {
        switch (selectedType) {
            case Operator__Views__Types.offers:
                return <OffersView/>
            case Operator__Views__Types.about:
                return (
                    <>
                        <AboutView
                            About={About}
                        />
                        {specialLowerBoxes}
                    </>
                )
            case Operator__Views__Types.contact:
                return (
                    <>
                        <ContactView
                            Contact={Contact}
                        />
                        {specialLowerBoxes}
                    </>
                )
            default :
                return <></>
        }
    }

    return (
        <Styled.Main__Values__Holder>
            <Styled.Caruzel__Type__Tabs
                isLoadingSections={false}
            >
                {tabsTypes.map((type: Operator__Views__Types, index: number) => {

                    const isLast = index === tabsTypes.length - 1

                    const isSelected = type === selectedType

                    const onCLickHandler = () => {
                        setValuesType(type as Operator__Views__Types)
                    }

                    return (
                        <Styled.Type__Tabs
                            onClick={onCLickHandler}
                            isSelected={isSelected}
                            isLast={isLast}
                        >
                            {type}
                        </Styled.Type__Tabs>
                    )
                })}
            </Styled.Caruzel__Type__Tabs>
            {getValue()}
        </Styled.Main__Values__Holder>
    )
}

export default MainValues