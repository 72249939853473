import {useLocation, useNavigate} from "react-router-dom";
import {getValid} from "../ProtoHashConfig/protoValidation";


const useNavigation = () => {

    const navigate = useNavigate()

    const location = useLocation()

    const reRoute = (path: any) => {
        const validSearch = getValid(path, document.location.search.replace('?&search=', ''))
        if (location.pathname !== path) {
            navigate(`${path}${validSearch}`)
            window.scrollTo(0, 0)
            sessionStorage.setItem('lastPosition', '0')
        }

    }


    return reRoute
}
export default useNavigation