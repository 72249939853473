import {Styled} from "../../Offer.styles"
import React, {useState} from "react";
import NavigationButton, {buttonType} from "./NavigationButtons";
import useWCSRequests from "../../../../lib/UseHooksBookBrd/useWCSRequests";
import BrdLoader from "../../../../lib/UIBookBrd/BrdLoader";
import {useSwipeable} from "react-swipeable";
import {isMobile} from "react-device-detect";

type ImagesProps = {
    offerData: any
    style?: React.CSSProperties
}


const Images = (
    {
        offerData,
        style
    }: ImagesProps
) => {

    const {Base} = offerData

    const defaultControls = {
        requestSent: false,
        currentIndex: 0,
        imagesList: [Base.ThumbUrl]
    }

    const [sliderControls, setSliderControls] = useState<any>(defaultControls)

    const {sendPicturesRequest} = useWCSRequests()

    const toggleLoading = (bool: boolean) => {
        setSliderControls((state: any) => ({
            ...state,
            isLoading: bool
        }))
    }

    const setImagesList = (list: any[]) => {
        setSliderControls((state: any) => ({
            ...state,
            imagesList: list
        }))
    }

    const onFullScreenClickHandler = () => {
        alert('full')
    }

    const onNavigationClickHandler = (event: any, type: buttonType) => {
        if (event) {
            event?.stopPropagation()
        }
        switch (type) {
            case buttonType.prev:
                if (!sliderControls.successRequest) {
                    setSliderControls((state: any) => ({
                        ...state,
                        requestSent: true
                    }))
                    sendPicturesRequest(offerData, Base.OfferId, setImagesList, toggleLoading).then((value: any) => {
                        if (value.length !== 0) {
                            if (sliderControls.currentIndex !== 0) {
                                setSliderControls((state: any) => ({
                                    ...state,
                                    successRequest: true,
                                    currentIndex: state?.currentIndex - 1
                                }))
                            } else {
                                setSliderControls((state: any) => ({
                                    ...state,
                                    successRequest: true,
                                    currentIndex: value.length - 1
                                }))
                            }
                        }
                    })
                } else {
                    if (sliderControls.currentIndex !== 0) {
                        setSliderControls((state: any) => ({
                            ...state,
                            currentIndex: state.currentIndex - 1
                        }))
                    } else {
                        setSliderControls((state: any) => ({
                            ...state,
                            currentIndex: sliderControls.imagesList.length - 1
                        }))
                    }
                }
                break
            case buttonType.next:
                if (!sliderControls.successRequest) {
                    setSliderControls((state: any) => ({
                        ...state,
                        requestSent: true
                    }))
                    sendPicturesRequest(offerData, Base.OfferId, setImagesList, toggleLoading).then((value: any) => {
                        if (value.length !== 0) {
                            if (sliderControls.currentIndex !== value.length - 1) {
                                setSliderControls((state: any) => ({
                                    ...state,
                                    successRequest: true,
                                    currentIndex: state?.currentIndex + 1
                                }))
                            } else {
                                setSliderControls((state: any) => ({
                                    ...state,
                                    successRequest: true,
                                    currentIndex: 0
                                }))
                            }
                        }
                    })
                } else {
                    if (sliderControls.currentIndex !== sliderControls?.imagesList.length - 1) {
                        setSliderControls((state: any) => ({
                            ...state,
                            currentIndex: state?.currentIndex + 1
                        }))
                    } else {
                        setSliderControls((state: any) => ({
                            ...state,
                            currentIndex: 0
                        }))
                    }
                }
                break
        }

    }

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            const {dir} = eventData
            if (dir === 'Left') {
                onNavigationClickHandler(undefined, buttonType.next)
            }
            if (dir === 'Right') {
                onNavigationClickHandler(undefined, buttonType.prev)
            }
        },
        preventScrollOnSwipe: !isMobile,
    });

    const getShowNextButton = () => {
        if (!sliderControls?.successRequest) {
            return true
        } else {
            return sliderControls.currentIndex !== sliderControls?.imagesList.length - 1
        }
    }
    const getShowPrevButton = () => {
        return sliderControls.currentIndex !== 0
    }

    return (
        <Styled.Images__Holder
            style={style}
            {...handlers}
        >
            {Array.from({length: 5}).map((_, index: number) => {

                const currentIndex = sliderControls.currentIndex + (index - 2)

                return (
                    <Styled.Offer__Image
                        isHidden={index !== 2}
                        src={sliderControls?.imagesList[currentIndex]}
                    />
                )
            })}
            <Styled.Images__Controls__Holder
                onClick={onFullScreenClickHandler}
            >
                <NavigationButton
                    showButton={getShowPrevButton()}
                    type={buttonType.prev}
                    onClick={onNavigationClickHandler}
                />
                {sliderControls?.isLoading && (
                    <BrdLoader
                        style={{
                            margin: 'auto'
                        }}
                    />
                )}
                <NavigationButton
                    showButton={getShowNextButton()}
                    type={buttonType.next}
                    onClick={onNavigationClickHandler}
                />
            </Styled.Images__Controls__Holder>
        </Styled.Images__Holder>
    )
}
export default Images