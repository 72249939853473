import {LIGHT_THEME} from "../../Styles/Themes/Light";
import {DARK_THEME} from "../../Styles/Themes/Dark";
import {ThemeProvider} from "styled-components";
import useConfigureSite from "../UseHooksBookBrd/useConfigureSite";
import {useEffect} from "react";

type AppThemeProviderProps = {
    children: React.ReactNode
}

const AppThemeProvider = (
    {
        children
    }: AppThemeProviderProps
) => {
    const {isLightTheme} = useConfigureSite()

    useEffect(() => {
        const colorPack = !!isLightTheme ? LIGHT_THEME : DARK_THEME
        document.body.style.backgroundColor = colorPack.backgroundColor
    }, [isLightTheme])

    return (
        <ThemeProvider theme={!!isLightTheme ? LIGHT_THEME : DARK_THEME}>
            {children}
        </ThemeProvider>
    )
}


export default AppThemeProvider;
