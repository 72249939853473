import {Styled} from "../../ContactPage.styles";
import TextInput from "../../../../lib/UIBookBrd/TextInput";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {includes, isEmpty} from "lodash-es";
import {supportQuickFieldsNames} from "../../../../lib/Types";
import useBookBrdPageFunctions from "../../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import useStorage from "../../../../lib/UseHooksBookBrd/useStorage";


const messageMaxLenght = 600

const QuickSupport = () => {

    const [name, setName] = useState<string>('')

    const [mail, setMail] = useState<string>('')

    const [messageToSupport, setMessageToSupport] = useStorage('sessionStorage', 'quick_support_message', '')

    const [isChecked, setIsChecked] = useState<boolean>(false)

    const [invalidFields, setInvalidFields] = useState<supportQuickFieldsNames[]>()

    const {
        checkIfMailValid,
        sendMessageToSupport
    } = useBookBrdPageFunctions()

    const {t} = useTranslation()

    const setFieldAsValid = (fieldName: supportQuickFieldsNames) => {
        if (includes(invalidFields, fieldName)) {
            setInvalidFields((state: any) => (
                state.filter((name: supportQuickFieldsNames) => name !== fieldName)
            ))
        }
    }

    const onNameChangeHandler = (event: any) => {
        const value = event.target.value
        setName(value)
        setFieldAsValid(supportQuickFieldsNames.name)
    }

    const onNameClearHandler = () => {
        setName('')
        setFieldAsValid(supportQuickFieldsNames.name)
    }

    const onMailChangeHandler = (event: any) => {
        const value = event.target.value
        setMail(value)
        setFieldAsValid(supportQuickFieldsNames.mail)
    }

    const onMailClearHandler = () => {
        setMail('')
        setFieldAsValid(supportQuickFieldsNames.mail)
    }

    const onMessageChangeHandler = (event: any) => {
        const value = event.target.value
        if (value.length <= messageMaxLenght) {
            setMessageToSupport(value)
            setFieldAsValid(supportQuickFieldsNames.message)
        }
    }

    const onSubmitClickHandler = () => {
        const newInvalidList: supportQuickFieldsNames[] = []
        if (isEmpty(name)) {
            newInvalidList.push(supportQuickFieldsNames.name)
        }
        if (!checkIfMailValid(mail)) {
            newInvalidList.push(supportQuickFieldsNames.mail)
        }
        if (isEmpty(messageToSupport)) {
            newInvalidList.push(supportQuickFieldsNames.message)
        }
        if (!isChecked) {
            newInvalidList.push(supportQuickFieldsNames.checkbox)
        }
        if (isEmpty(newInvalidList)) {
            setInvalidFields([])
            sendMessageToSupport(name, mail, messageToSupport)
        } else {
            setInvalidFields(newInvalidList)
        }
    }

    return (
        <Styled.Quick__Support__Holder>
            <Styled.Support__Half__Title>
                {t('lbl_quick_support')}
            </Styled.Support__Half__Title>
            <Styled.Input__Name>
                {t('lbl_your_name')}
            </Styled.Input__Name>
            <TextInput
                placeholder={t('lbl_your_name')}
                autoComplete={'first-name'}
                showIcon={false}
                onClear={onNameClearHandler}
                error={includes(invalidFields, supportQuickFieldsNames.name)}
                onChange={onNameChangeHandler}
                value={{
                    label: name,
                    value: name
                }}
            />
            <Styled.Input__Name>
                {t('lbl_mail')}
            </Styled.Input__Name>
            <TextInput
                placeholder={'example@bookbrd.com'}
                error={includes(invalidFields, supportQuickFieldsNames.mail)}
                autoComplete={'email'}
                showIcon={false}
                onClear={onMailClearHandler}
                onChange={onMailChangeHandler}
                value={{
                    label: mail,
                    value: mail
                }}
            />
            <Styled.Input__Name>
                {t('lbl_message')}
            </Styled.Input__Name>
            <Styled.Text__Area
                onChange={onMessageChangeHandler}
                isError={includes(invalidFields, supportQuickFieldsNames.message)}
                placeholder={t('lbl_support_message_field')}
                value={messageToSupport}
                style={{
                    height: '300px'
                }}
            />
            <Styled.Letters_Counter__Label>
                {messageToSupport.length} / {messageMaxLenght}
            </Styled.Letters_Counter__Label>
            <Styled.Check__Box
                error={includes(invalidFields, supportQuickFieldsNames.checkbox)}
                onChange={(title: string, value: string, bool: boolean) => {
                    setIsChecked(bool)
                    setFieldAsValid(supportQuickFieldsNames.checkbox)
                }}
                isCheckedProp={isChecked}
                title={`*${t('lbl_support_consent')}`}
            />
            <Styled.Submit__Button
                onClick={onSubmitClickHandler}
                label={t('lbl_submit_and_send')}
            />
        </Styled.Quick__Support__Holder>
    )
}

export default QuickSupport