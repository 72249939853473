import {useTranslation} from "react-i18next";


export const Available_Languages = [
    'en',
    'de',
    'ua',
    'es',
    'pl'
]

export const Local_Settings_Values = {
    isFirstLogin: true,
    hideTylerToggle: false,
    PreferredCurrency: null
}

const SettingsOptions = () => {

    const {t} = useTranslation();

    const Language_Options = [
        {
            label: t('lbl_english'),
            value: 'en',
        },
        {
            label: t('lbl_german'),
            value: 'de',
        },
        {
            label: t('lbl_ukraine'),
            value: 'ua',
        },
        {
            label: t('lbl_spanish'),
            value: 'es',
        },
        {
            label: t('lbl_polish'),
            value: 'pl',
        },
    ]

    const Currency_Options = [
        {
            label: t('lbl_offer_original_price'),
            value: null,
        },
        {
            label: t('lbl_GBP'),
            value: 'GBP',
        },
        {
            label: t('lbl_USD'),
            value: 'USD',
        },
        {
            label: t('lbl_EUR'),
            value: 'EUR',
        },
    ]

    const Preferred_Country_Options = [
        {
            label: t('lbl_england'),
            value: 'UK',
        },
        {
            label: t('lbl_spain'),
            value: 'ES',
        },
        {
            label: t('lbl_poland'),
            value: 'poland',
        },
    ]

    const Preferred_Continents_Options = [
        {
            label: t('lbl_any'),
            value: undefined
        },
        {
            label: t('lbl_europe'),
            value: 'europe'
        },
        {
            label: t('lbl_asia'),
            value: 'asia'
        },
        {
            label: t('lbl_south_america'),
            value: 'south_america'
        },
        {
            label: t('lbl_north_america'),
            value: 'north_america'
        },
        {
            label: t('lbl_australia'),
            value: 'australia'
        },
        {
            label: t('lbl_africa'),
            value: 'africa'
        },

    ]

    return ({
        Preferred_Continents_Options,
        Language_Options,
        Currency_Options,
        Preferred_Country_Options
    })
}

export default SettingsOptions