import styled from "styled-components";
import FlexBox from "../../../../lib/UIBookBrd/FlexBox";
import {tylerMessagesDirectionTypes} from "../../../../lib/Types";
import Image from "../../../../lib/UIBookBrd/Image";
import {rgba} from "polished";
import Text from "../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../../../lib/UIBookBrd/Button";

const Messages__Creator__Holder = styled(FlexBox)<{ type: tylerMessagesDirectionTypes }>`
  flex-wrap: nowrap;
  flex-direction: ${({type}) => type === tylerMessagesDirectionTypes.from ? 'initial' : 'row-reverse'};
  margin-left: ${({type}) => type === tylerMessagesDirectionTypes.to && 'auto'};;
  max-width: 95%;
  width: max-content;

  &:first-of-type {
    margin-top: 10px;
  }

  &:last-of-type {
    margin-bottom: 10px;
  }
`

const Speaker__Avatar__Holder = styled(FlexBox)`
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 5px;
  overflow: hidden;
  margin-top: auto;
`
const Tyler__Image = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Text__Message__Holder = styled(FlexBox)<{ type: tylerMessagesDirectionTypes }>`
  flex-shrink: 1;
  flex-grow: 0;
  padding: 6px 9px;
  border-radius: 14px;
  background-color: ${({theme, type}) =>
          rgba(
                  type === tylerMessagesDirectionTypes.from ?
                          theme.color
                          :
                          theme.brdExclusiveColor,
                  type === tylerMessagesDirectionTypes.from ? 0.1 : 0.8
          )};
`
const Text__Message__Text = styled(Text)<{ type: tylerMessagesDirectionTypes }>`
  font-size: 15px;
  margin: auto 0;
`
const Dot__Loader = styled(FontAwesomeIcon)<{ animationDelay: number }>`
  @keyframes LoadingDot {
    0% {
      margin-top: 5px;
    }
    10% {
      margin-top: 3px;
    }
    20% {
      margin-top: 5px;
    }
    30% {
      margin-top: 7px;
    }
    40% {
      margin-top: 5px;
    }
    100% {
      margin-top: 5px;
    }
  }
  height: 7px;
  width: 7px;
  margin-top: 5px;
  margin-left: 2px;
  margin-right: 2px;
  animation: LoadingDot 1200ms infinite linear;
  animation-delay: ${({animationDelay}) => animationDelay}ms;
  color: ${({theme}) => rgba(theme.color, 0.8)};
`

const Error__Label = styled(Text)`
  font-weight: bold;
  margin: 5px auto;
  text-align: center;
`
const Error__Desc = styled(Text)`
  text-align: center;
  margin-bottom: 15px;
`
const Error__Button = styled(Button)`
  padding: 0 10px;
`
const Error__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 5px;
  flex-wrap: nowrap;
  width: calc(100% - 10px);
`
const Nerd__Mode__Message__Section__Label = styled(Text)`
  font-weight: bold;
  font-size: 15px;
  padding: 10px 0;
`
const Request__Data__Section__Name = styled(Text)`
  font-weight: bold;
  margin-left: 5px;
`
const Request__Data__Section__Value = styled(Text)`
  margin: 5px 5px 5px 15px;
`
const Section__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 10px 0;
`

export const Styled = {
    //DELETE AFTER DEV
    Section__Holder,
    Request__Data__Section__Value,
    Request__Data__Section__Name,
    Nerd__Mode__Message__Section__Label,
    //DELETE AFTER DEV
    Error__Holder,
    Error__Button,
    Error__Desc,
    Error__Label,
    Dot__Loader,
    Text__Message__Text,
    Text__Message__Holder,
    Tyler__Image,
    Speaker__Avatar__Holder,
    Messages__Creator__Holder
}