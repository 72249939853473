import {Styled} from "../ManageAccountPage.styles"
import {useTranslation} from "react-i18next";
import LoaderForAllViews from "./LoaderForAllViews";
import {isEmpty} from "lodash-es";

type ReviewsViewProps = {
    value: any
}

const ReviewsView = (
    {
        value
    }: ReviewsViewProps
) => {

    const {t} = useTranslation()

    if (!value?.isLoaded) {
        return (
            <LoaderForAllViews
                title={t('lbl_looking_for_reviews')}
            />
        )

    }

    return isEmpty(value.data) ? (
        <Styled.Empty__Reviews>
            <Styled.Empty__Reviews__Title>
                {t('lbl_no_reviews_yet')}
            </Styled.Empty__Reviews__Title>
            <Styled.Empty__Reviews__Description>
                {t('lbl_no_reviews_yet_desc')}
            </Styled.Empty__Reviews__Description>
        </Styled.Empty__Reviews>
    ) : (
        <>
            REeviews
        </>
    )
}

export default ReviewsView