import {Styled} from "../SearchTripLowerPart.styles";
import {useTranslation} from "react-i18next";
import RegionsHolder from "./RegionsHolder";
import {Region} from "../../../../lib/Types";

const WorldOfRegionsLowerPart = () => {

    const {t} = useTranslation()


    const RegionsList: Region[] = [
        {
            priority: 1,
            name: 'Barbados',
            image: 'https://www.national-geographic.pl/media/cache/slider_big/uploads/media/default/0014/64/barbados-ceny-loty-atrakcje-stolica-informacje-praktyczne.jpeg',
            description: 'Captivating Caribbean island, enchants visitors with its pristine beaches, turquoise\n' +
                '                        waters, and vibrant culture. Nestled in the eastern Caribbean, Barbados is a paradise for\n' +
                '                        sun-seekers and water enthusiasts. The island boasts a rich history reflected in its colonial\n' +
                '                        architecture and UNESCO-listed Bridgetown. Immerse yourself in the rhythm of calypso music,\n' +
                '                        savor the flavors of local cuisine, and explore the lively street markets. Barbados offers\n' +
                '                        diverse experiences, from adventurous water sports to tranquil botanical gardens. With its warm\n' +
                '                        hospitality and idyllic landscapes, Barbados beckons you to unwind and create lasting memories\n' +
                '                        in this tropical haven.',
            linkToArticle: 'https://world-of-regions.com/',
            searchData: {}
        },
        {
            priority: 2,
            name: 'Dubai',
            image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Dubai_Skylines_at_night_%28Pexels_3787839%29.jpg/640px-Dubai_Skylines_at_night_%28Pexels_3787839%29.jpg',
            description: 'Marvel in the UAE, dazzles with futuristic skyscrapers, including the iconic Burj Khalifa.',
            linkToArticle: 'https://world-of-regions.com/',
            searchData: {}
        },
        {
            priority: 3,
            name: 'Maldives',
            image: 'https://www.state.gov/wp-content/uploads/2019/07/Maldives-2112x1406.png',
            description: 'Tropical paradise in the Indian Ocean, captivates with turquoise waters, coral reefs, and overwater bungalows.',
            linkToArticle: 'https://world-of-regions.com/',
            searchData: {}
        },
        {
            priority: 4,
            name: 'Bali',
            image: 'https://ocdn.eu/images/pulscms/YjQ7MDA_/6e36da5a-1d18-4a78-a532-f5f61a7162f7.jpeg',
            description: 'Indonesian gem, enchants with lush landscapes, vibrant culture, and serene beaches. A tropical haven awaits.',
            linkToArticle: 'https://world-of-regions.com/',
            searchData: {}
        },
        {
            priority: 5,
            name: 'Madeira',
            image: 'https://images.ctfassets.net/i3kf1olze1gn/4yAljFT0ferCVoBUzYf0Rc/54692f6d7a1a581b1db28aec583a4758/madeira.jpg',
            description: 'Portuguese archipelago in the Atlantic, boasts dramatic landscapes, from lush forests to rugged coastlines. Enchanting and diverse.',
            linkToArticle: 'https://world-of-regions.com/',
            searchData: {}
        },
    ]

    return (
        <>
            <Styled.Main__Label>
                {t('lbl_discover_regions')}
            </Styled.Main__Label>
            <RegionsHolder
                list={RegionsList}
            />
        </>
    )
}

export default WorldOfRegionsLowerPart