// @ts-ignore
import styled from "styled-components";

const Input = styled.input`
  user-focus-pointer: none !important;
  pointer-events: none;
  position: relative;
  text-decoration: none;
  font-size: 13px;
  max-width: 95%;
  margin: auto 0;
  margin-left: 5%;
  flex-grow: ${(props: { hideTextInput: any; }) => props.hideTextInput ? 0 : 1};
  background-color: transparent;
  color: ${(props: { theme: any; }) => props.theme.color};
  padding: 0;
  min-height: 32px;
  max-height: 32px;
  border: none;
  outline: none;
  cursor: pointer;

  ::placeholder {
    user-select: none;
    color: ${(props: { theme: any; }) => props.theme.placeholderColor};
  }

  &:focus {
    cursor: initial;
    border: none;
    outline: none;
  }

  &:active {
    border: none;
    outline: none;
  }
`;

const TextInput = styled.div`
  cursor: pointer;
  min-width: 90px;
  transition: 500ms;
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;
  flex-wrap: nowrap;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props: { theme: any; }) => props.theme.borderColor};
  border-color: ${(props: { isChildFocused: any; theme: any; }) => props.isChildFocused ? `${props.theme.focusedInputBorderColor}` : undefined};
  outline: ${(props: { isChildFocused: any; theme: any; }) => props.isChildFocused ? `solid 1px ${props.theme.focusedInputBorderColor} ` : undefined};
  border-radius: 4px;
  height: 34px;
  color: ${(props: { theme: any; }) => props.theme.borderColor};

  &:hover {
    transition: 500ms;
    outline: ${(props: { isChildFocused: any; theme: any; }) => props.isChildFocused ? undefined : `solid 1px ${props.theme.borderColor} `};
  }
`
const ClearButtonHolder = styled.div`
  z-index: 1000;
  height: 12px;
  width: 12px;
  display: flex;
  flex-wrap: nowrap;
  margin: 10px 5px;

  &:hover {
    background-color: #797979;
    border-radius: 15px;
  }
`
const SearchIconHolder = styled.div`
  display: flex;
  height: ${(props: { showIcon: any }) => props.showIcon ? '33px' : `15px`};
  width: ${(props: { showIcon: any }) => props.showIcon ? '26px' : `15px`};
`

const InputHolder = styled.div`
  box-sizing: border-box;
  min-width: inherit;
  max-width: inherit;
  width: inherit;
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
`
export const Styled = {
    InputHolder,
    SearchIconHolder,
    TextInput,
    Input,
    ClearButtonHolder,
};