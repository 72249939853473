import {Styled} from "../Messages.styles";
import {tylerMessagesDirectionTypes} from "../../../../../lib/Types";
import TylerAvatar from "../MessageCreator/TylerAvatar";
import {useTranslation} from "react-i18next";
import useTylerBot from "../../../Handlers/Hook";

const ErrorMessage = () => {

    const {t} = useTranslation()

    const {errorClickHandler} = useTylerBot()

    return (
        <Styled.Messages__Creator__Holder
            type={tylerMessagesDirectionTypes.from}
            style={{
                width: '100%',
            }}
        >
            <TylerAvatar/>
            <Styled.Text__Message__Holder
                type={tylerMessagesDirectionTypes.from}
                style={{
                    width: '100%'
                }}
            >
                <Styled.Error__Holder>
                    <Styled.Error__Label>
                        {t('lbl_error_tyler')}
                    </Styled.Error__Label>
                    <Styled.Error__Desc>
                        {t('lbl_error_tyler_desc')}
                    </Styled.Error__Desc>
                    <Styled.Error__Button
                        onClick={errorClickHandler}
                        label={t('lbl_retry')}
                    />
                </Styled.Error__Holder>
            </Styled.Text__Message__Holder>
        </Styled.Messages__Creator__Holder>
    )
}

export default ErrorMessage