import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Image from "../../lib/UIBookBrd/Image";
import SkeletonBox from "../../lib/UIBookBrd/SkeletonBox/SkeletonBox";
import {isMobileOnly} from "react-device-detect";
import Text from "../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";
import {buttonType} from "./Parts/Images/NavigationButtons";

const Offer = styled(FlexBox)`
  box-shadow: 0px 0px 4px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  min-width: ${isMobileOnly ? 'calc(100vw - 30px)' : '300px'};
  max-width: ${isMobileOnly ? 'unset' : '300px'};
  flex-direction: column;
  flex-wrap: nowrap;
  border-radius: 5px;
  overflow: hidden;
`
const Images__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;
  height: 190px;
  width: 100%;
`
const Offer__Image = styled(Image)<{ isHidden: boolean }>`
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: ${({isHidden}) => isHidden ? '0' : undefined};;
  min-width: ${({isHidden}) => isHidden ? '0px' : '100%'};
  position: ${({isHidden}) => isHidden ? 'absolute' : 'initial'};
  visibility: ${({isHidden}) => isHidden ? 'hidden' : 'initial'};
  z-index: ${({isHidden}) => isHidden ? '-999' : '1'};
  width: ${({isHidden}) => isHidden ? '1px' : '100%'};
  height: ${({isHidden}) => isHidden ? '1px' : '100%'};;
`
const Loading__Image = styled(SkeletonBox)`
  max-width: unset;
`
const Offer__Title = styled(Text)<{ enableWrap: boolean, isClickAble: boolean }>`
  font-size: 16px;
  white-space: ${({enableWrap}) => enableWrap ? 'wrap' : 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  cursor: ${({isClickAble}) => isClickAble && 'pointer'};;;

  &:hover {
    text-decoration: ${({isClickAble}) => isClickAble && 'underline'};
  }
`
const Title__Holder = styled(FlexBox)`
  margin: 3px 6px 15px 6px;
  flex-wrap: nowrap;
`
const Details__Holder = styled(FlexBox)`
  margin: 0 6px;
  gap: 8px 0;
  flex-direction: column;
`
const Details__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  margin-right: 5px;
  width: 17px;
  margin-top: 1px;
  height: 14px;
`
const Location__Label = styled(Text)`
  margin: 8px 5px 0 6px;
  font-size: 13px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Details__Text = styled(Text)<{ isLink: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${({isLink}) => isLink ? 'pointer' : 'default'};

  &:hover {
    text-decoration: ${({isLink}) => isLink ? 'underline' : undefined};
  }
`
const Details__Href = styled.a`
  font-size: 13px;
  color: ${({theme}) => theme.color};
  position: absolute;
  z-index: -999;
  visibility: hidden;
  width: 1px;
  height: 1px;
`
const Pricing__Holder = styled(FlexBox)`
  margin: 15px 6px 5px 6px;
`
const Buttons__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: 0 6px;
  margin-bottom: 5px;
`
const Images__Controls__Holder = styled(FlexBox)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  align-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
`
const Previous__Button__Holder = styled(FlexBox)`
`

const Button__Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  margin: auto;
  color: ${({theme}) => theme.color};
  transition: scale 200ms;
`

const Navigation__Button = styled(FlexBox)<{ type: buttonType, showButton: boolean }>`
  border-radius: 50%;
  z-index: 5;
  visibility: ${({showButton}) => !showButton && 'hidden'};
  top: calc(50% - 19.5px);
  right: ${({type}) => type === buttonType.next && '5px'};
  left: ${({type}) => type === buttonType.prev && '5px'};
  cursor: pointer;
  position: absolute;
  height: 39px;
  width: 39px;
  box-sizing: border-box;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.4)};
  border: 1px solid ${({theme}) => theme.color};
  transition: scale 200ms;

  &:hover {
    background-color: ${({theme}) => rgba(theme.backgroundColor, 0.6)};
  }
`
const Offer__Link = styled.a`
  position: absolute;
  visibility: hidden;
  z-index: -999;
  width: 1px;
  overflow: hidden;
  height: 1px;
`
export const Styled = {
    Offer__Link,
    Button__Icon,
    Details__Href,
    Navigation__Button,
    Previous__Button__Holder,
    Images__Controls__Holder,
    Buttons__Holder,
    Pricing__Holder,
    Location__Label,
    Details__Text,
    Details__Icon,
    Title__Holder,
    Details__Holder,
    Loading__Image,
    Offer__Title,
    Images__Holder,
    Offer__Image,
    Offer
}