import {Styled} from "./FilterHolder.styles"
import React, {useState} from "react";
import Drawer from "../../../../../lib/UIBookBrd/Drawer";
import DurationPicker from "../../../../../Components/DurationPicker";
import {
    BOARD_FIELD,
    DATE_RANGE_FIELD,
    DURATION_FIELD,
    ORIGIN_FIELD,
    PARTICIPANTS_FIELD,
    PRICE_FIELD,
    RATING_FIELD,
    TO_FIELD,
    TRANSPORT_FIELD
} from "../../../../../lib/DefaultFormikValue/DefaultSearchFormValues";
import {useTranslation} from "react-i18next";
import OutBoundPicker from "../../../../../Components/OutBoundPicker";
import DestinationPicker from "../../../../../Components/DestinationPicker";
import DatePicker from "../../../../../Components/DatePicker";
import ParticipantsPicker from "../../../../../Components/ParticipantsPicker";
import useLocalData from "../../../../../lib/UseHooksBookBrd/useSearchBaseData";
import BoardPicker from "../../../../../Components/BoardPicker";
import RatingPicker from "../../../../../Components/RatingPicker";
import {isMobileOnly} from "react-device-detect";
import TransportPicker from "../../../../../Components/TransportPicker";
import {GET_TRANSPORT_OPTIONS} from "../../TripSearchFunctions";
import useSiteConfig from "../../../../../lib/UseHooksBookBrd/useSiteConfig";
import PriceSlider from "../../../../../Components/PriceSlider";
import {isEmpty} from "lodash-es";
import useFilterFormValue from "../../../../../lib/UseHooksBookBrd/useFilterFormValue";
import useSearchFormValues from "../../../../../lib/UseHooksBookBrd/useSearchFormValues";
import useV5OfferListRequest from "../../../../../lib/UseHooksBookBrd/useV5OfferListRequest";
import useTripResultsValues from "../../useTripResultsValues";

type FiltersForTripResultsProps = {}

const FiltersForTripResults = (
    {}: FiltersForTripResultsProps
) => {

    const [modalElement, setModalElement] = useState<any | null>(null);

    const {searchBaseData} = useLocalData()

    const {t} = useTranslation();

    const {
        filterFormValue,
        setFilterFormValue,
        clearAllValues
    } = useFilterFormValue()

    const {
        state,
        setOptionList,
        toggleIsLoadingNew,
        setImagesControl,
        setOpenedList,
        showFiltersHandler
    } = useTripResultsValues()

    const {
        searchFormValue,
        setSearchFormValue,
    } = useSearchFormValues()

    const {SendRequestForOfferList} = useV5OfferListRequest(searchFormValue, filterFormValue)

    const {highlightDestination} = useSiteConfig()

    const onFiltersClose = () => {
        SendRequestForOfferList(toggleIsLoadingNew, setOptionList, "OfferList", undefined, 'OfferList', undefined)
        setImagesControl({})
        setOpenedList([])
        showFiltersHandler(false)
    }


    const content = (
        <Styled.FiltersContentHolder>
            {/*New Search Label*/}
            <Styled.Section_Type_Label>
                {t('lbl_new_search')}
            </Styled.Section_Type_Label>
            {/*OutBound picker*/}
            <Styled.FilterHolderStyles>
                <Styled.Type__Label>
                    {t('lbl_outbound_picker')}
                </Styled.Type__Label>
                <OutBoundPicker
                    appendTo={document.querySelector('.drawer-root')}
                    formikValue={searchFormValue[ORIGIN_FIELD]}
                    setFormikValues={setSearchFormValue}
                />
            </Styled.FilterHolderStyles>
            {/*Destination picker */}
            <Styled.FilterHolderStyles>
                <Styled.Type__Label>
                    {t('lbl_destination_picker')}
                </Styled.Type__Label>
                <DestinationPicker
                    appendTo={document.querySelector('.drawer-root')}
                    highLightedOption={highlightDestination}
                    values={!isEmpty(searchBaseData) ? searchBaseData[TO_FIELD] ? searchBaseData[TO_FIELD]?.countryandregion : {} : {}}
                    formikValue={searchFormValue[TO_FIELD]}
                    setFormikValues={setSearchFormValue}
                />
            </Styled.FilterHolderStyles>
            {/*Date picker*/}
            <Styled.FilterHolderStyles>
                <Styled.Type__Label>
                    {t('lbl_date_picker')}
                </Styled.Type__Label>
                <DatePicker
                    appendTo={document.querySelector('.drawer-root')}
                    formikValue={searchFormValue[DATE_RANGE_FIELD]}
                    setFormikValues={setSearchFormValue}
                />
            </Styled.FilterHolderStyles>
            {/*Duration picker*/}
            <Styled.FilterHolderStyles>
                <Styled.Type__Label>
                    {t('lbl_duration_picker')}
                </Styled.Type__Label>
                <DurationPicker
                    appendTo={document.querySelector('.drawer-root')}
                    formikValue={searchFormValue[DURATION_FIELD]}
                    setFormikValues={setSearchFormValue}
                />
            </Styled.FilterHolderStyles>
            {/*Participant picker*/}
            <Styled.FilterHolderStyles>
                <Styled.Type__Label>
                    {t('lbl_participant_picker')}
                </Styled.Type__Label>
                <ParticipantsPicker
                    appendTo={document.querySelector('.drawer-root')}
                    formikValue={searchFormValue[PARTICIPANTS_FIELD]}
                    setFormikValues={setSearchFormValue}
                />
            </Styled.FilterHolderStyles>
            {/*Filters Label*/}
            <Styled.Section_Type_Label>
                {t('lbl_filter_options')}
            </Styled.Section_Type_Label>
            {/*Board picker*/}
            <Styled.FilterHolderStyles>
                <Styled.Type__Label>
                    {t('lbl_transport_type_picker')}
                </Styled.Type__Label>
                <TransportPicker
                    isSelectMode={false}
                    filterFormValue={!!filterFormValue && filterFormValue[TRANSPORT_FIELD]}
                    setFilterValue={setFilterFormValue}
                    options={GET_TRANSPORT_OPTIONS(t)}
                />
            </Styled.FilterHolderStyles>
            <Styled.FilterHolderStyles>
                <Styled.Type__Label>
                    {t('lbl_board')}
                </Styled.Type__Label>
                <BoardPicker
                    isSelectMode={false}
                    filterFormValue={!!filterFormValue && filterFormValue[BOARD_FIELD]}
                    setFilterValue={setFilterFormValue}
                    searchBaseValue={searchBaseData ? searchBaseData[BOARD_FIELD] : {}}
                />
            </Styled.FilterHolderStyles>
            {/*RatingPicker */}
            <Styled.FilterHolderStyles>
                <Styled.Type__Label>
                    {t('lbl_rating_picker')}
                </Styled.Type__Label>
                <RatingPicker
                    isSelectMode={false}
                    filterFormValue={!!filterFormValue && filterFormValue[RATING_FIELD]}
                    setFilterValue={setFilterFormValue}
                />
            </Styled.FilterHolderStyles>
            <Styled.FilterHolderStyles>
                <Styled.Type__Label>
                    {t('lbl_first_person_price_range')}
                </Styled.Type__Label>
                <PriceSlider
                    filterFormValue={!!filterFormValue && filterFormValue[PRICE_FIELD]}
                    setFilterValue={setFilterFormValue}
                />
            </Styled.FilterHolderStyles>
        </Styled.FiltersContentHolder>
    )
    const controls = (
        <Styled.Controls__Buttons__Holder>
            <Styled.Control__Button
                onClick={clearAllValues}
                label={t('lbl_clear_filters')}
            />
            <Styled.Control__Button
                onClick={onFiltersClose}
                label={t('lbl_apply')}
            />
        </Styled.Controls__Buttons__Holder>
    )
    return (
        <>
            <Drawer
                ref={setModalElement}
                id={'filters_drawer'}
                title={t('lbl_filters_for_trip_results_page')}
                position={'left'}
                contentStyles={{width: isMobileOnly ? '100vw' : undefined}}
                isOpen={state?.filters?.isOpen}
                controls={controls}
                onClose={onFiltersClose}
            >
                {content}
            </Drawer>
        </>
    )

}
export default FiltersForTripResults