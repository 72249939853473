import React from 'react'
import {Styled} from './MainPage.styles'
import TypeOfSearchSelect from "../../Components/TypeOfSearchSelect";
import Footer from "../../Components/Footer";
import PageHolder from "../../lib/UIBookBrd/PageHolder";
import usePagesConfigs from "../../lib/PagesConfigs";

type MainPageProps = {
    searchPanel: React.ReactNode
    advertBoxes?: React.ReactNode
}
const MainPage = (
    {
        searchPanel,
        advertBoxes
    }: MainPageProps) => {

    const {
        availableSearchPages,
        currentlyShown
    } = usePagesConfigs()

    return (
        <>
            <Styled.MainPageBackground>
                <Styled.MainPage
                    backgroundImage={`url('${currentlyShown?.backgroundImage}')`}
                >
                    <PageHolder>
                        <Styled.TypeOfSearchHolder
                        >
                            {Object.keys(availableSearchPages).map((item: any) => {
                                const label = item
                                const navigation = availableSearchPages[item].Navigate
                                return (
                                    <TypeOfSearchSelect
                                        key={`type_of_search_${label}`}
                                        label={label}
                                        navigation={navigation}
                                    />
                                )
                            })}
                        </Styled.TypeOfSearchHolder>
                        {searchPanel}
                    </PageHolder>
                </Styled.MainPage>
                {advertBoxes}
                <PageHolder>
                    <Footer/>
                </PageHolder>
            </Styled.MainPageBackground>
        </>
    )
}
export default MainPage