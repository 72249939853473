import RoundTylerToggle from "./Toggles/RoundTylerToggle";
import React from "react";
import Context from "./Context";
import BotContentHolders from "./BotChatContentHolder";
import AdditionalModals from "./AdditionalModals";

const TylerBot = () => {

    return (
        <Context>
            <>
                <RoundTylerToggle/>
                <BotContentHolders/>
                <AdditionalModals/>
            </>
        </Context>
    )
}

export default TylerBot