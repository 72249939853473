import React from "react";
import {Styled} from "../HeaderForManageAccount.styles";

type ProfilePhotoProps = {
    image: string,
    name: string
    backgroundColor: string
    isDeleteBackground: boolean
    onProfileClick: (event: any) => void
}

const ProfilePhoto = (
    {
        name,
        backgroundColor,
        isDeleteBackground,
        onProfileClick,
        image
    }: ProfilePhotoProps
) => {


    return (
        <>
            {image ? (
                <Styled.Main_Account__Image
                    onClick={onProfileClick}
                    customColor={backgroundColor}
                    deleteBackground={isDeleteBackground}
                    src={image}
                />
            ) : (
                <Styled.Main__Account__No__Image
                    customColor={backgroundColor}
                >
                    <Styled.Main__Account__No__Image__Letter>
                        {name[0].toUpperCase()}
                    </Styled.Main__Account__No__Image__Letter>
                </Styled.Main__Account__No__Image>
            )}
        </>
    )
}

export default ProfilePhoto