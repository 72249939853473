enum OrderStatusEnum {
    approved = 'approved',
    declined = 'declined',
    notFound = 'notFound',
    pending = 'pending',
    error = 'error'
}

enum FieldsForOfferDetailsParams {
    hotel = 'Hotel',
    from = 'From',
    to = 'To',
    board = 'Board',
    room = 'Room',
    participants = 'Participants',
    departure = 'Departure',
    offerId = 'offerId'
}

enum OfferDetailsSectionTypes {
    details = 'OfferDetailsHolder',
    weather = 'OfferWeatherDetails',
    region = 'OfferRegionDetails',
    reviews = 'OfferReviewsRatingDetails',
    alternatives = 'OfferAlternativeOffer',
    advancedDetails = 'OfferAdvancedDetails'
}

enum linkTypes {
    pathname = 'path',
    link = 'link'
}

enum OrderPageTypeEnum {
    find = 'find',
    manage = 'manage',
    status = 'status'
}

enum FindOrderFieldsType {
    orderCode = 'orderCode',
    payerName = 'payerName'
}

enum supportPageType {
    main = 'main',
    faq = 'faq',
    support = 'support'
}

enum supportQuickFieldsNames {
    name = 'name',
    mail = 'mail',
    message = 'message',
    checkbox = 'checkbox'
}

enum accountPageType {
    own = 'own',
    other = 'other'
}

enum dropFileType {
    jpg = '.jpg',
    gif = ".gif",
    png = '.png'
}

enum userPageViews {
    myTrips = 'MyTrips',
    main = 'Main',
    reviews = 'Reviews',
    about = 'About'
}

enum WindowSizeType {
    small = 'SMALL',
    wide = 'WIDE'
}

enum checkBoxType {
    circle = 'circle',
    square = 'square'
}

enum tylerMessagesDirectionTypes {
    from = 'from',
    to = 'to'
}

type tylerResponseType = 'text' | 'action'

interface alternativesData {
    requestSent?: boolean
    errors?: string
    isLoading?: boolean
    offers?: any[] | undefined
}

interface recommendedOfferType {
    image?: string,
    onClick?: (event: any) => void
    label?: string
    additionalDesc?: string
    price?: { amount: number, currency: string }
}

interface bestHotelsType {
    offer: any
}

interface Region {
    priority: number,
    name: string
    image: string
    description: string
    linkToArticle: string
    searchData: any
}


interface tylerResponse {
    responseMessage: string
    sessionKey: string
    responseType: tylerResponseType
    searchStruct: any
    timeStamp: string
    language: string
}

interface tylerMessage {
    request?: any
    directionType: tylerMessagesDirectionTypes,
    text: string
}

export {
    tylerMessagesDirectionTypes,
    checkBoxType,
    OfferDetailsSectionTypes,
    linkTypes,
    FieldsForOfferDetailsParams,
    WindowSizeType,
    userPageViews,
    dropFileType,
    accountPageType,
    supportQuickFieldsNames,
    supportPageType,
    OrderPageTypeEnum,
    OrderStatusEnum,
    FindOrderFieldsType
}
export type {
    tylerResponse,
    Region,
    tylerMessage,
    bestHotelsType,
    alternativesData,
    recommendedOfferType

}
