import Drawer from "../../../../lib/UIBookBrd/Drawer";
import {useTranslation} from "react-i18next";
import {Styled} from "../../ManageAccountPage.styles";
import InputsForImages from "./InputsForImages";
import React, {useState} from "react";
import ProfilePhoto from "../Components/ProfilePhoto";
import AdditionalSwitches from "./AdditionalSwitches";
import ColorInput from "../../../../lib/UIBookBrd/ColorInput";
import FlexBox from "../../../../lib/UIBookBrd/FlexBox";
import HeaderForManageAccount from "../index";
import {accountPageType} from "../../../../lib/Types";
import GoBackToSettings from "../Components/GoBackToSettings";

type UserSettingsProps = {
    state: any
    setState: any
    isOpen: boolean
    onClose: () => void
}

const UserSettings = (
    {
        state,
        setState,
        isOpen,
        onClose
    }: UserSettingsProps
) => {

    const [isQuickPreview, setIsQuickPreview] = useState<boolean>(false)

    const {t} = useTranslation()

    const oChangeBackgroundImage = (event: any) => {
        const newValue = event.target.value
        setState((state: any) => ({
            ...state,
            userBackgroundImage: newValue
        }))
    }

    const onClearBackgroundImage = () => {
        setState((state: any) => ({
            ...state,
            userBackgroundImage: ''
        }))
    }

    const onDisableBackgroundColor = (bool: boolean) => {
        setState((state: any) => ({
            ...state,
            deleteBackground: bool
        }))
    }

    const onProfileBackgroundColorChange = (event: any) => {
        const color = event.target.value

        setState((state: any) => ({
            ...state,
            userBackgroundColor: color
        }))
    }

    const onQuickPreviewClick = () => {
        setIsQuickPreview(!isQuickPreview)
    }

    const controls = (
        <Styled.Controls__Holder>
            <Styled.Controls__Button
                onClick={onQuickPreviewClick}
                label={t(!isQuickPreview ? 'lbl_quick_preview' : 'lbl_back_to_settings')}
            />
            <Styled.Controls__Button
                label={t('lbl_submit_changes')}
            />
        </Styled.Controls__Holder>
    )

    return (
        <Drawer
            onClose={onClose}
            position={'right'}
            isOpen={isOpen}
            title={t('lbl_user_settings')}
            controls={controls}
            contentStyles={{
                width: '100vw',
                maxWidth: '800px'
            }}
        >
            <Styled.Settings__Holder>
                {!isQuickPreview ? (
                    <>
                        <Styled.Settings__Section__Title>
                            {t('lbl_user_background_image')}
                        </Styled.Settings__Section__Title>
                        <Styled.User__Background__Image
                            src={state?.userBackgroundImage ?? ''}
                        />
                        <InputsForImages
                            onClear={onClearBackgroundImage}
                            onChange={oChangeBackgroundImage}
                            value={state?.userBackgroundImage}
                        />
                        <Styled.Settings__Section__Title>
                            {t('lbl_user_profile_image')}
                        </Styled.Settings__Section__Title>
                        <Styled.Profile__Photo__Holder>
                            <ProfilePhoto
                                image={state?.userImage}
                                name={state.userName}
                                isDeleteBackground={state?.deleteBackground}
                                backgroundColor={state?.userBackgroundColor}
                                onProfileClick={(event: any) => null}
                            />
                        </Styled.Profile__Photo__Holder>
                        <InputsForImages
                            onClear={onClearBackgroundImage}
                            onChange={oChangeBackgroundImage}
                            value={state?.userBackgroundImage}
                        />
                        <Styled.Settings__Section__Title>
                            {t('lbl_user_profile_background')}
                        </Styled.Settings__Section__Title>
                        <AdditionalSwitches
                            onChange={onDisableBackgroundColor}
                            state={state?.deleteBackground}
                            label={t(state?.deleteBackground ? 'lbl_enable_background_color' : 'lbl_disable_background_color')}
                        />
                        <FlexBox
                            style={{
                                opacity: state?.deleteBackground ? 0.6 : 1.0
                            }}
                        >
                            <ColorInput
                                onChange={onProfileBackgroundColorChange}
                                color={state?.userBackgroundColor}
                                disabled={state?.deleteBackground}
                            />
                        </FlexBox>
                    </>
                ) : (
                    <>
                        <GoBackToSettings
                            onClick={onQuickPreviewClick}
                        />
                        <Styled.Settings__Section__Title>
                            {t('lbl_profile_preview')}
                        </Styled.Settings__Section__Title>
                        <HeaderForManageAccount
                            type={accountPageType.other}
                            specialState={state}
                        />
                        <Styled.Settings__Section__Title>
                            {t('lbl_review_preview')}
                        </Styled.Settings__Section__Title>
                        {/*<ReviewBox value={{}}/>*/}
                    </>
                )}
            </Styled.Settings__Holder>
        </Drawer>
    )
}

export default UserSettings