// @ts-ignore
import styled from "styled-components";
import Button from "../../../lib/UIBookBrd/Button";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../../lib/UIBookBrd/Text";
import Image from "../../../lib/UIBookBrd/Image";
import {isMobileOnly} from "react-device-detect";

const SearchPanel = styled.div<{ isSetMargin: boolean }>`
  padding: 2px;
  border-radius: 4px;
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({isSetMargin}) => isSetMargin && '138px'};
`;

const SearchPanelEssentialComponents = styled.div`
  flex-direction: ${isMobileOnly ? 'column' : 'initial'};
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
  gap: 2px 4px;
  display: flex;
  flex-wrap: wrap;
`
////

const DestinationHolder = styled(FlexBox)`
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  border-radius: 5px;
  @media screen and (min-width: 1090px) {
    max-width: calc(200px - 4px);
    width: calc(200px - 4px);;
  }
  @media screen and (max-width: 1090px) {
    width: calc(33.3% - 2px);
    max-width: calc(33.3% - 2px);
  }
  @media screen and (max-width: 599px) {
    width: calc(50% - 2px);
    max-width: calc(50% - 2px);
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    min-width: 100%;
  }
  width: ${isMobileOnly && '100% !important'};
  max-width: ${isMobileOnly && '100% !important'};
  flex-grow: 1;
`

const CalendarHolder = styled(FlexBox)`
  @media screen and (min-width: 1090px) {
    max-width: 180px;
  }
  @media screen and (max-width: 1090px) {
    width: calc(33.3% - 4px);
    max-width: calc(33.3% - 4px);
    min-width: calc(33.3% - 4px);
  }
  @media screen and (max-width: 599px) {
    width: calc(50% - 4px);
    max-width: calc(50% - 2px);
    min-width: calc(50% - 2px);
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  width: ${isMobileOnly && '100% !important'};
  max-width: ${isMobileOnly && '100% !important'};
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  border-radius: 5px;
  flex-wrap: nowrap;
`

const ParticipantsHolder = styled(FlexBox)`
  @media screen and (max-width: 400px) {
    width: 100%;
    min-width: 100%;
  }
  @media screen and (max-width: 1090px) {
    width: calc(33.3% - 2px);
    max-width: calc(33.3% - 2px);
  }
  @media screen and (max-width: 599px) {
    width: calc(50% - 2px);
    max-width: calc(50% - 2px);
  }
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  border-radius: 5px;
  width: ${isMobileOnly && '100% !important'};
  max-width: ${isMobileOnly && '100% !important'};
`

const DurationHolder = styled(FlexBox)`
  @media screen and (max-width: 400px) {
    width: 100%;
    min-width: 100%;
  }
  @media screen and (max-width: 1090px) {
    width: calc(33.3% - 2px);
    max-width: calc(33.3% - 2px);
  }
  @media screen and (max-width: 599px) {
    width: calc(50% - 2px);
    max-width: calc(50% - 2px);
  }
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  border-radius: 5px;
  width: ${isMobileOnly && '100% !important'};
  max-width: ${isMobileOnly && '100% !important'};
`

const ButtonHolder = styled(FlexBox)`

  @media screen and (max-width: 1090px) {
    width: calc(33.3% - 4px);
    max-width: calc(33.3% - 4px);
  }
  @media screen and (max-width: 599px) {
    width: calc(50% - 2px);
    max-width: calc(50% - 2px);
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    min-width: 100%;
  }
  flex-grow: 1;
  width: ${isMobileOnly && '100% !important'};
  max-width: ${isMobileOnly && '100% !important'};
`

/////
const ApplyButton = styled(Button)`
  height: 34px;
  width: 100%;
`
const SearchPanel__Holder = styled(FlexBox)`
  width: calc(100% - 12px);
  margin: 0 5px;
  flex-direction: column;
`
const Previous__Search__Label = styled(Text)`
  text-shadow: 2px 2px 2px ${({theme}) => theme.backgroundColor};;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
`

const Previous__Search__Boxes__Holder = styled(FlexBox)`
  gap: 10px;
  flex-wrap: nowrap;
  overflow: scroll;
  width: 100%;
  padding-bottom: 10px;
  height: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`
const Close_icon = styled(FontAwesomeIcon)`
  margin-top: 4px;
  margin-right: 6px;
  width: ${isMobileOnly ? '20px' : '14px'};
  height: ${isMobileOnly ? '20px' : '14px'};
  color: ${({theme}) => theme.color};
`
const Last__Search__Box = styled(FlexBox)`
  cursor: pointer;
    //box-shadow: 0px 0px 6px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  border-radius: 10px;
  background-color: ${({theme}) => theme.backgroundColor};
  min-width: 300px;
  max-width: 300px;
  height: auto;
  flex-wrap: nowrap;
  flex-direction: column;
`

const Close_Icon_Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  height: ${isMobileOnly ? '21px' : '15px'};
  width: 100%;


`
const Close_Icon_Positioner = styled(FlexBox)`
  width: calc(100% - 14px);
`

const Details_Holder = styled(FlexBox)`
  padding: 12px;
  padding-top: 3px;
  flex-wrap: nowrap;
`
const Image_Last_Search = styled(Image)`
  user-select: none;
  -webkit-user-drag: none;
  object-fit: cover;
  height: 70px;
  width: 70px;
`
const Details_Values = styled(FlexBox)`
  user-select: none;
  height: 70px;
  width: calc(100% - 12px - 70px);
  margin-left: 12px;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Destination__Label__Holder = styled(FlexBox)`
  width: 100%;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const PlaneIcon = styled(FontAwesomeIcon)`
  margin: auto 5px;
  color: ${({theme}) => theme.color};
  font-size: 11px;
`
const AdultIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 2px;
`
const KidIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 1px;
`
const Participants_Duration_Label = styled(Text)`
  padding: 8px 0;
`
const Dates_Label = styled(Text)`
  white-space: nowrap;
`
const Location__Label = styled(Text)<{ customMaxWidth: number, customMinWidth?: number }>`
  min-width: ${({customMinWidth}) => customMinWidth}px;
  max-width: ${({customMaxWidth}) => customMaxWidth}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const InformationIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  text-align: right;
  margin-bottom: auto;
  color: ${(props: { theme: any; }) => props.theme.circleInfoColor};
  font-size: 10px;
`
const Location__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
`
export const Styled = {
    ApplyButton,
    SearchPanel,
    SearchPanelEssentialComponents,
    DestinationHolder,
    CalendarHolder,
    ParticipantsHolder,
    DurationHolder,
    ButtonHolder,
    SearchPanel__Holder,
    Previous__Search__Label,
    Previous__Search__Boxes__Holder,
    Last__Search__Box,
    Close_Icon_Holder,
    Close_Icon_Positioner,
    Close_icon,
    Details_Holder,
    Image_Last_Search,
    Details_Values,
    Destination__Label__Holder,
    PlaneIcon,
    AdultIcon,
    KidIcon,
    Participants_Duration_Label,
    Dates_Label,
    Location__Label,
    InformationIcon,
    Location__Holder
};