import React from 'react'
import {Styled} from './AccountsPage.styles';

type MainPageProps = {
    pageContents: any
}
export const AccountsPage = ({pageContents}: MainPageProps) => {
    return (
        <Styled.FlexBox>
            {pageContents}
        </Styled.FlexBox>
    )
}