import {css} from 'styled-components';

const DatePickerStyles = css`
  .DateRangePicker, .SingleDatePicker {
    position: relative;
    display: inline-flex;
    height: auto !important;
    width: 100%;

    & > div {
      width: 100%;
    }
  }

  .DateRangePicker_picker .DayPicker_focusRegion {
    padding-bottom: 50px;
  }

  .DayPickerNavigation__horizontal {
    @media (max-width: 618px) {
      top: 35px;
    }
  }


  .DateRangePicker_picker, .SingleDatePicker_picker {
    top: 34px !important;
    border-radius: 5px !important;
    z-index: 9000 !important;
  }

  .DateRangePicker_picker__portal, .SingleDatePicker_picker__portal {
    top: 0 !important;
  }

  .DateRangePickerInput, .SingleDatePickerInput {
    display: flex;
    align-items: center;
    width: 100%;
    height: 34px;
    border-radius: 5px !important;
    border: 1px solid ${({theme}) => theme.borderColor};
    transition: all 0.2s ease;
    transition-property: box-shadow, border-color;

    input {
      cursor: pointer;
      font-weight: normal !important;
    }

    &:focus-within {
      border-color: transparent !important;
      box-shadow: 0 0 0 2.5px #2684ff !important
    }
  }

  .DayPicker_transitionContainer__horizontal {
    transition: none !important;
  }

  .DateInput_fang {
    display: none;
  }

  .DateRangePickerInput_arrow {
    display: flex;
    align-items: center;
    color: ${({theme}) => theme.color};
    margin: 0;

    svg {
      fill: ${({theme}) => theme.color};
      width: 20px;
    }
  }

  .DateInput {
    display: flex;
    flex-grow: 1;
    border-radius: 5px !important;
    overflow: hidden;
    height: 100%;
    width: auto;

    input {
      padding: 0 6px;
      font-size: 14px;
      color: ${({theme}) => theme.color};
      border: 0;
    }
  }

  .DateRangePickerInput {
    .DateInput {
      &:first-child {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      &:last-child {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      input {
        &:focus {
          background-color: #e6f1ff;
          color: #3074a2;

          &::placeholder {
            color: #3074a2;
          }
        }

        &::placeholder {
          font-size: 11px;
        }
      }
    }
  }

  .DateInput {
    input {
      &:focus {
        border: none;
      }
    }
  }

  .DayPicker {
    margin: 0 auto !important;
    background: ${({theme}) => theme.backgroundColor} !important;
    border-radius: 5px;
    box-shadow: none;
    overflow: hidden;
  }

  .DayPicker_transitionContainer {
    min-height: 340px !important;

    @media (max-width: 618px) {
      min-height: 351px !important;
    }
  }

  .DayPicker_weekHeader {
    top: 80px;
    user-select: none !important;
    z-index: 0;
    @media (max-width: 618px) {
      top: 100px;
    }

  }

  .DayPicker_weekHeaders__horizontal {
    margin-left: 13px !important;
  }

  .DayPicker_weekHeader_li {
    width: 39px !important;
    color: ${({theme}) => theme.color};

    small {
      font-size: 12px !important;
    }
  }

  .CalendarMonth {
    margin-top: 40px;
    padding: 0 10px !important;
    @media (max-width: 619px) {
      margin-top: 40px;
    }
  }

  .CalendarMonth, .CalendarMonthGrid {

    background: transparent !important;
  }

  .CalendarMonth_caption {
    margin-top: 15px !important;
    padding-top: 0;
    text-transform: capitalize;
    user-select: none !important;
    color: ${({theme}) => theme.color} !important;

    strong {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
    }
  }

  .CalendarDay__highlighted_calendar {
    background: #cde8ff;
    color: #2471b4;
  }

  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: ${({theme}) => theme.calendarSelectedDate} !important;
      // color: ${({theme}) => theme.backgroundColor} !important;
    border: none !important;
  }

  .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background: #f1f3f4 !important;
    color: #102c58 !important;
    border: none !important;
  }

  .CalendarDay__selected_span {
    background: ${({theme}) => theme.calendarFillDates} !important;
    color: ${({theme}) => theme.color} !important;
    border: none !important;
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_end:hover,
  .CalendarDay__default:hover {
    background: ${({theme}) => theme.optionHoverColor} !important;
    color: ${({theme}) => theme.color} !important;
    border: none !important;
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    cursor: not-allowed !important;
    background: ${({theme}) => theme.backgroundColor} !important;
    color: #b6b6b6 !important;
  }

  .CalendarDay__default {
    background: ${({theme}) => theme.backgroundColor};
    font-size: 12px;
    width: 40px !important;
    height: 39px !important;
    border: none !important;
    transition: all .3s ease;
    transition-property: background;
    color: ${({theme}) => theme.color};
  }
`;

export default DatePickerStyles;

