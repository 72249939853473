import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import styled from "styled-components";
import Text from "../../../lib/UIBookBrd/Text";
import Button from "../../../lib/UIBookBrd/Button";

const Find__Main__Content__Holder = styled(FlexBox)`
  max-width: 350px;
  width: calc(100% - 20px);
  flex-direction: column;
  padding: 0 10px;
  margin: 50px auto 0 auto;
`
const Find__Your__Offer__Title = styled(Text)`
  font-size: 30px;
  margin: 10px auto 30px auto;
  text-align: center;
  font-weight: bold;
`
const Input__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  margin-bottom: 4px;
`

const Input__Error = styled(Text)`
  text-align: right;
  height: 14px;
  color: ${({theme}) => theme.errorMessageBackgroundColor};
  font-size: 10px;
`
const Input__Title = styled(Text)`
  font-size: 13px;
  margin-bottom: 2px;
`
const Confirm__Button = styled(Button)`
  margin-top: 10px;
`
const Order__Find__Description = styled(Text)`
  text-align: center;
  width: 100%;
  margin-top: 25px;

`

export const Styled = {
    Order__Find__Description,
    Confirm__Button,
    Input__Title,
    Input__Error,
    Input__Holder,
    Find__Main__Content__Holder,
    Find__Your__Offer__Title
}
