import {Styled} from "./OverViewSection.styles"
import {isEmpty} from "lodash-es";

type OverViewSectionProps = {
    isLoadingData: boolean
    section: any
}


const OverViewSection = (
    {
        isLoadingData,
        section,
    }: OverViewSectionProps
) => {
    return (
        <Styled.Content__Holder>
            {isEmpty(section) || isLoadingData ? (
                <>
                    {Array.from({length: 6}).map((_: any, sectionIndex: number) => {
                        return (
                            <Styled.Content__Part
                                isGrey={sectionIndex % 2 === 1}
                            >
                                {Array.from({length: 2}).map(() => {
                                    return (
                                        <Styled.Content__Option>
                                            <Styled.Content__Option__Label__Skeleton
                                                isLoading={isLoadingData}
                                                isGrey={sectionIndex % 2 === 1}
                                            />
                                            <Styled.Content__Option__Value__Skeleton
                                                isLoading={isLoadingData}
                                                isGrey={sectionIndex % 2 === 1}
                                            />
                                        </Styled.Content__Option>
                                    )
                                })}
                            </Styled.Content__Part>
                        )
                    })}
                </>
            ) : (
                <>
                    {!isEmpty(section) && !isEmpty(section[0]) && section[0]?.map((part: any, sectionIndex: any) => {
                        return (
                            <Styled.Content__Part
                                isGrey={sectionIndex % 2 === 1}
                            >
                                {section?.map((option: any) => {
                                    const type = option[sectionIndex]?.type
                                    const value = option[sectionIndex]?.value
                                    return (
                                        <Styled.Content__Option>
                                            <Styled.Content__Option__Label>
                                                {type}
                                                {!isEmpty(type) && ':'}
                                            </Styled.Content__Option__Label>
                                            <Styled.Content__Option__Value>
                                                {value}
                                            </Styled.Content__Option__Value>
                                        </Styled.Content__Option>
                                    )
                                })}
                            </Styled.Content__Part>
                        )
                    })}
                </>
            )}
        </Styled.Content__Holder>
    )
}

export default OverViewSection