const useElementResize = () => {

    const heightObserver = (ref: any, state: any, setState: any, requestReRender: any) => {
        if (!ref.current) return;
        setState(ref.current.clientHeight)
        const resizeObserver = new ResizeObserver((event: any) => {
            const newHeight = event[0]?.target?.clientHeight
            if (newHeight !== state) {
                setState(newHeight)
                if (requestReRender) {
                    requestReRender()
                }
            }
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect();
    }


    return ({
        heightObserver
    })

}
export default useElementResize