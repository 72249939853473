import Drawer from "../../../../../../lib/UIBookBrd/Drawer/Drawer";
import {useTranslation} from "react-i18next";
import {isMobileOnly} from "react-device-detect";
import {useEffect} from "react";
import {Styled} from "../../../../ContactPage.styles";
import useSupport from "../../../../Hook/useSupport";
import MailSchema from "./MailSchema";
import MailInformation from "./MailInformation";

type MailGuideDrawerProps = {
    isOpen: boolean
    onClose: () => void
}

const MailGuideDrawer = (
    {
        onClose,
        isOpen
    }: MailGuideDrawerProps
) => {

    const {
        fetchMailGuide,
        mailGuide
    } = useSupport()

    const {t} = useTranslation()


    useEffect(() => {
        fetchMailGuide()
    }, [])

    return (
        <Drawer
            title={t('lbl_mail_guide')}
            position={'right'}
            isOpen={isOpen}
            onClose={onClose}
            contentStyles={{
                minWidth: isMobileOnly ? '100vw' : '400px',
                maxWidth: '600px',
                overflow: mailGuide?.isLoading ? 'hidden' : 'auto'
            }}
        >
            {mailGuide?.isLoading ? (
                <Styled.Guide__Mail__Structure__Holder>

                </Styled.Guide__Mail__Structure__Holder>
            ) : (
                <Styled.Guide__Mail__Structure__Holder>
                    <MailSchema/>
                    <MailInformation/>
                </Styled.Guide__Mail__Structure__Holder>
            )}
        </Drawer>
    )
}

export default MailGuideDrawer