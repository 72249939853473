import {Styled} from "./OfferReviewDetails.styles"
import {toNumber} from "lodash-es";
import {useTranslation} from "react-i18next";
import ReviewBox from "./ReviewBox";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {faStar} from "@fortawesome/pro-solid-svg-icons";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";

type OfferReviewDetailsProps = {
    isLoadingReview: boolean,
    reviewRatingData: any
}


const OfferReviewDetails = (
    {
        isLoadingReview,
        reviewRatingData
    }: OfferReviewDetailsProps
) => {

    const {t} = useTranslation();

    const reviewValues = ['location', 'food', 'Room', 'cleanHotel', 'staff', 'attractions',]


    const fakeReviews = [
        {
            userName: 'Peter',
            userTitle: 'Voyage Enthusiast-in-Training',
            userReviewsNumber: '1 review',
            description: "Unforgettable experience! From the moment I stepped into the grand lobby, I knew this hotel was special. The attention to detail was remarkable, and the spa facilities were heavenly. The restaurant offered culinary delights that satisfied even the most discerning palate. I can\'t wait to return!",
            totalRating: 5.6,
            whenLabel: '3 days ago',
        },
        {
            userName: 'Margaret',
            userTitle: 'Voyage Veteran',
            userReviewsNumber: '15 review',
            description: "A hidden gem! This boutique hotel charmed me with its unique decor, cozy atmosphere, and personalized attention. The rooms were stylishly appointed, and the staff's warmth made me feel right at home. Perfect for a peaceful and intimate retreat.",
            totalRating: 9.2,
            whenLabel: ' 4 months ago',
        },
        {
            userName: 'Json',
            userTitle: 'Journey Rookie',
            userReviewsNumber: '2 review',
            description: "Amazing stay! The hotel exceeded my expectations with its luxurious amenities, impeccable service, and breathtaking views. The staff went above and beyond to ensure a memorable experience. Highly recommend for a truly indulgent getaway.",
            totalRating: 6.5,
            whenLabel: ' 1 year ago',
        },
    ]


    return !isLoadingReview ? (
        <Styled.Offer__Review__Holder>
            <Styled.Offer__Ratings__Holder>
                <Styled.Rating__Data__Holder>
                    <Styled.Labels__Holder>
                        {reviewValues?.map((item: string) => {

                            return (
                                <Styled.Review__Type__Label
                                    key={`reviews_title_${item}`}
                                >
                                    {item}:
                                </Styled.Review__Type__Label>
                            )
                        })}
                    </Styled.Labels__Holder>
                    <Styled.Ratings__Holder>
                        {reviewValues?.map((item: any) => {

                            const ratingNumber = toNumber((Math.floor(Math.random() * 100 + 1) / 10).toFixed(1))

                            return (
                                <Styled.Rating__Line__Number__Holder
                                    key={`reviews_value_${item}`}
                                >
                                    <Styled.Rating__Number>
                                        {ratingNumber}
                                    </Styled.Rating__Number>
                                    <Styled.Rating__Line__Holder>
                                        <Styled.Rating__Line
                                            width={ratingNumber * 10}
                                        />
                                        <Styled.Missing__Rating__Line
                                            width={100 - ratingNumber * 10}
                                        />
                                    </Styled.Rating__Line__Holder>
                                </Styled.Rating__Line__Number__Holder>
                            )
                        })}
                    </Styled.Ratings__Holder>
                </Styled.Rating__Data__Holder>
                <FlexBox>
                    <Styled.Summary__Number>
                        {t('lbl_summary')}
                    </Styled.Summary__Number>
                    <Styled.Summare__Number__Value>
                        9.2
                    </Styled.Summare__Number__Value>
                    <Styled.Rating__Star
                        icon={faStar}
                        style={{
                            height: '18px',
                            width: '18px',
                            marginTop: '1px'
                        }}
                    />
                </FlexBox>
            </Styled.Offer__Ratings__Holder>
            <Styled.Review__Data__Holder>
                <Styled.Review__Items__holder>
                    <Styled.Reviews__List__Holder>
                        {fakeReviews.map((value: any, index: number) => {

                            // ADD TOOLTIP FOR SINGLE REVIEW TO SHOW

                            return (
                                <ReviewBox
                                    key={`review_box_${index}`}
                                    value={value}
                                />
                            )
                        })}
                    </Styled.Reviews__List__Holder>
                </Styled.Review__Items__holder>
            </Styled.Review__Data__Holder>

        </Styled.Offer__Review__Holder>
    ) : (
        <Styled.Offer__Review__Holder>
            <Styled.Offer__Ratings__Holder>
                <Styled.Rating__Data__Holder>
                    <Styled.Labels__Holder>
                        {Array.from({length: 6})?.map((_, index: number) => {

                            return (
                                <SkeletonBox
                                    key={`skeleton_box_for_labels_${index}`}
                                    style={{
                                        margin: '4px 0',
                                        minHeight: '14px',
                                        minWidth: '90px'
                                    }}
                                />
                            )
                        })}
                    </Styled.Labels__Holder>
                    <Styled.Ratings__Holder>
                        {reviewValues?.map((type: string) => {

                            return (
                                <Styled.Rating__Line__Number__Holder>
                                    <SkeletonBox
                                        key={`reviews_skeleton_${type}`}
                                        style={{
                                            margin: '0',
                                            marginRight: '8px',
                                            minHeight: '14px',
                                            minWidth: '30px'
                                        }}
                                    />
                                    <Styled.Rating__Line__Holder>
                                        <SkeletonBox
                                            key={`reviews_skeleton_value_${type}`}
                                            style={{
                                                margin: '0',
                                                marginRight: '4px',
                                                minHeight: '8px',
                                                minWidth: '100%'
                                            }}
                                        />
                                    </Styled.Rating__Line__Holder>
                                </Styled.Rating__Line__Number__Holder>
                            )
                        })}
                    </Styled.Ratings__Holder>
                </Styled.Rating__Data__Holder>
                <FlexBox>
                    <SkeletonBox
                        style={{
                            margin: "0",
                            marginLeft: "10px",
                            minHeight: '16px',
                            marginRight: "auto"
                        }}
                    />
                    <SkeletonBox
                        style={{
                            margin: "0",
                            marginRight: "4px",
                            minHeight: '16px',
                            minWidth: '40px',
                        }}
                    />
                    <SkeletonBox
                        style={{
                            margin: "0",
                            minHeight: '16px',
                            minWidth: '15px',
                        }}
                    />
                </FlexBox>
            </Styled.Offer__Ratings__Holder>
            <Styled.Review__Data__Holder>
                <Styled.Review__Items__holder>
                    <Styled.Reviews__List__Holder>
                        {Array.from({length: 3})?.map((_: any, index: number) => {


                            // ADD TOOLTIP FOR SINGLE REVIEW TO SHOW

                            return (
                                <ReviewBox
                                    key={`loading_review_box_${index}`}
                                    value={{}}
                                    isLoading={true}
                                />
                            )
                        })}
                    </Styled.Reviews__List__Holder>
                </Styled.Review__Items__holder>
            </Styled.Review__Data__Holder>

        </Styled.Offer__Review__Holder>
    )

}
export default OfferReviewDetails