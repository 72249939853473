import {Trip_Result_Page} from "../../../../lib/Context/Context";
import {useState} from "react";
import {pageDefaultContext} from "../Consts";

type ContextProviderProps = {
    children: React.ReactElement
}


const PageContext = (
    {
        children
    }: ContextProviderProps
) => {

    const [state, setState] = useState(pageDefaultContext)


    return (

        <Trip_Result_Page.Provider value={[state, setState]}>
            {children}
        </Trip_Result_Page.Provider>
    )
}

export default PageContext