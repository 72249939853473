import {useContext} from "react";
import {Search_Proto} from "../Context/Context";
import {isEmpty} from "lodash-es";

const deleteEmptyFields = (value: any) => {

    let newValue = value

    Object.keys(value).map((name: string) => isEmpty(value[name]) && delete newValue[name])

    return newValue
}

const useFilterFormValue = () => {

    const [protoValue, setProtoValue] = useContext<any>(Search_Proto)

    let filterFormValue = protoValue?.FilterFormValues ? protoValue.FilterFormValues : {}

    const setFilterFormValue = (name: string | string[], values: string | any[]) => {

        filterFormValue[`${name}`] = values

        setProtoValue((state: any) => ({
            ...state,
            FilterFormValues: deleteEmptyFields(filterFormValue)
        }))
    }

    const clearAllValues = () => {
        setProtoValue((state: any) => ({
            ...state,
            FilterFormValues: {},
            similarOffers: {}
        }))
    }

    return (

        {
            filterFormValue,
            setFilterFormValue,
            clearAllValues
        }
    )
}
export default useFilterFormValue