import {Styled} from "../../TripOffersDetails.styles";
import SmallWidthTitle from "./SmallWidthTitle/SmallWidthTitle";
import OfferDetailsOfferImages from "./OfferGallery/OfferDetailsOfferImages";
import BookingComponent from "./BookingComponent/BookingComponent";
import {useContext, useEffect, useRef, useState} from "react";
import {includes, isEmpty, round} from "lodash-es";
import useV5OfferListRequest, {viewTypes} from "../../../../../lib/UseHooksBookBrd/useV5OfferListRequest";
import useSearchFormValues from "../../../../../lib/UseHooksBookBrd/useSearchFormValues";
import useFilterFormValue from "../../../../../lib/UseHooksBookBrd/useFilterFormValue";
import usePricesHandler from "../../../../../lib/UseHooksBookBrd/usePricesHandler";
import {Search_Proto} from "../../../../../lib/Context/Context";
import {useTranslation} from "react-i18next";
import {GET_TRANSPORT_TYPE} from "../../../TripSearchResultPage/TripSearchFunctions";
import {faCircle} from "@fortawesome/pro-light-svg-icons";
import {faCircle as solidCircle} from "@fortawesome/pro-solid-svg-icons";


type HeaderProps = {
    controls: any
    onBookClick: () => void
    setBookingControls: any
    bookingControls: any
    onShowMap: () => void
    isLoadingOfferDetails: boolean
    requestSent: boolean
    offer: any
    setOffer: any
    pictures: any
}


const Header = (
    {
        requestSent,
        controls,
        onBookClick,
        setBookingControls,
        bookingControls,
        onShowMap,
        isLoadingOfferDetails,
        pictures,
        offer,
        setOffer,
        ...props
    }: HeaderProps) => {

    const {t} = useTranslation();

    const [showFixedTitle, setShowFixedTitle] = useState<boolean>(false)

    const [showPrice, setShowPrice] = useState<boolean>(false)

    const [showBookButton, setShowBookButton] = useState<boolean>(false)

    const [_, setProtoValue] = useContext<any>(Search_Proto)

    const [loadingSelects, setLoadingSelects] = useState({})

    const [requestSentFields, setRequestSentFields] = useState<string[]>([])

    const {searchFormValue} = useSearchFormValues()

    const {filterFormValue} = useFilterFormValue()

    const transportType = GET_TRANSPORT_TYPE(offer?.Transport)

    const getFields = () => {
        switch (transportType) {
            case 'Own':
                return ['OfferOtherDates', "OfferOtherDuration", 'OfferOtherRooms', 'OfferOtherBoard',] as viewTypes[]
            default:
                return ['OfferOtherDates', "OfferOtherDuration", 'OfferOtherOutBound', 'OfferOtherRooms', 'OfferOtherBoard',] as viewTypes[]
        }
    }

    const {getPriceDifference, currency} = usePricesHandler(offer?.Base?.Price)

    const {getDetailsParams} = useV5OfferListRequest(searchFormValue, filterFormValue)

    const titleRef = useRef<any>(null)

    const getPriceElementValue = (priceDifference: any) => {
        const amount = priceDifference?.amount
        const currency = priceDifference?.currency
        if (amount > 0) {
            return {
                isMore: true,
                value: `+${round(amount)} ${currency}`
            }
        }
        if (amount === 0) {
            return {
                value: t('lbl_no_price_differ'),
                isEqual: true
            }
        }
        if (amount < 0) {
            return {
                isMore: false,
                value: `${round(amount)} ${currency}`
            }
        }
    }

    const createOptionComponent = (mainOffer: any, newOffer: any, requestType: viewTypes, disablePrice: boolean = false, isOption: boolean = true) => {
        const priceDifference = getPriceDifference(mainOffer?.Base?.Price, newOffer?.Base.Price)
        const shownPriceValue = getPriceElementValue(priceDifference)
        const isSelectedOffer = mainOffer?.Base?.OfferId === newOffer?.Base?.OfferId

        const dataForRequestType: any = {
            ['OfferOtherDates']: {
                label: `${newOffer?.Base?.StartDate?.split('-')?.reverse()?.join('.')} - ${newOffer?.Base?.ReturnDate?.split('-')?.reverse()?.join('.')}`
            },
            ['OfferOtherRooms']: {
                label: newOffer?.Accommodation?.Room?.Name
            },
            ['OfferOtherBoard']: {
                label: newOffer?.Accommodation?.XService?.Name
            },
            ['OfferOtherOperator']: {
                label: newOffer?.Base?.OperatorDesc
            },
            ['OfferOtherDuration']: {
                label: `${newOffer?.Base?.Duration} ${t('lbl_days')}`
            },
            ['OfferOtherOutBound']: {
                label: newOffer?.Base?.DepartureLocation?.Name
            }
        }
        return !isOption ? (
            <Styled.Select__Option>
                <Styled.MainValue_Holder>
                    {dataForRequestType[requestType]?.label ?? ''}
                </Styled.MainValue_Holder>
                {!disablePrice && !isSelectedOffer && (
                    <Styled.Price_Holder
                        isEqual={!!shownPriceValue?.isEqual}
                        isMore={!!shownPriceValue?.isMore}
                    >
                        {shownPriceValue?.value}
                    </Styled.Price_Holder>
                )}
            </Styled.Select__Option>
        ) : (
            <Styled.Select__New__Option
                isSelected={mainOffer?.Base?.OfferId === newOffer?.Base?.OfferId}
            >
                <>
                    <Styled.Select__Option__Selected__Icon
                        icon={faCircle}
                    />
                    {mainOffer?.Base?.OfferId === newOffer?.Base?.OfferId && (
                        <Styled.Selected__Option__Select__Inner__Cirlce
                            icon={solidCircle}
                        />
                    )}
                </>
                <Styled.MainValue_Holder
                    enableWhiteSpace={true}
                >
                    {dataForRequestType[requestType]?.label ?? ''}
                </Styled.MainValue_Holder>
                {!disablePrice && !isSelectedOffer && (
                    <Styled.Price_Holder
                        isEqual={!!shownPriceValue?.isEqual}
                        isMore={!!shownPriceValue?.isMore}
                    >
                        {shownPriceValue?.value}
                    </Styled.Price_Holder>
                )}
            </Styled.Select__New__Option>
        )

    }

    const onSelectLoad = (offer: any, requestType: viewTypes) => {
        if (!includes(requestSentFields, requestType)) {
            setLoadingSelects((state: any) => ({
                ...state,
                [requestType]: true
            }))
            getDetailsParams(
                offer,
                requestType,
                setBookingControls,
                bookingControls,
                createOptionComponent,
                setLoadingSelects,
            ).then(() => {
                setRequestSentFields((state: string[]) => ([...state, requestType]))
            })
        }
    }

    const onSelectChangeHandler = (option: any) => {
        const {offer: newOffer} = option
        if (offer?.Base?.OfferId !== newOffer?.Base?.OfferId) {
            setOffer(newOffer)
            setProtoValue((state: any) => ({
                ...state,
                offerDetails: newOffer?.Base?.OfferId ?? ''
            }))
            setRequestSentFields([])
        }
    }

    const scrollAction = () => {
        if (window.scrollY >= 57) {
            if (!showFixedTitle) {
                setShowFixedTitle(true)
            }
        } else {
            setShowFixedTitle(false)
        }
        if (window.scrollY >= 430) {
            if (!showPrice) {
                setShowPrice(true)
            }
        } else {
            setShowPrice(false)
        }
        if (window.scrollY >= 470) {
            if (!showBookButton) {
                setShowBookButton(true)
            }
        } else {
            setShowBookButton(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollAction)
        return () => {
            window.removeEventListener('scroll', scrollAction)
        }
    }, [])

    useEffect(() => {
        if (!isEmpty(offer)) {
            let newValue: any = {}
            getFields()?.map((fieldName: viewTypes) => {
                newValue[fieldName] = {
                    list: [{
                        label: createOptionComponent(offer, offer, fieldName),
                        isCustom: true,
                        value: offer.Base.OfferId,
                        offer: offer
                    }]
                }

            })
            setBookingControls((state: any) => ({
                ...state,
                ...newValue
            }))
            setRequestSentFields([])
        }
    }, [offer])

    useEffect(() => {
        let newValue: any = {}
        Object.keys(bookingControls)?.map((sectionNames: string) => {
            const newList: any[] = []
            const sectionValue = bookingControls[sectionNames]
            const {list} = sectionValue
            list?.map((option: any) => {
                newList.push({
                    label: createOptionComponent(offer, option.offer, sectionNames as viewTypes),
                    isCustom: true,
                    value: option.offer.Base.OfferId,
                    offer: option.offer
                })
            })
            newValue[sectionNames] = {list: newList}
        })
        setBookingControls(newValue)
    }, [currency])

    return (
        <Styled.Offer_Details_Header>
            <SmallWidthTitle
                onBookClick={onBookClick}
                onShowMap={onShowMap}
                ref={titleRef}
                showFixedTitle={showFixedTitle}
                showBookButton={showBookButton}
                showPrice={showPrice}
                isLoadingOfferDetails={isLoadingOfferDetails}
                offer={offer}
            />
            <OfferDetailsOfferImages
                isLoadingSections={controls?.isLoadingImages}
                titleHeight={titleRef?.current?.clientHeight}
                showFixedTitle={!!showFixedTitle}
                requestSent={requestSent}
                images={pictures}
            />
            <BookingComponent
                onBookClick={onBookClick}
                onShowMap={onShowMap}
                fields={getFields()}
                createOptionComponent={createOptionComponent}
                onSelectChangeHandler={onSelectChangeHandler}
                loadingSelects={loadingSelects}
                bookingControls={bookingControls}
                getDetailsParams={onSelectLoad}
                isLoadingOfferDetails={isLoadingOfferDetails}
                offer={offer}
            />
        </Styled.Offer_Details_Header>
    )
}
export default Header