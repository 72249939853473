import {DayPickerRangeController} from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import React, {forwardRef, useEffect, useRef, useState} from "react";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import moment from "moment";
import {isMobileOnly} from "react-device-detect";
import {Styled} from "./Calendar.styles";
import AdditionalTimeButton from "./AdditionalTimeButton";
import {NextBtn, PrevBtn} from "./NavigationButton";
import {isEmpty} from "lodash-es";
import YearAndMonthForMonthRender from "./YearAndMonthForMonthRender";
import {useTranslation} from "react-i18next";


type CalendarProps = {
    style?: React.CSSProperties
    shownMonth?: any
    setShowMonth?: any
    setIsSelectOpen?: any
    startDate?: any
    endDate?: any
    setEndDate?: any
    setStartDate?: any
    maxDate?: any
    focusedInput?: any
    setFocusedInput?: any
    minDate?: any
    numberOfMonths?: number
    onClose?: any

}
const defaultProps = {
    shownMonth: moment(),
    setShowMonth: undefined,
    startDate: moment(),
    endDate: moment().add(1, 'week'),
    setIsSelectOpen: undefined,
    setStartDate: undefined,
    setEndDate: undefined,
    focusedInput: null,
    setFocusedInput: undefined,
    style: undefined,
    maxDate: undefined,
    minDate: undefined,
    numberOfMonths: 1,
}


const DateRangePicker = forwardRef<HTMLDivElement, CalendarProps>((
    {
        shownMonth,
        setShowMonth,
        style,
        focusedInput,
        setIsSelectOpen,
        setFocusedInput,
        maxDate,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        minDate,
        numberOfMonths,
        onClose,
        ...props
    }, ref) => {

    const {t} = useTranslation();
    const [monthsOptions, setMonthsOptions] = useState<any[]>([])

    const [yearOptions, setYearOptions] = useState<any[]>([])

    const inputRef = useRef<any>()

    const onDatesChange = (dates: any) => {
        const newStartDate = dates.startDate as any
        const newEndDate = dates.endDate as any
        setStartDate({label: newStartDate.format('DD.MM.YYYY') as any})
        setEndDate({label: isEmpty(newEndDate) ? null : focusedInput === 'startDate' ? null : newEndDate.format('DD.MM.YYYY') as any})
    }

    const isOutsideRange = (day: any) => day.isBefore(minDate, 'day');

    const onFocusChangeHandler = (focusedInput: any) => {
        const outPut = focusedInput === null ? [] : [focusedInput]

        if (setFocusedInput) {
            setFocusedInput(outPut)
        }
    }

    const calendarInfoButtons = [
        {
            startDateLabel: 'today',
            endDateLabel: 'for_day',
            add: 'day',
            setEndDate: setEndDate,
            setStartDate: setStartDate,
            setFocusedInput: setFocusedInput,
            focusedInput: focusedInput,
            minDate: minDate,
            startDate: startDate,
        },
        {
            startDateLabel: 'next_week',
            endDateLabel: 'for_week',
            add: 'week',
            setEndDate: setEndDate,
            setStartDate: setStartDate,
            setFocusedInput: setFocusedInput,
            focusedInput: focusedInput,
            minDate: minDate,
            startDate: startDate,
        },
        {
            startDateLabel: 'next_month',
            endDateLabel: 'for_month',
            add: 'month',
            setEndDate: setEndDate,
            setStartDate: setStartDate,
            setFocusedInput: setFocusedInput,
            focusedInput: focusedInput,
            minDate: minDate,
            startDate: startDate,
        }
    ]


    const initialVisibleMonth = () => {
        if (numberOfMonths as number === 1) {
            return shownMonth
        } else {
            if (focusedInput === 'startDate') {
                return startDate
            }
            if (focusedInput === 'endDate') {
                return endDate
            }
        }
    }


    const calendarInfo = (
        <Styled.CalendarInfoHolder>
            <Styled.CalendarInfo>
                <>
                    {calendarInfoButtons.map((item: any) => {
                        return (
                            <AdditionalTimeButton
                                label={focusedInput === 'startDate' ? item.startDateLabel : item.endDateLabel}
                                add={item.add}
                                setEndDate={item.setEndDate}
                                setStartDate={item.setStartDate}
                                focusedInput={item.focusedInput}
                                setFocusedInput={item.setFocusedInput}
                                startDate={item.startDate}
                                minDate={item.minDate}
                            />
                        )

                    })}

                </>
            </Styled.CalendarInfo>
        </Styled.CalendarInfoHolder>
    )

    useEffect(() => {
        const listOfMonths: any[] = []
        const listOfYears: any[] = []
        moment.months().map((item: any, index: number) => {
            listOfMonths.push(
                {
                    label: t(`lbl_${item.toLowerCase()}`),
                    value: index + 1
                }
            )

        })
        for (let i = 0; i < 3; i++) {
            listOfYears.push(
                {
                    label: moment().add(i, 'year').format('YYYY'),
                    value: moment().add(i, 'year').format('YYYY'),
                }
            )

        }
        setMonthsOptions(listOfMonths)
        setYearOptions(listOfYears)
    }, [])

    const DisplayCalendar = (
        <FlexBox style={{width: '100%', height: '100%', flexDirection: 'column', flexWrap: 'nowrap'}}>
            <FlexBox style={{width: "100%", height: "auto", paddingBottom: '10px'}}>
                <DayPickerRangeController
                    ref={inputRef as any}
                    minimumNights={0}
                    calendarInfoPosition="bottom"
                    noBorder={true}
                    numberOfMonths={numberOfMonths}
                    showKeyboardShortcuts={false}
                    hideKeyboardShortcutsPanel={true}
                    noNavButtons={false}
                    maxDate={maxDate}
                    isOutsideRange={isOutsideRange}
                    minDate={minDate}
                    startDate={startDate}
                    endDate={endDate}
                    renderNavNextButton={(props) => <NextBtn {...props}/>}
                    renderNavPrevButton={(props) => <PrevBtn {...props}/>}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput as any}
                    onFocusChange={onFocusChangeHandler}
                    initialVisibleMonth={initialVisibleMonth}
                    renderMonthElement={({onMonthSelect, onYearSelect, month}) => (
                        <>
                            <YearAndMonthForMonthRender
                                numberOfMonths={numberOfMonths}
                                monthsOptions={monthsOptions}
                                setIsSelectOpen={setIsSelectOpen}
                                onMonthSelect={onMonthSelect}
                                yearOptions={yearOptions}
                                onYearSelect={onYearSelect}
                                month={month}
                            />
                        </>
                    )}
                />
            </FlexBox>
            <>{calendarInfo}</>
        </FlexBox>
    )
    return (
        <>
            {!isMobileOnly && (
                <FlexBox
                    ref={ref}
                    style={{...style}}
                >
                    {DisplayCalendar}
                </FlexBox>
            )}
            {isMobileOnly && (

                <FlexBox
                    ref={ref}
                    style={{
                        height: '100%',
                        width: '100%',
                        ...style
                    }}
                >
                    {DisplayCalendar}
                </FlexBox>
            )}
        </>
    )
})

DateRangePicker.defaultProps = defaultProps;
export default DateRangePicker