export const LIGHT_THEME = {
    OfferListBackgroundColor: 'rgba(0,0,0,0.15)',
    isDark: false,
    selectTagsBackgroundColor: 'rgb(230, 230, 230)',
    toolTipColor: 'grey',
    focusedInputBorderColor: '#1a9fff',
    optionHoverColor: '#d0d0d0',
    calendarSelectedDate: '#ababab',
    calendarFillDates: '#e7e7e7',
    placeholderColor: '#8a8a8a',
    backgroundColor: '#fdfdfd',
    borderColor: "#1f1f1f",
    borderColorLight: '#dcdcdc',
    separatorColor: "#1f1f1f",
    color: "#1f1f1f",
    buttonBackgroundColor: "#689b0e",
    typeOfSearchHover: 'rgba(255,255,255,0.5)',
    colorBlue: 'midnightblue',
    disabledFontColor: 'grey',
    isCheckedSwitchGreen: 'rgba(9,119,5,0.73)',
    ratingStarColor: '#d79b1f',
    circleInfoColor: '#257ab0',
    successMessageBackgroundColor: "rgba(50,164,54,0.9)",
    lowerPriceColor: "rgb(5,129,5)",
    errorMessageBackgroundColor: 'rgba(255,0,0,0.9)',
    errorMessageIconColor: 'rgba(215,6,6,1)',
    favouriteOfferColor: 'rgb(253,38,119)',
    warningBackgroundColor: "rgba(241,196,105,0.9)",
    brdExclusiveColor: '#3ea3f8',
    missingRatingColor: '#c0c0c0'
}