import styled from "styled-components";
import FlexBox from "../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../lib/UIBookBrd/Text";

const Quick__Start__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100vh;
  max-height: 900px;
`
const Section__Label = styled(Text)`
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  padding: 10px 15px;
`
const Section__Value__Text__Centered = styled(Text)`
  padding: 5px 10px;
`
const Section__Value__Text = styled(Text)`
  padding: 5px 10px;
`
const Basic__Tasks__Section__Title = styled(Text)`
  font-weight: bold;
  padding: 5px 0;
  margin-left: 20px;
`
const Basic__Tasks__Section__Example = styled(Text)`
  padding: 3px 0;
  margin-left: 35px;
`

export const Styled = {
    Basic__Tasks__Section__Title,
    Section__Value__Text__Centered,
    Section__Value__Text,
    Basic__Tasks__Section__Example,
    Quick__Start__Holder,
    Section__Label
}