import {includes, isArray} from "lodash-es";
import {
    DATE_RANGE_FIELD,
    PARTICIPANTS_FIELD,
    Search_Form_Fields,
    TO_FIELD
} from "../DefaultFormikValue/DefaultSearchFormValues";
import {useContext} from "react";
import {Search_Proto} from "../Context/Context";
import moment from "moment";
import {SECOND_STEP_TRIP_SEARCH} from "../Routes";
import useNavigation from "./useNavigation";
import useBookBrdPageFunctions from "./useBookBrdPageFunctions";
import useStorage from "./useStorage";

type advertFuncType = 'Country' | 'Offer'


export const GET_VALID_SUGGESTED_DATES = (dates: any) => {

    const startDate = dates?.startDate

    const endDate = dates?.endDate

    const datesDiff = moment(endDate, 'DD.MM.YYYY').diff(moment(startDate, 'DD.MM.YYYY'), 'days')

    const newStartDate = moment().format('DD.MM.YYYY')

    const newEndDate = moment().add(datesDiff, 'days').format('DD.MM.YYYY')

    return ({
        startDate: newStartDate,
        endDate: newEndDate
    })

}

const isFirstLogged = () => sessionStorage.getItem('TFOB') === null || sessionStorage.getItem('FOA') === undefined

export const searchFormValidation = (searchFormValue: any) => {

    let newSearchValue = searchFormValue ?? {}

    let error: any = {}

    const isOutDated = moment(newSearchValue[DATE_RANGE_FIELD]?.startDate, 'DD.MM.YYYY').isBefore(moment().add(-1, 'day'))

    //Date Picker
    if (isOutDated) {
        error[DATE_RANGE_FIELD] = 'OUTDATED_START_DATE'
    }

    //Participants
    const ParticipantsError: any[] = []

    newSearchValue[PARTICIPANTS_FIELD]?.kids?.dates?.map((date: string, index: number) => {
        const isValid = moment(date, 'DD.MM.YYYY')?.isValid()
        if (!isValid) {
            ParticipantsError?.push(`Pp.Kids.${index}.Date`)
        }
    })

    error[PARTICIPANTS_FIELD] = ParticipantsError

    if (!isFirstLogged() && window?.location?.pathname === SECOND_STEP_TRIP_SEARCH) {
        if (isOutDated) {
            newSearchValue[DATE_RANGE_FIELD] = {
                startDate: moment().format('DD.MM.YYYY'),
                endDate: moment().add(1, 'week').format('DD.MM.YYYY')
            }
        }
    }

    return {
        SearchFormValue: newSearchValue,
        errorList: error
    }
}

const useSearchFormValues = () => {

    const reRoute = useNavigation()

    const {selectedLanguage} = useBookBrdPageFunctions()

    const [offersWithLabels, setOffersWithLabels] = useStorage<any>('localStorage', 'offersWithLabels', {language: selectedLanguage})

    const [protoValue, setProtoValue] = useContext<any>(Search_Proto)

    const SET_FORMIK_VALUES = (name: string, values: string | any) => {
        const isValid = includes(Search_Form_Fields, name)

        if (isValid) {
            setProtoValue((state: any) => ({
                ...state,
                SearchFormValues: {
                    ...state?.SearchFormValues ?? {},
                    [name]: values
                }

            }))
        } else {
            console.warn('It seems that you have entered incorrect name check the passed name!')
        }
    }

    const SET_FORM_FROM_PREVIOUS_SEARCH = (values: any) => {
        setProtoValue((state: any) => ({
            ...state,
            FilterFormValues: {},
            SearchFormValues: values
        }))
    }

    const ON_ADVERTISEMENT_BOX_CLICK = (type: advertFuncType, value: any) => {
        switch (type) {
            case 'Country':
                if (!includes(offersWithLabels?.list?.map((item: any) => item.value), value.id)) {
                    const newValues = isArray(offersWithLabels?.list) ? offersWithLabels?.list : []
                    newValues.push({label: value.label, value: value.id, type: 'countries'})
                    setOffersWithLabels((state: any) => ({
                        ...state,
                        list: newValues
                    }))
                }


                setProtoValue((state: any) => ({
                    ...protoValue,
                    SearchFormValues: {
                        ...state.SearchFormValues,
                        [TO_FIELD]: [value.id]
                    }
                }))
                reRoute(SECOND_STEP_TRIP_SEARCH)

                break
        }
    }

    return (
        {
            searchFormValue: protoValue?.SearchFormValues,
            setSearchFormValue: SET_FORMIK_VALUES,
            onAdvertisementClickHandler: ON_ADVERTISEMENT_BOX_CLICK,
            setFormPreviousSearch: SET_FORM_FROM_PREVIOUS_SEARCH
        }
    )
}
export default useSearchFormValues