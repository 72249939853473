import {Styled} from "../HeaderForManageAccount.styles"
import Switch from "../../../../lib/UIBookBrd/Switch";

type AdditionalSwitchesProps = {
    label: string
    state: boolean
    onChange: (bool: boolean) => void
}

const AdditionalSwitches = (
    {
        label,
        state,
        onChange
    }: AdditionalSwitchesProps
) => {


    return (
        <Styled.Switch__Holder>
            <Switch
                isChecked={state}
                setIsChecked={onChange}
            />
            <Styled.Switch__Label>
                {label}
            </Styled.Switch__Label>
        </Styled.Switch__Holder>
    )
}

export default AdditionalSwitches