import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";
import {rgba} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextArea from "../../lib/UIBookBrd/TextArea";
import Button from "../../lib/UIBookBrd/Button";
import CheckBox from "../../lib/UIBookBrd/CheckBox";
import {isMobileOnly} from "react-device-detect";

const Contact__Page__Holder = styled(FlexBox)`
  margin-top: 50px;
  flex-direction: column;
  width: 100%;
`
const Contact__Page__Title = styled(Text)`
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  width: calc(100% - 20px);
  padding: 0 10px;
  margin: 10px auto 30px auto;
`
const Contact__Page__Title__Description = styled(Text)`
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
  width: calc(100% - 20px);
  padding: 0 10px;
`
const Contact__Page__Main__Holder = styled(FlexBox)`
  width: 100%;
  margin-top: 40px;
`
const Contact__Page__Main__Half__Option = styled(FlexBox)`
  flex-direction: column;
  background-color: ${({theme}) => rgba(theme.color, 0.1)};
  min-width: 300px;
  height: 300px;
  flex-grow: 1;
  transition: 500ms;
  cursor: pointer;
  max-width: 50%;
  box-sizing: border-box;

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.3)};
  }

  &:first-of-type {
    border-right: 1px solid ${({theme}) => theme.color};
  }

  &:last-of-type {
    border-left: 1px solid ${({theme}) => theme.color};;
  }

  @media (max-width: calc(600px + 2vw)) {
    max-width: unset;
    &:last-of-type {
      border-left: 0;
      border-top: 1px solid ${({theme}) => theme.color};
    }

    &:first-of-type {
      border-right: 0;
      border-bottom: 1px solid ${({theme}) => theme.color};
    }
  }
`
const Contact__Page__Option__Icon = styled(FontAwesomeIcon)`
  width: 60px;
  height: 60px;
  margin: 80px auto 35px auto;
  color: ${({theme}) => theme.color}
`
const Contact__Page__Option__Title = styled(Text)`
  font-size: 24px;
  text-align: center;
  font-weight: bold;
`
const Faq__Holder = styled(FlexBox)`
  margin-top: 50px;
  flex-direction: column;
  width: 100%;
  height: 100%;

`
const Faq__Option = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.color};
`
const Faq__Question__Holder = styled(FlexBox)`
  padding: 15px 10px;
+ flex-wrap: nowrap;
  cursor: pointer;
  width: calc(100% - 20px)
`
const Faq__Question__Label = styled(Text)`
  font-size: 23px;
  font-weight: bold;
`
const Faq__Option__Status__Icon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
  margin: auto 0 auto auto;
  color: ${({theme}) => theme.color};
`
const Faq__Answer__Holder = styled(FlexBox)<{ isOpen: boolean }>`
  flex-direction: column;
  padding: ${({isOpen}) => isOpen ? '  15px 10px 25px 10px' : '0px 10px 0px 10px'};;
  transition: height 200ms, padding-bottom 200ms, padding-top 200ms;
  overflow: hidden;
  height: ${({isOpen}) => isOpen ? 'auto' : '0px'};
`
const Faq__Answer__Label = styled(Text)`
  font-size: 16px;
  text-align: left;
`
const Support__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
  width: calc(100% - 20px);
  margin-top: 50px;
  padding: 0 10px;
  padding-bottom: 10px;
  @media (max-width: 800px) {
    flex-direction: column;
  }

`
const Quick__Support__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`
const Traditional__Support__Holder = styled(FlexBox)`
  width: 100%;
  max-width: 480px;
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid ${({theme}) => theme.color};
  flex-direction: column;
  @media (max-width: 800px) {
    margin-top: 20px;
    border-top: 1px solid ${({theme}) => theme.color};
    max-width: unset;
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
  }
`
const Input__Name = styled(Text)`
  font-size: 13px;
  margin-top: 6px;
  padding-bottom: 2px;
`
const Text__Area = styled(TextArea)`
`
const Submit__Button = styled(Button)`
  margin-left: ${isMobileOnly ? 'unset' : 'auto'};
  margin-top: 10px;
  min-width: 125px;
  padding: 0 10px;
`
const Check__Box = styled(CheckBox)`
  margin-left: auto;
`
const Support__Half__Title = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  margin: 20px auto;
`
const Traditional__Support__Points = styled(Text)`
  margin: 4px 0;
`
const Letters_Counter__Label = styled(Text)`
  margin-top: 2px;
  margin-left: auto;
  margin-bottom: 5px;
  font-size: 13px;
`
const Guide__Mail__Structure__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  height: auto;
`
const Guide__Mail__Mail__Example = styled(FlexBox)`
  flex-direction: column;
  padding: 10px;
  margin: 0 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid ${({theme}) => rgba(theme.color, 0.4)};
`
const Guide__Mail__Title = styled(Text)`

`
const Guide__Mail__Short__Description = styled(Text)`

`
const Guide__Mail__Section__Explanation = styled(Text)`
  opacity: 0.6;
  font-weight: bold;
  margin-bottom: 6px;
`
const Guide__Mail__Mail__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: 10px 0;
`
const Guide__Mail__Subject = styled(Text)`


`
const Guide__mail__Subject__Holder = styled(FlexBox)`
  margin: 0 10px;
  padding: 10px;
  flex-direction: column;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid ${({theme}) => rgba(theme.color, 0.4)};
  border-bottom: none;
`
const Guide__Mail__Ending = styled(FlexBox)`
  flex-direction: column;
`
const Guide__Mail__Schema__Title = styled(Text)`
  font-size: 16px;
  font-weight: bold;
`
const Guide__Mail__Quick__Information = styled(Text)`
  margin: 10px;
`
const Guide__Mail__Quick__Information__Title = styled(Text)`
  font-weight: bold;
  margin: 0 10px;
`
export const Styled = {
    Guide__Mail__Quick__Information__Title,
    Guide__Mail__Quick__Information,
    Guide__Mail__Schema__Title,
    Guide__Mail__Ending,
    Guide__mail__Subject__Holder,
    Guide__Mail__Subject,
    Guide__Mail__Mail__Holder,
    Guide__Mail__Section__Explanation,
    Guide__Mail__Short__Description,
    Guide__Mail__Title,
    Guide__Mail__Mail__Example,
    Guide__Mail__Structure__Holder,
    Letters_Counter__Label,
    Traditional__Support__Points,
    Support__Half__Title,
    Traditional__Support__Holder,
    Check__Box,
    Submit__Button,
    Quick__Support__Holder,
    Text__Area,
    Input__Name,
    Support__Holder,
    Faq__Answer__Label,
    Faq__Answer__Holder,
    Faq__Option__Status__Icon,
    Faq__Question__Label,
    Faq__Question__Holder,
    Faq__Option,
    Faq__Holder,
    Contact__Page__Option__Title,
    Contact__Page__Option__Icon,
    Contact__Page__Main__Half__Option,
    Contact__Page__Main__Holder,
    Contact__Page__Title,
    Contact__Page__Title__Description,
    Contact__Page__Holder
}
