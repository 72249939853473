import {useRef} from 'react';

function areHookInputsEqual(
    nextDeps: any[],
    prevDeps: any[],
): boolean {
    if (nextDeps.length !== prevDeps.length) {
        return false;
    }

    for (let i = 0; i < prevDeps.length && i < nextDeps.length; i++) {
        if (Object.is(nextDeps[i], prevDeps[i])) {
            // eslint-disable-next-line no-continue
            continue;
        }
        return false;
    }
    return true;
}

// Create ref with current = initialRef()
// reset function defaults to initialRef()
// on deps change get new ref using resetRef function

const useRefWithReset = <D>(initialRef: () => D, resetDeps: any[], resetRef: (org: D) => D = initialRef): { current: D } => {
    const mutableRefObject = useRef<any>();
    if (mutableRefObject.current === undefined) {
        mutableRefObject.current = {
            object: {current: initialRef()},
            resetDeps,
        };
    } else if (!areHookInputsEqual(resetDeps, mutableRefObject.current.resetDeps)) {
        mutableRefObject.current.object.current = resetRef(mutableRefObject.current.object.current);
        mutableRefObject.current.resetDeps = resetDeps;
    }
    return mutableRefObject.current.object;
};

export default useRefWithReset;
