import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Section__Name__Holder = styled(FlexBox)<{ isLast: boolean, disabledSection: boolean, isOpen: boolean }>`
  cursor: ${({disabledSection}) => disabledSection ? 'not-allowed' : 'pointer'};
  padding: ${({isOpen}) => isOpen ? '25px 10px' : '15px 10px'};;
  border-bottom: ${({
                      theme,
                      isOpen,
                    }) => isOpen ? undefined : `1px solid ${theme.borderColor}`};;
  width: 100%;

  &:first-of-type {
    margin-top: 10px;
    border-top: 1px solid ${({theme}) => theme.borderColor};
  }
`
const Section__Label = styled(Text)`
  user-select: none;
  font-size: 18px;
  font-weight: bold;
`
const Section__Chevron__Icon = styled(FontAwesomeIcon)`
  color: ${(props: { theme: any; }) => props.theme.color};;
  font-size: 18px;
  margin-left: auto;
`

export const Styled = {
    Section__Name__Holder,
    Section__Label,
    Section__Chevron__Icon
}