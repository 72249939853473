import FlexBox from "../../lib/UIBookBrd/FlexBox";
import {Styled} from "./Calendar.styles";
import Select from "../../lib/UIBookBrd/SelectBookBrd/Select";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import useConfigureSite from "../../lib/UseHooksBookBrd/useConfigureSite";
import moment from "moment/moment";

type YearAndMonthForMonthRenderProps = {
    numberOfMonths: number | undefined
    month: any
    setIsSelectOpen: any
    monthsOptions: any[]
    onMonthSelect: any
    yearOptions: any[]
    onYearSelect: any

}


const YearAndMonthForMonthRender = (
    {
        numberOfMonths,
        month,
        setIsSelectOpen,
        monthsOptions,
        onMonthSelect,
        yearOptions,
        onYearSelect
    }: YearAndMonthForMonthRenderProps) => {

    const monthValue = month.month()


    const {Language} = useConfigureSite()

    moment.locale(Language)


    const {t} = useTranslation();

    const yearSelectOnChange = (values: any) => {
        const newValue = values.label
        onYearSelect(month, newValue)
    }

    const monthsSelectOnChange = (values: any) => {
        const newValue = values.value as number - 1;
        onMonthSelect(month, newValue)
    }

    useEffect(() => {
        if (monthValue !== month.month()) {
            onMonthSelect(month, monthValue);
        }
    }, [month]);
    return (
        <FlexBox
            style={{
                marginLeft: "-2px",
                minHeight: '34px',
                flexGrow: "1",
                flexDirection: 'column'
            }}
        >
            {numberOfMonths === 1 && (
                <FlexBox style={{flexWrap: 'nowrap', gap: '4px', maxWidth: '200px', margin: '0 auto'}}>
                    <Styled.SelectHolder>
                        <Select
                            title={t('lbl_month')}
                            isClearAble={false}
                            setIsSelectOpen={setIsSelectOpen}
                            isMulti={false}
                            appliedList={[
                                {
                                    label: t(`lbl_${month?.format('MMMM').toLowerCase()}`),
                                    value: month?.format('MMMM'),
                                }]}
                            setAppliedList={monthsSelectOnChange}
                            options={monthsOptions}
                        />
                    </Styled.SelectHolder>
                    <Styled.SelectHolder>
                        <Select
                            title={t('lbl_year')}
                            isClearAble={false}
                            setIsSelectOpen={setIsSelectOpen}
                            isMulti={false}
                            appliedList={[
                                {
                                    label: month?.format('YYYY'),
                                    value: month?.format('YYYY'),
                                }]}
                            setAppliedList={yearSelectOnChange}
                            options={yearOptions}
                        />
                    </Styled.SelectHolder>
                </FlexBox>
            )}
            <Styled.Month__Label>
                {month.format('MMM YYYY')}
            </Styled.Month__Label>
        </FlexBox>
    )
}

export default YearAndMonthForMonthRender