import {useContext} from "react";
import {Local_Data} from "../Context/Context";

const useUserData = () => {

    const [localDataValues, setLocalDataValues] = useContext<any>(Local_Data)


    const backgroundImage = () => {
        if (localDataValues?.userData?.isLogged) {
            if (localDataValues?.userData?.backGroundImage) {
                return localDataValues?.userData?.backGroundImage
            } else {
                return 'https://firebasestorage.googleapis.com/v0/b/bookbrd.appspot.com/o/IMG_2933%203%20(2)%20Large.jpeg?alt=media&token=f06e928c-faae-408a-88a3-06f9c9d2c1ca'
            }
        } else {
            return 'https://firebasestorage.googleapis.com/v0/b/bookbrd.appspot.com/o/IMG_2933%203%20(2)%20Large.jpeg?alt=media&token=f06e928c-faae-408a-88a3-06f9c9d2c1ca'
        }
    }

    return ({
        isLogged: localDataValues?.userData?.isLogged,
        userImage: localDataValues?.userData?.image,
        userName: localDataValues?.userData?.name,
        deleteBackground: localDataValues?.userData?.deleteBackground,
        userBackgroundColor: localDataValues?.userData?.backgroundColor,
        userBackgroundImage: backgroundImage()
    })
}

export default useUserData