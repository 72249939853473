import {useTranslation} from "react-i18next";
import {Styled} from "../../TripOffer.styles";

type CustomTextProps = {
    type: string | number | undefined
    value: string | number | undefined
    onValueClick?: (event: any) => void
}
const defaultProps = {
    onValueClick: undefined
}


const CustomText = (
    {
        type,
        value,
        onValueClick
    }: CustomTextProps
) => {

    const {t} = useTranslation();

    return (
        <Styled.CustomText>
            <Styled.TitleText>
                {t(`${type}`)}
                {':'}
            </Styled.TitleText>
            <Styled.ValueText
                isClickAble={!!onValueClick}
                onClick={onValueClick}
            >
                {value}
            </Styled.ValueText>
        </Styled.CustomText>
    )
}
CustomText.defaultProps = defaultProps

export default CustomText