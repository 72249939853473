import {Styled} from "./OfferWeather.styles"
import {useContext, useEffect, useState} from "react";
import {isEmpty, toNumber} from "lodash-es";
import {faCloud, faSun} from "@fortawesome/pro-solid-svg-icons";
import {Line} from 'react-chartjs-2';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {useTranslation} from "react-i18next";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {ThemeContext} from "styled-components";
import {rgba} from "polished";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";
import BrdLoader from "../../../../../../lib/UIBookBrd/BrdLoader";

type OfferWeatherProps = {
    isLoadingSections: boolean,
    offerControls: any,
    offer: any,


}

const OfferWeather = (
    {
        isLoadingSections,
        offerControls,
        offer
    }: OfferWeatherProps
) => {
    
    const {t} = useTranslation();

    const theme = useContext(ThemeContext)

    const [displayedMonthIndex, setDisplayedMonthIndex] = useState(toNumber(offer?.Base?.StartDate?.split('-')[1]) - 1)

    const labels = [
        t('lbl_january'),
        t('lbl_february'),
        t('lbl_march'),
        t('lbl_april'),
        t('lbl_may'),
        t('lbl_june'),
        t('lbl_july'),
        t('lbl_august'),
        t('lbl_september'),
        t('lbl_october'),
        t('lbl_november'),
        t('lbl_december'),
    ]

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const data = {
        labels,
        datasets: [
            {
                label: t('lbl_highest_temperature'),
                data: labels.map((_L: any, index: number) => offerControls?.Weather?.Data[index]?.HighestAirTemperature),
                prefix: 'temp',
                pointBackgroundColor: Array.from({length: 12}).map((_: any, index: number) => index === displayedMonthIndex ? theme.isDark ? '#f1ee42' : '#d9d617' : theme.color),
                pointBorderColor: Array.from({length: 12}).map((_: any, index: number) => index === displayedMonthIndex ? theme.isDark ? '#f1ee42' : '#d9d617' : theme.color),
                borderColor: theme.color,
                backgroundColor: theme.color,
                pointRadius: Array.from({length: 12}).map((_: any, index: number) => index === displayedMonthIndex ? 6 : 5),
            },
        ],
    };

    const options = {
        legend: {
            labels: {
                fontColor: 'white',
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event: any, chartElement: any) => {
            if (chartElement && chartElement.length) {
                const firstPoint = chartElement[0];
                setDisplayedMonthIndex(firstPoint?.index)
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: theme.color,
                },
                position: 'top' as const,
            },
            title: {
                display: false,
                text: offer?.Base?.XCity?.Name ?? '',
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }

                        if (context.parsed.y !== null) {
                            label += context.parsed.y + '°C';
                        }

                        return label;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    color: rgba(theme.color, 0.2),
                },
                ticks: {
                    color: theme.color,
                },
            },
            y: {
                grid: {
                    color: rgba(theme.color, 0.2),
                },
                suggestedMax: Math.max(...labels.map((_L: any, index: number) => offerControls?.Weather?.Data[index]?.HighestAirTemperature)) + 2,
                ticks: {
                    callback: (value: any) => {
                        return `${value}°C`;
                    },
                    color: theme.color,
                },
            },
        },

    };

    useEffect(() => {
        const tripMonth = toNumber(offer?.Base?.StartDate?.split('-')[1]);
        setDisplayedMonthIndex(tripMonth - 1)
    }, [offerControls])


    if (isLoadingSections) {
        return (
            <Styled.Loading__Offer__Weather>
                <Styled.Loader__Title__Holder>
                    <Styled.Loader__Title>
                        {t('lbl_loading_weather')}
                    </Styled.Loader__Title>
                    <BrdLoader/>
                </Styled.Loader__Title__Holder>
            </Styled.Loading__Offer__Weather>
        )
    }

    return (
        <Styled.OfferWeatherHolder>
            {!isEmpty(offerControls?.Weather?.Data) ? (
                <Styled.Main_Month_Value_Holder>
                    <Styled.Chart_Value_Holder>
                        <Styled.Chart__Data__Holder>
                            <Line
                                data={data}
                                options={options as any}
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        </Styled.Chart__Data__Holder>
                        <Styled.Weather__Values__Holder>
                            <FlexBox style={{padding: '4px 2px'}}>
                                <Styled.Weather__Icon
                                    icon={faSun}
                                />
                                <Styled.Weather__Icon
                                    icon={faCloud}
                                />
                                {isLoadingSections ? (
                                    <SkeletonBox
                                        style={{
                                            marginLeft: '5px',
                                            marginTop: 'auto'
                                        }}
                                        height={'18px'}
                                    />
                                ) : (
                                    <Styled.Displayed__Title>
                                        {labels[displayedMonthIndex]}
                                    </Styled.Displayed__Title>
                                )}
                            </FlexBox>
                            <Styled.Data__Chart__Value>
                                <FlexBox
                                    style={{flexWrap: 'nowrap'}}>
                                    <Text
                                        style={{
                                            marginRight: '2px'
                                        }}
                                    >
                                        {t('lbl_average_temperature')}
                                        {': '}
                                    </Text>
                                    {isLoadingSections ? (
                                        <SkeletonBox
                                            style={{
                                                margin: '0',
                                                marginLeft: '2px',
                                            }}
                                            height={'18px'}
                                            width={'20px'}
                                        />
                                    ) : (
                                        <Text
                                            style={{
                                                fontWeight: 'bold'
                                            }}>
                                            {(toNumber(offerControls?.Weather?.Data[displayedMonthIndex]?.HighestAirTemperature) + toNumber(offerControls?.Weather?.Data[displayedMonthIndex]?.LowestAirTemperature)) / 2}
                                            {'°C'}
                                        </Text>
                                    )}
                                </FlexBox>
                                <FlexBox
                                    style={{flexWrap: 'nowrap'}}>
                                    <Text
                                        style={{
                                            marginRight: '2px'
                                        }}
                                    >
                                        {t('lbl_highest_temperature')}
                                        {': '}
                                    </Text>
                                    {isLoadingSections ? (
                                        <SkeletonBox
                                            style={{
                                                margin: '0',
                                                marginLeft: '2px',
                                            }}
                                            height={'18px'}
                                            width={'20px'}
                                        />
                                    ) : (
                                        <Text
                                            style={{
                                                fontWeight: 'bold'
                                            }}>
                                            {toNumber(offerControls?.Weather?.Data[displayedMonthIndex]?.HighestAirTemperature)}
                                            {'°C'}
                                        </Text>
                                    )}
                                </FlexBox>
                                <FlexBox
                                    style={{flexWrap: 'nowrap'}}>
                                    <Text
                                        style={{
                                            marginRight: '2px'
                                        }}
                                    >
                                        {t('lbl_lowest_temperature')}
                                        {': '}
                                    </Text>
                                    {isLoadingSections ? (
                                        <SkeletonBox
                                            style={{
                                                margin: '0',
                                                marginLeft: '2px',
                                            }}
                                            height={'18px'}
                                            width={'20px'}
                                        />
                                    ) : (
                                        <Text
                                            style={{
                                                fontWeight: 'bold'
                                            }}>
                                            {toNumber(offerControls?.Weather?.Data[displayedMonthIndex]?.LowestAirTemperature)}
                                            {'°C'}
                                        </Text>
                                    )}
                                </FlexBox>
                                <FlexBox
                                    style={{flexWrap: 'nowrap'}}>
                                    <Text
                                        style={{
                                            marginRight: '2px'
                                        }}
                                    >
                                        {t('lbl_average_water_temperature')}
                                        {': '}
                                    </Text>
                                    {isLoadingSections ? (
                                        <SkeletonBox
                                            style={{
                                                margin: '0',
                                                marginLeft: '2px',
                                            }}
                                            height={'18px'}
                                            width={'20px'}
                                        />
                                    ) : (
                                        <Text
                                            style={{
                                                fontWeight: 'bold'
                                            }}>
                                            {toNumber(offerControls?.Weather?.Data[displayedMonthIndex]?.WaterTemperature)}
                                            {'°C'}
                                        </Text>
                                    )}
                                </FlexBox>
                                <FlexBox
                                    style={{flexWrap: 'nowrap'}}>
                                    <Text
                                        style={{
                                            marginRight: '2px'
                                        }}
                                    >
                                        {t('lbl_humidity')}
                                        {': '}
                                    </Text>
                                    {isLoadingSections ? (
                                        <SkeletonBox
                                            style={{
                                                margin: '0',
                                                marginLeft: '2px',
                                            }}
                                            height={'18px'}
                                            width={'20px'}
                                        />
                                    ) : (
                                        <Text
                                            style={{
                                                fontWeight: 'bold'
                                            }}>
                                            {offerControls?.Weather?.Data[displayedMonthIndex]?.Humidity ? `${offerControls?.Weather?.Data[displayedMonthIndex]?.Humidity}%` : ` ${t('lbl_not_specified')}`}
                                        </Text>
                                    )}
                                </FlexBox>
                            </Styled.Data__Chart__Value>
                        </Styled.Weather__Values__Holder>
                    </Styled.Chart_Value_Holder>
                </Styled.Main_Month_Value_Holder>
            ) : (
                <FlexBox
                    style={{
                        width: '100%',
                        height: '150px'
                    }}
                >
                    <Styled.No__Weather__Label>
                        {t('lbl_no_weather_label')}
                    </Styled.No__Weather__Label>
                </FlexBox>
            )}
        </Styled.OfferWeatherHolder>
    )
}
export default OfferWeather