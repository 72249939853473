import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";
import Button from "../../../lib/UIBookBrd/Button";

const Content__Holder = styled(FlexBox)`
  height: 100%;
  width: calc(100% - 10px);
  padding: 5px;
  padding-bottom: 10px;
  flex-direction: column;
`
const Explanation__Label = styled(Text)`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`
const Option__Button = styled(Button)`
  min-width: 130px;
  height: 30px;
`

export const Styled = {
    Content__Holder,
    Explanation__Label,
    Option__Button
}