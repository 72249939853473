import {promiseRequest} from "../../../lib/RequestBuilder";
import {CONTENT_SERVICE_URL} from "../../../lib/Configs/RequestConfig";
import {includes, isEmpty, toNumber} from "lodash-es";
import {useContext} from "react";
import {Booking_Page, Search_Proto} from "../../../lib/Context/Context";
import useBookBrdPageFunctions, {getParticipantsFromHash} from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {participantsType} from "./types";
import {adultsInputs, childInputs} from "./Consts";
import {useTranslation} from "react-i18next";
import useLocalData from "../../../lib/UseHooksBookBrd/useSearchBaseData";
import useNavigation from "../../../lib/UseHooksBookBrd/useNavigation";
import {ORDER_STATUS} from "../../../lib/Routes";
import useHandleOnlineRequestError from "../../../lib/UseHooksBookBrd/useHandleOnlineRequestError";

const useBookingFunctions = () => {

    const {t} = useTranslation();

    const navigate = useNavigation()

    const [protoValue, _] = useContext<any>(Search_Proto)

    const {offerDetails} = protoValue

    const [bookingValues, setBookingValues] = useContext<any>(Booking_Page)

    const {getV5Error} = useHandleOnlineRequestError()

    const {
        checkIfMailValid,
        getValidCardDate,
    } = useBookBrdPageFunctions()

    const {toggleDisableScroll} = useLocalData()


    ///AXIOS
    const getImages = async (
        offer: any,
        setOffer: any
    ) => {
        try {
            const request = await promiseRequest(`${CONTENT_SERVICE_URL}Pictures`, {
                Sections: ['Pictures'],
                Language: 'pl',
                HotelXCode: offer?.Base?.XCode?.Id,
                HotelCode: `${offer?.Accommodation?.Code}`,
                Operator: offer?.Base?.Operator,
                offerId: offer?.Base?.OfferId,
                PictureSizes: "full"
            });
            const {Sections} = request
            const {
                Pictures,
            } = Sections
            const {PictureUrlFull} = Pictures
            setOffer((state: any) => ({
                ...state,
                offerId: offer?.Base?.OfferId,
                pictures: PictureUrlFull,
                offer
            }))
        } catch (e) {
        } finally {
            setOffer((state: any) => ({
                ...state,
                isLoading: false
            }))
        }
    }

    const getDetails = async (setOffer: any) => {
        try {
            const {offerDetails} = protoValue
            setOffer({
                isLoading: true,
            })
            //Values
            // @ts-ignore
            const v5DataRequest = await promiseRequest(`https://mwsv5nmc.merlinx.pl/v5/data/travel/details?Base.OfferId=${offerDetails}`, null, 3);
            const {result} = v5DataRequest
            if (!isEmpty(result)) {
                const {offer} = result
                return offer
            }
        } catch (e) {
            setOffer((state: any) => ({
                ...state,
                error: getV5Error(e)
            }))
            console.log({e, maks: 'maks'})
        } finally {
            //
        }
    }

    const getDetailsAndPictures = (
        setOffer: any
    ) => {
        getDetails(setOffer).then((offer: any) => {
            getImages(
                offer,
                setOffer
            )
        })
    }

    // Normal

    const onInputChangeHandler = (inputValue: any, sectionId: string, inputType: string) => {
        let newValue = bookingValues?.participantsSection ?? {}
        switch (inputType) {
            case 'cardDate':
                const validCardDateValue = inputValue.split(' / ').join('').replace(/[^0-9]/g, '')
                if (validCardDateValue.length < 7) {
                    newValue[sectionId][inputType] = validCardDateValue
                }
                break
            case 'cvc':
                const cvcValidValue = inputValue.replace(/[^0-9]/g, '')
                if (cvcValidValue.length < 4) {
                    newValue[sectionId][inputType] = cvcValidValue
                }
                break
            case 'cardNumber':
                const cardNumberValidValue = inputValue.split(' ').join('').replace(/[^0-9]/g, '')
                if (cardNumberValidValue.length < 17) {
                    newValue[sectionId][inputType] = cardNumberValidValue
                }
                break
            case 'cardHolder':
                if (isEmpty(inputValue) || /^[A-Za-z\s]+$/.test(inputValue)) {
                    newValue[sectionId][inputType] = inputValue
                }
                break
            default:
                newValue[sectionId][inputType] = inputValue
                break
        }
        sessionStorage.setItem('BookingProvidedValues', JSON.stringify({...newValue, offerId: offerDetails}))
        setBookingValues((state: any) => ({
            ...state,
            offerId: offerDetails,
            participantsSection: newValue,
            inValidFields: [...state?.inValidFields ?? []]?.filter((item: string) => item !== `${sectionId}_${inputType}`)
        }))
    }

    const scrollToElement = (element: any) => {
        element.scrollIntoView({behavior: 'smooth', block: 'center'});
    }

    const onEnterPressed = (currentRef: string) => {
        const type = currentRef.split('_')[0] as participantsType
        const typeNumber = toNumber(currentRef.split('_')[1])
        const inputIndex = toNumber(currentRef.split('_')[2] ?? 0)
        const maxInputNumber = type === participantsType.child ? adultsInputs.length - 1 : childInputs?.length - 1
        const maxTypeNumber = type === participantsType.child ? participants?.adultsCount as number - 1 : participants?.childDates?.length as number - 1
        if (inputIndex === maxInputNumber) {
            if (type === participantsType.adult) {
                if (maxTypeNumber === typeNumber) {
                    if (childInputs?.length !== 0) {
                        document.getElementById(`${participantsType.adult}_0_0`)?.focus()
                        scrollToElement(document.getElementById(`${participantsType.adult}_0_0`))
                    } else {
                        document.getElementById(`${participantsType.payment}_0`)?.focus()
                        scrollToElement(document.getElementById(`${participantsType.payment}_0`))
                    }
                } else {
                    document.getElementById(`${participantsType.child}_${typeNumber + 1}_0`)?.focus()
                    scrollToElement(document.getElementById(`${participantsType.child}_${typeNumber + 1}_0`))
                }
            }
            if (type === participantsType.child) {
                if (maxTypeNumber === typeNumber) {
                    document.getElementById(`${participantsType.payment}_0`)?.focus()
                    scrollToElement(document.getElementById(`${participantsType.payment}_0`))
                } else {
                    document.getElementById(`${participantsType.child}_${typeNumber + 1}_0`)?.focus()
                    scrollToElement(document.getElementById(`${participantsType.child}_${typeNumber + 1}_0`))
                }
            }
        } else {
            if ([participantsType.adult, participantsType.child].includes(type)) {
                document.getElementById(`${type}_${typeNumber}_${inputIndex + 1}`)?.focus()
                scrollToElement(document.getElementById(`${type}_${typeNumber}_${inputIndex + 1}`))
            }
            if (type === participantsType.payment) {
                document.getElementById(`${type}_${typeNumber + 1}`)?.focus()
                scrollToElement(document.getElementById(`${type}_${typeNumber + 1}`))
            }
        }
    }

    const getInputType = (inputName: string) => {
        switch (inputName) {
            case 'mail':
                return 'email'
            default:
                return 'text'
        }
    }

    const getPlaceHolder = (inputName: string) => {
        switch (inputName) {
            case 'name':
                return t('lbl_name_placeholder')
            case 'surname':
                return t('lbl_surname_placeholder')
            case 'mail':
                return 'example@bookbrd.com'
            case 'country':
                return t('lbl_country_placeholder')
            case 'address':
                return t('lbl_address_placeholder')
            case 'city':
                return t('lbl_city_placeholder')
            case 'postCode':
                return t('lbl_postcode_placeholder')
            case 'cardNumber':
                return 'XXXX XXXX XXXX XXXX'
            case 'cardDate':
                return 'MM / YYYY'
            case 'cardHolder':
                return t('lbl_cardHolder_placeholder')
            case 'cvc':
                return '123'
        }
    }

    const getAutoComplete = (inputName: string) => {
        switch (inputName) {
            case 'name':
                return 'given-name'
            case 'surname':
                return 'family-name'
            case 'mail':
                return 'email'
            case 'country':
                return 'country-name'
            case 'address':
                return 'address'
            case 'city':
                return 'address-level2'
            case 'postCode':
                return 'postal-code'
            case 'cardNumber':
                return 'cc-number'
            case 'cardDate':
                return 'cc-exp'
            case 'cardHolder':
                return 'cc-name'
            case 'cvc':
                return 'cc-cvc'
        }
    }

    const getCardNumberValue = (value: any) => {
        if (isEmpty(value)) {
            return 'XXXX XXXX XXXX XXXX'
        }
        let newValue = ''
        for (let i = 0; i < 16; i++) {
            const number = value[i]
            newValue += `${number ?? 'X'}${includes([3, 7, 11], i) ? ' ' : ''}`
        }
        return newValue

    }

    const getCardNumberValueInput = (value: any) => {
        if (isEmpty(value)) {
            return ''
        }
        let newValue = ''
        for (let i = 0; i < value.length; i += 4) {
            const chunk = value.substring(i, i + 4);
            newValue += chunk;

            // Add a space if it's not the last chunk
            if (i + 4 < value.length) {
                newValue += " ";
            }
        }
        return newValue

    }

    const getCardDateValue = (value: any) => {
        let newValue = ''
        for (let i = 0; i < value?.length ?? 0; i++) {
            const number = value[i]

            if (i === 1 && (value?.length ?? 0) > 2) {
                newValue += `${number} / `
            } else {
                newValue += number
            }

        }

        return newValue;
    }

    const getCardDateValueForCard = (value: any) => {
        if (isEmpty(value)) {
            return 'MM /YY'
        }

        const monthFirstNumber = value[0]
        const monthSecondNumber = value[1]
        const yearFirstNumber = value[4]
        const yearSecondNumber = value[5]

        return `${monthFirstNumber ?? 'M'}${monthSecondNumber ?? 'M'} / ${yearFirstNumber ?? 'Y'}${yearSecondNumber ?? 'Y'}`
    }

    const getCheckBoxValue = (type: string) => {
        const checkBoxes = bookingValues?.checkBoxes ?? []
        if (includes(checkBoxes, type)) {
            return checkBoxes.filter((option: string) => option !== type)
        } else {
            checkBoxes.push(type)
        }
        return checkBoxes

    }

    const checkBoxChangeHandler = (type: string) => {
        setBookingValues((state: any) => ({
            ...state,
            checkBoxes: getCheckBoxValue(type)
        }))
    }

    const toggleIsLoadingResults = (bool: boolean) => {
        setBookingValues((state: any) => ({
            ...state,
            isFinalising: bool
        }))
    }

    const setFinalResult = (result: 'approved' | 'declined' | undefined) => {
        setBookingValues((state: any) => ({
            ...state,
            finalResult: result
        }))
        if (result === 'approved') {
            setTimeout(
                () => {
                    toggleDisableScroll(false)
                    navigate(`${ORDER_STATUS}/781217102023`)
                },
                1000 * 5
            )
        }
        if (result === 'declined') {
            setTimeout(
                () => {
                    setFinalResult(undefined)
                    toggleDisableScroll(false)
                    toggleIsLoadingResults(false)
                },
                1000 * 5
            )
        }
    }

    const reserveButtonClickHandler = () => {
        const invalidInputs: any[] = []

        Object.keys(bookingValues?.participantsSection)?.map((section: string) => {
            const sectionValue = bookingValues?.participantsSection[section]
            Object.keys(sectionValue)?.map((field: any) => {
                const fieldValue = sectionValue[field]
                const fieldName = `${section}_${field}`
                switch (field) {
                    case 'email':
                        const isValidMail = checkIfMailValid(field)
                        if (!isValidMail) {
                            invalidInputs.push(fieldName)
                        }
                        break
                    case 'cvc':
                        const isValidCvc = fieldValue.length === 3
                        if (!isValidCvc) {
                            invalidInputs.push(fieldName)
                        }
                        break
                    case 'cardNumber':
                        const isValidCardNumber = fieldValue.length === 16
                        if (!isValidCardNumber) {
                            invalidInputs.push(fieldName)
                        }
                        break
                    case 'cardDate':
                        const isValid = getValidCardDate(String(fieldValue))
                        if (!isValid) {
                            invalidInputs.push(fieldName)
                        }
                        break
                    default:
                        if (isEmpty(fieldValue)) {
                            invalidInputs.push(fieldName)
                        }
                        break
                }


            })
        })
        if (isEmpty(invalidInputs)) {
            toggleDisableScroll(true)
            toggleIsLoadingResults(true)
            setTimeout(() => {
                setFinalResult('approved')
            }, 1000 * 10)
        } else {
            setBookingValues((state: any) => ({
                ...state,
                inValidFields: invalidInputs
            }))
        }
    }

    const participants = getParticipantsFromHash(offerDetails ?? undefined)

    return {
        setFinalResult,
        toggleIsLoadingResults,
        getCardDateValue,
        getCardNumberValueInput,
        getCardDateValueForCard,
        getCardNumberValue,
        onInputChangeHandler,
        getInputType,
        getPlaceHolder,
        getAutoComplete,
        onEnterPressed,
        participants,
        state: bookingValues,
        reserveButtonClickHandler,
        checkBoxChangeHandler,
        getDetailsAndPictures,
    }
}
export default useBookingFunctions