import {Styled} from "./CookiesDrawer.styles";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import React from "react";
import {useTranslation} from "react-i18next";
import {faCookieBite} from "@fortawesome/pro-solid-svg-icons";
import useConfigureSite from "../../../lib/UseHooksBookBrd/useConfigureSite";
import Button from "../../../lib/UIBookBrd/Button";
import {Modal} from "../../../lib/UIBookBrd/Modal/ModalsTypes";
import BrdLogo from "../../BrdLogos";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {faGlobe} from "@fortawesome/pro-light-svg-icons";
import {isMobileOnly} from "react-device-detect";
import Drawer from "../../../lib/UIBookBrd/Drawer";


const CookiesDrawer = () => {

    const {t} = useTranslation();

    const {
        showCookiesDrawer,
        onCookiesChangeHandler
    } = useConfigureSite()

    const {
        onOpenLanguageModal
    } = useBookBrdPageFunctions()

    const content = (
        <Styled.Content__Holder>
            <FlexBox>
                <BrdLogo/>
                <Styled.Globe__Icon
                    icon={faGlobe}
                    onClick={onOpenLanguageModal}
                />
            </FlexBox>
            <FlexBox style={{paddingTop: '10px'}}>
                <Styled.Cookie__Icon
                    icon={faCookieBite}
                    color="inherit"
                />
                <Styled.Drawer__Title>
                    {t('lbl_cookies_drawer_title')}
                </Styled.Drawer__Title>

            </FlexBox>
            <FlexBox>
                <Styled.Cookies__Description>
                    {t('lbl_cookies_desc')}
                </Styled.Cookies__Description>
            </FlexBox>
            <FlexBox style={{gap: "5px", paddingTop: "15px"}}>
                <Button
                    label={t('lbl_accept_cookies')}
                    style={{marginLeft: "auto", width: isMobileOnly ? "100%" : undefined}}
                    onClick={() => {
                        onCookiesChangeHandler('accept')
                    }}
                />

            </FlexBox>
        </Styled.Content__Holder>
    )

    return !isMobileOnly ? (
        <Modal
            width={500}
            showOverlay={true}
            positionedByPopper={true}
            isOpen={showCookiesDrawer}
        >
            {content}
        </Modal>
    ) : (
        <Drawer
            isOpen={showCookiesDrawer}
            position={'bottom'}
        >
            {content}

        </Drawer>
    )

}
export default CookiesDrawer