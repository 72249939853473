import FlexBox from "../../../../../../../../lib/UIBookBrd/FlexBox";
import {Styled} from "../../TripOffer.styles";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import StarIcons from "../../../../../../../../Components/StarIcons";
import Tooltip from "../../../../../../../../lib/UIBookBrd/Tooltip";
import {useTranslation} from "react-i18next";
import Text from "../../../../../../../../lib/UIBookBrd/Text";
import {faHeart as lightHeart} from "@fortawesome/pro-light-svg-icons";
import {faHeart} from "@fortawesome/pro-solid-svg-icons";

type TitleProps = {
    isExlusive?: boolean
    offerData: any
    isOfferFocused: boolean
    onFavouriteClickHandler: () => void
    onOfferCloseHandler: () => void
    isFavourite?: boolean
}

const Title = (
    {
        isFavourite,
        isExlusive,
        offerData,
        onFavouriteClickHandler,
        isOfferFocused,
        onOfferCloseHandler
    }: TitleProps
) => {

    const {t} = useTranslation();

    const toolTipContent = (
        <FlexBox
            style={{
                flexDirection: "column",
                padding: '2px 4px',
                maxWidth: '300px'
            }}
        >
            <Text
                style={{
                    fontSize: '12px'
                }}
            >
                {t('brd_exclusive_desc')}
            </Text>
        </FlexBox>
    )

    return (
        <FlexBox
            style={{width: '100%', flexWrap: "nowrap"}}
        >
            <Styled.Title>
                {offerData?.Base?.XCode?.Name}
            </Styled.Title>
            <FlexBox style={{flexWrap: 'nowrap'}}>
                <StarIcons
                    number={offerData?.Accommodation?.Category}
                />
            </FlexBox>
            {!!isExlusive && (
                <Tooltip
                    content={toolTipContent}
                >
                    <Styled.Exlusive__Label>
                        Brd Exclusive
                    </Styled.Exlusive__Label>
                </Tooltip>
            )}
            <Styled.Heart__Icon
                onClick={(event: any) => {
                    event.stopPropagation();
                    onFavouriteClickHandler()
                }}
                isExlusive={!!isExlusive}
                isFavourite={!!isFavourite}
                icon={!!isFavourite ? faHeart : lightHeart}
            />
            <Styled.CloseIconHolder
                isExlusive={!!isExlusive}
            >
                {isOfferFocused && (
                    <Styled.CloseIcon
                        onClick={onOfferCloseHandler}
                        icon={faClose}
                    />
                )}
            </Styled.CloseIconHolder>
        </FlexBox>
    )
}
export default Title