import {Styled} from "./App.styles"
import {useEffect, useRef, useState} from "react";
import {useDebouncedCallback} from "use-debounce";
import {WCSErrors} from "./lib/UseHooksBookBrd/useHandleOnlineRequestError";
import {useTranslation} from "react-i18next";
import FlexBox from "./lib/UIBookBrd/FlexBox";

type ErrorScreenType = {
    errorType: WCSErrors
}

const ErrorScreen = (
    {
        errorType
    }: ErrorScreenType) => {

    const ref = useRef<any>()

    const {t} = useTranslation();

    const [height, setHeight] = useState<any>({})

    const setHeightFunc = useDebouncedCallback(() => {
        setHeight({
            elementHeight: ref?.current?.clientHeight,
            windowHeight: window?.innerHeight
        })
    }, 200)

    const getErrorContent = () => {

        switch (errorType) {
            case "NO_TOKEN":
                return (
                    <>
                        <Styled.TextHolder>
                            <Styled.RegularFont>
                                Book
                            </Styled.RegularFont>
                            <Styled.TiltedFont>
                                Brd
                            </Styled.TiltedFont>
                        </Styled.TextHolder>
                        <Styled.ComingSoonLabel>
                            Coming soon
                        </Styled.ComingSoonLabel>
                    </>
                )
            case "INVALID_TOKEN":
                return (
                    <>
                        <Styled.TextHolder>
                            <Styled.RegularFont>
                                Book
                            </Styled.RegularFont>
                            <Styled.TiltedFont>
                                Brd
                            </Styled.TiltedFont>
                            <br/>
                            <span style={{
                                fontSize: '20px',
                                fontWeight: "bold"
                            }}
                            >
                                INVALID TOKEN
                            </span>
                            <Styled.ActionButton
                                label={t('lbl_reload_page')}
                                onClick={() => {
                                    window.location.reload();
                                }}
                            />
                        </Styled.TextHolder>
                    </>
                )
            case "NO_HEALTHY_UPSTREAM":
                return (
                    <>
                        <Styled.TextHolder>
                            <Styled.RegularFont>
                                Book
                            </Styled.RegularFont>
                            <Styled.TiltedFont>
                                Brd
                            </Styled.TiltedFont>
                            <br/>
                            <span style={{
                                fontSize: '20px',
                                fontWeight: "bold"
                            }}
                            >
                                {t('lbl_no_healthy_upstream')}
                            </span>
                            <br/>
                            <span>
                                {t('lbl_no_healthy_upstream_desc')}
                            </span>
                            <Styled.ActionButton
                                label={t('lbl_reload_page')}
                                onClick={() => {
                                    window.location.reload();
                                }}
                            />
                        </Styled.TextHolder>

                    </>
                )
            case "UNKNOWN":
                return (
                    <>
                        <Styled.TextHolder>
                            <Styled.RegularFont>
                                Book
                            </Styled.RegularFont>
                            <Styled.TiltedFont>
                                Brd
                            </Styled.TiltedFont>
                            <br/>
                            <span style={{
                                fontSize: '20px',
                                fontWeight: "bold"
                            }}
                            >
                                {t('lbl_unknown_problem')} ( ._.)
                            </span>
                            <br/>
                            <span>
                                {t('lbl_unknown_problem_desc')}
                            </span>
                            <FlexBox
                                style={{gap: '4px', flexGrow: '1'}}
                            >
                                <Styled.ActionButton
                                    label={t('lbl_reload_page')}
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                />
                                <Styled.ActionButton
                                    label={t('lbl_report_issue')}
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                />
                            </FlexBox>
                        </Styled.TextHolder>

                    </>
                )
        }
    }


    useEffect(() => {
        setHeightFunc()
    }, [ref])


    useEffect(() => {
        window.addEventListener('resize', setHeightFunc)
        return () => {
            window.removeEventListener('resize', setHeightFunc)
        }
    }, [])

    return (
        <Styled.ErrorScreen>
            <Styled.MarginTopElement
                ref={ref}
                style={{
                    flexDirection: "column"
                }}
                windowHeight={height?.windowHeight}
                elementHeight={height?.elementHeight}
            >
                {getErrorContent()}
            </Styled.MarginTopElement>
        </Styled.ErrorScreen>
    )

}
export default ErrorScreen