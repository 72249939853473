import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Alternatives__Holder = styled(FlexBox)`
  flex-direction: initial;
  overflow: scroll;
  max-width: calc(100% - 10px);
  width: 100%;
  height: auto;
  gap: 10px;
  padding: 10px 5px;
  flex-wrap: nowrap;
`
const Alternatives__Section__Holder = styled(Text)`
  font-weight: bold;
`
const Alternatives__Holder__Loading = styled(FlexBox)`
  flex-direction: initial;
  overflow: hidden;
  max-width: calc(100% - 10px);
  width: 100%;
  height: auto;
  gap: 10px;
  padding: 10px 5px;
  flex-wrap: nowrap;
`
const Alternatives = styled.div`
  position: relative;
  overflow-x: hidden;
  padding: 5px 0;
  width: calc(100%);
`
const Direction__Button__Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  margin: auto;
  color: ${({theme}) => theme.color};
  transition: 200ms;
`
const Prev__Button = styled(FlexBox)`
  height: 36px;
  width: 36px;
  min-width: 36px;
  position: absolute;
  left: 0;
  top: calc(50% - 18px);
  z-index: 2;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.color};
  background-color: ${({theme}) => theme.backgroundColor};
  cursor: pointer;

  &:hover ${Direction__Button__Icon} {
    transform: scale(1.2);
  }
`
const Next__Button = styled(FlexBox)`
  height: 36px;
  width: 36px;
  min-width: 36px;
  right: 0;
  top: calc(50% - 18px);
  position: absolute;
  z-index: 2;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.color};
  background-color: ${({theme}) => theme.backgroundColor};
  cursor: pointer;

  &:hover ${Direction__Button__Icon} {
    transform: scale(1.2);
  }
`

export const Styled = {
    Direction__Button__Icon,
    Next__Button,
    Prev__Button,
    Alternatives,
    Alternatives__Holder__Loading,
    Alternatives__Holder,
    Alternatives__Section__Holder
}