import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";
import Image from "../../lib/UIBookBrd/Image";
import {isMobileOnly} from "react-device-detect";
import Button from "../../lib/UIBookBrd/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ManageAccountPageHolder = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-direction: column;
`
const Settings__Section__Title = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`
const Settings__Holder = styled(FlexBox)`
  width: calc(100% - 20px);

  flex-direction: column;
  padding: 10px;
  gap: 10px 0;
`
const User__Background__Image = styled(Image)`
  width: 100%;
  height: 200px;
  object-fit: cover;
`
const Input__Title = styled(Text)`
  margin-bottom: 2px;
  font-size: 13px;
`
const Inputs__Type__Separator = styled(FlexBox)`
  flex-direction: column;
`
const Separator__Line = styled(FlexBox)`
  margin: auto 0;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.color};
`
const Separator__Holder = styled(FlexBox)`
  margin: 10px 0;
  flex-wrap: nowrap
`
const Separator__Label = styled(Text)`
  padding: 0 10px;
`
const Profile__Photo__Holder = styled(FlexBox)`
  height: 150px;
  width: 150px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${({theme}) => theme.color}
`
const Controls__Holder = styled(FlexBox)`
  flex-grow: 1;
  gap: 5px;
  padding: ${isMobileOnly ? '5px 0' : ' 0 10px'};
  flex-direction: ${isMobileOnly ? 'column' : 'initial'};
  justify-content: right;
`
const Controls__Button = styled(Button)`
  margin: auto 0;
  width: ${isMobileOnly ? '100%' : 'unset'};
`

const Go__Back__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  width: 25px;
  height: 16px;
`
const Go__Back__Holder = styled(FlexBox)`
  cursor: pointer;

  &:hover {

  }
`
const Empty__Reviews = styled(FlexBox)`
  flex-direction: column;
  padding: 40px 20px;
`
const Empty__Reviews__Title = styled(Text)`
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
`
const Empty__Reviews__Description = styled(Text)`
  text-align: center;
  margin: 15px auto 0 auto;
`
const Loader__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 40px auto 0 auto;
`
const loader__Title = styled(Text)`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 21px;
`

export const Styled = {
    Loader__Holder,
    loader__Title,
    Empty__Reviews__Description,
    Empty__Reviews__Title,
    Empty__Reviews,
    Go__Back__Holder,
    Go__Back__Icon,
    Controls__Button,
    Controls__Holder,
    Profile__Photo__Holder,
    Separator__Label,
    Separator__Holder,
    Separator__Line,
    Inputs__Type__Separator,
    Input__Title,
    User__Background__Image,
    Settings__Holder,
    Settings__Section__Title,
    ManageAccountPageHolder,
}