import ContextProvider from "./Context/ContextProvider";
import StatusMainContent from "./StatusContent/StatusMainContent";
import PageHolder from "../../lib/UIBookBrd/PageHolder";
import FindMainContent from "./FindContent/FindMainContent";
import {OrderPageTypeEnum} from "../../lib/Types";
import ManageMainContent from "./ManageContent";

type ConfirmationPageProps = {
    type: OrderPageTypeEnum
}

const ConfirmationPage = (
    {
        type
    }: ConfirmationPageProps) => {

    return (
        <ContextProvider>
            <PageHolder>
                {type === OrderPageTypeEnum.status && (
                    <StatusMainContent/>
                )}
                {type === OrderPageTypeEnum.manage && (
                    <ManageMainContent/>
                )}
                {type === OrderPageTypeEnum.find && (
                    <FindMainContent
                        type={type}
                    />
                )}
            </PageHolder>
        </ContextProvider>
    )
}

export default ConfirmationPage