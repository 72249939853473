import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const StarIconsHolder = styled(FlexBox)`
  margin-left: 2px;
  flex-wrap: nowrap;
  margin-bottom: auto;
`
const StarIcons = styled(FontAwesomeIcon)<{ isLoading: boolean }>`
  color: ${({theme, isLoading}) => !isLoading ? theme.ratingStarColor : theme.placeholderColor};
  font-size: 9px;

  &:first-of-type {
    margin-left: 2px;
  }
`

export const Styled = {
    StarIconsHolder,
    StarIcons
}