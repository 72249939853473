import {Styled} from "./OfferDescription.styles";
import {useTranslation} from "react-i18next";
import OfferAttributes from "./OfferAttributes";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {useEffect, useRef, useState} from "react";
import useBookBrdPageFunctions from "../../../../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {isEmpty} from "lodash-es";
import {useDebouncedCallback} from "use-debounce";

type OfferDescriptionProps = {
    isLoadingOfferDetails: boolean
    isLoadingSections: boolean
    offerControls: any
    offer: any
}

const OfferDescription = (
    {
        isLoadingOfferDetails,
        isLoadingSections,
        offerControls,
        offer
    }: OfferDescriptionProps
) => {

    const {t} = useTranslation();

    const {onTourOperatorClick} = useBookBrdPageFunctions()

    const descriptionRef = useRef<any>(null)

    const [isOverFlowDesc, setIsOverFlowDesc] = useState<boolean>(true)

    const descriptionArray = offerControls?.Description?.Descriptions ?? []

    const [isDescOpen, setIsDescOpen] = useState<boolean>(false)

    const changeDescription = () => {
        if (!isLoadingSections) {
            setIsDescOpen(!isDescOpen)
            if (isDescOpen) {
                descriptionRef.current?.scrollIntoView();
            }
        }
    }

    const descriptionWidthChecker = useDebouncedCallback(() => {
        const height = descriptionRef?.current?.clientHeight
        if (height > 400) {
            if (!isOverFlowDesc) {
                setIsOverFlowDesc(true)
            }
        } else {
            if (isOverFlowDesc) {
                setIsOverFlowDesc(false)
            }
        }
    }, 50)

    useEffect(() => {
        descriptionWidthChecker()
    }, [offerControls])

    useEffect(() => {
        window.addEventListener('resize', descriptionWidthChecker)
        return () => {
            window.removeEventListener('resize', descriptionWidthChecker)
        }
    }, [])

    return (
        <>
            <Styled.Offer_Description_main_Holder>
                <FlexBox>
                    <Styled.Offer_Description_Main_Labels>
                        {`${t('lbl_location')}: `}
                    </Styled.Offer_Description_Main_Labels>
                    {isLoadingOfferDetails ? (
                        <SkeletonBox
                            width={'80px'}
                            height={'14px'}
                            margin={' 2px 0 0 2px'}

                        />
                    ) : (
                        <Styled.Location__Value__Label>
                            {offer?.Accommodation?.XCity?.Name === offer?.Accommodation?.XRegion?.Name ? `${offer?.Accommodation?.XCity?.Name} / ` : `${offer?.Accommodation?.XCity?.Name} / ${offer?.Accommodation?.XRegion?.Name} / `}{offer?.Accommodation?.XCountry?.Name}
                        </Styled.Location__Value__Label>
                    )}
                </FlexBox>
                <FlexBox>
                    <Styled.Offer_Description_Main_Labels>
                        {`${t('lbl_operator')}: `}
                    </Styled.Offer_Description_Main_Labels>
                    {isLoadingOfferDetails ? (
                        <SkeletonBox
                            width={'90px'}
                            height={'14px'}
                            margin={' 2px  0 0 2px'}

                        />
                    ) : (
                        <Styled.Offer_Description_Main_Label_Bold
                            onClick={() => onTourOperatorClick({
                                name: offer?.Base?.OperatorDesc,
                                code: offer?.Base?.Operator
                            })}
                        >
                            {offer?.Base?.OperatorDesc}
                        </Styled.Offer_Description_Main_Label_Bold>
                    )}
                </FlexBox>
            </Styled.Offer_Description_main_Holder>
            <Styled.Offer_Description_Content_Holder>
                <Styled.Description__Column__Holder
                    showFullWidth={!offer?.Accommodation?.Attributes}
                >

                    <Styled.Offer_Description_Main_Values
                        isDescOpen={isDescOpen}
                    >
                        {isLoadingSections ? (
                            <>
                                {Array.from({length: 20}).map((_, index: number) => (
                                    <SkeletonBox
                                        key={`description_loading_part_${index}`}
                                        height={'14px'}
                                        width={`${Math.random() * (95 - 45) + 45}%`}
                                    />
                                ))}
                            </>
                        ) : (
                            <>
                                {!isEmpty(descriptionArray) ? (
                                    <FlexBox ref={descriptionRef} style={{flexDirection: 'column'}}>
                                        {descriptionArray?.map((section: any, index: number) => {
                                            return (
                                                <Styled.Description__Section
                                                    key={`offer_description_${index}`}
                                                >
                                                    <Styled.Description__Section__Title>
                                                        {section.Subject}
                                                    </Styled.Description__Section__Title>
                                                    <Text>
                                                        <span dangerouslySetInnerHTML={{__html: section?.Body}}/>
                                                    </Text>
                                                </Styled.Description__Section>
                                            )
                                        })}
                                    </FlexBox>
                                ) : (
                                    <Styled.No_Description_Label>
                                        {t('lbl_no_description')}
                                    </Styled.No_Description_Label>
                                )}
                            </>
                        )}
                    </Styled.Offer_Description_Main_Values>
                    {!isDescOpen && (
                        <Styled.Shadow/>
                    )}
                    {!isLoadingSections && isOverFlowDesc && (
                        <Styled.ShowCloseLabel
                            isLoadingSections={isLoadingSections}
                            onClick={changeDescription}
                        >
                            {isDescOpen ? t('lbl_hide_description') : t('lbl_open_description')}
                        </Styled.ShowCloseLabel>
                    )}
                </Styled.Description__Column__Holder>

                <Styled.Offer_Attributes_Holder>
                    <OfferAttributes
                        isLoadingOfferDetails={isLoadingOfferDetails}
                        Attributes={offer?.Accommodation?.Attributes ?? undefined}
                    />
                </Styled.Offer_Attributes_Holder>
            </Styled.Offer_Description_Content_Holder>
        </>
    )
}
export default OfferDescription