import styled from "styled-components";
import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../../../../lib/UIBookBrd/Button";

const ContentHolder = styled(FlexBox)`
  margin-top: 25px;
  flex-wrap: nowrap;
  min-height: 100px;
  flex-direction: column;
  @media (max-width: 1100px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`;
const DrawerLabel = styled(Text)`
  user-select: none;
  white-space: pre-wrap;
  font-size: 18px;
  font-weight: bold;
  padding: 0 5px;
  margin: auto 0;
  @media (max-width: 1000px) {
    width: auto;
  }
`;
const CompareSectionsHolder = styled(FlexBox)`
  padding-top: 20px;
  flex-wrap: nowrap;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
  }

`;
const SectionHolder = styled(FlexBox)`
  flex-direction: column;
  width: 70%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`
const CloseDrawerButton = styled(FontAwesomeIcon)`
  color: ${(props: { theme: any; }) => props.theme.color};
  cursor: pointer;
  padding: 10px 0;
  margin-left: auto;
  font-size: 20px;
`
const CloseButtonHolder = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  z-index: 1000;
  position: fixed;
  border-bottom: 1px solid ${(props: { theme: any; }) => props.theme.separatorColor};
  width: 100%;

`
const ShowOfferButtonsHolder = styled(FlexBox)`
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  border-top: 1px solid ${(props: { theme: any; }) => props.theme.separatorColor};
  width: 100%;
  margin-bottom: 60px;

`
const ShowOfferButtons = styled(Button)`
  width: 100%;
`
const Button__Price__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: 10px;
  width: 50%;
`
const ShowOfferButtonsPositioner = styled(FlexBox)`
  flex-wrap: nowrap;
  min-height: 80px;
  @media (min-width: 1000px) {
    margin-left: 30%;
  }
`
const Section__Element = styled(FlexBox)`
  width: 100%;
`
const Section__Element__Part = styled(FlexBox)`
  width: 50%;
`
const Separator__For__Sections = styled(FlexBox)`
  width: 100%;
  border-bottom: ${(props: { theme: any; isOpen: boolean; }) => props.isOpen ? undefined : `1px solid ${props.theme.borderColor}`};;
`
const Price = styled(Text)`
  max-width: max-content;
  padding: 5px 0;
  font-weight: bold;
  margin-bottom: 5px;
`
const InformationIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  text-align: right;
  margin-bottom: 5px;
  color: ${(props: { theme: any; }) => props.theme.circleInfoColor};
  font-size: 10px;
`
export const Styled = {
    ShowOfferButtonsPositioner,
    ShowOfferButtonsHolder,
    Button__Price__Holder,
    ShowOfferButtons,
    CloseButtonHolder,
    CloseDrawerButton,
    SectionHolder,
    Section__Element,
    Section__Element__Part,
    CompareSectionsHolder,
    ContentHolder,
    DrawerLabel,
    Separator__For__Sections,
    Price,
    InformationIcon
};