import {Styled} from "./TripOfferList.styles";
import {useTranslation} from "react-i18next";
import Button from "../../../../../lib/UIBookBrd/Button";
import useHandleOnlineRequestError, {V5Errors} from "../../../../../lib/UseHooksBookBrd/useHandleOnlineRequestError";

type ResponseErrorProps = {
    errorMessage: V5Errors
    retryRequestSend: () => void
}

const ResponseError = (
    {
        errorMessage,
        retryRequestSend
    }: ResponseErrorProps
) => {

    const {t} = useTranslation();

    const {getDescriptionForRequests, getTitleForRequests} = useHandleOnlineRequestError()


    return (

        <Styled.No_Offers_Found_Holder>
            <Styled.No_Offers_Found_Main_Label>
                {getTitleForRequests(errorMessage)}
            </Styled.No_Offers_Found_Main_Label>
            <Styled.No_Offers_Found_Desc_Label>
                {getDescriptionForRequests(errorMessage)}
            </Styled.No_Offers_Found_Desc_Label>
            <Button
                onClick={retryRequestSend}
                label={t('lbl_retry')}
                style={{
                    minWidth: '125px',
                    margin: '10px auto 0 auto'
                }}
            />
        </Styled.No_Offers_Found_Holder>
    )
}
export default ResponseError