import {Styled} from "./TripOfferList.styles"
import TripOffer from "./TripOffer";
import {includes, isEmpty, isUndefined} from "lodash-es";
import EmptyOffers from "./EmptyOffers";
import SkeletonOffer from "./TripOffer/SkeletonOffer/SkeletonOffer";
import BrdLoader from "../../../../../lib/UIBookBrd/BrdLoader";
import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import React, {forwardRef, memo, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useWCSRequests from "../../../../../lib/UseHooksBookBrd/useWCSRequests";
import ImagesGalleryFullPage from "../../../../../Components/ImagesGalery/ImagesGaleryFullPage";
import ResponseError from "./ResponseError";
import useTripResultsValues from "../../useTripResultsValues";
import useV5OfferListRequest from "../../../../../lib/UseHooksBookBrd/useV5OfferListRequest";
import useFilterFormValue from "../../../../../lib/UseHooksBookBrd/useFilterFormValue";
import useSearchFormValues from "../../../../../lib/UseHooksBookBrd/useSearchFormValues";
import useSimilarOffersHandler from "../../../../../lib/UseHooksBookBrd/useSimilarOffersHandler";
import {CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import useRefWithReset from "../../../../../lib/UseHooksBookBrd/useRefWithReset";
import {MeasuredCellParent} from "react-virtualized/dist/es/CellMeasurer";
import {isMobileOnly} from "react-device-detect";
import {WindowSizeType} from "../../../../../lib/Types";
import useStorage from "../../../../../lib/UseHooksBookBrd/useStorage";


type TripOfferListProps = {}


const containerStyle = {width: '100%', maxWidth: '100%'};

const style = {width: '100%', scrollBehavior: 'smooth'} as React.CSSProperties;

const getWindowType = () => {
    if (window.innerWidth > 650) {
        return WindowSizeType.small
    }
    return WindowSizeType.wide
}

const TripOfferList = forwardRef<HTMLDivElement, TripOfferListProps>((
    {
        ...props
    }, ref: any) => {
    const [favouriteOffersRawList, setFavouriteOffersRawList] = useStorage<any>('localStorage', 'rawFavouriteOffers', {trips: []})

    const [windowType, setWindowType] = useState(getWindowType())

    const {t} = useTranslation();

    const {
        state,
        setOpenedList,
        setImagesControl,
        toggleIsLoadingMore,
        toggleIsLoadingNew,
        toggleIsLoadingPage,
        addToCompareOffers,
        optionList,
        setOptionList
    } = useTripResultsValues()

    const {
        filterFormValue,
    } = useFilterFormValue()

    const {
        searchFormValue,
    } = useSearchFormValues()

    const {
        SendRequestForOfferList
    } = useV5OfferListRequest(searchFormValue, filterFormValue)

    const {
        SimilarOffersHandler
    } = useSimilarOffersHandler(SendRequestForOfferList)

    const listOptionsRef = useRef<any>({})

    const listRef = useRef<any>(null);

    let sentRequest = false

    let sentMoreRequest = false

    const cache = useRefWithReset<CellMeasurerCache>(() => new CellMeasurerCache({
        defaultHeight: windowType === WindowSizeType.small ? 192 : 364,
        fixedWidth: true,
    }), [optionList?.items, state?.tripList?.openedList, state?.tripList?.imagesControl]);

    const refreshListHeights = (index: number) => {
        cache.current.clear(index, 0);
        listRef.current?.recomputeRowHeights(index);
    }

    const getMoreOffersForTripOfferList = () => {
        if (!sentMoreRequest) {
            sentRequest = true
            SendRequestForOfferList(toggleIsLoadingMore, setOptionList, "OfferList", undefined, 'OfferList', optionList).then(() => {
                sentRequest = false
            })
        }
    }

    const retryRequestSend = () => {
        SendRequestForOfferList(toggleIsLoadingNew, setOptionList, "OfferList", undefined, 'OfferList', optionList)
    }

    const [fullPageImageControl, setFullPageImageControl] = useState<number | undefined>(undefined)

    const {sendPicturesRequest} = useWCSRequests()

    const onOpenOffer = (index: number, offerId: string) => {

        const setImages = (imagesList: string[]) => {
            let value = state?.tripList?.imagesControl[index] ?? {}
            value['imagesList'] = imagesList
            value['isLoaded'] = true
            value['selectedImage'] = 0

            setImagesControl({
                ...state?.tripList?.imagesControl,
                [index]: value
            })
        }

        const {offer} = optionList.items[index]

        setOpenedList([
            ...state?.tripList?.openedList,
            offerId
        ])
        if (isEmpty(state?.tripList?.imagesControl[index])) {
            sendPicturesRequest(offer, offerId, setImages).then(() => {
                setTimeout(() => {
                    refreshListHeights(index)
                }, 600)
            })
        }
        refreshListHeights(index)
    }

    const navigateImagesHandler = (type: 'previous' | 'next', index: number) => {
        let value = state?.tripList?.imagesControl[index]
        const currentlySelected = value.selectedImage
        const ImagesList = value.imagesList

        if (type === 'next') {
            if (currentlySelected + 1 === ImagesList?.length) {
                value.selectedImage = 0
            } else {
                value.selectedImage = currentlySelected + 1
            }
        }
        if (type === 'previous') {
            if (currentlySelected === 0) {
                value.selectedImage = ImagesList.length - 1
            } else {
                value.selectedImage = currentlySelected - 1
            }
        }

        setImagesControl({
            ...state?.tripList?.imagesControl,
            [index]: value
        })
        refreshListHeights(index)
    }

    const favouriteChangeHandler = (offerId: string, index: number) => {
        setFavouriteOffersRawList((state: any) => ({
            ...state,
            trips: includes(state.trips, offerId) ? state.trips.filter((item: string) => item !== offerId) : [...state.trips, offerId]
        }))
        refreshListHeights(index)

    }

    const setParticularImage = (imgSrc: string, offerIndex: number) => {
        let value = state?.tripList?.imagesControl[offerIndex]
        value.selectedImage = value.imagesList.indexOf(imgSrc)
        setImagesControl({
            ...state?.tripList?.imagesControl,
            [offerIndex]: value
        })
        refreshListHeights(offerIndex)
    }

    const onCloseOffer = (index: number, offerId: string) => {
        const newList = state?.tripList?.openedList?.filter((id: string) => id !== offerId)
        setOpenedList(newList)
        refreshListHeights(index)
    }

    const renderRow = (
        {
            index,
            parent,
            style,
        }: { index: number, parent: MeasuredCellParent, style: React.CSSProperties }) => {

        const value = optionList?.items[index]

        const isLast = index === optionList?.items?.length - 1

        if (!value.isFinished) {
            const {offer} = value

            const isExlusive = index === 1

            const isOpen = includes(state?.tripList?.openedList, offer.Base.OfferId)

            const pictures = state?.tripList?.imagesControl[index]?.imagesList ? state?.tripList?.imagesControl[index]?.imagesList : [offer.Base.ThumbUrl.replace("/T320", "/T640")]

            const openFullImages = () => {
                setFullPageImageControl(index)
            }

            return (
                <CellMeasurer
                    key={index}
                    cache={cache.current}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                >
                    <div style={{...style, marginBottom: isMobileOnly && isLast ? '40px' : "unset"}}>
                        <TripOffer
                            enableObserver={true}
                            isFavourite={includes(favouriteOffersRawList.trips, offer?.Base?.OfferId)}
                            onFavouriteChangeHandler={favouriteChangeHandler}
                            requestReRender={() => {
                                refreshListHeights(index)
                            }}
                            ref={(input) => (listOptionsRef.current[`trip_offer_list_${index}`] = input)}
                            id={`trip_offer_list_${index}`}
                            openFullImages={openFullImages}
                            selectedImage={state?.tripList?.imagesControl[index]?.selectedImage ? state?.tripList?.imagesControl[index]?.selectedImage : 0}
                            isLoadingImages={!!!state?.tripList?.imagesControl[index]?.isLoaded && isOpen}
                            pictures={pictures}
                            navigateImagesHandler={navigateImagesHandler}
                            onOpenOffer={onOpenOffer}
                            onCloseOffer={onCloseOffer}
                            isOpen={isOpen}
                            index={index}
                            isExlusive={isExlusive}
                            SimilarOffersHandler={SimilarOffersHandler}
                            compareOffersHandler={addToCompareOffers}
                            offer={offer}
                        />
                    </div>
                </CellMeasurer>
            )
        } else {
            return (
                <CellMeasurer
                    key={index}
                    cache={cache.current}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                >
                    <div style={{...style}}>
                        <Styled.No_More_Offer_Found
                            ref={(input) => (listOptionsRef.current[`trip_offer_list_${index}`] = input)}
                        >
                            <Styled.No_More_Offer_Found_Title>
                                {t('lbl_no_more_offers_found_title')}
                            </Styled.No_More_Offer_Found_Title>
                            <Styled.No_More_Offer_Found_Description>
                                {t('lbl_no_more_offers_found_desc')}
                            </Styled.No_More_Offer_Found_Description>
                        </Styled.No_More_Offer_Found>
                    </div>
                </CellMeasurer>
            )
        }
    }

    const onItemsRender = (object: any) => {
        const lastVisible = object.stopIndex
        if (lastVisible === optionList.items.length - 3 && optionList.more) {
            getMoreOffersForTripOfferList()
        }
    }

    const sendOfferListRequ = () => {
        if (!sentRequest) {
            SendRequestForOfferList(toggleIsLoadingPage, setOptionList, "OfferList", undefined, 'OfferList', optionList)
            sentRequest = true
        }
    }

    const onFullImageCloseClick = () => {
        setFullPageImageControl(undefined)
    }

    const onWidthChange = () => {
        const isSameSizeType = windowType === getWindowType()
        if (!isSameSizeType) {
            setWindowType(getWindowType())
            cache.current.clearAll()
            listRef.current.forceUpdate();
        }
    }

    useEffect(() => {
        sendOfferListRequ()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onWidthChange)
        return () => {
            window.removeEventListener('resize', onWidthChange)
        }
    }, [])

    useEffect(() => {
        refreshListHeights(0)
    }, [state?.innerWidth])


    if (state?.tripList?.isLoadingNew) {
        return (
            <Styled.SkeletonHolder>
                {Array.from({length: 20}).map((offer: any) => {

                    return (
                        <SkeletonOffer/>
                    )
                })}
            </Styled.SkeletonHolder>
        )
    }

    if (optionList?.errorData?.errorWithRequest) {
        return (
            <ResponseError
                errorMessage={optionList?.errorData?.errorMessage}
                retryRequestSend={retryRequestSend}
            />
        )
    }

    if (!isEmpty(optionList?.items) && optionList?.items.length > 1 && !state?.tripList?.isLoadingNew) {
        const listContent = (
            <List
                ref={listRef}
                id="offer-list"
                width={1}
                scrollToAlignment={'center'}
                isScrollingOptOut
                overscanRowCount={1}
                onRowsRendered={onItemsRender}
                height={state?.innerHeight}
                deferredMeasurementCache={cache.current}
                rowHeight={cache.current.rowHeight}
                rowRenderer={renderRow}
                rowCount={optionList?.items.length}
                containerStyle={containerStyle}
                style={style}
                data={optionList?.items}
            />
        )

        return (
            <>
                {listContent}
                {state?.tripList?.isLoadingMore && optionList.more && (
                    <FlexBox style={{
                        position: 'absolute',
                        bottom: '0px',
                        minWidth: '100%',
                        width: '100%',
                        padding: '10px 0'
                    }}>
                        <BrdLoader
                            style={{
                                margin: 'auto'
                            }}
                        />
                    </FlexBox>
                )}
                {!isUndefined(fullPageImageControl) && (
                    <ImagesGalleryFullPage
                        displayedImage={state?.tripList?.imagesControl[fullPageImageControl]?.imagesList[state?.tripList?.imagesControl[fullPageImageControl]?.selectedImage]}
                        isOpen={!isUndefined(fullPageImageControl)}
                        setIsOpen={onFullImageCloseClick}
                        pictures={state?.tripList?.imagesControl[fullPageImageControl]?.imagesList}
                        setDisplayedImage={(value: any) => {
                            setParticularImage(value, fullPageImageControl)
                        }}
                        navigateFunction={(type: 'previous' | 'next') => {
                            navigateImagesHandler(type as 'previous' | 'next', fullPageImageControl)
                        }}
                    />)}
            </>
        )
    }

    if (isEmpty(optionList?.items) && !optionList?.errorData?.errorWithRequest && !state?.tripList?.isLoadingNew) {
        return (
            <EmptyOffers/>
        )
    }


    return null
})

export default memo(TripOfferList)