import React, {useEffect, useRef, useState} from "react";
import {Styled} from "./ParticipantsPickerDropDownStyles/ParticipantsPickerDropDown.styles";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import {isMobileOnly} from "react-device-detect";
import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";
import ParticipantPickerElement from "./ParticipantPickerElement";
import {useTranslation} from "react-i18next";
import Modal from "../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import usePopperPositioning from "../../lib/UseHooksBookBrd/usePopperPositioning";
import Button from "../../lib/UIBookBrd/Button";
import TextInput from "../../lib/UIBookBrd/TextInput";
import Drawer from "../../lib/UIBookBrd/Drawer";
import Tooltip from "../../lib/UIBookBrd/Tooltip";
import {isEmpty} from "lodash-es";

type ParticipantsPickerProps = {
    setIsSelectOpen?: any
    appendTo?: Element | null
    formikValue: any
    setFormikValues: any
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void
    ref?: any
    style?: any
}
const defaultProps = {
    appendTo: null,
    setIsSelectOpen: undefined,
    onClick: undefined,
    onMouseOver: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    ref: undefined,
}

const ParticipantsPicker = (
    {
        ref,
        appendTo,
        formikValue,
        setFormikValues,
        setIsSelectOpen,
        ...props
    }: ParticipantsPickerProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const [error, setError] = useState<boolean>(false)

    const [isSelectCalendarOpen, setIsSelectCalendarOpen] = useState<boolean>(false)

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const [appliedNumber, setapplyiedNumber] = useState(formikValue)

    const inputElement = useRef<HTMLDivElement>();

    const {styles, attributes} = usePopperPositioning({
        targetElementRef: inputElement,
        popperElementRef: modalElement,
        applyMaxSizeEnabled: true,
        zIndex: 9999,
        padding: 15,
    });

    useOnClickOutside(modalElement, () => onClose());

    const {t} = useTranslation();

    const ParticipantsList = [
        {
            type: 'Adults',
            minNumber: 1,
            number: appliedNumber.adults.value,
            maxNumber: 13
        },
        {
            type: 'Kids',
            minNumber: 0,
            number: appliedNumber.kids.value,
            maxNumber: 8
        },
    ]

    const onClickFunc = () => {
        if (isOpen) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }

    const onClose = () => {
        if (!isSelectCalendarOpen) {
            setFormikValues('Base.Participants', appliedNumber)
            setIsOpen(false)
        }
    }

    const onCLickApplyfunc = () => {
        onClose()
    }

    const displayText = () => {
        return {label: `${t('Adults')}: ${appliedNumber.adults.value} • ${t('Kids')}: ${appliedNumber.kids.value}`}
    }

    useEffect(() => {
        if (setIsSelectOpen) {
            setIsSelectOpen(isOpen)
        }
        if (isOpen) {
            setError(false)
        } else {
            const list: any[] = []
            appliedNumber.kids.dates?.map((item: any, index: number) => {
                if (isEmpty(item)) {
                    list.push(index)
                }
            })
            if (!isEmpty(list)) {
                setError(true)
            } else {
                setError(false)
            }
        }
    }, [isOpen])

    useEffect(() => {
        setapplyiedNumber(formikValue)
    }, [formikValue])

    const bothversionscontent = (
        <>
            {ParticipantsList.map((item: any) => (
                <ParticipantPickerElement
                    key={`participant_element_${item.type}`}
                    setIsSelectCalendarOpen={setIsSelectCalendarOpen}
                    label={item.type}
                    defultNumber={item.number}
                    maxNumber={item.maxNumber}
                    minNumber={item.minNumber}
                    formikValues={appliedNumber}
                    changeNumber={setapplyiedNumber}
                />
            ))}
        </>
    )
    const Content = (
        <FlexBox style={{
            flexDirection: 'column',
            gap: '15px',
            padding: isMobileOnly ? '10px 5px' : ' 5px 8px'
        }}>
            {bothversionscontent}
        </FlexBox>
    )
    const controls = (
        <>
            <Button
                style={{
                    flexGrow: '1',
                    minWidth: '100px',
                    margin: 'auto 4px',
                }}
                onClick={onCLickApplyfunc}
                label={t('lbl_apply')}
            />
        </>
    )
    return (
        <>
            <Tooltip
                theme={'red'}
                placement={'top'}
                content={t('lbl_wrong_data_participantPicker_error')}
                visible={error}
                followCursor={true}
            >
                <TextInput
                    error={error}
                    readOnly={true}
                    showIcon={false}
                    isClearAble={false}
                    onClick={onClickFunc}
                    value={displayText()}
                    ref={isMobileOnly ? ref : inputElement as any}
                />
            </Tooltip>
            {isOpen && !isMobileOnly && (
                <Modal
                    appendTo={appendTo}
                    showCloseIcon={false}
                    onClose={onClose}
                    ref={setModalElement}
                    showOverlay={false}
                    isOpen={isOpen}
                    positionedByPopper
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <Styled.FlexBox1
                        minWidth={modalElement?.clientWidth}
                    >
                        {Content}
                    </Styled.FlexBox1>
                </Modal>

            )}
            {isMobileOnly && (
                <Drawer
                    appendTo={appendTo}
                    position={'bottom'}
                    ref={setModalElement}
                    title={t('lbl_participants')}
                    onClose={onClose}
                    isOpen={isOpen}
                    controls={controls}
                >
                    {Content}
                </Drawer>
            )}

        </>
    )
}
ParticipantsPicker.defaultProps = defaultProps;

export default ParticipantsPicker