import {promiseRequest} from "../../../lib/RequestBuilder";
import {isEmpty} from "lodash-es";
import {CONTENT_SERVICE_URL} from "../../../lib/Configs/RequestConfig";
import useHandleOnlineRequestError from "../../../lib/UseHooksBookBrd/useHandleOnlineRequestError";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {THIRD_STEP_TRIP_SEARCH} from "../../../lib/Routes";
import useRoutesHandler from "../../../lib/RoutesHandler";

const useOfferDetailsFunctions = (protoValue: any) => {

    const {selectedLanguage} = useBookBrdPageFunctions()

    const {
        createParamsMap
    } = useRoutesHandler()

    const {getV5Error, getWebContentServiceError} = useHandleOnlineRequestError()

    const sectionsMap: any = {
        Pictures: 'Images',
        Description: 'Description',
        WeatherStatic: "Weather",
        RegionalInfo: 'RegionList'
    }

    const getDescriptionWithImages = (
        offer: any,
        offerId: string,
        setOfferControls: any,
        setControls: any
    ) => {
        ['Pictures', 'Description', 'WeatherStatic', 'RegionalInfo'].map((section: string) => {
            (async () => {
                try {
                    setControls((state: any) => ({
                        ...state,
                        requestSent: true,
                        [`isLoading${sectionsMap[section]}`]: true
                    }))

                    const requestParams = {
                        Language: selectedLanguage,
                        RegionId: offer?.Base?.DestinationLocation?.Id,
                        HotelXCode: offer?.Base?.XCode?.Id,
                        HotelCode: `${offer?.Accommodation?.Code}`,
                        Operator: offer?.Base?.Operator,
                        offerId: offerId,
                        PictureSizes: "full"
                    }
                    const request = await promiseRequest(`${CONTENT_SERVICE_URL}${section}/${JSON.stringify(requestParams)}`, undefined, 3);
                    const {Sections} = request
                    if (Sections) {
                        setOfferControls((state: any) => ({
                            ...state,
                            [sectionsMap[section]]: Sections[section]
                        }))
                    }
                } catch (e) {
                    setControls((state: any) => ({
                        ...state,
                        errorData: {
                            errorWithRequest: true,
                            errorMessage: getWebContentServiceError(e)
                        }
                    }))
                } finally {
                    setControls((state: any) => ({
                        ...state,
                        [`isLoading${sectionsMap[section]}`]: false
                    }))
                }

            })()
        })
    }


    const getDetails = async (setOffer: any, setControls: any) => {
        try {
            setControls((state: any) => ({
                ...state,
                errorData: {},
                requestSent: true,
                isLoadingSections: true,
                isLoadingOfferDetails: true

            }))

            //Values
            const getOfferID = () => {
                const offerParams = window.location.pathname.replace(`${THIRD_STEP_TRIP_SEARCH}/`, '').split('/')
                const value = createParamsMap(offerParams)?.offerId
                return value ?? ''
            }

            // @ts-ignore
            const v5DataRequest = await promiseRequest(`https://mwsv5nmc.merlinx.pl/v5/data/travel/details?Base.OfferId=${getOfferID()}`, null, 3);
            const {result} = v5DataRequest
            if (!isEmpty(result)) {
                const {offer} = result
                sessionStorage.setItem('tripOfferDetails', offer.Base.XCode.Name)
                setOffer(offer)
                return offer
            }
        } catch (e) {
            setControls((state: any) => ({
                ...state,
                errorData: {
                    errorWithRequest: true,
                    errorMessage: getV5Error(e)
                },
                isLoadingOfferDetails: false

            }))
        } finally {
            setControls((state: any) => ({
                ...state,
                isLoadingOfferDetails: false

            }))
        }
    }

    const getDetailsAndPictures = (
        setOffer: any,
        setControls: any,
        setOfferControls: any,
    ) => {
        getDetails(
            setOffer,
            setControls
        ).then((offer: any) => {
            if (!isEmpty(offer)) {
                getDescriptionWithImages(
                    offer,
                    offer?.Base?.OfferId,
                    setOfferControls,
                    setControls
                )
            }
        })

    }
    return ({
        getDetailsAndPictures
    })

}
export default useOfferDetailsFunctions