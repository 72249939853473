import {Styled} from "../BasicPickerForCountry.styles";
import {chunk, floor, includes, isEmpty} from "lodash-es";
import Option from "./Option";
import React, {useMemo} from "react";

type OptionListProps = {
    options: any[]
    flatCheckedList: any[] | undefined
    openOptionList: any[]
    openChangeHandler: (value: string | undefined) => void
    checkboxChangeHandler: any
    subOptionsEnabled: boolean
    maxNumberOfColumns: number
    minColumnWidth: number
    contentRef: any
}

const getParent = (value: any, options: any) => {
    let subOptions: any[] = []
    options?.map((item: any) => {
        if (item.value === value) {
            subOptions = item
        }

    })
    return subOptions
}

const OptionList = ({
                        options,
                        flatCheckedList,
                        openOptionList,
                        checkboxChangeHandler,
                        openChangeHandler,
                        subOptionsEnabled,
                        maxNumberOfColumns,
                        minColumnWidth,
                        contentRef,
                    }: OptionListProps) => {

    //Columns and rows control
    const numberOfColumns = (maxColumns: number, contentWidth: any, minWidthOfColumn: number) => {
        const maxAmountOfColumns = contentWidth / minWidthOfColumn
        const numberOfColumns = maxAmountOfColumns < maxColumns ? floor(maxAmountOfColumns) : maxColumns
        return numberOfColumns as number
    }

    const optionsChunks = useMemo(() => (items: any, columns: any): any[] => {
        const chunkSize = Math.ceil(items.length / columns);

        return chunk(items, chunkSize);
    }, [options]);

    const columnWidth = (columnsNumber: any, contentWidth: any) => {
        return contentWidth / columnsNumber
    }

    const optionsColumns = optionsChunks(options, numberOfColumns(maxNumberOfColumns as number, contentRef?.clientWidth, minColumnWidth as number));


    return (
        <Styled.Row>
            {optionsColumns.map((group: any[], index) => {
                return (
                    <Styled.Column
                        width={columnWidth(numberOfColumns(maxNumberOfColumns as number, contentRef?.clientWidth, minColumnWidth as number), contentRef?.clientWidth)}
                    >
                        {group?.map((option: any, index) => {
                            const isChecked = includes(flatCheckedList, option.value)
                            const isOpen = includes(openOptionList, option.value) && option?.subOptions?.length > 0
                            return (
                                <Styled.Option>
                                    <Option
                                        type={'countries'}
                                        isCheckedProp={isChecked}
                                        isOpen={isOpen}
                                        openChangeHandler={openChangeHandler}
                                        label={option.label}
                                        value={option.value}
                                        onChange={checkboxChangeHandler}
                                        hasSubOptions={!isEmpty(option?.subOptions)}
                                        isParentOption={true}
                                    />
                                    {subOptionsEnabled && isOpen && (
                                        <Styled.SubOptionsHolder>
                                            {option?.subOptions.map((subOption: any) => {
                                                const isChecked = includes(flatCheckedList, subOption.value)
                                                return (
                                                    <>
                                                        <Option
                                                            type={'regions'}
                                                            isCheckedProp={isChecked}
                                                            label={subOption.label}
                                                            value={subOption.value}
                                                            onChange={checkboxChangeHandler}
                                                            isParentOption={false}
                                                        />

                                                    </>
                                                )
                                            })}
                                        </Styled.SubOptionsHolder>
                                    )}


                                </Styled.Option>
                            )
                        })}

                    </Styled.Column>
                )
            })}

        </Styled.Row>
    )

}
export default OptionList