import {Styled} from "./AutoSuggestOptionList.styles"
import {faCity, faFlag, faHotel, faPanorama, faPlane} from "@fortawesome/free-solid-svg-icons";

type CustomIconForSectionProps = {
    sectionType: string
}

const CustomIconForSection = (
    {
        sectionType,
    }: CustomIconForSectionProps
) => {

    const IconForSection = (sectionName: any) => {
        if (sectionName === 'cities') {
            return faCity
        }
        if (sectionName === 'countries') {
            return faFlag
        }
        if (sectionName === 'regions') {
            return faPanorama
        }
        if (sectionName === 'hotels') {
            return faHotel
        }

        return faPlane
    }
    return (
        <>
            <Styled.CustomSectionIcon
                icon={IconForSection(sectionType) as any}
            />

        </>
    )
}
export default CustomIconForSection