import {Styled} from "./toggles.styles"
import {createPortal} from "react-dom";
import {faMessageSmile} from "@fortawesome/pro-solid-svg-icons";
import useTylerBot from "../Handlers/Hook";

const RoundTylerToggle = () => {


    const {
        tylerIconRef,
        state,
        isIconHidden,
        toggleContainer
    } = useTylerBot()

    return !isIconHidden ? (
        <>
            {createPortal(
                <Styled.Round__Toggle
                    isOpen={state.isOpen}
                    onClick={toggleContainer}
                    ref={tylerIconRef}
                    id={'Tyler-Round-Toggle'}
                >
                    <Styled.Round__Toggle__Content__Holder>
                        <Styled.Round__Inside__Icon__Color__Fill/>
                        <Styled.Round__Toggle__Icon
                            icon={faMessageSmile}
                        />
                        {state?.notificationsCount !== 0 && (
                            <Styled.Round__Toggle__Notification__Icon>
                                <Styled.Round__Toggle__Notification__Label>
                                    {state?.notificationsCount}
                                </Styled.Round__Toggle__Notification__Label>
                            </Styled.Round__Toggle__Notification__Icon>
                        )}
                    </Styled.Round__Toggle__Content__Holder>
                </Styled.Round__Toggle>,
                document.querySelector('body') as HTMLBodyElement
            )}
        </>
    ) : null
}
export default RoundTylerToggle