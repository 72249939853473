import {Styled} from "./AutoSuggestOptionList.styles";
import {toNumber} from "lodash-es";
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";
import {faStarHalf} from "@fortawesome/free-solid-svg-icons/faStarHalf";

type AutoSuggestOptionProps = {
    isDisabled: boolean
    isChecked: boolean
    onChange: any
    optionValue: any
    sectionType: string
}


const AutoSuggestOption = (
    {
        isDisabled,
        isChecked,
        onChange,
        optionValue,
        sectionType
    }: AutoSuggestOptionProps
) => {
    const {
        hotelName,
        city,
        region,
        country,
        category
    } = optionValue

    return (
        <>
            {sectionType === 'cities' && (
                <Styled.CustomCheckBox
                    isDisabled={isDisabled}
                    isCheckedProp={isChecked}
                    onChange={onChange}
                    title={
                        <Styled.CustomOptionHolder>
                            <Styled.MainOptionText
                                isDisabled={isDisabled}
                            >
                                {city}
                            </Styled.MainOptionText>
                            <Styled.AdditionalOptionText>
                                {region} {'•'} {country}
                            </Styled.AdditionalOptionText>
                        </Styled.CustomOptionHolder>
                    }
                />

            )}
            {sectionType === 'hotels' && (
                <Styled.CustomCheckBox
                    isDisabled={isDisabled}
                    isCheckedProp={isChecked}
                    onChange={onChange}
                    title={
                        <Styled.CustomOptionHolder>
                            <Styled.CustomHolderForHotels>
                                <Styled.MainOptionText
                                    isDisabled={isDisabled}
                                >
                                    {hotelName}
                                </Styled.MainOptionText>
                                {Array.from({length: (toNumber(category))}).map(_ => {
                                    return (
                                        <Styled.StarIcon
                                            icon={faStar}
                                        />
                                    )
                                })}
                                {(toNumber(category) * 2) % 2 === 1 && (
                                    <Styled.StarIcon
                                        icon={faStarHalf}
                                    />
                                )}
                            </Styled.CustomHolderForHotels>
                            <Styled.AdditionalOptionText>
                                {city} {'•'} {region} {'•'} {country}
                            </Styled.AdditionalOptionText>
                        </Styled.CustomOptionHolder>
                    }
                />

            )}
            {sectionType === 'regions' && (
                <Styled.CustomCheckBox
                    isDisabled={isDisabled}
                    isCheckedProp={isChecked}
                    onChange={onChange}
                    title={
                        <Styled.CustomOptionHolder>
                            <Styled.MainOptionText
                                isDisabled={isDisabled}
                            >
                                {region}
                            </Styled.MainOptionText>
                            <Styled.AdditionalOptionText>
                                {country}
                            </Styled.AdditionalOptionText>
                        </Styled.CustomOptionHolder>
                    }
                />

            )}
            {sectionType === 'countries' && (
                <Styled.CustomCheckBox
                    isDisabled={isDisabled}
                    isCheckedProp={isChecked}
                    onChange={onChange}
                    title={
                        <Styled.CustomOptionHolder>
                            <Styled.MainOptionText
                                isDisabled={isDisabled}
                            >
                                {country}
                            </Styled.MainOptionText>
                        </Styled.CustomOptionHolder>
                    }
                />

            )}
        </>
    )
}
export default AutoSuggestOption