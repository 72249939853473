import Modal from "../../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {useState} from "react";
import useOnClickOutside from "../../../lib/UseHooksBookBrd/useOnClickOutside";
import {useTranslation} from "react-i18next";
import {Styled} from "./LanguageModal.styles";
import SettingsOptions from "../../../lib/PageConfig";
import {Language_Images_Flags} from "../../../LanguageImages";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {isMobileOnly} from "react-device-detect";
import Drawer from "../../../lib/UIBookBrd/Drawer";


const LanguageModal = () => {

    const {Language_Options} = SettingsOptions()

    const [languageModal, setLanguageModal] = useState<HTMLDivElement | null>(null);

    const {t} = useTranslation();

    const {
        onCloseLanguageModal,
        isOpenLanguageModal,
        selectedLanguage,
        onLanguageSelectChange
    } = useBookBrdPageFunctions()

    useOnClickOutside(languageModal, onCloseLanguageModal)

    const content = (
        <Styled.Countries__Options__Holder>
            {Language_Options.map((item: any) => {

                const countryName = item?.label

                const countryImage = Language_Images_Flags[item?.value]

                const onOptionClick = () => {
                    onLanguageSelectChange(item)
                    onCloseLanguageModal()
                }

                const isSelected = item?.value === selectedLanguage

                return (
                    <Styled.Country__Option
                        key={`language_${countryName}`}
                        onClick={onOptionClick}
                    >
                        <Styled.Country__Image
                            src={countryImage}
                            alt={countryName}
                        />
                        <Styled.Country__Label>
                            {countryName}
                        </Styled.Country__Label>
                        {isSelected && (
                            <Styled.Selected__Language__Icon
                                icon={faCheck}
                            />
                        )}
                    </Styled.Country__Option>
                )
            })}
        </Styled.Countries__Options__Holder>
    )

    return !isMobileOnly ? (
        <Modal
            ref={setLanguageModal}
            isOpen={isOpenLanguageModal}
            onClose={onCloseLanguageModal}
            width={900}
            title={t('lbl_change_language')}
        >
            {content}
        </Modal>
    ) : (
        <Drawer
            onClose={onCloseLanguageModal}
            title={t('lbl_language')}
            isOpen={isOpenLanguageModal}
            position={'bottom'}
        >
            {content}
        </Drawer>
    )
}


export default LanguageModal