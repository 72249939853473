import React from "react";
import {Proto_Hash_Default_Values} from "../ProtoHashConfig";

export const Notifications_Data = React.createContext<any>({notificationList: []})

export const Local_Data = React.createContext({})

export const Search_Proto = React.createContext(Proto_Hash_Default_Values)

export const Booking_Page = React.createContext({})

export const Trip_Result_Page = React.createContext({})

export const Trip_Offers = React.createContext({})

export const Similar_Offers = React.createContext({})

export const Payment_Status = React.createContext({})

export const Customer_Support = React.createContext({})

export const User_Data = React.createContext({})

export const Bot__Data = React.createContext({})