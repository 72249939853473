import useNavigation from "../../../lib/UseHooksBookBrd/useNavigation";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";

const useFooterLinks = () => {

    const navigate = useNavigation()

    const {
        toggleFavouriteOptions
    } = useBookBrdPageFunctions()
    const favouriteOffersLink = () => {
        toggleFavouriteOptions(true)
    }
    const adminLink = () => {
        alert('admin link')
    }
    const becomeSupplier = () => {
        alert('become supplier')
    }
    const logoutLink = () => {
        alert('log out')
    }


    return ({
        favouriteOffersLink,
        becomeSupplier,
        adminLink,
    })
}


export default useFooterLinks