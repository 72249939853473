import {Styled} from "./QuickStart.styles"
import {useTranslation} from "react-i18next";

const QuickStart = () => {

    const {t} = useTranslation()

    return (
        <Styled.Quick__Start__Holder>
            {/*WELCOME SECTION*/}
            <Styled.Section__Label>
                {t('lbl_welcome_tyler_title')}
            </Styled.Section__Label>
            <Styled.Section__Value__Text__Centered>
                {t('lbl_welcome_tyler_desc')}
            </Styled.Section__Value__Text__Centered>
            {/*BASIC SKILLS*/}
            <Styled.Section__Label>
                {t('lbl_basic_skills_tyler_title')}
            </Styled.Section__Label>
            <Styled.Section__Value__Text>
                {t('lbl_basic_skills_tyler_desc_first')}
            </Styled.Section__Value__Text>
            <Styled.Basic__Tasks__Section__Title>
                {'• '}{t('lbl_basic_skills_general_title')}
            </Styled.Basic__Tasks__Section__Title>
            <Styled.Basic__Tasks__Section__Example>
                {'• '}{'"'}{t('lbl_basic_skills_general_example')}{'"'}
            </Styled.Basic__Tasks__Section__Example>
            <Styled.Basic__Tasks__Section__Title>
                {'• '}{t('lbl_basic_skills_search_title')}
            </Styled.Basic__Tasks__Section__Title>
            <Styled.Basic__Tasks__Section__Example>
                {'• '}{'"'}{t('lbl_basic_skills_search_example')}{'"'}
            </Styled.Basic__Tasks__Section__Example>
            <Styled.Basic__Tasks__Section__Title>
                {'• '}{t('lbl_basic_skills_navigation_title')}
            </Styled.Basic__Tasks__Section__Title>
            <Styled.Basic__Tasks__Section__Example>
                {'• '}{'"'}{t('lbl_basic_skills_navigation_example')}{'"'}
            </Styled.Basic__Tasks__Section__Example>
            {/*HOW TO ASK QUESTIONS*/}
            <Styled.Section__Label>
                {t('lbl_how_to_ask_tyler_title')}
            </Styled.Section__Label>
            <Styled.Section__Value__Text__Centered>
                {t('lbl_how_to_ask_tyler_desc')}
            </Styled.Section__Value__Text__Centered>
        </Styled.Quick__Start__Holder>
    )
}
export default QuickStart