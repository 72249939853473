import {Styled} from "../Offer.styles";
import Images from "../Parts/Images/Images";
import Location from "../Parts/Location";
import Title from "../Parts/Title";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import useRoutesHandler from "../../../lib/RoutesHandler";
import {linkTypes} from "../../../lib/Types";

type HotelOfferBoxProps = {
    offerData: any
}

const HotelOfferBox = (
    {
        offerData
    }: HotelOfferBoxProps
) => {

    const {createLinkPathForOfferDetails} = useRoutesHandler()

    const onClick = () => {
        window.open(createLinkPathForOfferDetails(window.location.pathname, linkTypes.link, offerData, true), '_blank')
    }

    return (
        <Styled.Offer
            style={{
                boxShadow: 'none'
            }}
        >
            <Images
                offerData={offerData}
                style={{
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px'
                }}
            />
            <FlexBox style={{
                flexDirection: "column",
                marginLeft: "-5px"
            }}>
                <Location
                    offerData={offerData}
                />
                <Title
                    holderStyle={{
                        marginBottom: '0px'
                    }}
                    onClick={onClick}
                    disableRating={true}
                    enableWrap={true}
                    offerData={offerData}
                />
            </FlexBox>
        </Styled.Offer>
    )
}

export default HotelOfferBox