import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Image from "../../../../../../lib/UIBookBrd/Image";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";
import Button from "../../../../../../lib/UIBookBrd/Button";

const Section__Element__Part = styled(FlexBox)`
  @keyframes skeletonColor {
    0% {
      background-color: ${(props: { theme: any; }) => props.theme.placeholderColor};
    }
    50% {
      background-color: ${(props: { theme: any; }) => props.theme.optionHoverColor};
    }
    100% {
      background-color: ${(props: { theme: any; }) => props.theme.placeholderColor};
    }
  }
  margin-top: 10px;
  padding: 0 10px;
  min-width: calc(50% - 20px);
  width: calc(50% - 20px);;
`
const Section__Element = styled(FlexBox)`
  min-width: 100%;
`
const HotelImages__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`
const Main___Image = styled(Image)<{ isFocused: boolean, isEmpty?: boolean }>`
  cursor: ${({isEmpty}) => isEmpty ? 'pointer' : undefined};
  width: 100%;
  min-height: 150px;
  max-height: 150px;
  object-fit: cover;
  margin: 10px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  box-shadow: 0px 0px 6px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  border-radius: 5px;
  display: ${({isFocused}) => isFocused ? "flex" : "none"};
  @media (max-width: 600px) {
    min-height: 150px;
    max-height: 150px;
  }
`

const CameraIcon = styled(FontAwesomeIcon)`
  width: 80%;
  height: 80%;
  margin: auto;
  color: ${(props: { theme: any; }) => props.theme.color};
  background-color: transparent;
`

const Small__Image = styled(Image)<{ isDisplayed: any, isLoadingData: any, showCursor?: boolean }>`
  border-radius: 5px;
  height: 50px;
  margin: auto 0;
  max-width: 100px;
  min-width: 100px;
  outline: ${(props: { isLoadingData?: any; isDisplayed: any; theme: any }) => props.isDisplayed ? props.isLoadingData ? undefined : `1px solid ${props.theme.borderColor}` : undefined};
  cursor: ${({showCursor}) => showCursor ? 'pointer' : undefined};
  box-shadow: 0px 0px 6px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};

  &:hover {
    box-shadow: ${(props: { isDisplayed?: any; isLoadingData?: any; theme: any }) => props.isDisplayed ? undefined : !props.isLoadingData && `0px 0px 9px 0px ${props.theme.isDark ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.9)'}`}
  }

  &:first-of-type {
    margin-left: 2px;
  }
`

const HotelImages__Small = styled(FlexBox)`
  border-radius: 5px;
  height: 50px;
  margin: auto 0;
  max-width: 100px;
  min-width: 100px;
  animation: ${(props: { isLoadingData?: any; isDisplayed: any; image: any }) => props.isLoadingData && 'skeletonColor 1s linear infinite'};
  background-image: url(${(props: { isDisplayed?: any; image?: any; }) => props.image});
  outline: ${(props: { isLoadingData?: any; image: any; isDisplayed: any; theme: any }) => props.isDisplayed ? props.isLoadingData ? undefined : `1px solid ${props.theme.borderColor}` : undefined};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: box-shadow 200ms;
  transition-delay: 200ms;
  cursor: pointer;
  box-shadow: 0px 0px 6px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};

  &:hover {
    box-shadow: ${(props: { isDisplayed?: any; isLoadingData?: any; theme: any }) => props.isDisplayed ? undefined : !props.isLoadingData && `0px 0px 9px 0px ${props.theme.isDark ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.9)'}`}
  }

  &:first-of-type {
    margin-left: 2px;
  }
`

const HotelImages__Small__Empty__Filler = styled(FlexBox)`
  min-height: 50px;
  max-height: 50px;
  max-width: 100px;
  background-color: ${({theme}) => theme.optionHoverColor};
  border-radius: 5px;
  min-width: 100px;
`

const SmallImagesHolder = styled(FlexBox)`
  gap: 8px;
  overflow: ${(props: { isLoadingData?: boolean }) => props.isLoadingData ? 'hidden' : 'scroll'};
  flex-wrap: nowrap;
  padding-top: 5px;
  padding-bottom: 20px;
  max-width: 100%;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`
const Hotel__Name__Label = styled(Text)`
  margin-left: 10px;
  animation: ${(props: { isLoadingData?: boolean }) => props.isLoadingData && 'skeletonColor 1s linear infinite'};
  border-radius: 15px;
  width: ${(props: { isLoadingData?: boolean }) => props.isLoadingData && '70%'};
  min-height: 18px;
  font-size: 16px;
  font-weight: bold;
`
const StarIcons = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.ratingStarColor};
  font-size: 10px;
  margin-bottom: auto;

  &:first-of-type {
    margin-left: 2px;
  }
`
const Hotel__Main__Image__Controls__Holder = styled(FlexBox)`
  width: 100%;
  flex-wrap: nowrap;
  height: 100%;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 5px;
  margin-left: -100%;
  min-height: 150px;
  max-height: 150px;
`
const Navigation__Icon = styled(FontAwesomeIcon)`
  margin: auto;
  font-size: 25px;
  color: ${({theme}) => theme.color}
`
const Navigate__Button__Holder = styled(FlexBox)`
  height: 100%;
  width: 40px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.40);
  }
`
const Open__FullScreen__Holder = styled(FlexBox)`
  cursor: pointer;
  height: 100%;
  width: calc(100% - 80px);
`
const Empty__Main__Image = styled(FlexBox)`
  min-height: 150px;
  max-height: 150px;
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
  background-color: ${({theme}) => theme.optionHoverColor};
`

const Loading__Main__Image = styled(SkeletonBox)`
  min-height: 150px;
  max-height: 150px;
  width: 100%;
  min-width: unset;
  border-radius: 5px;
  margin: 10px 0;
`

const Empty__Main__Image__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  margin: auto;
  width: 65px;
  height: 65px;
`
const Section__Error__Holder = styled(FlexBox)`
  flex-direction: column;
  height: 180px;
  width: 100%;
`
const Error__Box = styled(FlexBox)`
  flex-direction: column;
  margin: auto;
`
const Error__Title = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  margin: 0 auto 10px auto;
`
const Section__Error__Button = styled(Button)`
  margin: 20px auto 0 auto;
  width: 100%;
  max-width: 300px;
`

export const Styled = {
    Section__Error__Button,
    Error__Box,
    Error__Title,
    Section__Error__Holder,
    Loading__Main__Image,
    Empty__Main__Image__Icon,
    Small__Image,
    Main___Image,
    Hotel__Name__Label,
    HotelImages__Small__Empty__Filler,
    HotelImages__Small,
    SmallImagesHolder,
    Section__Element__Part,
    Section__Element,
    HotelImages__Holder,
    CameraIcon,
    StarIcons,
    Hotel__Main__Image__Controls__Holder,
    Navigate__Button__Holder,
    Open__FullScreen__Holder,
    Navigation__Icon,
    Empty__Main__Image
}