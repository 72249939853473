export const DARK_THEME = {
    OfferListBackgroundColor: 'rgba(255,255,255,0.15)',
    isDark: true,
    selectTagsBackgroundColor: 'rgb(145, 145, 145)',
    toolTipColor: 'grey',
    focusedInputBorderColor: '#1a9fff',
    optionHoverColor: '#424242',
    calendarSelectedDate: '#000000',
    calendarFillDates: '#4f4f4f',
    placeholderColor: '#868686',
    backgroundColor: "#1f1f1f",
    borderColor: "white",
    borderColorLight: '#2c2c2c',
    separatorColor: "white",
    color: "#f3f3f3",
    buttonBackgroundColor: "#537517",
    typeOfSearchHover: 'rgba(0,0,0,0.5)',
    colorBlue: '#0000c9',
    disabledFontColor: 'grey',
    isCheckedSwitchGreen: '#097705',
    ratingStarColor: '#d79b1f',
    circleInfoColor: '#429ddc',
    successMessageBackgroundColor: "rgba(50,164,54,0.9)",
    lowerPriceColor: "rgb(77,208,82)",
    favouriteOfferColor: 'rgb(253,38,119)',
    errorMessageBackgroundColor: 'rgba(255,48,48,0.9)',
    errorMessageIconColor: 'rgba(255,48,48,1)',
    warningBackgroundColor: "rgba(241,196,105,0.9)",
    brdExclusiveColor: '#3ea3f8',
    missingRatingColor: '#575757'
};
