import Modal from "../../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import {Styled} from "./TripOffersDetails.styles";
import {useTranslation} from "react-i18next";
import useHandleOnlineRequestError, {
    V5Errors,
    WCSErrors
} from "../../../lib/UseHooksBookBrd/useHandleOnlineRequestError";

type RequestErrorProps = {
    errorMessage: WCSErrors | V5Errors
    reSendRequest: () => void
}

const RequestError = (
    {
        errorMessage,
        reSendRequest
    }: RequestErrorProps
) => {

    const {getDescriptionForRequests, getTitleForRequests} = useHandleOnlineRequestError()

    const {t} = useTranslation();

    return (
        <Modal
            theme={'transparent'}
            showOverlay={false}
            isOpen={true}
            width={400}
        >
            <Styled.No__Offer__Found__Values__Holder>
                <Styled.No__Offer__Found__Label>
                    {getTitleForRequests(errorMessage)}
                </Styled.No__Offer__Found__Label>
                <Styled.No__Offer__Found__Description__Label>
                    {getDescriptionForRequests(errorMessage)}
                </Styled.No__Offer__Found__Description__Label>
                <Styled.No__Offer__Found__Buttons__Holder>
                    <Styled.Custom__Button
                        onClick={reSendRequest}
                        label={t('lbl_retry')}
                    />
                </Styled.No__Offer__Found__Buttons__Holder>
            </Styled.No__Offer__Found__Values__Holder>
        </Modal>
    )
}

export default RequestError