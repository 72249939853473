import {Styled} from "../../TripSearchBookingPage";
import CardPayment from "./CardPayment/CardPayment";
import PayerDetails from "./PayerDetails";

const Payment = () => {

    return (
        <Styled.Payer__Holder>
            <PayerDetails/>
            <CardPayment/>
        </Styled.Payer__Holder>
    )
}
export default Payment