import FlexBox from "../../../../../../../lib/UIBookBrd/FlexBox";
import styled from "styled-components";
import Text from "../../../../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toNumber} from "lodash-es";

const SkeletonOffer = styled(FlexBox)<{ disableShadow: any }>`
  @keyframes skeletonColor {
    0% {
      background: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
    50% {
      background: ${(props: { theme: any; }) => props.theme.optionHoverColor};
    }
    100% {
      background: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
  }
  cursor: not-allowed;
  margin: 5px 0;
  height: auto;
  flex-direction: initial;
  flex-wrap: nowrap;
  width: calc(100% - 10px);
  transition: background-color 500ms;
  transition-property: outline-width;
  transition-delay: 200ms;
  padding: 0 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px ${({
                                   theme,
                                   disableShadow
                                 }) => disableShadow ? 'transparent' : theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  background: ${(props: { theme: any; }) => props.theme.backgroundColor};
  box-sizing: border-box;
`
const OfferTopLayer = styled(FlexBox)`
  width: 100%;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  flex-wrap: nowrap;
  min-height: 100px;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`
const TitleAndImage = styled(FlexBox)`
  max-width: 30%;
  flex-wrap: nowrap;
  flex-direction: column;
  min-width: 300px;
  @media (max-width: 650px) {
    width: unset;
    max-width: unset;
    min-width: 260px;
    flex-grow: 1;
  }
`
const SkeletonImage = styled(FlexBox)`
  animation: skeletonColor 2s linear infinite;
  height: 145px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 650px) {
    height: 180px;
  }
`
const TopSkeletonOfferDescription = styled(FlexBox)`
  flex-wrap: nowrap;
  max-width: 70%;
  flex-direction: column;
  min-height: 180px;
  max-height: 180px;
  flex-grow: 1;
  @media (max-width: 650px) {
    max-width: calc(100% - 10px);
    height: 100%;
  }
`
const SkeletonValuesText = styled(FlexBox)<{ lenght?: number }>`
  animation: skeletonColor 2s linear infinite;
  height: 15px;
  min-width: ${({lenght}) => lenght}%;
  max-width: ${({lenght}) => lenght}%;
  border-radius: 15px;
  margin: 10px 0;
  margin-left: 10px;

  &:first-of-type {
    margin-top: 24px;
  }

  @media (max-width: 650px) {
    min-width: calc(${({lenght}) => toNumber(lenght) + 20}% - 10px);
    max-width: calc(${({lenght}) => toNumber(lenght) + 20}% - 10px);
  }

  &:last-of-type {
    max-width: 40%;
    min-width: 40%;
    height: 18px;
    margin-left: auto;
    margin-top: auto;
    @media (max-width: 650px) {
      margin-right: 0;
      min-width: 60%;
      max-width: 60%;
    }
  }


`
const SkeletonLabelText = styled(Text)`
  animation: skeletonColor 2s linear infinite;
  margin: auto 0;
  margin-left: 5px;
  margin-right: 2px;
  height: 13px;
  min-width: 40%;
  max-width: 40%;
  border-radius: 15px;
  @media (max-width: 650px) {
    margin: 2px;
  }
`
const StarIcon = styled(FontAwesomeIcon)`
  @keyframes iconSkeleton {
    0% {
      color: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
    50% {
      color: ${(props: { theme: any; }) => props.theme.optionHoverColor};
    }
    100% {
      color: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
  }
  animation: iconSkeleton 2s linear infinite;
  font-size: 10px;
`

export const Styled = {
    SkeletonImage,
    TitleAndImage,
    SkeletonOffer,
    OfferTopLayer,
    SkeletonValuesText,
    SkeletonLabelText,
    StarIcon,
    TopSkeletonOfferDescription
}