import Tooltip from "../../../../../../../../lib/UIBookBrd/Tooltip";
import {GET_TRANSPORT_COMPONENTS} from "../../../../../TripSearchFunctions";

type TransportFieldProps = {
    transport: any
    base: any
    transportType: string
}

const TransportField = (
    {
        transport,
        base,
        transportType,
    }: TransportFieldProps
) => {

    const values = GET_TRANSPORT_COMPONENTS(transport, base, transportType)


    return (
        <>
            <Tooltip
                disabled={!values?.toolTip}
                content={
                    <>
                        {values.toolTip}
                    </>
                }
            >
                {values.value as any}
            </Tooltip>
        </>
    )

}

export default TransportField