import {Styled} from "./ContactView.styles"
import {useTranslation} from "react-i18next";

type ContactViewProps = {
    Contact: any
}

const ContactView = (
    {
        Contact
    }: ContactViewProps
) => {

    const {t} = useTranslation()

    const {
        Email,
        Prefix,
        PhoneNumber,
        Location
    } = Contact

    const {
        HQImage,
        Country,
        City,
        PostCode,
        Address
    } = Location


    return (
        <Styled.Contact__Holder>
            <Styled.Localization__Holder>
                <Styled.HQ__Image
                    src={HQImage}
                />
                <Styled.Localization__Holder__Values>
                    <Styled.Localization__Label>
                        {t('lbl_address')}
                        {': '}
                        <span
                            style={{
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                        >
                    {` ${Address}`}
                </span>
                    </Styled.Localization__Label>
                    <Styled.Localization__Label>
                        {t('lbl_city')}
                        {': '}
                        <span
                            style={{
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                        >
                    {` ${City}, ${PostCode}`}
                </span>
                    </Styled.Localization__Label>
                    <Styled.Localization__Label>
                        {t('lbl_country')}
                        {': '}
                        <span
                            style={{
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                        >
                    {` ${Country}`}
                </span>
                    </Styled.Localization__Label>
                    <Styled.Email__Holder>
                        {t('lbl_operator_email')}
                        {': '}
                        <span
                            style={{
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                        >
                    {` ${Email}`}
                </span>
                    </Styled.Email__Holder>
                    <Styled.Phone__Holder>
                        {t('lbl_operator_phone')}
                        {': '}
                        <span
                            style={{
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                        >
                    {` ${Prefix} ${PhoneNumber}`}
                </span>
                    </Styled.Phone__Holder>
                </Styled.Localization__Holder__Values>
            </Styled.Localization__Holder>
        </Styled.Contact__Holder>
    )
}
export default ContactView