import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";
import Button from "../../lib/UIBookBrd/Button";
import TextInput from "../../lib/UIBookBrd/TextInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";

type styleType = 'default' | 'error' | 'success'

const SignInUp__Holder = styled(FlexBox)`
  max-width: 350px;
  padding: 0 10px;
  width: calc(100% - 20px);
  flex-direction: column;
  margin: 50px auto 0 auto;
`
const Sign__Type = styled(Text)`
  font-size: 30px;
  margin: 10px auto 30px auto;
  text-align: center;
  font-weight: bold;
`
const Error__Message__Label = styled(Text)`
  height: 14px;
  color: ${({theme}) => theme.errorMessageBackgroundColor};
  font-size: 10px;
`
const Continue__Submit__Button = styled(Button)`
  margin: 10px 0 20px 0;
  height: 34px;
`
const Email__Field = styled(TextInput)`

`
const Password__Field = styled(TextInput)`
  margin-top: 10px;
`
const Forgot__Password__Label = styled(Text)`
  margin-top: 10px;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const Password__Condition = styled(Text)<{ type: styleType }>`
  font-size: 13px;
  color: ${({
              theme,
              type
            }) => type === 'success' ? rgba(theme.successMessageBackgroundColor, 2.0) : type === 'error' ? theme.errorMessageIconColor : theme.color};
`
const Conditions__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 10px;
  gap: 4px;
`
const Condition__Icon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`
const Password__Must__Include__Label = styled(Text)`
  padding: 2px 0;
`
const Switch__To__Other__Type__Label = styled(Text)`
  margin: 0 auto;
  cursor: default;
`
const Other__Options__Separator = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 20px 0;
`
const Separator__Line = styled(FlexBox)`
  border-bottom: 1px solid ${({theme}) => theme.color};;
  flex-grow: 1;
  margin-bottom: 6px;
`
const Separator__Label = styled(Text)`
  padding: 0 10px;
  max-width: max-content;
`
const Other__Options__Sign__Box = styled(FlexBox)`
  cursor: pointer;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.color};
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
`
const Brand__Icon_For__Sign = styled(FontAwesomeIcon)`
  height: 25px;
  width: 25px;
  color: ${({theme}) => theme.color};;
`
const Brand__Label = styled(Text)`
  margin-left: 10px;
  font-weight: bold;
`
export const Styled = {
    Brand__Label,
    Brand__Icon_For__Sign,
    Other__Options__Sign__Box,
    Separator__Label,
    Separator__Line,
    Other__Options__Separator,
    Switch__To__Other__Type__Label,
    Password__Must__Include__Label,
    Condition__Icon,
    Conditions__Holder,
    Password__Condition,
    Forgot__Password__Label,
    Password__Field,
    Email__Field,
    Error__Message__Label,
    SignInUp__Holder,
    Sign__Type,
    Continue__Submit__Button
}