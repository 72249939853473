import {Styled} from "../../Offer.styles"
import Images from "../../Parts/Images/Images";
import Title from "../../Parts/Title";
import Details from "../../Parts/Details";
import Location from "../../Parts/Location";
import Price from "../../Parts/Price";
import Buttons from "../../Parts/Buttons";
import SEOLinks from "../../Parts/SEOLinks";


type OfferProps = {
    offerData: any
    openOfferInSameTab?: boolean
}

const Offer = (
    {
        offerData,
        openOfferInSameTab
    }: OfferProps
) => {

    return (
        <Styled.Offer>
            <SEOLinks
                offerData={offerData}
            />
            <Images
                offerData={offerData}
            />
            <Location
                offerData={offerData}
            />
            <Title
                offerData={offerData}
            />
            <Details
                offerData={offerData}
            />
            <Price
                offerData={offerData}
            />
            <Buttons
                openOfferInSameTab={openOfferInSameTab}
                offerData={offerData}
            />
        </Styled.Offer>
    )
}
export default Offer