import {Styled} from "../Offer.styles";
import Button from "../../../lib/UIBookBrd/Button";
import {useTranslation} from "react-i18next";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";

type ButtonsProps = {
    offerData: any
    openOfferInSameTab?: boolean
}

const Buttons = (
    {
        offerData,
        openOfferInSameTab
    }: ButtonsProps
) => {

    const {
        showOfferHandler,
    } = useBookBrdPageFunctions()

    const {t} = useTranslation()

    const showOfferClickHandler = () => {
        showOfferHandler(offerData, !openOfferInSameTab)
    }

    return (
        <Styled.Buttons__Holder>

            <Button
                label={t('lbl_see_offer_button')}
                onClick={showOfferClickHandler}
            />
        </Styled.Buttons__Holder>
    )
}

export default Buttons