import React, {useEffect, useState} from "react";
import {Styled} from "./SearchTripPanel.styles";
import {useTranslation} from "react-i18next";
import DestinationPicker from "../../DestinationPicker";
import ParticipantsPicker from "../../ParticipantsPicker";
import OutBoundPicker from "../../OutBoundPicker";
import DatePicker from "../../DatePicker";
import DurationPicker from "../../DurationPicker";
import {
    DATE_RANGE_FIELD,
    DURATION_FIELD,
    ORIGIN_FIELD,
    PARTICIPANTS_FIELD,
    TO_FIELD,
    XCITY_FIELD,
    XCODE_FIELD
} from "../../../lib/DefaultFormikValue/DefaultSearchFormValues";
import useSearchFormValues from "../../../lib/UseHooksBookBrd/useSearchFormValues";
import useNavigation from "../../../lib/UseHooksBookBrd/useNavigation";
import useLocalData from "../../../lib/UseHooksBookBrd/useSearchBaseData";
import {includes, isArray, isEmpty, isEqual} from "lodash-es";
import LastSearchBoxes from "./LastSearchBoxes";
import moment from "moment";
import {SECOND_STEP_TRIP_SEARCH} from "../../../lib/Routes";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import useAutoSuggest from "../../DestinationPicker/AutoSuggestFunctionality";
import useSiteConfig from "../../../lib/UseHooksBookBrd/useSiteConfig";
import useStorage from "../../../lib/UseHooksBookBrd/useStorage";
import useTripHandler from "../../../lib/UseHooksBookBrd/useTripHandler";

type SearchTripPanelProps = {}


const SearchTripPanel = (
    {}: SearchTripPanelProps
) => {


    const {searchFormValue, setSearchFormValue, setFormPreviousSearch} = useSearchFormValues()

    const {selectedLanguage} = useBookBrdPageFunctions()

    const {searchBaseData} = useLocalData()

    const {getNamesForLastSearchBoxes} = useAutoSuggest(selectedLanguage)

    const {highlightDestination} = useSiteConfig()

    const reRoute = useNavigation()

    const {t} = useTranslation();

    const [destinationCountries, setDestinationCountries] = useState<any[]>([])

    const [searchedValues, setSearchedValues] = useStorage<any>('localStorage', 'trp_src_values', {})

    const {onSearchTrip} = useTripHandler()

    const onBoxClick = (value: any) => {
        setFormPreviousSearch(value)
        reRoute(SECOND_STEP_TRIP_SEARCH)
    }

    const deleteFromHistory = (value: any) => {
        setSearchedValues((state: any) => ({
            ...state,
            offerList: searchedValues?.offerList?.filter((search: any) => JSON.stringify(search) !== JSON.stringify(value))
        }))
    }

    const getLabel = (option: any) => {
        const allCodes: any[] = []
        const countriesRegions = option[TO_FIELD]
        const hotels = option[XCODE_FIELD]
        const cities = option[XCITY_FIELD]

        if (!isEmpty(countriesRegions)) {
            countriesRegions.map((id: string) => allCodes.push(id))
        }
        if (!isEmpty(hotels)) {
            hotels.map((code: any) => allCodes.push(code))
        }
        if (!isEmpty(cities)) {
            allCodes.push(cities)
        }

        if (!isEmpty(allCodes)) {
            const filteredList = searchedValues.list?.filter((option: any) => includes(allCodes, option.value)).map((item: any) => item.label)
            return {
                destinationToolTipContent: `${filteredList?.join(', ')}`,
                destinationLabelValue: `${filteredList[0]} ${filteredList?.length > 1 ? `+ ${filteredList?.length - 1}` : ''}`
            }

        }
        return {
            destinationToolTipContent: t('lbl_anywhere'),
            destinationLabelValue: t('lbl_anywhere')
        }


    }

    useEffect(() => {
        if (!isEmpty(searchBaseData) && !isEmpty(searchBaseData[TO_FIELD]) && !isEmpty(searchBaseData[TO_FIELD]?.countryandregion)) {
            const newList: any[] = []
            const destinationCountriesRegions = searchBaseData[TO_FIELD]?.countryandregion
            Object.keys(destinationCountriesRegions ? destinationCountriesRegions : {})?.map((countryId: any) => {
                const countryDesc = destinationCountriesRegions[countryId]?.desc
                const regions = destinationCountriesRegions[countryId]?.regions
                newList.push({
                    label: countryDesc,
                    id: countryId
                })
                if (!isEmpty(regions)) {
                    Object.keys(regions)?.map((regionId: any) => {
                        const regionDesc = regions[regionId]?.desc
                        newList.push({
                            label: regionDesc,
                            id: regionId
                        })

                    })
                }
            })
            setDestinationCountries(newList)
        }
    }, [searchBaseData])

    useEffect(() => {
        sessionStorage.setItem('TFOB', 'false')
        if (!isEmpty(searchedValues?.offerList)) {
            const updatedList = searchedValues?.offerList?.filter((option: any) => {
                const startDate = option[DATE_RANGE_FIELD]?.startDate
                const isBefore = moment(startDate, 'DD.MM.YYYY').isBefore(moment(), 'day')
                return !isBefore
            })
            setSearchedValues((state: any) => ({
                ...state,
                offerList: updatedList
            }))
        }
        if (!isEqual(selectedLanguage, searchedValues?.language)) {
            if (isEmpty(searchedValues?.offerList)) {
                setSearchedValues((state: any) => ({
                    ...state,
                    language: selectedLanguage
                }))
            } else {
                getNamesForLastSearchBoxes(searchedValues?.offerList, setSearchedValues)
            }
        }
    }, [])

    return (
        <Styled.SearchPanel__Holder>
            <Styled.SearchPanel
                isSetMargin={isEmpty(searchedValues?.offerList)}
            >
                <Styled.SearchPanelEssentialComponents>
                    <Styled.DestinationHolder>
                        <OutBoundPicker
                            formikValue={searchFormValue[ORIGIN_FIELD]}
                            setFormikValues={setSearchFormValue}
                        />
                    </Styled.DestinationHolder>
                    <Styled.DestinationHolder>
                        <DestinationPicker
                            highLightedOption={highlightDestination}
                            values={!isEmpty(searchBaseData) ? searchBaseData[TO_FIELD] ? searchBaseData[TO_FIELD]?.countryandregion : {} : {}}
                            formikValue={searchFormValue[TO_FIELD]}
                            setFormikValues={setSearchFormValue}
                        />
                    </Styled.DestinationHolder>
                    <Styled.CalendarHolder>
                        <DatePicker
                            formikValue={searchFormValue[DATE_RANGE_FIELD]}
                            setFormikValues={setSearchFormValue}
                        />
                    </Styled.CalendarHolder>
                    <Styled.ParticipantsHolder>
                        <ParticipantsPicker
                            formikValue={searchFormValue[PARTICIPANTS_FIELD]}
                            setFormikValues={setSearchFormValue}
                        />
                    </Styled.ParticipantsHolder>
                    <Styled.DurationHolder>
                        <DurationPicker
                            formikValue={searchFormValue[DURATION_FIELD]}
                            setFormikValues={setSearchFormValue}
                        />
                    </Styled.DurationHolder>
                    <Styled.ButtonHolder>
                        <Styled.ApplyButton
                            label={t('lbl_search')}
                            onClick={onSearchTrip}
                        />
                    </Styled.ButtonHolder>
                </Styled.SearchPanelEssentialComponents>
            </Styled.SearchPanel>
            {!isEmpty(searchedValues?.offerList) && (
                <Styled.Previous__Search__Label>
                    {t('lbl_recent_searches')}
                </Styled.Previous__Search__Label>
            )}
            <Styled.Previous__Search__Boxes__Holder>
                {isArray(searchedValues?.offerList) && searchedValues?.offerList?.map((option: any, index: number) => {

                        return (
                            <LastSearchBoxes
                                key={`last_search_boxes_${index}`}
                                destinationCountries={destinationCountries}
                                getLabel={getLabel}
                                option={option}
                                deleteFromHistory={deleteFromHistory}
                                onBoxClick={onBoxClick}
                            />
                        )
                    }
                )}
            </Styled.Previous__Search__Boxes__Holder>
        </Styled.SearchPanel__Holder>
    )
}
export default SearchTripPanel