import {Styled} from "../../../TripSearchBookingPage";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {participantsType} from "../../../types";
import {isEmpty} from "lodash-es";
import useBookingFunctions from "../../../functions";
import {useTranslation} from "react-i18next";

const CardGraph = () => {

    const {
        state,
        getCardNumberValue,
        getCardDateValueForCard,
    } = useBookingFunctions()

    const {t} = useTranslation();

    return (
        <Styled.Payment__Card__Graph>
            <Styled.Card__Chip
                src={'https://firebasestorage.googleapis.com/v0/b/bookbrd.appspot.com/o/card%20chip.png?alt=media&token=6e20c505-39af-4a1a-8e58-368c3552bf85&_gl=1*b6odhg*_ga*MTIzMjI5MTc2MC4xNjc4OTc3MTQz*_ga_CW55HF8NVT*MTY5NjU5NjY4NC42Ny4xLjE2OTY1OTY3MTMuMzEuMC4w'}
            />
            <FlexBox>
                <FlexBox style={{flexDirection: 'column'}}>
                    <Styled.Card__Field__Name>
                        {t('lbl_cardNumber')}
                    </Styled.Card__Field__Name>
                    <Styled.Payment__Card__Number>
                        {getCardNumberValue(state?.participantsSection[`${participantsType.payment}_0`]?.cardNumber?.split(' ').join('')) ?? ''}
                    </Styled.Payment__Card__Number>
                </FlexBox>
                <FlexBox style={{flexDirection: 'column', marginLeft: 'auto'}}>
                </FlexBox>
            </FlexBox>
            <FlexBox>
                <FlexBox style={{flexDirection: 'column'}}>
                    <Styled.Card__Field__Name>
                        {t('lbl_cardHolder')}
                    </Styled.Card__Field__Name>
                    <Styled.Payment__Card__Name__Date>
                        {!isEmpty(state?.participantsSection[`${participantsType.payment}_0`]?.cardHolder) ? state?.participantsSection[`${participantsType.payment}_0`]?.cardHolder : t('lbl_cardHolder_placeholder')}
                    </Styled.Payment__Card__Name__Date>
                </FlexBox>
                <FlexBox style={{flexDirection: 'column', marginLeft: 'auto'}}>
                    <Styled.Card__Field__Name
                        style={{
                            marginLeft: '0',
                            marginRight: '10px'
                        }}
                    >
                        {t('lbl_cardDate')}
                    </Styled.Card__Field__Name>
                    <Styled.Payment__Card__Name__Date>
                        {getCardDateValueForCard(state?.participantsSection[`${participantsType.payment}_0`]?.cardDate) ?? ''}
                    </Styled.Payment__Card__Name__Date>
                </FlexBox>
            </FlexBox>


        </Styled.Payment__Card__Graph>
    )
}
export default CardGraph