import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Image from "../../../../../../lib/UIBookBrd/Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";

const Gallery__Holder = styled(FlexBox)<{ titleHeight: any }>`
  flex-wrap: nowrap;
  width: 100%;
  flex-grow: 1;
  flex-direction: initial;
  margin-right: 4px;
  max-height: 400px;
  margin-top: ${({titleHeight}) => titleHeight}px;
  @media (max-width: calc(800px + 2vw)) {
    height: 300px;
    max-height: unset;
    flex-direction: column;
    min-width: 250px;
    max-width: 800px;
    margin-right: 0;
  }
`
const Main__Image__Holder = styled(FlexBox)`
  height: 400px;
`
const Main__Image = styled(Image)`
  max-width: 800px;
  object-fit: cover;
  width: 100%;
  flex-grow: 1;
  min-width: 400px;
  height: 400px;
  @media (max-width: calc(800px + 2vw)) {
    height: 300px;
    max-height: 300px;
    min-width: 250px;
  }
`
const Small__Images__Holder = styled(FlexBox)<{ isLoadingSections: boolean }>`
  flex-wrap: nowrap;
  gap: 0 4px;
  overflow: ${({isLoadingSections}) => isLoadingSections ? 'hidden' : 'scroll'};
  flex-grow: 1;
  margin: 0 2px;
  height: 100px;
  min-height: 100px;
  scroll-behavior: smooth;
  display: none;
  @media (max-width: calc(800px + 2vw)) {
    display: flex;
  }
`
const Small__Image = styled(Image)<{ isCurrentImage: boolean }>`
  margin-top: 5px;
  height: 90px;
  width: 130px;
  min-width: 130px;
  box-sizing: border-box;
  border: ${({theme, isCurrentImage}) => isCurrentImage ? `1px solid ${theme.color}` : undefined};
  outline: ${({theme, isCurrentImage}) => isCurrentImage ? `1px solid ${theme.color}` : undefined};
  cursor: pointer;

  &:first-of-type {
    margin-left: 1px;
  }

  &:last-of-type {
    margin-right: 1px;
  }
;
`
const Loading__Small__Image = styled(FlexBox)`
  margin-top: 5px;
  height: 90px;
  width: 130px;
  min-width: 130px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.optionHoverColor};;
`

const Control__Holder = styled(FlexBox)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`
const Control = styled(FlexBox)`
  cursor: pointer;
  height: 39px;
  width: 39px;
  border-radius: 50%;
  margin: auto 5px;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.4)};
  border: 1px solid ${({theme}) => theme.color};
  transition: scale 200ms;

  &:hover {
    background-color: ${({theme}) => rgba(theme.backgroundColor, 0.6)};
  }
`
const Controls__Separator = styled(FlexBox)`
  flex-grow: 1;
  cursor: pointer;
  height: 100%;
`
const Control__Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  margin: auto;
  color: ${({theme}) => theme.color};
`
const Image__Holder = styled(FlexBox)`
  height: 400px;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 796px;
  min-width: 446px;
  @media (max-width: calc(800px + 2vw)) {
    flex-direction: column;
    min-width: 250px;
    max-width: 800px;
    margin-right: 0;
  }
`
const Two__Images__Holder = styled(FlexBox)`
  width: 350px;
  min-width: 350px;
  margin-left: 2px;
  height: 400px;
  flex-direction: column;
  flex-wrap: nowrap;
  @media (max-width: calc(800px + 2vw)) {
    display: none;
  }
`
const Big__Image = styled(Image)`
  object-fit: cover;
  width: calc(100% - 4px);
  margin: 0 2px;
  height: 198px;

  &:first-of-type {
    margin-bottom: 4px;
  }
`

export const Styled = {
    Loading__Small__Image,
    Big__Image,
    Two__Images__Holder,
    Image__Holder,
    Controls__Separator,
    Gallery__Holder,
    Main__Image__Holder,
    Main__Image,
    Small__Images__Holder,
    Control__Holder,
    Control,
    Control__Icon,
    Small__Image
}