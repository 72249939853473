import {userPageViews} from "../../../lib/Types";
import AboutView from "./AboutView";
import MyTrips from "./MyTrips";
import MainView from "./MainView";
import ReviewsView from "./ReviewsView";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

const ViewContent = () => {

    const [sectionsValue, setSectionValues] = useState<any>({})

    const {pageType} = useParams()

    const views: any = {
        [userPageViews.about]: <AboutView value={sectionsValue[pageType as userPageViews]}/>,
        [userPageViews.myTrips]: <MyTrips value={sectionsValue[pageType as userPageViews]}/>,
        [userPageViews.main]: <MainView value={sectionsValue[pageType as userPageViews]}/>,
        [userPageViews.reviews]: <ReviewsView value={sectionsValue[pageType as userPageViews]}/>,
    }

    const fetchSection = async (pageType: userPageViews) => {
        try {
            setSectionValues((state: any) => ({
                ...state,
                [pageType]: {
                    ...state[pageType],
                    requestSent: true,
                }
            }))
        } catch (e: any) {
            console.log({e})
        } finally {
            setTimeout(() => {
                setSectionValues((state: any) => ({
                    ...state,
                    [pageType]: {
                        ...state[pageType],
                        isLoaded: true
                    }
                }))
            }, 500)
        }
    }

    useEffect(() => {
        if (!sectionsValue[pageType as userPageViews]?.requestSent) {
            fetchSection(pageType as userPageViews)
        }
    }, [pageType])

    return (
        <>
            {views[pageType as userPageViews]}
        </>
    )
}

export default ViewContent