import RegionBox from "../RegionBox";
import {Styled} from "../RegionsHolder.styles";
import {Region} from "../../../../../lib/Types";
import {useRef, useState} from "react";

type RegionsHolderProps = {
    list: Region[]
}

const RegionsHolder = (
    {
        list
    }: RegionsHolderProps
) => {

    const defaultSliderState = {
        progress: 0,
        index: 0,
    }

    const [sliderState, setSliderState] = useState(defaultSliderState);

    const swiperRef = useRef<any>(null)

    const onPrevButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slidePrev();
    }

    const onNextButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slideNext();
    }

    const onActiveIndexChange = (swiper: any) => {
        setSliderState((state: any) => ({
            ...state,
            progress: swiper.progress,
            index: swiper?.activeIndex,
        }));
    };

    const wideScreenContent = (
        <Styled.Wide__Screen__Content__Holder>
            <Styled.Grande_Region__Box__Holder>
                <RegionBox
                    data={list[0]}
                />
            </Styled.Grande_Region__Box__Holder>
            <Styled.Non_Priority__Boxes__Holder>
                {Array.from({length: 4}).map((_, index) => (
                    <Styled.Medium__Size__Box
                        key={`region_medium_${list[index + 1].name}`}
                    >
                        <RegionBox
                            data={list[index + 1]}
                        />
                    </Styled.Medium__Size__Box>
                ))}
            </Styled.Non_Priority__Boxes__Holder>
        </Styled.Wide__Screen__Content__Holder>
    )

    const smallScreenContent = (
        <Styled.Small__Width__Content__Holder>
            {list.map((option: any) => (
                <Styled.Grande_Region__Box__Holder
                    key={`region_key_${option.name}`}
                >
                    <RegionBox
                        data={option}
                    />
                </Styled.Grande_Region__Box__Holder>
            ))}
        </Styled.Small__Width__Content__Holder>
    )

    return (
        <Styled.Regions__Holder>
            {wideScreenContent}
            {smallScreenContent}
        </Styled.Regions__Holder>
    )
}
export default RegionsHolder