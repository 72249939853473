import {Styled} from "./SpecialInput.styles";
import React, {forwardRef, useEffect, useRef, useState} from "react";

type TextInputProps = {
    title?: string
    hideTextInput?: boolean
    readOnly?: boolean
    style?: React.CSSProperties
    value?: any
    onClear?: any
    showIcon?: boolean
    isClearAble?: boolean
    placeholder?: string
    onChange?: any
    onFocus?: any
    onBlur?: any
    onClick?: any
    setFocusElement?: any
}
const defaultProps = {
    title: undefined,
    hideTextInput: false,
    style: undefined,
    readOnly: false,
    value: undefined,
    onChange: undefined,
    onClear: undefined,
    showIcon: true,
    isClearAble: true,
    placeholder: 'insert placeholder',
    onFocus: undefined,
    onBlur: undefined,
    onClick: undefined,
    setFocusElement: undefined
}

const SpecialInput = forwardRef<HTMLDivElement, TextInputProps>(({
                                                                     title,
                                                                     hideTextInput,
                                                                     readOnly,
                                                                     value,
                                                                     onClear,
                                                                     setFocusElement,
                                                                     showIcon,
                                                                     isClearAble,
                                                                     placeholder,
                                                                     onChange,
                                                                     onFocus,
                                                                     onBlur,
                                                                     onClick,
                                                                     style,
                                                                 }, ref) => {


    const [isFocused, setIsFocused] = useState<Boolean>(false);
    const inputRef = useRef<any>();

    const onClearHandler = () => {
        onClear()
    }

    const onFocusChange = () => {
        setIsFocused(true)
        if (onFocus) {
            onFocus()
        }
    }

    const setFocus = () => {
        if (onClick) {
            onClick()
        }
        inputRef.current?.focus();
    }

    const onBlurChange = () => {

        setIsFocused(false)
        if (onBlur) {
            onBlur()
        }

    }

    const changeHandler = (event: any) => {
        onChange(event)
    }
    useEffect(() => {
        if (setFocusElement) {
            setFocusElement(inputRef)
        }
    }, [])
    return (
        <Styled.TextInput
            ref={ref}
            onClick={setFocus}
            style={style}
            isChildFocused={isFocused}
        >
            <Styled.Input
                unselectable={readOnly ? "on" : "off"}
                hideTextInput={hideTextInput}
                readOnly={readOnly}
                ref={inputRef}
                value={value?.label}
                onFocus={onFocusChange}
                onBlur={onBlurChange}
                placeholder={placeholder}
                onChange={changeHandler}
                type={"text"}
            />
        </Styled.TextInput>
    )
})
SpecialInput.defaultProps = defaultProps;

export default SpecialInput