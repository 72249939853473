import Slider from '@mui/material/Slider';
import {useContext} from "react";
import {ThemeContext} from "styled-components";


type SliderBookBrdProps = {
    min?: number
    max?: number
    step?: number
    minDistance?: number
    value?: [number, number]
    setValue?: any
}
const defaultProps = {
    setValue: () => false,
    minDistance: 0,
    value: [0, 20],
    min: 0,
    max: 100,
    step: 0
}


const SliderBookBrd = (
    {
        setValue,
        value,
        minDistance,
        min,
        max,
        step
    }: SliderBookBrdProps
) => {

    const theme = useContext(ThemeContext)

    const handleChange1 = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (value) {
            if (!Array.isArray(newValue)) {
                return;
            }

            if (activeThumb === 0) {
                setValue([Math.min(newValue[0], value[1] - (minDistance ?? 0)), value[1]]);
            } else {
                setValue([value[0], Math.max(newValue[1], value[0] + (minDistance ?? 0))]);
            }
        }
    };


    return (
        <Slider
            getAriaLabel={() => 'Minimum distance'}
            value={value}
            onChange={handleChange1}
            min={min}
            max={max}
            step={step}
            sx={{
                width: '100%',
                color: theme.color,
                '& .MuiSlider-track': {
                    border: 'none',
                },
                '& .MuiSlider-thumb': {
                    width: 15,
                    height: 15,
                    backgroundColor: '#fff',
                    '&:before': {
                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                    },
                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                        boxShadow: 'none',
                    },
                },
            }}
            valueLabelDisplay="off"
            getAriaValueText={(value: any) => {
                return `${value}$`
            }}

            disableSwap
        />
    )
}
SliderBookBrd.defaultProps = defaultProps;

export default SliderBookBrd