import FlexBox from "./lib/UIBookBrd/FlexBox";
import Navigation from "./Components/Navigation";
import {useLocation} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import BookBrdModulesHolder from "./Components/BookBrdModulesHolder";
import ErrorScreen from "./ErrorScreen";
import useConfigureSite from "./lib/UseHooksBookBrd/useConfigureSite";
import {WCSErrors} from "./lib/UseHooksBookBrd/useHandleOnlineRequestError";
import usePagesConfigs from "./lib/PagesConfigs";
import {Local_Data, Search_Proto} from "./lib/Context/Context";
import useMetaTags from "./lib/UseHooksBookBrd/useMetaTags";
import routesHandler from "./lib/RoutesHandler";
import RoutesBookBrd from "./Routes";
import TylerBot from "./Components/TylerTheBot/Core";


// XML CONSTRUCTOR 
const getSiteXML = (paths: any) => {
    const DefaultPRIO = 0.5
    const DefaultFreq = 'monthly'

    const urls = paths.filter((item: any) => item.pathName.includes('/')).map((path: any) => {
        const url = `https://bookbrd.com${path.pathName}`;
        const lastmod = '2023-11-09T00:00:00.000Z';
        const priority = path.priority || DefaultPRIO;
        const changefreq = path.changeFreq || DefaultFreq;

        return `
      <url>
        <loc>${url}</loc>
        <lastmod>${lastmod}</lastmod>
        <priority>${priority}</priority>
        <changefreq>${changefreq}</changefreq>
      </url>
    `;
    });

    return `
    <?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${urls.join('')}
    </urlset>
  `;
};

const BookBrd = () => {

    const location = useLocation()

    const [hashValue, _] = useContext<any>(Search_Proto)

    const [localData, setLocalData] = useContext<any>(Local_Data)

    const {
        getPageContent,
    } = usePagesConfigs()

    const {
        routerController
    } = routesHandler()

    const {getMetaForSEO} = useMetaTags()

    // const XMLSITE = getSiteXML(routesList)
    //
    // console.log({XMLSITE})

    const {
        errorType,
        showCookiesDrawer,
        comingSoonFunc,
    } = useConfigureSite()


    useEffect(() => {
        routerController()
        getPageContent()
        comingSoonFunc()
    }, [location.pathname])

    useEffect(() => {
        getMetaForSEO()
    }, [hashValue?.SearchFormValues, location])

    useEffect(() => {
        let value = localData
        value.isOnline = navigator.onLine
        setLocalData(value)
        window.addEventListener('online', () => {
            setLocalData((state: any) => ({
                ...state,
                isOnline: true
            }))
        })
        window.addEventListener('offline', () => {
            setLocalData((state: any) => ({
                ...state,
                isOnline: false
            }))
        })
        return () => {
            window.removeEventListener('online', () => {
                setLocalData((state: any) => ({
                    ...state,
                    isOnline: true
                }))
            })
            window.removeEventListener('offline', () => {
                setLocalData((state: any) => ({
                    ...state,
                    isOnline: false
                }))
            })
        }
    }, [])

    useEffect(() => {
        if (localData?.disableScroll || localData?.isLoading || showCookiesDrawer) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [localData?.disableScroll, localData?.isLoading, showCookiesDrawer])

    return (
        <>
            <img
                src={'https://firebasestorage.googleapis.com/v0/b/bookbrd.appspot.com/o/TabLogo.png?alt=media&token=d47be325-88c2-44e6-8802-4557f645b740'}
                alt={'BookBrd company logo'}
                sizes={'1px 1px'}
                style={{
                    width: '1px',
                    height: '1px',
                    visibility: 'hidden',
                    zIndex: '-999',
                    position: 'absolute'
                }}
            />
            <FlexBox
                style={{
                    display: 'block',
                    height: '100%',

                }}
            >
                {!errorType ? (
                    <>
                        <Navigation/>
                        <RoutesBookBrd/>
                        <TylerBot/>
                        <BookBrdModulesHolder/>
                    </>
                ) : (
                    <>
                        <ErrorScreen
                            errorType={errorType as WCSErrors}
                        />
                    </>
                )}
            </FlexBox>
        </>
    )
}
export default BookBrd