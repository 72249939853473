import React, {useEffect, useState} from "react";
import {User_Data} from "../../../lib/Context/Context";
import {accountPageType} from "../../../lib/Types";
import useUserData from "../../../lib/UseHooksBookBrd/useUserData";

type ContextProviderProps = {
    children: React.ReactElement
    type: accountPageType
}

const ContextProvider = (
    {
        children,
        type
    }: ContextProviderProps
) => {
    const {
        userName,
        userImage,
        deleteBackground,
        userBackgroundImage,
        userBackgroundColor,
        isLogged
    } = useUserData()

    const getContextValue = (type: accountPageType) => {
        switch (type) {
            case accountPageType.other:
                return {
                    //    isLoading: true,
                    type: accountPageType.other,
                    userName: 'Tyler',
                    userBackgroundColor: '#c800ff',
                    isLogged: true,
                    userBackgroundImage: 'https://kinorotterdam.nl/content/uploads/2023/09/Fight-club-still-1.jpg',
                    userImage: 'https://media.tenor.com/yWVN7tL5BvoAAAAd/tyler-durden.gif'
                }
            case accountPageType.own:
                return {
                    type: accountPageType.own,
                    isLogged: isLogged,
                    userName: userName,
                    userImage,
                    userBackgroundImage,
                    deleteBackground,
                    userBackgroundColor
                }
            default:
                return {}
        }
    }

    const [state, setState] = useState(getContextValue(type))

    useEffect(() => {
        if (state?.type !== type) {
            setState(getContextValue(type))
        }
    }, [type])

    return (
        <User_Data.Provider value={[state, setState]}>
            {children}
        </User_Data.Provider>
    )
}

export default ContextProvider

