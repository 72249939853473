import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";
import Button from "../../../lib/UIBookBrd/Button";
import {rgba} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Trip_Offer_Details_Page_Holder = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Offer_Details_Header = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-direction: column;
  padding-bottom: 29px;
  @media (max-width: calc(800px + 2vw)) {
    padding-bottom: 0;
    border-bottom: 1px solid ${({theme}) => theme.color};
    flex-direction: column;
  }
`
const Select__Option = styled(FlexBox)`
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 4px;
  align-items: center;
  width: calc(100% - 8px);
`
const Select__New__Option = styled(FlexBox)<{ isSelected: boolean }>`
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 10px 8px;
  border: 1px solid ${({theme, isSelected}) => isSelected ? theme.color : rgba(theme.color, 0.4)};
  outline: 0;
  border-radius: 5px;
  align-items: center;
  width: calc(100% - 16px);
  margin: 4px 8px;

  &:hover {
    outline: 1px solid ${({theme, isSelected}) => isSelected ? 'transparent' : rgba(theme.color, 0.4)};;
  }
`

const MainValue_Holder = styled(Text)<{ enableWhiteSpace?: boolean }>`
  overflow: hidden;
  user-select: none;
  white-space: ${({enableWhiteSpace}) => enableWhiteSpace ? undefined : 'nowrap'};
  text-overflow: ellipsis;
  font-size: 13px;
`
const Price_Holder = styled(Text)<{ isMore: any, isEqual: boolean }>`
  margin-left: auto;
  font-weight: bold;
  white-space: ${({isEqual}) => isEqual ? 'nowrap' : undefined};
  color: ${({
              isMore,
              isEqual,
              theme
            }) => isEqual ? theme.color : isMore ? theme.errorMessageBackgroundColor : theme.lowerPriceColor};
`

const No__Offer__Found__Label = styled(Text)`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 5px;
  user-select: none;
`
const No__Offer__Found__Values__Holder = styled(FlexBox)`
  flex-direction: column;
  width: calc(100% - 20px);
  max-width: 1100px;
  margin: auto;
  padding: 25px 10px;
`
const No__Offer__Found__Description__Label = styled(Text)`
  padding-bottom: 30px;
  text-align: center;
  user-select: none;
  font-size: 16px;
`
const No__Offer__Found__Buttons__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%
`
const Custom__Button = styled(Button)`
  height: 34px;
  flex-grow: 1;

  &:first-of-type {
    margin-left: auto;
    margin-right: 2px;
  }

  &:last-of-type {
    margin-left: 2px;
    margin-right: auto;
  }
`
const Close__Map__Button__Large__Screen = styled(Button)`
  margin: 10px 10px auto auto;
  z-index: 10;
  padding: 0 10px;
  @media (max-width: 900px) {
    display: none;
  }
`
const Close__Map__Button__Small_Screen = styled(Button)`
  margin: 10px 10px auto auto;
  z-index: 10;
  padding: 0 10px;
  @media (min-width: 900px) {
    display: none;
  }
`
const Map__Full__Screen_Holder = styled(FlexBox)`
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  position: fixed;
  z-index: 999;
  flex-direction: column;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.9)};
`

const Close__Map__Button__Large__Screen__Content = styled(FlexBox)`
  color: inherit;

`

const Close__Map__Icon__Big__Button = styled(FontAwesomeIcon)`
  color: inherit;
  height: 16px;
  width: 16px;
  margin-left: 2px;
  margin-top: 1px;
`
const Select__Option__Selected__Icon = styled(FontAwesomeIcon)`
  margin: auto 10px auto 0;
  width: 17px;
  height: 17px;
  color: ${({theme}) => theme.color};
`
const Selected__Option__Select__Inner__Cirlce = styled(FontAwesomeIcon)`
  width: 11px;
  height: 13px;
  margin: auto 10px auto -24px;
  color: ${({theme}) => theme.color};
`
const Offer__Not__Found__Holder = styled(FlexBox)`
  flex-direction: column;
  position: absolute;
  top: 57px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`
const Offer__Not__Found__Higher__Part = styled(FlexBox)`
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
`
const Separator__Line = styled(FlexBox)`
  border-bottom: 1px solid ${({theme}) => theme.color};
  flex-grow: 1;
  margin-bottom: 6px;
`
const Separator__Text = styled(Text)`
  padding: 0 10px;
`
const Separator__Holder = styled(FlexBox)`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px 0;
`
export const Styled = {
    Separator__Holder,
    Separator__Text,
    Separator__Line,
    Offer__Not__Found__Higher__Part,
    Offer__Not__Found__Holder,
    Selected__Option__Select__Inner__Cirlce,
    Select__Option__Selected__Icon,
    Select__New__Option,
    Close__Map__Icon__Big__Button,
    Close__Map__Button__Large__Screen__Content,
    Map__Full__Screen_Holder,
    Close__Map__Button__Small_Screen,
    Close__Map__Button__Large__Screen,
    Custom__Button,
    No__Offer__Found__Buttons__Holder,
    No__Offer__Found__Description__Label,
    No__Offer__Found__Values__Holder,
    No__Offer__Found__Label,
    Price_Holder,
    MainValue_Holder,
    Select__Option,
    Trip_Offer_Details_Page_Holder,
    Offer_Details_Header
}
