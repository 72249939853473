import {Styled} from "./TripOffersDetails.styles";
import {useTranslation} from "react-i18next";
import useNavigation from "../../../lib/UseHooksBookBrd/useNavigation";
import {FIRST_STEP_TRIP_SEARCH, THIRD_STEP_TRIP_SEARCH} from "../../../lib/Routes";
import OfferAlternatives from "./DedicatedComponent/OfferDetailsHolder/OfferAlternatives";
import {useEffect, useState} from "react";
import {alternativesData} from "../../../lib/Types";
import {promiseRequest} from "../../../lib/RequestBuilder";
import {CONTENT_SERVICE_URL} from "../../../lib/Configs/RequestConfig";
import useRoutesHandler from "../../../lib/RoutesHandler";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {useDebouncedCallback} from "use-debounce";

const EmptyOffer = () => {

    const [alternative, setAlternative] = useState<alternativesData>({isLoading: true})

    const {t} = useTranslation();

    const {createParamsMap} = useRoutesHandler()

    const {selectedLanguage} = useBookBrdPageFunctions()

    const reRoute = useNavigation()

    const onGoBackButton = () => {
        reRoute(FIRST_STEP_TRIP_SEARCH)
    }
    const getOfferID = () => {
        const offerParams = window.location.pathname.replace(`${THIRD_STEP_TRIP_SEARCH}/`, '').split('/')
        const value = createParamsMap(offerParams)?.offerId
        return value ?? ''
    }

    const fetchAlternatives = async () => {
        try {
            setAlternative((state: any) => ({
                ...state,
                isLoading: true
            }))
            const requestParams = {
                Language: selectedLanguage,
                OfferId: getOfferID(),
                AlternativeOffers: {
                    Type: "OtherOperatorsHotels",
                    AlternativeOperators: true,
                    AlternativeRegions: false,
                    AlternativeDates: false,
                    limit: 10
                }
            }
            const response = await promiseRequest(`${CONTENT_SERVICE_URL}AlternativeOffers/${JSON.stringify(requestParams)}`, undefined, 3)
            const {Sections} = response
            const {AlternativeOffers} = Sections
            const {Offers} = AlternativeOffers
            setAlternative((state: any) => ({
                ...state,
                offers: Offers,
                errors: undefined,
            }))
        } catch (e) {
            setAlternative((state: any) => ({
                ...state,
                errors: 'Error',
            }))
        } finally {
            setAlternative((state: any) => ({
                ...state,
                isLoading: false
            }))
        }
    }

    const debounceFetch = useDebouncedCallback(() => {
        fetchAlternatives()
    }, 200)

    useEffect(() => {
        if (!alternative?.requestSent) {
            setAlternative((state: alternativesData) => ({
                ...state,
                requestSent: true
            }))
            debounceFetch()
        }
    }, [])

    return (
        <Styled.Offer__Not__Found__Holder>
            <Styled.No__Offer__Found__Values__Holder>
                <Styled.Offer__Not__Found__Higher__Part>
                    <Styled.No__Offer__Found__Label>
                        {t('lbl_no_offer_found_details')}
                    </Styled.No__Offer__Found__Label>
                    <Styled.No__Offer__Found__Description__Label>
                        {t('lbl_no_offer_found_description')}
                    </Styled.No__Offer__Found__Description__Label>
                    <Styled.No__Offer__Found__Buttons__Holder>
                        <Styled.Custom__Button
                            onClick={onGoBackButton}
                            label={t('lbl_go_back_to_search')}
                        />
                    </Styled.No__Offer__Found__Buttons__Holder>
                </Styled.Offer__Not__Found__Higher__Part>
                <Styled.Separator__Holder>
                    <Styled.Separator__Line/>
                    <Styled.Separator__Text>
                        {t('lbl_or')}
                    </Styled.Separator__Text>
                    <Styled.Separator__Line/>
                </Styled.Separator__Holder>
                <Styled.No__Offer__Found__Label>
                    {t('lbl_find_similar_alternative')}
                </Styled.No__Offer__Found__Label>
                <OfferAlternatives
                    openOfferInSameTab={true}
                    data={alternative}
                />
            </Styled.No__Offer__Found__Values__Holder>
        </Styled.Offer__Not__Found__Holder>
    )
}
export default EmptyOffer