// @ts-ignore
import styled from "styled-components";
import Text from "./lib/UIBookBrd/Text";
import FlexBox from "./lib/UIBookBrd/FlexBox";
import Button from "./lib/UIBookBrd/Button";

const LoadingScreen = styled.div`
  z-index: 99999;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  overflow: hidden;
  scroll-behavior: unset;
`;
const LoaderHolder = styled.div`
  height: 100%;
`
const ErrorScreen = styled.div`
  @keyframes showTextAnimation {
    100% {
      color: ${(props: { theme: any; }) => props.theme.color};
    }
    30% {
      color: ${({theme}) => theme.backgroundColor};
    }
    0% {
      color: ${({theme}) => theme.backgroundColor};
    }
  }
  user-select: none;
  top: 0px;
  bottom: 0px;
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  left: 0px;
  position: absolute;
  align-items: center;
  align-content: center;
  right: 0px;
`
const TextHolder = styled(Text)`
  align-items: center;
  text-align: center;
  align-content: center;
  animation: showTextAnimation 1s linear;
`
const RegularFont = styled.span`
  font-weight: bold;
  font-family: serif;
  font-size: 10vw;
  @media (min-width: 1100px) {
    font-size: 80px;
  }
`
const TiltedFont = styled.span`
  font-family: HandWritenBrdStyle;
  font-weight: lighter;
  font-size: 10vw;
  margin-left: 2vw;
  @media (min-width: 1100px) {
    font-size: 80px;
    margin-left: 20px;
  }
`
const ComingSoonLabel = styled(Text)`
  margin: 0 auto;
  width: max-content;
  font-size: 5vw;
  font-family: serif;
  margin-top: -10px;
  animation: showTextAnimation 1s linear;
  @media (min-width: 1100px) {
    font-size: 40px
  }
`
const MarginTopElement = styled(FlexBox)<{ elementHeight: number, windowHeight: number }>`
  margin-top: calc((${({windowHeight}) => windowHeight}px - ${({elementHeight}) => elementHeight}px) / 2);
`
const ActionButton = styled(Button)`
  @keyframes buttonAnimation {
    100% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    0% {
      opacity: 0;
    }
  }
  animation: buttonAnimation 1s linear;
  max-width: 200px;

  margin-top: 10px;
  min-width: 150px;

  &:first-of-type {
    margin-left: auto;
  }

  &:last-of-type {
    margin-right: auto;
  }



`

export const Styled = {
    LoaderHolder,
    LoadingScreen,
    ErrorScreen,
    TextHolder,
    RegularFont,
    TiltedFont,
    ComingSoonLabel,
    ActionButton,
    MarginTopElement
}