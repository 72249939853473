import {GET_TRANSPORT_TYPE} from "../../../../TripSearchResultPage/TripSearchFunctions";
import {Styled} from "./OfferDetails.styles";
import {isEmpty} from "lodash-es";
import FlightDescription
    from "../../../../TripSearchResultPage/Content/TripOfferList/TripOffer/OfferBottomLayer/TransportDescription/FlightDescription";
import BusDescription
    from "../../../../TripSearchResultPage/Content/TripOfferList/TripOffer/OfferBottomLayer/TransportDescription/BusDescription";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {useTranslation} from "react-i18next";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {getParticipantsFromHash} from "../../../../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import AdditionalValue
    from "../../../../TripSearchResultPage/Content/TripOfferList/TripOffer/OfferBottomLayer/TransportDescription/AdditionalValue";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";

type OfferDetailsProps = {
    onShowMap: () => void
    offer: any,
    isLoadingOfferDetails: boolean

}

const OfferDetails = (
    {
        onShowMap,
        offer,
        isLoadingOfferDetails
    }: OfferDetailsProps
) => {

    const type = GET_TRANSPORT_TYPE(offer?.Transport ?? {})

    const {t} = useTranslation();

    const participants = getParticipantsFromHash(offer?.Base?.OfferId)

    const {
        Out,
        Ret
    } = !isEmpty(offer?.Transport) ? offer?.Transport[type] : {Out: undefined, Ret: undefined} as any

    const getTransportContent = () => {
        switch (type) {
            case'Flight':
                return (
                    <>
                        <FlightDescription
                            showFullDetails={true}
                            flightType={'lbl_outbound_flight'}
                            flightInfo={Out}

                        />
                        <FlightDescription
                            showFullDetails={true}
                            flightType={'lbl_return_flight'}
                            flightInfo={Ret}
                        />

                    </>
                )
            case 'Bus':
                return (
                    <>
                        <BusDescription
                            typeTitle={'lbl_outbound_bus'}
                            value={Out}
                        />
                        <BusDescription
                            typeTitle={'lbl_return_bus'}
                            value={Ret}
                        />
                    </>
                )
            default:
                return <></>
        }
    }

    if (isLoadingOfferDetails) {
        return (
            <Styled.Offer__Details__Holder>
                {Array.from({length: 8}).map((_, index: number) => {
                    return (
                        <SkeletonBox
                            key={`loading_for_offer_details_${index}`}
                            minWidth={`${Math.floor(Math.random() * (95 - 70 + 1)) + 70}%`}
                            margin={'2px 0'}
                            minHeight={'13px'}
                        />
                    )
                })}
            </Styled.Offer__Details__Holder>
        )
    }

    if (!isLoadingOfferDetails) {
        return (<Styled.Offer__Details__Holder>
                {/*OFFER*/}
                <Styled.Part__Title__Label>
                    {t('lbl_offer_information_title')}
                </Styled.Part__Title__Label>
                <Styled.Section__Data__Holder style={{gap: '4px'}}>
                    <Text>
                        {t('lbl_participants')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${participants?.adultsCount} ${t('lbl_adults')} ${participants.childDates?.length !== 0 ? `${participants.childDates?.length} ${t('lbl_child')}` : ''}`}
                        </span>
                    </Text>
                    <Text>
                        {t('lbl_operator')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${offer?.Base?.OperatorDesc}`}
                        </span>
                    </Text>
                    <Text>
                        {t('lbl_dates')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${offer?.Base?.StartDate?.split('-').reverse().join('.')} - ${offer?.Base?.ReturnDate?.split('-').reverse().join('.')}`}
                        </span>
                    </Text>
                    <Text>
                        {t('lbl_duration')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${offer?.Base?.Duration} ${t('lbl_days')}`}
                        </span>
                    </Text>
                </Styled.Section__Data__Holder>
                {/*TRANSPORT*/}
                {type !== 'Own' && (
                    <>
                        <Styled.Part__Title__Label>
                            {t('lbl_transport_information_title')}
                        </Styled.Part__Title__Label>
                        <Styled.Section__Data__Holder>
                            <FlexBox>
                                {getTransportContent()}
                            </FlexBox>
                            <AdditionalValue
                                offer={offer}
                                offerType={type}
                            />
                        </Styled.Section__Data__Holder>
                    </>
                )}
                {/*ACCOMMODATION*/}
                <Styled.Part__Title__Label>
                    {t('lbl_Accommodation_information_title')}
                </Styled.Part__Title__Label>
                <Styled.Section__Data__Holder style={{gap: '4px'}}
                >
                    <Text>
                        {t('lbl_object_name')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${offer?.Accommodation?.XCode?.Name}`}
                        </span>
                    </Text>
                    <Text>
                        {t('lbl_object_type')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${offer?.Accommodation?.Type?.Name}`}
                        </span>
                    </Text>
                    <Text>
                        {t('lbl_rating')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${offer?.Accommodation?.Category} ${t('lbl_stars')}`}
                        </span>
                    </Text>
                    <Text>
                        {t('lbl_board')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${offer?.Accommodation?.XService?.Name}`}
                        </span>
                    </Text>
                    <Text>
                        {t('lbl_room_type')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${offer?.Accommodation?.Room?.Name}`}
                        </span>
                    </Text>
                    <Text>
                        {t('lbl_location')}
                        {':'}
                        <span
                            onClick={onShowMap}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                        >
                    {` ${offer?.Accommodation?.XCode?.Name} / ${offer?.Accommodation?.XCity?.Name} / ${offer?.Accommodation?.XCountry?.Name}`}
                        </span>
                    </Text>
                    <Text>
                        {t('lbl_room_access')}
                        {':'}
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                    {` ${offer?.Accommodation?.Nights} ${t('lbl_nights')}`}
                        </span>
                    </Text>

                </Styled.Section__Data__Holder>

            </Styled.Offer__Details__Holder>
        )
    }

    return null
}

export default OfferDetails