import {Search_Proto} from "./Context"
import useBookBrdComponentStates from "../UseHooksBookBrd/useBookBrdComponentStates";

type HashValueProviderProps = {
    children: React.ReactNode
}

const HashValueProvider = (
    {
        children
    }: HashValueProviderProps
) => {

    const {
        protoArray
    } = useBookBrdComponentStates()

    return (
        <Search_Proto.Provider value={protoArray as any}>
            {children}
        </Search_Proto.Provider>
    )
}
export default HashValueProvider