import {Styled} from "./BotControls.styles"
import {faPaperPlaneTop} from "@fortawesome/pro-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useTylerBot from "../Handlers/Hook";
import MoreOptions from "./MoreOptions";
import {isMobileOnly} from "react-device-detect";
import TextArea from "../../../lib/UIBookBrd/TextArea";


const BotControls = () => {

    const {t} = useTranslation()

    const {
        state,
        textFieldChangeInput,
        submitMessage,
    } = useTylerBot()

    return (
        <Styled.Controls__Holder>
            <MoreOptions/>
            <TextArea
                isAutoFocus={!isMobileOnly}
                resizeToContent={true}
                disableEnter={true}
                maxLineCount={3}
                onChange={textFieldChangeInput}
                placeholder={t('lbl_tyler_text_input')}
                value={state?.textInputValue}
                style={{
                    padding: '0 6px',
                    paddingTop: "8px",
                    paddingBottom: '6px',
                    borderRadius: '15px'
                }}
            />

            <Styled.Send__Message__Icon
                isPending={!!state?.isPending}
                onClick={submitMessage}
                icon={faPaperPlaneTop}
            />
        </Styled.Controls__Holder>
    )
}


export default BotControls