import {useState} from "react";
import {Payment_Status} from "../../../lib/Context/Context";
import {useParams} from "react-router-dom";
import {OrderStatusEnum} from "../../../lib/Types";
import {ViewTypes} from "../consts";

type ContextProvider = {
    children: React.ReactElement
}

const offer = {
    "Base": {
        "XCity": {
            "Id": "745",
            "Name": "Hurghada"
        },
        "XCode": {
            "Id": 26,
            "Name": "Aladdin Beach Resort"
        },
        "XCountry": {
            "Id": "11",
            "Name": "Egipt"
        },
        "XRegion": {
            "Id": "64",
            "Name": "Hurghada"
        },
        "Availability": {
            "base": "available",
            "extended": [
                "confirmed"
            ],
            "message": ""
        },
        "Catalog": "LAST",
        "DatasetInfo": {
            "name": "",
            "private": false
        },
        "DepartureLocation": {
            "Id": "0x4b61746f77696365",
            "Name": "Katowice"
        },
        "Duration": 4,
        "NightsBeforeReturn": 3,
        "OfferId": "3dd6dfb11d9162ab4992b830ffaa8a867588bca659b6278030034881101a956f|OREX|Mnx8",
        "Operator": "OREX",
        "OperatorDesc": "Anex Poland",
        "OriginalPrice": {
            "FirstPerson": {
                "amount": "1192.00",
                "currency": "PLN",
                "details": {
                    "TFGIncluded": true
                }
            },
            "Total": {
                "amount": "2385.00",
                "currency": "PLN",
                "details": {
                    "TFGIncluded": true
                }
            }
        },
        "Price": {
            "FirstPerson": {
                "amount": "1192.00",
                "currency": "PLN",
                "details": {
                    "TFGIncluded": true
                }
            },
            "Total": {
                "amount": "2385.00",
                "currency": "PLN",
                "details": {
                    "TFGIncluded": true
                }
            }
        },
        "Promotion": "LAST",
        "Refundable": "unspecified",
        "Resident": "noinformation",
        "ReturnDate": "2023-10-29",
        "StartDate": "2023-10-26",
        "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/OREX/IMG/8/main.webp",
        "Transfer": "yes",
        "UniqueObjectId": "3dd6dfb11d9162ab4992b830ffaa8a867588bca659b6278030034881101a956f"
    },
    "Accommodation": {
        "XCity": {
            "Id": "745",
            "Name": "Hurghada"
        },
        "Attributes": [
            "location_seaside_resorts",
            "location_directly_on_the_beach",
            "location_near_airport",
            "facility_free_wifi",
            "facility_wellness",
            "facility_home_appliances",
            "facility_for_families_with_childrens",
            "facility_fitness_or_gym",
            "facility_outdoor_pool",
            "facility_internet_access",
            "facility_air_conditioning",
            "facility_night_life",
            "activity_animation_for_adults",
            "activity_sport",
            "activity_tennis",
            "activity_water_sports",
            "activity_diving"
        ],
        "Category": 4,
        "Code": "8",
        "ExtTripAdvisor": {
            "count": 1745,
            "description": "https://www.tripadvisor.com/Hotel_Review-g297549-d316672-Reviews-Aladdin_Beach_Resort-Hurghada_Red_Sea_and_Sinai.html?m=62467",
            "image": "https://staticpict.merlinx.pl/tripadvisor/4.0-62467-5.svg",
            "rating": 4,
            "review": "https://www.tripadvisor.com/UserReview-g297549-d316672-Aladdin_Beach_Resort-Hurghada_Red_Sea_and_Sinai.html?m=62467"
        },
        "Location": {
            "Coords": [
                27.106647,
                33.831921
            ],
            "geoSearchDistance": 0
        },
        "Name": "Aladdin Beach Resort Hurghada",
        "Nights": 3,
        "Room": {
            "Id": "E.2",
            "Name": "Standard Room"
        },
        "Service": {
            "Id": "A",
            "Name": "All Inclusive"
        },
        "Type": {
            "Id": "H",
            "Name": "Hotel"
        },
        "XService": {
            "Id": 1,
            "Name": "Wszystko w cenie"
        }
    },
    "Transport": {
        "Flight": {
            "AirlineType": "gds",
            "Luggage": "yes",
            "Out": [
                {
                    "Airline": {
                        "Code": "QU",
                        "Name": "SKYLINE EXPRESS AIRLINES"
                    },
                    "Departure": {
                        "Code": "KTW",
                        "Date": "2023-10-26",
                        "Name": "Katowice",
                        "Time": "01:05"
                    },
                    "Destination": {
                        "Code": "HRG",
                        "Date": "2023-10-26",
                        "Name": "Hurghada",
                        "Time": "06:20"
                    },
                    "FlightNo": "QU 9121"
                }
            ],
            "Ret": [
                {
                    "Airline": {
                        "Code": "QU",
                        "Name": "SKYLINE EXPRESS AIRLINES"
                    },
                    "Departure": {
                        "Code": "HRG",
                        "Date": "2023-10-29",
                        "Name": "Hurghada",
                        "Time": "15:15"
                    },
                    "Destination": {
                        "Code": "KTW",
                        "Date": "2023-10-29",
                        "Name": "Katowice",
                        "Time": "18:45"
                    },
                    "FlightNo": "QU 9126"
                }
            ],
            "Stops": "no"
        }
    },
    "Online": {
        "actions": [
            {
                "action": "checkstatus",
                "fieldList": [
                    "Base.Availability",
                    "Base.Price",
                    "Accommodation.AvailableRoomsCount",
                    "Transport.*"
                ],
                "timestamp": "2023-10-24T14:55:11+02:00"
            }
        ]
    }
}

const offerPictures: any[] = [
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/48e5aaadc616d41479aa60a75588fc8f6d2a136932cd59cb2697e45d683acc71.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/e58d9182debddcd7010cc8af07dbfbd4b7118447c7bbae1bbc5ed092f5b7b76b.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/1da0704cec56532d4902061c1ad7ab8bb6ef778d2d2a1b4ad1cd36599298c814.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/3532070ccbd98d5253f4a4542e71b6aa8940fcc8eb9b022a84d56e2c5758354f.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/bf41570bb3de457c59e40889b36f994e73dac31be29b84e03ff0636c624a3fc0.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/66db264184d3d793fcb4f1d278e794bf0a1a2e69cca33da1ad42595f42cf7ee4.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/44be60f108c485049341c2e4d6be6b6573cc3739e4b3b0f680403406ec30acce.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/e9b7a527a89dd55258840dc9b90d2a6b563adc583ea41c564464fa8e3d715402.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/69ccb00c96ede4cc307d5a2774329763e147b7a008e9a3e37b6532032e5a253c.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/a4033ec9f874518fd183a49beeef7fc55a0a8b907883fecf9d80c02e8e3a636d.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/2618103edad9d750b2f2bb5aeebb52674b9f93ca8cb8f003aecff37b7ee3d1e6.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/79e724f1dc9063209c608661c31c06ddf2d907429802b30209d4125ff0be4f7e.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/f81e5dc01d7a262460ef6fc8803c49c7351adcb3b706375073cfe1485f2c94db.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/a9c5d520601d90d61b1f5d0bab5cd74b64182916cf582def9d70afe1de6e6e48.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/c914cda176a6f71b61f938ef7f3b19fcd17ce442f9e4ca92a16f9c1064f8cd64.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/7a3951b3b15f5b48aeb25b8927c3ec9c1f7b22b886b31384232f1e6a8d0a8496.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/70e17ffc515dfd2a8c7c57aae0e680da9d3be7d4e2fe0150a35cd66a018cc29f.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/b533bfcd582b92d5ad80980264c19cbb6ab1d348bd9bc618169e4631cbea3a37.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/94ad27b7da09dd5aaa010266496457b3c41a88f6818e6c47ea7c05b5b4ce5d4d.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/41ccbd843e192ab5af5edeb07f16e41db9e7e5ff5c02e703094d46d6d7ea95fe.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/438881cd90d22ecd36a8acf85dfdfbe6226dc0bdc4a1d2b10bab8fd389beaefb.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/08ceac77dafa3da616582afb3e9d0002bd351c7877c3a892315af3d5833ab4ab.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/0776860091f08ac892df3b0a994fb423a9c1f2bd1e7bff8f83c0e90452e46c39.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/3d2379023c995035aa91c293f2782a3d8ce1f0b767d0b115b130a180d713a8f5.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/0959aaf3c274137544cd096df0110942f899aa70cc0717b3cd518ddd42a0aa3e.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/887e0b80795b60212af34df462b78010c6004a97047968d910eec4eb2d9fc106.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/75827091f62c644289db8ed985c9a994b04ac651909e44a5f1c5c70263195237.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/492406c01437ddb310f71d13ecacc830aa1f4d61f71e282490814be4d099e561.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/3e22f6a332fc619ef1e5a1b68c95d843af9d788500a3e0073d7bfc86558acd5b.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/c95cbbe9e967c84ff3bb0a9285674508974a556164dc3bc87348354688d970db.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/082ab6b4341aaa7b740788aa62a464a80e44138cb337c0f8ab4e5bea14eb668a.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/f9fb32dc0ea17cd9a9a3d55319fb293ae01a3c342677353337b00c532fbe8af8.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/df3bda8b8214cee10a99751f53e0e4a916fb5771cb9837c11580400472108f93.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/6d2f5ca884519fac83706b37396c7869b79d465011362c9fdee1ea9de1166f2e.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/582b45f20f59d1f3e7f9953dbddd36bb943f0f43049fa8fddc259ef1117350bd.webp",
    "https://datacfstatic.merlinx.pl/OREX/IMG/8/6d2f5ca884519fac83706b37396c7869b79d465011362c9fdee1ea9de1166f2e.webp"
]

const participants: any = [
    {type: 'adult', name: 'Maksymilian', surname: 'B'},
    {type: 'adult', name: 'Andrew', surname: 'T'},
]

const plan: any = {
    quickNote: `<span style="font-weight: bold; font-size: 18px">Travel Requirements for England to Egypt</span>
<ul>
    <li><strong>Passport and Visa:</strong> Ensure your passport is valid and check visa requirements.</li>
    <li><strong>Health and Vaccinations:</strong> Consider required vaccinations and health precautions.</li>
    <li><strong>Currency:</strong> Exchange for Egyptian Pounds (EGP) and have cash on hand.</li>
    <li><strong>Language:</strong> Learn basic Arabic phrases or use English in tourist areas.</li>
    <li><strong>Clothing and Weather:</strong> Pack light, breathable clothing and sun protection.</li>
    <li><strong>Electrical Adapters:</strong> Bring the right plug adapters for your devices.</li>
    <li><strong>Safety and Local Customs:</strong> Be mindful of safety and respect local customs.</li>
    <li><strong>Transportation:</strong> Familiarize yourself with local transportation options.</li>
    <li><strong>Sightseeing:</strong> Plan your itinerary to explore Egypt's historical treasures.</li>
    <li><strong>Emergency Contacts:</strong> Keep essential contact numbers handy.</li>
</ul>
<p>Enjoy your trip and stay safe!</p>`,
    links: `<ul>
    <li><strong><a target="_blank" href="https://www.egyptembassy.net/">Egyptian Embassy Information</a></strong>: Find official information from the Egyptian Embassy in your home country.</li>
    <li><strong><a target="_blank" href="https://www.egypt.travel/">Egypt Travel Official Website</a></strong>: Explore Egypt's official tourism website for travel tips and updates.</li>
</ul>`,
    plan: `<ul>
<li>
26.09.2023 - Please make sure that you have all documents 
</li>
<li>
19.10.2023 - Double check if you need any necessary things you still have time
</li>
<li>
23.10.2023 - Start packing your suitcase and make sure you are not missing something
</li>
<li>
25.10.2023 - Get ready tomorrow is big day
</li>
<li>
26.10.2023 -> 29.10.2023 - Have fun!
</li>


           </ul>`
}

const refund = {
    isRefundAble: true,
}

const ContextProvider = (
    {
        children
    }: ContextProvider
) => {
    const {orderNumber} = useParams()

    const [state, setState] = useState<any>({
        orderNumber,
        orderStatus: OrderStatusEnum.approved,
        offer,
        plan,
        participants,
        offerPictures,
        refund,
        viewType: ViewTypes.Status
    })

    return (
        <Payment_Status.Provider
            value={[state, setState]}
        >
            {children}
        </Payment_Status.Provider>

    )
}
export default ContextProvider