// @ts-ignore
import styled from "styled-components";


const FlexBox = styled.div`
  width: 100%;
  height: auto;
`;

export const Styled = {
    FlexBox
}