import FlightDescription from "./FlightDescription";
import BusDescription from "./BusDescription";
import {GET_TRANSPORT_TYPE} from "../../../../../TripSearchFunctions";
import {isEmpty} from "lodash-es";
import {useTranslation} from "react-i18next";
import {Styled} from "../../TripOffer.styles";

type TransportDescription = {
    transport: any
}

const TransportDescription = (
    {
        transport
    }: TransportDescription
) => {

    const {t} = useTranslation();

    const type = GET_TRANSPORT_TYPE(transport)

    const {
        Out,
        Ret
    } = !isEmpty(transport) ? transport[type] : {Out: undefined, Ret: undefined} as any

    const value = () => {
        switch (type) {
            case'Flight':
                return (
                    <>
                        <FlightDescription
                            flightType={'lbl_outbound_flight'}
                            flightInfo={Out}

                        />
                        <FlightDescription
                            flightType={'lbl_return_flight'}
                            flightInfo={Ret}
                        />

                    </>
                )
            case 'Bus':
                return (
                    <>
                        <BusDescription
                            typeTitle={'lbl_outbound_bus'}
                            value={Out}
                        />
                        <BusDescription
                            typeTitle={'lbl_return_bus'}
                            value={Ret}
                        />
                    </>
                )
            default:
                return <></>
        }
    }

    return (
        <Styled.FlightDetailsHolder>
            {value()}
        </Styled.FlightDetailsHolder>
    )
}
export default TransportDescription