import {Styled} from "./BasicPickerForCountry.styles"
import React, {useRef, useState} from "react";
import {isMobileOnly} from "react-device-detect";
import Modal from "../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";
import usePopperPositioning from "../../lib/UseHooksBookBrd/usePopperPositioning";
import {useTranslation} from "react-i18next";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Button from "../../lib/UIBookBrd/Button";
import Text from "../../lib/UIBookBrd/Text";
import OptionList from "./OptionList/OptionList";
import BrdLoader from "../../lib/UIBookBrd/BrdLoader";
import Header from "./Header";
import Drawer from "../../lib/UIBookBrd/Drawer";
import Field from "../../lib/UIBookBrd/SelectBookBrd/Field/Field";
import AutoSuggestOptionList from "./AutoSuggestOptionList";
import {isEmpty} from "lodash-es";

type BasicPickerProps = {
    appendTo?: Element | null
    isOpen: boolean
    setIsOpen: any
    autoSuggestCheckedOptions?: any
    setDisplayedContinent?: (name: string | null) => void
    ContientValue?: any
    flatCheckedList?: any[]
    autoSuggestOptions?: object
    isLoadingAutoSuggest?: boolean
    options: any[]
    onClear: () => void
    autoSuggestEnabled?: boolean
    showAutoSuggestOptions?: boolean,
    onSearchInputClearHandler?: any
    onSearchInputChangeHandler?: any
    checkboxChangeHandler: (label: string | undefined, value: string | undefined, type: string) => void
    openChangeHandler?: (value: string | undefined) => void
    onApplyChangeHandler: any
    appliedList: any[]
    openOptionList?: any[]
    searchValue?: string
    maxNumberOfColumns?: number
    minColumnWidth?: number
    title?: string
    placeholder?: string
    subOptionsEnabled?: boolean
    width?: number
}
const defaultProps = {
    appendTo: undefined,
    setDisplayedContinent: undefined,
    continetsList: {},
    flatCheckedList: [],
    autoSuggestOptions: {},
    isLoadingAutoSuggest: false,
    maxNumberOfColumns: 4,
    openOptionList: [null],
    openChangeHandler: undefined,
    searchValue: '',
    autoSuggestEnabled: false,
    showAutoSuggestOptions: false,
    onSearchInputChangeHandler: undefined,
    onSearchInputClearHandler: undefined,
    minColumnWidth: 200,
    placeholder: 'insert placeholder',
    title: undefined,
    subOptionsEnabled: false,
    width: 500
}


const BasicPicker = (
    {
        isOpen,
        setIsOpen,
        autoSuggestCheckedOptions,
        setDisplayedContinent,
        ContientValue,
        flatCheckedList,
        autoSuggestOptions,
        isLoadingAutoSuggest,
        placeholder,
        maxNumberOfColumns,
        minColumnWidth,
        appendTo,
        checkboxChangeHandler,
        openChangeHandler,
        autoSuggestEnabled,
        showAutoSuggestOptions,
        onApplyChangeHandler,
        options,
        appliedList,
        openOptionList,
        onSearchInputChangeHandler,
        onSearchInputClearHandler,
        onClear,
        searchValue,
        title,
        subOptionsEnabled,
        width

    }: BasicPickerProps) => {

    const {t} = useTranslation();

    const [focusField, setFocusField] = useState<boolean>(false)

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);

    const [isSelectOpen, setIsSelectOpen] = useState(false)

    const inputElement = useRef<HTMLDivElement>();

    useOnClickOutside(modalElement, () => !isSelectOpen && onApply());

    const {styles, attributes} = usePopperPositioning({
        targetElementRef: inputElement,
        popperElementRef: modalElement,
        applyMaxSizeEnabled: true,
        zIndex: 9999,
        padding: 15,
    });

    // Modal and Field controls
    const onClose = () => {
        setIsOpen(false)
        setFocusField(false)
        inputElement.current?.focus()
        onApplyChangeHandler()
    }

    const onApply = () => {
        onClose()
    }

    const onFieldFocus = () => {
        setFocusField(true)
    }

    const onFieldBlur = () => {
        setFocusField(false)
    }

    const onFieldClick = (event: any) => {
        if (isOpen) {
            onClose()
        } else {
            setFocusField(true)
            setIsOpen(true)
        }

    }


    const content = (
        <Styled.Content
            ref={isMobileOnly ? undefined : setContentRef}
        >
            <Header
                openDropDown={isOpen}
                autoSuggestEnabled={autoSuggestEnabled as boolean}
                searchValue={searchValue}
                onSearchInputChangeHandler={onSearchInputChangeHandler}
                onSearchInputClearHandler={onSearchInputClearHandler}
                setIsSelectOpen={setIsSelectOpen}
            />

            <Styled.OptionsHolder>
                <>
                    {isLoadingAutoSuggest ? (
                        <Styled.LoadingMode>
                            <BrdLoader
                                label={'lbl_please_wait_loading_content'}
                                style={{
                                    margin: 'auto'
                                }}

                            />
                        </Styled.LoadingMode>
                    ) : (
                        <>
                            {showAutoSuggestOptions ? (
                                <AutoSuggestOptionList
                                    autoSuggestCheckedOptions={autoSuggestCheckedOptions}
                                    flatCheckedList={flatCheckedList}
                                    checkboxChangeHandler={checkboxChangeHandler}
                                    autoSuggestOptions={autoSuggestOptions}
                                />
                            ) : (
                                <>
                                    {!isEmpty(ContientValue) && !isEmpty(ContientValue?.list) && (
                                        <>
                                            {Object.keys(ContientValue?.list).map((contient: any) => {

                                                const value = ContientValue?.list[contient]?.list
                                                return (
                                                    <FlexBox key={`basic_picker_continents_${contient}`}
                                                             style={{flexDirection: 'column'}}>
                                                        <Styled.Contient__Section__Label>
                                                            {t(`lbl_${contient}`)}
                                                        </Styled.Contient__Section__Label>
                                                        {value?.length > 0 ?
                                                            (
                                                                <OptionList
                                                                    options={value}
                                                                    flatCheckedList={flatCheckedList}
                                                                    openOptionList={openOptionList as any}
                                                                    checkboxChangeHandler={checkboxChangeHandler}
                                                                    openChangeHandler={openChangeHandler as any}
                                                                    subOptionsEnabled={subOptionsEnabled as boolean}
                                                                    maxNumberOfColumns={maxNumberOfColumns as number}
                                                                    minColumnWidth={minColumnWidth as number}
                                                                    contentRef={contentRef}


                                                                />

                                                            )
                                                            :
                                                            (
                                                                <Styled.NoOptionsLabelHolder>
                                                                    <Text>
                                                                        {t('no_options')}
                                                                    </Text>
                                                                </Styled.NoOptionsLabelHolder>
                                                            )
                                                        }


                                                    </FlexBox>
                                                )
                                            })}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            </Styled.OptionsHolder>
        </Styled.Content>
    )

    const controls = (
        <FlexBox
            style={{
                flexGrow: isMobileOnly ? '1' : undefined,
                flexDirection: isMobileOnly ? 'column' : undefined,
                marginLeft: isMobileOnly ? "4px" : 'auto',
                marginRight: isMobileOnly ? "4px" : '10px',
                marginTop: '2px',
                marginBottom: '2px'
            }}
        >
            <Button
                label={t('lbl_clear')}
                onClick={onClear}
                style={{
                    flexGrow: "1",
                    minWidth: '100px',
                    margin: isMobileOnly ? '2px 0' : " 2px 2px ",
                    padding: "2px 0",
                    maxWidth: isMobileOnly ? undefined : "150px"

                }}
            />
            <Button
                label={t('lbl_apply')}
                onClick={onApply}
                style={{
                    flexGrow: "1",
                    minWidth: '100px',
                    margin: isMobileOnly ? '2px 0' : " 2px 2px ",
                    padding: "2px 0",
                    maxWidth: isMobileOnly ? undefined : "150px"

                }}
            />


        </FlexBox>
    )

    return (
        <>
            <Field
                title={title}
                placeholder={placeholder}
                ref={inputElement as any}
                selectedOptions={appliedList}
                onBlur={onFieldBlur}
                onFocus={onFieldFocus}
                onClick={onFieldClick}
                onClear={onClear}
                isDropDownOpen={focusField}
            />
            {isMobileOnly ? (
                    <>
                        <Drawer
                            appendTo={appendTo}
                            ref={setModalElement}
                            title={title}
                            position={'bottom'}
                            isOpen={isOpen}
                            onClose={onClose}
                            controls={controls}
                            contentStyles={{
                                height: '100vh'
                            }}
                        >
                            <FlexBox
                                ref={isMobileOnly ? setContentRef : undefined}
                                style={{
                                    maxWidth: `${modalElement?.clientWidth as number}px`,
                                    height: '100%',
                                    flexDirection: 'column',
                                }}
                            >
                                {content}
                            </FlexBox>
                        </Drawer>
                    </>
                ) :
                (
                    <>
                        <Modal
                            appendTo={appendTo}
                            ref={setModalElement}
                            onClose={onClose}
                            width={width}
                            showOverlay={false}
                            isOpen={isOpen}
                            positionedByPopper
                            style={styles.popper}
                            {...attributes.popper}
                            controls={controls}
                        >
                            <>
                                {content}
                            </>
                        </Modal>
                    </>
                )}


        </>
    )

}
BasicPicker.defaultProps = defaultProps;
export default BasicPicker