import {Styled} from "../../../../TripSearchBookingPage";
import TextInput from "../../../../../../../lib/UIBookBrd/TextInput";
import {participantsType} from "../../../../types";
import {includes} from "lodash-es";
import useBookingFunctions from "../../../../functions";
import {useTranslation} from "react-i18next";
import {payerInputs} from "../../../../Consts";

const CardNumberInput = () => {

    const {
        state,
        onEnterPressed,
        getCardNumberValueInput,
        getAutoComplete,
        getInputType,
        getPlaceHolder,
        onInputChangeHandler,
    } = useBookingFunctions()


    const {t} = useTranslation();

    const sectionId = `${participantsType.payment}_0`

    const inputIndex = payerInputs.length - 1 + 1


    return (
        <Styled.Input__Holder>
            <Styled.Input__Label>
                {t(`lbl_cardNumber`)}
            </Styled.Input__Label>
            <TextInput
                id={`${participantsType.payment}_${inputIndex}`}
                onEnterPressed={() => {
                    onEnterPressed(`${participantsType.payment}_${inputIndex}`)
                }}
                autoComplete={getAutoComplete('cardNumber')}
                textFieldType={getInputType('cardNumber') as any}
                placeholder={getPlaceHolder('cardNumber')}
                showIcon={false}
                onChange={(event: any) => {
                    onInputChangeHandler(event?.target?.value, `${sectionId}`, 'cardNumber')
                }}
                error={includes(state?.inValidFields ?? [], `${sectionId}_cardNumber`)}
                isClearAble={false}
                value={{
                    label: state?.participantsSection[sectionId] ? state?.participantsSection[sectionId]['cardNumber'] ? getCardNumberValueInput(state?.participantsSection[sectionId]['cardNumber']?.split(' ').join('')) : '' : '',
                    value: state?.participantsSection[sectionId] ? state?.participantsSection[sectionId]['cardNumber'] : ''
                }}
            />
        </Styled.Input__Holder>
    )
}
export default CardNumberInput