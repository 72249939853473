import React, {useState} from "react";
import {Styled} from "./HeaderForManageAccount.styles";
import {useTranslation} from "react-i18next";
import {faEllipsis, faUserSecret} from "@fortawesome/pro-solid-svg-icons";
import FullScreenProfilePhoto from "./FullScreenProfilePhoto";
import {accountPageType} from "../../../lib/Types";
import useAccountData from "../Hook/useUserData";
import SkeletonBox from "../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";
import UserSettings from "./UserSettings/UserSettings";
import ProfilePhoto from "./Components/ProfilePhoto";

type HeaderForManageAccountProps = {
    type: accountPageType
    specialState?: any
}


const HeaderForManageAccount = (
    {
        type,
        specialState
    }: HeaderForManageAccountProps
) => {

    const {
        userName,
        userImage,
        isLogged,
        deleteBackground,
        userBackgroundImage,
        isLoading,
        userBackgroundColor,
        defaultUserSettings
    } = useAccountData(specialState)


    const [userSettingsData, setUserSettingData] = useState<any>(defaultUserSettings)

    const [isShowProfilePhoto, setIsShowProfilePhoto] = useState<boolean>(false)

    const [isUserSettingsOpen, setIsUserSettingsOpen] = useState<boolean>(false)

    const {t} = useTranslation();

    const onCloseFullScreenPhoto = () => {
        setIsShowProfilePhoto(false)
    }

    const onSettingsClose = () => {
        setIsUserSettingsOpen(false)
    }

    const onProfilePhotoCameraClick = (event: any) => {
        event.stopPropagation()
        setUserSettingData(defaultUserSettings)
        setIsUserSettingsOpen(true)
    }

    const onProfilePhotoClick = (event: any) => {
        event.stopPropagation()
        setIsShowProfilePhoto(true)
    }

    return (
        <Styled.Main__Account__Header__Holder>
            <Styled.Main__Account_Information_Holder>
                <Styled.Main__Account__Background__Image
                    forceLoader={isLoading}
                    src={!isLoading ? userBackgroundImage : ''}
                />
                <Styled.Main__Account__User__Main__Data>
                    <Styled.Main__Account__Image__Holder>
                        {isLogged && type === accountPageType.own && (
                            <Styled.Main__Account__Change__Profile__Photo
                                onClick={onProfilePhotoCameraClick}
                                icon={faEllipsis}
                            />
                        )}
                        {isLoading ? (
                            <SkeletonBox
                                style={{
                                    maxWidth: 'unset',
                                    margin: '0',
                                    maxHeight: 'unset',
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        ) : !isLogged ? (
                            <Styled.Main__Account__Not__Logged__User>
                                <Styled.Main__Account__Not__Logged__Icon
                                    icon={faUserSecret}
                                />
                            </Styled.Main__Account__Not__Logged__User>
                        ) : (
                            <>
                                <ProfilePhoto
                                    image={userImage}
                                    isDeleteBackground={deleteBackground}
                                    backgroundColor={userBackgroundColor}
                                    name={userName}
                                    onProfileClick={onProfilePhotoClick}
                                />
                            </>
                        )}
                    </Styled.Main__Account__Image__Holder>
                    <Styled.Main__Account__User__Name>
                        {isLoading ? (
                            <SkeletonBox
                                style={{
                                    width: '80px',
                                    height: '18px'
                                }}
                            />
                        ) : (
                            <>
                                {isLogged ? userName : t('NoAccount')}
                            </>
                        )}
                    </Styled.Main__Account__User__Name>
                </Styled.Main__Account__User__Main__Data>
            </Styled.Main__Account_Information_Holder>
            <Styled.Main__Account__User__Name__Small__Width>
                {isLoading ? (
                    <SkeletonBox
                        style={{
                            width: '80px',
                            height: '18px'
                        }}
                    />
                ) : (
                    <>
                        {isLogged ? userName : t('NoAccount')}
                    </>
                )}
            </Styled.Main__Account__User__Name__Small__Width>
            <UserSettings
                state={userSettingsData}
                setState={setUserSettingData}
                onClose={onSettingsClose}
                isOpen={isUserSettingsOpen}
            />
            <FullScreenProfilePhoto
                userBackgroundColor={userBackgroundColor}
                userImage={userImage}
                deleteBackground={deleteBackground}
                isOpen={isShowProfilePhoto}
                onClose={onCloseFullScreenPhoto}
            />
        </Styled.Main__Account__Header__Holder>
    )
}

export default HeaderForManageAccount