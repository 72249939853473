import {Styled} from "../../ManageContent.styles"
import {useTranslation} from "react-i18next";
import useConfirmationPage from "../../../useConfirmationPage";

const Planner = () => {

    const {t} = useTranslation()

    const {plan} = useConfirmationPage()

    return (
        <Styled.Planner__Holder>
            <span dangerouslySetInnerHTML={{__html: plan?.quickNote}}/>
            <Styled.Plan__Section__Title>
                {t('lbl_useful_links')}
            </Styled.Plan__Section__Title>
            <span dangerouslySetInnerHTML={{__html: plan?.links}}/>
            <Styled.Plan__Section__Title>
                {t('lbl_basic_pre_trip_plan')}
            </Styled.Plan__Section__Title>
            <span dangerouslySetInnerHTML={{__html: plan?.plan}}/>
        </Styled.Planner__Holder>
    )
}

export default Planner