import useHashForm from "./useHashForm";
import {Local_Data_Default_Values, Proto_Hash_Default_Values} from "../ProtoHashConfig";
import {useState} from "react";

const useBookBrdComponentStates = () => {

    const [formikContext, setFormikContext] = useHashForm(
        Proto_Hash_Default_Values,
        'search',
    )

    const [localData, setLocalData] = useState<any>(Local_Data_Default_Values)

    const [notificationsList, setNotificationsList] = useState<any[]>([])

    return (
        {
            notificationsArray: [notificationsList, setNotificationsList],
            SearchFormValues: formikContext?.SearchFormValues,
            protoArray: [formikContext as any, setFormikContext] as any,
            localDataArray: [localData, setLocalData],
        }
    )
}
export default useBookBrdComponentStates