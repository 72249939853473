import {Styled} from "../../../../TripSearchBookingPage";
import TextInput from "../../../../../../../lib/UIBookBrd/TextInput";
import {participantsType} from "../../../../types";
import {includes} from "lodash-es";
import FlexBox from "../../../../../../../lib/UIBookBrd/FlexBox";
import useBookingFunctions from "../../../../functions";
import {useTranslation} from "react-i18next";
import {payerInputs} from "../../../../Consts";

const CardDateAndCVCInput = () => {

    const {
        state,
        onEnterPressed,
        getCardDateValue,
        getAutoComplete,
        getInputType,
        getPlaceHolder,
        onInputChangeHandler,
    } = useBookingFunctions()

    const sectionId = `${participantsType.payment}_0`
    const dateInputIndex = payerInputs.length - 1 + 2
    const cvcInputIndex = payerInputs.length - 1 + 3

    const {t} = useTranslation();

    return (
        <FlexBox
            style={{
                flexWrap: 'nowrap',
            }}

        >
            <Styled.Input__Holder
                style={{
                    flexGrow: '1'
                }}
            >
                <Styled.Input__Label>
                    {t(`lbl_cardDate`)}
                </Styled.Input__Label>
                <TextInput
                    id={`${participantsType.payment}_${dateInputIndex}`}
                    onEnterPressed={() => {
                        onEnterPressed(`${participantsType.payment}_${dateInputIndex}`)
                    }}
                    autoComplete={getAutoComplete('cardDate')}
                    textFieldType={getInputType('cardDate') as any}
                    placeholder={getPlaceHolder('cardDate')}
                    showIcon={false}
                    onChange={(event: any) => {
                        onInputChangeHandler(event?.target?.value, sectionId, 'cardDate')
                    }}
                    error={includes(state?.inValidFields ?? [], `${sectionId}_cardDate`)}
                    isClearAble={false}
                    value={{
                        label: state?.participantsSection[sectionId] ? getCardDateValue(state?.participantsSection[sectionId]['cardDate']) : '',
                        value: state?.participantsSection[sectionId] ? state?.participantsSection[sectionId]['cardDate'] : ''
                    }}
                    style={{
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0'
                    }}
                />
            </Styled.Input__Holder>
            <Styled.Input__Holder>
                <Styled.Input__Label>
                    {t(`lbl_cvc`)}
                </Styled.Input__Label>
                <TextInput
                    id={`${participantsType.payment}_${cvcInputIndex}`}
                    onEnterPressed={() => {
                        onEnterPressed(`${participantsType.payment}_${cvcInputIndex}`)
                    }}
                    autoComplete={getAutoComplete('cvc')}
                    textFieldType={getInputType('cvc') as any}
                    placeholder={getPlaceHolder('cvc')}
                    showIcon={false}
                    onChange={(event: any) => {
                        onInputChangeHandler(event?.target?.value, sectionId, 'cvc')
                    }}
                    error={includes(state?.inValidFields, `${sectionId}_cvc`)}
                    isClearAble={false}
                    value={{
                        label: state?.participantsSection[sectionId] ? state?.participantsSection[sectionId]['cvc'] : '',
                        value: state?.participantsSection[sectionId] ? state?.participantsSection[sectionId]['cvc'] : ''
                    }}
                    style={{
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                        maxWidth: '50px'
                    }}
                />
            </Styled.Input__Holder>
        </FlexBox>
    )
}
export default CardDateAndCVCInput