import BasicPicker from "../BasicPicker";
import {useEffect, useState} from "react";
import {includes, isArray, isEmpty, uniq} from "lodash-es";
import {isMobileOnly} from "react-device-detect";
import sortArray from "../../lib/sortArray";
import {TO_FIELD, XCITY_FIELD, XCODE_FIELD} from "../../lib/DefaultFormikValue/DefaultSearchFormValues";
import {promiseRequest} from "../../lib/RequestBuilder";
import {CONTENT_SERVICE_URL} from "../../lib/Configs/RequestConfig";
import {useTranslation} from "react-i18next";
import {useDebouncedCallback} from "use-debounce";
import useSearchFormValues from "../../lib/UseHooksBookBrd/useSearchFormValues";
import useBookBrdPageFunctions from "../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import useStorage from "../../lib/UseHooksBookBrd/useStorage";

type DestinationPickerProps = {
    highLightedOption?: any[]
    appendTo?: Element | null
    values: any
    formikValue: any
    setFormikValues: any
}
const parseCheckedOptions = (value: any, options: any[]) => {
    let subOptions: any[] = []
    options?.map((item: any) => {
        if (item.value === value) {
            subOptions = item?.subOptions
        }

    })
    return subOptions
}
const deleteParentOption = (flatCheckedList: any, value: any) => {
    let list: any = uniq(flatCheckedList)
    list = list.filter((item: any) => item !== value)
    if (includes(flatCheckedList, `${value?.split('_')[0]}:`)) {
        list = list.filter((item: any) => item !== `${value?.split('_')[0]}:`)
    }
    return list
}
const getParentId = (value: any, options: any) => {
    const parent = options.filter((item: any) => item.value === value)
    return parent[0].value
}
const setCheckedFromFormik = (formikValue: string[], options: any[]) => {
    const newData: any[] = []
    formikValue.map((item) => {
        if (item?.split('_').length as any === 1) {
            const subOptions = options?.filter((option: any) => option.value === item)[0]?.subOptions
            subOptions?.map((subOption: any) => newData.push(subOption.value))
            newData.push(item)
        } else {
            newData.push(item)
        }
    })
    return newData
}

const getAllCheckedOptions = (formikValue: any, searchFormValue: any) => {
    const allCheckedValues: any[] = []
    if (!isEmpty(searchFormValue[XCODE_FIELD])) {
        searchFormValue[XCODE_FIELD]?.map((xCode: any) => allCheckedValues?.push(`${xCode}`))
    }
    if (!isEmpty(searchFormValue[XCITY_FIELD])) {
        allCheckedValues?.push(searchFormValue[XCITY_FIELD])
    }
    if (!isEmpty(formikValue)) {
        formikValue?.map((id: any) => allCheckedValues?.push(id))
    }
    return allCheckedValues
}

const parseSubmittedList = (checkedList: any, options: any[]) => {
    const flatCheckedList: any[] = []
    checkedList.map((option: any) => {
        const isParent = option.split(":").length > 1
        if (isParent) {
            flatCheckedList.push(option)
        }
        if (!isParent) {
            let hasParent = false
            const parent = options.filter((item: any) => item.value === `${option.split('_')[0]}:`)[0] as any
            if (!isEmpty(parent)) {
                checkedList.map((item: any) => {
                    if (item === parent.value) {
                        hasParent = true
                    }
                })
            }
            if (!hasParent) {
                flatCheckedList.push(option)
            }
        }
    })

    return flatCheckedList
}

const setAppliedListOptions = (checkedList: any, offersWithLabels: any) => isArray(offersWithLabels?.list) ? offersWithLabels?.list?.filter((option: any) => includes(checkedList, option.value)) : []

const DestinationPicker = (
    {
        highLightedOption,
        values,
        formikValue,
        appendTo,
        setFormikValues
    }: DestinationPickerProps) => {

    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const [contientValue, setContientValue] = useState<any>({})

    const [options, setOptions] = useState<any[]>([])

    const [flatCheckedList, setFlatCheckedList] = useState<any[]>([])

    const [openOptionList, setOpenOptionList] = useStorage<any[]>('sessionStorage', 'DestinationPickerOpenOption', [])

    const [searchValue, setSearchValue] = useState<any>({label: ''})

    const [autoSuggestCheckedOptions, setAutoSuggestCheckedOptions] = useState<any>({})

    const [offersWithLabels, setOffersWithLabel] = useStorage<any>('sessionStorage', 'offersWithLabels', {})

    const [showAutoSuggestOptions, setShowAutoSuggestOptions] = useState<boolean>(false)

    const [isLoadingAutoSuggest, setIsLoadingAutoSuggest] = useState<boolean>(false)

    const [autoSuggestOptions, setAutoSuggestOptions] = useState({})

    const {selectedLanguage} = useBookBrdPageFunctions()

    const {searchFormValue} = useSearchFormValues()

    const [appliedList, setAppliedList] = useState<any[]>(setAppliedListOptions(getAllCheckedOptions(formikValue, searchFormValue), offersWithLabels))

    const checkboxChangeHandler = (label: string | undefined, value: string | undefined, type: string) => {
        if (!includes(offersWithLabels?.list?.map((item: any) => item.value), value)) {
            const newValues = isArray(offersWithLabels?.list) ? offersWithLabels?.list : []
            newValues.push({label, value, type})
            setOffersWithLabel((state: any) => ({
                ...state,
                list: newValues
            }))
        }
        if (type === 'regions' || type === 'countries') {
            if (!isEmpty(autoSuggestCheckedOptions)) {
                setAutoSuggestCheckedOptions({})
            }
            if (includes(flatCheckedList, value)) {
                setFlatCheckedList((state: any) => {
                    let newList: any[] = state
                    if (value?.split('_').length as any === 1) {
                        const subOptions: any[] = parseCheckedOptions(value, options)
                        const flatList: any[] = []
                        subOptions?.map((item: any) => {
                            flatList.push(item.value)
                        })
                        flatList.push(value)

                        newList = newList.filter((item: any) => !includes(flatList, item))
                        return [...newList] as any
                    }
                    if (value?.split('_').length as any > 1) {
                        return [...deleteParentOption(state, value)] as any
                    }
                })
            }
            if (!includes(flatCheckedList, value)) {
                setFlatCheckedList((state: any) => {
                    let newValue: any = []
                    const wholeValue: any[] = state
                    if (value?.split('_').length as any === 1) {
                        const subOptions: any[] = parseCheckedOptions(value, options)
                        subOptions?.map((item: any) => {
                            wholeValue.push(item.value)
                        })
                        wholeValue.push(value)
                        newValue = uniq(wholeValue)
                    }
                    if (value?.split('_').length as any > 1) {
                        if (!isEmpty(value?.split('_')[0])) {
                            const FlatSubOptions: any[] = []
                            const subOptions: any = parseCheckedOptions(`${value?.split('_')[0]}:`, options)
                            subOptions?.map((item: any) => FlatSubOptions.push(item.value))
                            wholeValue.push(value)
                            const checkedSubOptions = subOptions.filter((item: any) => includes(state, item.value))
                            if (checkedSubOptions.length === FlatSubOptions.length && !isEmpty(value?.split('_')[0])) {
                                wholeValue.push(getParentId(`${value?.split('_')[0]}:`, options))
                            }
                            newValue = wholeValue
                        } else {
                            wholeValue.push(value)
                            newValue = wholeValue
                        }
                    }

                    return [...uniq(newValue)]
                })
            }
        }
        if (type === 'cities') {
            setFlatCheckedList([])
            if (isEmpty(autoSuggestCheckedOptions?.cities)) {
                setAutoSuggestCheckedOptions({cities: value})
            }
            if (autoSuggestCheckedOptions?.cities === value) {
                setAutoSuggestCheckedOptions({cities: ''})
            }
        }
        if (type === 'hotels') {
            const newValue = isArray(autoSuggestCheckedOptions?.hotels) ? autoSuggestCheckedOptions?.hotels : []
            setFlatCheckedList([])
            if (includes(newValue, value)) {
                setAutoSuggestCheckedOptions({hotels: newValue.filter((code: any) => code !== value)})
            } else {
                newValue.push(value)
                setAutoSuggestCheckedOptions({hotels: newValue})
            }

        }
    }

    const openChangeHandler = (value: string | undefined) => {
        if (includes(openOptionList, value)) {
            setOpenOptionList((state: any) => ([
                ...state.filter((item: any) => item !== value)
            ]))
        }
        if (!includes(openOptionList, value)) {
            setOpenOptionList((state: any) => ([
                ...state,
                value
            ]))
        }
    }

    const sendRequestForAutoSuggest = useDebouncedCallback(async (value: any) => {
        try {
            setIsLoadingAutoSuggest(true)
            const request = await promiseRequest(`${CONTENT_SERVICE_URL}Autosuggest`, {
                Sections: ['Autosuggest'],
                query: value,
                ExternalDataSource: 'MDS',
                Language: selectedLanguage,
            });
            const {Sections} = request;
            const {Autosuggest} = Sections;
            const {items} = Autosuggest;
            const {
                cities,
                countries,
                hotels,
                regions,
            } = items;
            setAutoSuggestOptions((state: any) => ({
                ...state,
                countries,
                regions,
                cities,
                hotels,
            }));
        } catch (e) {
            console.log(e);
            // eslint-disable-next-line no-empty
        } finally {
            setIsLoadingAutoSuggest(false)
        }
    }, 200)

    const onSearchInputChangeHandler = (event: any) => {
        const value = event.target.value
        setSearchValue({label: value})
        if (value?.length > 2) {
            (async () => {
                sendRequestForAutoSuggest(value)
            })();
        } else {
            setShowAutoSuggestOptions(false)
        }

    }

    const onSearchInputClearHandler = () => {
        setSearchValue({label: ''})
    }

    const onApplyChangeHandler = () => {
        setFlatCheckedList([])
        setAutoSuggestCheckedOptions({})
        setSearchValue({label: ''})
        setFormikValues(XCODE_FIELD, autoSuggestCheckedOptions?.hotels)
        setFormikValues(XCITY_FIELD, autoSuggestCheckedOptions?.cities)
        setFormikValues(TO_FIELD, uniq(parseSubmittedList(flatCheckedList, options)))
    }

    const onClear = () => {
        setAutoSuggestCheckedOptions({})
        setFlatCheckedList([])
        setAppliedList([])
        setFormikValues(XCODE_FIELD, [])
        setFormikValues(XCITY_FIELD, '')
        setFormikValues(TO_FIELD, [])
    }

    const getValidValues = (object: any) => {
        const newOptions: any[] = []
        Object.keys(object).map((countryId) => {
            const subOptions: any = [];
            const countryItem = object[countryId];
            const {
                desc: countryName, continent, hasRoundTrip, attributes, regions = {},
            } = countryItem;
            const continents = continent;
            Object.keys(regions).map((regionId) => {
                const listOfAttributes: any = [];
                const regionItem = regions[regionId];
                const {desc: regionName, attributes, hasRoundTrip} = regionItem;
                attributes?.map((attribute: any) => {
                    listOfAttributes.push(attribute);
                });

                subOptions.push({
                    countryId,
                    countryName,
                    hasRoundTrip,
                    value: regionId,
                    label: regionName,
                    extra: {
                        attributes,
                        continents,
                    },
                });
            });
            const sortedSubOptions = sortArray(subOptions, 'label') as any;

            newOptions.push({
                value: countryId,
                label: countryName,
                hasRoundTrip,
                extra: {
                    continents,
                    attributes,
                },
                ...subOptions.length ? {subOptions: sortedSubOptions} : {},
            });
        });

        return newOptions.sort(function (a, b) {
            var labelA = a.label.toUpperCase();
            var labelB = b.label.toUpperCase();
            if (labelA < labelB) {
                return -1;
            }
            if (labelA > labelB) {
                return 1;
            }
            return 0;
        })

    }

    const setDisplayedContinent = (contientName?: string | null) => {
        setContientValue((state: any) => ({
            ...state,
            selected: contientName,
        }))
    }

    const onContinentChangeHandler = (continentName: string) => {
        const validCountries: any = {}
        Object.keys(values).filter((continentId: string) => values[continentId].continent === continentName).map((validContinent: string) => validCountries[validContinent] = values[validContinent])
        return getValidValues(validCountries)
    }

    const setCheckedOptions = () => {
        let checkedAutoSuggestOptions: any = {}
        checkedAutoSuggestOptions['cities'] = searchFormValue[XCITY_FIELD]
        checkedAutoSuggestOptions['hotels'] = searchFormValue[XCODE_FIELD]?.map((xCode: any) => `${xCode}`)
        setAutoSuggestCheckedOptions(checkedAutoSuggestOptions)
        if (!isOpen) {
            setFlatCheckedList(setCheckedFromFormik(formikValue, options))
            setAppliedList(setAppliedListOptions(getAllCheckedOptions(formikValue, searchFormValue), offersWithLabels))
        }
    }

    useEffect(() => {
        if (!isEmpty(values)) {
            const Options: any = [];
            const FlatOptions: any = []
            let continent: any = {}
            Object.keys(values).map((countryId) => {
                const subOptions: any = [];
                const countryItem = values[countryId];
                const {
                    desc: countryName, continent, hasRoundTrip, attributes, regions = {},
                } = countryItem;
                const continents = continent;

                Object.keys(regions).map((regionId) => {
                    const listOfAttributes: any = [];
                    const regionItem = regions[regionId];
                    const {desc: regionName, attributes, hasRoundTrip} = regionItem;
                    attributes?.map((attribute: any) => {
                        listOfAttributes.push(attribute);
                    });

                    subOptions.push({
                        countryId,
                        countryName,
                        hasRoundTrip,
                        value: regionId,
                        label: regionName,
                        extra: {
                            attributes,
                            continents,
                        },
                    });
                });

                const sortedSubOptions = sortArray(subOptions, 'label') as any;

                Options.push({
                    value: countryId,
                    label: countryName,
                    hasRoundTrip,
                    extra: {
                        continents,
                        attributes,
                    },
                    ...subOptions.length ? {subOptions: sortedSubOptions} : {},
                });
            });
            Object.keys(values).map((countryId) => {
                const subOptions: any = [];
                const countryItem = values[countryId];
                const {
                    desc: countryName, continent, hasRoundTrip, attributes, regions = {},
                } = countryItem;
                const continents = continent;

                Object.keys(regions).map((regionId) => {
                    const listOfAttributes: any = [];
                    const regionItem = regions[regionId];
                    const {desc: regionName, attributes, hasRoundTrip} = regionItem;
                    attributes?.map((attribute: any) => {
                        listOfAttributes.push(attribute);
                    });

                    FlatOptions.push({
                        countryId,
                        countryName,
                        hasRoundTrip,
                        value: regionId,
                        label: regionName,
                        extra: {
                            attributes,
                            continents,
                        },
                    });
                });

                const sortedSubOptions = sortArray(subOptions, 'label') as any;

                FlatOptions.push({
                    value: countryId,
                    label: countryName,
                    hasRoundTrip,
                    extra: {
                        continents,
                        attributes,
                    },
                    ...subOptions.length ? {subOptions: sortedSubOptions} : {},
                });
            });
            continent['highlighted_options'] = {
                list: FlatOptions.filter((option: any) => highLightedOption?.includes(option?.value))
            }
            uniq(Object.keys(values).map((countryId: any) => values[countryId].continent))?.filter((continent: any) =>
                !!continent)?.map((continentName: any) => continent[continentName] = {
                list: onContinentChangeHandler(continentName)
            })
            setOptions(Options)
            setContientValue({
                list: continent,
                selected: null
            })
        }
    }, [values || highLightedOption])

    useEffect(() => {
        if (searchValue.label.length >= 3) {
            setShowAutoSuggestOptions(true)
        } else {
            setShowAutoSuggestOptions(false)
        }
    }, [searchValue])

    useEffect(() => {
        setCheckedOptions()
    }, [options, isOpen])

    useEffect(() => {
        setAppliedList(setAppliedListOptions(getAllCheckedOptions(formikValue, searchFormValue), offersWithLabels))
    }, [offersWithLabels])

    return (
        <>
            <BasicPicker
                isOpen={isOpen}
                appendTo={appendTo}
                setIsOpen={setIsOpen}
                setDisplayedContinent={setDisplayedContinent}
                ContientValue={contientValue}
                autoSuggestCheckedOptions={autoSuggestCheckedOptions}
                autoSuggestOptions={autoSuggestOptions}
                isLoadingAutoSuggest={isLoadingAutoSuggest}
                title={t('lbl_destination')}
                placeholder={'where_to'}
                maxNumberOfColumns={isMobileOnly ? 1 : 4}
                minColumnWidth={200}
                width={1000}
                options={contientValue?.selected ? contientValue?.list[contientValue?.selected]?.countryList as any[] : []}
                subOptionsEnabled={true}
                autoSuggestEnabled={true}
                showAutoSuggestOptions={showAutoSuggestOptions}
                flatCheckedList={flatCheckedList}
                appliedList={appliedList}
                openOptionList={openOptionList}
                searchValue={searchValue}
                checkboxChangeHandler={checkboxChangeHandler}
                openChangeHandler={openChangeHandler}
                onApplyChangeHandler={onApplyChangeHandler}
                onSearchInputChangeHandler={onSearchInputChangeHandler}
                onSearchInputClearHandler={onSearchInputClearHandler}
                onClear={onClear}
            />
        </>
    )
}
export default DestinationPicker