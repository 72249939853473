import {Styled} from "./OfferDetailsHolder.styles"
import {useTranslation} from "react-i18next";
import OfferDescription from "./OfferDescription/OfferDescription";
import OfferWeather from "./OfferWeather";
import OfferRegionInfo from "./OfferRegionInfo";
import OfferDetails from "./OfferDetails";
import OfferReviewDetails from "./OfferReviewDetails";
import {OfferDetailsSectionTypes} from "../../../../../lib/Types";

type OfferDetailsHolderProps = {
    onShowMap: () => void
    isLoadingOfferDetails: boolean
    controls: any
    offerControls: any
    offer: any
}


const OfferDetailsHolder = (
    {
        onShowMap,
        isLoadingOfferDetails,
        controls,
        offerControls,
        offer,
    }: OfferDetailsHolderProps
) => {
    const {t} = useTranslation();

    const sectionsList: OfferDetailsSectionTypes[] = [
        OfferDetailsSectionTypes.details,
        OfferDetailsSectionTypes.weather,
        OfferDetailsSectionTypes.region,
        OfferDetailsSectionTypes.advancedDetails,
        OfferDetailsSectionTypes.reviews,
    ]

    const getComponentForSection = (type: OfferDetailsSectionTypes) => {
        switch (type) {
            case OfferDetailsSectionTypes.details:
                return (
                    <OfferDescription
                        isLoadingOfferDetails={isLoadingOfferDetails}
                        isLoadingSections={controls?.isLoadingDescription}
                        offerControls={offerControls}
                        offer={offer}
                    />
                )
            case OfferDetailsSectionTypes.weather:
                return (
                    <OfferWeather
                        isLoadingSections={controls?.isLoadingWeather}
                        offer={offer}
                        offerControls={offerControls}
                    />
                )
            case OfferDetailsSectionTypes.region:
                return (
                    <OfferRegionInfo
                        isLoadingSections={controls?.isLoadingRegionList}
                        offer={offer}
                        offerControls={offerControls}
                    />
                )
            case OfferDetailsSectionTypes.advancedDetails:
                return (
                    <OfferDetails
                        onShowMap={onShowMap}
                        offer={offer}
                        isLoadingOfferDetails={isLoadingOfferDetails}
                    />
                )
            case OfferDetailsSectionTypes.reviews:
                return (
                    <OfferReviewDetails
                        reviewRatingData={{}}
                        isLoadingReview={false}
                    />
                )
            default:
                return null
        }
    }

    return (
        <Styled.OfferDetailsHolder>
            {sectionsList.map((type: OfferDetailsSectionTypes) => {

                return (
                    <Styled.Section__Holder
                        key={`offer_details_section_${type}`}
                    >
                        <Styled.Section__Title__Label>
                            {t(`lbl_${type}`)}
                        </Styled.Section__Title__Label>
                        {getComponentForSection(type)}
                    </Styled.Section__Holder>
                )
            })}
        </Styled.OfferDetailsHolder>)
}
export default OfferDetailsHolder