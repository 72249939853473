import {Styled} from "../OrderStatus.styles";
import {faCheckCircle, faCircleX} from "@fortawesome/pro-light-svg-icons";
import Button from "../../../../../../lib/UIBookBrd/Button";
import {useTranslation} from "react-i18next";
import useConfirmationPage from "../../../../useConfirmationPage";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../../../../../../lib/UIBookBrd/Tooltip";
import Text from "../../../../../../lib/UIBookBrd/Text";

type ApprovedProps = {
    isFullDetails: boolean
}


const confirmationDetails: any = {
    offer_confirmed: true,
    offer_pre_paid: true,
    offer_fully_paid: false

}
const Approved = (
    {
        isFullDetails
    }: ApprovedProps
) => {

    const {
        shareOnSocialMedia,
    } = useConfirmationPage()

    const {t} = useTranslation()

    const toolTip = (
        <Text>
            {t('lbl_delay_explanation')}
        </Text>
    )

    return (
        <Styled.Order__Status__Holder>
            {!isFullDetails && (
                <Styled.Approved__Order__Icon
                    icon={faCheckCircle}
                />
            )}
            {!isFullDetails && (
                <Styled.Order__Status__Label>
                    {t('lbl_approved_order_status')}
                </Styled.Order__Status__Label>
            )}
            {!isFullDetails && (
                <Styled.Buttons__Holder>
                    <Button
                        label={t('lbl_share_on_social_media')}
                        onClick={shareOnSocialMedia}
                        style={{
                            padding: '0 10px'
                        }}
                    />
                </Styled.Buttons__Holder>
            )}
            {isFullDetails && (
                <Styled.Confirmation__Details>
                    {Object.keys(confirmationDetails).map((type: string) => {
                        const isConfirmed = confirmationDetails[type]
                        return (
                            <Styled.Confirmation__Detail>
                                <Styled.Order__Details__Icon
                                    isConfirmed={isConfirmed}
                                    icon={isConfirmed ? faCheckCircle : faCircleX}
                                />
                                <Styled.Order__Details__Label
                                    isConfirmed={isConfirmed}
                                >
                                    {t(`lbl_${type}`)}
                                </Styled.Order__Details__Label>
                                {!isConfirmed && (
                                    <Tooltip
                                        content={toolTip}
                                    >
                                        <Styled.InformationIcon
                                            icon={faInfoCircle}
                                        />
                                    </Tooltip>
                                )}
                            </Styled.Confirmation__Detail>
                        )
                    })}
                </Styled.Confirmation__Details>
            )}


        </Styled.Order__Status__Holder>
    )
}

export default Approved