import styled from "styled-components";
import FlexBox from "../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../lib/UIBookBrd/Text";
import Image from "../../../../lib/UIBookBrd/Image";

const Contact__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 5px 10px;
  gap: 4px 0;
`
const Email__Holder = styled(Text)`

`
const Phone__Holder = styled(Text)`

`
const Localization__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
`
const Localization__Holder__Values = styled(FlexBox)`
  margin-left: 10px;
  flex-direction: column;
  gap: 8px;
`
const Localization__Label = styled(Text)`

`
const HQ__Image = styled(Image)`
  width: 240px;
  height: 180px;
`

export const Styled = {
    HQ__Image,
    Email__Holder,
    Phone__Holder,
    Contact__Holder,
    Localization__Holder,
    Localization__Holder__Values,
    Localization__Label
}