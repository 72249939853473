import {Styled} from "./Settings.styles";
import React from "react";
import {useTranslation} from "react-i18next";

type SettingsFieldsProps = {
    children?: React.ReactNode
    label?: string
}
const defaultProps = {
    label: 'add label',
    children: undefined,
}

const SettingsFields = ({children, label}: SettingsFieldsProps) => {
    const {t} = useTranslation();
    return (
        <Styled.SettingsType>
            <Styled.FieldTypeLabel>
                {t(`${label}`)}
            </Styled.FieldTypeLabel>
            <Styled.ParticularSetting>
                {children}
            </Styled.ParticularSetting>
        </Styled.SettingsType>
    )
}
SettingsFields.defaultProps = defaultProps;


export default SettingsFields