import {Styled} from "../OrderStatus.styles";
import {faCircleQuestion} from "@fortawesome/pro-light-svg-icons";
import {useTranslation} from "react-i18next";
import Text from "../../../../../../lib/UIBookBrd/Text";
import useConfirmationPage from "../../../../useConfirmationPage";

const NotFound = () => {

    const {
        orderNumber
    } = useConfirmationPage()

    const {t} = useTranslation()

    return (
        <Styled.Order__Status__Holder>
            <Styled.Not__Found__Order__Icon
                icon={faCircleQuestion}
            />
            <Styled.Order__Status__Label>
                {t('lbl_not_found_order_status')}
            </Styled.Order__Status__Label>
            <Styled.Buttons__Holder>
                <Text>
                    {t('lbl_check_order_code')}{`: ${orderNumber}`}
                </Text>
            </Styled.Buttons__Holder>
        </Styled.Order__Status__Holder>
    )
}
export default NotFound