import {useTranslation} from "react-i18next";
import React from "react";
import PageHolder from "../../lib/UIBookBrd/PageHolder";
import {accountPageType} from "../../lib/Types";
import Content from "./Content";
import ContextProvider from "./Context";

type ManageAccountProps = {
    type: accountPageType
}
const defaultProps = {}
const ManageAccount = (
    {
        type
    }: ManageAccountProps) => {

    const {t} = useTranslation();

    return (
        <PageHolder>
            <ContextProvider
                type={type}
            >
                <Content
                    type={type}
                />
            </ContextProvider>
        </PageHolder>
    )
}


ManageAccount.defaultProps = defaultProps;
export default ManageAccount