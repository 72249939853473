import React from "react";
import {Styled} from "./AddButton.styles";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

type AddButton = {
    isAdd?: boolean
    isDisabled?: boolean
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void
    ref?: any
    style?: any
}
const defaultProps = {
    isDisabled: true,
    isAdd: false,
    onClick: undefined,
    onMouseOver: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    ref: undefined,
}
const AddButton = ({isAdd, onClick, isDisabled, ...props}: AddButton) => {


    return (
        <Styled.AddButton
            isDisabled={isDisabled}
            onClick={!isDisabled ? onClick : () => {
            }}

        >
            <Styled.IconHolder>
                {isAdd && (
                    <Styled.FontAwesomeIcons
                        isDisabled={isDisabled}
                        icon={faPlus}
                    />
                )}
                {!isAdd && (
                    <Styled.FontAwesomeIcons
                        isDisabled={isDisabled}
                        icon={faMinus}
                    />
                )}
            </Styled.IconHolder>
        </Styled.AddButton>
    )

}
AddButton.defaultProps = defaultProps;
export default AddButton