import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";
import CheckBox from "../../../lib/UIBookBrd/CheckBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AutoSuggestOptionList__Holder = styled(FlexBox)`
  flex-grow: 1;
  padding: 0 10px;
`
const AutoSuggestSection = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`
const AutoSuggestSection__Label = styled(Text)`
  padding: 5px 0;
  font-size: 15px;
  font-weight: bold;
`
const CustomOptionHolder = styled(FlexBox)`
  padding: 2px 0;
  margin-bottom: 5px;
  flex-direction: column;
  flex-wrap: nowrap;
`
const MainOptionText = styled(Text)<{ isDisabled: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: ${({isDisabled}) => isDisabled ? 'grey' : undefined};
`
const AdditionalOptionText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 2px;
  margin-left: 5px;
  text-align: left;
  font-weight: lighter;
`
const CustomCheckBox = styled(CheckBox)`
`
const CustomHolderForHotels = styled(FlexBox)`
  flex-wrap: nowrap
`
const StarIcon = styled(FontAwesomeIcon)`
  font-size: 5px;
  color: ${({theme}) => theme.ratingStarColor};

  &:first-of-type {
    margin-left: 2px;
  }
`
const CustomSectionIcon = styled(FontAwesomeIcon)`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 2px;
  width: 20px;
  height: 15px;
  color: ${(props: { theme: any; }) => props.theme.color};

`
export const Styled = {
    AutoSuggestOptionList__Holder,
    AutoSuggestSection,
    AutoSuggestSection__Label,
    CustomOptionHolder,
    MainOptionText,
    AdditionalOptionText,
    CustomHolderForHotels,
    CustomCheckBox,
    StarIcon,
    CustomSectionIcon
}