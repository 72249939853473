import FlexBox from "../../../../lib/UIBookBrd/FlexBox";
import {Styled} from "../../Navigation.styles";
import {faUserSecret} from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";
import useUserData from "../../../../lib/UseHooksBookBrd/useUserData";
import {isMobileOnly} from "react-device-detect";

const Account = () => {

    const {t} = useTranslation()

    const {
        isLogged,
        userImage,
        userName,
        deleteBackground,
        userBackgroundImage,
        userBackgroundColor
    } = useUserData()

    return (
        <Styled.Account__Holder>
            <FlexBox style={{
                flexWrap: 'nowrap',
                position: 'relative',
                height: isMobileOnly ? '130px' : '90px',
                minWidth: '100%',
            }}
            >
                <Styled.Background__Image src={userBackgroundImage}/>
                {isLogged ? (
                    <>
                        {userImage ? (
                            <Styled.Account__Image
                                userBackgroundColor={userBackgroundColor}
                                deleteBackground={deleteBackground}
                                src={userImage}
                            />
                        ) : (
                            <Styled.No_Image_Account
                                isLogged={isLogged}
                                backgroundColor={userBackgroundColor}
                            >
                                <Styled.No__User__Image__Label>
                                    {userName[0].toUpperCase()}
                                </Styled.No__User__Image__Label>
                            </Styled.No_Image_Account>
                        )}
                    </>
                ) : (
                    <Styled.No_Image_Account
                        isLogged={isLogged}
                        backgroundColor={undefined}
                    >
                        <Styled.Not__Logged__User
                            icon={faUserSecret}
                        />

                    </Styled.No_Image_Account>
                )}
                <Styled.Account__Label
                    isLogged={isLogged}
                >
                    {isLogged ? userName : t('NoAccount')}
                </Styled.Account__Label>
            </FlexBox>
        </Styled.Account__Holder>
    )
}
export default Account