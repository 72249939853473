interface googleAnalytics {
    event: string,
    eventCategory: string,
    eventAction: string,
    eventLabel?: string
    label?: string,
    value?: number | undefined
}


declare let window: {
    dataLayer: any
    ga: any
};

const useGoogleAnalytics = () => {


    const sendToAnalytics = (message: googleAnalytics) => {

        window.dataLayer.push(message)
    }


    return {
        sendToAnalytics,
    }
}

export default useGoogleAnalytics