enum participantsType {
    child = 'Child',
    payment = 'Payment',
    adult = 'Adult'

}

export {
    participantsType
}
