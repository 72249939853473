// Trips routes
import React from "react";
import {TIME_ZONE_LIST} from "../TimezoneList";
import {lowerCase} from "lodash-es";

const getLoginCountry = (timezone: string) => {

    const country = TIME_ZONE_LIST[timezone]

    return lowerCase(country)

}

export const getPreferences = (localSettings: any) => {
    const isLightTheme = window.matchMedia('(prefers-color-scheme: light )').matches
    const language = window.navigator.language.split('-')[0]
    const country = getLoginCountry(Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone)


    return ({
        ...localSettings,
        isFirstLogin: false,
        isLightTheme: isLightTheme,
        SelectedLanguage: language,
        PreferredCountry: country
    })
}

//Trips routes
export const getLangForLink = () => {

    const localSettings = JSON.parse(localStorage.getItem('LocalSettings') ?? '{}')
    const userPreference = getPreferences(localSettings)

    return localSettings?.SelectedLanguage ?? userPreference.SelectedLanguage
}


export const getValidRoute = (route: string) => {
    const lang = getLangForLink()

    return `/${lang}${route}`
}


const FIRST_STEP_TRIP_SEARCH = getValidRoute(`/Trips`)
const SECOND_STEP_TRIP_SEARCH = `${FIRST_STEP_TRIP_SEARCH}/Offers`
const THIRD_STEP_TRIP_SEARCH = `${FIRST_STEP_TRIP_SEARCH}/OfferDetails`
const BOOK_STEP_TRIP_SEARCH = `${FIRST_STEP_TRIP_SEARCH}/BookingPage`
//Flights routes
const FIRST_STEP_FLIGHT_SEARCH = getValidRoute(`/Flights`)
//AccountRelated
const CREATE_ACCOUNT = getValidRoute("/Account/SignUp")
const LOGIN = getValidRoute('/Account/SignIn')
const MANAGE_ACCOUNT = getValidRoute("/Account/User/Me")
const SOMEONE_ACCOUNT = getValidRoute(`/Account/User`)
//Other
const NO_ROUTE_FOUND = '/Lost/Route'
//Support
const CONTACT_US = getValidRoute('/Support')
const CONTACT_OWN = `${CONTACT_US}/FAQ`
const CONTACT_OUR = `${CONTACT_US}/Report`
// Suppliers
const OPERATOR_INFORMATION = getValidRoute('/TourOperators/Details')
const OPERATOR_LIST = getValidRoute('/TourOperators')
//Other Information
const TRAVEL__INFORMATION = getValidRoute('/Travel-Information')
const TYLER_DOCUMENTATION = getValidRoute('/TylerAIAssistant/Documentation')
// ORDER
const ORDER_STATUS = getValidRoute('/Order/Status')
const ORDER_MANAGE = getValidRoute('/Order/Manage')


export {
    TRAVEL__INFORMATION,
    OPERATOR_LIST,
    TYLER_DOCUMENTATION,
    SOMEONE_ACCOUNT,
    CONTACT_OUR,
    CONTACT_OWN,
    CONTACT_US,
    FIRST_STEP_TRIP_SEARCH,
    SECOND_STEP_TRIP_SEARCH,
    BOOK_STEP_TRIP_SEARCH,
    THIRD_STEP_TRIP_SEARCH,
    FIRST_STEP_FLIGHT_SEARCH,
    CREATE_ACCOUNT,
    LOGIN,
    MANAGE_ACCOUNT,
    NO_ROUTE_FOUND,
    OPERATOR_INFORMATION,
    ORDER_STATUS,
    ORDER_MANAGE
}