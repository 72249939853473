import {Styled} from "./OfferReviewDetails.styles";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {faStar} from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "../../../../../../lib/UIBookBrd/Tooltip";
import {toNumber} from "lodash-es";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";

type ReviewBoxProps = {
    value: any
    isLoading?: boolean
}

const ReviewBox = (
    {
        isLoading,
        value
    }: ReviewBoxProps
) => {

    const [isOpen, setIsOpen] = useState<any>(false)

    const {
        userName,
        userTitle,
        userReviewsNumber,
        description,
        totalRating,
        whenLabel

    } = value

    const {t} = useTranslation();

    const reviewValues = ['location', 'food', 'Room', 'cleanHotel', 'staff', 'attractions', 'total']

    return !isLoading ? (
        <Styled.Review__Box>
            <Styled.User__Data__Holder>
                <Styled.User__Icon>
                    <Text
                        style={{
                            fontSize: '15px',
                            fontWeight: 'bold',
                            margin: 'auto',
                            color: '#fdfdfd'
                        }}
                    >
                        {userName[0]?.toUpperCase()}
                    </Text>
                </Styled.User__Icon>
                <Styled.Name__User__Data__Holder>
                    <Styled.User__Name>
                        {userName}
                    </Styled.User__Name>
                    <Styled.Title__User__Data__Label>
                        {userTitle}
                        {' • '}
                        <Styled.User__Data__Span>
                            {userReviewsNumber}
                        </Styled.User__Data__Span>
                    </Styled.Title__User__Data__Label>
                </Styled.Name__User__Data__Holder>

            </Styled.User__Data__Holder>
            <Styled.User__Rating__Holder>
                <Tooltip
                    content={
                        <Styled.TollTip__Content__Holder>
                            <Styled.Labels__Holder>
                                {reviewValues?.map((item: string) => {

                                    return (
                                        <Styled.Review__Type__Label
                                            key={`box_review_${item}`}
                                        >
                                            {item}:
                                        </Styled.Review__Type__Label>
                                    )
                                })}
                            </Styled.Labels__Holder>
                            <Styled.Ratings__Holder>
                                {reviewValues?.map((_, index: number) => {

                                    const ratingNumber = toNumber((Math.floor(Math.random() * 100 + 1) / 10).toFixed(1))

                                    return (
                                        <Styled.Rating__Line__Number__Holder
                                            key={`box_review_rating_${index}`}
                                        >
                                            <Styled.Rating__Number>
                                                {ratingNumber}
                                            </Styled.Rating__Number>
                                            <Styled.Rating__Line__Holder>
                                                <Styled.Rating__Line
                                                    width={ratingNumber * 10}
                                                />
                                                <Styled.Missing__Rating__Line
                                                    width={100 - ratingNumber * 10}
                                                />
                                            </Styled.Rating__Line__Holder>
                                        </Styled.Rating__Line__Number__Holder>
                                    )
                                })}
                            </Styled.Ratings__Holder>
                        </Styled.TollTip__Content__Holder>
                    }
                >
                    <Styled.Rating__Value>
                        <Styled.Rating__Number__Holder>
                            {totalRating}
                        </Styled.Rating__Number__Holder>
                        <Styled.Rating__Star
                            icon={faStar}
                        />
                    </Styled.Rating__Value>
                </Tooltip>
                <Styled.When__Label>
                    {whenLabel}
                </Styled.When__Label>
            </Styled.User__Rating__Holder>
            <Styled.Review__Data
                isOpen={isOpen}
            >
                {description}
            </Styled.Review__Data>
            <Styled.Blur__Over__Flow/>
            <Text style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontSize: '12px',
                marginLeft: 'auto',
                marginRight: '10px'
            }}>
                {t('lbl_show_full_review')}
            </Text>
        </Styled.Review__Box>
    ) : (
        <Styled.Review__Box>
            <Styled.User__Data__Holder>
                <SkeletonBox style={{
                    margin: "0",
                    width: '38px',
                    minWidth: "38px",
                    minHeight: '38px',
                    height: '38px',
                    borderRadius: '50%'
                }}/>
                <Styled.Name__User__Data__Holder>
                    <SkeletonBox
                        style={{
                            minWidth: '90px',
                            width: "90px",
                            minHeight: "14px",
                            height: '14px',
                            margin: "0 0 auto 0"
                        }}
                    />
                    <SkeletonBox
                        style={{
                            minWidth: '120px',
                            minHeight: "13px",
                            height: '13px',
                            margin: "0 0 auto 0"
                        }}
                    />
                </Styled.Name__User__Data__Holder>

            </Styled.User__Data__Holder>
            <Styled.User__Rating__Holder>
                <SkeletonBox
                    style={{
                        minWidth: '100px',
                        minHeight: "13px",
                        height: '13px',
                        margin: "0"
                    }}
                />
            </Styled.User__Rating__Holder>
            <Styled.Review__Data
                isOpen={isOpen}
            >
                {Array.from({length: Math.floor(Math.random() * (6 - 3 + 1)) + 3})?.map((_, index: number) => {

                    return (
                        <SkeletonBox
                            key={`loading_review_data_${index}`}
                            style={{
                                minWidth: `${Math.floor(Math.random() * (95 - 90 + 1)) + 90}%`,
                                minHeight: "13px",
                                height: '13px',
                                margin: " 2px 0"
                            }}
                        />
                    )
                })}
            </Styled.Review__Data>
        </Styled.Review__Box>
    );
}
export default ReviewBox
