import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import TextInput from "../../../lib/UIBookBrd/TextInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from "polished";
import {isMobileOnly} from "react-device-detect";
import Text from "../../../lib/UIBookBrd/Text";

const Controls__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 8px 5px;
  margin: ${isMobileOnly && 'auto 0 '};
  height: 100%;
  width: 100%;
`
const Custom__Text__Input = styled(TextInput)`
  margin: auto 0;
`
const Send__Message__Icon = styled(FontAwesomeIcon)<{ isPending: boolean }>`
  color: ${({theme, isPending}) => rgba(theme.color, isPending ? 0.6 : 1.0)};
  height: 20px;
  width: 20px;
  margin: auto 10px 5px 10px;
  cursor: ${({isPending}) => isPending ? 'not-allowed' : 'pointer'};
  transition: 200ms;

  &:hover {
    transform: ${({isPending}) => !isPending && 'scale(1.1)'};
  }
`
const More__Option__Icon = styled(FontAwesomeIcon)`
  height: 25px;
  width: 25px;
  color: ${({theme}) => theme.color};
  transition: 200ms;
  margin: auto 8px 5px 8px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

const More__Options__Option__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: nowrap;
  padding: 10px 5px;
  color: ${({theme}) => theme.color};
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => rgba(theme.color, 0.1)};
  }

`
const More__Options__Option__Icon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
  color: inherit;
  margin: auto 0;
  transition: 200ms;
`
const More__Options__Option__Label = styled(Text)`
  color: inherit;
  margin: auto;
  padding-left: 5px;
  text-align: center;
  transition: 200ms;
`


export const Styled = {
    More__Options__Option__Label,
    More__Options__Option__Icon,
    More__Options__Option__Holder,
    More__Option__Icon,
    Send__Message__Icon,
    Controls__Holder,
    Custom__Text__Input
}