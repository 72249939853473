import {isMobileOnly} from "react-device-detect";
import ModalHolder from "./ModalHolder";
import DrawerHolder from "./DrawerHolder";

const BotContentHolders = () => {

    return isMobileOnly ? (
        <DrawerHolder/>
    ) : (
        <ModalHolder/>
    )
}
export default BotContentHolders