import Modal from "../../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import TextInput from "../../../lib/UIBookBrd/TextInput";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Button from "../../../lib/UIBookBrd/Button";
import {useState} from "react";

const TokenModalDEVONLYDELTELATER = () => {

    const [inputValue, setInputValue] = useState<string>(sessionStorage.getItem('token') ?? '')

    const {toggleTokenModal, isModalTokenOpen} = useBookBrdPageFunctions()

    return (
        <Modal
            onClose={() => toggleTokenModal(false)}
            title={'Apply token'}
            isOpen={isModalTokenOpen}
        >
            <FlexBox
                style={{
                    padding: '10px',
                    gap: '10px',
                    flexDirection: 'column'
                }}
            >
                <TextInput
                    value={inputValue}
                    onChange={(event: any) => setInputValue(event.target.value)}
                    placeholder={'token'}
                    showIcon={false}
                    isClearAble={false}
                />
                <Button
                    onClick={() => {
                        sessionStorage.setItem('token', inputValue)
                        toggleTokenModal(false)
                        window.location.reload()
                    }}
                    label={'Apply'}
                />
            </FlexBox>
        </Modal>
    )
}

export default TokenModalDEVONLYDELTELATER