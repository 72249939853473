import {FieldsForOfferDetailsParams} from "../Types";

const MANDATORY__TRIP__DETAILS__FIELDS: FieldsForOfferDetailsParams[] = [
    FieldsForOfferDetailsParams.hotel,
    FieldsForOfferDetailsParams.from,
    FieldsForOfferDetailsParams.to,
    FieldsForOfferDetailsParams.board,
    FieldsForOfferDetailsParams.room,
    FieldsForOfferDetailsParams.participants,
    FieldsForOfferDetailsParams.departure,
    FieldsForOfferDetailsParams.offerId
]


export {
    MANDATORY__TRIP__DETAILS__FIELDS
}