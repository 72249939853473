import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../../../../../lib/UIBookBrd/Text";

const OfferWeatherHolder = styled(FlexBox)`
  flex-direction: column;
  padding: 0 5px;
`
const Main_Month_Value_Holder = styled(FlexBox)`
  padding: 10px 0;
  flex-direction: column;
`
const Weather__Icon = styled(FontAwesomeIcon)`
  height: 25px;
  width: 25px;
  color: ${({theme}) => theme.isDark ? '#f1ee42' : '#d9d617'};

  &:last-of-type {
    margin-top: 5px;
    height: 25px;
    margin-left: -20px;
    color: #989898;
  }
`
const Location__Label = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  margin-left: 5px;
  margin-bottom: 5px;
`
const Chart__Data__Holder = styled(FlexBox)`
  width: calc(100% - 250px);
  max-height: 300px;
  height: 300px;
  @media (max-width: 599px) {
    height: 200px;
    width: 100%;
    max-width: calc(100% - 10px);
  }
`
const Weather__Values__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: 0 5px;
  padding: 0 5px;
  height: auto;
  margin-top: 25px;
  flex-grow: 1;
  @media (max-width: 599px) {
    margin-top: 10px;
  }
`
const Displayed__Title = styled(Text)`
  font-size: 20px;
  margin-top: auto;
  user-select: none;
  margin-bottom: 1px;
  margin-left: 2px;
  font-weight: bold;
`
const Chart_Value_Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  @media (max-width: 599px) {
    flex-direction: column;
  }
`
const Data__Chart__Value = styled(FlexBox)`
  flex-direction: column;
  padding-bottom: 4px;
  gap: 8px 0;
`
const No__Weather__Label = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  margin: auto;
  text-align: center;
  align-content: center;
  align-items: center;
`
const Loading__Offer__Weather = styled(FlexBox)`
  height: 320px;
  width: 100%;
`
const Loader__Title__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: auto;
`
const Loader__Title = styled(Text)`
  text-align: center;
  font-weight: bold;
  margin: 0 auto 10px auto;

`

export const Styled = {
    Loader__Title,
    Loader__Title__Holder,
    Loading__Offer__Weather,
    Data__Chart__Value,
    No__Weather__Label,
    Chart_Value_Holder,
    Displayed__Title,
    Weather__Values__Holder,
    Chart__Data__Holder,
    OfferWeatherHolder,
    Main_Month_Value_Holder,
    Weather__Icon,
    Location__Label
}
