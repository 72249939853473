import {Styled} from "../../TripOffer.styles";
import {useTranslation} from "react-i18next";
import FlexBox from "../../../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../../../lib/UIBookBrd/Text";
import {faCalendar} from "@fortawesome/pro-light-svg-icons";

type BusDescription = {
    typeTitle: string
    value: any
}

const BusDescription = (
    {
        typeTitle,
        value,
    }: BusDescription
) => {

    const {t} = useTranslation();

    const {Departure, Destination} = value


    return (
        <Styled.FlightTypeDesc>
            <Styled.FlightTypeTitle>
                {t(`${typeTitle}`)}
                {':'}
            </Styled.FlightTypeTitle>
            <Styled.Flight__Segment__Holder>
                <Styled.Flight__Segment__Quick__View>
                    <Styled.Flight__Time>
                        {Departure?.Time}
                    </Styled.Flight__Time>
                    <FlexBox
                        style={{
                            flexGrow: '1',
                            marginTop: '13px',
                            flexDirection: 'column',
                            flexWrap: 'nowrap'
                        }}
                    >
                        <Styled.Flight__Line/>
                    </FlexBox>
                    <Styled.Flight__Time>
                        {Destination?.Time}
                    </Styled.Flight__Time>
                </Styled.Flight__Segment__Quick__View>
                <Styled.Flight__Destination__Quick__View>
                    <Text>
                       <span
                           style={{
                               fontWeight: 'bold'
                           }}
                       >
                             {Departure.Code}
                        </span>
                        {' • '}
                        {Departure.Name}
                    </Text>
                    <Text
                        style={{
                            marginLeft: 'auto'
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                             {Destination.Code}
                        </span>
                        {' • '}
                        {Destination.Name}
                    </Text>
                </Styled.Flight__Destination__Quick__View>
                <Styled.Flight__Details>
                    <Styled.Flight__Details__Value__Holder>
                        <Styled.Flight__Details__Value__Icon
                            icon={faCalendar}
                        />
                        <Styled.Flight__Details__Value>
                            {`${t('lbl_departure_date')} ${Departure.Date.split('-').reverse().join('.')}`}
                        </Styled.Flight__Details__Value>
                    </Styled.Flight__Details__Value__Holder>
                </Styled.Flight__Details>

            </Styled.Flight__Segment__Holder>
        </Styled.FlightTypeDesc>
    )
}
export default BusDescription