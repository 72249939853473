import {Modal} from "../../lib/UIBookBrd/Modal/ModalsTypes";
import {useTranslation} from "react-i18next";
import {Styled} from "./CompareOffersModal.styles";
import {isEmpty} from "lodash-es";
import {faCameraSlash, faHotel} from "@fortawesome/pro-light-svg-icons";
import {useState} from "react";
import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";
import useNotifications from "../../lib/UseHooksBookBrd/useNotifications";
import useTripResultsValues from "../../Pages/TripSearch/TripSearchResultPage/useTripResultsValues";


type CompareOffersModalProps = {}

const CompareOffersModal = (
    {}: CompareOffersModalProps
) => {

    const {
        state,
        onCompareModalApproveButton,
        toggleCompareModal
    } = useTripResultsValues()

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const {t} = useTranslation();

    const {addNotification} = useNotifications()

    const firstOfferEmpty = isEmpty(state?.compareOffers?.comparedOffers) ? true : isEmpty(state?.compareOffers?.comparedOffers[0])

    const secondOfferEmpty = isEmpty(state?.compareOffers?.comparedOffers) ? true : isEmpty(state?.compareOffers?.comparedOffers[1])

    const onButtonClick = (offerIndex: number, value: any) => {
        let selectedOffers = state?.compareOffers?.comparedOffers ?? []
        if (selectedOffers && isEmpty(selectedOffers[0]) && offerIndex === 1) {
            selectedOffers[0] = value
        } else {
            selectedOffers[offerIndex] = value
        }
        onCompareModalApproveButton(selectedOffers)
        addNotification({
            type: 'Success',
            title: t('lbl_added_to_compare'),
            description: `${state?.compareOffers?.newPotentialValue?.frontData?.name} ${t('lbl_added_to_compare_desc')}`
        })
    }

    const onClose = () => {
        toggleCompareModal(false)
    }

    useOnClickOutside(modalElement, onClose)

    return !!state?.compareOffers?.isModalOpen ? (
        <Modal
            width={700}
            ref={setModalElement}
            title={t('lbl_choose_compare_offer')}
            onClose={onClose}
            isOpen={!!state?.compareOffers?.isModalOpen}
        >
            <Styled.Modal__Content__Holder>
                <Styled.Section__Title>
                    {t('lbl_new_compare_offer')}
                </Styled.Section__Title>
                <Styled.New__Offer_Holder>
                    <Styled.New__Offer>
                        <Styled.New__Offer__Title>
                            {state?.compareOffers?.newPotentialValue.frontData?.name}
                        </Styled.New__Offer__Title>
                        <Styled.New__Object__Image
                            image={state?.compareOffers?.newPotentialValue.frontData?.firstImage}
                        >
                            {isEmpty(state?.compareOffers?.newPotentialValue.frontData?.firstImage) && (
                                <Styled.CameraIcon icon={faCameraSlash}/>
                            )}
                        </Styled.New__Object__Image>
                    </Styled.New__Offer>
                </Styled.New__Offer_Holder>

                <Styled.Old__Offers_Holder>
                    <Styled.Section__Title>
                        {t('lbl_old_compare_offers')}
                    </Styled.Section__Title>
                    <Styled.Offer__Title__Holder>
                        {Array.from({length: 2}).map((option: any, index: any) => {
                            const isEmptyOffer = index === 1 ? secondOfferEmpty : firstOfferEmpty
                            return (
                                <Styled.Offer_Title>
                                    {isEmptyOffer ? t('lbl_compare_offer_empty_slot') : state?.compareOffers?.comparedOffers ? state?.compareOffers?.comparedOffers[index]?.frontData?.name : ''}
                                </Styled.Offer_Title>
                            )
                        })}
                    </Styled.Offer__Title__Holder>
                    <Styled.Offer__Pictures__Holder>
                        {Array.from({length: 2}).map((option: any, index: any) => {

                            const image = state?.compareOffers?.comparedOffers ? state?.compareOffers?.comparedOffers[index]?.frontData?.firstImage : ''
                            const isEmptyOffer = index === 1 ? secondOfferEmpty : firstOfferEmpty
                            return (
                                <Styled.Offer__Picture
                                    image={image}
                                >
                                    {isEmpty(image) && !isEmptyOffer && (
                                        <Styled.CameraIcon icon={faCameraSlash}/>
                                    )}
                                    {isEmptyOffer && (
                                        <Styled.CameraIcon icon={faHotel}/>
                                    )}

                                </Styled.Offer__Picture>
                            );
                        })}
                    </Styled.Offer__Pictures__Holder>
                    <Styled.Offer__Buttons__Holder>
                        {Array.from({length: 2}).map((option: any, index: any) => {
                            const offerEmpty = index === 0 ? firstOfferEmpty : secondOfferEmpty
                            return (
                                <Styled.Offer__Button
                                    onClick={() => {
                                        onButtonClick(index, state?.compareOffers?.newPotentialValue)
                                    }}
                                    label={offerEmpty ? t('lbl_set_as_new_compare') : t('lbl_replace')}
                                />
                            )
                        })}
                    </Styled.Offer__Buttons__Holder>
                </Styled.Old__Offers_Holder>
            </Styled.Modal__Content__Holder>
        </Modal>
    ) : null
}

export default CompareOffersModal