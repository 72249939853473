import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";

const Favourite__Offers__Holder = styled(FlexBox)`
  flex-direction: column;
  width: calc(100% - 20px);
  padding: 0 10px;
  height: auto;
`
const Favourite__Offers__Loader__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: 40px auto;
`
const Section__Title = styled(Text)`
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
`
const Centered__Section__Title = styled(Text)`
  margin: 0px auto 15px auto;
  font-size: 20px;
  font-weight: bold;
`

const Empty__Offers__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: 40px auto;
  max-width: 600px;
`
const Section__Description = styled(Text)`
  text-align: center;
  font-size: 16px;
`
export const Styled = {
    Centered__Section__Title,
    Section__Description,
    Empty__Offers__Holder,
    Section__Title,
    Favourite__Offers__Holder,
    Favourite__Offers__Loader__Holder

}
