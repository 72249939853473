import {Styled} from "../../ContactPage.styles"
import QuickSupport from "./QuickSupport";
import TraditionalSupport from "./TraditionalSupport/TraditionalSupport";

const Support = () => {

    return (
        <Styled.Support__Holder>
            <QuickSupport/>
            <TraditionalSupport/>
        </Styled.Support__Holder>
    )
}
export default Support