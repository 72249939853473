import {Styled} from "./LocationModal.styles"
import Modal from "../../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import useOnClickOutside from "../../../lib/UseHooksBookBrd/useOnClickOutside";
import useConfigureSite from "../../../lib/UseHooksBookBrd/useConfigureSite";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import useStorage from "../../../lib/UseHooksBookBrd/useStorage";

const LocationModal = () => {

    const [isClosed, setIsClosed] = useStorage('sessionStorage', 'userLocationPermitted', true)

    const {userData} = useConfigureSite()

    const [locationModal, setLocationModal] = useState<HTMLDivElement | null>(null);

    const {t} = useTranslation();

    const onClose = () => {
        setIsClosed(true)
    }
    const onShow = () => {
        onClose()
        //TODO
        console.log('TODO ADD FUNCTIONALITY ')
    }

    useOnClickOutside(locationModal, onClose)

    return (
        <Modal
            title={t('lbl_location_modal_title')}
            width={400}
            ref={setLocationModal}
            isOpen={!isClosed}
            onClose={onClose}
        >
            <Styled.Content__Holder>
                <Styled.Explanation__Label>
                    {t('lbl_location_changed_explanation')}
                    {userData?.PreferredCountry}
                    {'!'}
                </Styled.Explanation__Label>
                <FlexBox
                    style={{
                        gap: '2px',
                        margin: "0 auto",
                    }}
                >
                    <Styled.Option__Button
                        onClick={onClose}
                        label={t('lbl_dismiss')}
                    />
                    <Styled.Option__Button
                        onClick={onShow}
                        label={`${t(`lbl_show_trip_from`)} ${userData?.PreferredCountry}`}
                    />

                </FlexBox>


            </Styled.Content__Holder>
        </Modal>
    )
}

export default LocationModal