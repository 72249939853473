import styled from "styled-components";
import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../../../../lib/UIBookBrd/Text";

const Order__Status__Holder = styled(FlexBox)`
  flex-direction: column;

`
const Approved__Order__Icon = styled(FontAwesomeIcon)`
  margin: 5px auto 5px auto;
  height: 47px;
  width: 47px;
  color: ${({theme}) => theme.isCheckedSwitchGreen};
`
const Declined__Order__Icon = styled(FontAwesomeIcon)`
  margin: 5px auto 5px auto;
  height: 47px;
  width: 47px;
  color: ${({theme}) => theme.errorMessageIconColor};
`
const Not__Found__Order__Icon = styled(FontAwesomeIcon)`
  margin: 5px auto 5px auto;
  height: 47px;
  width: 47px;
  color: ${({theme}) => theme.color};
`

const Order__Status__Label = styled(Text)`
  font-size: 16px;
  margin: 20px auto auto auto;
`
const Buttons__Holder = styled(FlexBox)`
  margin: 30px auto 0 auto;
  gap: 5px 8px;
  flex-direction: initial;
  align-content: center;
`
const Confirmation__Details = styled(FlexBox)`
  gap: 12px;
  margin: 30px auto 0 auto;
  flex-direction: column;
`
const Confirmation__Detail = styled(FlexBox)`
  flex-wrap: nowrap;
`
const Order__Details__Icon = styled(FontAwesomeIcon)<{ isConfirmed: boolean }>`
  width: 24px;
  height: 24px;
  color: ${({theme, isConfirmed}) => isConfirmed ? theme.isCheckedSwitchGreen : theme.color};
  margin-right: 8px;
`
const Order__Details__Label = styled(Text)<{ isConfirmed: boolean }>`
  font-size: 18px;
  font-weight: ${({isConfirmed}) => isConfirmed ? 'bold' : 'normal'};
`
const InformationIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  text-align: right;
  margin-bottom: auto;
  color: ${(props: { theme: any; }) => props.theme.circleInfoColor};
  height: 13px;
  width: 13px;
`

export const Styled = {
    InformationIcon,
    Order__Details__Label,
    Order__Details__Icon,
    Confirmation__Detail,
    Confirmation__Details,
    Not__Found__Order__Icon,
    Declined__Order__Icon,
    Buttons__Holder,
    Order__Status__Label,
    Approved__Order__Icon,
    Order__Status__Holder
}