import {Trip_Offers} from "../../../../lib/Context/Context";
import {useState} from "react";

type TripOfferContextProps = {
    children: React.ReactElement
}

const TripOfferContext = (
    {
        children
    }: TripOfferContextProps
) => {


    const [state, setState] = useState({})

    return (
        <Trip_Offers.Provider value={[state, setState]}>
            {children}
        </Trip_Offers.Provider>
    )
}

export default TripOfferContext