import {promiseRequest} from "../RequestBuilder";
import {
    BOARD_FIELD,
    DATE_RANGE_FIELD,
    DURATION_FIELD,
    PARTICIPANTS_FIELD,
    PRICE_FIELD,
    RATING_FIELD,
    TO_FIELD,
    TRANSPORT_FIELD,
    XCITY_FIELD,
    XCODE_FIELD
} from "../DefaultFormikValue/DefaultSearchFormValues";
import {includes, isEmpty, toNumber} from "lodash-es";
import moment from "moment";
import {OFFER_LIST_V5_FIELD_LIST} from "../Configs/V5requestConfigs";
import {getTransportForV5} from "../../Pages/TripSearch/TripSearchResultPage/TripSearchFunctions";
import {V5_SEARCH} from "../Configs/RequestConfig";
import useHandleOnlineRequestError from "./useHandleOnlineRequestError";

export  type viewTypes =
    'OfferList'
    | 'SimilarOffers'
    | 'OfferOtherDates'
    | "OfferOtherRooms"
    | "OfferOtherBoard"
    | "OfferOtherOperator"
    | 'OfferOtherDuration'
    | "OfferOtherOutBound"

const getParticipantsV5Format = (value: any) => {
    const newFormatList: any[] = []

    const numberOfAdults = toNumber(value.adults.value)

    const numberOfChild = toNumber(value.kids.value)
    //Adding Adults to list
    Array.from({length: numberOfAdults}).map(() => {
        newFormatList.push({"code": "ADULT", "roomIndex": 0})
    })
    //Adding Kids to list 
    Array.from({length: numberOfChild}).map((_: any, index: any) => {
        const date = moment(value.kids.dates[index], 'DD.MM.YYYY').format('YYYY-MM-DD')
        newFormatList.push({"code": "CHILD", "roomIndex": 0, "birthdate": date})
    })

    return newFormatList
}

const getBaseValue = (searchFormValue: any, filterFormValue: any) => {

    const participantsV5Format = getParticipantsV5Format(searchFormValue[PARTICIPANTS_FIELD])

    const startDate = moment(searchFormValue[DATE_RANGE_FIELD].startDate, 'DD.MM.YYYY').format('YYYY-MM-DD')

    const endDate = moment(searchFormValue[DATE_RANGE_FIELD].endDate, 'DD.MM.YYYY').format('YYYY-MM-DD')

    const PriceRequ = {
        Min: !isEmpty(filterFormValue[PRICE_FIELD]) ? filterFormValue[PRICE_FIELD][0] ?? 0 : 0,
        Max: !isEmpty(filterFormValue[PRICE_FIELD]) ? filterFormValue[PRICE_FIELD][1] ?? 10000 : 0
    }
    if (filterFormValue[PRICE_FIELD] && filterFormValue[PRICE_FIELD][1] === 0) {
        delete PriceRequ.Max
    }
    if (filterFormValue[PRICE_FIELD] && filterFormValue[PRICE_FIELD][1] === 10000) {
        delete PriceRequ.Max
    }


    let BaseValue: any = {
        "ParticipantsList": participantsV5Format,
        "Availability": [
            "available",
        ],
        "Price": {
            "FirstPerson": PriceRequ
        },
        ReturnDate: {Before: endDate},
        "StartDate": {
            "After": startDate,
            "Before": endDate
        },
        "Currency": "PLN",
        "ComponentsCombinations": [
            [
                ...getTransportForV5(!!filterFormValue ? filterFormValue[TRANSPORT_FIELD] : {}),
                "accommodation.*"
            ]
        ],
        "Operator": [
            "ALMA",
            "ATLA",
            "ATLH",
            "ATLR",
            "BUTR",
            "BVPR",
            "CKVI",
            "CLGO",
            "CORE",
            "CTPO",
            "CTRV",
            "DER",
            "DERD",
            "DERP",
            "DERW",
            "EPTR",
            "ETI",
            "ETRV",
            "FIT",
            "FNCB",
            "GRCS",
            "BVBE",
            "BVBX",
            "ECCO",
            "ECCX",
            "ECTR",
            "EXIM",
            "EXIF",
            "ITAK",
            "VITX",
            "VITG",
            "VITN",
            "VITD",
            "TRAD",
            "TRAR",
            "VXEM",
            "EMWP",
            "VYOB",
            "XYOB",
            "WEZY",
            "WEZX",
            "ICC",
            "JAWR",
            "JOAT",
            "JOIN",
            "KARP",
            "KOBP",
            "LULU",
            "MERT",
            "NKRA",
            "OASI",
            "OCH",
            "OKTU",
            "ONHO",
            "OREX",
            "ORKA",
            "ORTR",
            "OSKA",
            "OSKR",
            "PLES",
            "PNE",
            "PRTS",
            "PRTW",
            "PWAK",
            "REGO",
            "RNBW",
            "ROTR",
            "SEVA",
            "SFPL",
            "SKIS",
            "SNOW",
            "SYAL",
            "SYAT",
            "SYIN",
            "SYKM",
            "SYLO",
            "SYRG",
            "SYTP",
            "TETO",
            "UNQM",
            "VELT",
            "VMAT",
            "VMRO",
            "VOTI",
            "VSKI",
            "VTCD",
            "VTLE",
            "VTLO",
            "VTTC",
            "VTTD",
            "VTTG",
            "VTTH",
            "VTTL",
            "VTTN",
            "VTTX",
            "WETR",
            "XTOP"
        ]
    }

    Object.keys(searchFormValue).map((name: any) => {
        const fieldValue = searchFormValue[name]
        switch (name) {
            case XCITY_FIELD:
                BaseValue['XCity'] = fieldValue
                break
            case TO_FIELD:
                if (!isEmpty(fieldValue)) {
                    BaseValue['DestinationLocation'] = {"Id": fieldValue}
                }
                break
            case DURATION_FIELD:
                if (fieldValue.min !== 0 && fieldValue.max !== 0) {
                    BaseValue['NightsBeforeReturn'] = fieldValue
                }
                break
        }
    })


    return BaseValue
}

const getAccommodationValue = (filterFormValue: any, searchFormValue: any) => {

    let newAccommodationObject: any = {}

    const validFields = Object.keys(filterFormValue).filter((item: string) => {
        const value = filterFormValue[item]
        return !isEmpty(value)
    })
    validFields?.map((item: string) => {
        switch (item) {
            case XCITY_FIELD:
                newAccommodationObject['XCode'] = filterFormValue[item]
                break
            case BOARD_FIELD:
                newAccommodationObject['XService'] = filterFormValue[item]
                break
            case RATING_FIELD:
                newAccommodationObject['Category'] = filterFormValue[item]
                break
        }
    })
    Object.keys(searchFormValue).map((name: any) => {
        const fieldValue = searchFormValue[name]
        switch (name) {
            case XCODE_FIELD:
                if (!isEmpty(fieldValue)) {
                    newAccommodationObject['XCode'] = fieldValue.map((item: any) => toNumber(item))
                }
                break
        }
    })

    return newAccommodationObject
}

const getFilters = (requestType: viewTypes, additionalQuery: any) => {
    switch (requestType) {
        case "SimilarOffers":
            return {
                Accommodation: {
                    XCode: [additionalQuery]
                }
            }
        case 'OfferOtherDates':
            return {
                Accommodation: {
                    XCode: [additionalQuery?.Base?.XCode?.Id],
                    Room: additionalQuery?.Accommodation?.Room.Id,
                    XService: [additionalQuery?.Accommodation?.XService?.Id]
                },
                Base: {
                    DepartureLocation: {
                        Id: [additionalQuery?.Base?.DepartureLocation?.Id]
                    },
                    NightsBeforeReturn: {
                        min: additionalQuery?.Base?.NightsBeforeReturn,
                        max: additionalQuery?.Base?.NightsBeforeReturn
                    },
                    // Operator: [additionalQuery?.Base?.Operator]
                }
            }
        case "OfferOtherRooms":
            return {
                Accommodation: {
                    XCode: [additionalQuery?.Base?.XCode?.Id],
                    XService: [additionalQuery?.Accommodation?.XService?.Id]
                },
                Base: {
                    DepartureLocation: {
                        Id: [additionalQuery?.Base?.DepartureLocation?.Id]
                    },
                    NightsBeforeReturn: {
                        min: additionalQuery?.Base?.NightsBeforeReturn,
                        max: additionalQuery?.Base?.NightsBeforeReturn
                    },
                    //  Operator: [additionalQuery?.Base?.Operator],
                    StartDate: {
                        After: additionalQuery?.Base?.StartDate,
                        Before: additionalQuery?.Base?.StartDate
                    }
                }
            }
        case "OfferOtherBoard":
            return {
                Accommodation: {
                    XCode: [additionalQuery?.Base?.XCode?.Id],
                    Room: additionalQuery?.Accommodation?.Room.Id,
                },
                Base: {
                    DepartureLocation: {
                        Id: [additionalQuery?.Base?.DepartureLocation?.Id]
                    },
                    NightsBeforeReturn: {
                        min: additionalQuery?.Base?.NightsBeforeReturn,
                        max: additionalQuery?.Base?.NightsBeforeReturns
                    },
                    //  Operator: [additionalQuery?.Base?.Operator],
                    StartDate: {
                        After: additionalQuery?.Base?.StartDate,
                        Before: additionalQuery?.Base?.StartDate
                    }
                }
            }
        case "OfferOtherOperator":
            return {
                Accommodation: {
                    XCode: [additionalQuery?.Base?.XCode?.Id],
                    XService: [additionalQuery?.Accommodation?.XService?.Id],
                    Room: additionalQuery?.Accommodation?.Room.Id,
                },
                Base: {
                    DepartureLocation: {
                        Id: [additionalQuery?.Base?.DepartureLocation?.Id]
                    },
                    NightsBeforeReturn: {
                        min: additionalQuery?.Base?.NightsBeforeReturn,
                        max: additionalQuery?.Base?.NightsBeforeReturns
                    },
                    StartDate: {
                        After: additionalQuery?.Base?.StartDate,
                        Before: additionalQuery?.Base?.StartDate
                    }
                }
            }
        case"OfferOtherDuration":
            return {
                Accommodation: {
                    XCode: [additionalQuery?.Base?.XCode?.Id],
                    XService: [additionalQuery?.Accommodation?.XService?.Id],
                    Room: additionalQuery?.Accommodation?.Room.Id,
                },
                Base: {
                    ///Operator: [additionalQuery?.Base?.Operator],
                    DepartureLocation: {
                        Id: [additionalQuery?.Base?.DepartureLocation?.Id]
                    },
                    StartDate: {
                        After: additionalQuery?.Base?.StartDate,
                        Before: additionalQuery?.Base?.StartDate
                    }
                }
            }
        case "OfferOtherOutBound":
            return {
                Accommodation: {
                    XCode: [additionalQuery?.Base?.XCode?.Id],
                    XService: [additionalQuery?.Accommodation?.XService?.Id],
                    Room: additionalQuery?.Accommodation?.Room.Id,
                },
                Base: {
                    //  Operator: [additionalQuery?.Base?.Operator],
                    NightsBeforeReturn: {
                        min: additionalQuery?.Base?.NightsBeforeReturn,
                        max: additionalQuery?.Base?.NightsBeforeReturn
                    },
                    StartDate: {
                        After: additionalQuery?.Base?.StartDate,
                        Before: additionalQuery?.Base?.StartDate
                    }
                }
            }

    }


}

const getConditions = (searchFormValue: any, filterFormValue: any, requestType: viewTypes, additionalQuery: any) => {

    const baseValue = getBaseValue(searchFormValue, filterFormValue)

    const accommodationValue = getAccommodationValue(filterFormValue, searchFormValue)

    const filter: any = getFilters(requestType, additionalQuery)

    let conditionsObject: any = {}

    let searchObject: any = {}

    if (!isEmpty(baseValue)) {
        searchObject['Base'] = baseValue
    }

    if (!isEmpty(accommodationValue)) {
        searchObject['Accommodation'] = accommodationValue
    }

    if (!isEmpty(searchObject)) {
        conditionsObject['search'] = searchObject
    }
    if (!isEmpty(filter)) {
        conditionsObject['filter'] = filter
    }

    return conditionsObject
}

const getGroupBy = (requestType: viewTypes) => {
    switch (requestType) {
        case "OfferOtherDates":
            return {
                key: "Base.StartDate",
                value: ""
            }
        default:
            return {
                key: "Accommodation.XCode",
                // value: ""
            }
    }
}

const useV5OfferListRequest = (searchFormValue: any, filterFormValue: any) => {

    const {getV5Error} = useHandleOnlineRequestError()

    const Send_V5_List_Request = async (setIsLoading: any, setOptionList: any, requestType: viewTypes, additionalQuery?: any, listType?: viewTypes, optionList?: any) => {
        const isSimilarOffers = listType === 'SimilarOffers'

        const pageBookMark = optionList?.pageBookmark ? optionList?.pageBookmark : ''

        let viewsValue: any = {
            [`${isSimilarOffers ? 'offerList' : 'groupedList'}`]: {
                "limit": 30,
                "fieldList": OFFER_LIST_V5_FIELD_LIST,
                "orderBy": ["Base.Price.FirstPerson"]
            }
        }
        if (!isEmpty(pageBookMark)) {
            viewsValue[isSimilarOffers ? 'offerList' : 'groupedList'].previousPageBookmark = pageBookMark
        }
        try {
            setIsLoading(true)
            setOptionList((state: any) => ({
                ...state,
                errorData: {}
            }))
            const request = await promiseRequest(V5_SEARCH, {
                conditions: getConditions(searchFormValue, filterFormValue, requestType, additionalQuery),
                results: {
                    "groupBy": {...getGroupBy(requestType)},
                },
                views: viewsValue
            }, 2);

            const data = isSimilarOffers ? request.offerList : request.groupedList;

            if (isEmpty(optionList)) {
                let newValue: any = data
                const newList = Object.keys(data.items).map((code: string) => data.items[code])
                if (!newValue.more) {
                    newList.push({isFinished: true})
                }
                newValue.items = newList
                setOptionList(newValue)
            } else {
                let newValue: any = optionList
                const list: any = Object.keys(newValue.items).map((code: string) => newValue.items[code])
                Object.keys(data.items).map((code: string) => data.items[code]).map((item: any) => list.push(item))
                if (!newValue.more) {
                    list.push({isFinished: true})
                }
                newValue.items = list
                newValue.pageBookmark = data.pageBookmark
                newValue.more = data.more
                newValue.sortKeyOrderAscending = data.sortKeyOrderAscending
                setOptionList(newValue)
            }


        } catch (e: any) {
            setOptionList((state: any) => ({
                ...state,
                errorData: {
                    errorWithRequest: true,
                    errorMessage: getV5Error(e)
                }
            }))
        } finally {
            setIsLoading(false)
        }
    }

    const getPreparedList = (
        request: any,
        requestType: viewTypes,
        oldOffer: any,
        createOptionComponent: (mainOffer: any, newOffer: any, requestType: viewTypes) => any,
    ) => {
        const {groupedList} = request
        if (!isEmpty(groupedList)) {

            const {items} = groupedList
            const newList: any[] = []
            Object.keys(items)?.map((name: string) => {
                const option = items[name]
                const {offer} = option
                newList.push({
                    label: createOptionComponent(oldOffer, offer, requestType),
                    isCustom: true,
                    value: offer.Base.OfferId,
                    offer: offer
                })
            })
            return newList
        }
    }

    const getDetailsParams = async (
        offer: any,
        requestType: viewTypes,
        setBookingControls: any,
        bookingControls: any,
        createOptionComponent: (mainOffer: any, newOffer: any, requestType: viewTypes) => void,
        setLoadingSelects: any
    ) => {
        try {
            const request = await promiseRequest(V5_SEARCH, {
                conditions: getConditions(searchFormValue, filterFormValue, requestType, offer),
                results: {
                    "groupBy": {...getGroupBy(requestType)},
                },
                views: {
                    groupedList: {
                        "limit": 30,
                        "fieldList": OFFER_LIST_V5_FIELD_LIST,

                    }
                }
            }, 3);
            const list: any[] = []
            const value = [...getPreparedList(request, requestType, offer, createOptionComponent) as any[], ...bookingControls[requestType]?.list].filter((item: any) => {
                const id = item.value
                if (includes(list, id)) {
                    return false
                }
                list.push(id)
                return true
            })
            setBookingControls((state: any) => ({
                ...state,
                [requestType]: {
                    list: value
                }
            }))
        } catch (e: any) {
            console.log({e})
        } finally {
            setLoadingSelects((state: any) => ({
                ...state,
                [requestType]: false
            }))
        }
    }


    return (
        {
            getDetailsParams,
            SendRequestForOfferList: Send_V5_List_Request,

        }
    )

}

export default useV5OfferListRequest