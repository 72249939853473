const comparedOffersStorage = 'CompareOffers'
const comparedOffers = sessionStorage.getItem(comparedOffersStorage) ? JSON.parse(sessionStorage.getItem(comparedOffersStorage) as string) : []


const compareOffersDefaultValues = {
    comparedOffers,
    data: {
        offerComparison: {
            sectionLabel: 'lbl_offer_comparison',
            sectionDescription: 'lbl_desc_compare_offers',
            offers: {
                hotelImage: [
                    [],
                    []
                ]
            }
        },
        overview: {
            sectionLabel: 'lbl_offer_comparison',
            sectionDescription: 'lbl_desc_compare_offers',
            offers: {
                hotelImage: [
                    [],
                    []
                ]
            }
        },
        AccommodationDetails: {
            sectionLabel: 'lbl_offer_comparison',
            sectionDescription: 'lbl_desc_compare_offers',
            offers:
//Array of Offers
                [
                    // First Offer
                    // Array of sections
                    [],
// Second Offer
// Array of sections
                []
            ]
        },
        FlightDetails: {
            sectionLabel: 'lbl_offer_comparison',
            sectionDescription: 'lbl_desc_compare_offers',
            offers:
//Array of Offers
                [
                    // First Offer
                    // Array of sections
                    [],
// Second Offer
// Array of sections
                []
            ]
        },
    },
    isModalOpen: false,
    isOpen: false,
}

const defaultTripList = {
    itemsHeight: [],
    imagesControl: [],
    openedList: []
}

const pageDefaultContext = {
    isLoading: true,
    innerHeight: window?.innerHeight - 57,
    filters: {},
    similarOffers: {
        isLoadingMore: false
    },
    tripList: defaultTripList,
    compareOffers: compareOffersDefaultValues
}


const Filters_Drawer_Id = 'filters_drawer'

export {
    Filters_Drawer_Id,
    pageDefaultContext,
    comparedOffersStorage
}