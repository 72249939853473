import {Styled} from "../../ManageAccountPage.styles";
import TextInput from "../../../../lib/UIBookBrd/TextInput";
import {useTranslation} from "react-i18next";
import DropFile from "../../../../lib/UIBookBrd/DropFile";
import {dropFileType} from "../../../../lib/Types";
import {useState} from "react";

type InputsForImagesProps = {
    value: string
    onClear: () => void
    onChange: (event: any) => void
}

const InputsForImages = (
    {
        value,
        //onChange,
        onClear
    }: InputsForImagesProps
) => {

    const {t} = useTranslation()


    const [file, setFile] = useState<any>({})

    const onChange = (readyValue: any) => {
        setFile(readyValue)
    }

    return (
        <Styled.Inputs__Type__Separator>
            <Styled.Input__Title>
                {t("lbl_image_link")}
            </Styled.Input__Title>
            <TextInput
                showIcon={false}
                isClearAble={true}
                placeholder={t('lbl_image_link')}
                value={{
                    label: value ?? '',
                    value: value ?? '',
                }}
                onClear={onClear}
                onChange={onChange}
            />
            <Styled.Separator__Holder>
                <Styled.Separator__Line/>
                <Styled.Separator__Label>
                    {t('lbl_or')}
                </Styled.Separator__Label>
                <Styled.Separator__Line/>
            </Styled.Separator__Holder>
            <DropFile
                value={file}
                onChange={onChange}
                types={[dropFileType.png, dropFileType.gif, dropFileType.jpg]}
            />
        </Styled.Inputs__Type__Separator>
    )
}

export default InputsForImages