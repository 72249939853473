import {Styled} from "../../App.styles";
import BrdLoader from "../../lib/UIBookBrd/BrdLoader";
import React from "react";


type LoadingScreenProps = {
    isLoading: boolean
}

const LoadingScreen = ({
                           isLoading
                       }: LoadingScreenProps) => {

    return (
        <>
            {isLoading && (
                <Styled.LoadingScreen>
                    <Styled.LoaderHolder>
                        <BrdLoader style={{height: '100%'}} isbig={true}/>
                    </Styled.LoaderHolder>
                </Styled.LoadingScreen>
            )}
        </>
    )
}
export default LoadingScreen