import {Booking_Page, Search_Proto} from "../../../lib/Context/Context";
import {useContext, useState} from "react";
import {getParticipantsFromHash} from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {isEmpty, isString} from "lodash-es";

type ContextProvider = {
    children: React.ReactElement
}

const getInitialValueForParticipants = (participant: any, offerId: string) => {
    const storageValue = sessionStorage.getItem('BookingProvidedValues')


    if (!isEmpty(storageValue) && isString(storageValue) && JSON.parse(storageValue).offerId === offerId) {

        return {participantsSection: JSON.parse(storageValue)}
    }

    const newObject: any = {
        'Payment_0': {
            'name': '',
            'surname': '',
            'mail': '',
            'country': '',
            'city': '',
            'address': '',
            'postCode': "",
            "cvc": "",
            "cardNumber": "",
            "cardDate": "",
            "cardHolder": ""
        }
    }
    const {
        adultsCount,
        childDates
    } = participant
    Array.from({length: adultsCount}).map((_: any, index: number) => {
        newObject[`Adult_${index}`] = {
            'name': '', 'surname': '', 'mail': '', 'country': '', 'city': '', 'address': '', 'postCode': ""
        }
    })
    childDates?.map((_: any, index: any) => {
        newObject[`Child_${index}`] = {
            'name': '', 'surname': '', 'country': '', 'address': '', 'postCode': '', 'city': ''
        }

    })
    return {participantsSection: newObject}


}

const ContextProvider = (
    {
        children
    }: ContextProvider
) => {
    const [protoValue, _] = useContext<any>(Search_Proto)

    const {offerDetails} = protoValue


    const participants = getParticipantsFromHash(offerDetails ?? undefined)

    const [state, setState] = useState<any>(getInitialValueForParticipants(participants, offerDetails))

    return (
        <Booking_Page.Provider value={[state, setState]}>
            {children}
        </Booking_Page.Provider>
    )

}
export default ContextProvider