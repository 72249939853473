import Map from "../../../../../lib/UIBookBrd/Map";
import {useTranslation} from "react-i18next";
import {Styled} from "../../TripOffersDetails.styles";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import BrdLoader from "../../../../../lib/UIBookBrd/BrdLoader";
import {createPortal} from "react-dom";
import {useEffect} from "react";
import useLocalData from "../../../../../lib/UseHooksBookBrd/useSearchBaseData";
import Text from "../../../../../lib/UIBookBrd/Text";

type CustomMapProps = {
    offer: any,
    onCloseMap: () => void,
    isMapOpen: boolean,

}

const CustomMap = (
    {
        offer,
        onCloseMap,
        isMapOpen,
    }: CustomMapProps
) => {

    const {toggleDisableScroll} = useLocalData()
    const {t} = useTranslation();


    useEffect(() => {
        toggleDisableScroll(isMapOpen)
    }, [isMapOpen])

    const closeMapBigButtonContent = (
        <Styled.Close__Map__Button__Large__Screen__Content>
            <Text
                style={{
                    color: 'inherit',
                    transition: '0ms'
                }}
            >
                {t('lbl_close_map')}
            </Text>
            <Styled.Close__Map__Icon__Big__Button
                icon={faTimes}
            />
        </Styled.Close__Map__Button__Large__Screen__Content>
    )

    return (
        <>
            {isMapOpen && createPortal(
                <Styled.Map__Full__Screen_Holder>
                    <Styled.Close__Map__Button__Large__Screen
                        buttonType={'custom'}
                        label={closeMapBigButtonContent}
                        onClick={onCloseMap}
                    />
                    <Styled.Close__Map__Button__Small_Screen
                        buttonType={'icon'}
                        label={faTimes}
                        onClick={onCloseMap}
                    />
                    <BrdLoader style={{
                        margin: "0 auto auto auto"
                    }}
                    />
                    {offer && (
                        <Map
                            styles={{top: '0px', bottom: '0px', right: '0px', left: '0px', position: 'absolute'}}
                            locations={[{
                                lat: offer?.Accommodation?.Location?.Coords[0] ?? 0,
                                lng: offer?.Accommodation?.Location?.Coords[1] ?? 0
                            }]}
                        />
                    )}
                </Styled.Map__Full__Screen_Holder>, document.querySelector('body') as HTMLElement
            )}
        </>
    )
}
export default CustomMap