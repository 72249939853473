import {Styled} from "../TripSearchBookingPage";
import TripOffer from "../../TripSearchResultPage/Content/TripOfferList/TripOffer";
import SkeletonOffer from "../../TripSearchResultPage/Content/TripOfferList/TripOffer/SkeletonOffer/SkeletonOffer";
import useBookingFunctions from "../functions";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import useHandleOnlineRequestError from "../../../../lib/UseHooksBookBrd/useHandleOnlineRequestError";
import Button from "../../../../lib/UIBookBrd/Button";
import FlexBox from "../../../../lib/UIBookBrd/FlexBox";

const ChosenOffer = () => {

    const [offer, setOffer] = useState<any>({isLoading: true})

    let requestSent = false

    const {getTitleForRequests, getDescriptionForRequests} = useHandleOnlineRequestError()

    const {
        getDetailsAndPictures,
    } = useBookingFunctions()

    const {t} = useTranslation();


    useEffect(() => {
        if (!requestSent) {
            getDetailsAndPictures(setOffer)
            requestSent = true
        }
    }, [])


    const getContent = () => {
        if (offer?.isLoading) {
            return (
                <SkeletonOffer
                    disableShadow={true}
                />
            )
        }
        if (offer?.offer) {
            return (
                <TripOffer
                    isDisabled={true}
                    id={0}
                    openFullImages={() => {
                    }}
                    selectedImage={0}
                    pictures={offer?.pictures}
                    navigateImagesHandler={() => {
                    }}
                    onOpenOffer={() => {
                    }}
                    onCloseOffer={() => {
                    }}
                    isOpen={false}
                    index={0}
                    SimilarOffersHandler={() => {
                    }}
                    compareOffersHandler={() => {
                    }}
                    offer={offer?.offer}
                />
            )
        }
        if (offer?.error) {
            return (
                <Styled.Loading__Offer__Error>
                    <Styled.Loading__Offer__Error__Title>
                        {getTitleForRequests(offer.error)}
                    </Styled.Loading__Offer__Error__Title>
                    <Styled.Loading__Offer__Error__Desc>
                        {getDescriptionForRequests(offer.error)}
                    </Styled.Loading__Offer__Error__Desc>
                    <Button
                        onClick={() => {
                            getDetailsAndPictures(setOffer)
                        }}
                        label={t('lbl_retry')}
                        style={{
                            minWidth: '125px',
                            margin: '20px auto auto auto'
                        }}
                    />
                </Styled.Loading__Offer__Error>
            )
        }
        return (
            <FlexBox style={{
                height: '150px',
                width: '100%'
            }}>
                <Styled.No__Offer__Found>
                    {t('lbl_no_offer_found_details')}
                </Styled.No__Offer__Found>
            </FlexBox>
        )
    }


    return (
        <Styled.Offer__Holder>
            {getContent()}
        </Styled.Offer__Holder>
    )
}
export default ChosenOffer