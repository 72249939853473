import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Button from "../../../lib/UIBookBrd/Button";
import {isMobileOnly} from "react-device-detect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../../lib/UIBookBrd/Text";

const BackgroundForResultPage = styled(FlexBox)`
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 57px;
  bottom: 0px;
  transition: background 500ms;
  max-width: 1500px;
  margin: 0 auto;
  background-image: ${isMobileOnly ? (({theme}) => theme.isDark ? `url("https://media.tacdn.com/media/attractions-splice-spp-674x446/06/70/1e/d7.jpg")` : `url("https://media.cnn.com/api/v1/images/stellar/prod/230324090551-01-visiting-france-during-protests-what-to-know-top.jpg?c=16x9&q=h_720,w_1280,c_fill")`) : ({theme}) => theme.isDark ? `url("https://www.spain.info/export/sites/segtur/.content/imagenes/cabeceras-grandes/madrid/calle-gran-via-madrid-s333961043.jpg_604889389.jpg")` : `url("https://www.travelandleisure.com/thmb/l4gMoI0BOszC3BZx2q1LYr3N3rE=/1600x1200/smart/filters:no_upscale()/madrid-spain-MADRIDTG0621-b2347a98186a4281a0874992b213ade0.jpg")`};;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: 1500px) {
    box-shadow: 25px 0 15px -8px ${(props: { theme: any; }) => props.theme.backgroundColor} inset, -25px 0 15px -8px ${(props: { theme: any; }) => props.theme.backgroundColor} inset;
  }

`
const TripSearchResults = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  max-height: calc(100vh - 30px - ${isMobileOnly ? '90px' : '75px'});
  bottom: 0px;
  padding: 0;

`
const HorizontalButton = styled(Button)`
  margin-top: 5px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 5px;

  &:first-of-type {
    min-width: 120px;
    margin-left: auto;
  }

`
const PageNumberButtonsHolder = styled(FlexBox)`
  flex-wrap: nowrap;
`
const NextPageButton = styled(Button)`
  border: 0;
  min-width: 35px;
  max-width: 35px;
  height: 40px;
  border-radius: 0;
`
const PageNumberButton = styled(Button)`
  margin: 0;
  border: ${(props: { theme: any; isHighLighted: any }) => props.isHighLighted ? undefined : 0};;
  border-radius: 0;
  min-width: 35px;
  max-width: 35px;
  height: 40px;

`
const NavigationHolder = styled(FlexBox)`
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
`
const NavigateOfferListBar = styled(FlexBox)`
  height: 40px;
  overflow: hidden;
  margin: 0 auto;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid white;
  border-bottom: unset;
  background: ${(props: { theme: any; }) => props.theme.backgroundColor};;
  flex-wrap: nowrap;
`
const HorizontalButtonsHolder = styled(FlexBox)`
`
const ChevronIcon = styled(FontAwesomeIcon)`
`
const StarIcon = styled(FontAwesomeIcon)`
  color: ${(props: { theme: any; }) => props.theme.ratingStarColor};
`
const ToolTip__Content__Rating = styled(FlexBox)`
  padding: 5px;
`
const Custom__Flight__Info__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-direction: column;
`
const Type__Label = styled(Text)`
  padding: 2px 0;
  font-size: 14px;

`
const CustomText = styled(FlexBox)`
  max-width: max-content;
  flex-wrap: wrap;
  margin: 2px 0;
`
const TransportIcon = styled(FontAwesomeIcon)`
  color: ${(props: { theme: any; }) => props.theme.color};
  margin-right: 5px;
  width: 17px;
  height: 14px;
`
const IATAText = styled(Text)`
  font-size: 14px;
  font-weight: bold;
`
const DatesText = styled(Text)`
  margin-right: 2px;
  margin-left: 2px;
  white-space: pre-wrap;
  margin-top: auto;
`
const ToolTip__Content__Transport = styled(FlexBox)`
  flex-wrap: nowrap;
`
const ToolTip__Content__Part__Holder = styled(FlexBox)`
  padding: 5px;
  flex-direction: column;
`
const ToolTip__Content__Line__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
`
const ToolTip__Content__Type__Label = styled(Text)`
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2px;
`
const ToolTip__Content__Label = styled(Text)`
  margin-left: 5px;
  font-size: 12px;

`
const Error__Desc = styled(Text)`
  text-align: center;
  padding: 10px 5px 10px 5px;
  font-weight: bold;
`
const Error__Button = styled(Button)`
  height: 34px;
  width: ${isMobileOnly ? '100%' : undefined}
`
const Fix__Error__Button = styled(Button)`
  height: 34px;
  width: ${isMobileOnly ? '100%' : undefined}
`
const Error__Desc__Details = styled(Text)`
  padding: 5px 5px;
`
const Buttons__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: ${isMobileOnly ? '5px' : '10px'};
  align-content: center;
  align-items: center;
  width: ${isMobileOnly ? 'calc(100% - 10px)' : undefined};
  flex-direction: ${isMobileOnly ? 'column' : undefined};
`
const InformationIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  text-align: right;
  margin-bottom: auto;
  color: ${(props: { theme: any; }) => props.theme.circleInfoColor};
  font-size: 10px;
`
export const Styled = {
    ChevronIcon,
    HorizontalButtonsHolder,
    NavigationHolder,
    NavigateOfferListBar,
    PageNumberButtonsHolder,
    NextPageButton,
    PageNumberButton,
    HorizontalButton,
    BackgroundForResultPage,
    TripSearchResults,
    StarIcon,
    ToolTip__Content__Rating,
    Custom__Flight__Info__Holder,
    Type__Label,
    CustomText,
    TransportIcon,
    IATAText,
    DatesText,
    ToolTip__Content__Transport,
    ToolTip__Content__Part__Holder,
    ToolTip__Content__Line__Holder,
    ToolTip__Content__Type__Label,
    ToolTip__Content__Label,
    Error__Desc,
    Error__Button,
    Fix__Error__Button,
    Error__Desc__Details,
    Buttons__Holder,
    InformationIcon

}