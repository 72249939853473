import useSearchFormValues, {GET_VALID_SUGGESTED_DATES, searchFormValidation} from "./useSearchFormValues";
import {DATE_RANGE_FIELD, TO_FIELD, XCITY_FIELD, XCODE_FIELD} from "../DefaultFormikValue/DefaultSearchFormValues";
import {SECOND_STEP_TRIP_SEARCH} from "../Routes";
import {includes, isArray, isEmpty, uniq} from "lodash-es";
import useFilterFormValue from "./useFilterFormValue";
import useGoogleAnalitics from "../GoogleAnalytics/useGoogleAnalitics";
import useStorage from "./useStorage";
import useBookBrdPageFunctions from "./useBookBrdPageFunctions";
import useNavigation from "./useNavigation";

const useTripHandler = () => {

    const {selectedLanguage} = useBookBrdPageFunctions()

    const [offersWithLabels, _] = useStorage<any>('sessionStorage', 'offersWithLabels', {language: selectedLanguage})

    const [searchedValues, setSearchedValues] = useStorage<any>('localStorage', 'trp_src_values', {})

    const {clearAllValues} = useFilterFormValue()

    const {sendToAnalytics} = useGoogleAnalitics()

    const {searchFormValue, setSearchFormValue} = useSearchFormValues()

    const navigate = useNavigation()


    const addToSearched = (value: any) => {
        let newValue = searchedValues

        const list = isArray(newValue?.offerList) ? newValue?.offerList : []
        const labelsList = isArray(newValue?.list) ? newValue?.list : []
        const flatLabelsList = labelsList?.map((item: any) => item.value)
        if (!isEmpty(value[XCITY_FIELD]) && !includes(flatLabelsList, value[XCITY_FIELD])) {
            const parsedValue = offersWithLabels?.list?.filter((item: any) => item.value === value[XCITY_FIELD])[0]
            labelsList.push(parsedValue)
        }
        if (!isEmpty(value[XCODE_FIELD])) {
            const validOffers = value[XCODE_FIELD]?.filter((xCode: string) => !includes(flatLabelsList, xCode))
            offersWithLabels.list?.filter((offer: any) => includes(validOffers, offer.value)).map((item: any) => labelsList.push(item))
        }
        if (!isEmpty(value[TO_FIELD])) {
            const validOffers = value[TO_FIELD].filter((value: string) => !includes(flatLabelsList, value))
            offersWithLabels?.list?.map((item: any) => includes(validOffers, item.value) ? labelsList.push(item) : undefined)
        }
        const objectLength = list?.length
        const newList = list.reverse()
        const isDiff = newList?.filter((search: any) => JSON.stringify(search) === JSON.stringify(value)).length === 0
        if (isDiff) {
            if (objectLength === 5) {
                newList.shift();
                newList.push(value)
            } else {
                newList.push(value)
            }
        }

        newValue.list = uniq(labelsList.map((item: any) => JSON.stringify(item))).map((item: any) => JSON.parse(item))
        newValue.offerList = newList.reverse()
        setSearchedValues(newValue)
    }

    const onSearchTrip = (disableClear?: boolean) => {
        if (!disableClear) {
            clearAllValues()
        }
        const {errorList} = searchFormValidation(searchFormValue)
        if (errorList[DATE_RANGE_FIELD] === 'OUTDATED_START_DATE') {
            setSearchFormValue(DATE_RANGE_FIELD, {
                startDate: GET_VALID_SUGGESTED_DATES(searchFormValue[DATE_RANGE_FIELD])?.startDate,
                endDate: GET_VALID_SUGGESTED_DATES(searchFormValue[DATE_RANGE_FIELD])?.endDate
            })
        }
        addToSearched(searchFormValue)
        sendToAnalytics({
            event: 'Search_Trip_Submit',
            eventCategory: 'BUTTON_CLICK',
            eventAction: 'Searched_Trip_Submit_Button'
        })
        navigate(SECOND_STEP_TRIP_SEARCH)
    }

    return ({
        onSearchTrip
    })
}

export default useTripHandler