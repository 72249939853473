import {Styled} from "../TripSearchBookingPage";
import TextInput from "../../../../lib/UIBookBrd/TextInput";
import {includes, toNumber} from "lodash-es";
import useBookingFunctions from "../functions";
import {useTranslation} from "react-i18next";
import {participantsType} from "../types";
import {adultsInputs, childInputs} from "../Consts";

const Participants = () => {

    const {t} = useTranslation();


    const {
        participants,
        state,
        onEnterPressed,
        getPlaceHolder,
        getAutoComplete,
        getInputType,
        onInputChangeHandler
    } = useBookingFunctions()

    return (
        <>

            <Styled.Section__Type__Label>
                {t('lbl_participants')}
            </Styled.Section__Type__Label>
            {Array.from({length: participants?.adultsCount ?? 0}).map((_: any, number: number) => {

                const sectionId = `${participantsType.adult}_${number}`

                const sectionValue = state?.participantsSection[sectionId]

                return (
                    <Styled.Participant__Section__Holder
                        key={`participant_adult_${number}`}
                    >
                        <Styled.Particiapant__Section
                        >
                            <Styled.Participant__Section__Title>
                                {`${number + 1}. `}
                                {t('lbl_adult')}
                                {sectionValue?.name ? `: ${sectionValue?.name} ${sectionValue?.surname ? `${sectionValue?.surname[0]}.` : ''}` : ''}
                            </Styled.Participant__Section__Title>
                        </Styled.Particiapant__Section>
                        <Styled.Participant__Section__Values>
                            {adultsInputs.map((inputName: string, index: any) => (
                                <Styled.Input__Holder
                                    key={`${sectionId}_${index}`}
                                >
                                    <Styled.Input__Label>
                                        {t(`lbl_${inputName}`)}
                                    </Styled.Input__Label>
                                    <TextInput
                                        name={`${sectionId}_${index}_${getAutoComplete(inputName)}`}
                                        id={`${sectionId}_${index}`}
                                        onEnterPressed={() => {
                                            onEnterPressed(`${sectionId}_${index}`)
                                        }}
                                        error={includes(state?.inValidFields ?? [], `${sectionId}_${inputName}`)}
                                        autoComplete={getAutoComplete(inputName)}
                                        textFieldType={getInputType(inputName) as any}
                                        placeholder={getPlaceHolder(inputName)}
                                        showIcon={false}
                                        onChange={(event: any) => {
                                            onInputChangeHandler(event?.target?.value, sectionId, inputName)
                                        }}
                                        isClearAble={false}
                                        value={{
                                            label: sectionValue ? sectionValue[inputName] : '',
                                            value: sectionValue ? sectionValue[inputName] : ''
                                        }}
                                    />
                                </Styled.Input__Holder>
                            ))}
                        </Styled.Participant__Section__Values>
                    </Styled.Participant__Section__Holder>
                )

            })}
            {participants?.childDates?.length !== 0 && Array.from({length: participants?.childDates?.length ?? 0}).map((_: any, number: number) => {

                const sectionId = `${participantsType.child}_${number}`

                const sectionValue = state?.participantsSection[sectionId]

                return (
                    <Styled.Participant__Section__Holder
                        key={`participant_child_${number}`}
                    >
                        <Styled.Particiapant__Section>
                            <Styled.Participant__Section__Title>
                                {`${number + 1 + toNumber(participants?.adultsCount) ?? 0}. `}
                                {t('lbl_kid')}
                                {sectionValue?.name ? `: ${sectionValue?.name} ${sectionValue?.surname ? `${sectionValue?.surname[0]}.` : ''}` : ''}
                            </Styled.Participant__Section__Title>
                        </Styled.Particiapant__Section>
                        <Styled.Participant__Section__Values>
                            {childInputs.map((inputName: string, index: number) => (
                                <Styled.Input__Holder
                                    key={`${sectionId}_${index}`}
                                >
                                    <Styled.Input__Label>
                                        {t(`lbl_${inputName}`)}
                                    </Styled.Input__Label>
                                    <TextInput
                                        name={`${sectionId}_${index}_${getAutoComplete(inputName)}`}
                                        id={`${sectionId}_${index}`}
                                        onEnterPressed={() => {
                                            onEnterPressed(`${sectionId}_${index}`)
                                        }}
                                        textFieldType={getInputType(inputName) as any}
                                        placeholder={getPlaceHolder(inputName)}
                                        autoComplete={getAutoComplete(inputName)}
                                        showIcon={false}
                                        onChange={(event: any) => {
                                            onInputChangeHandler(event?.target?.value, sectionId, inputName)
                                        }}
                                        error={includes(state?.invalidInputs ?? [], `${sectionId}_${inputName}`)}
                                        isClearAble={false}
                                        value={{
                                            label: sectionValue ? sectionValue[inputName] : '',
                                            value: sectionValue ? sectionValue[inputName] : ''
                                        }}
                                    />
                                </Styled.Input__Holder>
                            ))}
                        </Styled.Participant__Section__Values>
                    </Styled.Participant__Section__Holder>
                )

            })}
        </>
    )
}
export default Participants