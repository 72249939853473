import {Styled} from "../../Pages/TripSearch/TripSearchResultPage/Content/TripOfferList/TripOffer/TripOffer.styles";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {floor, isEmpty, isNull, toNumber} from "lodash-es";
import {Local_Settings_Values} from "../PageConfig";
import useStorage from "./useStorage";

const getMultiplierForCurrency = (oldCurrency: string, newCurrency: string) => {
    switch (newCurrency) {
        case 'GBP':
            return 0.19
        case'EUR':
            return 0.21
        case 'USD':
            return 0.23
    }
    return 1
}

const currencyExchangeHandler = (pricing: any, newCurrency: string) => {
    const firstPersonPrice = pricing?.FirstPerson?.amount ?? 0

    const totalPersonPrice = pricing?.Total?.amount ?? 0

    const firstPersonCurrency = pricing?.FirstPerson?.currency ?? 'PLN'

    const totalPersonCurrency = pricing?.Total?.currency ?? 'PLN'

    const newValueFirstPerson = (toNumber(firstPersonPrice) * getMultiplierForCurrency(firstPersonCurrency, newCurrency))

    const newValueTotal = (toNumber(totalPersonPrice) * getMultiplierForCurrency(totalPersonCurrency, newCurrency))

    return {
        newAmount: {
            firstPerson: {
                amount: newValueFirstPerson,
                currency: newCurrency
            },
            total: {
                amount: newValueTotal,
                currency: newCurrency
            }
        },
        oldAmount: {
            firstPerson: {
                amount: firstPersonPrice,
                currency: firstPersonCurrency
            },
            total: {
                amount: totalPersonPrice,
                currency: totalPersonCurrency
            }
        },

    }
}

export const usePricesHandler = (pricing?: any) => {

    const {t} = useTranslation();

    const [localSettings, _] = useStorage<any>('localStorage', 'LocalSettings', Local_Settings_Values)

    const currency = localSettings?.PreferredCurrency

    const isCurrencyDiff = !isNull(currency)

    const isTFGIncluded = pricing?.FirstPerson?.details?.TFGIncluded ?? false

    const taxValue = isTFGIncluded ? t('lbl_tfg_included') : t('lbl_tfg_not_included')

    const [newValue, setNewValue] = useState<any>({
        oldAmount: {
            firstPerson: {
                amount: pricing?.FirstPerson?.amount ?? 0,
                currency: pricing?.FirstPerson?.currency ?? 'PLN',
            },
            total: {
                amount: pricing?.Total?.amount ?? 0,
                currency: pricing?.Total?.currency ?? 'PLN',
            }

        },
        newAmount: {
            firstPerson: {
                amount: toNumber(pricing?.FirstPerson?.amount ?? 0) * getMultiplierForCurrency(pricing?.FirstPerson?.curreny ?? 'PLN', currency),
                currency: currency
            },
            total: {
                amount: toNumber(pricing?.Total?.amount ?? 0) * getMultiplierForCurrency(pricing?.Total?.curreny ?? 'PLN', currency),
                currency: currency
            }
        }
    })

    useEffect(() => {
        if (isCurrencyDiff) {
            setNewValue(currencyExchangeHandler(pricing, currency))
        }
    }, [currency])
    useEffect(() => {
        setNewValue(currencyExchangeHandler(pricing, currency))
    }, [pricing])

    const getPriceDifference = (oldOfferPrice: any, newOfferPrice: any) => {
        const oldTotalPrice = oldOfferPrice?.Total?.amount ?? 0
        const oldTotalCurrency = oldOfferPrice?.Total?.currency ?? 'PLN'
        const newTotalPrice = newOfferPrice?.Total?.amount ?? 0
        const newTotalCurrency = newOfferPrice?.Total?.currency ?? 'PLN'
        const currentCurrency = newTotalCurrency === oldTotalCurrency ? newTotalCurrency : 'PLN'
        const difference = toNumber(newTotalPrice) - toNumber(oldTotalPrice)
        const validDifference = isCurrencyDiff ? difference * getMultiplierForCurrency(currentCurrency, currency) : difference

        return {
            amount: validDifference,
            currency: isCurrencyDiff ? currency : currentCurrency
        }
    }

    const getCurrentPrice = (amount: number, currentCurrency: string) => {

        return {
            amount: amount * getMultiplierForCurrency(currentCurrency, currency),
            currency: currency ?? currentCurrency
        }
    }

    const toolTipContent = (
        <Styled.ToolTip__Content__Pricing>
            <Styled.Pricing__ToolTip__Holder>
                <Styled.Pricing__ToolTip__Text__Type>
                    {`${t('lbl_price_per_person')}: `}
                </Styled.Pricing__ToolTip__Text__Type>
                <Styled.Pricing__ToolTip__Text__Number>
                    {`${floor(newValue.oldAmount.firstPerson.amount)} ${newValue.oldAmount.firstPerson.currency}`}
                </Styled.Pricing__ToolTip__Text__Number>
            </Styled.Pricing__ToolTip__Holder>
            {isCurrencyDiff && !isEmpty(newValue?.newAmount) && (
                <Styled.Pricing__ToolTip__Holder>
                    <Styled.Pricing__ToolTip__Text__Type>
                        {`${t('lbl_price_per_person_preferred')}: `}
                    </Styled.Pricing__ToolTip__Text__Type>
                    <Styled.Pricing__ToolTip__Text__Number>
                        {`${floor(newValue?.newAmount?.firstPerson?.amount)} ${newValue?.newAmount?.firstPerson?.currency}`}
                    </Styled.Pricing__ToolTip__Text__Number>
                </Styled.Pricing__ToolTip__Holder>
            )}
            <Styled.Pricing__ToolTip__Holder>
                <Styled.Pricing__ToolTip__Text__Type>
                    {`${t('lbl_total_price')}: `}
                </Styled.Pricing__ToolTip__Text__Type>
                <Styled.Pricing__ToolTip__Text__Number>
                    {`${floor(newValue.oldAmount.total.amount)} ${newValue.oldAmount.total.currency}`}
                </Styled.Pricing__ToolTip__Text__Number>
            </Styled.Pricing__ToolTip__Holder>
            {isCurrencyDiff && !isEmpty(newValue?.newAmount) && (
                <Styled.Pricing__ToolTip__Holder>
                    <Styled.Pricing__ToolTip__Text__Type>
                        {`${t('lbl_price_total_preferred')}: `}
                    </Styled.Pricing__ToolTip__Text__Type>
                    <Styled.Pricing__ToolTip__Text__Number>
                        {`${floor(newValue.newAmount.total.amount)} ${newValue.newAmount.total.currency}`}
                    </Styled.Pricing__ToolTip__Text__Number>
                </Styled.Pricing__ToolTip__Holder>
            )}
            {isCurrencyDiff && (
                <Styled.Pricing__ToolTip__Holder>
                    <Styled.Pricing__ToolTip__Text__Type>
                        {`${t('lbl_exchange_disclaimer')}: `}
                    </Styled.Pricing__ToolTip__Text__Type>
                    <Styled.Pricing__ToolTip__Text__Number>
                        {`1 ${newValue?.oldAmount?.firstPerson?.currency} = ${getMultiplierForCurrency(newValue?.oldAmount?.firstPerson?.currency, currency)} ${currency}`}
                    </Styled.Pricing__ToolTip__Text__Number>
                </Styled.Pricing__ToolTip__Holder>
            )}
            <Styled.Pricing__ToolTip__Holder>
                <Styled.Pricing__ToolTip__Text__Type>
                    {`${t('lbl_price_tax_info')}: `}
                </Styled.Pricing__ToolTip__Text__Type>
                <Styled.Pricing__ToolTip__Text__Number>
                    {taxValue}
                </Styled.Pricing__ToolTip__Text__Number>
            </Styled.Pricing__ToolTip__Holder>
        </Styled.ToolTip__Content__Pricing>
    )

    return (
        {
            currency: localSettings?.PreferredCurrency,
            getCurrentPrice,
            getPriceDifference,
            toolTipContent,
            mainValue: isCurrencyDiff ? newValue?.newAmount : newValue?.oldAmount,
            wholeValue: newValue,
            taxInfo: taxValue,
            isCurrencyDiff

        }
    )
}

export default usePricesHandler