import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import {isMobileOnly} from "react-device-detect";

const Content__Holder = styled(FlexBox)`
  position: relative;
  gap: 10px;
  padding: 0 5px;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  height: ${isMobileOnly ? 'auto' : "400px"}
`

export const Styled = {
    Content__Holder
}