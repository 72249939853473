import {Styled} from "./OfferRegionInfo.styles"
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {useState} from "react";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";
import {isEmpty} from "lodash-es";
import {useTranslation} from "react-i18next";

type OfferRegionInfoProps = {
    isLoadingSections: boolean,
    offer: any,
    offerControls: any
}


const OfferRegionInfo = (
    {
        isLoadingSections,
        offerControls,
        offer
    }: OfferRegionInfoProps
) => {

    const [selectedDescription, setSelectedDescription] = useState<number>(0)

    const value = offerControls?.RegionList

    const {t} = useTranslation();

    return isLoadingSections || !isEmpty(value?.CountryInfo?.Descriptions) ? (
        <Styled.Region__Info__Holder>
            <Styled.Country__Title__Holder>
                {!isLoadingSections ? (
                    <>
                        <Styled.Flag__Image
                            src={value?.CountryInfo?.FlagLink}
                        />
                        <Styled.Region__Country__Title>
                            {value?.CountryInfo?.Name}
                        </Styled.Region__Country__Title>
                    </>
                ) : (
                    <>
                        <SkeletonBox
                            style={{
                                margin: '0 5px 0 0',
                                height: '18px',
                                width: '30px',
                                borderRadius: "0",
                                minWidth: 'unset',
                                minHeight: 'unset'
                            }}
                        />
                        <SkeletonBox
                            style={{
                                margin: '0',
                                minHeight: 'unset',
                                height: '21px'
                            }}
                        />
                    </>
                )}
            </Styled.Country__Title__Holder>
            <Styled.Country__Information>
                <Styled.Map__Image
                    src={value?.CountryInfo?.MapLink}
                />
                <Styled.Country__Description__Holder>
                    <Styled.Country__Information__Positioner>
                        {!isLoadingSections ? (
                            <>
                                {value?.CountryInfo?.Descriptions?.map((item: any) => {
                                    return (
                                        <FlexBox
                                            key={`country_description_${item.Key}`}
                                        >
                                            <Styled.Country__Key__Label>
                                                {item.Key}:
                                            </Styled.Country__Key__Label>
                                        </FlexBox>
                                    )
                                })}
                            </>
                        ) : (
                            <>
                                {Array.from({length: 7}).map((_, index: number) => (
                                    <SkeletonBox
                                        key={`loading_for_country_name_${index}`}
                                        width={Math.random() * (110 - 90) + 90}
                                        margin={'0'}
                                        minHeight={'17px'}
                                    />
                                ))}
                            </>
                        )}
                    </Styled.Country__Information__Positioner>
                    <Styled.Country__Information__Positioner>
                        {!isLoadingSections ? (
                            <>
                                {value?.CountryInfo && value?.CountryInfo?.Descriptions?.map((item: any, index: number) => {
                                    return (
                                        <Styled.Country__Value__Label
                                            key={`country_info_description_${index}`}
                                            dangerouslySetInnerHTML={{__html: item?.Desc?.replace('font-size: medium', '')}}/>
                                    )
                                })}
                            </>
                        ) : (<>
                            {Array.from({length: 7}).map((_, index: number) => (
                                <SkeletonBox
                                    key={`loading_country_description_${index}`}
                                    margin={'0'}
                                    minWidth={"unset"}
                                    width={Math.random() * (220 - 90) + 90}
                                    minHeight={'17px'}
                                />
                            ))}
                        </>)}
                    </Styled.Country__Information__Positioner>
                </Styled.Country__Description__Holder>
            </Styled.Country__Information>
            <Styled.Caruzel__Region__Options
                isLoadingSections={isLoadingSections}
            >
                {!isLoadingSections ? (
                    <>
                        {!isEmpty(value?.Regioninfo?.Descriptions) ?? value?.Regioninfo?.Descriptions?.map((section: any, index: number) => {

                            const name = section.Key

                            return (
                                <Styled.Description__Section__Tab
                                    key={`section_tab_${index}`}
                                    isSelected={index === selectedDescription}
                                    onClick={() => {
                                        setSelectedDescription(index)
                                    }}
                                >
                                    {name}
                                </Styled.Description__Section__Tab>
                            )

                        })}
                    </>
                ) : (
                    <>

                        {Array.from({length: 7}).map((_, index: number) => (
                            <Styled.Description__Tab__Loader>
                                <SkeletonBox
                                    key={`loading_tab_${index}`}
                                    width={Math.random() * (170 - 90) + 90}
                                    margin={'10px 10px'}
                                    minHeight={'17px'}
                                />
                            </Styled.Description__Tab__Loader>
                        ))}

                    </>
                )}
            </Styled.Caruzel__Region__Options>
            <Styled.Description__Value__Holder>
                {!isLoadingSections ? (
                    <Styled.Description__Title>
                        {value?.Regioninfo?.Descriptions && value?.Regioninfo?.Descriptions[selectedDescription]?.Key}
                    </Styled.Description__Title>
                ) : (
                    <>
                    </>
                )}
                {!isLoadingSections ? (
                    <span
                        dangerouslySetInnerHTML={{__html: value?.Regioninfo?.Descriptions && value?.Regioninfo?.Descriptions[selectedDescription].Desc.replace('font-size: medium', '')}}
                    />
                ) : (
                    <Styled.Loading__Description__Holder>
                        {Array.from({length: 7}).map((_, index: number) => (
                            <SkeletonBox
                                key={`loading_description_${index}`}
                                minHeight={'14px'}
                                margin={'2px 0'}
                                width={`${Math.random() * (98 - 55) + 55}%`}
                            />
                        ))}
                    </Styled.Loading__Description__Holder>
                )}
            </Styled.Description__Value__Holder>

        </Styled.Region__Info__Holder>
    ) : (
        <FlexBox
            style={{
                width: '100%',
                height: '150px'
            }}
        >
            <Styled.No__Region__Found__Label>
                {t('lbl_no_region_found_label')}
            </Styled.No__Region__Found__Label>
        </FlexBox>
    )

}


export default OfferRegionInfo