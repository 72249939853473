import {includes, isEmpty} from "lodash-es";
import {TO_FIELD, XCITY_FIELD, XCODE_FIELD} from "../DefaultFormikValue/DefaultSearchFormValues";
import {
    BOOK_STEP_TRIP_SEARCH,
    CONTACT_OUR,
    CONTACT_OWN,
    CONTACT_US,
    CREATE_ACCOUNT,
    FIRST_STEP_TRIP_SEARCH,
    getPreferences,
    LOGIN,
    SECOND_STEP_TRIP_SEARCH,
    THIRD_STEP_TRIP_SEARCH
} from "../Routes";
import {useTranslation} from "react-i18next";
import useAutoSuggest from "../../Components/DestinationPicker/AutoSuggestFunctionality";
import {Local_Settings_Values} from "../PageConfig";
import {useContext} from "react";
import {Search_Proto} from "../Context/Context";
import {DEFAULT_DESCRIPTION, DEFAULT_IMAGE, DEFAULT_KEYWORDS, DEFAULT_TITLE} from "../Configs/SEO";
import useRoutesHandler from "../RoutesHandler";
import useStorage from "./useStorage";

const getAllCodes = (SearchFormValues: any) => {
    const allCodes: any[] = []
    const countriesRegions = SearchFormValues ? SearchFormValues[TO_FIELD] : []
    const hotels = SearchFormValues ? SearchFormValues[XCODE_FIELD] : []
    const cities = SearchFormValues ? SearchFormValues[XCITY_FIELD] : ''
    if (!isEmpty(countriesRegions)) {
        countriesRegions?.map((id: string) => allCodes.push(id))
    }
    if (!isEmpty(hotels)) {
        hotels.map((code: any) => allCodes.push(code))
    }
    if (!isEmpty(cities)) {
        allCodes.push(cities)
    }

    return allCodes
}

const useMetaTags = () => {

    const [localSettings, setLocalSettings] = useStorage<any>('localStorage', 'LocalSettings', Local_Settings_Values)

    const [hashValue, _] = useContext<any>(Search_Proto)

    const {createParamsMap} = useRoutesHandler()

    const userData = getPreferences(localSettings)

    const [offersWithLabels, setOffersWithLabels] = useStorage<any>('sessionStorage', 'offersWithLabels', {language: localSettings?.SelectedLanguage ?? userData.SelectedLanguage})

    const {getNamesForValues} = useAutoSuggest(localSettings?.SelectedLanguage ?? userData.SelectedLanguage)

    const {t} = useTranslation()

    const getValueForSecondStepTrips = () => {
        const allCodes: any[] = getAllCodes(hashValue?.SearchFormValues)
        const value = offersWithLabels?.list?.filter((item: any) => includes(allCodes, item.value))[0]?.label
        const number = offersWithLabels?.list?.filter((item: any) => includes(allCodes, item.value)).length - 1
        const baseSecondStepKeyWords = 'vacation options, inclusive holiday packages, affordable trip results, special offers on vacations, all-in-one trip choices, '
        switch (allCodes.length) {
            case 0:
                return {
                    title: 'BookBrd | ' + t('lbl_second_step_any_where'),
                    keywords: baseSecondStepKeyWords,
                    description: 'Explore curated trip results for dream getaways. Find diverse options, top-rated destinations, and exclusive deals. Plan your next adventure now!'
                }
            case 1:
                return {
                    title: `BookBrd | ${t(`lbl_second_step_one_place`)} ${value}`,
                    keywords: baseSecondStepKeyWords + `search results for: ${value}`,
                    description: `Explore curated trip results for dream getaways in ${value}. Find diverse options, top-rated destinations, and exclusive deals.`
                }
            default:
                return {
                    title: `BookBrd | ${t('lbl_second_step_few_places', {value, number})} `,
                    keywords: baseSecondStepKeyWords + `search results for: ${offersWithLabels?.list?.filter((item: any) => includes(allCodes, item.value)).map((item: any) => `${item.label}, `)}`,
                    description: `Explore ${offersWithLabels?.list?.filter((item: any) => includes(allCodes, item.value)).map((item: any) => `${item.label} `)} with possibly best options just for you.`
                }
        }
    }

    const getValueForThirdStepTrips = () => {
        const valuesArray = window.location.pathname.replace(`${THIRD_STEP_TRIP_SEARCH}/`, '').split('/')
        const valuesMap = createParamsMap(valuesArray)
        const {
            Hotel,
            Room,
            From,
            To,
            Board,
            Participants,
            Departure
        } = valuesMap

        return {
            title: `BookBrd | ${Hotel}`,
            keywords: `${Hotel} deal, luxury stay at ${Hotel}, exclusive offer from ${From} to ${To}, ${Room} accommodation, gourmet ${Board} board, ${Departure} departure package, travel promotion, limited-time offer, unforgettable experience.`,
            description: `Unlock an exclusive opportunity for ${Participants} at ${Hotel} from ${From} to ${To}. Indulge in comfort with ${Room} & ${Board}. Your journey begins from ${Departure}. Seize this unique offer for an unforgettable experience.`
        }
    }

    const mapForTripPages: any = {
        [FIRST_STEP_TRIP_SEARCH]: {
            title: `BookBrd | ${t('lbl_first_step_trip')}`,
            keywords: 'BookBrd, search trips, find vacations, search holiday, trip finder, all-inclusive vacation search, best vacation search, summer vacation search, winter getaway finder',
            description: `Unlock the world's wonders with ease. Discover, compare, and effortlessly book your dream vacations through BookBrd. Your gateway to global adventures and dreams awaits.`
        },
        [SECOND_STEP_TRIP_SEARCH]: getValueForSecondStepTrips(),
        [THIRD_STEP_TRIP_SEARCH]: getValueForThirdStepTrips(),
        [BOOK_STEP_TRIP_SEARCH]: {
            title: `BookBrd | Booking {Name}`,
            keywords: 'Bookbrd, booking page, book your trip, reserve trip',
            description: 'BookBrd book your desired offer and pay just prepay today!'
        }
    }
    const mapForAccountRelatedPages: any = {
        [CREATE_ACCOUNT]: {
            title: 'BookBrd | Create Account',
            keywords: 'BookBrd, join community, create account, join BookBrd, create account at BookBrd',
            description: 'Create account at BookBrd to join our community and get access to exclusive offers.'
        },
        [LOGIN]: {
            title: 'BookBrd | Login',
            keywords: 'BookBrd, log into your account, welcome back to login, glad to see BookBrd member',
            description: 'Login into your account at BookBrd to secure your exclusive offers and manage your bookings even easier.'
        }
    }
    const mapForSupportRelatedPages: any = {
        [CONTACT_US]: {
            title: 'BookBrd | Support',
            keywords: 'customer support, technical assistance, helpdesk solutions, support services, troubleshooting, problem resolution, contact Support',
            description: 'Get expert assistance and solutions on our support page. Our dedicated team is here to help you with any questions or issues. Find the support you need for a seamless experience.'
        },
        [CONTACT_OUR]: {
            title: 'BookBrd | Contact us',
            keywords: 'contact bookbrd support, send message to bookbrd team, get help fast with bookbrd team',
            description: 'Send us message to resolve your problem as soon as possible. We are here to help you with your problem'
        },
        [CONTACT_OWN]: {
            title: 'BookBrd | FAQ',
            keywords: 'FAQ , frequently asked questions, questions about bookbrd, about bookbrd questions',
            description: 'Solve all your concerns! Find your question in FAQ and life will be much more simple'
        },
    }

    const mapForMetaTagsSEO: any = {
        ...mapForAccountRelatedPages,
        ...mapForTripPages,
        ...mapForSupportRelatedPages
    }

    const checkForMissingLabels = () => {
        const allCodes: any[] = getAllCodes(hashValue?.SearchFormValues)
        if (allCodes.length !== (!isEmpty(offersWithLabels?.list) ? offersWithLabels?.list?.filter((item: any) => includes(allCodes, item.value)).length : 0)) {
            getNamesForValues(
                offersWithLabels,
                setOffersWithLabels,
                hashValue?.SearchFormValues[TO_FIELD],
                hashValue?.SearchFormValues,
                allCodes,
            )
        }
    }

    const getValidPath = (path: string) => {
        if (path.includes(THIRD_STEP_TRIP_SEARCH)) {
            return THIRD_STEP_TRIP_SEARCH
        }

        return path
    }

    const getMetaForSEO = () => {
        const ogTitleTag = document.querySelector('meta[property="og:title"]') || document.createElement('meta');
        const keywordsTag = document.querySelector('meta[property="keywords"]') || document.createElement('meta');
        const ogDescription = document.querySelector('meta[property="og:description"]') || document.createElement('meta');
        const descriptionTag = document.querySelector('meta[name="description"]') || document.createElement('meta');
        const ogImage = document.querySelector('meta[property="og:image"]') || document.createElement('meta');
        checkForMissingLabels()
        const pathName = getValidPath(window.location.pathname)
        const {title, description, keywords, image} = mapForMetaTagsSEO[pathName] ?? {}
        document.title = title ?? DEFAULT_TITLE
        ogDescription.setAttribute('property', 'og:description')
        ogImage.setAttribute('property', 'og:image')
        ogTitleTag.setAttribute('property', 'og:title')
        keywordsTag.setAttribute('property', 'keywords');
        descriptionTag.setAttribute('name', 'description');
        ogImage.setAttribute('content', image ?? DEFAULT_IMAGE)
        keywordsTag.setAttribute('content', keywords ?? DEFAULT_KEYWORDS);
        ogDescription.setAttribute('content', description ?? DEFAULT_DESCRIPTION)
        descriptionTag.setAttribute('content', description ?? DEFAULT_DESCRIPTION);
        ogTitleTag.setAttribute('content', title ?? DEFAULT_TITLE)
    }


    return ({
        getMetaForSEO
    })
}
export default useMetaTags