import React, {useEffect} from 'react';
import {BrowserRouter} from "react-router-dom";
import BookBrd from "./BookBrd";
import LocalDataProvider from "./lib/Context/LocalDataProvider";
import HashValueProvider from "./lib/Context/HashValueProvider";
import NotificationProvider from "./lib/Context/NotificationProvider";
import AppThemeProvider from "./lib/Context/AppThemeProvider";
import useOnSiteOpen from "./lib/UseHooksBookBrd/useOnSIteOpen";
import GlobalStyle from "./Styles/GlobalStyles";


function App() {


    const {
        onSiteOpen,
        refactorTokenUrl,
        onWindowScrollHandler,
    } = useOnSiteOpen()

    useEffect(() => {
        refactorTokenUrl()
        onWindowScrollHandler()
        onSiteOpen()
    }, [])


    return (
        <React.Fragment>
            <BrowserRouter>
                <LocalDataProvider>
                    <AppThemeProvider>
                        <HashValueProvider>
                            <NotificationProvider>
                                <BookBrd/>
                                <GlobalStyle/>
                            </NotificationProvider>
                        </HashValueProvider>
                    </AppThemeProvider>
                </LocalDataProvider>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
