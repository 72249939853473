import {Styled} from "../../SearchTripLowerPart.styles"
import useSearchFormValues from "../../../../../lib/UseHooksBookBrd/useSearchFormValues";

type RecommendedOfferBoxProps = {
    item: any
}

const RecommendedCountryBox = (
    {
        item
    }: RecommendedOfferBoxProps
) => {

    const {onAdvertisementClickHandler} = useSearchFormValues()

    const onBoxClick = () => {
        onAdvertisementClickHandler('Country', item)
    }

    return (
        <Styled.Country__Advertisement
            onClick={onBoxClick}
        >
            <Styled.Country__Image
                src={item.image}
            />
            <Styled.Country__Label>
                <Styled.Country__Flag
                    src={item.flag}
                />
                {item?.label}
            </Styled.Country__Label>


        </Styled.Country__Advertisement>
    )
}
export default RecommendedCountryBox