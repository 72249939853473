import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";

const Views__Switch__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: scroll;
  margin-top: 5px;
`
const Type__Tabs = styled(Text)<{ isSelected: boolean, isLast: boolean }>`
  white-space: nowrap;
  user-select: none;
  padding: 10px 15px;
  margin: 5px 0;
  font-size: 19px;
  font-weight: ${({isSelected}) => isSelected ? 'bold' : undefined};
  border-right: 1px solid ${({theme, isLast}) => isLast ? 'transparent' : theme.color};
  cursor: pointer;
`
export const Styled = {
    Type__Tabs,
    Views__Switch__Holder
}