import {isEmpty} from "lodash-es";

const useFavouriteOffers = () => {

    const getIsListEmpty = (rawData: any) => {
        const list: any[] = []
        Object.keys(rawData).map((type: any) => {
            const sectionValue = rawData[type]
            if (!isEmpty(sectionValue)) {
                list.push(type)
            }
        })
        return isEmpty(list)
    }

    return ({
        getIsListEmpty
    })
}

export default useFavouriteOffers