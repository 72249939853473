import {
    BOARD_FIELD,
    DATE_RANGE_FIELD,
    DURATION_FIELD,
    ORIGIN_FIELD,
    PARTICIPANTS_FIELD,
    PRICE_FIELD,
    RATING_FIELD,
    TO_FIELD,
    TRANSPORT_FIELD,
    XCODE_FIELD
} from "../DefaultFormikValue/DefaultSearchFormValues";
import {isEmpty, toNumber} from "lodash-es";
import {
    FormTransportList,
    SEOBoard,
    SEODates,
    SEODestination,
    SEODuration,
    SEOFrom,
    SEOParticipants,
    SEOPrice,
    SEORating,
    SEOTransport,
    SEOTransportList
} from "../Configs/SEO";

const getKidsValue = (value: any) => {
    const secondNumber = value.split(':')[1]
    if (secondNumber === '0') {
        return {
            value: 0,
            dates: []
        }
    }
    const dateArray = value.split(':')[1].split(',')
    return {
        value: dateArray.length,
        dates: dateArray
    }
}

const getValueForSearchForm = (value: any, type: string) => {
    switch (type) {
        case TO_FIELD:
            if (value === 'anywhere') {
                return []
            } else {
                return value.split(',').map((item: any) => {
                    const itemSplit = item.split(':').filter((item: any) => !!item)
                    if (itemSplit.length === 1) {
                        return item
                    }
                    const [country, region] = itemSplit

                    return `${country}_${region}`
                })
            }
        case DURATION_FIELD:
            if (value === 'not-specified') {
                return {min: 0, max: 0}
            }
            const splitValue = value.split(':')
            const numbers = splitValue[1].split('-')[0].split(',')
            if (numbers.length === 1) {
                return {min: toNumber(numbers[0]), max: toNumber(numbers[0])}
            }
            return {min: toNumber(numbers[0]), max: toNumber(numbers[1])}
        case XCODE_FIELD:
            return value.split(',').map((type: string) => (toNumber(type)))
        case DATE_RANGE_FIELD:
            const [start, end] = value.split(',')
            return {
                startDate: start.split(':')[1],
                endDate: end.split(':')[1]
            }
        case PARTICIPANTS_FIELD:
            const [adults, kids] = value.split('-')
            const adultsNumber = toNumber(adults.split(':')[1])
            return {
                adults: {value: adultsNumber},
                kids: {...getKidsValue(kids)},
                rooms: {value: 1}
            }
        case ORIGIN_FIELD:
            return []
        case PRICE_FIELD:
            const [min, max] = value.split(',')
            return [toNumber(min.split(':')[1]), toNumber(max.split(':')[1])]
        case TRANSPORT_FIELD:
            return value.split(',').map((type: string) => {
                return FormTransportList[type] ?? type
            })
        case BOARD_FIELD:
            return value.split(',').map((type: string) => (toNumber(type)))
        case RATING_FIELD:
            return {min: toNumber(value.split('-')[1]), max: 7}
        default :
            return value
    }
}

const getValueForSEOLink = (partValue: any, type: string) => {
    switch (type) {
        case SEOParticipants:
            const adultsValue = partValue['adults']
            const kidsValue = partValue['kids']
            return `Adults:${adultsValue.value}-Kids:${!isEmpty(kidsValue.dates) ? kidsValue.dates.join(',') : 0}`
        case SEODestination:
            return isEmpty(partValue) ? 'anywhere' : partValue.map((item: any) => {
                const itemSplit = item.split('_')
                if (item.split('_').length === 1) {
                    return item
                }
                const [country, region] = itemSplit
                return `${country}:${region}`
            })
        case SEODates:
            return `Start:${partValue?.startDate},End:${partValue?.endDate}`
        case SEODuration:
            if (partValue.min === 0) {
                return 'not-specified'
            }
            if (partValue.min === partValue.max) {
                return `For:${partValue.min}-Days`
            }
            return `For:${partValue.min},${partValue.max}-Days`
        case SEOFrom:
            return 'Poland'
        case SEOPrice:
            return `min:${partValue[0]},max:${partValue[1]}`
        case SEOTransport:
            return partValue.map((type: string) => {
                return SEOTransportList[type] ?? type
            }).join(',')
        case SEOBoard:
            return partValue.join(',')
        case SEORating:
            return `min-${partValue.min}-stars`
        default :
            return partValue
    }
}


export {
    getValueForSearchForm,
    getValueForSEOLink
}

