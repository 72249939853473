import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../lib/UIBookBrd/Text";
import Image from "../../lib/UIBookBrd/Image";
import {isMobile, isMobileOnly} from "react-device-detect";
import {rgba} from "polished";


const Navigation = styled.div`
  display: flex;
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1100px;
  height: 56px;

  flex-wrap: wrap;
  background-color: transparent;
  user-select: none;
`;
const NavigationHolder = styled.div`
  transition: 500ms;
  border-bottom: 1px solid ${(props: { theme: any; }) => props.theme.borderColor};
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  width: 100%;

`;
const SmallerWidth__Drop__Down = styled.div`
  transform-origin: top center;
  z-index: 1000;
  border-top: ${(props: { theme: any; }) => props.theme.borderColor} 1px solid;
  width: 100%;
  top: 60px;
  height: auto;
  position: absolute;
  box-shadow: 0 2px 5px ${(props: { theme: any; }) => props.theme.color};;
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
`;
const SmallerWidth__Option = styled.div`
  cursor: pointer;
  width: auto;
  height: auto;
  padding: 8px 15px;

  &:hover {
    background-color: ${(props: { theme: any; }) => props.theme.optionHoverColor};;
  }
`;
const Drop_Down_label = styled(Text)`
  text-decoration: none;
  user-select: none;
  cursor: pointer;

`
const BiggerWidthHolder = styled(FlexBox)`
  width: 100%;
  flex-wrap: nowrap;
`
const SmallerWidthHolder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
`
const SettingsIcon = styled(FontAwesomeIcon)`
  transition: 500ms;
  margin: 15px 5px;
  cursor: pointer;
  height: 26px;
  width: 26px;
  color: ${(props: { theme: any; }) => props.theme.color};

  &:hover {
    color: ${(props: { theme: any; }) => props.theme.placeholderColor};
  }
`
const BurgerHolder = styled(FlexBox)`
  width: 26px;
  height: 26px;
  overflow: hidden;
  border-color: #00a699;

  :hover {
    border-color: red;
  }
`

const NavigationIcon = styled(FontAwesomeIcon)`
  transition: 5000ms;
  margin: auto;
  font-size: 26px;
  color: ${(props: { theme: any; }) => props.theme.color};

  &:hover {
    color: ${(props: { theme: any; }) => props.theme.placeholderColor};
  }
`
const SmallerWidth__Option__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
`
const Country__Image = styled.img`
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin: auto 0 auto 10px;
`
const Options__Icon = styled(FontAwesomeIcon)`
  height: 14px;
  width: 18px;
  margin-right: 2px;
`
const Half__Option__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-grow: 1;
  padding: 0 5px 5px 5px;
  gap: 0 4px;
  @media (max-width: 350px) {
    min-width: calc(100vw - 10px);
    width: auto;
  }
`
const Round__Image__Label = styled(Text)`
  text-align: center;
  font-weight: bold;
  margin-top: auto;
  cursor: pointer;
  margin-left: 5px;
  user-select: none;
  margin-bottom: auto;
`
const Account__Option__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  padding: 7.5px;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.color};
  margin-top: auto;
  margin-bottom: 10px;
  width: 17.5px;
  height: 17.5px;
  margin-right: 2px;
  margin-left: 2px;
  cursor: pointer;
  display: ${isMobile ? 'flex' : 'none'};

  &:first-of-type {
    margin-left: auto;
  }

  &:last-of-type {
    margin-right: 0;
  }
`
const Account__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  margin-bottom: 10px;

  &:hover ${Account__Option__Icon} {
    display: flex;
  }
`
const Account__Image = styled(Image)<{ deleteBackground: boolean, userBackgroundColor: string | undefined }>`
  width: ${isMobileOnly ? '85px' : '65px'};
  height: ${isMobileOnly ? '85px' : '65px'};
  margin: auto 0 5px 5px;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  background-color: ${({
                         deleteBackground,
                         userBackgroundColor
                       }) => deleteBackground ? 'transparent' : userBackgroundColor};
  border-radius: 50%;
  cursor: default;
`
const No_Image_Account = styled(FlexBox)<{ backgroundColor?: string, isLogged: boolean }>`
  width: ${isMobileOnly ? '85px' : '65px'};;
  height: ${isMobileOnly ? '85px' : '65px'};
  margin: auto 0 5px 5px;
  box-sizing: border-box;
  background-color: ${({backgroundColor, theme}) => backgroundColor ?? theme.brdExclusiveColor};
  box-shadow: 0px 0px 5px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  border-radius: 50%;
  cursor: default;
`
const No__User__Image__Label = styled(Text)`
  font-size: 35px;
  font-weight: bold;
  margin: auto;
`
const Not__Logged__User = styled(FontAwesomeIcon)`
  height: 30px;
  width: 30px;
  margin: auto;
  color: ${({theme}) => theme.color};
`

const Account__Label = styled(Text)<{ isLogged: boolean }>`
  margin-top: auto;
  margin-left: 5px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 2px ${({theme}) => theme.backgroundColor};
  font-size: ${isMobileOnly ? '22px' : '18px'};
  font-weight: bold;
  cursor: default;
`
const Elipsis__Icon = styled(FontAwesomeIcon)`
  width: 20px;
  cursor: pointer;
  margin-left: auto;
  height: 20px;
  padding: 2.5px;
  color: ${({theme}) => theme.color};
`
const Currency__Label = styled(Text)`
  user-select: none;
  margin: auto;
  padding-bottom: 1px;
  font-weight: bold;
  text-align: center;
`
const Round__Currency__Holder = styled(FlexBox)`
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: auto 0 auto 10px;
`

const Currency__Label__Navigation = styled(Text)`
  cursor: pointer;
  width: 35px;
  height: 16px;
  padding: 10.5px 2px;
  font-weight: bold;
  min-width: 35px;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  transition: none;

  &:hover {
    background-color: ${({theme}) => theme.optionHoverColor};
  }

`

const Manage__Account__Icon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
  padding: 12.5px;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 50%;
  color: ${({theme}) => theme.color};
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};
`
const Bars__Small__Width = styled(FontAwesomeIcon)`
  height: 26px;
  cursor: pointer;
  width: 26px;
  margin: auto 0;
  color: ${({theme}) => theme.color};
`
const Main__Drawer__Value = styled(FlexBox)`
  flex-wrap: nowrap;
  cursor: pointer;
  width: calc(50% - 2px);
  padding: 8px 0;
  border-radius: 5px;
  background-color: ${({theme}) => rgba(theme.optionHoverColor, 0.6)};

  &:hover {
    background-color: ${({theme}) => rgba(theme.optionHoverColor, 0.9)};
  }
`
const Regular__Options__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 5px 0;
`
const Single__Option__Box = styled(FlexBox)`
  padding: 10px;
  width: calc(100% - 30px);
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({theme}) => rgba(theme.optionHoverColor, 0.6)};

  &:hover {
    background-color: ${({theme}) => rgba(theme.optionHoverColor, 0.9)};
  }
`
const Single__Option__Label = styled(Text)`
  font-size: 15px;
  margin: auto 0;
  font-weight: bold;
`
const Single__Option__Icon = styled(FontAwesomeIcon)`
  height: 25px;
  width: 25px;
  color: ${({theme}) => theme.color};
  margin-right: 10px;
`
const Operator__Label = styled(Text)`
  margin: 0 auto auto 10px;
  padding-right: 10px;
  font-weight: bold;
`
const Background__Image = styled(Image)`
  position: absolute;
  z-index: -1;
  object-fit: cover;
  width: 100%;
  height: ${isMobileOnly ? '130px' : '90px'};
`

export const Styled = {
    Operator__Label,
    Background__Image,
    Single__Option__Icon,
    Single__Option__Label,
    Single__Option__Box,
    Regular__Options__Holder,
    Not__Logged__User,
    No__User__Image__Label,
    No_Image_Account,
    Main__Drawer__Value,
    Bars__Small__Width,
    Currency__Label__Navigation,
    Manage__Account__Icon,
    Currency__Label,
    Account__Option__Icon,
    Elipsis__Icon,
    Account__Label,
    Account__Image,
    Round__Currency__Holder,
    Navigation,
    NavigationHolder,
    SmallerWidth__Drop__Down,
    SmallerWidth__Option,
    Drop_Down_label,
    BiggerWidthHolder,
    SmallerWidthHolder,
    SettingsIcon,
    BurgerHolder,
    NavigationIcon,
    SmallerWidth__Option__Holder,
    Country__Image,
    Half__Option__Holder,
    Options__Icon,
    Round__Image__Label,
    Account__Holder
}