import {Styled} from './BrdLogo.styles';
import React from "react";

type BrdLogoProps = {
    image?: string
    alt?: string
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void
    style?: React.CSSProperties
}
const defaultProps = {
    image: 'BookBrdLogo.png',
    alt: 'undefined',
    onClick: undefined,
    onMouseOver: undefined,
    onMouseLeave: undefined,
    children: undefined,
}

const BrdLogo = ({image, alt, ...props}: BrdLogoProps) => {
    return (
        <Styled.BrdLogo
            {...props}
        >
            <Styled.FlexBox>
                <Styled.Book__Label>
                    <span
                        style={{
                            fontWeight: 'bold',
                            fontFamily: 'serif'
                        }}
                    >
                    Book
                    </span>
                    <Styled.Brd__Span>
                        Brd
                    </Styled.Brd__Span>
                </Styled.Book__Label>
            </Styled.FlexBox>
        </Styled.BrdLogo>
    )
}
BrdLogo.defaultProps = defaultProps;

export default BrdLogo