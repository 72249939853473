import {Styled} from "../Offer.styles";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import StarIcons from "../../StarIcons";
import React from "react";

type TitleProps = {
    offerData: any
    onClick?: (event: any) => void
    disableRating?: boolean
    enableWrap?: boolean
    holderStyle?: React.CSSProperties
}

const Title = (
    {
        holderStyle,
        disableRating,
        offerData,
        onClick,
        enableWrap
    }: TitleProps
) => {

    const {Base, Accommodation} = offerData

    return (
        <Styled.Title__Holder
            style={holderStyle}
        >
            <Styled.Offer__Title
                onClick={onClick}
                isClickAble={!!onClick}
                enableWrap={!!enableWrap}
            >
                {Base?.XCode?.Name}

            </Styled.Offer__Title>
            {!disableRating && (
                <FlexBox style={{flexWrap: 'nowrap'}}>
                    <StarIcons
                        number={Accommodation?.Category}
                    />
                </FlexBox>
            )}
        </Styled.Title__Holder>
    )
}

export default Title