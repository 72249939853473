import {Styled} from "./SkeletonOffer.styles"
import FlexBox from "../../../../../../../lib/UIBookBrd/FlexBox";
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";


function randomiseLength() {

    const difference = Math.floor(Math.random() * 6);

    const numberOfFives = Math.floor(Math.random() * 7);

    return (40 + (numberOfFives * 5) + difference);
}

type SkeletonOfferProps = {
    disableShadow?: boolean
}

const SkeletonOffer = (
    {
        disableShadow
    }: SkeletonOfferProps
) => {

    return (
        <Styled.SkeletonOffer
            disableShadow={disableShadow}
        >
            <Styled.OfferTopLayer>
                <Styled.TitleAndImage>
                    <FlexBox>
                        <Styled.SkeletonLabelText/>
                        {Array.from({length: Math.floor(Math.random() * 5) + 1}).map(() => (
                                <Styled.StarIcon icon={faStar}/>
                            )
                        )}
                    </FlexBox>
                    <Styled.SkeletonImage/>
                </Styled.TitleAndImage>
                <Styled.TopSkeletonOfferDescription>
                    {Array.from({length: 3}).map(() => {
                        const lenght = randomiseLength()
                        return <Styled.SkeletonValuesText
                            lenght={lenght}
                        />
                    })}

                    <Styled.SkeletonValuesText lenght={60}/>
                </Styled.TopSkeletonOfferDescription>
            </Styled.OfferTopLayer>
        </Styled.SkeletonOffer>
    )

}

export default SkeletonOffer