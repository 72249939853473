import {useContext, useEffect} from "react";
import {Notifications_Data} from "../Context/Context";
import moment from "moment";
import {isEqual} from "lodash-es";

const useNotifications = () => {

    const [notifications, setNotifications] = useContext<any>(Notifications_Data)

    const deleteNotification = () => {
        const newList = notifications?.notificationsList?.filter((notification: any) => {
            const notificationMoment = notification.addedTime
            const messageAge = moment().diff(notificationMoment, 'seconds')
            return messageAge < 5
        })
        
        if (!isEqual(newList, notifications?.notificationsList)) {
            setNotifications((state: any) => ({
                ...state,
                notificationsList: newList

            }))
        }
    }

    const addNotification = (value: any) => {
        setNotifications((state: any) => ({
            ...state,
            notificationsList: [
                ...state?.notificationsList ?? [],
                {
                    ...value,
                    addedTime: moment()
                }
            ]
        }))
        setTimeout(() => {
            setNotifications((state: any) => ({
                ...state,
                trigger: value
            }))
        }, 5000)
    }

    const distanceFromTop = 60

    const getValidTopValue = (window: any) => {
        const newDistance = distanceFromTop - window.scrollY
        if (newDistance <= 5) {
            return 5
        } else {
            return Number(newDistance)
        }
    }

    useEffect(() => {
        deleteNotification()
    }, [notifications?.trigger])

    return {
        getValidTopValue,
        addNotification,
        deleteNotification,
        notifications,
        setNotifications
    }
}
export default useNotifications