import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isMobileOnly} from "react-device-detect";

const Footer = styled(FlexBox)`
  flex-wrap: nowrap;
  padding-top: 25px;
  padding-bottom: 15px;
  flex-direction: column;
  width: 100%;
  background: ${(props: { theme: any; }) => props.theme.backgroundColor};
`
const Company__Footer__Holder = styled(FlexBox)`
  margin: 0 auto;
  margin-bottom: 5px;
`
const Footer__Links__Sections = styled(FlexBox)`
  width: calc(100% - 20px);
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 50px;
  gap: 20px 0;
`
const Footer__Section = styled(FlexBox)`
  flex-direction: column;
  width: calc(100% / 4);
  @media (max-width: 900px) {
    width: calc(100% / 2);
  }
  @media (max-width: 450px) {
    width: calc(100% / 1);
  }

`
const Footer__Section__Title = styled(Text)`
  user-select: none;
  font-weight: bold;
  margin-bottom: 5px;
  @media (max-width: 450px) {
    font-size: 16px;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 10px;
  }
`
const Little__Dot = styled.span`
  user-select: none;
  text-decoration: none;
  margin-right: 2px;
  @media (max-width: 450px) {
    display: none;
  }
`

const Link__Label = styled(Text)`
  cursor: pointer;
  user-select: none;

  @media (max-width: 450px) {
    margin: 2px auto;
  }

  &:hover {
    text-decoration: underline;
  }
`
const Link__Label__Link__Type = styled.a`
  cursor: pointer;
  user-select: none;
  text-decoration: none;

  @media (max-width: 450px) {
    margin: 2px auto;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Social__Media__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  padding: 18px 0;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
`
const Social__Media__Title = styled(Text)`
  user-select: none;
  font-weight: bold;
  margin: 0 auto;
  font-size: 20px;
  padding-bottom: 18px;

`
const Social__Media__Values = styled(FlexBox)`
  margin: 0 auto;
  gap: 5px 10px;
  flex-direction: initial
`
const Social__Media_Type__label = styled(Text)`
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }

  margin: ${isMobileOnly ? '0 auto' : undefined};
  @media (max-width: 400px) {
    margin: 0 auto;
  }
`
const Brand__Icon = styled(FontAwesomeIcon)`
  height: 32px;
  margin-right: 2px;
  width: 32px;
`

export const Styled = {
    Link__Label__Link__Type,
    Footer,
    Company__Footer__Holder,
    Footer__Links__Sections,
    Footer__Section,
    Footer__Section__Title,
    Link__Label,
    Little__Dot,
    Social__Media__Holder,
    Social__Media__Title,
    Social__Media__Values,
    Social__Media_Type__label,
    Brand__Icon,
}