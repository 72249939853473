import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";
import Button from "../../lib/UIBookBrd/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Offer__Pictures__Holder = styled(FlexBox)`
  margin: 5px 0;
  width: 100%;
  flex-wrap: nowrap;
`
const Offer__Picture = styled(FlexBox)`
  width: calc(50% - 10px);
  height: 130px;
  margin: 0 5px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${(props: { theme: any; }) => props.theme.placeholderColor};
  background-image: url(${(props: { image?: any; }) => props.image});
  @media (min-width: 700px) {
    height: 150px;
  }
  @media (max-width: 500px) {
    height: 100px;
  }
`
const Modal__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 0 5px;
  width: calc(100% - 10px);
`
const New__Offer_Holder = styled(FlexBox)`
  width: 100%;
  margin-bottom: 10px;
`
const Offer__Title__Holder = styled(FlexBox)`
  margin-top: 5px;
  flex-wrap: nowrap;
  width: 100%;
`
const Offer__Buttons__Holder = styled(FlexBox)`
  margin-bottom: 5px;
  flex-wrap: nowrap;
  width: 100%;
`
const Offer__Button = styled(Button)`
  width: calc(50% - 10px);
  margin: 5px;
`
const Offer_Title = styled(Text)`
  width: calc(50% - 10px);
  margin: 5px 5px 2px 5px;
  white-space: pre-wrap;
  font-weight: bold;
`
const Old__Offers_Holder = styled(FlexBox)`
  border-top: 1px solid ${(props: { theme: any; }) => props.theme.borderColor};
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Old__Offer = styled(FlexBox)`
  flex-direction: column;
  width: calc(50% - 10px);
  margin: 5px;
`
const New__Offer = styled(FlexBox)`
  flex-direction: column;
  width: 50%;
  margin: 10px auto 0 auto
`
const New__Offer__Title = styled(Text)`
  width: 100%;
  margin-bottom: 2px;
  font-weight: bold;

`
const New__Object__Image = styled(FlexBox)`
  width: 100%;
  height: 130px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${(props: { theme: any; }) => props.theme.placeholderColor};
  background-image: url(${(props: { image?: any; }) => props.image});
  @media (min-width: 700px) {
    height: 150px;
  }
  @media (max-width: 500px) {
    height: 100px;
  }
`
const CameraIcon = styled(FontAwesomeIcon)`
  width: 80%;
  height: 80%;
  margin: auto;
  color: ${(props: { theme: any; }) => props.theme.color};
  background-color: transparent;
`
const Section__Title = styled(Text)`
  margin-top: 5px;
  margin-left: 5px;
  font-size: 15px;
  font-weight: bold;
`


export const Styled = {
    Offer__Pictures__Holder,
    Offer__Picture,
    Modal__Content__Holder,
    New__Offer_Holder,
    Offer__Title__Holder,
    Offer__Buttons__Holder,
    Offer__Button,
    Offer_Title,
    Old__Offers_Holder,
    Old__Offer,
    New__Offer,
    New__Offer__Title,
    New__Object__Image,
    CameraIcon,
    Section__Title,
}