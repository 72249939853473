import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../../lib/UIBookBrd/Text";


const Round__Toggle__Content__Holder = styled(FlexBox)`
  position: relative;
  width: 100%;
  height: 100%;
`

const Round__Toggle = styled(FlexBox)<{ isOpen: boolean }>`
  height: 60px;
  width: 60px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: ${({theme}) => theme.backgroundColor};
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  @media (min-width: 1255px) {
    right: calc((100vw - 1100px) / 2 - 68px);
  }
`

const Round__Toggle__Icon = styled(FontAwesomeIcon)`
  height: 30px;
  width: 30px;
  color: ${({theme}) => theme.brdExclusiveColor};
  margin: 16px auto 0 auto;
`
const Round__Toggle__Notification__Icon = styled(FlexBox)`
  @keyframes ToggleShakeEffect {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(0);
    }
    52.5% {
      transform: rotate(25deg);
    }
    55% {
      transform: rotate(0);
    }
    57.5% {
      transform: rotate(-25deg);
    }
    60% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(0);
    }
  }
  position: absolute;
  top: -3px;
  left: -1px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.brdExclusiveColor};
  animation: ToggleShakeEffect 5s infinite linear;
`
const Round__Toggle__Notification__Label = styled(Text)`
  margin: auto;
  font-weight: bold;
  color: ${({theme}) => theme.color};

`
const Round__Inside__Icon__Color__Fill = styled(FlexBox)`
  position: absolute;
  top: 16px;
  left: 20px;
  height: 20px;
  width: 20px;
  z-index: -1;
  background-color: ${({theme}) => theme.color};;
`
export const Styled = {
    Round__Inside__Icon__Color__Fill,
    Round__Toggle__Icon,
    Round__Toggle,
    Round__Toggle__Content__Holder,
    Round__Toggle__Notification__Icon,
    Round__Toggle__Notification__Label
}