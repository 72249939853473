// @ts-ignore
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isMobileOnly} from "react-device-detect";


const FontAwesomeIcons = styled(FontAwesomeIcon)`
  width: 40%;
  height: 40%;
  margin: 30%;
  color: ${(props: { isDisabled: any; theme: any }) => props.isDisabled ? props.theme.disabledFontColor : props.theme.color};
`

const AddButton = styled.div`
  cursor: ${(props: { isDisabled: any; }) => props.isDisabled ? 'not-allowed' : 'pointer'};
  transition: 500ms;
  border: 1px solid ${(props: { theme: any; }) => props.theme.borderColor};
  border-radius: 4px;
  overflow: hidden;
  margin: auto 4px 2px 4px;
  height: ${isMobileOnly ? '30px' : '25px'};
  width: ${isMobileOnly ? '30px' : '25px'};

  &:hover {
    background-color: ${(props: { isDisabled: any; theme: any }) => props.isDisabled ? undefined : props.theme.optionHoverColor};;
  }

  &:hover ${FontAwesomeIcons} {
  }
`;

const IconHolder = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const Styled = {
    FontAwesomeIcons,
    AddButton,
    IconHolder
};