import Select from "../../lib/UIBookBrd/SelectBookBrd/Select";
import React from "react";
import {useTranslation} from "react-i18next";
import {TRANSPORT_FIELD} from "../../lib/DefaultFormikValue/DefaultSearchFormValues";
import {includes, isUndefined} from "lodash-es";
import {checkBoxType} from "../../lib/Types";
import Text from "../../lib/UIBookBrd/Text";
import CheckBox from "../../lib/UIBookBrd/CheckBox";
import {Styled} from "./Transport.styles";

type TransportPickerProps = {
    options: any,
    isSelectMode?: boolean
    filterFormValue: any
    setFilterValue: (name: string | string[], values: string | any[]) => void
}


const TransportPicker = (
    {
        isSelectMode,
        options,
        filterFormValue,
        setFilterValue
    }: TransportPickerProps) => {

    const {t} = useTranslation();

    const onApply = (value: any) => {
        const newOptions = value?.map((item: any) => item.value)
        setFilterValue(TRANSPORT_FIELD, newOptions)
    }
    const onChange = (value: any) => {
        const validValue = filterFormValue ?? []
        console.log({value, validValue})
        if (includes(validValue, value)) {
            setFilterValue(TRANSPORT_FIELD, validValue.filter((option: any) => option !== value))

        } else {
            setFilterValue(TRANSPORT_FIELD, [...validValue, value])
        }
    }


    return !isSelectMode ? (
        <Styled.Transport__Holder>
            <CheckBox
                type={checkBoxType.circle}
                onChange={() => onApply([])}
                isCheckedProp={isUndefined(filterFormValue)}
                title={(
                    <Text
                        style={{
                            margin: '4.5px  0'
                        }}
                    >
                        {t('lbl_any_transport')}
                    </Text>
                )}
            />
            {options.map((option: any) => {
                return (
                    <CheckBox
                        key={`Transport_checkbox_${option.value}`}
                        type={checkBoxType.square}
                        onChange={() => onChange(option?.value)}
                        isCheckedProp={includes(filterFormValue, option?.value)}
                        title={(
                            <Text
                                style={{
                                    margin: '4.5px  0'
                                }}
                            >
                                {option.label}
                            </Text>
                        )}
                    />
                )
            })}
        </Styled.Transport__Holder>
    ) : (
        <>
            <Select
                title={t('lbl_transport')}
                placeholder={t('lbl_any_transport')}
                appliedList={options?.filter((item: any) => includes(filterFormValue, item.value))}
                onApplyChangeHandler={onApply}
                options={options}
            />

        </>
    )

}

export default TransportPicker