import {Styled} from "../SearchTripLowerPart.styles";
import {useTranslation} from "react-i18next";
import HotelOfferBox from "../../../Offers/HotelOfferBox";
import {NextButton, PrevButton} from "../../../../lib/UIBookBrd/Swiper/Buttons";
import {SwiperContainer, SwiperSlide} from "../../../../lib/UIBookBrd/Swiper";
import {isMobileOnly} from "react-device-detect";
import {useRef, useState} from "react";

type RecommendedHotelByReviewProps = {
    list: any[]
}

const defaultSliderState = {
    progress: 0,
    index: 0,
}

const RecommendedHotelByReview = (
    {
        list
    }: RecommendedHotelByReviewProps
) => {

    const {t} = useTranslation()

    const [sliderState, setSliderState] = useState(defaultSliderState);

    const swiperRef = useRef<any>(null)

    const onPrevButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slidePrev();
    }

    const onNextButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slideNext();
    }

    const onActiveIndexChange = (swiper: any) => {
        setSliderState((state: any) => ({
            ...state,
            progress: swiper.progress,
            index: swiper?.activeIndex,
        }));
    };


    return (
        <>
            <Styled.Main__Label>
                {t('lbl_recommended_hotels')}
            </Styled.Main__Label>
            <Styled.Recommended__Hotel__Holder>
                {sliderState.index !== 0 && (
                    <PrevButton
                        onClick={onPrevButtonClick}
                    />
                )}
                <SwiperContainer
                    onActiveIndexChange={onActiveIndexChange}
                    ref={swiperRef}
                >
                    {list.map((option: any, index: number) => (
                        <SwiperSlide
                            style={{
                                maxWidth: isMobileOnly ? 'calc(100% - 5px)' : '300px',
                                margin: isMobileOnly || index === 0 ? '5px' : '10px',
                            }}
                        >
                            <HotelOfferBox
                                offerData={option?.offer}
                            />
                        </SwiperSlide>
                    ))}
                </SwiperContainer>
                {sliderState.progress < 1 && (
                    <NextButton
                        onClick={onNextButtonClick}
                    />
                )}

            </Styled.Recommended__Hotel__Holder>
        </>
    )
}

export default RecommendedHotelByReview