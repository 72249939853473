import Modal from "../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import {Styled} from "./DescriptionModal.styles";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";


type DescriptionModalProps = {
    modalTitle?: string
    descriptionValue: any
    isOpen: boolean
    setIsOpen: any
}


const DescriptionModal = (
    {
        modalTitle,
        descriptionValue,
        isOpen,
        setIsOpen
    }: DescriptionModalProps
) => {

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const {t} = useTranslation();

    useOnClickOutside(
        modalElement,
        () => {
            setIsOpen(false)
        },
    );

    return (
        <Modal
            ref={setModalElement}
            showOverlay={true}
            title={modalTitle ? modalTitle : t('lbl_description')}
            maxHeight={60}
            width={1100}
            isOpen={isOpen}
            onClose={() => {
                setIsOpen(false)
            }}
        >
            <Styled.Content__Holder>
                {descriptionValue && descriptionValue?.map((section: any, index: number) => {

                    const subject = section?.Subject

                    const data = section?.Body

                    return index !== 0 ? (
                        <>
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            marginLeft: "2px"
                                        }}
                                    >
                                        {subject}
                                    </span>
                            <div
                                dangerouslySetInnerHTML={{__html: data}}
                            />
                            <br/>
                        </>
                    ) : null
                })}
            </Styled.Content__Holder>
        </Modal>
    )
}


export default DescriptionModal