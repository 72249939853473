import FlexBox from "../../lib/UIBookBrd/FlexBox";
import React from "react";

const SearchFlight = () => {
    return (
        <FlexBox
            style={{
                width: '100%',
            }}
        >
            TEST :)
        </FlexBox>
    )
}
export default SearchFlight