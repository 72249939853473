import {Styled} from "../../Navigation.styles";
import React from "react";
import {useTranslation} from "react-i18next";

type OptionListProps = {
    listOfOptions: any[]
}

const OptionList = (
    {
        listOfOptions
    }: OptionListProps
) => {

    const {t} = useTranslation()

    return (
        <Styled.Regular__Options__Holder>
            {listOfOptions?.filter((item: any) => item.isVisible)?.map((option: any) => {
                return (
                    <Styled.Single__Option__Box
                        onClick={() => {
                            option.onClick(option.navigate)
                        }}
                    >
                        <Styled.Single__Option__Icon
                            icon={option?.icon}
                        />
                        <Styled.Single__Option__Label>
                            {t(option?.label)}
                        </Styled.Single__Option__Label>

                    </Styled.Single__Option__Box>
                )
            })}

        </Styled.Regular__Options__Holder>
    )
}

export default OptionList