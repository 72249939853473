import {Styled} from "./DetailsSection.styles";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";


type CloseAbleSectionProps = {
    disabledSection: boolean
    isOpen: boolean
    onClick: () => void
    sectionLabel: string
    isLast?: boolean
}

const CloseAbleSection = (
    {
        isLast,
        disabledSection,
        isOpen,
        onClick,
        sectionLabel
    }: CloseAbleSectionProps
) => {

    return (
        <Styled.Section__Name__Holder
            disabledSection={!!disabledSection}
            isOpen={!!isOpen}
            isLast={!!isLast}
            onClick={onClick}
        >
            <Styled.Section__Label>
                {sectionLabel}
            </Styled.Section__Label>
            <Styled.Section__Chevron__Icon
                icon={isOpen ? faChevronUp : faChevronDown}
            />
        </Styled.Section__Name__Holder>
    )
}
export default CloseAbleSection