import {Styled} from "../SearchTripLowerPart.styles";
import {useTranslation} from "react-i18next";
import RecommendedOfferTypeBox from "./RecommendedOfferTypeBox";
import {SwiperContainer, SwiperSlide} from "../../../../lib/UIBookBrd/Swiper";
import {useRef, useState} from "react";
import {isMobileOnly} from "react-device-detect";
import {NextButton, PrevButton} from "../../../../lib/UIBookBrd/Swiper/Buttons";
import {recommendedOfferType} from "../../../../lib/Types";

const defaultSliderState = {
    progress: 0,
    index: 0,
}
type RecommendedOfferTypes = {
    list: recommendedOfferType[]
}

const RecommendedOfferTypes = (
    {
        list
    }: RecommendedOfferTypes
) => {

    const {t} = useTranslation()

    const [sliderState, setSliderState] = useState(defaultSliderState);

    const swiperRef = useRef<any>(null)

    const onPrevButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slidePrev();
    }

    const onNextButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slideNext();
    }

    const onActiveIndexChange = (swiper: any) => {
        setSliderState((state: any) => ({
            ...state,
            progress: swiper.progress,
            index: swiper?.activeIndex,
        }));
    };

    return (
        <>
            <Styled.Main__Label>
                {t('lbl_recommended_offer_types')}
            </Styled.Main__Label>
            <Styled.Recommended__Offer__Types__Holder>
                {sliderState.index !== 0 && (
                    <PrevButton
                        onClick={onPrevButtonClick}
                    />
                )}
                <SwiperContainer
                    onActiveIndexChange={onActiveIndexChange}
                    ref={swiperRef}
                >
                    {list.map((option: recommendedOfferType, index: number) => (
                        <SwiperSlide
                            key={`recommended_offer_type_${index}`}
                            style={{
                                maxWidth: isMobileOnly ? 'calc(50% - 10px)' : '189px',
                                minWidth: isMobileOnly ? '180px' : 'unset',
                                margin: index === 0 || index === list.length ? '0' : '10px',
                            }}
                        >
                            <RecommendedOfferTypeBox
                                value={option}
                            />
                        </SwiperSlide>
                    ))}
                </SwiperContainer>
                {sliderState.progress < 1 && (
                    <NextButton
                        onClick={onNextButtonClick}
                    />
                )}
            </Styled.Recommended__Offer__Types__Holder>
        </>
    )

}

export default RecommendedOfferTypes