import {Styled} from "./TripSearchResultPage.styles";
import React, {useContext, useEffect, useState} from "react";
import LoadingScreen from "../../LoadingScreen";
import useSearchFormValues, {searchFormValidation} from "../../../lib/UseHooksBookBrd/useSearchFormValues";
import {DATE_RANGE_FIELD} from "../../../lib/DefaultFormikValue/DefaultSearchFormValues";
import {useDebouncedCallback} from "use-debounce";
import OffersListHolder from "./Content/TripOfferList/OffersListHolder";
import AdditionalComponentsHolder from "./Content/AdditionalComponentsHolder";
import useTripResultsValues from "./useTripResultsValues";
import ErrorModal from "./Content/ErrorModal";
import {Search_Proto} from "../../../lib/Context/Context";
import {isEmpty} from "lodash-es";

const TripSearchResultPageContent = () => {

    const {
        state,
        handleForceRefreshList,
        operatorInnerSizes,
    } = useTripResultsValues()

    const [hashValue, setHashValue] = useContext<any>(Search_Proto)

    const {searchFormValue} = useSearchFormValues()

    const [isError, setIsError] = useState(false)

    const getSizes = useDebouncedCallback(() => {
        operatorInnerSizes(window?.innerHeight - 57, window.innerWidth)
    }, 50)

    useEffect(() => {
        window?.addEventListener('resize', getSizes)
        return () => {
            window?.addEventListener('resize', getSizes)
        }
    }, [])

    useEffect(() => {
        if (!isEmpty(hashValue?.AdditionalCommands)) {
            if (hashValue?.AdditionalCommands?.forceSecondStepRefreshList) {
                setHashValue((state: any) => ({
                    ...state,
                    AdditionalCommands: {}
                }))
                handleForceRefreshList()
            }
        }
    }, [hashValue])

    useEffect(() => {
        if (searchFormValidation(searchFormValue).errorList[DATE_RANGE_FIELD] === 'OUTDATED_START_DATE') {
            setIsError(true)
        } else {
            setIsError(false)
        }
    }, [searchFormValue])

    return (
        <Styled.BackgroundForResultPage>
            {isError ? (
                <ErrorModal/>
            ) : (
                <>
                    <LoadingScreen
                        isLoading={state.isLoading}
                    />
                    <>
                        <AdditionalComponentsHolder/>
                        <OffersListHolder/>
                    </>
                </>
            )}
        </Styled.BackgroundForResultPage>
    )
}
export default TripSearchResultPageContent