import {useTranslation} from 'react-i18next';
import BrdLogo from "../BrdLogos";
import React, {useEffect, useState} from "react";
import {Styled} from './Navigation.styles';
import UserAccountTap from "../UserAccount";
import Settings from "../Settings";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import useNavigation from "../../lib/UseHooksBookBrd/useNavigation";
import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";
import useBookBrdPageFunctions from "../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {Local_Settings_Values} from "../../lib/PageConfig";
import {Language_Images_Flags} from "../../LanguageImages";
import {
    faBars,
    faClipboardList,
    faHeart,
    faPortalEnter,
    faPortalExit,
    faQuestion,
    faUser,
    faUserPlus,
    faVirus
} from "@fortawesome/pro-solid-svg-icons";
import ManageAccountDrawer from "./ManageAccountDrawer";
import {
    CREATE_ACCOUNT,
    FIRST_STEP_TRIP_SEARCH,
    LOGIN,
    MANAGE_ACCOUNT,
    OPERATOR_INFORMATION,
    SOMEONE_ACCOUNT
} from "../../lib/Routes";
import useUserData from "../../lib/UseHooksBookBrd/useUserData";
import {userPageViews} from "../../lib/Types";
import useStorage from "../../lib/UseHooksBookBrd/useStorage";

type navigationProps = {}


const Navigation = ({}: navigationProps) => {

    const {t} = useTranslation();

    const reRoute = useNavigation()

    const [localSettings, _] = useStorage<any>('localStorage', 'LocalSettings', Local_Settings_Values)

    const {
        isLogged: isUserLogged,
        userBackgroundColor,
        userImage,
        userName
    } = useUserData()

    const [isSelectOpen, setIsSelectOpen] = useState(false)

    const {
        onOpenSettings,
        setOpenClientDrawer,
        onCloseSettings,
        onOpenCurrencyModal,
        onOpenLanguageModal,
        setCloseClientDrawer,
        toggleFavouriteOptions,
        toggleTokenModal,
        isSettingsOpen,
        isOpenClientDrawer,
    } = useBookBrdPageFunctions()


    const [settingsDrawer, setSettingsDrawer] = useState<HTMLDivElement | null>(null);

    const [isSmallWidth, setIsSmallWidth] = useState<any>(window?.innerWidth < 600)

    const navigateChangeHandler = (navigationString: any) => {
        setCloseClientDrawer()
        reRoute(`${navigationString}`)

    }

    const onLogoClickChangeHandler = () => {
        reRoute(FIRST_STEP_TRIP_SEARCH)
        setCloseClientDrawer()
    }

    const isVisible = (isLogged: boolean | null, isSmallWindowOnly: boolean) => {
        if (typeof isLogged === 'boolean') {
            return isLogged === isUserLogged
        } else {
            if (isSmallWindowOnly) {
                return isSmallWidth
            } else {
                return true
            }
        }
    }

    const showCustomNavigation = () => {
        if (window.location.pathname.includes(OPERATOR_INFORMATION)) {
            return (
                <>
                    <BrdLogo
                        style={{
                            cursor: 'initial',
                            margin: 'auto',
                            paddingRight: '10px'
                        }}
                    />
                </>
            )
        }
        return null
    }

    const listOfOptions: any = [
        {
            icon: faQuestion,
            label: 'test only',
            navigate: `${SOMEONE_ACCOUNT}/Maks/${userPageViews.main}`,
            isVisible: isVisible(null, false),
            onClick: navigateChangeHandler
        },
        {
            icon: faHeart,
            label: 'lbl_your_favourite_offers',
            navigate: 'favourite_offers',
            isVisible: isVisible(null, false),
            onClick: (value: any) => {
                setCloseClientDrawer()
                toggleFavouriteOptions(true)
            },
        },
        {
            icon: faVirus,
            label: 'ADD TOKEN DEV ONLY!',
            navigate: null,
            isVisible: isVisible(null, false),
            onClick: (value: any) => {
                setCloseClientDrawer()
                toggleTokenModal(true)
            },
        },
        {
            icon: faPortalEnter,
            label: 'lbl_login',
            navigate: LOGIN,
            isVisible: isVisible(false, false),
            onClick: navigateChangeHandler,
        },
        {
            icon: faUserPlus,
            label: 'lbl_sign_up',
            navigate: CREATE_ACCOUNT,
            isVisible: isVisible(false, false),
            onClick: navigateChangeHandler,
        },
        {
            icon: faUser,
            label: 'lbl_view_profile',
            navigate: `${MANAGE_ACCOUNT}/Main`,
            isVisible: isVisible(true, false),
            onClick: navigateChangeHandler,
        },
        {
            icon: faClipboardList,
            label: 'lbl_my_trips',
            navigate: `${MANAGE_ACCOUNT}/${userPageViews.myTrips}`,
            isVisible: isVisible(true, false),
            onClick: navigateChangeHandler,
        },
        {
            icon: faPortalExit,
            label: 'lbl_logout',
            navigate: '/',
            isVisible: isVisible(true, false),
            onClick: navigateChangeHandler,
        }
    ]


    useOnClickOutside(settingsDrawer, () => !isSelectOpen && onCloseSettings());


    const countryImage = Language_Images_Flags[localSettings?.SelectedLanguage]

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsSmallWidth(window?.innerWidth < 600)
        })
        return () => {
            window.removeEventListener('resize', () => {
                setIsSmallWidth(window?.innerWidth < 600)
            })
        }
    }, [])

    return (
        <>
            <Settings
                isSelectOpen={isSelectOpen}
                setIsSelectOpen={setIsSelectOpen}
                onCloseSettings={onCloseSettings}
                showSettings={isSettingsOpen}
                ref={setSettingsDrawer}
            />
            {isOpenClientDrawer && isSmallWidth && (
                <ManageAccountDrawer
                    listOfOptions={listOfOptions}
                    isSmallWidth={isSmallWidth}
                />
            )}
            <Styled.NavigationHolder>
                <Styled.Navigation>
                    {showCustomNavigation() ? (
                        <>
                            {showCustomNavigation()}
                        </>
                    ) : (
                        <>
                            {!isSmallWidth ? (
                                <Styled.BiggerWidthHolder>
                                    <BrdLogo
                                        style={{marginRight: "auto"}}
                                        onClick={onLogoClickChangeHandler}
                                    />
                                    <UserAccountTap
                                        countryImage={countryImage}
                                        isSmallWidth={isSmallWidth}
                                        listOfOptions={listOfOptions?.filter((item: any) => item?.isVisible)}
                                        accountDropDown={isOpenClientDrawer}
                                        setOpenClientDrawer={setOpenClientDrawer}
                                        setCloseClientDrawer={setCloseClientDrawer}
                                        userBackgroundColor={userBackgroundColor}
                                        logged={isUserLogged}
                                        image={userImage}
                                        accountText={userName}
                                    />

                                    <Styled.Country__Image
                                        src={countryImage}
                                        alt={localSettings?.SelectedLanguage}
                                        onClick={onOpenLanguageModal}
                                        style={{
                                            height: '35px',
                                            width: '35px'
                                        }}

                                    />
                                    <Styled.Currency__Label__Navigation
                                        onClick={onOpenCurrencyModal}
                                    >
                                        {localSettings?.PreferredCurrency ? localSettings?.PreferredCurrency : t('lbl_currency').substring(0, 3).toUpperCase()}
                                    </Styled.Currency__Label__Navigation>
                                    <Styled.SettingsIcon
                                        onClick={onOpenSettings}
                                        icon={faGear}
                                    />
                                </Styled.BiggerWidthHolder>
                            ) : (
                                <Styled.SmallerWidthHolder>
                                    <Styled.Bars__Small__Width
                                        onClick={setOpenClientDrawer}
                                        icon={faBars}/>
                                    <BrdLogo
                                        onClick={onLogoClickChangeHandler}
                                        style={{
                                            margin: 'auto',
                                            paddingRight: '10px'
                                        }}
                                    />
                                    <Styled.SettingsIcon
                                        style={{margin: "auto 0"}}
                                        onClick={onOpenSettings}
                                        icon={faGear}
                                    />
                                </Styled.SmallerWidthHolder>
                            )}
                        </>
                    )}
                </Styled.Navigation>
            </Styled.NavigationHolder>
        </>
    )
}
export default Navigation