import {Styled} from "../../ManageContent.styles"
import useConfirmationPage from "../../../useConfirmationPage";
import {useTranslation} from "react-i18next";

const Participants = () => {

    const {participants} = useConfirmationPage()

    const {t} = useTranslation()

    return (
        <Styled.Participants__Holder>
            {participants?.map((participant: any) => (
                <Styled.Participant__Holder>
                    <Styled.Participant__Name>
                        {participant.name}{' '}{participant.surname}{'.'}
                    </Styled.Participant__Name>
                    <Styled.Change__User__Data__Button
                        label={t('lbl_change_data')}
                    />
                </Styled.Participant__Holder>
            ))}
        </Styled.Participants__Holder>
    )
}

export default Participants