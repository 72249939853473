import React from "react";
import {faSuitcase} from "@fortawesome/free-solid-svg-icons";
import {Styled} from "./TypeOfSearchSelect.styles";
import {faFerrisWheel, faHotel, faPlaneDeparture} from "@fortawesome/pro-solid-svg-icons";

type TypeOfSearchIconProps = {
    label: string
}
const TypeOfSearchIcon = ({label}: TypeOfSearchIconProps) => {

    const getIcon = (type: string) => {

        switch (type) {
            case 'Trip':
                return faSuitcase
            case 'Hotel':
                return faHotel
            case 'Flight':
                return faPlaneDeparture
            case 'Attractions':
                return faFerrisWheel
        }

    }


    return (
        <Styled.FontAwesomeIcons
            icon={getIcon(label) as any}
        />
    )
}
export default TypeOfSearchIcon