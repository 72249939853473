import ChosenOffer from "./Components/ChosenOffer";
import Participants from "./Components/Participants";
import Payment from "./Components/Payment/payment";
import FinalConfirmation from "./Components/FinalConfirmation";
import {Styled} from "./TripSearchBookingPage";
import LoadingResults from "./Components/LoadingResults";

const BookingPageContent = () => {

    return (
        <>
            <Styled.Trip__Search__Booking__Page__Content>
                {/*OFFER*/}
                <ChosenOffer/>
                {/*PARTICPANTS*/}
                <Participants/>
                {/*PAYMENT*/}
                <Payment/>
                {/*FINAL CONFIRMATION*/}
                <FinalConfirmation/>
                {/*LOADING RESULT*/}
            </Styled.Trip__Search__Booking__Page__Content>
            <LoadingResults/>
        </>
    )
}

export default BookingPageContent