import {useContext} from "react";
import {Local_Data} from "../Context/Context";
import {bestHotelsType, recommendedOfferType} from "../Types";

const useSiteConfig = () => {

    const [localDataValues, _] = useContext<any>(Local_Data)

    const quickSearchTypesBoxesList: recommendedOfferType[] = [
        {
            image: 'https://hotelepl.pl/wp-content/uploads/2023/01/informacje-wakacje-all-inclusive.jpg',
            label: 'AllInclusive',
            price: {
                amount: 1500,
                currency: 'PLN'
            }
        },
        {
            image: 'https://firebasestorage.googleapis.com/v0/b/bookbrd.appspot.com/o/BookBrdLinkImage.png?alt=media&token=7d39f0d4-4f57-416c-9daa-88b8335a762f',
            label: 'Only at BookBrd',
            price: {
                amount: 750,
                currency: 'PLN'
            }
        },
        {
            image: 'https://trivabook.pl/thumbs/4/porady-wakacje-first-minute-co-to-znaczy-4747248b.jpg',
            label: 'First minute',
            price: {
                amount: 850,
                currency: 'PLN'
            }
        },
        {
            image: 'https://nikwax.files.wordpress.com/2013/08/endofsummer.jpg',
            label: 'Summer 2024',
            price: {
                amount: 1250,
                currency: 'PLN'
            }
        },
        {
            image: 'https://wakacjeamigos.pl//uploads/blog/0d62b439-9209-422f-9033-517c11c05c5f.jpg',
            label: 'Exclusive hotels',
            price: {
                amount: 2800,
                currency: 'PLN'
            }
        },
        {
            image: 'https://thumbs.dreamstime.com/b/winter-mountains-sunny-day-colorado-united-states-rocky-landscape-35916222.jpg',
            label: 'Winter 2024',
            price: {
                amount: 1350,
                currency: 'PLN'
            }
        },
        {
            image: 'https://d1fegwn2wjh0cs.cloudfront.net/04-13-2023/t_7fd05ee79f17487e9336e1df86e0af90_name_7d1c824dcb934d64917642bcb9d38a77.jpeg',
            label: '2 weeks with family',
            price: {
                amount: 4800,
                currency: 'PLN'
            }
        },
        {
            image: 'https://www.beverlyhillsmagazine.com/wp-content/uploads/Thailand-Exclusive-Escapes-Exotic-Travel-Luxury-Travel-Travel-Magazine-Beverly-Hills-Magazine.jpg',
            label: 'Exotic Vacations',
            price: {
                amount: 1600,
                currency: 'PLN'
            }
        },
        {
            image: 'https://img.wprost.pl/img/last-minute/5e/0b/859ec928e3312fef664d31846c32.jpeg',
            label: 'Last minute',
            price: {
                amount: 900,
                currency: 'PLN'
            }
        },
        {
            image: 'https://pipandthecity.com/wp-content/uploads/2023/08/city-break-packing-bag-1.jpg',
            label: 'City break',
            price: {
                amount: 1100,
                currency: 'PLN'
            }
        },
    ]

    const bestHotelsList: bestHotelsType[] = [
        {
            offer: {
                "Base": {
                    "XCity": {
                        "Id": "4815",
                        "Name": "Bavaro"
                    },
                    "XCode": {
                        "Id": 5422,
                        "Name": "Occidental Punta Cana"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "XRegion": {
                        "Id": "145",
                        "Name": "Punta Cana"
                    },
                    "Availability": {
                        "base": "available",
                        "message": ""
                    },
                    "DatasetInfo": {
                        "name": "",
                        "private": false
                    },
                    "DepartureLocation": {
                        "Id": "0x576172737a617761",
                        "Name": "Warszawa"
                    },
                    "Duration": 12,
                    "NightsBeforeReturn": 10,
                    "OfferId": "b1b98ecf6425def46ab1d99517dc55a2cfb5fe9ca00962628a0248bf70df05ec|WEZX|Mnx8",
                    "Operator": "WEZX",
                    "OperatorDesc": "Coral Travel-Egzotyka",
                    "OriginalPrice": {
                        "FirstPerson": {
                            "amount": "8775.28",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "17550.57",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Price": {
                        "FirstPerson": {
                            "amount": "8775.28",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "17550.57",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "ReturnDate": "2024-03-01",
                    "StartDate": "2024-02-19",
                    "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/WEZY/IMG/3593/main.webp",
                    "Transfer": "unspecified",
                    "UniqueObjectId": "b1b98ecf6425def46ab1d99517dc55a2cfb5fe9ca00962628a0248bf70df05ec"
                },
                "Accommodation": {
                    "XCity": {
                        "Id": "4815",
                        "Name": "Bavaro"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "Attributes": [
                        "location_exotic_offers",
                        "location_seaside_resorts",
                        "location_directly_on_the_beach",
                        "location_near_airport",
                        "facility_free_wifi",
                        "facility_wellness",
                        "facility_parking",
                        "facility_for_families_with_childrens",
                        "facility_fitness_or_gym",
                        "facility_kindergarten",
                        "facility_internet_access",
                        "facility_air_conditioning",
                        "facility_night_life",
                        "activity_animation_for_adults",
                        "activity_tennis",
                        "activity_golf",
                        "activity_water_sports",
                        "activity_diving",
                        "activity_windsurfing"
                    ],
                    "Category": 5,
                    "Code": "3593",
                    "ExtTripAdvisor": {
                        "count": 9518,
                        "description": "https://www.tripadvisor.com/Hotel_Review-g3176298-d247957-Reviews-Occidental_Punta_Cana-Bavaro_Punta_Cana_La_Altagracia_Province_Dominican_Republic.html?m=62467",
                        "image": "https://staticpict.merlinx.pl/tripadvisor/4.0-62467-5.svg",
                        "rating": 4,
                        "review": "https://www.tripadvisor.com/UserReview-g3176298-d247957-Occidental_Punta_Cana-Bavaro_Punta_Cana_La_Altagracia_Province_Dominican_Republic.html?m=62467"
                    },
                    "Location": {
                        "Coords": [
                            18.69706,
                            -68.434196
                        ],
                        "geoSearchDistance": 0
                    },
                    "Name": "OCCIDENTAL PUNTA CANA",
                    "Nights": 10,
                    "Room": {
                        "Id": "62992",
                        "Name": "SUPERIOR ROOM"
                    },
                    "Service": {
                        "Id": "5",
                        "Name": "All Inclusive"
                    },
                    "Type": {
                        "Id": "H",
                        "Name": "Hotel"
                    },
                    "XService": {
                        "Id": 1,
                        "Name": "Wszystko w cenie"
                    }
                },
                "Transport": {
                    "Flight": {
                        "AirlineType": "gds",
                        "Luggage": "unspecified",
                        "Out": [
                            {
                                "Airline": {
                                    "Code": "LO",
                                    "Name": "LOT Polish Airlines"
                                },
                                "Departure": {
                                    "Code": "WAW",
                                    "Date": "2024-02-19",
                                    "Name": "Warszawa",
                                    "Time": "06:15"
                                },
                                "Destination": {
                                    "Code": "PUJ",
                                    "Date": "2024-02-19",
                                    "Name": "Punta Cana",
                                    "Time": "12:40"
                                },
                                "FlightNo": "LO 6139"
                            }
                        ],
                        "Ret": [
                            {
                                "Airline": {
                                    "Code": "LO",
                                    "Name": "LOT Polish Airlines"
                                },
                                "Departure": {
                                    "Code": "PUJ",
                                    "Date": "2024-02-29",
                                    "Name": "Punta Cana",
                                    "Time": "17:45"
                                },
                                "Destination": {
                                    "Code": "WAW",
                                    "Date": "2024-03-01",
                                    "Name": "Warszawa",
                                    "Time": "08:45"
                                },
                                "FlightNo": "LO 6140"
                            }
                        ],
                        "Stops": "no"
                    }
                },
                "Online": {
                    "actions": [
                        {
                            "action": "checkstatus",
                            "fieldList": [
                                "Base.Availability",
                                "Base.Price",
                                "Accommodation.AvailableRoomsCount",
                                "Transport.*"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        }
                    ]
                }
            }
        },
        {
            offer: {
                "Base": {
                    "XCity": {
                        "Id": "10300",
                        "Name": "La Terrenas"
                    },
                    "XCode": {
                        "Id": 5424,
                        "Name": "Bahia Principe Grand El Portillo"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "XRegion": {
                        "Id": "2893",
                        "Name": "Samana"
                    },
                    "Availability": {
                        "base": "available",
                        "message": ""
                    },
                    "Catalog": "DAN",
                    "DatasetInfo": {
                        "name": "",
                        "private": false
                    },
                    "DepartureLocation": {
                        "Id": "0x4b61746f77696365",
                        "Name": "Katowice"
                    },
                    "Duration": 8,
                    "NightsBeforeReturn": 7,
                    "OfferId": "7da92bf4d9d1f106390c69a2875d62b5ca90ef00f2ab70c925cb2ab1bad9f674|RNBW|Mnx8",
                    "Operator": "RNBW",
                    "OperatorDesc": "Rainbow",
                    "OriginalPrice": {
                        "FirstPerson": {
                            "amount": "9000.31",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "18000.62",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Price": {
                        "FirstPerson": {
                            "amount": "9000.31",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "18000.62",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Promotion": "XXXX",
                    "ReturnDate": "2024-03-01",
                    "StartDate": "2024-02-22",
                    "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/RNBW/IMG/3533/main.webp",
                    "Transfer": "unspecified",
                    "UniqueObjectId": "7da92bf4d9d1f106390c69a2875d62b5ca90ef00f2ab70c925cb2ab1bad9f674"
                },
                "Accommodation": {
                    "XCity": {
                        "Id": "10300",
                        "Name": "La Terrenas"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "Attributes": [
                        "location_exotic_offers",
                        "location_seaside_resorts",
                        "location_directly_on_the_beach",
                        "location_near_airport",
                        "facility_free_wifi",
                        "facility_wellness",
                        "facility_home_appliances",
                        "facility_parking",
                        "facility_for_families_with_childrens",
                        "facility_fitness_or_gym",
                        "facility_outdoor_pool",
                        "facility_kindergarten",
                        "facility_water_slides",
                        "facility_internet_access",
                        "facility_air_conditioning",
                        "facility_night_life",
                        "activity_animation_for_adults",
                        "activity_sport",
                        "activity_tennis",
                        "activity_water_sports",
                        "activity_diving",
                        "activity_riding",
                        "activity_windsurfing"
                    ],
                    "Category": 5,
                    "Code": "3533",
                    "ExtTripAdvisor": {
                        "count": 8073,
                        "description": "https://www.tripadvisor.com/Hotel_Review-g259440-d149384-Reviews-Bahia_Principe_Grand_El_Portillo-Las_Terrenas_Samana_Province_Dominican_Republic.html?m=62467",
                        "image": "https://staticpict.merlinx.pl/tripadvisor/4.5-62467-5.svg",
                        "rating": 4.5,
                        "review": "https://www.tripadvisor.com/UserReview-g259440-d149384-Bahia_Principe_Grand_El_Portillo-Las_Terrenas_Samana_Province_Dominican_Republic.html?m=62467"
                    },
                    "Location": {
                        "Coords": [
                            19.323462,
                            -69.495148
                        ],
                        "geoSearchDistance": 0
                    },
                    "Name": "Bahia Principe Grand El Portillo",
                    "Nights": 7,
                    "Room": {
                        "Id": "4YI",
                        "Name": "2 os. pokoj standardowy"
                    },
                    "Service": {
                        "Id": "A",
                        "Name": "All Inclusive"
                    },
                    "Type": {
                        "Id": "H",
                        "Name": "Hotel"
                    },
                    "XService": {
                        "Id": 1,
                        "Name": "Wszystko w cenie"
                    }
                },
                "Transport": {
                    "Flight": {
                        "AirlineType": "gds",
                        "Luggage": "unspecified",
                        "Out": [
                            {
                                "Airline": {
                                    "Code": "LO6545",
                                    "Name": "LO6545"
                                },
                                "Departure": {
                                    "Code": "KTW",
                                    "Date": "2024-02-22",
                                    "Name": "Katowice",
                                    "Time": "10:05"
                                },
                                "Destination": {
                                    "Code": "POP",
                                    "Date": "2024-02-22",
                                    "Name": "Puerto Plata",
                                    "Time": "15:55"
                                },
                                "FlightNo": "LO6545"
                            }
                        ],
                        "Ret": [
                            {
                                "Departure": {
                                    "Code": "POP",
                                    "Date": "2024-02-29",
                                    "Name": "Puerto Plata",
                                    "Time": "17:55"
                                },
                                "Destination": {
                                    "Code": "KTW",
                                    "Date": "2024-03-01",
                                    "Name": "Katowice",
                                    "Time": "08:45"
                                }
                            }
                        ],
                        "Stops": "no"
                    }
                },
                "Online": {
                    "actions": [
                        {
                            "action": "checktransport",
                            "fieldList": [
                                "Transport.*",
                                "Accommodation.AvailableRoomsCount"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        },
                        {
                            "action": "checkstatus",
                            "fieldList": [
                                "Base.Availability",
                                "Base.Price"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        }
                    ]
                }
            }
        },
        {
            offer: {
                "Base": {
                    "XCity": {
                        "Id": "929",
                        "Name": "Cabarete"
                    },
                    "XCode": {
                        "Id": 5425,
                        "Name": "Viva Wyndham Tangerine"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "XRegion": {
                        "Id": "144",
                        "Name": "Puerto Plata"
                    },
                    "Availability": {
                        "base": "available",
                        "message": ""
                    },
                    "Catalog": "DAN",
                    "DatasetInfo": {
                        "name": "",
                        "private": false
                    },
                    "DepartureLocation": {
                        "Id": "0x4b61746f77696365",
                        "Name": "Katowice"
                    },
                    "Duration": 8,
                    "NightsBeforeReturn": 7,
                    "OfferId": "94fbec7d9c40cf43584d6a3b6d7be6f6ca90ef00f2ab70c925cb2ab1bad9f674|RNBW|Mnx8",
                    "Operator": "RNBW",
                    "OperatorDesc": "Rainbow",
                    "OriginalPrice": {
                        "FirstPerson": {
                            "amount": "7079.64",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "14159.28",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Price": {
                        "FirstPerson": {
                            "amount": "7079.64",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "14159.28",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Promotion": "XXXX",
                    "ReturnDate": "2024-03-01",
                    "StartDate": "2024-02-22",
                    "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/RNBW/IMG/3441/main.webp",
                    "Transfer": "unspecified",
                    "UniqueObjectId": "94fbec7d9c40cf43584d6a3b6d7be6f6ca90ef00f2ab70c925cb2ab1bad9f674"
                },
                "Accommodation": {
                    "XCity": {
                        "Id": "929",
                        "Name": "Cabarete"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "Attributes": [
                        "location_exotic_offers",
                        "location_seaside_resorts",
                        "location_directly_on_the_beach",
                        "location_near_airport",
                        "location_near_the_center",
                        "facility_free_wifi",
                        "facility_wellness",
                        "facility_parking",
                        "facility_for_families_with_childrens",
                        "facility_fitness_or_gym",
                        "facility_outdoor_pool",
                        "facility_kindergarten",
                        "facility_internet_access",
                        "facility_air_conditioning",
                        "activity_animation_for_adults",
                        "activity_sport",
                        "activity_tennis",
                        "activity_golf",
                        "activity_water_sports",
                        "activity_diving",
                        "activity_riding",
                        "activity_windsurfing"
                    ],
                    "Category": 4,
                    "Code": "3441",
                    "ExtTripAdvisor": {
                        "count": 3954,
                        "description": "https://www.tripadvisor.com/Hotel_Review-g317144-d291623-Reviews-Viva_Wyndham_Tangerine-Cabarete_Puerto_Plata_Province_Dominican_Republic.html?m=62467",
                        "image": "https://staticpict.merlinx.pl/tripadvisor/4.0-62467-5.svg",
                        "rating": 4,
                        "review": "https://www.tripadvisor.com/UserReview-g317144-d291623-Viva_Wyndham_Tangerine-Cabarete_Puerto_Plata_Province_Dominican_Republic.html?m=62467"
                    },
                    "Location": {
                        "Coords": [
                            19.753532,
                            -70.415726
                        ],
                        "geoSearchDistance": 0
                    },
                    "Name": "Viva Tangerine by Wyndham",
                    "Nights": 7,
                    "Room": {
                        "Id": "32Q",
                        "Name": "2 os. Superior"
                    },
                    "Service": {
                        "Id": "A",
                        "Name": "All Inclusive"
                    },
                    "Type": {
                        "Id": "H",
                        "Name": "Hotel"
                    },
                    "XService": {
                        "Id": 1,
                        "Name": "Wszystko w cenie"
                    }
                },
                "Transport": {
                    "Flight": {
                        "AirlineType": "gds",
                        "Luggage": "unspecified",
                        "Out": [
                            {
                                "Airline": {
                                    "Code": "LO6545",
                                    "Name": "LO6545"
                                },
                                "Departure": {
                                    "Code": "KTW",
                                    "Date": "2024-02-22",
                                    "Name": "Katowice",
                                    "Time": "10:05"
                                },
                                "Destination": {
                                    "Code": "POP",
                                    "Date": "2024-02-22",
                                    "Name": "Puerto Plata",
                                    "Time": "15:55"
                                },
                                "FlightNo": "LO6545"
                            }
                        ],
                        "Ret": [
                            {
                                "Departure": {
                                    "Code": "POP",
                                    "Date": "2024-02-29",
                                    "Name": "Puerto Plata",
                                    "Time": "17:55"
                                },
                                "Destination": {
                                    "Code": "KTW",
                                    "Date": "2024-03-01",
                                    "Name": "Katowice",
                                    "Time": "08:45"
                                }
                            }
                        ],
                        "Stops": "no"
                    }
                },
                "Online": {
                    "actions": [
                        {
                            "action": "checktransport",
                            "fieldList": [
                                "Transport.*",
                                "Accommodation.AvailableRoomsCount"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        },
                        {
                            "action": "checkstatus",
                            "fieldList": [
                                "Base.Availability",
                                "Base.Price"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        }
                    ]
                }
            }
        },
        {
            offer: {
                "Base": {
                    "XCity": {
                        "Id": "2671",
                        "Name": "Samana"
                    },
                    "XCode": {
                        "Id": 6182,
                        "Name": "Bahia Principe Grand Cayacoa"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "XRegion": {
                        "Id": "2893",
                        "Name": "Samana"
                    },
                    "Availability": {
                        "base": "available",
                        "message": ""
                    },
                    "Catalog": "DAN",
                    "DatasetInfo": {
                        "name": "",
                        "private": false
                    },
                    "DepartureLocation": {
                        "Id": "0x4b61746f77696365",
                        "Name": "Katowice"
                    },
                    "Duration": 8,
                    "NightsBeforeReturn": 7,
                    "OfferId": "f02c991217bd6ee7c50464bf750ca0f9ca90ef00f2ab70c925cb2ab1bad9f674|RNBW|Mnx8",
                    "Operator": "RNBW",
                    "OperatorDesc": "Rainbow",
                    "OriginalPrice": {
                        "FirstPerson": {
                            "amount": "7978.98",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "15957.96",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Price": {
                        "FirstPerson": {
                            "amount": "7978.98",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "15957.96",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Promotion": "XXXX",
                    "ReturnDate": "2024-03-01",
                    "StartDate": "2024-02-22",
                    "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/RNBW/IMG/6588/main.webp",
                    "Transfer": "unspecified",
                    "UniqueObjectId": "f02c991217bd6ee7c50464bf750ca0f9ca90ef00f2ab70c925cb2ab1bad9f674"
                },
                "Accommodation": {
                    "XCity": {
                        "Id": "2671",
                        "Name": "Samana"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "Attributes": [
                        "location_exotic_offers",
                        "location_seaside_resorts",
                        "location_directly_on_the_beach",
                        "location_near_airport",
                        "facility_free_wifi",
                        "facility_wellness",
                        "facility_home_appliances",
                        "facility_parking",
                        "facility_for_families_with_childrens",
                        "facility_fitness_or_gym",
                        "facility_outdoor_pool",
                        "facility_kindergarten",
                        "facility_internet_access",
                        "facility_air_conditioning",
                        "facility_night_life",
                        "activity_animation_for_adults",
                        "activity_sport",
                        "activity_water_sports",
                        "activity_diving",
                        "activity_riding",
                        "activity_windsurfing",
                        "activity_sailing"
                    ],
                    "Category": 5,
                    "Code": "6588",
                    "ExtTripAdvisor": {
                        "count": 4757,
                        "description": "https://www.tripadvisor.com/Hotel_Review-g811253-d638085-Reviews-Bahia_Principe_Grand_Cayacoa-Santa_Barbara_de_Samana_Samana_Province_Dominican_Republic.html?m=62467",
                        "image": "https://staticpict.merlinx.pl/tripadvisor/4.5-62467-5.svg",
                        "rating": 4.5,
                        "review": "https://www.tripadvisor.com/UserReview-g811253-d638085-Bahia_Principe_Grand_Cayacoa-Santa_Barbara_de_Samana_Samana_Province_Dominican_Republic.html?m=62467"
                    },
                    "Location": {
                        "Coords": [
                            19.197954,
                            -69.338074
                        ],
                        "geoSearchDistance": 0
                    },
                    "Name": "Bahia Principe Grand Cayacoa",
                    "Nights": 7,
                    "Room": {
                        "Id": "5R3",
                        "Name": "3 os. pokój standardowy"
                    },
                    "Service": {
                        "Id": "B",
                        "Name": "Śniadania"
                    },
                    "Type": {
                        "Id": "H",
                        "Name": "Hotel"
                    },
                    "XService": {
                        "Id": 3,
                        "Name": "Śniadanie"
                    }
                },
                "Transport": {
                    "Flight": {
                        "AirlineType": "gds",
                        "Luggage": "unspecified",
                        "Out": [
                            {
                                "Airline": {
                                    "Code": "LO6545",
                                    "Name": "LO6545"
                                },
                                "Departure": {
                                    "Code": "KTW",
                                    "Date": "2024-02-22",
                                    "Name": "Katowice",
                                    "Time": "10:05"
                                },
                                "Destination": {
                                    "Code": "POP",
                                    "Date": "2024-02-22",
                                    "Name": "Puerto Plata",
                                    "Time": "15:55"
                                },
                                "FlightNo": "LO6545"
                            }
                        ],
                        "Ret": [
                            {
                                "Departure": {
                                    "Code": "POP",
                                    "Date": "2024-02-29",
                                    "Name": "Puerto Plata",
                                    "Time": "17:55"
                                },
                                "Destination": {
                                    "Code": "KTW",
                                    "Date": "2024-03-01",
                                    "Name": "Katowice",
                                    "Time": "08:45"
                                }
                            }
                        ],
                        "Stops": "no"
                    }
                },
                "Online": {
                    "actions": [
                        {
                            "action": "checktransport",
                            "fieldList": [
                                "Transport.*",
                                "Accommodation.AvailableRoomsCount"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        },
                        {
                            "action": "checkstatus",
                            "fieldList": [
                                "Base.Availability",
                                "Base.Price"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        }
                    ]
                }
            }
        },
        {
            offer: {
                "Base": {
                    "XCity": {
                        "Id": "1668",
                        "Name": "Puerto Plata"
                    },
                    "XCode": {
                        "Id": 6184,
                        "Name": "Iberostar Costa Dorada"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "XRegion": {
                        "Id": "144",
                        "Name": "Puerto Plata"
                    },
                    "Availability": {
                        "base": "available",
                        "message": ""
                    },
                    "Catalog": "DAN",
                    "DatasetInfo": {
                        "name": "",
                        "private": false
                    },
                    "DepartureLocation": {
                        "Id": "0x4b61746f77696365",
                        "Name": "Katowice"
                    },
                    "Duration": 8,
                    "NightsBeforeReturn": 7,
                    "OfferId": "4d55ef50202b397dba47647d589a5c9dca90ef00f2ab70c925cb2ab1bad9f674|RNBW|Mnx8",
                    "Operator": "RNBW",
                    "OperatorDesc": "Rainbow",
                    "OriginalPrice": {
                        "FirstPerson": {
                            "amount": "8765.99",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "17531.98",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Price": {
                        "FirstPerson": {
                            "amount": "8765.99",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "17531.98",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Promotion": "XXXX",
                    "ReturnDate": "2024-03-01",
                    "StartDate": "2024-02-22",
                    "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/RNBW/IMG/3436/main.webp",
                    "Transfer": "unspecified",
                    "UniqueObjectId": "4d55ef50202b397dba47647d589a5c9dca90ef00f2ab70c925cb2ab1bad9f674"
                },
                "Accommodation": {
                    "XCity": {
                        "Id": "1668",
                        "Name": "Puerto Plata"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "Attributes": [
                        "location_exotic_offers",
                        "location_seaside_resorts",
                        "location_directly_on_the_beach",
                        "location_near_airport",
                        "facility_wellness",
                        "facility_for_families_with_childrens",
                        "facility_fitness_or_gym",
                        "facility_outdoor_pool",
                        "facility_kindergarten",
                        "facility_internet_access",
                        "facility_air_conditioning",
                        "facility_night_life",
                        "activity_animation_for_adults",
                        "activity_sport",
                        "activity_tennis",
                        "activity_golf",
                        "activity_water_sports",
                        "activity_diving"
                    ],
                    "Category": 5,
                    "Code": "3436",
                    "ExtTripAdvisor": {
                        "count": 9633,
                        "description": "https://www.tripadvisor.com/Hotel_Review-g147290-d150815-Reviews-Iberostar_Costa_Dorada-Puerto_Plata_Puerto_Plata_Province_Dominican_Republic.html?m=62467",
                        "image": "https://staticpict.merlinx.pl/tripadvisor/4.5-62467-5.svg",
                        "rating": 4.5,
                        "review": "https://www.tripadvisor.com/UserReview-g147290-d150815-Iberostar_Costa_Dorada-Puerto_Plata_Puerto_Plata_Province_Dominican_Republic.html?m=62467"
                    },
                    "Location": {
                        "Coords": [
                            19.776339,
                            -70.657906
                        ],
                        "geoSearchDistance": 0
                    },
                    "Name": "Iberostar Costa Dorada",
                    "Nights": 7,
                    "Room": {
                        "Id": "2VU",
                        "Name": "2 os. Pokój Premium z widokiem na basen"
                    },
                    "Service": {
                        "Id": "A",
                        "Name": "All Inclusive"
                    },
                    "Type": {
                        "Id": "H",
                        "Name": "Hotel"
                    },
                    "XService": {
                        "Id": 1,
                        "Name": "Wszystko w cenie"
                    }
                },
                "Transport": {
                    "Flight": {
                        "AirlineType": "gds",
                        "Luggage": "unspecified",
                        "Out": [
                            {
                                "Airline": {
                                    "Code": "LO6545",
                                    "Name": "LO6545"
                                },
                                "Departure": {
                                    "Code": "KTW",
                                    "Date": "2024-02-22",
                                    "Name": "Katowice",
                                    "Time": "10:05"
                                },
                                "Destination": {
                                    "Code": "POP",
                                    "Date": "2024-02-22",
                                    "Name": "Puerto Plata",
                                    "Time": "15:55"
                                },
                                "FlightNo": "LO6545"
                            }
                        ],
                        "Ret": [
                            {
                                "Departure": {
                                    "Code": "POP",
                                    "Date": "2024-02-29",
                                    "Name": "Puerto Plata",
                                    "Time": "17:55"
                                },
                                "Destination": {
                                    "Code": "KTW",
                                    "Date": "2024-03-01",
                                    "Name": "Katowice",
                                    "Time": "08:45"
                                }
                            }
                        ],
                        "Stops": "no"
                    }
                },
                "Online": {
                    "actions": [
                        {
                            "action": "checktransport",
                            "fieldList": [
                                "Transport.*",
                                "Accommodation.AvailableRoomsCount"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        },
                        {
                            "action": "checkstatus",
                            "fieldList": [
                                "Base.Availability",
                                "Base.Price"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        }
                    ]
                }
            }
        },
        {
            offer: {
                "Base": {
                    "XCity": {
                        "Id": "5295",
                        "Name": "Playa Dorada"
                    },
                    "XCode": {
                        "Id": 6186,
                        "Name": "Gran Ventana Beach Resort"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "XRegion": {
                        "Id": "144",
                        "Name": "Puerto Plata"
                    },
                    "Availability": {
                        "base": "available",
                        "message": ""
                    },
                    "Catalog": "DAN",
                    "DatasetInfo": {
                        "name": "",
                        "private": false
                    },
                    "DepartureLocation": {
                        "Id": "0x4b61746f77696365",
                        "Name": "Katowice"
                    },
                    "Duration": 8,
                    "NightsBeforeReturn": 7,
                    "OfferId": "852235f8202b36487ad647c7fb9cf8d3ca90ef00f2ab70c925cb2ab1bad9f674|RNBW|Mnx8",
                    "Operator": "RNBW",
                    "OperatorDesc": "Rainbow",
                    "OriginalPrice": {
                        "FirstPerson": {
                            "amount": "8179.38",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "16358.76",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Price": {
                        "FirstPerson": {
                            "amount": "8179.38",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        },
                        "Total": {
                            "amount": "16358.76",
                            "currency": "PLN",
                            "details": {
                                "TFGIncluded": true
                            }
                        }
                    },
                    "Promotion": "XXXX",
                    "ReturnDate": "2024-03-01",
                    "StartDate": "2024-02-22",
                    "ThumbUrl": "https://datacfstatic.merlinx.pl/T320/RNBW/IMG/5929/main.webp",
                    "Transfer": "unspecified",
                    "UniqueObjectId": "852235f8202b36487ad647c7fb9cf8d3ca90ef00f2ab70c925cb2ab1bad9f674"
                },
                "Accommodation": {
                    "XCity": {
                        "Id": "5295",
                        "Name": "Playa Dorada"
                    },
                    "XCountry": {
                        "Id": "10",
                        "Name": "Dominikana"
                    },
                    "Attributes": [
                        "location_exotic_offers",
                        "location_seaside_resorts",
                        "location_near_airport",
                        "facility_for_families_with_childrens",
                        "facility_fitness_or_gym",
                        "facility_outdoor_pool",
                        "facility_kindergarten",
                        "facility_internet_access",
                        "facility_air_conditioning",
                        "activity_bicycles",
                        "activity_animation_for_adults",
                        "activity_sport",
                        "activity_tennis",
                        "activity_golf",
                        "activity_water_sports",
                        "activity_diving",
                        "activity_windsurfing",
                        "activity_sailing"
                    ],
                    "Category": 4,
                    "Code": "5929",
                    "ExtTripAdvisor": {
                        "count": 7717,
                        "description": "https://www.tripadvisor.com/Hotel_Review-g147290-d150764-Reviews-Gran_Ventana_Beach_Resort-Puerto_Plata_Puerto_Plata_Province_Dominican_Republic.html?m=62467",
                        "image": "https://staticpict.merlinx.pl/tripadvisor/4.0-62467-5.svg",
                        "rating": 4,
                        "review": "https://www.tripadvisor.com/UserReview-g147290-d150764-Gran_Ventana_Beach_Resort-Puerto_Plata_Puerto_Plata_Province_Dominican_Republic.html?m=62467"
                    },
                    "Location": {
                        "Coords": [
                            19.771717,
                            -70.640114
                        ],
                        "geoSearchDistance": 0
                    },
                    "Name": "VH Gran Ventana Beach Resort",
                    "Nights": 7,
                    "Room": {
                        "Id": "6EM",
                        "Name": "4 os. pokój superior"
                    },
                    "Service": {
                        "Id": "A",
                        "Name": "All Inclusive"
                    },
                    "Type": {
                        "Id": "H",
                        "Name": "Hotel"
                    },
                    "XService": {
                        "Id": 1,
                        "Name": "Wszystko w cenie"
                    }
                },
                "Transport": {
                    "Flight": {
                        "AirlineType": "gds",
                        "Luggage": "unspecified",
                        "Out": [
                            {
                                "Airline": {
                                    "Code": "LO6545",
                                    "Name": "LO6545"
                                },
                                "Departure": {
                                    "Code": "KTW",
                                    "Date": "2024-02-22",
                                    "Name": "Katowice",
                                    "Time": "10:05"
                                },
                                "Destination": {
                                    "Code": "POP",
                                    "Date": "2024-02-22",
                                    "Name": "Puerto Plata",
                                    "Time": "15:55"
                                },
                                "FlightNo": "LO6545"
                            }
                        ],
                        "Ret": [
                            {
                                "Departure": {
                                    "Code": "POP",
                                    "Date": "2024-02-29",
                                    "Name": "Puerto Plata",
                                    "Time": "17:55"
                                },
                                "Destination": {
                                    "Code": "KTW",
                                    "Date": "2024-03-01",
                                    "Name": "Katowice",
                                    "Time": "08:45"
                                }
                            }
                        ],
                        "Stops": "no"
                    }
                },
                "Online": {
                    "actions": [
                        {
                            "action": "checktransport",
                            "fieldList": [
                                "Transport.*",
                                "Accommodation.AvailableRoomsCount"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        },
                        {
                            "action": "checkstatus",
                            "fieldList": [
                                "Base.Availability",
                                "Base.Price"
                            ],
                            "timestamp": "2024-01-07T12:12:15+01:00"
                        }
                    ]
                }
            }
        }

    ]

    return (
        {
            bestHotelsList,
            quickSearchTypesBoxesList,
            allConfig: localDataValues?.SiteConfig,
            highlightDestination: localDataValues?.SiteConfig?.highlightDestination,
            highlightedAdvertCountries: localDataValues?.SiteConfig?.highlightedAdvertCountries

        }
    )
}
export default useSiteConfig