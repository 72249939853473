import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../../../../../lib/UIBookBrd/Text";
import Button from "../../../../../../lib/UIBookBrd/Button";
import {isMobile, isMobileOnly} from "react-device-detect";
import Image from "../../../../../../lib/UIBookBrd/Image";
import {rgba} from "polished";


const AdditionalOptionsOnHover = styled(FlexBox)`
  transition: 500ms;
  max-width: 50px;
  min-width: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-left: -60px;
  margin-right: 10px;
  border-right: 1px solid ${(props: { theme: any; }) => props.theme.separatorColor};
`
const ObjectPhoto = styled(FlexBox)`
  overflow: hidden;
  position: relative;
  flex-grow: unset;
  flex-wrap: nowrap;
  box-shadow: 0px 0px 1px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  background: ${(props: { theme: any; }) => props.theme.placeholderColor};
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: ${isMobileOnly ? '200px' : '150px'};
  max-width: 300px;
  max-height: ${isMobileOnly ? '200px' : '150px'};
  width: 100%;


  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 650px) {
    max-width: 1000px;
    height: 180px;
  }
`
const Custom__Image = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`


const CameraIcon = styled(FontAwesomeIcon)`
  width: 80%;
  height: 80%;
  margin: auto;
  color: ${(props: { theme: any; }) => props.theme.color};
  background-color: transparent;
`
const SkeletonImage = styled(FlexBox)`
  @keyframes skeletonColor {
    0% {
      background: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
    50% {
      background: ${(props: { theme: any; }) => props.theme.optionHoverColor};
    }
    100% {
      background: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
  }
  animation: skeletonColor 1s linear infinite;
  height: 145px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 650px) {
    height: 180px;
  }
`
const TripOffer = styled(FlexBox)<{ isExlusive: boolean, isOfferFocused: boolean, isDisabled: boolean }>`
  cursor: ${({isDisabled}) => isDisabled ? undefined : 'pointer'};
  margin: 4px 0;
  transition: background-color 500ms;
  height: auto;
  padding: 0 5px;
  border-radius: 5px;
  width: calc(100% - 8px);
  margin-left: 3px;
  box-shadow: ${({
                   isDisabled,
                   isExlusive,
                   theme
                 }) => !isDisabled ? isExlusive ? `0px 0px 6px 0px ${rgba(theme.brdExclusiveColor, 0.4)}` : `0px 0px 6px 0px ${theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'}` : undefined};

  background: ${({theme}) => theme.backgroundColor};
  box-sizing: border-box;
  outline: ${({

                isOfferFocused,
                theme,
              }) => isOfferFocused ? `2px solid ${theme.color}` : ''};

  &:hover ${AdditionalOptionsOnHover} {
    transition-delay: 500ms;
    margin-left: 0;
  }

  &:hover {
    outline: ${({
                  isDisabled,
                  isExlusive,
                  theme
                }) => !isDisabled ? (isExlusive ? `3px solid ${theme.brdExclusiveColor}` : `2px solid ${theme.color}`) : 'none'};
    box-shadow: ${({
                     isDisabled,
                     isExlusive,
                     theme
                   }) => !isDisabled ? isExlusive ? `0px 0px 6px 0px ${rgba(theme.brdExclusiveColor, 0.9)}` : `0px 0px 6px 0px ${theme.isDark ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.9)'}` : ''};

  }
`


const CompareIcon = styled(FontAwesomeIcon)`
  color: ${(props: { theme: any; }) => props.theme.color};;
  margin: auto;
`
const Title = styled(Text)`
  text-overflow: ellipsis;
  white-space: pre-wrap;
  font-weight: bold;
  font-size: 16px;
  @media (max-width: 400px) {
    max-width: 200px;
  }

`

const TitleAndImage = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-direction: column;
  min-width: 300px;
  @media (max-width: 650px) {
    width: unset;
    min-width: 260px;
    flex-grow: 1;
  }
`

const CloseIconHolder = styled(FlexBox)<{ isExlusive: boolean }>`
  width: 12.5px;
  margin-left: 10px;
`
const CloseIcon = styled(FontAwesomeIcon)`
  color: ${(props: { theme: any; }) => props.theme.color};;
  font-size: 20px;
`

const TopOfferDescription = styled(FlexBox)`
  flex-direction: column;
  max-width: calc(100% - 165px);
  overflow: hidden;
  margin-left: 5px;
  flex-grow: 1;
  @media (max-width: 650px) {
    max-width: unset;
    width: unset;
    min-width: 260px;
    flex-grow: 1;
  }
  @media (max-width: 650px) {
    height: auto;
    margin-left: 0;
  }
`
const OfferTopLayer = styled(FlexBox)`
  flex-direction: column;
  width: calc(100% - 10px);
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  flex-wrap: nowrap;
  min-height: min-content;
`
const OfferBottomLayer = styled(FlexBox)`
  margin-left: ${!isMobileOnly ? '10px' : undefined};
  //flex-direction: column;
  transition: height 200ms;
  height: ${(props: { isOfferFocused?: any; }) => props.isOfferFocused ? 'auto' : '0px'};
  overflow: hidden;
  flex-grow: 1;
  @media (max-width: 450px) {

    margin-left: 0;
  }
`
const StarIcon = styled(FontAwesomeIcon)`
  margin-bottom: auto;
  font-size: 10px;
  color: ${(props: { theme: any; }) => props.theme.ratingStarColor};

  &:first-of-type {
    margin-left: 5px;
  }
`
const TextHolder = styled(FlexBox)`
  flex-direction: column;
  padding-left: 5px;
  margin-top: 5px;
  width: calc(100% - 5px);
  min-height: calc(100% - 15px);
  margin-bottom: 5px;
`

const CustomText = styled(FlexBox)`
  margin: 2px 0;
  width: 100%;
  max-width: max-content;
`
const FlightTypeDescHolder = styled(FlexBox)`
  margin-left: 10px;
  @media (max-width: 650px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`
const FlightTypeDesc = styled(FlexBox)`
  flex-direction: column;
  height: auto;
  width: calc(100% - 10px)
`
const TitleText = styled(Text)`
  font-weight: bold;
  white-space: nowrap;
  margin-right: 5px;
`
const ValueText = styled(Text)<{ isClickAble: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: ${({isClickAble}) => isClickAble ? 'pointer' : undefined};;
  text-decoration: ${({isClickAble}) => isClickAble && isMobile ? 'underline' : undefined};

  &:hover {
    text-decoration: ${({isClickAble}) => isClickAble ? 'underline' : undefined};
  }
`
const TransportIcon = styled(FontAwesomeIcon)`
  color: ${(props: { theme: any; }) => props.theme.color};
  margin-right: 5px;
  font-size: 14px;
`
const IATAText = styled(Text)`
  font-size: 14px;
  font-weight: bold;
`
const DatesText = styled(Text)`
  margin-right: 2px;
  margin-left: 2px;
  margin-top: auto;
`
const PriceContentsHolder = styled(FlexBox)`
  flex-direction: column;
  margin-top: auto;
  margin-left: auto;
  cursor: initial;
`
const PriceHolder = styled(Text)`
  font-weight: bold;

`
const Custom__Color = styled.span<{ isExlusive: boolean }>`
  color: ${({isExlusive, theme}) => isExlusive ? theme.brdExclusiveColor : undefined};
`

const InformationIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  text-align: right;
  margin-bottom: auto;
  color: ${(props: { theme: any; }) => props.theme.circleInfoColor};
  font-size: 10px;
`
const ImageAndDescHolder = styled(FlexBox)`
  flex-wrap: nowrap;
  max-width: 100%;
  width: 100%;
  @media (max-width: 650px) {
    flex-direction: column;
    flex-grow: 1;
  }
`
const OfferAttribute = styled(FlexBox)`
  border: 1px solid ${(props: { theme: any; }) => props.theme.borderColor};;
  border-radius: 5px;
`
const OfferAttributesHolder = styled(FlexBox)`
  padding: 2px 0;
  gap: 4px;
`
const OfferAttributeText = styled(Text)`
  user-select: none;
  white-space: nowrap;
  font-size: 11px;
  padding: 1px 3px;
  margin: auto;
`
const OfferAttributesLabel = styled(Text)`
  margin: 2px 0;
  font-weight: bold;

`
const TypeRegularLabel = styled(Text)`
  margin: 0 5px;
`
const FlightDescHolder = styled(FlexBox)`
  flex-direction: ${isMobileOnly ? 'column ' : undefined};
  @media (max-width: 420px) {
    flex-direction: column;
  }


`
const TripOfferHolder = styled(FlexBox)`
  transition: height 500ms;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
`
const SeeOfferButtonPlacment = styled(FlexBox)`
  gap: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-content: center;
  align-items: center;
  margin-left: auto;
  margin-top: auto;

  @media (max-width: 450px) {
    width: 100%;
    padding-top: 10px;
    flex-direction: column;
    margin-left: unset;
  }

`
const SimilarOffersButton = styled(Button)`
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: 450px) {
    width: 100%;
  }
`
const SeeOfferButton = styled(Button)`
  min-width: 120px;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: 450px) {
    width: 100%;
  }

`
const DescPartHolder = styled(FlexBox)`
  margin: 2px 0;
  flex-wrap: nowrap;
  width: 100%;
  max-width: calc(100%);
`
const FlightTypeTitle = styled(Text)`
  font-weight: bold;
  font-size: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
`
const RoomTypeLabel = styled(Text)`
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 10px);
`
const FlightDetailsHolder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: column;
`
const CompareOfferButton = styled(Button)`
  min-width: 120px;
  @media (max-width: 450px) {
    width: 100%;
  }
`
const ToolTip__Content__Pricing = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
`
const Pricing__ToolTip__Holder = styled(FlexBox)`
  padding: 1px 2px;
`
const Pricing__ToolTip__Text__Type = styled(Text)`
  width: max-content;
  margin-right: 10px;
  font-size: 12px;
`
const Pricing__ToolTip__Text__Number = styled(Text)`
  margin-left: auto;
  font-weight: bold;
  font-size: 12px;
`
const Tax__Label = styled(Text)`
  margin-right: ${isMobile ? undefined : '10px'};
  text-align: right;
  font-size: 10px;
`
const Controls__Holder = styled(FlexBox)`
  min-width: 100%;
  z-index: 1;
  margin-left: -100%;
`
const Previous__Button = styled(FlexBox)`
  width: 40px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.40);
  }
`
const Spacer__Between__Buttons = styled(FlexBox)`
  width: calc(100% - 80px);
  height: 100%;
`
const Navigation__Icon = styled(FontAwesomeIcon)`
  margin: auto;
  font-size: 25px;
  color: ${({theme}) => theme.color}
`
const Images__Holder = styled(FlexBox)`
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-wrap: nowrap;
`
const Exlusive__Label = styled(Text)`
  margin-left: auto;
  margin-right: 5px;
  height: 18px;
  cursor: help;
  color: ${({theme}) => theme.brdExclusiveColor};
  font-size: 15px;
  white-space: nowrap;
  font-family: HandWritenBrdStyle;
  padding-left: 6px;
  font-weight: bold;
`
const TimeLine__Holder = styled(FlexBox)`
  margin-top: 4px;
  flex-wrap: nowrap;
  margin-left: 5px;
`
const TimeLine__Line__Element = styled(FlexBox)`
  height: 60px;
  border-left: 2px solid ${({theme}) => theme.color};
`
const TimeLine__Dots__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: -5px;

`

const TimeLine__Dot__Element = styled(FlexBox)<{ isDirect?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.color};
  margin-top: 12px;

  &:first-of-type {
    margin-top: 6px;
  }

  &:last-of-type {
    margin-top: ${({isDirect}) => isDirect ? '32px' : '12px'};
  }
`
const Time__Label = styled(Text)<{ isDirect?: boolean }>`
  margin-top: 3px;

  &:first-of-type {
    margin-top: 5px;
  }

  &:last-of-type {
    margin-top: ${({isDirect}) => isDirect ? '23' : '4'}px
  }
`
const Transport__Values__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
`
const TimeSpan__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: 4px;
`
const Transport__Full__Details__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-top: 10px;
  gap: 4px;
`
const Transport__Detail__Type = styled(Text)`
`
const Flight__Segment__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: column;
  padding: 0 0 10px 0;
`

const Flight__Segment__Quick__View = styled(FlexBox)`
  flex-wrap: nowrap;
`
const Flight__Time = styled(Text)`
  font-weight: bold;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 2px;
`
const Stops__Count = styled(Text)<{ isDirect?: boolean }>`
  font-size: 12px;
  padding: 0 4px;
  margin: ${({isDirect}) => isDirect ? '0 auto' : '0 auto -8px auto'};
  z-index: 1;
  background-color: ${({theme}) => theme.backgroundColor};
`
const Flight__Line = styled(FlexBox)`
  width: calc(100% - 20px);
  margin: 0 10px;
  height: 1px;
  border-bottom: solid ${({theme}) => rgba(theme.color, 0.6)} 1px;
`
const Plane__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  padding: 2px 5px;
  background-color: ${({theme}) => theme.backgroundColor};;
  height: 15px;
  width: 20px;
`
const Flight__Destination__Quick__View = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
`
const Flight__Details = styled(FlexBox)`
  flex-direction: column;
  gap: 4px;
  margin: 4px 0;
  margin-top: 10px;
`
const Flight__Details__Value = styled(Text)`
  margin-top: 2px;
  font-size: 14px;
`
const Flight__Details__Value__Icon = styled(FontAwesomeIcon)`
  margin: 0 4px;
  margin-top: 3px;
  width: 15px;
  height: 15px;
  color: ${({theme}) => theme.color};
`
const Flight__Details__Value__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
`
const SEO__TRIP__LINK = styled.a`
  visibility: hidden;
  position: absolute;
  z-index: -999;
`
const Heart__Icon = styled(FontAwesomeIcon)<{ isExlusive: boolean, isFavourite: boolean }>`
  color: ${({theme}) => theme.color};
  margin: 1px 0;
  margin-left: ${({isExlusive}) => isExlusive ? 'unset' : 'auto'};
  color: ${({isFavourite, theme}) => isFavourite ? theme.favouriteOfferColor : theme.color};
  height: 18px;
  width: 18px;
`

export const Styled = {
    Heart__Icon,
    SEO__TRIP__LINK,
    Flight__Details__Value__Holder,
    Flight__Details__Value__Icon,
    Flight__Details__Value,
    Flight__Details,
    Flight__Destination__Quick__View,
    Plane__Icon,
    Flight__Line,
    Stops__Count,
    Flight__Time,
    Flight__Segment__Quick__View,
    Flight__Segment__Holder,
    Transport__Detail__Type,
    Transport__Full__Details__Holder,
    TimeSpan__Holder,
    Transport__Values__Holder,
    Time__Label,
    TimeLine__Dots__Holder,
    TimeLine__Dot__Element,
    TimeLine__Holder,
    TimeLine__Line__Element,
    Exlusive__Label,
    Images__Holder,
    Navigation__Icon,
    Controls__Holder,
    CompareOfferButton,
    FlightDetailsHolder,
    RoomTypeLabel,
    FlightTypeTitle,
    DescPartHolder,
    SimilarOffersButton,
    SeeOfferButton,
    SeeOfferButtonPlacment,
    TripOfferHolder,
    FlightDescHolder,
    TypeRegularLabel,
    FlightTypeDescHolder,
    FlightTypeDesc,
    OfferAttributesLabel,
    OfferAttributeText,
    OfferAttributesHolder,
    OfferAttribute,
    ImageAndDescHolder,
    PriceContentsHolder,
    InformationIcon,
    PriceHolder,
    Custom__Color,
    DatesText,
    TransportIcon,
    IATAText,
    CustomText,
    TitleText,
    ValueText,
    TextHolder,
    StarIcon,
    OfferBottomLayer,
    OfferTopLayer,
    TopOfferDescription,
    SkeletonImage,
    CloseIcon,
    CloseIconHolder,
    TitleAndImage,
    ObjectPhoto,
    Custom__Image,
    Previous__Button,
    Spacer__Between__Buttons,
    CameraIcon,
    Title,
    CompareIcon,
    TripOffer,
    AdditionalOptionsOnHover,
    ToolTip__Content__Pricing,
    Pricing__ToolTip__Holder,
    Pricing__ToolTip__Text__Type,
    Pricing__ToolTip__Text__Number,
    Tax__Label


}