import {Notifications_Data} from "./Context"
import useBookBrdComponentStates from "../UseHooksBookBrd/useBookBrdComponentStates";

type NotificationProviderProps = {
    children: React.ReactNode
}


const NotificationProvider = (
    {
        children
    }: NotificationProviderProps
) => {

    const {
        notificationsArray,
    } = useBookBrdComponentStates()

    return (
        <Notifications_Data.Provider value={notificationsArray as any}>
            {children}
        </Notifications_Data.Provider>

    )

}


export default NotificationProvider
