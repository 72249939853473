import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";

const PriceSlider__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`
const Price__Slider__Prices__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
`

export const Styled = {
    Price__Slider__Prices__Holder,
    PriceSlider__Holder
}