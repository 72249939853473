import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Booking__Component = styled(FlexBox)`
  z-index: 10;
  height: 361px;
  width: 316px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};
  min-width: 316px;
  margin-left: auto;
  margin-right: 15px;
  margin-top: -385px;
  background-color: ${({theme}) => theme.backgroundColor};
  flex-direction: column;
  box-shadow: ${({
                   theme
                 }) => `0px 0px 6px 0px ${theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'}`};
  @media (max-width: calc(800px + 2vw)) {
    box-shadow: none;
    margin-top: 100px;
    width: 100%;
    min-width: 250px;
    margin-right: 0;
    flex-direction: column;
    height: auto;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    border-right: 0;
    border-radius: 0;
  }
`
const Object__Title__Name = styled(Text)<{ showFixedTitle?: boolean, isExclusiveOffer?: boolean }>`
  flex-wrap: nowrap;
  overflow: hidden;
  flex-direction: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 70px);
  font-weight: bold;
  font-size: 22px;
  transition: font-size 200ms;
  color: ${({theme, isExclusiveOffer}) => isExclusiveOffer ? theme.brdExclusiveColor : theme.color};
  @media (max-width: 599px) {
    white-space: pre-wrap;
  }
`
const Title__Holder = styled(FlexBox)`
  width: calc(100% - 4px);
  padding-top: 4px;
  padding-left: 4px;
  overflow: hidden;
  flex-wrap: nowrap;
  max-width: unset;

`
const Control__Components__Holder = styled(FlexBox)`
  flex-direction: column;
  max-width: 100%;
  flex-grow: 1;
`
const Select__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: 3px 5px;
  margin-bottom: 0;
  width: calc(100% - 5px);
  max-width: calc(100% - 5px);
`

const Select__Title = styled(Text)`
  user-select: none;
  font-size: 12px;
  margin-bottom: 1px;
`
const Apply__Price__Button__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: 5px;
  margin-top: auto;
  width: calc(100% - 10px);
  @media (max-width: calc(800px + 2vw)) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`
const Price__Label__Holder = styled(FlexBox)`
  font-weight: bold;
  margin-left: auto;
  flex-direction: column;
  max-width: max-content;
  white-space: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 2px;

  @media (max-width: 599px) {
    margin-right: auto;
    margin-top: auto;
    margin-left: 0;
  }
`
const Price__Label = styled(FlexBox)<{ showFixedTitle?: boolean }>`
  margin-left: auto;
  flex-wrap: nowrap;
  margin-right: 2px;
  color: ${({theme}) => theme.color};
  font-size: ${({showFixedTitle}) => showFixedTitle ? '11px' : '14px'};
  transition: font-size 200ms;
`
const Price__Per__Person__Label = styled.span`
  margin-left: auto;
  margin-right: 2px;
  color: ${({theme}) => theme.color};
  font-weight: lighter;
  font-size: 12px;
`
const Price__Label__Amount = styled.span<{ showFixedTitle?: boolean }>`
  font-size: ${({showFixedTitle}) => showFixedTitle ? '13px' : '18px'};
  transition: font-size 200ms;
  font-weight: bold;
`
const InformationIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  text-align: right;
  color: ${(props: { theme: any; }) => props.theme.circleInfoColor};
  font-size: 10px;
`
const Title = styled(FlexBox)<{ isLoading?: boolean, showFixedTitle?: boolean }>`
  cursor: ${({isLoading}) => isLoading ? 'wait' : undefined} !important;
  position: ${({showFixedTitle}) => showFixedTitle ? 'fixed' : undefined};
  top: ${({showFixedTitle}) => showFixedTitle ? '0px' : undefined};;
  width: ${({showFixedTitle}) => showFixedTitle ? 'calc(100% -  20px)' : undefined};
  border-bottom: ${({showFixedTitle, theme}) => showFixedTitle ? ` 1px solid ${theme.color}` : ''};
  background-color: ${({theme}) => theme.backgroundColor};
  z-index: 11;
  margin-left: ${({showFixedTitle}) => showFixedTitle ? '' : undefined};;
  padding: ${({showFixedTitle}) => showFixedTitle ? '5px 10px' : '5px 10px'};
  @media (min-width: 1120px) {
    margin-left: ${({showFixedTitle}) => showFixedTitle ? '-10px' : undefined};;
    padding: ${({showFixedTitle}) => showFixedTitle ? '5px 30px 5px 10px' : '5px 0'};
    width: ${({showFixedTitle}) => showFixedTitle ? 'calc(100%)' : undefined};
    width: ${({showFixedTitle}) => showFixedTitle ? '1080px' : undefined};
  }
`
const Location__Label = styled.span<{ isLoading?: boolean, showFixedTitle?: boolean }>`
  flex-wrap: ${({isLoading}) => isLoading ? 'nowrap' : undefined};
  display: ${({isLoading}) => isLoading ? 'flex' : undefined};
  font-size: ${({showFixedTitle}) => showFixedTitle ? '9px' : '14px'};
  margin-top: 5px;
  padding-bottom: 7px;
  transition: font-size 200ms;
  margin-left: 4px;
  color: ${({theme}) => theme.color}


`
const Show__Map__Label = styled.span`
  margin-left: 4px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`
const Pin__Icon = styled(FontAwesomeIcon)<{ isLoading?: boolean, showFixedTitle?: boolean }>`
  @keyframes iconColor {
    0% {

      color: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
    50% {
      color: ${(props: { theme: any; }) => props.theme.optionHoverColor};
    }
    100% {
      color: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
  }
  height: ${({showFixedTitle}) => showFixedTitle ? '10px' : '15px'};
  transition: height 200ms;
  margin-right: 4px;
  animation: ${({isLoading}) => isLoading ? 'iconColor 1s linear infinite' : undefined};
`
const Object__Title__Name__Skeleton = styled(FlexBox)`
  @keyframes skeletonColor {
    0% {
      background: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
      color: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
    50% {
      background: ${(props: { theme: any; }) => props.theme.optionHoverColor};
      color: ${(props: { theme: any; }) => props.theme.optionHoverColor};
    }
    100% {
      background: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
      color: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
  }
  height: 18px;
  width: ${Math.floor(Math.random() * (70 - 30 + 1)) + 30}%;
  border-radius: 15px;
  animation: skeletonColor 1s linear infinite;
  margin-left: 4px;
  margin-top: 4px;
  @media (max-width: calc(800px + 2vw)) {
    height: 22px;
  }
`
const Location__Label__Skeleton = styled(FlexBox)`
  height: 12px;
  width: ${Math.floor(Math.random() * (70 - 30 + 1)) + 30}%;
  border-radius: 15px;
  animation: skeletonColor 1s linear infinite;
  margin-left: 4px;
  margin-top: 4px;
  @media (max-width: calc(800px + 2vw)) {
    height: 15px;
  }
`
const Names__Holder = styled(FlexBox)`
  flex-grow: 1;
  flex-direction: column;
  max-height: 58px;
  flex-wrap: nowrap;
  @media (max-width: 599px) {
    display: none;
  }
`

const Price__Holder = styled.span<{ showButton?: boolean }>`
  padding-top: 5px;
  margin-top: auto;
  margin-left: auto;
  flex-direction: column;
  display: ${({showButton}) => showButton ? 'flex' : 'none'};
  @media (max-width: calc(800px + 2vw)) {
    display: flex;
  }
  @media (max-width: 599px) {
    width: 100%;
    align-items: start;
    align-content: space-between;
    flex-direction: initial;

  }
`
const Price__Type__label = styled.span<{ showFixedTitle?: boolean }>`
  margin-top: auto;
  margin-bottom: ${({showFixedTitle}) => showFixedTitle ? '0' : '1px'};
  margin-right: 2px;
`
const Brd__Exclusive__Holder = styled(FlexBox)`
  width: 100%;
  @media (min-width: calc(800px + 2vw)) {
    display: none;
  }
`
const Brd__Exclusive__Label = styled(FlexBox)<{ showFixedTitle?: boolean }>`
  font-size: 9px;
  margin-bottom: 15px;
  transition: font-size 200ms;
  color: ${({theme}) => theme.brdExclusiveColor};
  font-family: cursive;
  font-weight: bold;
`
const Control__Component__Title = styled(Text)`
  font-size: 15px;
  margin: 4px 0 0 4px;
  font-weight: bold;
`
const Names__Holder__Small__Width = styled(FlexBox)`
  flex-direction: column;
  display: none;
  @media (max-width: 599px) {
    display: flex;
  }
`
const ToolTip__Exclusive__Label = styled(Text)`
  font-size: 12px;
`
const Custom__Select__Selected__Option = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
`
const Custom__Select__Selected__Option__Icon = styled(FontAwesomeIcon)`
  width: 15px;
  height: 15px;
  margin-top: 2px;
  margin-left: 6px;
  margin-right: 4px;
`

export const Styled = {
    Custom__Select__Selected__Option__Icon,
    Custom__Select__Selected__Option,
    ToolTip__Exclusive__Label,
    Names__Holder__Small__Width,
    Control__Component__Title,
    Price__Type__label,
    Brd__Exclusive__Holder,
    Brd__Exclusive__Label,
    Price__Holder,
    Names__Holder,
    Pin__Icon,
    Show__Map__Label,
    Title,
    Booking__Component,
    Object__Title__Name,
    Title__Holder,
    Control__Components__Holder,
    Select__Holder,
    Select__Title,
    Price__Per__Person__Label,
    Apply__Price__Button__Holder,
    Price__Label__Holder,
    Price__Label,
    Price__Label__Amount,
    InformationIcon,
    Location__Label__Skeleton,
    Object__Title__Name__Skeleton,
    Location__Label
}
