enum ViewTypes {
    Status = 'status',
    Refund = 'refund',
    OfferDetails = 'offer_details',
    ManageOffer = 'manage',
    Calendar = 'before_trip'

}

const View__Types = [
    ViewTypes.Status,
    ViewTypes.OfferDetails,
    ViewTypes.Calendar,
    ViewTypes.ManageOffer,
    ViewTypes.Refund,
]

export {
    View__Types,
    ViewTypes
}