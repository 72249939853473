import {Styled} from "../../Navigation.styles";
import React from "react";
import SettingsOptions, {Local_Settings_Values} from "../../../../lib/PageConfig";
import useBookBrdPageFunctions from "../../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {Language_Images_Flags} from "../../../../LanguageImages";
import {useTranslation} from "react-i18next";
import useStorage from "../../../../lib/UseHooksBookBrd/useStorage";

const TwoMainOptions = () => {

    const {t} = useTranslation();

    const {
        Language_Options,
        Currency_Options
    } = SettingsOptions()

    const {
        onOpenCurrencyModal,
        onOpenLanguageModal,
    } = useBookBrdPageFunctions()


    const [localSettings, _] = useStorage<any>('localStorage', 'LocalSettings', Local_Settings_Values)

    const countryImage = Language_Images_Flags[localSettings?.SelectedLanguage]

    return (
        <Styled.Half__Option__Holder>
            <Styled.Main__Drawer__Value
                onClick={onOpenLanguageModal}
            >
                <Styled.Country__Image
                    src={countryImage}
                    alt={localSettings?.SelectedLanguage}
                />
                <Styled.Round__Image__Label>
                    {Language_Options?.filter((item: any) => item.value === localSettings?.SelectedLanguage)[0]?.label}
                </Styled.Round__Image__Label>
            </Styled.Main__Drawer__Value>
            <Styled.Main__Drawer__Value
                onClick={onOpenCurrencyModal}
            >
                <Styled.Round__Currency__Holder>
                    <Styled.Currency__Label>
                        {localSettings?.PreferredCurrency ? localSettings?.PreferredCurrency : t('lbl_any')}
                    </Styled.Currency__Label>
                </Styled.Round__Currency__Holder>
                <Styled.Round__Image__Label>
                    {Currency_Options?.filter((item: any) => item.value === localSettings?.PreferredCurrency)[0]?.label}
                </Styled.Round__Image__Label>
            </Styled.Main__Drawer__Value>

        </Styled.Half__Option__Holder>
    )
}
export default TwoMainOptions