import {Styled} from "../Offer.styles"
import TransportField
    from "../../../Pages/TripSearch/TripSearchResultPage/Content/TripOfferList/TripOffer/OfferTopLayer/TopLayerDescription/TransportField";
import {GET_TRANSPORT_TYPE} from "../../../Pages/TripSearch/TripSearchResultPage/TripSearchFunctions";
import AccommodationAttributes from "../../AccommodationAttributes";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import {faBedFront, faForkKnife, faGlobe} from "@fortawesome/pro-solid-svg-icons";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {isEmpty} from "lodash-es";


type DetailsProps = {
    offerData: any
}

const Details = (
    {
        offerData
    }: DetailsProps
) => {

    const {Base, Accommodation, Transport} = offerData

    const {
        createLinkForOperator,
        onTourOperatorClick
    } = useBookBrdPageFunctions()

    const DetailPart = (icon: any, value: string, link?: string | undefined, onClick?: () => void) => {

        return (
            <FlexBox style={{flexWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%'}}>
                <Styled.Details__Icon icon={icon}/>
                <Styled.Details__Text
                    onClick={onClick}
                    isLink={!isEmpty(link)}
                >
                    {value}
                </Styled.Details__Text>
                {!isEmpty(link) && (
                    <Styled.Details__Href
                        href={createLinkForOperator({name: Base.OperatorDesc, code: Base.Operator})}
                    >
                        {value}
                    </Styled.Details__Href>
                )}
            </FlexBox>
        )
    }

    return (
        <Styled.Details__Holder>
            <TransportField
                transport={Transport}
                base={Base}
                transportType={GET_TRANSPORT_TYPE(Transport)}
            />
            {DetailPart(faForkKnife, Accommodation.XService.Name)}
            {DetailPart(faBedFront, Accommodation?.Room?.Name)}
            {DetailPart(faGlobe, Base?.OperatorDesc, Base?.OperatorDesc, () => onTourOperatorClick({
                name: Base.OperatorDesc,
                code: Base.Operator
            }))}
            <AccommodationAttributes
                maxNumberOfRows={1}
                attributes={Accommodation?.Attributes}
            />
        </Styled.Details__Holder>
    )
}

export default Details