import CustomMap from "./CustomMap";

type ModalsHolderProps = {
    isMapOpen: boolean
    onCloseMap: () => void
    offer: any
}

const ModalsHolder = (
    {
        isMapOpen,
        onCloseMap,
        offer
    }: ModalsHolderProps
) => {

    return (
        <>
            <CustomMap
                offer={offer}
                onCloseMap={onCloseMap}
                isMapOpen={isMapOpen}
            />
        </>
    )
}
export default ModalsHolder