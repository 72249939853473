const adultsInputs = ['name', 'surname', 'mail', 'country', 'city', 'address', 'postCode']

const childInputs = ['name', 'surname', 'country', 'address', 'postCode', 'city']

const payerInputs = ['name', 'surname', 'mail', 'country', 'address', 'postCode', 'city']

export {
    adultsInputs,
    childInputs,
    payerInputs
}