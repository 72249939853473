import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Countries__Options__Holder = styled(FlexBox)`
  width: calc(100% - 10px);
  margin: 10px 5px;
  flex-wrap: wrap;
`
const Currency__Option = styled(FlexBox)`
  flex-wrap: nowrap;
  cursor: pointer;
  padding: 8px 0;
  margin: 0 5px;
  border-radius: 6px;
  @media (max-width: 900px) {
    width: calc(25% - 10px);
  }
  @media (min-width: 900px) {
    width: calc(20% - 10px)
  }
  @media (max-width: 700px) {
    width: calc(100% / 3 - 10px);
  }
  @media (max-width: 500px) {
    width: calc(50% - 10px)
  }
  @media (max-width: 350px) {
    width: calc(100%)
  }

  &:hover {
    background-color: ${({theme}) => theme.optionHoverColor};
  }

`

const Currency__Name__Label = styled(Text)`
  margin-left: 5px;
  font-weight: bold;
`
const Currency__Code__Label = styled(Text)`
  font-weight: lighter;
  margin-left: 5px;
  padding: 1px 0;
`
const Country__Option = styled(FlexBox)`
  cursor: pointer;
  padding: 8px 0;
  margin: 0 5px;
  border-radius: 6px;
  @media (max-width: 900px) {
    width: calc(25% - 10px);
  }
  @media (max-width: 700px) {
    width: calc(100% / 3 - 10px);
  }
  @media (max-width: 500px) {
    width: calc(50% - 10px)
  }
  @media (min-width: 900px) {
    width: calc(20% - 10px)
  }

  &:hover {
    background-color: ${({theme}) => theme.optionHoverColor};
  }

`
const Country__Image = styled.img`
  width: 30px;
  height: 30px;
  user-select: none;
  box-shadow: 0px 0px 3px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  -webkit-user-drag: none;
  border-radius: 50%;
  margin-left: 2px;
`
const Country__Label = styled(Text)`
  font-size: 15px;
  user-select: none;
  margin: auto 0 auto 5px;
`
const Selected__Language__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  margin: auto 2px auto auto;
`

export const Styled = {
    Currency__Option,
    Currency__Code__Label,
    Currency__Name__Label,
    Countries__Options__Holder,
    Country__Option,
    Country__Image,
    Country__Label,
    Selected__Language__Icon
}