import Modal from "../../../../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import {isMobileOnly} from "react-device-detect";
import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import {Styled} from "../../TripSearchResultPage.styles";
import {DATE_RANGE_FIELD} from "../../../../../lib/DefaultFormikValue/DefaultSearchFormValues";
import useSearchFormValues, {GET_VALID_SUGGESTED_DATES} from "../../../../../lib/UseHooksBookBrd/useSearchFormValues";
import React from "react";
import {useTranslation} from "react-i18next";
import useV5OfferListRequest from "../../../../../lib/UseHooksBookBrd/useV5OfferListRequest";
import useFilterFormValue from "../../../../../lib/UseHooksBookBrd/useFilterFormValue";
import useNavigation from "../../../../../lib/UseHooksBookBrd/useNavigation";
import {FIRST_STEP_TRIP_SEARCH} from "../../../../../lib/Routes";
import useTripResultsValues from "../../useTripResultsValues";

const ErrorModal = () => {

    const {searchFormValue, setSearchFormValue} = useSearchFormValues()

    const {toggleIsLoadingPage, setOptionList} = useTripResultsValues()

    const {filterFormValue} = useFilterFormValue()

    const {SendRequestForOfferList} = useV5OfferListRequest(searchFormValue, filterFormValue)

    const {t} = useTranslation();

    const reRoute = useNavigation()

    return (
        <>
            <Modal
                style={{width: isMobileOnly ? "90vw" : undefined}}
                showCloseIcon={false}
                width={450}
                isOpen={true}
            >
                <FlexBox style={{width: 'calc(100% - 20px)', padding: '10px', flexDirection: 'column'}}>
                    <Styled.Error__Desc>
                        {t('lbl_passed_link_error_desc')}
                    </Styled.Error__Desc>
                    <Styled.Error__Desc__Details>
                        {t('lbl_given_invalid_dates')}
                        {': '}
                        {searchFormValue[DATE_RANGE_FIELD]?.startDate}
                        {' - '}
                        {searchFormValue[DATE_RANGE_FIELD]?.endDate}
                    </Styled.Error__Desc__Details>
                    <Styled.Error__Desc__Details>
                        {t('lbl_suggested_valid_dates')}
                        {': '}
                        {GET_VALID_SUGGESTED_DATES(searchFormValue[DATE_RANGE_FIELD])?.startDate}
                        {' - '}
                        {GET_VALID_SUGGESTED_DATES(searchFormValue[DATE_RANGE_FIELD])?.endDate}
                    </Styled.Error__Desc__Details>
                    <Styled.Buttons__Holder>
                        <Styled.Fix__Error__Button
                            onClick={() => {
                                setSearchFormValue(DATE_RANGE_FIELD, {
                                    startDate: GET_VALID_SUGGESTED_DATES(searchFormValue[DATE_RANGE_FIELD])?.startDate,
                                    endDate: GET_VALID_SUGGESTED_DATES(searchFormValue[DATE_RANGE_FIELD])?.endDate
                                })
                                SendRequestForOfferList(toggleIsLoadingPage, setOptionList, "OfferList")
                            }}
                            label={t('lbl_fix_dates_in_link')}
                        />
                        <Styled.Error__Button
                            label={t('lbl_navigate_back_to_main_page')}
                            onClick={() => {
                                reRoute(FIRST_STEP_TRIP_SEARCH)
                            }}
                        />
                    </Styled.Buttons__Holder>
                </FlexBox>
            </Modal>
        </>
    )
}
export default ErrorModal
