// @ts-ignore
import styled from "styled-components";
import Text from "../../lib/UIBookBrd/Text";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isMobileOnly} from "react-device-detect";

const CalendarInfoHolder = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid ${({theme}) => theme.separatorColor};
  flex-direction: column;
  padding: 10px 0;

`
const CalendarInfo = styled.div`
  margin: ${isMobileOnly ? '0 auto' : '0'};
  display: flex;
  padding: 0 20px;

`
const SetAdditionalTimeButton = styled.div`
  display: flex;
  margin: auto 0 auto 5px;
  padding: 2px;
  border-radius: 15px;
  min-height: 26px;
  transition: 500ms;
  background: ${({theme}) => theme.backgroundColor};
  border: 1px solid transparent;
  border-color: ${isMobileOnly ? ({theme}) => theme.borderColor : undefined};

  &:hover {
    border-color: ${({theme}) => theme.borderColor};
  }

  &:first-of-type {
    margin-left: 0;
  }
`
const SetAdditionalTimeButtonText = styled(Text)`
  user-select: none;
  padding: 0 5px;
  margin: auto 0;
  font-size: 16px;
`
const FontAwesomeIcons = styled(FontAwesomeIcon)`
  height: 20px;
  width: 20px;
  padding: 10px;
  margin: auto;
  color: ${({theme}) => theme.color};
  transition: 500ms;


`
const NextButton = styled(FlexBox)`
  transition: 500ms;
  right: 18px;
  top: 17px;
  position: absolute;
  border: 1px solid ${({theme}) => theme.borderColorLight};
  background: ${({theme}) => theme.backgroundColor};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    border-color: ${({theme}) => theme.borderColor};
  }

  &:hover ${FontAwesomeIcons} {
    transform: scale(1.1);
  }
`
const PrevButton = styled(FlexBox)`
  transition: 500ms;
  left: 18px;
  top: 17px;
  position: absolute;
  border: 1px solid ${({theme}) => theme.borderColorLight};
  background: ${({theme}) => theme.backgroundColor};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    border-color: ${({theme}) => theme.borderColor};
  }

  &:hover ${FontAwesomeIcons} {
    transform: scale(1.1);
  }


`
const SelectHolder = styled.div`
  margin-top: -15px;
  display: flex;
  flex-grow: 1;
  max-width: 150px;
  margin-bottom: 10px;
`
const Month__Label = styled(Text)`
  font-size: 16px;
  padding: 5px 0;
  font-weight: bold;
  @media (max-width: 618px) {
    padding: 0;
  }
`

export const Styled = {
    CalendarInfoHolder,
    Month__Label,
    CalendarInfo,
    SetAdditionalTimeButton,
    SetAdditionalTimeButtonText,
    NextButton,
    PrevButton,
    FontAwesomeIcons,
    SelectHolder
};