import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SettingsHolder = styled.div`
  width: 100vw;
  max-width: 400px;
  user-select: none;
  display: flex;
  flex-direction: column;
  transition: 500ms;
  min-width: ${(props: { isMobileOnly: any; }) => props.isMobileOnly ? '100vw' : undefined};

`;
const ParticularSetting = styled.div`
  min-height: 38px;
  display: flex;
  flex-direction: column;
`
const FieldTypeLabel = styled.div`
  display: flex;
  transition: 500ms;
  font-size: 18px;
  font-weight: bold;
  color: ${(props: { theme: any; }) => props.theme.color};
  box-sizing: border-box;
`
const SettingsType = styled.div`
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`
const Settings__Section__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  padding: 4px 5px;
  align-items: center;
  min-height: 38px;
`
const Settings__Type__Label = styled(Text)`
  font-size: 16px;
  flex-wrap: nowrap;
  padding-right: ${(props: { isToolTip?: any; }) => props.isToolTip ? undefined : '10px'};
  max-width: 100%;
  width: auto;
  @media (max-width: 400px) {
    white-space: pre-wrap;
    width: unset;
    min-width: unset;
  }
`
const Content__With__ToolTip = styled(FlexBox)`
  width: calc(100% - 130px);
  margin-right: auto;
  flex-wrap: nowrap;
`

const Settings__Select__Holder = styled(FlexBox)`
  max-width: 120px;
  min-width: 120px;
  flex-grow: 1;
`
const InformationIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  text-align: right;
  margin-bottom: 5px;
  color: ${(props: { theme: any; }) => props.theme.circleInfoColor};
  font-size: 10px;
`
const Content__Without__ToolTip = styled(FlexBox)`
  margin-right: auto;
  flex-direction: column;
  flex-wrap: nowrap;
`
const ToolTip__Value__Label = styled(Text)`
  font-size: 10px;
  width: calc(100%);
`
const Children__Holder = styled(FlexBox)`
  padding-left: 5px;
  flex-wrap: nowrap;
  margin-left: auto;
`
const Setting__Icon = styled(FontAwesomeIcon)`
  width: 30px;

`
const Settings__Participants__Holder = styled(FlexBox)`
  min-width: 120px;
  max-width: 120px;
`
export const Styled = {
    SettingsHolder,
    ParticularSetting,
    SettingsType,
    FieldTypeLabel,
    Settings__Section__Holder,
    Settings__Type__Label,
    Settings__Select__Holder,
    InformationIcon,
    Content__With__ToolTip,
    Content__Without__ToolTip,
    ToolTip__Value__Label,
    Children__Holder,
    Setting__Icon,
    Settings__Participants__Holder,

}