import styled from "styled-components";
import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isMobileOnly} from "react-device-detect";
import Button from "../../../../../lib/UIBookBrd/Button";

const Accommodation__Holder = styled(FlexBox)<{ isFixed: boolean }>`
  @keyframes skeletonColor {
    0% {
      background-color: ${(props: { theme: any; }) => props.theme.placeholderColor};
    }
    50% {
      background-color: ${(props: { theme: any; }) => props.theme.optionHoverColor};
    }
    100% {
      background-color: ${(props: { theme: any; }) => props.theme.placeholderColor};
    }
  }
  background-color: ${({theme}) => theme.backgroundColor};

  width: 100%;
  flex-wrap: nowrap;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  max-width: 1080px;
  display: ${({isFixed}) => isFixed ? 'none' : undefined};;
  @media (min-width: 1100px) {
    padding: 0 20px;
    margin-left: -10px;
  }
  @media (max-width: 1100px) {
    padding: 0 2vw;
    margin-left: -1vw;
    width: calc(98vw - 20px)
  }
  @media (min-width: 700px) {
    @media (min-height: 700px) {
      z-index: ${({isFixed}) => isFixed ? '999' : undefined};;
      position: ${({isFixed}) => isFixed ? 'fixed' : undefined};
      display: ${({isFixed}) => isFixed ? 'flex' : undefined};;
    }
  }
  @media (max-width: 700px) {

    flex-direction: column;
  }
`

const HotelImageHolder = styled(FlexBox)`
  max-width: 400px;
  @media (max-width: 700px) {
    max-width: unset;
    width: calc(98vw - 20px);
  }
`
const Accommodation__Details__Holder = styled(FlexBox)`
  flex-grow: 1;
  flex-direction: column;
  padding-bottom: 10px;
  margin: 10px;
  @media (max-width: 700px) {
    margin: 0 ;
  }
`
const Accommodation__Label = styled(Text)<{ isLoading: boolean }>`
  font-size: 16px;
  height: ${({isLoading}) => isLoading ? '16px' : undefined};
  min-width: ${({isLoading}) => isLoading ? '80px' : undefined};
  animation: ${({isLoading}) => isLoading ? 'skeletonColor 1s linear infinite' : ''};
  border-radius: 10px;
  font-weight: bold;
  transition: unset;
`
const Accommodation__Location__Label = styled(Text)<{ isLoading: boolean }>`
  width: ${({isLoading}) => isLoading ? '100px' : undefined};
  margin-top: ${({isLoading}) => isLoading ? '2px' : undefined};;
  margin-bottom: ${({isLoading}) => isLoading ? '10px' : undefined};;
  height: ${({isLoading}) => isLoading ? '12px' : undefined};
  animation: ${({isLoading}) => isLoading ? 'skeletonColor 1s linear infinite' : ''};
  border-radius: 10px;
  user-select: none;
  font-weight: lighter;
  transition: unset;
`
const Accommodation__Description = styled(Text)`
  user-select: none;
  width: calc(100vw - 2vw - 400px - 20px - 10px);
  display: -webkit-box;
  -webkit-line-clamp: ${isMobileOnly ? undefined : '12'};
  -webkit-box-orient: vertical;
  max-height: ${isMobileOnly ? '362px' : undefined};
  overflow: ${isMobileOnly ? 'hidden' : undefined};
  overflow: hidden;
  white-space: pre-wrap;
  @media (min-width: 1100px) {
    width: calc(1100px - 400px - 20px - 10px);
  }
  @media (max-width: 700px) {
    min-width: 0;
    max-width: calc(98vw - 20px);
    width: 100%;
  }
`
const Subject__Label = styled(FlexBox)<{ width: number }>`
  min-height: 14px;
  animation: skeletonColor 1s linear infinite;
  width: ${({width}) => width}px;
  border-radius: 10px;
  margin-top: 10px;
`
const Body__Label = styled(FlexBox)<{ width: number }>`
  min-height: 12px;
  border-radius: 10px;
  animation: skeletonColor 1s linear infinite;
  width: ${({width}) => width}%;
  margin: 3px 0;
`
const Similar__Offer = styled(FlexBox)<{ isLoading?: boolean }>`
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 50px;
  width: calc(100% - 10px);
  margin-top: 10px;
  padding: 5px;
  gap: 2px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  cursor: ${({isLoading}) => isLoading ? 'not-allowed' : 'pointer'};
`
const Transportation__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
`
const Transport__Label = styled(Text)`
  max-width: calc(100% - 17px);
  margin-left: 2px;
`
const Show__Full__Description = styled(Text)`
  margin-top: 5px;
  cursor: pointer;
  text-align: right;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`
const Similar__List__Offer__Holder = styled(FlexBox)`
  padding-bottom: 10px;
  flex-direction: column;
  width: 100%;
`
const Close__Offer__Icon = styled(FontAwesomeIcon)`
  width: 15px;
  height: 20px;
  font-size: 20px;
  color: ${({theme}) => theme.color};
  margin-left: 5px;
`
const Accommodation__Details = styled(FlexBox)`
  width: 100%;
  flex-grow: 1;
  gap: 4px;
  align-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 10px;
`
const Bold__Label = styled.span`
  font-weight: bold;
`
const No_More_Offer_Found = styled(FlexBox)`
  margin: 10px 5px 10px 5px;

  padding: 2px 4px;
  box-shadow: ${({theme}) => `0px 0px 6px 0px ${theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'}`};
  background-color: ${({theme}) => theme.backgroundColor};
  border-radius: 5px;
  width: calc(100% - 18px);
  flex-direction: column;
`
const No_More_Offer_Found_Title = styled(Text)`
  font-size: 15px;
  font-weight: bold;
  margin: 5px auto 5px auto;
`
const No_More_Offer_Found_Description = styled(Text)`
  margin: 0 auto 10px auto;
`
const Error__Accommodation = styled(FlexBox)`
  flex-direction: column;
  height: 246px;
  width: 100%;
`
const Error__Handler = styled(FlexBox)`
  flex-direction: column;
  margin: auto;
`
const Error__Title = styled(Text)`
  margin: 10px auto;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
`
const Error__Button = styled(Button)`
  margin: 10px auto;
  min-width: 125px;
  padding: 0 5px;
`
const Error_Desc = styled(Text)`
  margin: 0 auto;
  text-align: center;
`
const Heart__Icon = styled(FontAwesomeIcon)<{ isExlusive: boolean, isFavourite: boolean, isOpen: boolean }>`
  color: ${({theme}) => theme.color};
  margin: 1px 0;
  margin-left: ${({isExlusive}) => isExlusive ? 'unset' : 'auto'};
  margin-right: ${({isOpen}) => !isOpen ? '20px' : '0'};
  color: ${({isFavourite, theme}) => isFavourite ? theme.favouriteOfferColor : theme.color};
  height: 18px;
  width: 18px;
`
export const Styled = {
    Heart__Icon,
    Error_Desc,
    Error__Button,
    Error__Title,
    Error__Handler,
    Error__Accommodation,
    No_More_Offer_Found,
    No_More_Offer_Found_Description,
    No_More_Offer_Found_Title,
    Accommodation__Holder,
    HotelImageHolder,
    Accommodation__Details__Holder,
    Accommodation__Label,
    Accommodation__Location__Label,
    Accommodation__Description,
    Subject__Label,
    Body__Label,
    Similar__Offer,
    Transportation__Icon,
    Transport__Label,
    Show__Full__Description,
    Similar__List__Offer__Holder,
    Close__Offer__Icon,
    Accommodation__Details,
    Bold__Label
}