import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../../lib/UIBookBrd/Button";
import Image from "../../../lib/UIBookBrd/Image";
import {isMobileOnly} from "react-device-detect";

const Trip__Search__Booking__Page__Content = styled(FlexBox)`
  flex-direction: column;
  padding-bottom: 10px;
`
const Offer__Holder = styled(FlexBox)`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.color};
`
const Particiapant__Section = styled(FlexBox)`
  cursor: pointer;
  padding: 8px 4px;
`
const Participant__Section__Title = styled(Text)`
  font-size: 18px;
`
const Chevron__Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: 10px;
  color: ${({theme}) => theme.color};
`
const Participant__Section__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 4px 10px;
  width: calc(100% - 20px);
  border-bottom: ${({theme}) => `1px solid ${theme.color}`};
`
const Participant__Section__Values = styled(FlexBox)`
  padding: 4px 0;
  flex-direction: column;
`
const Input__Holder = styled(FlexBox)`
  flex-direction: column;
  margin: 2px 0;
  max-width: 400px;
`
const Input__Label = styled(Text)`
  font-size: 12px;
  margin-bottom: 1px;
`
const Section__Type__Label = styled(Text)`
  font-weight: bold;
  padding: 10px;
  font-size: 20px;
`
const Payer__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  @media (max-width: 840px) {
    flex-direction: column;
  }

`
const Payers__Data__Holder = styled(FlexBox)`
  flex-grow: 1;
  max-width: 400px;
  flex-direction: column;
  margin-right: auto;
  padding: 0 10px;
  max-height: 500px;
  flex-wrap: nowrap;

  @media (max-width: 840px) {
    width: calc(100% - 20px);
    padding-right: 0;
    margin-right: 0;
  }
`


const CheckBoxes__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  width: calc(100% - 20px);
`
const Reserve__Button = styled(Button)`
  max-width: ${isMobileOnly ? 'unset' : '300px'};
  width: calc(100% - 20px);
  margin-left: ${isMobileOnly ? '10px' : 'auto'};
  margin-right: 10px;
  margin-bottom: 20px;
`
const Payment__Card__Graph = styled(FlexBox)`
  background-color: ${({theme}) => !theme.isDark ? '#5d5d5d' : '#e7e4e4'};;
  box-shadow: 0px 0px 5px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  max-width: 400px;
  min-width: 200px;
  flex-grow: 1;
  flex-direction: column;
  height: 200px;
  max-height: 200px;
  border-radius: 5px;

  @media (max-width: 830px) {
  }
`
const Payment__Card__Number = styled(Text)`
  font-weight: bold;
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 0 10px 10px;
  opacity: 0.8;
  color: ${({theme}) => theme.backgroundColor};
`
const Payment__Card__Name__Date = styled(Text)`
  font-weight: bold;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 1px 0 0 10px;
  opacity: 0.8;
  color: ${({theme}) => theme.backgroundColor};
`

const Card__Bank__Name = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  margin: 15px 0 0 15px;

`
const Card__Chip = styled(Image)`
  width: 120px;
  height: 80px;
  min-height: 50px;
  margin: 15px 0 10px 0;
  border-radius: 15px;
  object-fit: fill;
  background-color: transparent;
`
const Card__Field__Name = styled(Text)`
  font-size: 12px;
  color: ${({theme}) => theme.backgroundColor};
  opacity: 0.6;
  margin-left: 12px;
`
const Card__Inputs__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Loading__Offer__Error = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  height: 190px;
  @media (max-width: 650px) {
    height: 250px;
  }
`
const Loading__Offer__Error__Title = styled(Text)`
  font-weight: bold;
  text-align: center;
  margin: auto auto 0 auto;
`
const Loading__Offer__Error__Desc = styled(Text)`
  text-align: center;
  white-space: break-spaces;
  user-select: none;
  padding-top: 10px;
`
const No__Offer__Found = styled(Text)`
  text-align: center;
  margin: auto;
  font-weight: bold;

`


export const Styled = {
    No__Offer__Found,
    Loading__Offer__Error__Desc,
    Loading__Offer__Error__Title,
    Loading__Offer__Error,
    Card__Inputs__Holder,
    Card__Field__Name,
    Payment__Card__Name__Date,
    Card__Chip,
    Card__Bank__Name,
    Reserve__Button,
    Payers__Data__Holder,
    Payer__Holder,
    Payment__Card__Number,
    Section__Type__Label,
    Input__Label,
    Input__Holder,
    Trip__Search__Booking__Page__Content,
    Offer__Holder,
    Particiapant__Section,
    Participant__Section__Title,
    Chevron__Icon,
    Participant__Section__Holder,
    Participant__Section__Values,
    CheckBoxes__Holder,
    Payment__Card__Graph
}