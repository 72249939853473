import LoaderForAllViews from "./LoaderForAllViews";
import {isEmpty} from "lodash-es";
import {Styled} from "../ManageAccountPage.styles";
import {useTranslation} from "react-i18next";

type MainViewProps = {
    value: any
}

const MainView = (
    {
        value
    }: MainViewProps
) => {

    const {t} = useTranslation()

    if (!value?.isLoaded) {
        return (
            <LoaderForAllViews
                title={t('lbl_loading_main_feed')}
            />
        )

    }

    return isEmpty(value.data) ? (
        <Styled.Empty__Reviews>
            <Styled.Empty__Reviews__Title>
                {t('lbl_no_feed_yet')}
            </Styled.Empty__Reviews__Title>
            <Styled.Empty__Reviews__Description>
                {t('lbl_no_feed_yet_desc')}
            </Styled.Empty__Reviews__Description>
        </Styled.Empty__Reviews>
    ) : (
        <>
            REeviews
        </>
    )
}

export default MainView