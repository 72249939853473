import {Styled} from "../ManageContent.styles"
import {View__Types, ViewTypes} from "../../consts";
import {useTranslation} from "react-i18next";
import useConfirmationPage from "../../useConfirmationPage";

const ViewTypeSelect = () => {

    const {t} = useTranslation()

    const {
        viewType,
        chooseViewType
    } = useConfirmationPage()

    return (
        <Styled.View__Type__Select>
            {View__Types.map((item: ViewTypes, index: number) => (
                <Styled.Type__Tabs
                    key={`view_type_${item}`}
                    isSelected={viewType === item}
                    isLast={View__Types.length === index + 1}
                    onClick={() => chooseViewType(item)}
                >
                    {t(`lbl_${item}`)}
                </Styled.Type__Tabs>
            ))}

        </Styled.View__Type__Select>
    )
}
export default ViewTypeSelect