import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";

const Operator__Page__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  padding-bottom: 40px;
`
const Operator__Header = styled(FlexBox)`
  flex-direction: initial;
  width: calc(100% - 20px);
  padding: 10px;
`
const Operator__Name__Title = styled(Text)`
  font-size: 22px;
  font-weight: bold;
`
const Caruzel__Type__Tabs = styled(FlexBox)<{ isLoadingSections: boolean }>`
  flex-wrap: nowrap;
  max-width: calc(98vw - 10px);
  padding: 10px 0;
  overflow: ${({isLoadingSections}) => isLoadingSections ? 'hidden' : 'scroll'};
`

const Type__Tabs = styled(Text)<{ isSelected: boolean, isLast: boolean }>`
  white-space: nowrap;
  user-select: none;
  padding: 10px 15px;
  margin: 5px 0;
  font-size: 19px;
  font-weight: ${({isSelected}) => isSelected ? 'bold' : undefined};
  border-right: 1px solid ${({theme, isLast}) => isLast ? 'transparent' : theme.color};
  cursor: pointer;
`
const Main__Values__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 0 10px;
  width: cacl(100% -20px);
`
export const Styled = {
    Main__Values__Holder,
    Caruzel__Type__Tabs,
    Type__Tabs,
    Operator__Name__Title,
    Operator__Page__Holder,
    Operator__Header
}