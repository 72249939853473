import {useContext} from "react";
import {Payment_Status} from "../../lib/Context/Context";
import {FindOrderFieldsType} from "../../lib/Types";
import {isEmpty} from "lodash-es";
import useNavigation from "../../lib/UseHooksBookBrd/useNavigation";
import {ORDER_MANAGE} from "../../lib/Routes";
import {ViewTypes} from "./consts";

const useConfirmationPage = () => {

    const navigate = useNavigation()

    const [paymentStatus, setPaymentStatus] = useContext<any>(Payment_Status)

    const shareOnSocialMedia = () => {
    }

    const manageReservationClickHandler = () => {


    }

    const chooseViewType = (type: ViewTypes) => {
        setPaymentStatus((state: any) => ({
            ...state,
            viewType: type
        }))
    }

    const getOrderStatus = async (orderCode: string | number, paymentStatus: string) => {
        setPaymentStatus((state: any) => ({
            ...state,
            kutengs: 'siema',
            orderNumber: '231231231231',
            orderData: {}
        }))
        navigate(`${ORDER_MANAGE}/231231231231`)


    }

    const onConfirmButton = (state: any, setState: any) => {
        const errorList: string[] = []
        const orderCode = state[FindOrderFieldsType.orderCode]
        const payerName = state[FindOrderFieldsType.payerName]

        if (orderCode.length !== 14) {
            errorList.push(FindOrderFieldsType.orderCode)
        }
        if (isEmpty(payerName)) {
            errorList.push(FindOrderFieldsType.payerName)
        }

        if (!isEmpty(errorList)) {
            setState((state: any) => ({
                ...state,
                errors: errorList
            }))
        } else {
            getOrderStatus(orderCode, paymentStatus)
        }
    }

    return ({
        chooseViewType,
        onConfirmButton,
        shareOnSocialMedia,
        manageReservationClickHandler,
        refund: paymentStatus?.refund,
        viewType: paymentStatus?.viewType,
        participants: paymentStatus?.participants,
        offer: paymentStatus?.offer,
        plan: paymentStatus?.plan,
        offerPictures: paymentStatus?.offerPictures,
        orderStatus: paymentStatus?.orderStatus,
        orderNumber: paymentStatus?.orderNumber,
        paymentStatus
    })

}
export default useConfirmationPage