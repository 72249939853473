import {Styled} from "../Footer.styles";
import Text from "../../../lib/UIBookBrd/Text";
import React from "react";
import {useTranslation} from "react-i18next";
import '../../../Styles/fonts.css'

const HoldingsInformation = () => {

    const {t} = useTranslation();

    return (
        <Styled.Company__Footer__Holder>
            <Text
                style={{
                    textAlign: 'center',
                    userSelect: 'none',
                }}
            >
                <span
                    style={{
                        marginLeft: '2px',
                        fontWeight: 'bold',
                        fontFamily: 'serif'
                    }}
                >
                    Book
                </span>
                <span
                    style={{
                        margin: "auto 0 ",
                        marginLeft: '4px',
                        fontFamily: 'HandWritenBrdStyle'
                    }}>
                    Brd
                </span>
                {' '}
                {t('lbl_is_part_of')}
                <span
                    style={{
                        marginLeft: '2px',
                        fontWeight: 'bold',
                        fontFamily: 'serif'
                    }}
                >
                    Holdings
                </span>
                <span
                    style={{
                        margin: "auto 0 ",
                        marginLeft: '4px',
                        fontFamily: 'HandWritenBrdStyle'
                    }}>
                    Brd
                </span>
            </Text>
        </Styled.Company__Footer__Holder>
    )
}
export default HoldingsInformation