import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../lib/UIBookBrd/Text";

const Attributes__Component = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;

`

const Attributes__Holder = styled(FlexBox)<{ rowsNumber: number }>`
  overflow: hidden;
  max-height: ${({rowsNumber}) => rowsNumber ? rowsNumber * 32 : 32}px;
  min-height: ${({rowsNumber}) => rowsNumber ? rowsNumber * 32 : 32}px;
  width: 100%;
  gap: 4px;
`
const Attribute = styled(FlexBox)`
  height: 28px;
  width: 28px;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.color};;
`
const Attribute__Icon = styled(FontAwesomeIcon)`
  height: 15px;
  width: 15px;
  margin: auto;
  color: ${({theme}) => theme.color};
`
const Title = styled(Text)`
  font-weight: bold;
  margin-bottom: 4px;
`
const Hidden_Attributes__Number = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  margin: auto;
  width: 30px;
  text-align: center;
`
export const Styled = {
    Attributes__Component,
    Attributes__Holder,
    Attribute,
    Attribute__Icon,
    Title,
    Hidden_Attributes__Number,
}