import usePricesHandler from "../../../../../../lib/UseHooksBookBrd/usePricesHandler";
import {Styled} from "./BookingComponent.styles";
import {useTranslation} from "react-i18next";
import Tooltip from "../../../../../../lib/UIBookBrd/Tooltip";
import {faCircleInfo} from "@fortawesome/pro-solid-svg-icons";
import {floor} from "lodash-es";

type PriceHolderProps = {
    Base: any
    showFixedTitle?: boolean
}


const PriceHolder = (
    {
        showFixedTitle,
        Base
    }: PriceHolderProps
) => {
    const {t} = useTranslation();

    const {
        toolTipContent,
        mainValue,
        taxInfo,
        isCurrencyDiff
    } = usePricesHandler(Base?.Price)

    return (
        <Tooltip
            content={toolTipContent}
        >
            <Styled.Price__Label__Holder>
                <Styled.Price__Label
                    showFixedTitle={false}
                >
                    <Styled.Price__Type__label
                        showFixedTitle={false}
                    >
                        {`${t('lbl_total_price')}:`}
                    </Styled.Price__Type__label>
                    <Styled.Price__Label__Amount
                        showFixedTitle={false}
                    >
                        {floor(mainValue.total.amount)}{' '}{mainValue.total.currency}
                    </Styled.Price__Label__Amount>
                    <Styled.InformationIcon
                        icon={faCircleInfo}
                    />
                </Styled.Price__Label>
            </Styled.Price__Label__Holder>
        </Tooltip>
    )
}
export default PriceHolder