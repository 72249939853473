import TripSearchResultPageContent from "./index";
import PageContext from "./Context/PageContext";
import TripOfferContext from "./Context/TripOfferContext";
import SimilarOfferContext from "./Context/SimilarOfferContext";

const TripResultsPage = () => {

    return (
        <PageContext>
            <TripOfferContext>
                <SimilarOfferContext>
                    <TripSearchResultPageContent/>
                </SimilarOfferContext>
            </TripOfferContext>
        </PageContext>
    )
}
export default TripResultsPage