import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";

const Content__Holder = styled(FlexBox)`
  @keyframes skeletonColor {
    0% {
      background: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
    50% {
      background: ${(props: { theme: any; }) => props.theme.optionHoverColor};
    }
    100% {
      background: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};
    }
  }
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Content__Part = styled(FlexBox)`
  min-height: 50px;
  background-color: ${(props: { theme: any; isGrey: boolean }) => props.isGrey && props.theme.optionHoverColor};;
  width: 100%;
  flex-wrap: nowrap;
`
const Content__Option = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 10px 5px;
  width: 100%;
  max-width: 50%;
`
const Content__Option__Label = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  padding-bottom: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
  font-weight: bold;
`
const Content__Option__Label__Skeleton = styled(FlexBox)`
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  padding-bottom: 4px;
  font-size: 14px;
  width: 100px;
  height: 14px;
  border-radius: 15px;
  font-weight: bold;
  animation: ${(props: { isLoading: any; isGrey: any }) => props.isLoading ? 'skeletonColor 1s linear infinite' : undefined};
  background-color: ${(props: { theme: any; isGrey: any }) => props.isGrey ? props.theme.typeOfSearchHover : props.theme.optionHoverColor};
`
const Content__Option__Value = styled(Text)`
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  font-size: 14px;
  width: calc(100% - 10px);
`
const Content__Option__Value__Skeleton = styled(FlexBox)`
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  font-size: 14px;
  height: 14px;
  border-radius: 15px;
  width: calc(50% - 10px);
  animation: ${(props: { isLoading: any; isGrey: any }) => props.isLoading ? 'skeletonColor 1s linear infinite' : undefined};
  background-color: ${(props: { theme: any; isGrey: any }) => props.isGrey ? props.theme.typeOfSearchHover : props.theme.optionHoverColor};
`
const Content__Option__Value__Attribute = styled(FlexBox)`
  margin-left: 10px;

`

export const Styled = {
    Content__Holder,
    Content__Part,
    Content__Option,
    Content__Option__Label,
    Content__Option__Label__Skeleton,
    Content__Option__Value,
    Content__Option__Value__Skeleton,
    Content__Option__Value__Attribute
}