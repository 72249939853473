import {Styled} from "./TripOffersDetails.styles"
import PageHolder from "../../../lib/UIBookBrd/PageHolder";
import {useContext, useEffect, useState} from "react";
import {Search_Proto} from "../../../lib/Context/Context";
import useOfferDetailsFunctions from "./functions";
import Header from "./DedicatedComponent/Header";
import ModalsHolder from "./DedicatedComponent/CustomModalsDrawers/modalsHolder";
import OfferDetailsHolder from "./DedicatedComponent/OfferDetailsHolder";
import useNavigation from "../../../lib/UseHooksBookBrd/useNavigation";
import {BOOK_STEP_TRIP_SEARCH} from "../../../lib/Routes";
import {isEmpty} from "lodash-es";
import EmptyOffer from "./EmptyOffer";
import RequestError from "./RequestError";


const defaultControls = {
    isLoadingDescription: true,
    isLoadingImages: true,
    isLoadingRegionList: true,
    isLoadingWeather: true,
    isLoadingOfferDetails: true
}

const TripOfferDetails = () => {

    const [protoValue, _] = useContext<any>(Search_Proto)

    const reRoute = useNavigation()

    const [controls, setControls] = useState<any>(defaultControls)

    const [isMapOpen, setIsMapOpen] = useState<boolean>(false)

    const [offer, setOffer] = useState<any>(undefined)

    const [offerControls, setOfferControls] = useState<any>({})

    const [bookingControls, setBookingControls] = useState<any>({})

    const {
        getDetailsAndPictures
    } = useOfferDetailsFunctions(protoValue)

    const onShowMap = () => {
        setIsMapOpen(true)
    }

    const onCloseMap = () => {
        setIsMapOpen(false)
    }

    const reSendRequest = () => {
        getDetailsAndPictures(
            setOffer,
            setControls,
            setOfferControls)
    }

    const onBookClick = () => {
        const value = {
            offerId: offer?.Base?.OfferId,
            pictures: offerControls?.Images,
            offer,
        }
        const validOutput = JSON.stringify(value)
        sessionStorage.setItem('bookingValues', validOutput)
        reRoute(BOOK_STEP_TRIP_SEARCH)
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        if (!controls?.requestSent) {
            getDetailsAndPictures(
                setOffer,
                setControls,
                setOfferControls
            )
        }
    }, [])

    const content = (
        <>
            <PageHolder>
                <Styled.Trip_Offer_Details_Page_Holder>
                    <Header
                        controls={controls}
                        onBookClick={onBookClick}
                        bookingControls={bookingControls}
                        setBookingControls={setBookingControls}
                        onShowMap={onShowMap}
                        isLoadingOfferDetails={controls?.isLoadingOfferDetails}
                        offer={offer}
                        setOffer={setOffer}
                        requestSent={controls?.requestSent}
                        pictures={offerControls?.Images?.PictureUrlFull ?? []}
                    />
                    <OfferDetailsHolder
                        onShowMap={onShowMap}
                        controls={controls}
                        isLoadingOfferDetails={controls?.isLoadingOfferDetails}
                        offerControls={offerControls}
                        offer={offer}
                    />
                </Styled.Trip_Offer_Details_Page_Holder>
            </PageHolder>
            <ModalsHolder
                isMapOpen={isMapOpen}
                onCloseMap={onCloseMap}
                offer={offer}
            />
        </>
    )

    if (controls?.isLoadingOfferDetails) {
        return content
    }
    if (!controls?.isLoadingOfferDetails && !isEmpty(offer)) {
        return content
    }

    if (!controls?.isLoadingOfferDetails && controls?.errorData?.errorWithRequest) {
        return (
            <RequestError
                reSendRequest={reSendRequest}
                errorMessage={controls?.errorData?.errorMessage}
            />
        )
    }

    if (!controls?.isLoadingOfferDetails && isEmpty(offer)) {
        return (<EmptyOffer/>)
    }
    return (<EmptyOffer/>)

}
export default TripOfferDetails