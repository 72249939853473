import {Styled} from "./Calendar.styles";
import React from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";

type  AdditionalTimeButtonProps = {
    label: string
    add: any
    setEndDate: any
    setStartDate: any
    focusedInput: any
    setFocusedInput: any
    minDate: any
    startDate: any
}


const AdditionalTimeButton = ({
                                  label,
                                  add,
                                  setEndDate,
                                  setStartDate,
                                  focusedInput,
                                  setFocusedInput,
                                  minDate,
                                  startDate,

                              }: AdditionalTimeButtonProps) => {

    const addTime = () => {
        if (setStartDate && setEndDate) {
            if (focusedInput === 'startDate') {
                if (label === 'today') {
                    setStartDate({label: moment(minDate, 'DD.MM.YYYY').format('DD.MM.YYYY')})
                    setEndDate({label: moment(minDate, 'DD.MM.YYYY').format('DD.MM.YYYY')})
                } else {
                    setStartDate({label: moment(minDate, 'DD.MM.YYYY').add(1, add).format('DD.MM.YYYY')})
                    setEndDate({label: moment(minDate, 'DD.MM.YYYY').add(1, add).format('DD.MM.YYYY')})
                }
                setFocusedInput(['endDate'])
            }
            if (focusedInput === 'endDate') {
                setEndDate({label: startDate.add(1, add).format('DD.MM.YYYY')})
                setFocusedInput([])
            }
        }
    }
    const {t} = useTranslation();

    return (
        <Styled.SetAdditionalTimeButton
            onClick={addTime}
        >
            <Styled.SetAdditionalTimeButtonText>
                {t(label)}
            </Styled.SetAdditionalTimeButtonText>
        </Styled.SetAdditionalTimeButton>
    )
}
export default AdditionalTimeButton