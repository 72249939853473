// @ts-ignore
import styled from "styled-components";

const MainPageBackground = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};;

`

const MainPage = styled.div<{ backgroundImage?: string }>`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  transition: 500ms;
  max-width: 1500px;
  margin: 0 auto;
  background-color: ${({theme}) => theme.optionHoverColor};
  background-image: ${({backgroundImage}) => backgroundImage};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: 1500px) {
    box-shadow: 25px 0 15px -8px ${(props: { theme: any; }) => props.theme.backgroundColor} inset, -25px 0 15px -8px ${(props: { theme: any; }) => props.theme.backgroundColor} inset;
  }
`
const TypeOfSearchHolder = styled.div`
  user-select: none;
  overflow-x: scroll;
  -webkit-scrollbar: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  margin-top: 40px;
  width: calc(100% - 20px);
  padding: 0 10px;

  ::-webkit-scrollbar {
    display: none;
  }
`


export const Styled = {
    MainPageBackground,
    MainPage,
    TypeOfSearchHolder,
}