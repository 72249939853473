import TextInput from "../../../lib/UIBookBrd/TextInput";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {toNumber} from "lodash-es";
import BrdLoader from "../../../lib/UIBookBrd/BrdLoader";
import {Styled} from "./NewsSeller.styles";
import {faCheckCircle} from "@fortawesome/pro-light-svg-icons";
import {faHandshake} from "@fortawesome/pro-solid-svg-icons";

const NewsSeller = () => {

    const {t} = useTranslation();

    const [mailValue, setMailValue] = useState<string>()

    const [sectionBehaviour, setSectionBehaviour] = useState<any>({})

    const SectionRef = useRef<any>(null)

    const {subscribeToNewsSeller, unsubscribeNewsSeller} = useBookBrdPageFunctions()

    const inputChangeHandler = (event: any) => {
        setMailValue(event?.target?.value)
    }

    const onSubscribeButtonClickHandler = () => {
        setSectionBehaviour((state: any) => ({
            ...state,
            minHeight: toNumber(SectionRef?.current?.clientHeight) - 40,
        }))
        subscribeToNewsSeller(mailValue, setSectionBehaviour)
    }

    const getContentType = () => {

        switch (sectionBehaviour?.actionType) {
            case 'approved':
                return (
                    <>
                        <Styled.News__Seller__Title__Label>
                            {t('lbl_approved_news_seller_sign_up_title')}
                        </Styled.News__Seller__Title__Label>
                        <Styled.News__Seller__Desc__Label>
                            {t('lbl_approved_news_seller_sign_up_desc')}
                        </Styled.News__Seller__Desc__Label>
                        <Styled.Approved__Icon
                            icon={faCheckCircle}
                        />
                    </>
                )
            case 'existing':
                return (
                    <>
                        <Styled.News__Seller__Title__Label>
                            {t('lbl_existing_news_seller_sign_up_title')}
                        </Styled.News__Seller__Title__Label>
                        <Styled.News__Seller__Desc__Label>
                            {t('lbl_existing_news_seller_sign_up_desc')}
                        </Styled.News__Seller__Desc__Label>
                        <Styled.Approved__Icon
                            icon={faHandshake}
                        />
                    </>
                )
            default:
                return (
                    <>
                        <Styled.News__Seller__Title__Label>
                            {t('lbl_sign_for_news_seller_title')}
                        </Styled.News__Seller__Title__Label>
                        <Styled.News__Seller__Desc__Label>
                            {t('lbl_sign_for_news_seller_desc')}
                        </Styled.News__Seller__Desc__Label>
                        <Styled.News__Seller__Input__Holder>
                            <Styled.Controls__News__Seller>
                                <Styled.Insert_Mail_Input_Holder>
                                    <Styled.Error__Message__Label>
                                        {!!sectionBehaviour?.isError ? t('lbl_news_seller_email_error') : null}
                                    </Styled.Error__Message__Label>
                                    <TextInput
                                        error={!!sectionBehaviour?.isError}
                                        errorLabel={t('lbl_email_error')}
                                        style={{
                                            borderBottomRightRadius: '0',
                                            borderTopRightRadius: '0'
                                        }}
                                        autoComplete={'email'}
                                        textFieldType={'email'}
                                        value={{label: mailValue}}
                                        placeholder={'example@bookbrd.com'}
                                        isClearAble={false}
                                        showIcon={false}
                                        onChange={inputChangeHandler}
                                    />
                                </Styled.Insert_Mail_Input_Holder>
                                <Styled.Sign_Up_Button
                                    label={t('lbl_subscribe')}
                                    onClick={onSubscribeButtonClickHandler}
                                />
                            </Styled.Controls__News__Seller>
                            <Styled.Controls__UnSubscribe__Label
                                onClick={unsubscribeNewsSeller}
                            >
                                {t('lbl_news_seller_unsubscribe')}
                            </Styled.Controls__UnSubscribe__Label>
                        </Styled.News__Seller__Input__Holder>
                    </>
                )
        }
    }

    useEffect(() => {
        setSectionBehaviour((state: any) => ({
            ...state,
            isError: false
        }))
    }, [mailValue])

    return (
        <Styled.News_Seller__Holder
            newHeight={sectionBehaviour?.minHeight ? sectionBehaviour?.minHeight : 0}
            ref={SectionRef}
        >
            {sectionBehaviour?.isLoading ? (
                <Styled.Loader__Holder>
                    <Styled.Loading__Label>
                        {t('lbl_loading_news_seller_response')}
                    </Styled.Loading__Label>
                    <BrdLoader
                        style={{
                            margin: "auto"
                        }}
                    />
                </Styled.Loader__Holder>
            ) : (
                <>
                    {getContentType()}
                </>
            )}
        </Styled.News_Seller__Holder>
    )

}
export default NewsSeller