import CompareOffersModal from "../../../../../Components/CompareOffersModal";
import SimilarOffersDrawer from "../SimilarOffersDrawer";
import CompareOffersDrawer from "../CompareOffersDrawer";
import FiltersForTripResults from "../FiltersForTripResults";
import React from "react";

const AdditionalComponentsHolder = () => {

    return (
        <>
            <CompareOffersModal/>
            <CompareOffersDrawer/>
            <SimilarOffersDrawer/>
            <FiltersForTripResults/>
        </>
    )
}

export default AdditionalComponentsHolder