import SettingsOptions from "../../../lib/PageConfig";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import useOnClickOutside from "../../../lib/UseHooksBookBrd/useOnClickOutside";
import {Styled} from "../LanguageModal/LanguageModal.styles";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {isMobileOnly} from "react-device-detect";
import Modal from "../../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import Drawer from "../../../lib/UIBookBrd/Drawer";
import useConfigureSite from "../../../lib/UseHooksBookBrd/useConfigureSite";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";

const CurrencyModal = () => {

    const {Currency_Options} = SettingsOptions()

    const [currencyModal, setCurrencyModal] = useState<HTMLDivElement | null>(null);

    const {t} = useTranslation();

    const {
        isOpenCurrencyModal,
        selectedCurrency,
        onCloseCurrencyModal,
    } = useBookBrdPageFunctions()

    const {
        onCurrencyChangeHandler
    } = useConfigureSite()

    useOnClickOutside(currencyModal, onCloseCurrencyModal)

    const content = (
        <Styled.Countries__Options__Holder>
            {Currency_Options.map((item: any) => {

                const currencyName = item?.label

                const currencyCode = item?.value === null ? t('lbl_various') : item?.value

                const onOptionClick = () => {
                    onCurrencyChangeHandler(item)
                    onCloseCurrencyModal()
                }

                const isSelected = item?.value === selectedCurrency

                return (
                    <Styled.Currency__Option
                        key={`currency_${currencyName}`}
                        onClick={onOptionClick}
                    >
                        <FlexBox style={{
                            flexDirection: 'column'
                        }}>
                            <Styled.Currency__Name__Label>
                                {currencyName}
                            </Styled.Currency__Name__Label>
                            <Styled.Currency__Code__Label>
                                {currencyCode}
                            </Styled.Currency__Code__Label>
                        </FlexBox>
                        {isSelected && (
                            <Styled.Selected__Language__Icon
                                icon={faCheck}
                            />
                        )}
                    </Styled.Currency__Option>
                )
            })}
        </Styled.Countries__Options__Holder>
    )

    return !isMobileOnly ? (
        <Modal
            ref={setCurrencyModal}
            isOpen={isOpenCurrencyModal}
            onClose={onCloseCurrencyModal}
            width={900}
            title={t('lbl_currency')}
        >
            {content}
        </Modal>
    ) : (
        <Drawer
            onClose={onCloseCurrencyModal}
            title={t('lbl_currency')}
            isOpen={isOpenCurrencyModal}
            position={'bottom'}
        >
            {content}
        </Drawer>
    )
}
export default CurrencyModal