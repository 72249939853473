import {useContext} from "react";
import {User_Data} from "../../../lib/Context/Context";

const useAccountData = (specialState?: any) => {

    const [stateData, setStateData] = useContext<any>(User_Data)

    const state = specialState ?? stateData

    const defaultUserSettings: any = {
        userImage: state?.userImage ?? '',
        deleteBackground: state?.deleteBackground ?? false,
        userBackgroundImage: state?.userBackgroundImage ?? '',
        isLogged: true,
        userBackgroundColor: state?.userBackgroundColor ?? ''
    }

    return {
        defaultUserSettings,
        isLoading: state?.isLoading,
        userName: state?.userName ?? '',
        isLogged: state?.isLogged,
        deleteBackground: state?.deleteBackground ?? false,
        userBackgroundImage: state?.userBackgroundImage ?? '',
        userImage: state?.userImage ?? '',
        userBackgroundColor: state?.userBackgroundColor ?? ''
    }
}
export default useAccountData