import {Styled} from "../../TripSearchBookingPage";
import {payerInputs} from "../../Consts";
import {participantsType} from "../../types";
import TextInput from "../../../../../lib/UIBookBrd/TextInput";
import {includes} from "lodash-es";
import useBookingFunctions from "../../functions";
import {useTranslation} from "react-i18next";

const PayerDetails = () => {

    const {
        state,
        onEnterPressed,
        getAutoComplete,
        getInputType,
        getPlaceHolder,
        onInputChangeHandler,
    } = useBookingFunctions()

    const {t} = useTranslation();

    const sectionId = `${participantsType.payment}_0`

    return (
        <Styled.Payers__Data__Holder>
            <Styled.Section__Type__Label>
                {t('lbl_payer_information')}
            </Styled.Section__Type__Label>
            {payerInputs.map((inputName: string, index: any) => {
                return (
                    <Styled.Input__Holder>
                        <Styled.Input__Label>
                            {t(`lbl_${inputName}`)}
                        </Styled.Input__Label>
                        <TextInput
                            name={`payer_${getAutoComplete(inputName)}`}
                            id={`${participantsType.payment}_${index}`}
                            onEnterPressed={() => {
                                onEnterPressed(`${participantsType.payment}_${index}`)
                            }}
                            autoComplete={getAutoComplete(inputName)}
                            textFieldType={getInputType(inputName) as any}
                            placeholder={getPlaceHolder(inputName)}
                            showIcon={false}
                            onChange={(event: any) => {
                                onInputChangeHandler(event?.target?.value, sectionId, inputName)
                            }}
                            error={includes(state?.inValidFields ?? [], `${sectionId}_${inputName}`)}
                            isClearAble={false}
                            value={{
                                label: state?.participantsSection[sectionId] ? state?.participantsSection[sectionId][inputName] : '',
                                value: state?.participantsSection[sectionId] ? state?.participantsSection[sectionId][inputName] : ''
                            }}
                        />
                    </Styled.Input__Holder>
                )
            })}
        </Styled.Payers__Data__Holder>
    )
}
export default PayerDetails