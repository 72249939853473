import {Styled} from "./ManageAccountPage.styles";
import HeaderForManageAccount from "./HeaderForManageAccount";
import React from "react";
import {accountPageType} from "../../lib/Types";
import ViewsSwitch from "./ViewsSwitch";
import ViewContent from "./ViewContent";

type ContentProps = {
    type: accountPageType
}


const Content = (
    {
        type
    }: ContentProps
) => {

    return (
        <Styled.ManageAccountPageHolder>
            <HeaderForManageAccount
                type={type}
            />
            <ViewsSwitch
                type={type}
            />
            <ViewContent
            />
        </Styled.ManageAccountPageHolder>
    )
}

export default Content