import {Styled} from "../ContactPage.styles";
import Header from "./Header";
import {supportPageType} from "../../../lib/Types";
import Main from "./Main";
import FaqContent from "./FAQ/FaqContent";
import Support from "./Support";

type ContentProps = {
    type: supportPageType
}

const Content = (
    {
        type
    }: ContentProps
) => {

    const content: any = {
        [supportPageType.main]: <Main/>,
        [supportPageType.faq]: <FaqContent/>,
        [supportPageType.support]: <Support/>
    }

    return (
        <Styled.Contact__Page__Holder>
            <Header
                type={type}
            />
            <>
                {content[type]}
            </>
        </Styled.Contact__Page__Holder>
    )
}
export default Content