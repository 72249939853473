import Modal from "../../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import {useState} from "react";
import useTylerBot from "../Handlers/Hook";
import usePopperPositioning from "../../../lib/UseHooksBookBrd/usePopperPositioning";
import BotChatContent from "../BotChatContent";
import BotControls from "../BotControls";
import {useTranslation} from "react-i18next";

const ModalHolder = () => {

    const {
        tylerIconRef,
        isOpen,
        isNerdMode,
        toggleContainer
    } = useTylerBot()

    const {t} = useTranslation()

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const {styles, attributes} = usePopperPositioning({
        targetElementRef: tylerIconRef,
        popperElementRef: modalElement,
        applyMaxSizeEnabled: true,
        zIndex: 0,
        padding: 15,
    });

    return (
        <Modal
            ref={setModalElement}
            onClose={toggleContainer}
            isOpen={isOpen}
            showOverlay={false}
            positionedByPopper
            width={isNerdMode ? 650 : 350}
            title={t('lbl_tyler_title')}
            isCustomControlsHeight={true}
            controls={<BotControls/>}
            style={styles.popper}
            {...attributes.popper}
        >
            <BotChatContent/>
        </Modal>
    )
}

export default ModalHolder