import {Styled} from "./OfferDetailsOfferImages.styles"
import {useRef, useState} from "react";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import ImagesGalleryFullPage from "../../../../../../Components/ImagesGalery/ImagesGaleryFullPage";
import {isEmpty} from "lodash-es";
import {useSwipeable} from "react-swipeable";
import BrdLoader from "../../../../../../lib/UIBookBrd/BrdLoader";

type OfferDetailsOfferImagesProps = {
    isLoadingSections: boolean
    titleHeight: any
    showFixedTitle: boolean
    images: any[]
    requestSent: boolean
}

const OfferDetailsOfferImages = (
    {
        requestSent,
        isLoadingSections,
        showFixedTitle,
        titleHeight,
        images
    }: OfferDetailsOfferImagesProps
) => {

    const [currentImageNumber, setCurrentImageNumber] = useState<number>(0)

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const imagesListRef = useRef<any>(null)

    const onSmallImageClick = (index: any) => {
        setCurrentImageNumber(index)
    }
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            const {dir} = eventData
            if (dir === 'Left') {
                navigateImagesHandler('next')
            }
            if (dir === 'Right') {
                navigateImagesHandler('previous')
            }
        },
        preventScrollOnSwipe: true,
    });

    const navigateInList = (index: number) => {
        const elementWidth = 134
        imagesListRef.current.scrollTo(elementWidth * (index - 1), 0)
    }

    const navigateImagesHandler = (type: 'previous' | 'next') => {

        let number = currentImageNumber

        if (type === 'next') {
            if (number + 1 === images?.length) {
                number = 0
            } else {
                number = number + 1
            }
        }
        if (type === 'previous') {
            if (number === 0) {
                number = images.length - 1
            } else {
                number = number - 1
            }
        }

        setCurrentImageNumber(number)
        navigateInList(number)
    }

    const getNumberForImage = (number: number) => {
        if (images.length <= number) {
            return (number - (images.length - 1))
        } else {
            return number
        }
    }

    return (
        <>
            <Styled.Gallery__Holder
                titleHeight={showFixedTitle ? titleHeight : 0}
            >
                <Styled.Image__Holder>
                    <Styled.Main__Image
                        forceLoader={isLoadingSections}
                        disableLoader={requestSent && isEmpty(images) && !isLoadingSections}
                        showIconWhenEmpty={!isLoadingSections}
                        src={images[currentImageNumber]}
                    />
                    {!isLoadingSections && requestSent && !isEmpty(images) && (
                        <Styled.Control__Holder
                            {...handlers}
                        >
                            <Styled.Control
                                onClick={() => {
                                    navigateImagesHandler("previous")
                                }}
                            >
                                <Styled.Control__Icon
                                    icon={faChevronLeft}
                                />
                            </Styled.Control>
                            <Styled.Controls__Separator
                                onClick={() => {
                                    setIsOpen(true)
                                }}
                            />
                            <Styled.Control
                                onClick={() => {
                                    navigateImagesHandler("next")
                                }}
                            >
                                <Styled.Control__Icon
                                    icon={faChevronRight}
                                />
                            </Styled.Control>
                        </Styled.Control__Holder>
                    )}
                </Styled.Image__Holder>
                <Styled.Two__Images__Holder>
                    {Array.from({length: 2}).map((_: any, index: number) => {

                        const nextNumber = getNumberForImage((currentImageNumber + index))

                        return (
                            <Styled.Big__Image
                                key={`Big_Image_${index}`}
                                disableLoader={requestSent && isEmpty(images)}
                                showIconWhenEmpty={true}
                                onClick={() => {
                                    setCurrentImageNumber(nextNumber)
                                    setIsOpen(true)
                                }}
                                src={images[getNumberForImage(nextNumber)]}
                            />
                        )
                    })}

                </Styled.Two__Images__Holder>
                <Styled.Small__Images__Holder
                    isLoadingSections={isLoadingSections}
                    ref={imagesListRef}
                >
                    {isLoadingSections ? (
                        <>
                            {Array.from({length: 8})?.map((_, index: number) => {


                                return (
                                    <Styled.Loading__Small__Image
                                        key={`skeleton_small_image${index}`}
                                    >
                                        <BrdLoader style={{margin: 'auto'}}/>
                                    </Styled.Loading__Small__Image>
                                )
                            })}
                        </>
                    ) : (
                        <>
                            {images?.map((image: string, index: number) => {

                                const isCurrentImage = currentImageNumber === index

                                const onClick = () => {
                                    onSmallImageClick(index)
                                }

                                return (
                                    <Styled.Small__Image
                                        key={`small_image${index}`}
                                        disableLoader={requestSent && isEmpty(images)}
                                        showIconWhenEmpty={true}
                                        onClick={onClick}
                                        src={image}
                                        isCurrentImage={isCurrentImage}
                                    />
                                )
                            })}
                        </>
                    )}
                </Styled.Small__Images__Holder>
            </Styled.Gallery__Holder>
            <ImagesGalleryFullPage
                displayedImage={images[currentImageNumber]}
                isOpen={isOpen}
                setDisplayedImage={(value: any) => {
                    setCurrentImageNumber(images.indexOf(value))
                }}
                setIsOpen={setIsOpen}
                navigateFunction={navigateImagesHandler}
                pictures={images}
            />
        </>
    );
}
export default OfferDetailsOfferImages