import {Styled} from "./ImagesGallery.styles";
import OfferDetailsOfferImages
    from "../../TripSearch/TripOfferDetails/DedicatedComponent/Header/OfferGallery/OfferDetailsOfferImages";

type ImagesGalleryProps = {
    images: string[] | null
}

const ImagesGallery = (
    {
        images
    }: ImagesGalleryProps
) => {

    return (
        <Styled.Images__Gallery__Holder>
            <OfferDetailsOfferImages
                isLoadingSections={false}
                titleHeight={0}
                showFixedTitle={false}
                requestSent={true}
                images={images ?? []}
            />
        </Styled.Images__Gallery__Holder>
    )
}
export default ImagesGallery