import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import styled from "styled-components";
import Text from "../../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../../../../lib/UIBookBrd/Button";
import {isMobileOnly} from "react-device-detect";


const FilterHolderStyles = styled(FlexBox)`
  flex-direction: column;
  transition: 500ms;
  margin: 0 5px;
  min-width: 300px;
  @media (max-width: 800px) {
    min-width: unset;
    max-width: unset;
  }
`
const CustomText = styled(Text)`
  overflow: hidden;
  margin-left: 5px;
  max-width: 70px;
  min-width: 70px;
  rotation-point: center;
  white-space: nowrap;
`
const ChevronIcon = styled(FontAwesomeIcon)`
  transition: 500ms;
  width: 15px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 5px;
  font-size: 10px;
  color: ${(props: { theme: any; }) => props.theme.color};
`
const ShowFilterButton = styled(FlexBox)`
  transition: 500ms;
  margin-top: 40px;
  text-align: center;
  align-items: center;
  align-content: center;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background: ${(props: { theme: any; }) => props.theme.backgroundColor}
  height: 110px;
  width: 20px;
  border: 1px solid ${(props: { theme: any; }) => props.theme.borderColor};

  @media (max-width: 800px) {
    display: none;
  }

  &:hover {
    background: ${(props: { theme: any; }) => props.theme.color};
  }

  &:hover ${CustomText} {
    color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  }

  &:hover ${ChevronIcon} {
    color: ${(props: { theme: any; }) => props.theme.backgroundColor};
  }
`
const VerticalButton = styled(FlexBox)`
  margin-left: -41px;
  flex-wrap: nowrap;
  rotate: -90deg;
`
const FiltersContentHolder = styled(FlexBox)`
  gap: 10px 0;
  flex-direction: column;
  padding: 0 5px;
  flex-wrap: nowrap;
  padding-bottom: 10px;
`
const Type__Label = styled(Text)`
  font-weight: bold;
  padding-bottom: 2px;
`
const Section_Type_Label = styled(Text)`
  font-size: 21px;
  margin-left: -5px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
`
const Controls__Buttons__Holder = styled(FlexBox)`
  width: calc(100% - 10px);
  padding: 0 5px;
  flex-wrap: nowrap;
  flex-direction: ${isMobileOnly ? 'column' : undefined};
`
const Control__Button = styled(Button)`
  height: 34px;
  margin: ${isMobileOnly ? '2px 2px ' : 'auto 2px '};
  min-width: calc(50% - 4px);

`

export const Styled = {
    ChevronIcon,
    VerticalButton,
    FilterHolderStyles,
    ShowFilterButton,
    CustomText,
    FiltersContentHolder,
    Type__Label,
    Section_Type_Label,
    Controls__Buttons__Holder,
    Control__Button

}