import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";

const ObjectFirstLook__Section__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  @media (max-width: 1000px) {
    padding-bottom: 10px;
    flex-direction: column;
  }
`
const SectionDescription__Texts__Holder = styled(FlexBox)`
  width: 30%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`
const SectionDescription__Label = styled(Text)`
  width: 100%;
  margin-top: auto;
  height: auto;
  padding: 10px 5px;
  text-align: center;
  font-weight: bolder;
  font-size: 24px;
  user-select: none;

  &:last-child {
    margin-bottom: auto;
  }
`
const SectionDescription__Description = styled(Text)`
  font-size: 15px;
  user-select: none;
  padding: 0 5px 10px 5px;
  margin-bottom: auto;
  width: 100%;
  text-align: center;
`
const SectionDescription__Children__Holder = styled(FlexBox)`
  width: 70%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`


export const Styled = {
    SectionDescription__Children__Holder,
    SectionDescription__Texts__Holder,
    SectionDescription__Label,
    SectionDescription__Description,
    ObjectFirstLook__Section__Holder,

};