import styled from "styled-components";
import Text from "../../../../../../lib/UIBookBrd/Text";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {rgba} from 'polished';

const Offer_Description_Main_Labels = styled(Text)`
`
const Offer_Description_main_Holder = styled(FlexBox)`
  gap: 6px 10px;
  @media (max-width: 599px) {
    flex-direction: column;
  }
`
const Offer_Description_Main_Label_Bold = styled.span`
  margin-left: 2px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  color: ${({theme}) => theme.color};

  &:hover {
    text-decoration: underline;
  }
`
const Location__Value__Label = styled.span`
  margin-left: 2px;
  font-weight: bold;
  font-size: 14px;
  color: ${({theme}) => theme.color};
`

const Offer_Description_Content_Holder = styled(FlexBox)`
  padding-top: 10px;
  width: 100%;
  flex-wrap: wrap-reverse;

`
const Offer_Description_Main_Values = styled(FlexBox)<{ isDescOpen: boolean }>`
  flex-grow: 1;
  max-width: 100%;
  min-height: 400px;
  max-height: ${({isDescOpen}) => isDescOpen ? undefined : '400px'};
  overflow: hidden;
  @media (max-width: 599px) {
    max-width: 100%;
  }
`
const Offer_Attributes_Holder = styled(FlexBox)`
  min-width: 250px;
  flex-direction: column;
  max-width: 250px;
  padding-left: 50px;
  @media (max-width: 599px) {
    padding-left: 0;
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
`

const Offer_Attributes_Section_Title = styled(Text)`
  padding: 3px 4px;
  font-size: 20px;
  font-weight: bold;
`
const Chevron__Icon = styled(FontAwesomeIcon)`
  height: 16px;
  width: 16px;
  margin-left: 5px;
  margin-right: 5px;
  color: ${({theme}) => theme.color};
`
const Attribute__Label = styled(Text)`
`
const Attribute = styled(FlexBox)`
  margin-left: 5px;
  padding: 2px 4px;
  @media (max-width: 599px) {
    margin-left: 0;
  }
`
const Attribute__Section__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 8px 0;
`
const Description__Section = styled(FlexBox)`
  flex-direction: column;
  padding: 10px 5px;

  &:first-of-type {
    padding: 0 5px;
  }
`
const Description__Section__Title = styled(Text)`
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
`
const Shadow = styled(FlexBox)`
  width: 100%;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.9)};
  box-shadow: 0px 0px 15px 15px ${({theme}) => rgba(theme.backgroundColor, 0.95)};
}
`
const ShowCloseLabel = styled(Text)<{ isLoadingSections: boolean }>`
  cursor: ${({isLoadingSections}) => isLoadingSections ? 'not-allowed' : 'pointer'};
  max-width: max-content;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`
const Description__Column__Holder = styled(FlexBox)<{ showFullWidth: boolean }>`
  flex-direction: column;
  max-width: ${({showFullWidth}) => showFullWidth ? '100%' : 'calc(100% - 300px)'};

  flex-grow: 1;
  @media (max-width: 599px) {
    max-width: 98vw;
  }

`
const No_Description_Label = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin: auto;
`
export const Styled = {
    No_Description_Label,
    Description__Column__Holder,
    Shadow,
    Description__Section__Title,
    Description__Section,
    Attribute__Section__Holder,
    Attribute,
    Attribute__Label,
    Chevron__Icon,
    Offer_Attributes_Section_Title,
    Offer_Attributes_Holder,
    Offer_Description_main_Holder,
    Location__Value__Label,
    Offer_Description_Main_Labels,
    Offer_Description_Main_Label_Bold,
    Offer_Description_Content_Holder,
    Offer_Description_Main_Values,
    ShowCloseLabel
}
