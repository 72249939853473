import Drawer from "../../../lib/UIBookBrd/Drawer";
import {isMobileOnly} from "react-device-detect";
import React, {useState} from "react";
import useOnClickOutside from "../../../lib/UseHooksBookBrd/useOnClickOutside";
import {useTranslation} from "react-i18next";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import Account from "./Parts/Account";
import TwoMainOptions from "./Parts/TwoMainOptions";
import OptionList from "./Parts/OptionList";

type ManageAccountDrawerProps = {
    isSmallWidth: boolean
    listOfOptions: any
}


const ManageAccountDrawer = (
    {
        isSmallWidth,
        listOfOptions,
    }: ManageAccountDrawerProps
) => {

    const {t} = useTranslation();

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const {
        setCloseClientDrawer,
        isOpenLanguageModal,
        isOpenCurrencyModal,
        isOpenClientDrawer
    } = useBookBrdPageFunctions()

    useOnClickOutside(modalElement, () => isSmallWidth && !isOpenLanguageModal && !isOpenCurrencyModal && setCloseClientDrawer());

    return (
        <Drawer
            ref={setModalElement}
            title={t('lbl_account_Drawer')}
            contentStyles={{
                minWidth: isMobileOnly ? '100vw' : '250px',
                maxWidth: isMobileOnly ? 'unset' : '350px',
                width: '100vw'
            }}
            onClose={setCloseClientDrawer}
            isOpen={isOpenClientDrawer}
            position={'left'}
        >
            <Account/>
            <TwoMainOptions/>
            <OptionList
                listOfOptions={listOfOptions}
            />
        </Drawer>
    )
}
export default ManageAccountDrawer