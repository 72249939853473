import Drawer from "../../../lib/UIBookBrd/Drawer";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Styled} from "./FavouriteOffers.styles";
import BrdLoader from "../../../lib/UIBookBrd/BrdLoader";
import useFavouriteOffers from "./useFavouriteOffers";
import useStorage from "../../../lib/UseHooksBookBrd/useStorage";

const defaultStateValue = {
    isRequestSent: false,
    isLoading: false,
    data: {
        trip: [],
        flights: [],
    }
}


const FavouriteOffers = () => {

    const [favouriteOptionsControl, setFavouriteOptionsControl] = useState(defaultStateValue)

    const [favouriteOffersRawList, setFavouriteOffersRawList] = useStorage('localStorage', 'rawFavouriteOffers', {trips: []})

    const [scrollTop, setScrollTop] = useState<number>(0)

    const {t} = useTranslation()

    const {
        isFavouriteOffersOpen,
        toggleFavouriteOptions
    } = useBookBrdPageFunctions()

    const {
        getIsListEmpty
    } = useFavouriteOffers()

    useEffect(() => {
        if (isFavouriteOffersOpen && !favouriteOptionsControl?.isRequestSent) {
            // request for favourite offers
        }
    }, [isFavouriteOffersOpen])


    const scrollFunction = () => {
        const navBarFixedHeight = 58
        const validHeight = navBarFixedHeight - window.scrollY > 0 ? navBarFixedHeight - window.scrollY : 0
        if (validHeight >= 0 && validHeight <= 58 && scrollTop !== validHeight) {
            setScrollTop(validHeight)
        }
    }

    useEffect(() => {
        scrollFunction()
        window.addEventListener('scroll', scrollFunction)
        return () => {
            window.removeEventListener('scroll', scrollFunction)
        }
    }, [isFavouriteOffersOpen])


    return (
        <Drawer
            title={t('lbl_your_favourite_offers')}
            showOverlay={false}
            isOpen={isFavouriteOffersOpen}
            position={'bottom'}
            onClose={() => toggleFavouriteOptions(false)}
            top={scrollTop}
            contentStyles={{
                height: `${window.screen.height - scrollTop as number}px`,
                top: `${scrollTop}px`
            }}
        >
            <Styled.Favourite__Offers__Holder>
                {favouriteOptionsControl?.isLoading ? (
                    <Styled.Favourite__Offers__Loader__Holder>
                        <Styled.Centered__Section__Title>
                            {t('lbl_favourites_offers_loader')}
                        </Styled.Centered__Section__Title>
                        <BrdLoader/>
                    </Styled.Favourite__Offers__Loader__Holder>
                ) : (
                    <>
                        {getIsListEmpty(favouriteOffersRawList) ? (
                            <Styled.Empty__Offers__Holder>
                                <Styled.Centered__Section__Title>
                                    {t('lbl_no_favourite_offers_title')}
                                </Styled.Centered__Section__Title>
                                <Styled.Section__Description>
                                    {t('lbl_no_favourite_offer_description')}
                                </Styled.Section__Description>
                            </Styled.Empty__Offers__Holder>
                        ) : (
                            <>
                                Favourite offers
                            </>
                        )}
                    </>
                )}

            </Styled.Favourite__Offers__Holder>
        </Drawer>
    )
}
export default FavouriteOffers