import Drawer from "../../../../../lib/UIBookBrd/Drawer";
import PageHolder from "../../../../../lib/UIBookBrd/PageHolder";
import {Styled} from "./CompareOffersDrawer.styles";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import SectionHolder from "./CustomSections/SectionHolder";
import OfferComparisonSection from "./OfferComparisonSection";
import OverViewSection from "./OverViewSection";
import DetailsSection from "./DetailsSection";
import {isEmpty, round} from "lodash-es";
import usePricesHandler from "../../../../../lib/UseHooksBookBrd/usePricesHandler";
import Tooltip from "../../../../../lib/UIBookBrd/Tooltip";
import useBookBrdPageFunctions from "../../../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import useTripResultsValues from "../../useTripResultsValues";

type CompareOffersDrawerProps = {}

type PriceHolderProps = {
    pricing: any
}

const PriceHolder = ({pricing}: PriceHolderProps) => {

    const {t} = useTranslation();

    const {mainValue, toolTipContent} = usePricesHandler(pricing) as any

    return !isEmpty(pricing) ? (
        <Tooltip
            content={toolTipContent}
        >
            <Styled.Price>
                {`${t('lbl_total_price')}: ${round(mainValue?.total?.amount)} ${mainValue?.total?.currency}`}
                <Styled.InformationIcon
                    icon={faInfoCircle}
                />
            </Styled.Price>
        </Tooltip>
    ) : null
}

const CompareOffersDrawer = (
    {}: CompareOffersDrawerProps
) => {

    const [clickOutsideIgnoreElements, setClickOutsideIgnoreElements] = useState<any[]>([])

    const [buttonsElement, setButtonsSElement] = useState<any>(null)

    const [isAccommodationDetailsOpen, setIsAccommodationDetailsOpen] = useState<boolean>(false)

    const [isTransportDetailsOpen, setIsTransportDetailsOpen] = useState<boolean>(false)

    const {
        state,
        toggleCompareDrawer
    } = useTripResultsValues()

    const {showOfferHandler} = useBookBrdPageFunctions()

    const {t} = useTranslation();

    const controlButtons = (
        <Styled.ShowOfferButtonsHolder>
            <PageHolder>
                <Styled.ShowOfferButtonsPositioner
                    ref={setButtonsSElement}
                >
                    <Styled.Button__Price__Holder>
                        {!state?.compareOffers?.isError && !isEmpty(state?.compareOffers?.data?.offerComparison) && !isEmpty(state?.compareOffers?.data?.offerComparison[0]) && (
                            <>
                                {!isEmpty(state?.compareOffers?.data?.offerComparison) && (
                                    <PriceHolder
                                        pricing={state?.compareOffers?.data?.offerComparison[0].price}
                                    />
                                )}
                                <Styled.ShowOfferButtons
                                    onClick={() => {
                                        showOfferHandler(state?.compareOffers?.data?.offers[0])
                                    }}
                                    label={t('lbl_see_offer_button')}
                                />
                            </>
                        )}
                    </Styled.Button__Price__Holder>
                    <Styled.Button__Price__Holder>
                        {!state?.compareOffers?.isError && !isEmpty(state?.compareOffers?.data?.offerComparison) && !isEmpty(state?.compareOffers?.data?.offerComparison[1]) && (
                            <>
                                {!isEmpty(state?.compareOffers?.data?.offerComparison) && (
                                    <PriceHolder
                                        pricing={state?.compareOffers?.data?.offerComparison[1].price}
                                    />
                                )}
                                <Styled.ShowOfferButtons
                                    onClick={() => {
                                        showOfferHandler(state?.compareOffers?.data?.offers[1])
                                    }}
                                    label={t('lbl_see_offer_button')}
                                />
                            </>
                        )}
                    </Styled.Button__Price__Holder>
                </Styled.ShowOfferButtonsPositioner>
            </PageHolder>
        </Styled.ShowOfferButtonsHolder>
    )

    return (
        <>
            <Drawer
                clickOutsideIgnoreElements={clickOutsideIgnoreElements}
                position={'top'}
                isOpen={state?.compareOffers?.isOpen}
                top={57}
                showOverlay={false}
                contentStyles={{height: 'calc(100vh - 107px)'}}
                title={t('lbl_offer_comparator')}
                onClose={() => {
                    toggleCompareDrawer(false)
                }}
                controls={controlButtons}

            >
                <PageHolder>
                    <Styled.ContentHolder>
                        <SectionHolder
                            label={t('lbl_offer_comparison')}
                            description={t("lbl_desc_compare_offers")}
                        >
                            <OfferComparisonSection
                                isError={!!state?.compareOffers?.isError}
                                setClickOutsideIgnoreElements={setClickOutsideIgnoreElements}
                                selectedOffers={state?.compareOffers?.comparedOffers}
                                isLoadingData={state?.compareOffers?.isLoading}
                                section={state?.compareOffers?.isError ? {} : state?.compareOffers?.data?.offerComparison}
                            />
                        </SectionHolder>
                        <SectionHolder
                            label={t('lbl_compare_overview_label')}
                        >
                            <OverViewSection
                                isLoadingData={state?.compareOffers?.isLoading}
                                section={state?.compareOffers?.isError ? {} : state?.compareOffers?.data?.overView}
                            />
                        </SectionHolder>
                        <SectionHolder
                            label={t('lbl_compare_details')}
                        >
                            <DetailsSection
                                isLoadingData={state?.compareOffers?.isLoading}
                                accommodationDetailsState={{
                                    boolean: isAccommodationDetailsOpen,
                                    setState: setIsAccommodationDetailsOpen
                                }}
                                transportDetailsState={{
                                    boolean: isTransportDetailsOpen,
                                    setState: setIsTransportDetailsOpen
                                }}
                                transportDetails={state?.compareOffers?.isError ? {} : state?.compareOffers?.data?.transportDetails}
                                accommodationDetails={state?.compareOffers?.isError ? {} : state?.compareOffers?.data?.accommodationDetails}
                            />
                        </SectionHolder>
                    </Styled.ContentHolder>
                </PageHolder>
            </Drawer>
        </>
    )
}

export default CompareOffersDrawer