import BasicPicker from "../BasicPicker";
import {memo, useCallback, useEffect, useState} from "react";
import {includes, uniq} from "lodash-es";
import {isMobileOnly} from "react-device-detect";
import useStorage from "../../lib/UseHooksBookBrd/useStorage";


type OutBoundPickerProps = {
    appendTo?: Element | null
    formikValue: any
    setFormikValues: any
}

const parseCheckedOptions = (value: any, options: any[]) => {
    let subOptions: any[] = []
    options?.map((item: any) => {
        if (item.value === value) {
            subOptions = item?.subOptions
        }

    })
    return subOptions
}

const setCheckedFromFormik = (value: string[], options: any[]) => {
    const flatListOfOptions: any[] = []
    const newList: any[] = []
    options.map((option: any) => {
        flatListOfOptions.push(option)
        option?.subOptions?.map((subOptions: any) => {
            flatListOfOptions.push(subOptions)
        })
    })
    value.map((item: any) => {
        if (item.includes(':')) {
            const subOptions: any[] = parseCheckedOptions(item, options)
            const parentOption: any[] = options.filter((option: any) => option.value === item)
            parentOption.map((option: any) => newList.push(option))
            subOptions.map((subOption: any) => newList.push(subOption))
        } else {
            flatListOfOptions.map((option: any) => option.value === item && newList.push(option))
        }

    })
    return newList
}

const deleteParentOption = (flatCheckedList: any, checkedList: any, value: any) => {
    let list: any = uniq(flatCheckedList)
    list = list.filter((item: any) => item !== value)
    if (includes(flatCheckedList, `${value?.split('_')[0]}:`)) {
        list = list.filter((item: any) => item !== `${value?.split('_')[0]}:`)
    }
    const newList = checkedList.filter((item: any) => includes(list, item.value))
    return newList
}

const getParentId = (value: any, options: any) => {
    const parent = options.filter((item: any) => item.value === value)
    return {
        label: parent[0].label,
        value: parent[0].value
    }
}

const parseSubmittedList = (checkedList: any, options: any[]) => {
    const flatCheckedList: any[] = []
    checkedList.map((option: any) => {
        const isParent = option.value.split(":").length > 1

        if (isParent) {
            flatCheckedList.push(option.value)
        }
        if (!isParent) {
            let hasParent = false
            const parent = options.filter((item: any) => item.value === `${option.value?.split('_')[0]}:`)[0] as any


            checkedList.map((item: any) => {
                if (item.value === parent.value) {
                    hasParent = true
                }

            })
            if (!hasParent) {
                flatCheckedList.push(option.value)
            }
        }
    })

    return flatCheckedList
}

const setAppliedListOptions = (formikValues: any, options: any) => {
    const flatListOfOptions: any[] = []
    options.map((option: any) => {
        flatListOfOptions.push(option)
        option?.subOptions?.map((subOptions: any) => {
            flatListOfOptions.push(subOptions)
        })
    })
    return flatListOfOptions.filter((option: any) => includes(formikValues, option.value))

}

const OutBoundPicker = ({
                            formikValue,
                            appendTo,
                            setFormikValues
                        }: OutBoundPickerProps) => {

    const [appliedList, setAppliedList] = useState<any[]>([])

    const [checkedList, setCheckedList] = useState<any[]>([])

    const [openOptionList, setOpenOptionList] = useStorage<any[]>('sessionStorage', 'OutBoundPickerOpenOption', [])

    const [searchValue, setSearchValue] = useState<any>({label: ''})

    const [showAutoSuggestOptions, setShowAutoSuggestOptions] = useState<boolean>(false)

    const [isLoadingAutoSuggest, setIsLoadingAutoSuggest] = useState<boolean>(false)

    const options: any = [
        {
            label: 'England',
            value: 'England:',
            subOptions: [
                {
                    label: 'London',
                    value: 'England_London'
                },
                {
                    label: 'Brighton',
                    value: 'England_Brighton'
                },
                {
                    label: 'LiverPool',
                    value: 'England_LiverPool'
                },
                {
                    label: 'Oxford',
                    value: 'England_Oxford'
                },

            ]
        },
        {
            label: 'Spain',
            value: 'Spain:',
            subOptions: [
                {
                    label: 'Madrid',
                    value: 'Spain_Madrid'
                },
                {
                    label: 'Barcelona',
                    value: 'Spain_Barcelona'
                },
                {
                    label: 'Mallorca',
                    value: 'Spain_Mallorca'
                },
            ]
        },
        {
            label: 'Japan',
            value: 'Japan:',
            subOptions: [
                {
                    label: 'Tokyo',
                    value: 'Japan_Tokyo'
                }
            ]
        },
        {
            label: 'Germany',
            value: 'Germany:',
            subOptions: [
                {
                    label: 'wroclaw',
                    value: 'wroclaw'
                }
            ]
        },


    ]

    const checkboxChangeHandler = useCallback((label: string | undefined, value: string | undefined) => {
        const flatList: any[] = []
        if (checkedList?.length > 0) {
            checkedList?.map((item: any) => flatList.push(item !== undefined && item?.value))
        }
        if (includes(flatList, value as string)) {
            let newList: any[] = checkedList
            if (value?.split('_').length as any === 1) {
                const subOptions: any[] = parseCheckedOptions(value, options)
                const flatList: any[] = []
                subOptions?.map((item: any) => {
                    flatList.push(item.value)
                })
                flatList.push(value)

                newList = newList.filter((item: any) => !includes(flatList, item.value))
                setCheckedList(newList)
            }
            if (value?.split('_').length as any > 1) {
                setCheckedList(deleteParentOption(flatList, checkedList, value))
            }
        }
        if (!includes(flatList, value as string)) {
            const newCheckedOptions: any[] = checkedList
            //isParentOption
            if (value?.split('_').length as any === 1) {
                const subOptions: any[] = parseCheckedOptions(value, options)
                newCheckedOptions.push({
                    label: label,
                    value: value
                })
                subOptions?.map((item: any) => {
                    newCheckedOptions.push({
                        label: item.label,
                        value: item.value
                    })
                })
                setCheckedList([])
            }
            //isSubOption
            if (value?.split('_').length as any > 1) {
                const FlatSubOptions: any[] = []
                const subOptions: any = parseCheckedOptions(`${value?.split('_')[0]}:`, options)
                subOptions?.map((item: any) => FlatSubOptions.push(item.value))
                newCheckedOptions.push({
                    label, value
                })
                const checkedSubOptions = newCheckedOptions.filter((item: any) => includes(FlatSubOptions, item.value))
                if (checkedSubOptions.length === FlatSubOptions.length) {
                    newCheckedOptions.push(getParentId(`${value?.split('_')[0]}:`, options))
                }
                setCheckedList(newCheckedOptions)
            }
        }

    }, [checkedList])

    const openChangeHandler = useCallback((value: string | undefined) => {
        if (includes(openOptionList, value)) {
            setOpenOptionList((state: any) => ([
                ...state.filter((item: any) => item !== value)
            ]))
        }
        if (!includes(openOptionList, value)) {
            setOpenOptionList((state: any) => ([
                ...state,
                value
            ]))
        }
    }, [openOptionList])

    const onSearchInputChangeHandler = useCallback((event: any) => {
        const value = event.target.value
        setSearchValue({label: value})

    }, [searchValue])

    const onSearchInputClearHandler = useCallback(() => {
        setSearchValue({label: ''})

    }, [searchValue])

    const onApplyChangeHandler = () => {
        setFormikValues('Base.FromField', parseSubmittedList(checkedList, options))
        setCheckedList([])
    }

    const onClear = () => {
        setCheckedList([])
        setFormikValues('Base.FromField', [])
    }

    useEffect(() => {
        if (searchValue?.label?.length > 2) {
            setShowAutoSuggestOptions(true)
            setIsLoadingAutoSuggest(true)
        } else {
            setShowAutoSuggestOptions(false)
        }
    }, [searchValue])

    useEffect(() => {
        setCheckedList(setCheckedFromFormik(formikValue, options))
        setAppliedList(setAppliedListOptions(formikValue, options))
    }, [formikValue])


    return (
        <>
            <BasicPicker
                isOpen={false}
                setIsOpen={() => {
                }}
                title={'lbl_outboundpicker'}
                placeholder={'from_where'}
                maxNumberOfColumns={isMobileOnly ? 1 : 4}
                minColumnWidth={200}
                width={1000}
                options={options}
                subOptionsEnabled={true}
                autoSuggestEnabled={true}
                showAutoSuggestOptions={showAutoSuggestOptions}
                appliedList={appliedList}
                openOptionList={openOptionList}
                searchValue={searchValue}
                checkboxChangeHandler={checkboxChangeHandler}
                openChangeHandler={openChangeHandler}
                onApplyChangeHandler={onApplyChangeHandler}
                onSearchInputChangeHandler={onSearchInputChangeHandler}
                onSearchInputClearHandler={onSearchInputClearHandler}
                onClear={onClear}
            />
        </>
    )
}
export default memo(OutBoundPicker)