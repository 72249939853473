import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";
import {useEffect, useRef, useState} from "react";
import Modal from "../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import {Styled} from "./ImagesGallery.styles";
import {faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faClose} from "@fortawesome/pro-solid-svg-icons";
import {useDebouncedCallback} from "use-debounce";
import {isMobile, isMobileOnly} from "react-device-detect";
import {useSwipeable} from "react-swipeable";
import useLocalData from "../../lib/UseHooksBookBrd/useSearchBaseData";

type ImagesGalleryFullPageProps = {
    setDisplayedImage: any
    navigateFunction: any
    pictures: any[]
    displayedImage?: string | undefined
    setIsOpen?: any
    isOpen?: boolean | undefined
}


const ImagesGalleryFullPage = (
    {
        setDisplayedImage,
        navigateFunction,
        pictures,
        displayedImage,
        setIsOpen,
        isOpen
    }: ImagesGalleryFullPageProps
) => {

    const {toggleDisableScroll} = useLocalData()

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const [isSmallHeight, setIsSmallHeight] = useState<boolean>(window?.innerHeight < 950 ? window?.innerWidth * 0.75 > window?.innerHeight - 30 : false)

    const [isShowSmallImages, setIsShowSmallImages] = useState<boolean>(false)

    const imagesListRef = useRef<any>(null)

    const onClose = () => {
        if (setIsOpen) {
            setIsOpen(false)
        }
    }

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            const {dir} = eventData
            if (dir === 'Left') {
                navigateFunction('next')
            }
            if (dir === 'Right') {
                navigateFunction('previous')
            }
        },
        preventScrollOnSwipe: true,
    });

    const setValue = useDebouncedCallback((boolean: boolean) => {
        setIsSmallHeight(boolean)
    }, isMobileOnly ? 50 : 200)

    const resizeFunction = () => {
        const vw = window?.innerWidth
        const vh = window?.innerHeight
        const isHorizontal = window?.orientation !== undefined ? window?.orientation !== 0 : false
        const value = (isHorizontal) ? true : vh < 950 ? vw * 0.75 > vh - 30 : false
        setValue(value)
    }

    useOnClickOutside(modalElement, onClose);

    useEffect(() => {
        const elementWidth = 154
        imagesListRef?.current?.scrollTo(elementWidth * (pictures.indexOf(displayedImage) - 1), 0)
    }, [displayedImage])

    useEffect(() => {
        if (isMobile) {
            addEventListener('orientationchange', resizeFunction)
        }
        addEventListener('resize', resizeFunction)
        return () => {
            if (isMobile) {
                removeEventListener('orientationchange', resizeFunction)
            }
            removeEventListener('resize', resizeFunction)
        }
    }, [])

    useEffect(() => {
        toggleDisableScroll(!!isOpen)
    }, [isOpen])
    return (
        <>
            <Modal
                ref={setModalElement}
                theme={'transparent'}
                isOpen={isOpen}
                width={1100}
            >
                <Styled.Image__Gallery__Holder>
                    <Styled.Close__Icon
                        icon={faClose}
                        onClick={onClose}
                    />
                    <Styled.All__Images__Holder>
                        <Styled.Image__Holder>
                            {Array.from({length: 5}).map((picture: any, index: number) => (
                                <Styled.MainImage
                                    isFocused={index === 0}
                                    isSmallHeight={isSmallHeight}
                                    src={pictures[pictures?.indexOf(displayedImage)] as string}
                                />
                            ))}
                            <Styled.Controls__Holder
                                isShowSmallImages={isSmallHeight && isShowSmallImages}
                                {...handlers}
                            >
                                <Styled.Control
                                    isShowSmallImages={isSmallHeight && isShowSmallImages}
                                    type={'previous'}
                                    onClick={() => {
                                        if (!isShowSmallImages) {
                                            navigateFunction('previous')
                                        }
                                    }}
                                >
                                    {(!isSmallHeight ? true : !isShowSmallImages) && (
                                        <Styled.navigation__Icon
                                            isShowSmallImages={isSmallHeight && isShowSmallImages}
                                            icon={faChevronLeft}
                                        />
                                    )}
                                </Styled.Control>
                                <Styled.Controls__Separator>
                                    {isSmallHeight && (
                                        <Styled.Show__Small__Images__Button
                                            isShowSmallImages={isSmallHeight && isShowSmallImages}
                                            onClick={() => {
                                                setIsShowSmallImages(!isShowSmallImages)
                                            }}
                                        >
                                            <Styled.Show__Images__Control__Image
                                                icon={isShowSmallImages ? faChevronDown : faChevronUp}
                                            />
                                        </Styled.Show__Small__Images__Button>
                                    )}
                                </Styled.Controls__Separator>
                                <Styled.Control
                                    isShowSmallImages={isSmallHeight && isShowSmallImages}
                                    type={'next'}
                                    onClick={() => {
                                        if (!isShowSmallImages) {
                                            navigateFunction('next')
                                        }
                                    }}
                                >
                                    {(!isSmallHeight ? true : !isShowSmallImages) && (
                                        <Styled.navigation__Icon
                                            isShowSmallImages={isSmallHeight && isShowSmallImages}
                                            icon={faChevronRight}
                                        />
                                    )}
                                </Styled.Control>
                            </Styled.Controls__Holder>
                        </Styled.Image__Holder>
                        <Styled.Small__Images__Holder
                            isShowSmallImages={isShowSmallImages}
                            isSmallHeight={isSmallHeight}
                            ref={imagesListRef}
                        >
                            {pictures?.map((picture: any) => <Styled.Small__Image
                                    isFocused={displayedImage === picture}
                                    onClick={() => {
                                        setDisplayedImage(picture)
                                    }}
                                    src={picture}
                                />
                            )}
                        </Styled.Small__Images__Holder>
                    </Styled.All__Images__Holder>
                </Styled.Image__Gallery__Holder>
            </Modal>
        </>
    )
}
export default ImagesGalleryFullPage