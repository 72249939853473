import {floor, isEmpty, toNumber} from "lodash-es";
import Tooltip from "../../../lib/UIBookBrd/Tooltip";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import {Styled} from "./TripSearchResultPage.styles";
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";
import {faStarHalf} from "@fortawesome/free-solid-svg-icons/faStarHalf";
import {faPlaneDeparture} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {faBus, faInfoCircle, faPersonWalkingLuggage} from "@fortawesome/pro-solid-svg-icons";
import Text from "../../../lib/UIBookBrd/Text";
import AccommodationAttributes from "../../../Components/AccommodationAttributes";
import Map from "../../../lib/UIBookBrd/Map";
import moment from "moment/moment";

//Components
type ContentPartForTransportProps = {
    type: string
    transport: any,
    typeLabel: any,
}

const ContentPartForTransport = (
    {
        type,
        transport,
        typeLabel,
    }: ContentPartForTransportProps) => {

    const {t} = useTranslation();

    const length = transport.length

    const maxNumber = length - 1

    switch (type) {
        case'Flight' :
            return (
                <Styled.ToolTip__Content__Part__Holder>
                    <Styled.ToolTip__Content__Type__Label>
                        {t(typeLabel)}:
                    </Styled.ToolTip__Content__Type__Label>
                    <Styled.ToolTip__Content__Label>
                        {`${t('lbl_additional_information')}: ${length === 1 ? t('lbl_direct_flight') : t('lbl_not_direct_flight')}`}
                    </Styled.ToolTip__Content__Label>
                    <Styled.ToolTip__Content__Label>
                        {`${t(`lbl_departure`)}: ${transport[0].Departure.Name} (${transport[0].Departure.Code}) ${transport[0].Departure.Date?.split('-').reverse().join('.')} ${transport[0].Departure.Time}`}
                    </Styled.ToolTip__Content__Label>
                    <Styled.ToolTip__Content__Label>
                        {`${t(`lbl_destination`)}: ${transport[maxNumber].Destination.Name} (${transport[maxNumber].Destination.Code}) ${transport[maxNumber].Destination.Date?.split('-').reverse().join('.')} ${transport[maxNumber].Destination.Time}`}
                    </Styled.ToolTip__Content__Label>
                </Styled.ToolTip__Content__Part__Holder>
            )
        case 'Bus' :
            return (
                <Styled.ToolTip__Content__Part__Holder>
                    <Styled.ToolTip__Content__Type__Label>
                        {t(typeLabel)}:
                    </Styled.ToolTip__Content__Type__Label>
                    <Styled.ToolTip__Content__Label>
                        {`${t(`lbl_departure`)}: ${transport.Departure.Name} (${transport.Departure.Code}) ${transport.Departure.Date?.split('-').reverse().join('.')} ${transport?.Departure?.Time === '00:00' ? '--:--' : transport?.Departure?.Time}`}
                    </Styled.ToolTip__Content__Label>
                    <Styled.ToolTip__Content__Label>
                        {`${t(`lbl_destination`)}: ${transport.Destination.Name} (${transport.Destination.Code}) ${transport.Destination.Date?.split('-').reverse().join('.')} ${transport?.Destination?.Time === '00:00' ? '--:--' : transport?.Destination?.Time}`}
                    </Styled.ToolTip__Content__Label>
                </Styled.ToolTip__Content__Part__Holder>
            )
        case'Own':
            return (
                <Text>
                    {t(typeLabel)}
                </Text>
            )
        default:
            return <></>
    }
}


// Transport Functions

const getFlightDetails = (values: any, data: any[], t: any) => {
    const flightValues: any[] = data

    const outBound = values.Out

    const returnFLight = values.Ret

    const isDirectOutBound = outBound.length === 1

    const isDirectReturn = returnFLight.length === 1

    flightValues.push(
        {
            type: t('lbl_flight_to'),
            value: isDirectOutBound ? t('lbl_direct_flight') : (`${t('lbl_not_direct_flight')} (${outBound.length - 1} ${t('lbl_stops')})`)
        },
    )


    outBound.map((option: any) => {
        const departureInfo = option.Departure
        const departureInfoValue = (
            <Styled.Custom__Flight__Info__Holder>
                <Styled.Type__Label>
                    {t('lbl_airport_name')}{':'}{' '}{`${departureInfo.Name} (${departureInfo.Code}) `}
                </Styled.Type__Label>
                <Styled.Type__Label>
                    {t('lbl_date')}{':'}{' '}{`${departureInfo.Date.split('-').reverse().join('.')}`}
                </Styled.Type__Label>
                <Styled.Type__Label>
                    {t('lbl_time')}{':'}{' '}{departureInfo.Time} {t('lbl_local_time')}
                </Styled.Type__Label>
            </Styled.Custom__Flight__Info__Holder>
        )
        flightValues.push(
            {
                type: t('lbl_flight_departure'),
                value: departureInfoValue
            },
        )
    })

    flightValues.push(
        {
            type: t('lbl_flight_back'),
            value: isDirectReturn ? t('lbl_direct_flight') : (`${t('lbl_not_direct_flight')} (${returnFLight.length - 1} ${t('lbl_stops')})`)
        },
    )

    returnFLight.map((option: any) => {
        const returnInfo = option.Departure
        const returnInfoValue = (
            <Styled.Custom__Flight__Info__Holder>
                <Styled.Type__Label>
                    {t('lbl_airport_name')}{':'}{' '}{`${returnInfo.Name} (${returnInfo.Code}) `}
                </Styled.Type__Label>
                <Styled.Type__Label>
                    {t('lbl_date')}{':'}{' '}{`${returnInfo.Date.split('-').reverse().join('.')}`}
                </Styled.Type__Label>
                <Styled.Type__Label>
                    {t('lbl_time')}{':'}{' '}{returnInfo.Time} {t('lbl_local_time')}
                </Styled.Type__Label>
            </Styled.Custom__Flight__Info__Holder>
        )
        flightValues.push(
            {
                type: t('lbl_flight_return'),
                value: returnInfoValue
            },
        )
    })


    return flightValues
}

export const getLuggageInfo = (luggage: string | undefined, t: any) => {
    switch (luggage) {
        case 'no':
            return t('lbl_luggage_not_included')
        case 'yes' :
            return t('lbl_luggage_included')
        case 'unspecified':
            return t('lbl_luggage_unspecified')
        case undefined :
            return t('lbl_not_specified')
    }
    return undefined
}

export const getTransferInfo = (transfer: any, t: any) => {
    switch (transfer) {
        case'yes':
            return t('lbl_transfer_included')
        case 'no':
            return t('lbl_transfer_not_included')
        default:
            return t('lbl_not_specified')
    }
}


export const GET_TRANSPORT_DETAILS = (offer: any, t: any) => {

    const {Transport} = offer

    const TransportType = Object.keys(Transport)[0]

    const values = Transport[TransportType]

    const newTransportData: any[] = [
        {
            type: t('lbl_transport_type'),
            value: !isEmpty(TransportType) ? t(`lbl_${TransportType.toLowerCase()}`) : ''
        },
    ]

    switch (TransportType) {
        case 'Flight':
            newTransportData.push(
                {
                    type: t('lbl_airline_type'),
                    value: values.AirlineType
                },
                {
                    type: t('lbl_luggage_registered'),
                    value: getLuggageInfo(values.Luggage, t)
                }
            )
            return getFlightDetails(values, newTransportData, t)

    }

    return undefined

}

export const GET_TRANSPORT_TYPE = (transport: any) => {
    if (!isEmpty(transport)) {
        return Object.keys(transport)[0]
    }
    return 'Own'
}

const getToolTip = (type: any, transport: any,) => {

    const getTitles = () => {

        switch (type) {
            case 'Flight':
                return {
                    Out: 'lbl_outbound_flight',
                    Ret: 'lbl_return_flight'
                }
            case 'Bus':
                return {
                    Out: 'lbl_outbound_bus',
                    Ret: 'lbl_return_bus'
                }
            case'Own':
                return ({
                    Out: '',
                    Ret: ''
                })
            default:
                return {
                    Out: '',
                    Ret: ''
                }
        }
    }
    const titles = getTitles()
    if (type === 'Own') {
        return (
            <Text
                style={{
                    fontSize: "12px"
                }}
            >
                <ContentPartForTransport
                    type={type}
                    transport={{}}
                    typeLabel={'lbl_own_transport'}
                />
            </Text>
        )
    } else {
        return (
            <>
                <ContentPartForTransport
                    type={type}
                    transport={transport?.Out}
                    typeLabel={titles?.Out}
                />
                <ContentPartForTransport
                    type={type}
                    transport={transport?.Ret}
                    typeLabel={titles?.Ret}
                />
            </>
        )
    }
}

const getValue = (type: any, transport: any, base: any) => {
    const isSameDate = base?.StartDate?.split('-')[0] === base.ReturnDate.split('-')[0]
    const returnDate = isSameDate ? base?.ReturnDate?.split('-').splice(1, 2).reverse().join('.') : base.ReturnDate.split('-').reverse().join('.')
    switch (type) {
        case'Flight':


            return (
                <>
                    <Styled.TransportIcon icon={faPlaneDeparture}/>
                    <Styled.IATAText>
                        {transport.Out[0].Departure.Code}
                    </Styled.IATAText>
                    <FlexBox style={{flexWrap: 'nowrap'}}>
                        <Styled.DatesText>
                            {`${base.StartDate?.split('-').reverse().join('.')} - ${returnDate} (${base.Duration}d / ${base.NightsBeforeReturn}n)`}
                        </Styled.DatesText>
                        <Styled.InformationIcon
                            icon={faInfoCircle}
                        />
                    </FlexBox>
                </>
            )
        case 'Bus' :
            return (
                <>
                    <Styled.TransportIcon
                        icon={faBus}
                    />
                    <Styled.IATAText>
                        {transport.Out.Departure.Code}
                    </Styled.IATAText>
                    <FlexBox style={{flexWrap: 'nowrap'}}>
                        <Styled.DatesText>
                            {`${base.StartDate?.split('-').reverse().join('.')} - ${returnDate} (${base.Duration}d/${base.NightsBeforeReturn}n)`}
                        </Styled.DatesText>
                        <Styled.InformationIcon
                            icon={faInfoCircle}
                        />
                    </FlexBox>
                </>
            )
        case'Own':
            return (
                <>
                    <Styled.TransportIcon
                        style={{
                            marginTop: '2px'
                        }}
                        icon={faPersonWalkingLuggage}
                    />
                    <FlexBox style={{flexWrap: 'nowrap'}}>
                        <Styled.DatesText
                            style={{
                                marginLeft: '0'
                            }}
                        >
                            {`${base?.StartDate?.split('-').reverse().join('.')} - ${returnDate} (${base?.Duration}d/${base?.NightsBeforeReturn}n)`}
                        </Styled.DatesText>
                        <Styled.InformationIcon
                            icon={faInfoCircle}
                        />
                    </FlexBox>
                </>
            )
    }
}

export const GET_TRANSPORT_COMPONENTS = (transport: any, base: any, type: any) => {


    const toolTipValue = getToolTip(type, transport && transport[type])

    return {
        toolTip: toolTipValue ? (
            <Styled.ToolTip__Content__Transport>
                {toolTipValue}
            </Styled.ToolTip__Content__Transport>
        ) : null,
        value: (
            <Styled.CustomText>
                {getValue(type, transport && transport[type], base)}
            </Styled.CustomText>
        )
    }
}

export const GET_TRANSPORT_OPTIONS = (t: any) => {

    return ([
        {
            label: t('lbl_flight'),
            value: 'transport.flight'
        },
        {
            label: t('lbl_bus'),
            value: 'transport.bus'
        },
        {
            label: t('lbl_own_transport'),
            value: 'transport.*'
        }
    ])
}

export const getTransportForV5 = (filterFormValue: any) => {

    if (isEmpty(filterFormValue)) {
        return [
            'transport.flight',
            'transport.bus',
            'transport.*'
        ]
    } else {
        if (filterFormValue?.length === 1 && filterFormValue[0] === 'transport.*') {
            return []
        }
        return filterFormValue
    }
}

export const getTimeDiff = (start: any, end: any, t: any) => {
    const duration = moment.duration(end.diff(start));
    return `${duration.hours()}${t('lbl_hours_sc')} ${duration.minutes() === 0 ? '00' : duration.minutes()}${t('lbl_minutes_sc')}`

}

// Accommodation Functions


export const getDistance = (distance: number) => {

    const isKilometer = distance >= 1000

    const value = isKilometer ? floor(toNumber(distance) / 1000, 1) : toNumber(distance)

    const meterType = isKilometer ? 'km' : 'm';

    const isNan = isNaN(value)

    return isNan ? 'unknown' : `${value} ${meterType}`
}

export const GET_ACCOMMODATION_DETAILS = (offer: any, t: any) => {

    const {Accommodation} = offer

    const distanceToAirport = getDistance(toNumber(Accommodation.DistanceToAirport))

    const distanceToBeach = getDistance(toNumber(Accommodation.DistanceToBeach))

    const distanceToCityCenter = getDistance(toNumber(Accommodation.DistanceToCityCenter))

    const minTemperature = toNumber(Accommodation?.ExtMonthlyWeather?.AirMinTemp)

    const maxTemperature = toNumber(Accommodation?.ExtMonthlyWeather?.AirMaxTemp)

    const averageTemperature = (minTemperature + maxTemperature) / 2

    const ratingComponent = (
        <Tooltip
            content={`${Accommodation.Category} ${t('lbl_stars')}`}
        >
            <FlexBox
                style={{width: "max-content"}}
            >
                {}
                {Array.from({length: (toNumber(Accommodation.Category))})?.map(_ => {
                    return (
                        <Styled.StarIcon
                            icon={faStar}
                        />
                    )
                })}
                {(toNumber(Accommodation.Category) * 2) % 2 === 1 && (
                    <Styled.StarIcon
                        icon={faStarHalf}
                    />
                )}
            </FlexBox>
        </Tooltip>
    )
    const accommodationContent = (
        <AccommodationAttributes
            attributes={Accommodation?.Attributes}
            maxNumberOfRows={1}
        />
    )

    const mapContent = (
        <Map
            styles={{width: '100%', height: "200px"}}
            locations={[{
                lat: Accommodation?.Location?.Coords[0] ?? 0,
                lng: Accommodation?.Location?.Coords[1] ?? 0
            }]}
        />
    )

    return [
        {
            type: t('lbl_accommodation_name'),
            value: Accommodation.Name
        },
        {
            type: t('lbl_rating'),
            value: ratingComponent
        },
        {
            type: t('lbl_accommodation_type'),
            value: Accommodation.Type.Name
        },
        {
            type: t('lbl_room_type'),
            value: Accommodation.Room.Name
        },
        {
            type: t('lbl_distance_to_airport'),
            value: distanceToAirport
        },
        {
            type: t('lbl_distance_to_beach'),
            value: distanceToBeach
        },
        {
            type: t('lbl_distance_to_city_center'),
            value: distanceToCityCenter
        },
        {
            type: t('lbl_average_temperature'),
            value: `${averageTemperature}${`°`}${t('C')}`
        },
        {
            type: t('lbl_board'),
            value: Accommodation.Service.Name
        },
        {
            type: t('lbl_accommodation_attributes'),
            value: accommodationContent
        },
        {
            type: t('lbl_destination_map'),
            value: mapContent
        },
    ]

}
