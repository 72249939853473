import {ViewTypes} from "../../../consts";
import Status from "./Status";
import useConfirmationPage from "../../../useConfirmationPage";
import Participants from "./Particpants";
import Text from "../../../../../lib/UIBookBrd/Text";
import Refund from "./Refund";
import Planner from "./Planner";
import OfferDetailsForConfimation from "./OfferDetails";

const Views = () => {

    const {
        viewType
    } = useConfirmationPage()

    const views: any = {
        [ViewTypes.Status]: <Status/>,
        [ViewTypes.OfferDetails]: <OfferDetailsForConfimation/>,
        [ViewTypes.ManageOffer]: <Participants/>,
        [ViewTypes.Refund]: <Refund/>,
        [ViewTypes.Calendar]: <Planner/>
    }


    return (
        <Text>
            {views[viewType]}
        </Text>
    )
}
export default Views