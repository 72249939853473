import {Styled} from "../../TripOffer.styles";
import {useTranslation} from "react-i18next";
import FlightSegmentContent from "./FlightSegment";

type FlightDescriptionProps = {
    showFullDetails?: boolean
    flightType: string
    flightInfo: any
}

const FlightDescription = (
    {
        showFullDetails,
        flightType,
        flightInfo
    }: FlightDescriptionProps
) => {

    const {t} = useTranslation();


    return (
        <Styled.FlightTypeDesc>
            <Styled.FlightTypeTitle>
                {t(`${flightType}`)}
            </Styled.FlightTypeTitle>
            {flightInfo.map((segment: any, index: number) => {

                return (
                    <FlightSegmentContent
                        key={`flight_segment_${index}`}
                        segment={segment}
                    />
                )
            })}
        </Styled.FlightTypeDesc>
    );

}
export default FlightDescription