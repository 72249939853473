import PageHolder from "../../lib/UIBookBrd/PageHolder";
import TylerDocumentationContent from "./Content";

const TylerDocumentationPage = () => {

    return (
        <PageHolder>
            <TylerDocumentationContent/>
        </PageHolder>
    )
}
export default TylerDocumentationPage