const useSocialMediaRedirect = () => {


    const onInstagramClickHandler = () => {
        window.open('https://www.instagram.com/mks_brd/?hl=pl', '_blank')
    }

    const onTwitterClickHandler = () => {
        window.open('https://twitter.com/mks_brd', '_blank')
    }
    const onFacebookClickHandler = () => {
        window.open('https://facebook.com', '_blank')
    }
    const onTikTokClickHandler = () => {
        window.open('https://tiktok.com', '_blank')

    }

    return {
        onInstagramClickHandler,
        onTwitterClickHandler,
        onFacebookClickHandler,
        onTikTokClickHandler
    }
}
export default useSocialMediaRedirect