export const OFFER_LIST_V5_FIELD_LIST = [
    "Transport.*",
    "Base.DatasetInfo",
    "Base.Availability",
    "Base.DepartureLocation",
    "Base.UniqueObjectId",
    "Base.OfferId",
    "Base.Operator",
    "Base.ThumbUrl",
    "Base.OperatorDesc",
    "Base.StartDate",
    "Base.ReturnDate",
    "Base.Duration",
    "Base.NightsBeforeReturn",
    "Base.XCountry",
    "Base.XCity",
    "Base.XRegion",
    "Base.XCode",
    "Base.Catalog",
    "Base.Price",
    "Base.OmnibusPrice",
    "Base.OriginalPrice",
    "Base.Transfer",
    "Base.Candy",
    "Base.Promotion",
    "Base.Refundable",
    "Base.Resident",
    "Accommodation.XCountry",
    "Accommodation.XCity",
    "Accommodation.Code",
    "Accommodation.Nights",
    "Accommodation.Name",
    "Accommodation.Location",
    "Accommodation.Category",
    "Accommodation.Room",
    "Accommodation.Service",
    "Accommodation.XService",
    "Accommodation.Rating",
    "Accommodation.Attributes",
    "Accommodation.Type",
    "Accommodation.ExtTripAdvisor",
    "Accommodation.ExtAgentAttributes",
    "Base.OfferId"
]