import {Styled} from "./AboutView.styles"

type GeneralValueProps = {
    title: string,
    value: string
}


const GeneralValue = (
    {
        title,
        value
    }: GeneralValueProps
) => {
    return (
        <Styled.General__Value__Holder>
            {title}:
            <span
                style={{fontWeight: 'bold'}}
            >
                {` ${value}`}
            </span>
        </Styled.General__Value__Holder>
    )
}
export default GeneralValue