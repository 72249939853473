import {Styled} from "./AutoSuggestOptionList.styles"
import {includes, isEmpty} from "lodash-es";
import AutoSuggestOption from "./AutoSuggestOption";
import {useTranslation} from "react-i18next";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import CustomIconForSection from "./CustomIconForSection";

type AutoSuggestOptionListProps = {
    autoSuggestCheckedOptions: any | undefined
    flatCheckedList: any[] | undefined
    checkboxChangeHandler: (label: string | undefined, value: string | undefined, type: string) => void
    autoSuggestOptions: any
}


const getLabelsForType = (optionValue: any, type: string) => {
    switch (type) {
        case'countries':
            return optionValue.country
        case 'regions':
            return optionValue.region
        case 'cities':
            return optionValue.city
        case 'hotels':
            return optionValue.hotelName
    }
}

const isChecked = (value: any, flatCheckedList: any | undefined, checkedAutoSuggest: any, sectionType: string) => {
    switch (sectionType) {
        case 'countries' :
            return includes(flatCheckedList, value)
        case 'regions' :
            return includes(flatCheckedList, value)
        case 'cities':
            return checkedAutoSuggest?.cities === value
        default:
            return includes(checkedAutoSuggest?.hotels, value)
    }
}

const AutoSuggestOptionList = (
    {
        autoSuggestCheckedOptions,
        flatCheckedList,
        checkboxChangeHandler,
        autoSuggestOptions
    }: AutoSuggestOptionListProps
) => {


    const {t} = useTranslation();

    return (
        <Styled.AutoSuggestOptionList__Holder>
            {Object.keys(autoSuggestOptions).map((sectionType: any) => {
                const sectionValue = autoSuggestOptions[sectionType]
                return (
                    <>
                        {!isEmpty(sectionValue) && (
                            <Styled.AutoSuggestSection>
                                <FlexBox>
                                    <CustomIconForSection
                                        sectionType={sectionType}
                                    />
                                    <Styled.AutoSuggestSection__Label>
                                        {t(`lbl_${sectionType}`)}
                                    </Styled.AutoSuggestSection__Label>
                                </FlexBox>
                                {sectionValue.map((optionValue: any) => {

                                    const isDisabled = sectionType !== 'cities' ? false : !isEmpty(autoSuggestCheckedOptions?.cities) ? optionValue.code !== autoSuggestCheckedOptions?.cities : false
                                    const onChange = () => {
                                        checkboxChangeHandler(getLabelsForType(optionValue, sectionType), optionValue.code, sectionType)
                                    }

                                    return (
                                        <AutoSuggestOption
                                            isDisabled={isDisabled}
                                            isChecked={isChecked(optionValue.code, flatCheckedList, autoSuggestCheckedOptions, sectionType) as boolean}
                                            onChange={onChange}
                                            optionValue={optionValue}
                                            sectionType={sectionType}
                                        />
                                    )
                                })}

                            </Styled.AutoSuggestSection>
                        )}
                    </>
                )
            })}
        </Styled.AutoSuggestOptionList__Holder>
    )
}
export default AutoSuggestOptionList