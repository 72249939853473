import {isEmpty, isNull, isString} from "lodash-es";
import {enCodeTripValues} from "../UseHooksBookBrd/useHashForm";
import {Proto_Hash_Default_Values} from "./index";
import {SearchFormFromSeoList} from "../Configs/SEO";
import {getValueForSearchForm} from "./SeoValidation";
import {Default_Search_Form_Values} from "../DefaultFormikValue/DefaultSearchFormValues";
import {isPageWithFlightSearch, isPageWithTripSearch} from "../RoutesHandler";


const reduceUseLessContent = (value: any) => {
    let parsedValue = typeof value === 'string' ? JSON.parse(value) : value
    const emptyField = Object.keys(parsedValue).filter((name: string) => {
        return typeof parsedValue[name] === 'number' ? false : isEmpty(parsedValue[name])
    });
    emptyField.map((name: string) => delete parsedValue[name])
    return parsedValue
}

const getSearchFromSEOFormatForTrip = (value: any) => {
    let newSearchFormValue: any = {}
    let newFilterFormValue: any = {}
    let readyValues: any = {}
    value.split('&').filter((item: string) => item).map((section: string) => {
        const [type, value] = section.split('=')
        const correctFormValues: any = {}
        if (type === 'Trip' || type === 'Filters') {
            value.split('/').filter((item: string) => item).map((item: any) => {
                const [field, fieldValue] = item.split('_')
                correctFormValues[field] = fieldValue
            })
            readyValues[type] = correctFormValues
        } else if (type === 'ShownOffer') {
            readyValues[type] = value
        } else {
            readyValues[type] = JSON.parse(value)
        }

    })
    Object.keys(readyValues).map((section: string) => {
        const sectionValue = readyValues[section]
        switch (section) {
            case 'Trip':
                Object.keys(sectionValue).map((type: string) => {
                    const value = sectionValue[type]
                    const formType = SearchFormFromSeoList[type]
                    newSearchFormValue[formType ?? type] = getValueForSearchForm(value, formType)
                })
                break
            case'Filters':
                Object.keys(sectionValue).map((type: string) => {
                    const value = sectionValue[type]
                    const formType = SearchFormFromSeoList[type]
                    newFilterFormValue[formType ?? type] = getValueForSearchForm(value, formType)
                })
                break
            default:
                break
        }
    })

    return ({
        values: reduceUseLessContent({
            SearchFormValues: newSearchFormValue,
            offerDetails: readyValues['ShownOffer'],
            FilterFormValues: newFilterFormValue,
            similarOffers: readyValues['similarOffers'],
        })
    })
}


const PREFIX = '?&search='

const addPrefix = (value: string) => `${PREFIX}${value}`


const getValidTripValue = (value: any, nextPath: any) => {

    const routingFunc = (value: any, path: string) => {
        const {values} = value

        return values
    }

    const parsedValue = getSearchFromSEOFormatForTrip(value)

    const validValue = routingFunc(parsedValue, nextPath)

    const encodedValue = enCodeTripValues(validValue)


    return `?&search=${encodedValue}`
}

const getValid = (nextPath: string, search: string) => {
    const nextSearchType = `/${nextPath.split('/')[2]}`
    const storageValue = sessionStorage.getItem('searchValuesForAllTypes')
    if (isPageWithTripSearch(nextPath)) {
        if (!search?.includes('Trip')) {
            if (!isNull(storageValue) && !isEmpty(storageValue) && isString(storageValue)) {
                const parsed = JSON.parse(storageValue as string) ?? {}
                const memoryValue = parsed[nextSearchType]
                if (memoryValue) {
                    return addPrefix(memoryValue)
                }
                return addPrefix(enCodeTripValues(Proto_Hash_Default_Values))
            }
        } else {
            return getValidTripValue(search, nextPath)
        }
        return getValidTripValue(enCodeTripValues({SearchFormValues: Default_Search_Form_Values}), nextPath)
    }
    if (isPageWithFlightSearch(nextPath)) {
        if (!search?.includes('Flight')) {
            if (!isNull(storageValue) && isString(storageValue) && !isEmpty(storageValue)) {
                if (!isNull(storageValue) && isString(storageValue) && !isEmpty(storageValue)) {
                    const parsed = JSON.parse(storageValue as string) ?? {}
                    const memoryValue = parsed[nextSearchType]
                    if (memoryValue) {
                        return addPrefix(memoryValue)
                    }
                    return addPrefix(enCodeTripValues(Proto_Hash_Default_Values))
                }
            }
        } else {

        }
    }
    return ''
}


export {
    reduceUseLessContent,
    getValid
}