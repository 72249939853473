import {Styled} from "./TextArea.styles"
import {useEffect, useRef} from "react";
import {floor, isEmpty, isNumber} from "lodash-es";

type TextAreaProps = {
    disableEnter?: boolean
    isAutoFocus?: boolean
    maxLineCount?: number
    resizeToContent?: boolean
    onChange?: (event: any) => void
    isError?: boolean
    value?: string
    placeholder?: string
    style?: React.CSSProperties
}

const defaultProps = {
    disableEnter: false,
    maxLineCount: undefined,
    isAutoFocus: false,
    resizeToContent: false,
    onChange: (event: any) => false,
    value: "",
    placeholder: '',
    isError: false,
    style: {}
}

const TextArea = (
    {
        disableEnter,
        maxLineCount,
        isAutoFocus,
        resizeToContent,
        onChange,
        placeholder,
        isError,
        style,
        value
    }: TextAreaProps
) => {

    const inputElement = useRef<any>(null)

    const lineCounter = (value: any) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as any;
        ctx.font = '14px Arial';
        const lineMaxWidth = Number(inputElement?.current?.getBoundingClientRect().width) - 14;
        const words: any = value.split(' ') as any;
        let lineCount = 0;
        let currentLineWidth = lineMaxWidth;
        words.map((word: string, index: number) => {
            let wordWidth = ctx.measureText((index === 0 && isEmpty(words[1])) || index === words.length - 1 || isEmpty(word) ? `${word}` : `${word} `).width;
            if (currentLineWidth <= wordWidth) {
                lineCount += 1;
                currentLineWidth = lineMaxWidth;
                const neededLines = wordWidth / lineMaxWidth;
                if (neededLines >= 1) {
                    lineCount += floor(neededLines);
                    wordWidth -= lineMaxWidth * floor(neededLines);
                    currentLineWidth -= wordWidth;
                } else {
                    currentLineWidth -= wordWidth;
                }
            } else {
                currentLineWidth -= wordWidth;
            }
        });
        if (isNumber(maxLineCount) && maxLineCount < lineCount + 1) {
            return maxLineCount - 1
        }
        return lineCount;
    };

    const getHeight = () => {
        const baseHeight = 34;
        const additionalLineHeight = 15;

        if (!resizeToContent) {
            return '100%';
        }
        const lineCount = lineCounter(value);

        if (isEmpty(value)) {
            return `${baseHeight}px`;
        }
        if (lineCount >= 1) {
            return `${baseHeight + additionalLineHeight * lineCount}px`;
        }
        return `${baseHeight}px`;
    };

    const onFocusChange = (event: any) => {

        event.preventDefault()
    }

    const onBlurChange = () => {
    }

    useEffect(() => {
        if (isAutoFocus) {
            inputElement.current?.focus()
        }
    }, [])

    return (
        <Styled.TextArea
            ref={inputElement}
            placeholder={placeholder}
            value={value}
            onFocus={onFocusChange}
            onBlur={onBlurChange}
            onChange={onChange}
            error={!!isError}
            onKeyPress={disableEnter ? (event: any) => {
                if (event.keyCode === 13 || event.which === 13) {
                    event.preventDefault();
                }
            } : () => false}
            style={{
                ...style,
                height: getHeight()
            }}
        />
    )
}

TextArea.defaultProps = defaultProps

export default TextArea