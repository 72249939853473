import {Styled} from "../BasicPickerForCountry.styles";
import {isMobileOnly} from "react-device-detect";
import TextInput from "../../../lib/UIBookBrd/TextInput";
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";

type HeaderProps = {
    openDropDown: boolean
    autoSuggestEnabled: boolean
    searchValue: any
    onSearchInputChangeHandler: any
    onSearchInputClearHandler: any
    setIsSelectOpen: any
}


const Header = ({
                    openDropDown,
                    autoSuggestEnabled,
                    searchValue,
                    onSearchInputChangeHandler,
                    onSearchInputClearHandler,
                    setIsSelectOpen,
                }: HeaderProps) => {
    const inputRef = useRef<any>(null)
    const {t} = useTranslation();
    return (
        <Styled.Header>
            {autoSuggestEnabled && (
                <Styled.SearchInputHolder
                    isMobileOnly={isMobileOnly}
                >
                    <TextInput
                        placeholder={t('destiantion_autosuggest_placeholder')}
                        value={searchValue}
                        ref={inputRef as any}
                        onChange={onSearchInputChangeHandler}
                        onClear={onSearchInputClearHandler}
                        isClearAble={true}
                    />
                </Styled.SearchInputHolder>
            )}
        </Styled.Header>
    )
}

export default Header