// @ts-ignore
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../../lib/UIBookBrd/Text";


const TypeOfSearchHolder = styled.div`
  transition: 500ms;
  cursor: pointer;
  display: flex;
  width: auto;
  height: auto;
  text-align: center;
  border-radius: 15px;
  border: 1px solid ${(props: { theme: any; isSelected: any }) => props.isSelected ? props.theme.borderColor : 'transparent'};
  color: ${(props: { theme: any; }) => props.theme.borderColor};;
  background-color: ${(props: { theme: any; isSelected: any }) => props.isSelected ? props.theme.typeOfSearchHover : 'transparent'};;
  padding: 2px;
  margin: 10px 3px;

  &:hover {
    background: ${(props: { theme: any; }) => props.theme.typeOfSearchHover};;
  }
`;
const FontAwesomeIcons = styled(FontAwesomeIcon)`
  font-size: 13px;
  transition: 500ms;
  margin-top: 4px;
  color: ${(props: { theme: any; }) => props.theme.borderColor};;
  padding: 5px 0 5px 5px;
`
const Type__Label = styled(Text)`
  margin: auto 0;
  font-size: 15px;
  font-weight: bold;
  padding: 5px;
`


export const Styled = {
    FontAwesomeIcons,
    TypeOfSearchHolder,
    Type__Label,
}