import {Styled} from "../ManageAccountPage.styles"
import BrdLoader from "../../../lib/UIBookBrd/BrdLoader";

type LoaderForAllViewsProps = {
    title: string
}

const LoaderForAllViews = (
    {
        title
    }: LoaderForAllViewsProps
) => {

    return (
        <Styled.Loader__Holder>
            <Styled.loader__Title>
                {title}
            </Styled.loader__Title>
            <BrdLoader/>
        </Styled.Loader__Holder>
    )
}

export default LoaderForAllViews