import {Styled} from "./OfferAlternatives.styles"
import {alternativesData} from "../../../../../../lib/Types";
import {isEmpty} from "lodash-es";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {useTranslation} from "react-i18next";
import {useRef, useState} from "react";
import {SwiperContainer, SwiperSlide} from "../../../../../../lib/UIBookBrd/Swiper";
import {isMobileOnly} from "react-device-detect";
import {NextButton, PrevButton} from "../../../../../../lib/UIBookBrd/Swiper/Buttons";
import Offer from "../../../../../../Components/Offers/VerticalTripOffer/Offer";
import SkeletonOffer from "../../../../../../Components/Offers/VerticalTripOffer/Offer/SkeletonOffer";


type OfferAlternativesProps = {
    data: alternativesData
    openOfferInSameTab?: boolean
}

const defaultSliderState = {
    progress: 0,
    index: 0,
}

const OfferAlternatives = (
    {
        data,
        openOfferInSameTab,
    }: OfferAlternativesProps) => {

    const {t} = useTranslation()

    const [sliderState, setSliderState] = useState(defaultSliderState);

    const swiperRef = useRef<any>(null);

    const onActiveIndexChange = (swiper: any) => {
        setSliderState((state) => ({
            ...state,
            progress: swiper.progress,
            index: swiper?.activeIndex,
        }));
    };

    const onPrevButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slidePrev();
    }

    const onNextButtonClick = (event: any) => {
        event.stopPropagation()
        swiperRef.current.swiper.slideNext();
    }

    if (data?.isLoading) {
        return (
            <Styled.Alternatives__Holder__Loading>
                {Array.from({length: 4}).map(() => {

                    return (
                        <SkeletonOffer/>
                    )
                })}
            </Styled.Alternatives__Holder__Loading>
        )
    }

    if (isEmpty(data?.offers)) {
        return (
            <FlexBox
                style={{
                    width: '100%',
                    height: '180px'
                }}
            >
                <Text
                    style={{
                        margin: 'auto'
                    }}
                >
                    {t('lbl_no_offers_found')}
                </Text>
            </FlexBox>
        )
    }

    return (
        <Styled.Alternatives>
            {sliderState.index !== 0 && (
                <PrevButton
                    onClick={onPrevButtonClick}
                />
            )}
            <SwiperContainer
                onActiveIndexChange={onActiveIndexChange}
                ref={swiperRef}
            >
                {data?.offers?.map((offerData: any, index: number) => {
                    return (
                        <SwiperSlide
                            key={`alternative_${index}`}
                            style={{
                                maxWidth: isMobileOnly ? 'calc(100% - 2px)' : '300px',
                                margin: isMobileOnly || index === 0 ? '0' : '10px',
                                padding: '1px',
                            }}
                        >
                            <Offer
                                openOfferInSameTab={openOfferInSameTab}
                                offerData={offerData?.Offer}
                            />
                        </SwiperSlide>
                    )
                })}
            </SwiperContainer>
            {sliderState.progress < 1 && (
                <NextButton
                    onClick={onNextButtonClick}
                />
            )}
        </Styled.Alternatives>
    )
}

export default OfferAlternatives