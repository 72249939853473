import {Styled} from "./TripOffer.styles"
import OfferTopLayer from "./OfferTopLayer";
import OfferBottomLayer from "./OfferBottomLayer";
import {isEmpty} from "lodash-es";
import {forwardRef, useEffect, useRef, useState} from "react";
import useElementResize from "../../../../../../lib/UseHooksBookBrd/useElementResize";
import useBookBrdPageFunctions from "../../../../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";

type TripOfferProps = {
    isLoadingImages?: boolean
    enableObserver?: boolean
    requestReRender?: () => void
    isDisabled?: boolean
    id?: any
    isOpen?: boolean
    openFullImages?: () => void
    selectedImage: number
    pictures: string[]
    navigateImagesHandler?: (type: 'previous' | 'next', index: number) => void
    onOpenOffer?: (index: number, offerId: string) => void
    onCloseOffer: (index: number, offerId: string) => void
    index?: number
    isExlusive?: boolean
    SimilarOffersHandler?: (value: any) => void
    compareOffersHandler?: (value: any) => void
    onFavouriteChangeHandler?: (value: string, index: number) => void
    isFavourite?: boolean
    offer: any
}

const TripOffer = forwardRef<HTMLDivElement, TripOfferProps>(
    ({
         isLoadingImages,
         onFavouriteChangeHandler,
         enableObserver,
         requestReRender,
         isDisabled,
         id,
         openFullImages,
         selectedImage,
         pictures,
         navigateImagesHandler,
         onOpenOffer,
         onCloseOffer,
         isOpen,
         index,
         isExlusive,
         SimilarOffersHandler,
         compareOffersHandler,
         isFavourite,
         offer,
     }, ref) => {

        const containerRef = useRef<any>(null)

        const [elementHeight, setElementHeight] = useState(containerRef?.current?.clientHeight ?? 0)

        const {heightObserver} = useElementResize()

        const {
            createLinkForThirdStep,
            showOfferHandler
        } = useBookBrdPageFunctions()

        const onOfferClickHandler = () => {
            if (!isOpen && onOpenOffer) {
                onOpenOffer(index as number, offer.Base.OfferId)
            }
        }

        const onOfferCloseHandler = () => {
            onCloseOffer(index as number, offer.Base.OfferId)
        }

        const onSeeOfferButtonHandler = () => {
            showOfferHandler(offer)
        }

        const seeSimilarOfferHandler = () => {
            if (SimilarOffersHandler) {
                SimilarOffersHandler(
                    {
                        offerId: offer.Base.OfferId,
                        XCode: offer?.Base?.XCode?.Id
                    }
                )
            }
        }

        const addToCompareHandler = () => {
            if (compareOffersHandler) {
                compareOffersHandler({
                        v5Data: {
                            HotelXCode: offer?.Base?.XCode?.Id,
                            Operator: offer.Base.Operator,
                            offerId: offer.Base.OfferId,
                            HotelCode: `${offer.Accommodation.Code}`
                        },
                        frontData: {
                            firstImage: !isEmpty(pictures) ? pictures[0] : '',
                            name: offer.Base.XCode.Name
                        }
                    }
                )
            }
        }

        const onImagesChangeHandler = (type: 'previous' | 'next') => {
            if (navigateImagesHandler) {
                navigateImagesHandler(type, index as number)
            }
        }

        const onFavouriteClickHandler = () => {
            if (onFavouriteChangeHandler) {
                onFavouriteChangeHandler(offer.Base.OfferId as string ?? '', Number(index))
            }
        }

        useEffect(() => {
            if (enableObserver) {
                heightObserver(containerRef, elementHeight, setElementHeight, requestReRender)
            }
        }, [elementHeight]);

        return (
            <Styled.TripOffer
                isDisabled={!!isDisabled as boolean}
                ref={ref}
                id={id}
                isExlusive={!!isExlusive as boolean}
                isOfferFocused={isOpen as boolean}
                onClick={onOfferClickHandler}
            >
                <Styled.SEO__TRIP__LINK
                    href={createLinkForThirdStep({
                        offerId: offer.Base.OfferId,
                        name: offer.Base.XCode.Name
                    })}
                >
                    link to offer ${offer.Base.XCode.Name}
                </Styled.SEO__TRIP__LINK>
                <Styled.TripOfferHolder>
                    <OfferTopLayer
                        isLoadingImages={isLoadingImages}
                        isFavourite={isFavourite}
                        onFavouriteClickHandler={onFavouriteClickHandler}
                        openFullImages={openFullImages as any}
                        selectedImage={selectedImage}
                        navigateImagesHandler={onImagesChangeHandler}
                        isExlusive={isExlusive}
                        pictures={pictures}
                        offerData={offer}
                        isOfferFocused={isOpen as boolean}
                        onOfferCloseHandler={onOfferCloseHandler}
                    />
                    <OfferBottomLayer
                        SimilarOffersHandler={seeSimilarOfferHandler}
                        addToCompareHandler={addToCompareHandler}
                        onSeeOfferButtonHandler={onSeeOfferButtonHandler}
                        isOfferFocused={isOpen as boolean}
                        offerData={offer}

                    />
                </Styled.TripOfferHolder>
            </Styled.TripOffer>
        )
    })

export default TripOffer