import {Styled} from "./FindMainContent.styles"
import {useTranslation} from "react-i18next";
import TextInput from "../../../lib/UIBookBrd/TextInput";
import {FindOrderFieldsType, OrderPageTypeEnum} from "../../../lib/Types";
import useConfirmationPage from "../useConfirmationPage";
import React, {useState} from "react";
import {includes, isEmpty, isString} from "lodash-es";


type FindMainContentProps = {
    type: OrderPageTypeEnum
}

const FindMainContent = (
    {
        type
    }: FindMainContentProps
) => {

    const [findOrderData, setFindOrderData] = useState<any>({orderCode: '', payerName: '', errors: []})

    const {onConfirmButton} = useConfirmationPage()

    const {t} = useTranslation()

    const onFieldChange = (event: any, fieldName: FindOrderFieldsType) => {

        const value = isString(event) ? event : event.target.value

        if (fieldName === FindOrderFieldsType.orderCode) {
            if (/^\d+$/.test(value) && value.length <= 14 || isEmpty(value)) {
                setFindOrderData((state: any) => ({
                    ...state,
                    [fieldName]: value,
                    errors: state.errors.filter((item: string) => item !== fieldName)
                }))
            }
        } else {
            setFindOrderData((state: any) => ({
                ...state,
                [fieldName]: value,
                errors: state.errors.filter((item: string) => item !== fieldName)
            }))
        }

    }

    return (
        <Styled.Find__Main__Content__Holder>
            <Styled.Find__Your__Offer__Title>
                {t('lbl_find_your_order')}
            </Styled.Find__Your__Offer__Title>
            {/*ORDER CODE*/}
            <Styled.Input__Holder>
                <Styled.Input__Title>
                    {t('lbl_order_code')}
                </Styled.Input__Title>
                <TextInput
                    showIcon={false}
                    isClearAble={true}
                    placeholder={'12345622102023'}
                    error={includes(findOrderData.errors, FindOrderFieldsType.orderCode)}
                    onChange={(event: any) => {
                        onFieldChange(event, FindOrderFieldsType.orderCode)
                    }}
                    onClear={() => {
                        onFieldChange('', FindOrderFieldsType.orderCode)
                    }}
                    value={{
                        label: findOrderData.orderCode,
                        value: findOrderData.orderCode,
                    }}
                />
            </Styled.Input__Holder>
            {/*ORDER PAYER SURNAME*/}
            <Styled.Input__Holder>
                <Styled.Input__Title>
                    {t('lbl_payer_surname')}
                </Styled.Input__Title>
                <TextInput
                    showIcon={false}
                    isClearAble={true}
                    placeholder={'surname'}
                    autoComplete={'family-name'}
                    error={includes(findOrderData.errors, FindOrderFieldsType.payerName)}
                    onChange={(event: any) => {
                        onFieldChange(event, FindOrderFieldsType.payerName)
                    }}
                    onClear={() => {
                        onFieldChange('', FindOrderFieldsType.payerName)
                    }}
                    value={{
                        label: findOrderData.payerName,
                        value: findOrderData.payerName,
                    }}
                />
            </Styled.Input__Holder>
            {/*ORDER CONFIRMATION BUTTON*/}
            <Styled.Confirm__Button
                label={t('lbl_search')}
                onClick={() => onConfirmButton(findOrderData, setFindOrderData)}
            />
            {/*ORDER FIND DESCRIPTION*/}
            <Styled.Order__Find__Description>
                {t('lbl_find_order_desc')}
            </Styled.Order__Find__Description>
        </Styled.Find__Main__Content__Holder>
    )
}
export default FindMainContent