import {includes, isEmpty, isEqual} from "lodash-es";
import {promiseRequest} from "../../lib/RequestBuilder";
import {CONTENT_SERVICE_URL} from "../../lib/Configs/RequestConfig";
import {XCITY_FIELD, XCODE_FIELD} from "../../lib/DefaultFormikValue/DefaultSearchFormValues";
import {useTranslation} from "react-i18next";

const useAutoSuggest = (selectedLanguage: string) => {

    const {t} = useTranslation();

    const sendRequest = async (formikValue: any, searchFormValue: any, setOffersWithLabel: any) => {
        let fieldsToSend: any = {}
        const offersWithNoLabels: any[] = []
        const newOffersWithLabels: any[] = []
        const countryList: any[] = []
        const regionList: any[] = []
        formikValue?.map((id: string) => id.includes('_') ? (regionList.push(id), offersWithNoLabels.push({
            label: t('lbl_loading'),
            type: 'regions',
            value: id
        })) : (countryList.push(id), offersWithNoLabels.push({
            label: t('lbl_loading'),
            type: 'countries',
            value: id
        })))
        if (!isEmpty(searchFormValue[XCODE_FIELD])) {
            searchFormValue[XCODE_FIELD]?.map((xCode: number) => offersWithNoLabels.push({
                label: t('lbl_loading'),
                type: 'hotels',
                value: `${xCode}`
            }))
            fieldsToSend.HotelsByCode = searchFormValue[XCODE_FIELD]
        }
        if (!isEmpty(searchFormValue[XCITY_FIELD])) {
            offersWithNoLabels.push({
                label: t('lbl_loading'),
                type: 'cities',
                value: searchFormValue[XCITY_FIELD]
            })
            fieldsToSend.CitiesByCode = [searchFormValue[XCITY_FIELD]]
        }
        if (!isEmpty(regionList)) {
            fieldsToSend.RegionsByCode = regionList
        }
        if (!isEmpty(countryList)) {
            fieldsToSend.CountriesByCode = countryList
        }

        const request = await promiseRequest(`${CONTENT_SERVICE_URL}Autosuggest`, {
            Sections: [
                'Autosuggest',
            ],
            ExternalDataSource: 'MDS',
            Query: '#BYID#',
            Language: selectedLanguage,
            AutosuggestOptions: {
                NamesOnly: true,
                ...fieldsToSend
            },

        }, 2);

        const list = request?.Sections?.Autosuggest?.items
        Object.keys(list).map((type: string) => {
            const sectionList = list[type]
            sectionList?.map((item: any) =>
                newOffersWithLabels.push({label: item.Name, value: item.code, type})
            )
        })
        setOffersWithLabel((state: any) => ({
            ...state,
            language: selectedLanguage,
            list: newOffersWithLabels
        }))
    }

    const getNamesForValues = (
        offersWithLabels: any,
        setOffersWithLabel: any,
        formikValue: any,
        searchFormValue: any,
        allList: any,
    ) => {
        const allListWithLabels = offersWithLabels?.list?.map((item: any) => item.value)
        const boolList = allList?.map((code: string) => {
            if (includes(allListWithLabels, code)) {
                return code
            }
        })
        if (!isEmpty(allList)) {
            if (!isEqual(allList, boolList) || !isEqual(offersWithLabels?.language, selectedLanguage)) {
                sendRequest(
                    formikValue,
                    searchFormValue,
                    setOffersWithLabel,
                )
            }
        } else if (isEmpty(offersWithLabels?.language) || offersWithLabels?.language !== selectedLanguage) {
            setOffersWithLabel((state: any) => ({
                ...state,
                language: selectedLanguage
            }))
        }
    }

    const getNamesForLastSearchBoxes = async (labelsList: any, setState: any) => {
        const countriesList: any[] = []
        const regionsList: any[] = []
        const citiesList: any[] = []
        const hotelsList: any[] = []
        let fieldToSend: any = {}
        const newOffersWithLabels: any[] = []
        labelsList?.map((item: any) => {
            const {type, value} = item
            switch (type) {
                case 'countries':
                    countriesList.push(value)
                    break
                case 'regions':
                    regionsList.push(value)
                    break
                case 'cities':
                    citiesList.push(value)
                    break
                case 'hotels':
                    hotelsList.push(value)
                    break
            }
        })

        if (!isEmpty(countriesList)) {
            fieldToSend.CountriesByCode = countriesList
        }
        if (!isEmpty(regionsList)) {
            fieldToSend.RegionsByCode = regionsList
        }
        if (!isEmpty(hotelsList)) {
            fieldToSend.HotelsByCode = hotelsList
        }
        if (!isEmpty(citiesList)) {
            fieldToSend.CitiesByCode = citiesList
        }

        const request = await promiseRequest(`${CONTENT_SERVICE_URL}`, {
            Sections: [
                'Autosuggest',
            ],
            ExternalDataSource: 'MDS',
            Query: '#BYID#',
            Language: selectedLanguage,
            AutosuggestOptions: {
                NamesOnly: true,
                ...fieldToSend
            },

        }, 2);
        const list = request?.Sections?.Autosuggest?.items
        Object.keys(list).map((type: string) => {
            const sectionList = list[type]
            sectionList?.map((item: any) =>
                newOffersWithLabels.push({label: item.Name, value: item.code, type})
            )
        })
        setState((state: any) => ({
            ...state,
            language: selectedLanguage,
            list: newOffersWithLabels
        }))
    }

    return ({
        getNamesForValues,
        getNamesForLastSearchBoxes,
    })
}

export default useAutoSuggest