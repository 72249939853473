import {useContext} from "react";
import {Similar_Offers, Trip_Offers, Trip_Result_Page} from "../../../lib/Context/Context";
import {isEmpty} from "lodash-es";
import {promiseRequest} from "../../../lib/RequestBuilder";
import {CONTENT_SERVICE_URL, V5_DETAILS} from "../../../lib/Configs/RequestConfig";
import {GET_ACCOMMODATION_DETAILS, GET_TRANSPORT_DETAILS} from "./TripSearchFunctions";
import {useTranslation} from "react-i18next";
import {comparedOffersStorage} from "./Consts";
import useV5OfferListRequest from "../../../lib/UseHooksBookBrd/useV5OfferListRequest";
import useFilterFormValue from "../../../lib/UseHooksBookBrd/useFilterFormValue";
import useSearchFormValues from "../../../lib/UseHooksBookBrd/useSearchFormValues";

const useTripResultsValues = () => {

    const [similarState, setSimilarState] = useContext<any>(Similar_Offers)

    const [resultsState, setResultsState] = useContext<any>(Trip_Result_Page)

    const [optionList, setOptionList] = useContext<any>(Trip_Offers)

    const {
        filterFormValue,
    } = useFilterFormValue()

    const {
        searchFormValue,
    } = useSearchFormValues()

    const {SendRequestForOfferList} = useV5OfferListRequest(searchFormValue, filterFormValue)

    const {t} = useTranslation()

    const showFiltersHandler = (bool: boolean) => {
        setResultsState((state: any) => ({
            ...state,
            filters: {
                ...state?.filters,
                isOpen: bool
            }
        }))
    }

    const getOverViewData = (offer: any, t: any) => {
        const {Base, Accommodation, Transport} = offer

        return [
            {
                type: t('lbl_trip_dates'),
                value: `${Base.StartDate.split('-').reverse().join('.')} - ${Base.ReturnDate.split('-').reverse().join('.')}`
            },
            {
                type: t('lbl_location'),
                value: `${Base.DestinationLocation.Name} `
            },
            {
                type: t('lbl_room_type'),
                value: `${Accommodation.Room.Name}`
            },
            {
                type: t('lbl_board'),
                value: `${Accommodation.Service.Name}`
            },
            {
                type: t('lbl_transport_type'),
                value: `${!isEmpty(Transport) ? t(`lbl_${Object.keys(Transport)[0].toLowerCase()}`) : t('lbl_own_transport')}`
            },
            {
                type: t('lbl_operator'),
                value: `${Base.OperatorDesc}`
            },
        ]
    }

    const fetchValuesForCompareOffers = async () => {
        try {
            setResultsState((state: any) => ({
                ...state,
                compareOffers: {
                    ...state?.compareOffers,
                    isError: false,
                    isLoading: true,
                    requestSent: true
                }

            }))

            let offerComparisonSection = resultsState?.compareOffers?.offerComparison ?? []
            let offerComparisonOfferV5 = resultsState?.offerComparisonOfferV5 ?? []
            let offerOverViewSection = resultsState?.compareOffers?.overView ?? []
            let offerDetailsAccommodation = resultsState?.compareOffers?.offerDetailsAccomodation ?? []
            let offerDetailsTransport = resultsState?.compareOffers?.offerDetailsTransport ?? []
            for (let offerIndex = 0; offerIndex < resultsState?.compareOffers?.comparedOffers?.length; offerIndex++) {
                if (!isEmpty(resultsState?.compareOffers?.comparedOffers[offerIndex]?.v5Data)) {
                    const wcsRequest = await promiseRequest(`${CONTENT_SERVICE_URL}Pictures`, {
                        ...resultsState?.compareOffers?.comparedOffers[offerIndex]?.v5Data,
                        Sections: [
                            'Pictures',
                        ],
                        PictureSizes: "full",
                        Language: 'PL',
                    });
                    const v5DataRequest = await promiseRequest(`${V5_DETAILS}${resultsState?.compareOffers?.comparedOffers[offerIndex].v5Data.offerId}`);
                    const {result} = v5DataRequest
                    const {offer} = result
                    const {Accommodation, Base} = offer
                    const {Sections} = wcsRequest
                    const {Pictures} = Sections
                    const {PictureUrlFull} = Pictures
                    offerComparisonOfferV5[offerIndex] = offer
                    offerComparisonSection[offerIndex] = {
                        PictureUrlFull: PictureUrlFull ?? [],
                        ThumbUrl: offer?.Base?.ThumbUrl,
                        rating: Accommodation.Category,
                        price: Base.Price,
                        name: Accommodation.Name
                    }
                    offerOverViewSection[offerIndex] = getOverViewData(offer, t)
                    offerDetailsAccommodation[offerIndex] = GET_ACCOMMODATION_DETAILS(offer, t)
                    offerDetailsTransport[offerIndex] = GET_TRANSPORT_DETAILS(offer, t)
                } else {
                    offerOverViewSection[offerIndex] = []
                    offerDetailsAccommodation[offerIndex] = []
                    offerDetailsTransport[offerIndex] = []
                }
            }
            setResultsState((state: any) => ({
                ...state,
                compareOffers: {
                    ...state?.compareOffers,
                    data: {
                        offers: offerComparisonOfferV5,
                        offerComparison: offerComparisonSection,
                        overView: offerOverViewSection,
                        transportDetails: offerDetailsTransport,
                        accommodationDetails: offerDetailsAccommodation
                    }
                }
            }))
        } catch (e) {
            setResultsState((state: any) => ({
                ...state,
                compareOffers: {
                    ...state?.compareOffers,
                    isLoading: false,
                    requestSent: false,
                    isError: true
                }
            }))
        } finally {
            setResultsState((state: any) => ({
                ...state,
                compareOffers: {
                    ...state?.compareOffers,
                    isLoading: false,
                    requestSent: false,
                }
            }))
        }
    }

    const showCompareHandler = () => {
        setResultsState((state: any) => ({
            ...state,
            compareOffers: {
                ...state?.compareOffers,
                isOpen: true

            }
        }))
        fetchValuesForCompareOffers()
    }

    const onCompareModalApproveButton = (newComparedOffers: any) => {
        sessionStorage.setItem(comparedOffersStorage, JSON.stringify(newComparedOffers))
        setResultsState((state: any) => ({
            ...state,
            compareOffers: {
                ...state.compareOffers,
                isModalOpen: false,
                comparedOffers: newComparedOffers
            }
        }))
    }

    const addToCompareOffers = (value: any) => {
        setResultsState((state: any) => ({
            ...state,
            compareOffers: {
                ...state.compareOffers,
                isModalOpen: true,
                newPotentialValue: value
            }
        }))
    }

    const toggleCompareModal = (bool: boolean) => {
        setResultsState((state: any) => ({
            ...state,
            compareOffers: {
                ...state.compareOffers,
                isModalOpen: bool
            }
        }))
    }

    const toggleCompareDrawer = (bool: boolean) => {
        setResultsState((state: any) => ({
            ...state,
            compareOffers: {
                ...state.compareOffers,
                isOpen: bool
            }
        }))
    }

    const operatorInnerSizes = (newHeight: number, newWidth: number) => {

        setResultsState((state: any) => ({
            ...state,
            innerHeight: newHeight,
            innerWidth: newWidth
        }))
    }

    const toggleIsLoadingPage = (bool: boolean) => {
        setResultsState((state: any) => ({
            ...state,
            isLoading: bool
        }))
    }

    const setImagesControl = (value: any) => {

        setResultsState((state: any) => ({
            ...state,
            tripList: {
                ...state.tripList,
                imagesControl: value
            }
        }))
    }

    const setOpenedList = (value: any) => {
        setResultsState((state: any) => ({
            ...state,
            tripList: {
                ...state.tripList,
                openedList: value
            }
        }))
    }

    const toggleIsLoadingMore = (bool: boolean) => {
        setResultsState((state: any) => ({
            ...state,
            tripList: {
                ...state.tripList,
                isLoadingMore: bool
            }
        }))
    }

    const toggleIsLoadingNew = (bool: boolean) => {
        setResultsState((state: any) => ({
            ...state,
            tripList: {
                ...state.tripList,
                isLoadingNew: bool
            }
        }))
    }

    const handleForceRefreshList = () => {
        SendRequestForOfferList(toggleIsLoadingNew, setOptionList, "OfferList", undefined, 'OfferList', undefined)
    }

    return ({
        setOpenedList,
        optionList,
        setOptionList,
        similarState,
        setSimilarState,
        toggleIsLoadingMore,
        handleForceRefreshList,
        setImagesControl,
        toggleIsLoadingPage,
        operatorInnerSizes,
        addToCompareOffers,
        fetchValuesForCompareOffers,
        state: resultsState,
        toggleCompareModal,
        toggleIsLoadingNew,
        toggleCompareDrawer,
        showCompareHandler,
        onCompareModalApproveButton,
        showFiltersHandler
    })
}

export default useTripResultsValues