import SliderBookBrd from "../../lib/UIBookBrd/Slider";
import {useState} from "react";
import {useDebouncedCallback} from "use-debounce";
import {PRICE_FIELD} from "../../lib/DefaultFormikValue/DefaultSearchFormValues";
import usePricesHandler from "../../lib/UseHooksBookBrd/usePricesHandler";
import {Styled} from "./PriceSlider.styles";
import {useTranslation} from "react-i18next";
import Text from "../../lib/UIBookBrd/Text";
import FlexBox from "../../lib/UIBookBrd/FlexBox";

type PriceSliderProps = {
    filterFormValue: any
    setFilterValue: (name: string | string[], values: string | any) => void
}

const PriceSlider = (
    {
        filterFormValue,
        setFilterValue
    }: PriceSliderProps
) => {

    const [value, setValue] = useState<[number, number]>(filterFormValue ?? [0, 10000])

    const {getCurrentPrice, currency} = usePricesHandler()

    const {t} = useTranslation();

    const setToFormikValue = useDebouncedCallback((value: any) => {
        setFilterValue(PRICE_FIELD, value)
    }, 200)

    const getToAmountLabel = (amount: any) => {
        if (amount === 10000) {
            return t('lbl_unlimited')
        }
        return `${t('lbl_to')}: ${getCurrentPrice(value[1], 'PLN').amount} ${currency ?? 'PLN'}`
    }

    return (
        <Styled.PriceSlider__Holder>
            <FlexBox
                style={{
                    width: 'calc(100% - 20px)',
                    margin: '0 8px'
                }}
            >
                <SliderBookBrd
                    value={value}
                    min={0}
                    max={10000}
                    minDistance={0}
                    step={1000}
                    setValue={(value: any) => {
                        setValue(value)
                        setToFormikValue(value)
                    }}
                />
            </FlexBox>
            <Styled.Price__Slider__Prices__Holder>
                <Text>
                    {`${t('lbl_from')}: ${getCurrentPrice(value[0], 'PLN').amount} ${currency ?? 'PLN'}`}
                </Text>
                <Text
                    style={{
                        marginLeft: 'auto'
                    }}
                >
                    {getToAmountLabel(value[1])}
                </Text>
            </Styled.Price__Slider__Prices__Holder>
        </Styled.PriceSlider__Holder>
    )
}
export default PriceSlider