import styled from "styled-components";
import FlexBox from "../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Rating__Values__Holder = styled(FlexBox)`
  padding: 0 10px;
  width: 380px;
  min-width: 380px;
  flex-grow: 1;
  flex-direction: initial;
  flex-wrap: nowrap;


  @media (max-width: 815px) {
    width: calc(100% - 20px);
    min-width: calc(100% - 20px);
  }

`
const Rating__Box = styled(FlexBox)`
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`

const Rating__Line__Number__Holder = styled(FlexBox)`
  padding: 2px 0;
  flex-wrap: nowrap;
`
const Rating__Number = styled(Text)`
  width: 21px;
  font-weight: bold;
  text-align: center;
  margin-right: 5px;
`
const Rating__Line__Holder = styled(FlexBox)`
  flex-grow: 1;
  margin: auto 0;
`
const Rating__Line = styled(FlexBox)<{ width: number }>`
  height: 6px;
  width: ${({width}) => width}%;
  background-color: ${({theme}) => theme.brdExclusiveColor};
`
const Missing__Rating__Line = styled(FlexBox)<{ width: number }>`
  height: 6px;
  width: ${({width}) => width}%;
  background-color: ${({theme}) => theme.missingRatingColor};
`
const Rating__Lines___Holder = styled(FlexBox)`
  flex-direction: column;
  flex-grow: 1;
`
const Review__Type__Label = styled(Text)`
  padding: 2px 0;
`
const Rating__Names__Holder = styled(FlexBox)`
  flex-direction: column;
  margin-right: 10px;
`
const About__Page__Holder = styled(FlexBox)`
  flex-direction: column;

`
const Desc__Review__Holder = styled(FlexBox)`

  flex-direction: initial;
  flex-wrap: nowrap;

  @media (max-width: 815px) {
    flex-wrap: wrap;
  }
`

const Summary__Number = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: auto;
`
const Summary__Number__Value = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`
const Rating__Holder = styled(FlexBox)`
  flex-direction: column;
  max-width: 400px;
  @media (max-width: 815px) {
    width: 100%;
    max-width: 100%;
  }
`
const Rating__Star = styled(FontAwesomeIcon)`
  height: 18px;
  width: 18px;
  margin-top: 1px;
  margin-left: 2px;
  margin-right: 10px;
  color: ${({theme}) => theme.brdExclusiveColor};
`
const Summary__Rating__Holder = styled(FlexBox)`
  padding-top: 5px;
`
const Description = styled(Text)`

`
const Part__Title = styled(Text)`
  font-size: 22px;
  padding: 10px 0;
  font-weight: bold;
`

const General__Important__Information = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: wrap;
  align-content: space-between;
  gap: 10px;
  padding: 10px;
`
const General__Value__Holder = styled(Text)`

`

export const Styled = {
    General__Value__Holder,
    General__Important__Information,
    Desc__Review__Holder,
    Part__Title,
    Description,
    Summary__Rating__Holder,
    Rating__Star,
    Rating__Holder,
    Summary__Number__Value,
    Summary__Number,
    About__Page__Holder,
    Review__Type__Label,
    Rating__Names__Holder,
    Rating__Lines___Holder,
    Rating__Line__Number__Holder,
    Rating__Number,
    Rating__Line__Holder,
    Rating__Line,
    Missing__Rating__Line,
    Rating__Values__Holder,
    Rating__Box
}