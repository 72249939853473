import moment from "moment/moment";
import {Local_Settings_Values} from "../PageConfig";
import {useDebouncedCallback} from "use-debounce";
import {getPreferences} from "../Routes";
import useStorage from "./useStorage";

const useOnSiteOpen = () => {

    const [localSettings, setLocalSettings] = useStorage<any>('localStorage', 'LocalSettings', Local_Settings_Values)

    const userData = getPreferences(localSettings)

    const onSiteOpen = () => {
        if (localSettings.isFirstLogin) {
            moment.locale(userData?.SelectedLanguage)
            setLocalSettings({...userData, Cookies: undefined})
        } else if (sessionStorage?.getItem('userLocationPermitted') !== 'true') {
            moment.locale(localSettings?.SelectedLanguage)
            if (userData?.PreferredCountry !== localSettings?.PreferredCountry) {
                sessionStorage.setItem('userLocationPermitted', 'false')
            } else {
                sessionStorage.setItem('userLocationPermitted', 'true')
            }
        }
    }

    const setWindowPosition = useDebouncedCallback((value: any) => {
        sessionStorage.setItem('lastPosition', JSON.stringify(value))
    }, 200)

    const refactorTokenUrl = () => {
        const isToken = window?.location?.pathname.includes('token')
        const pathname = window?.location?.pathname
        if (isToken) {
            sessionStorage.setItem('token', pathname.slice(7,))
            window.location.replace(`${window.location.protocol}//${window.location.host}/en/Trips`)
        }
    }

    const onWindowScrollHandler = () => {
        window?.addEventListener('scroll', () => {
            setWindowPosition(window?.pageYOffset)
        })
        return () => {
            window?.removeEventListener('scroll', () => {
                setWindowPosition(window?.pageYOffset)
            })
        }
    }

    return {
        onWindowScrollHandler,
        refactorTokenUrl,
        onSiteOpen
    }

}
export default useOnSiteOpen