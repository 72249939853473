import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import {Styled} from "../../TripSearchResultPage.styles";
import {useTranslation} from "react-i18next";
import useTripResultsValues from "../../useTripResultsValues";

type TopButtonsProps = {}

const TopButtons = (
    {}: TopButtonsProps
) => {

    const {
        state,
        showCompareHandler,
        showFiltersHandler
    } = useTripResultsValues()

    const {t} = useTranslation();

    const showFilterLabel = t('lbl_show_filters')

    const hideFilterLabel = t('lbl_hide_filters')

    return (
        <FlexBox>
            <Styled.HorizontalButton
                onClick={showCompareHandler}
                label={t('lbl_show_compare_offers')}
            />
            <Styled.HorizontalButton
                onClick={() => {
                    showFiltersHandler(true)
                }}
                label={state?.filters?.isOpen ? hideFilterLabel : showFilterLabel}
            />
        </FlexBox>
    )
}
export default TopButtons