import {isMobileOnly} from "react-device-detect";
import Drawer from "../../lib/UIBookBrd/Drawer";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Modal from "../../lib/UIBookBrd/Modal/ModalsTypes/Modal";
import React, {useEffect, useRef, useState} from "react";
import Button from "../../lib/UIBookBrd/Button";
import TextInput from "../../lib/UIBookBrd/TextInput";
import usePopperPositioning from "../../lib/UseHooksBookBrd/usePopperPositioning";
import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";
import {Styled} from "./LenghtBasicPicker.styles";
import LengthElement from "./LengthElement";
import {toNumber} from "lodash-es";
import {useTranslation} from "react-i18next";
import Select from "../../lib/UIBookBrd/SelectBookBrd/Select";

type LenghtBasicPickerProps = {
    title: string
    appendTo?: Element | null
    setIsModalOpen?: any
    fieldPlaceholder: string
    selectPlaceholder?: string
    selectOptions?: any[]
    hasSelect?: boolean
    value: any
    setValue: any
    onApply: (value: { min: string, max: string }) => void
}
const defaultProps = {
    appendTo: null,
    setIsModalOpen: undefined,
    hasSelect: false,
    selectOptions: [],
    selectPlaceholder: ''
}


const LenghtBasicPicker = (
    {
        title,
        appendTo,
        setIsModalOpen,
        value,
        setValue,
        fieldPlaceholder,
        selectPlaceholder,
        selectOptions,
        hasSelect,
        onApply,
    }: LenghtBasicPickerProps) => {

    const {t} = useTranslation();

    const [isSelectOpen, setIsSelectOpen] = useState(false)

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const inputElement = useRef<HTMLDivElement>();

    const {styles, attributes} = usePopperPositioning({
        targetElementRef: inputElement,
        popperElementRef: modalElement,
        applyMaxSizeEnabled: true,
        zIndex: 9999,
        padding: 15,
    });

    const constructInputValue = () => {
        if (toNumber(value.min) !== 0 && toNumber(value.max) !== 0 && value.min !== value.max) {
            return `${t('lbl_from')} ${value.min} ${t('lbl_to')} ${value.max} ${t('lbl_days').toLowerCase()}`
        }
        if (toNumber(value.min) !== 0 && toNumber(value.max) !== 0 && value.min === value.max) {
            return `${t('lbl_for')} ${value.min} ${t('lbl_days').toLowerCase()}`
        }
        if (toNumber(value.min) === 0 && toNumber(value.max) === 0) {
            return ''
        }
    }

    const onFieldClick = () => {
        if (isOpen) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }

    const onClear = () => {
        setValue({
            min: '0',
            max: '0'
        })
    }

    const onClose = () => {
        setIsOpen(false)
        onApply(value)
    }

    const onClickHandler = (newNumber: string | number) => {
        if (toNumber(value.min) === 0 && toNumber(value.max) === 0) {
            setValue({
                    min: newNumber,
                    max: newNumber
                }
            )
        }
        if (toNumber(value.min) !== 0 && toNumber(value.max) !== 0) {
            setValue({
                    min: newNumber,
                    max: newNumber
                }
            )
        }
        if (toNumber(value.min) !== 0 && value.min === value.max) {
            if (toNumber(newNumber) < toNumber(value.min)) {
                setValue({
                    min: newNumber,
                    max: value.min,
                })
            } else {
                setValue({
                    min: value.min,
                    max: newNumber
                })
            }

        }
    }

    const selectChangeHandler = (value: any) => {
        setValue(value.value)

    }

    useOnClickOutside(modalElement, () => isSelectOpen ? undefined : onClose());

    useEffect(() => {
        if (setIsModalOpen) {
            setIsModalOpen(isOpen)
        }
    }, [isOpen])

    const controls = (
        <FlexBox
            style={{
                flexGrow: isMobileOnly ? '1' : undefined,
                flexDirection: isMobileOnly ? 'column' : undefined,
                marginLeft: isMobileOnly ? "4px" : 'auto',
                marginRight: isMobileOnly ? "4px" : '10px',
                marginTop: '2px',
                marginBottom: '2px'
            }}
        >
            <Button
                label={t('lbl_clear')}
                onClick={onClear}
                style={{
                    flexGrow: "1",
                    minWidth: '100px',
                    margin: isMobileOnly ? '2px 0' : " 2px 2px ",
                    padding: "2px 0",
                    maxWidth: isMobileOnly ? undefined : "150px"

                }}
            />
            <Button
                label={t('lbl_apply')}
                onClick={onClose}
                style={{
                    flexGrow: "1",
                    minWidth: '100px',
                    margin: isMobileOnly ? '2px 0' : " 2px 2px ",
                    padding: "2px 0",
                    maxWidth: isMobileOnly ? undefined : "150px"

                }}
            />


        </FlexBox>
    )
    const content = (
        <>
            {hasSelect && (
                <Styled.SelectHolder>
                    <Select
                        title={t('lbl_duration_combination')}
                        appliedList={selectOptions ? selectOptions.filter((item: any) => JSON.stringify(item.value) === JSON.stringify(value)) : []}
                        setAppliedList={selectChangeHandler}
                        setIsSelectOpen={setIsSelectOpen}
                        placeholder={selectPlaceholder}
                        options={selectOptions}
                        isMulti={false}
                        isClearAble={false}
                    />
                </Styled.SelectHolder>

            )}
            <Styled.LengthBasicPicker>
                <Styled.CustomLabelText>
                    {`${t('lbl_choose_duration_manually')}:`}
                </Styled.CustomLabelText>
                <Styled.RangePickerElementsHolder>
                    {Array.from({length: 35}).map((item: any, index: number) => {
                        const number = (1 + index).toString()
                        return (
                            <LengthElement
                                key={`lenght_basic_${index}`}
                                label={number}
                                value={toNumber(number)}
                                onClick={onClickHandler}
                                isSelected={toNumber(number) === value.min || toNumber(number) === value.max}
                                isIncluded={toNumber(number) < toNumber(value.max) && toNumber(number) > toNumber(value.min)}
                            />
                        )

                    })}

                </Styled.RangePickerElementsHolder>
            </Styled.LengthBasicPicker>
        </>
    )

    return (
        <>
            <TextInput
                placeholder={fieldPlaceholder}
                showIcon={false}
                isClearAble={false}
                ref={inputElement as any}
                onClick={onFieldClick}
                readOnly={true}
                value={{label: constructInputValue(), value: constructInputValue()}}
            />
            {isMobileOnly ? (
                    <>
                        <Drawer
                            appendTo={appendTo}
                            ref={setModalElement}
                            title={title}
                            position={'bottom'}
                            isOpen={isOpen}
                            onClose={onClose}
                            controls={controls}
                        >
                            {content}
                        </Drawer>
                    </>
                ) :
                (
                    <>
                        <Modal
                            appendTo={appendTo}
                            ref={setModalElement}
                            showCloseIcon={false}
                            width={400}
                            showOverlay={false}
                            isOpen={isOpen}
                            positionedByPopper
                            style={styles.popper}
                            {...attributes.popper}
                            controls={controls}
                        >
                            {content}
                        </Modal>
                    </>
                )}


        </>
    )

}


LenghtBasicPicker.defaultProps = defaultProps;
export default LenghtBasicPicker