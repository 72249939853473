import {Styled} from "../TripSearchBookingPage";
import {includes} from "lodash-es";
import CheckBox from "../../../../lib/UIBookBrd/CheckBox";
import {useTranslation} from "react-i18next";
import useBookingFunctions from "../functions";

const FinalConfirmation = () => {

    const {t} = useTranslation();

    const {
        state,
        checkBoxChangeHandler,
        reserveButtonClickHandler
    } = useBookingFunctions()

    const mandatoryCheckBoxes = [
        {
            type: "terms",
            label: (
                <>
                    {`* ${t('lbl_have_accepted_terms_condition')} `}
                    <span
                        onClick={(event: any) => {
                            event.stopPropagation();
                            alert('Terms')
                        }}
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>
                            {`${t('lbl_terms_conditions')}`}
                        </span>
                </>)
        },
        {
            type: 'payment',
            label: `* ${t('lbl_mandatory_payment_checkbox')}`
        },
        {
            type: 'userData',
            label: `* ${t('lbl_personal_dta_checkbox')}`
        }
    ]

    return (
        <>
            <Styled.Section__Type__Label>
                {t('lbl_final_agreements')}
            </Styled.Section__Type__Label>
            <Styled.CheckBoxes__Holder>
                {mandatoryCheckBoxes?.map((option: any) => (
                        <CheckBox
                            onChange={() => checkBoxChangeHandler(option?.type)}
                            isCheckedProp={includes(state?.checkBoxes ?? [], option.type)}
                            title={option.label as any}
                        />
                    )
                )}
            </Styled.CheckBoxes__Holder>
            <Styled.Reserve__Button
                onClick={reserveButtonClickHandler}
                label={t('lbl_reserve_pay')}
            />
        </>

    )
}
export default FinalConfirmation