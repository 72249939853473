import {Route, Routes} from "react-router-dom";
import React from "react";
import usePagesConfigs from "./lib/PagesConfigs";

const RoutesBookBrd = () => {

    const {
        routesList,
    } = usePagesConfigs()


    return (
        <Routes>
            {routesList?.map((routeValue: any) => {
                const path = routeValue?.pathName
                const content = routeValue?.content
                return (
                    <Route
                        key={`route_${path}`}
                        path={path}
                        element={content}
                    />
                )
            })}
        </Routes>
    )
}
export default RoutesBookBrd