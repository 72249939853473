import {Styled} from "../SimilarOffersDrawer.styles";
import HotelImage from "../../CompareOffersDrawer/OfferComparisonSection/HotelImage";
import StarIcons from "../../../../../../Components/StarIcons";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {useTranslation} from "react-i18next";
import DescriptionModal from "../../../../../../Components/DescriptionModal";
import {useEffect, useRef, useState} from "react";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {getDistance} from "../../../TripSearchFunctions";
import AccommodationAttributes from "../../../../../../Components/AccommodationAttributes";
import useElementResize from "../../../../../../lib/UseHooksBookBrd/useElementResize";
import {isEmpty} from "lodash-es";
import useHandleOnlineRequestError from "../../../../../../lib/UseHooksBookBrd/useHandleOnlineRequestError";

type DetailsProps = {
    type: string
    value: string
}

const Detail = (
    {
        type,
        value
    }: DetailsProps
) => {
    return (
        <Text style={{margin: "2px 0"}}>
            <Styled.Bold__Label>
                {type}{": "}
            </Styled.Bold__Label>
            {value}
        </Text>
    )

}


type AccommodationDetailsProps = {
    isFixed: boolean
    retryFunc?: () => void
    offer?: any
    enableObserver?: boolean
    requestReRender?: () => void
    description: any[] | undefined
    pictures: any[] | undefined
    isLoadingDetails: boolean
    isLoadingImagesAndDescription: boolean
    offerDetails: any
}

const AccommodationDetails = (
    {
        retryFunc,
        offer,
        isFixed,
        enableObserver,
        requestReRender,
        description,
        pictures,
        isLoadingDetails,
        isLoadingImagesAndDescription,
        offerDetails
    }: AccommodationDetailsProps
) => {

    const {t} = useTranslation();

    const containerRef = useRef<any>(null)

    const [elementHeight, setElementHeight] = useState(containerRef?.current?.clientHeight ?? 0)

    const [isOpenDescription, setIsOpenDescription] = useState<boolean>(false)

    const {heightObserver} = useElementResize()

    const {getTitleForRequests, getDescriptionForRequests} = useHandleOnlineRequestError()

    const distanceFromBeach = getDistance(offerDetails?.Accommodation?.DistanceToBeach)

    const distanceFromAirport = getDistance(offerDetails?.Accommodation?.DistanceToAirport)

    const distanceFromCityCenter = getDistance(offerDetails?.Accommodation?.DistanceToCityCenter)

    useEffect(() => {
        if (enableObserver) {
            heightObserver(containerRef, elementHeight, setElementHeight, requestReRender)
        }
    }, [elementHeight]);

    console.log({offer})

    return (
        <>
            <DescriptionModal
                isOpen={isOpenDescription}
                setIsOpen={setIsOpenDescription}
                descriptionValue={description}
            />
            <Styled.Accommodation__Holder
                isFixed={isFixed}
                ref={containerRef}
            >
                {isEmpty(offer?.error) || isLoadingDetails ? (
                    <>
                        <Styled.HotelImageHolder>
                            <HotelImage
                                pictures={pictures ?? []}
                                isLoadingData={isLoadingImagesAndDescription}
                                isEmptySection={false}
                            />
                        </Styled.HotelImageHolder>
                        <Styled.Accommodation__Details__Holder>
                            <FlexBox style={{flexWrap: "nowrap"}}>
                                <Styled.Accommodation__Label
                                    isLoading={isLoadingDetails}
                                >
                                    {offerDetails?.Accommodation?.XCode?.Name}
                                </Styled.Accommodation__Label>
                                <StarIcons
                                    isLoading={isLoadingDetails}
                                    number={offerDetails?.Accommodation?.Category}
                                />
                            </FlexBox>
                            <Styled.Accommodation__Location__Label
                                isLoading={isLoadingDetails}
                            >
                                {!isLoadingDetails ? `${offerDetails?.Accommodation?.XCountry?.Name} • ${offerDetails?.Accommodation?.XCity?.Name}` : ''}
                            </Styled.Accommodation__Location__Label>
                            <Styled.Accommodation__Details>
                                <Detail
                                    type={t('lbl_object_type')}
                                    value={offerDetails?.Accommodation?.Type?.Name}
                                />
                                <Detail
                                    type={t('lbl_distance_to_beach')}
                                    value={distanceFromBeach}
                                />
                                <Detail
                                    type={t('lbl_distance_to_city_center')}
                                    value={distanceFromCityCenter}
                                />
                                <Detail
                                    type={t('lbl_distance_to_airport')}
                                    value={distanceFromAirport}
                                />
                                <AccommodationAttributes
                                    maxNumberOfRows={2}
                                    attributes={offerDetails?.Accommodation?.Attributes}
                                />

                            </Styled.Accommodation__Details>
                        </Styled.Accommodation__Details__Holder>
                    </>
                ) : (
                    <Styled.Error__Accommodation>
                        <Styled.Error__Handler>
                            <Styled.Error__Title>
                                {getTitleForRequests(offer.error)}
                            </Styled.Error__Title>
                            <Styled.Error_Desc>
                                {getDescriptionForRequests(offer.error)}
                            </Styled.Error_Desc>
                            <Styled.Error__Button
                                onClick={retryFunc}
                                label={t('lbl_retry')}
                            />
                        </Styled.Error__Handler>
                    </Styled.Error__Accommodation>
                )}
            </Styled.Accommodation__Holder>
        </>
    )
}
export default AccommodationDetails