import styled from "styled-components";
import '../../Styles/fonts.css'

const BrdLogo = styled.div`
  cursor: pointer;
  min-width: 150px;
  max-width: 250px;
  height: 50px;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
`;

const FlexBox = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  max-width: 150px;
  height: auto;
`;

const Book__Label = styled.h1`
  margin: auto 0;
  font-size: 40px;

`

const Brd__Span = styled.span`
  margin-left: 8px;
  font-weight: lighter;
  font-family: HandWritenBrdStyle;
`

export const Styled = {
    Brd__Span,
    BrdLogo,
    FlexBox,
    Book__Label
}