import {Styled} from "./StatusMainContent.styles"
import {useTranslation} from "react-i18next";
import useConfirmationPage from "../useConfirmationPage";
import OrderStatus from "./Components/Status/OrderStatus";

type StatusMainContentProps = {
    isFullDetails?: boolean
}

const StatusMainContent = (
    {
        isFullDetails
    }: StatusMainContentProps) => {

    const {t} = useTranslation()

    const {
        orderNumber,
        paymentStatus
    } = useConfirmationPage()

    return (
        <Styled.Content__Core__Holder>
            {!isFullDetails && (
                <Styled.Order__Code__Title>
                    {`${t('lbl_order_code')}: ${orderNumber}`}
                </Styled.Order__Code__Title>
            )}
            <OrderStatus
                isFullDetails={!!isFullDetails}
            />
        </Styled.Content__Core__Holder>
    )
}
export default StatusMainContent