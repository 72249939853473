import {Styled} from "../Footer.styles";
import React from "react";
import Text from "../../../lib/UIBookBrd/Text";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {useTranslation} from "react-i18next";
import useFooterLinks from "./useFooterLinks";
import {
    FIND_ORDER_STATUS,
    LOGIN_LINK,
    MANAGE_ACCOUNT_LINK,
    OPERATORS__LIST,
    SUPPORT_MAIN_LINK,
    TRAVEL__REQUIERMENTS
} from "../../../lib/LinksForSEO";
import useUserData from "../../../lib/UseHooksBookBrd/useUserData";


type SectionLinkLabelProps = {
    label?: string
    isLink?: boolean
    link?: string
    onClick?: () => void
}

const SectionLinkLabel = (
    {
        isLink,
        link,
        label,
        onClick
    }: SectionLinkLabelProps
) => {


    return (
        <Text
            style={{
                margin: '1px 0',
                flexWrap: "nowrap",
                display: 'flex'
            }}
        >
            {!isLink ? (
                <>
                    <Styled.Little__Dot>
                        •
                    </Styled.Little__Dot>
                    <Styled.Link__Label
                        onClick={onClick}
                    >
                        {label}
                    </Styled.Link__Label>
                </>
            ) : (
                <>
                    <Styled.Little__Dot>
                        •
                    </Styled.Little__Dot>
                    <Styled.Link__Label__Link__Type
                        href={link}
                    >
                        {label}
                    </Styled.Link__Label__Link__Type>
                </>

            )}
        </Text>
    )
}

const Links = () => {

    const {t} = useTranslation();

    const {
        adminLink,
        becomeSupplier,
        favouriteOffersLink
    } = useFooterLinks()

    const {
        onOpenSettings,
    } = useBookBrdPageFunctions()

    const {isLogged} = useUserData()

    const otherOptions: any[] = [
        {
            label: t('lbl_settings'),
            onClick: onOpenSettings
        },
    ]
    const individualClientOptions: any[] = [
        {
            label: t('lbl_your_favourite_offers'),
            onClick: favouriteOffersLink,
            isShown: true
        },
        {
            label: t('lbl_get_refund'),
            isLink: true,
            link: FIND_ORDER_STATUS,
            isShown: true
        },
        {
            label: t('lbl_login'),
            isLink: true,
            link: LOGIN_LINK,
            isShown: !isLogged
        },
        {
            label: t('lbl_logout'),
            onClick: () => alert('logout'),
            isShown: false
        },
        {
            label: t('lbl_manage_account'),
            isLink: true,
            link: MANAGE_ACCOUNT_LINK,
            isShown: isLogged
        },
        {
            label: t('lbl_logout'),
            onClick: () => alert('logout'),
            isShown: isLogged
        },


    ]
    const businessClientOptions: any[] = [
        {
            label: t('lbl_admin_panel'),
            onClick: adminLink
        },
        {
            label: t('lbl_become_new_supplier'),
            onClick: becomeSupplier
        },
    ]
    const informationOptions: any[] = [
        {
            label: t('lbl_manage_reservation'),
            isLink: true,
            link: FIND_ORDER_STATUS,
        },
        {
            label: t('lbl_support'),
            link: SUPPORT_MAIN_LINK,
            isLink: true,
            isShown: true
        },
        {
            label: t('lbl_our_partners'),
            isLink: true,
            link: OPERATORS__LIST
        },
        {
            label: t('lbl_travel_regulations'),
            link: TRAVEL__REQUIERMENTS,
            isLink: true

        }

    ]

    return (
        <Styled.Footer__Links__Sections>
            {/*INDIVIDUAL CLIENT*/}
            <Styled.Footer__Section>
                <Styled.Footer__Section__Title>
                    {t('lbl_private_client_label')}
                </Styled.Footer__Section__Title>
                {individualClientOptions.filter((option: any) => option.isShown).map((option: any) => (
                    <SectionLinkLabel
                        isLink={option?.isLink ?? false}
                        link={option?.link ?? ''}
                        key={option.label}
                        label={option.label}
                        onClick={option?.onClick ?? undefined}
                    />
                ))}
            </Styled.Footer__Section>
            {/*SITE RELATED*/}
            <Styled.Footer__Section>
                <Styled.Footer__Section__Title>
                    {t('lbl_important_information_link')}
                </Styled.Footer__Section__Title>
                {informationOptions.map((option: any) => (
                    <SectionLinkLabel
                        isLink={option?.isLink ?? false}
                        link={option?.link ?? ''}
                        key={option.label}
                        label={option.label}
                        onClick={option.onClick}
                    />
                ))}
            </Styled.Footer__Section>
            {/*BUSINESS CLIENT*/}
            <Styled.Footer__Section>
                <Styled.Footer__Section__Title>
                    {t('lbl_business_information_label')}
                </Styled.Footer__Section__Title>
                {businessClientOptions.map((option: any) => (
                    <SectionLinkLabel
                        isLink={option?.isLink ?? false}
                        link={option?.link ?? ''}
                        key={option.label}
                        label={option.label}
                        onClick={option.onClick}
                    />
                ))}
            </Styled.Footer__Section>
            {/*OTHER SECTIONS*/}
            <Styled.Footer__Section>
                <Styled.Footer__Section__Title>
                    {t('lbl_other_section_label')}
                </Styled.Footer__Section__Title>
                {otherOptions.map((option: any) => (
                    <SectionLinkLabel
                        isLink={option?.isLink ?? false}
                        link={option?.link ?? ''}
                        key={option.label}
                        label={option.label}
                        onClick={option.onClick}
                    />
                ))}
            </Styled.Footer__Section>
        </Styled.Footer__Links__Sections>
    )
}

export default Links