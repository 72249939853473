import {Styled} from "./BookingComponent.styles"
import Select from "../../../../../../lib/UIBookBrd/SelectBookBrd/Select";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../lib/UIBookBrd/Button";
import PriceHolder from "./PriceHolder";
import {viewTypes} from "../../../../../../lib/UseHooksBookBrd/useV5OfferListRequest";
import StarIcons from "../../../../../../Components/StarIcons";
import {faBedFront, faCalendar, faClock, faMapPin, faPlane, faUtensils} from "@fortawesome/pro-solid-svg-icons";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";

type BookingComponent = {
    onBookClick: () => void
    onShowMap: any
    fields: viewTypes[]
    createOptionComponent: any
    onSelectChangeHandler: (value: any) => void
    loadingSelects: any
    bookingControls: any
    getDetailsParams: any
    isLoadingOfferDetails: boolean
    offer: any
}

const BookingComponent = (
    {
        onBookClick,
        onShowMap,
        fields,
        createOptionComponent,
        onSelectChangeHandler,
        loadingSelects,
        bookingControls,
        getDetailsParams,
        isLoadingOfferDetails,
        offer
    }: BookingComponent
) => {

    const {t} = useTranslation();

    const getPlaceHolder = (requestType: viewTypes) => {
        switch (requestType) {
            case "OfferOtherDates":
                return 'lbl_date_any'
            case "OfferOtherRooms":
                return 'lbl_room_any'
            case "OfferOtherBoard":
                return 'lbl_board_any'
            case "OfferOtherOperator":
                return 'lbl_operator_any'
            case "OfferOtherDuration":
                return 'lbl_duration_any'
            case "OfferOtherOutBound":
                return 'lbl_outbound_any'
        }
    }

    const getSelectTitle = (requestType: viewTypes) => {
        switch (requestType) {
            case "OfferOtherDates":
                return 'lbl_dates'
            case "OfferOtherRooms":
                return 'lbl_room'
            case "OfferOtherBoard":
                return 'lbl_board'
            case "OfferOtherOperator":
                return 'lbl_operator'
            case "OfferOtherDuration":
                return 'lbl_duration'
            case "OfferOtherOutBound":
                return 'lbl_outbound'
        }
    }

    const getCustomIcon = (requestType: viewTypes) => {
        switch (requestType) {
            case "OfferOtherDates":
                return faCalendar
            case "OfferOtherRooms":
                return faBedFront
            case "OfferOtherBoard":
                return faUtensils
            case "OfferOtherDuration":
                return faClock
            case "OfferOtherOutBound":
                return faPlane
            default:
                return faClock
        }
    }

    const customSelectField = (label: string, fieldName: string) => {

        return (
            <Styled.Custom__Select__Selected__Option>
                <Styled.Custom__Select__Selected__Option__Icon icon={getCustomIcon(fieldName as viewTypes) as any}/>
                <Text style={{width: '100%', maxWidth: 'calc(100%  - 20px)'}}>
                    {label}
                </Text>
            </Styled.Custom__Select__Selected__Option>
        )
    }

    return (
        <Styled.Booking__Component>
            <Styled.Names__Holder__Small__Width
                style={{
                    flexDirection: "column"
                }}
            >
                <Styled.Title__Holder>
                    <Styled.Object__Title__Name
                    >
                        {offer?.Base?.XCode?.Name}
                    </Styled.Object__Title__Name>
                    <StarIcons
                        isLoading={isLoadingOfferDetails}
                        number={offer?.Accommodation?.Category}
                    />
                </Styled.Title__Holder>
                <Styled.Location__Label
                >
                    <Styled.Pin__Icon
                        icon={faMapPin}
                    />
                    {offer?.Base?.XCity?.Name} / {offer?.Base?.XCountry?.Name} -
                    <Styled.Show__Map__Label
                        onClick={onShowMap}
                    >
                        {t('lbl_show_object_on_map')}
                    </Styled.Show__Map__Label>
                </Styled.Location__Label>
            </Styled.Names__Holder__Small__Width>
            <Styled.Control__Components__Holder>
                {fields?.map((fieldName: string) => (
                    <Styled.Select__Holder
                        key={`booking_component_${fieldName}`}
                    >
                        <Styled.Select__Title>
                            {t(getSelectTitle(fieldName as viewTypes) as string)}
                        </Styled.Select__Title>
                        <Select
                            listMinWidth={350}
                            customSelectedOption={(label: string) => customSelectField(label, fieldName)}
                            isLoadingAppliedList={isLoadingOfferDetails}
                            title={t(getSelectTitle(fieldName as viewTypes) as string)}
                            isLoadingMore={loadingSelects[fieldName]}
                            isClearAble={false}
                            onOpen={() => getDetailsParams(offer, fieldName)}
                            setAppliedList={onSelectChangeHandler}
                            isMulti={false}
                            options={bookingControls[fieldName]?.list ?? []}
                            placeholder={t(getPlaceHolder(fieldName as viewTypes) as string)}
                            appliedList={[{
                                label: createOptionComponent(
                                    bookingControls[fieldName]?.list?.filter((item: any) => item?.value === offer?.Base?.OfferId)[0]?.offer,
                                    bookingControls[fieldName]?.list?.filter((item: any) => item?.value === offer?.Base?.OfferId)[0]?.offer,
                                    fieldName,
                                    true,
                                    false
                                ),
                                isCustom: true,
                                value: offer?.Base?.OfferId,
                                offer: offer
                            }]}

                        />
                    </Styled.Select__Holder>
                ))}
                <Styled.Apply__Price__Button__Holder>

                    <FlexBox
                        style={{marginLeft: 'auto'}}
                    >
                        {offer && (
                            <PriceHolder
                                Base={offer?.Base}
                            />
                        )}
                    </FlexBox>
                    <Button
                        label={t('lbl_book_trip')}
                        onClick={onBookClick}
                    />
                </Styled.Apply__Price__Button__Holder>

            </Styled.Control__Components__Holder>

        </Styled.Booking__Component>
    )
}
export default BookingComponent