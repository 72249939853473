import {Styled} from "../../TripOffer.styles"
import Tooltip from "../../../../../../../../lib/UIBookBrd/Tooltip";
import {useTranslation} from "react-i18next";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import FlexBox from "../../../../../../../../lib/UIBookBrd/FlexBox";
import {isMobileOnly} from "react-device-detect";
import {usePricesHandler} from "../../../../../../../../lib/UseHooksBookBrd/usePricesHandler";
import {floor} from "lodash-es";

type PricingProps = {
    isExlusive?: boolean
    pricing: any
    style?: React.CSSProperties
}

const Pricing = (
    {
        isExlusive,
        pricing,
        style
    }: PricingProps
) => {

    const {t} = useTranslation();

    const {toolTipContent, mainValue, taxInfo, isCurrencyDiff} = usePricesHandler(pricing)


    return (
        <>
            <Tooltip
                disabled={isMobileOnly}
                content={toolTipContent}>
                <Styled.PriceContentsHolder
                    style={{...style}}
                >
                    <FlexBox>
                        <Styled.PriceHolder>
                            {` ${(isCurrencyDiff && isMobileOnly) ? t('lbl_price_total_preferred') : t('lbl_total_price')}:`}
                            {' '}
                            <Styled.Custom__Color
                                isExlusive={!!isExlusive}
                            >
                                {`${floor(mainValue?.total?.amount)}`}
                                {' '}
                                {`${mainValue?.total?.currency}`}
                            </Styled.Custom__Color>
                        </Styled.PriceHolder>
                        {!isMobileOnly && (
                            <Styled.InformationIcon
                                icon={faInfoCircle}
                            />
                        )}
                    </FlexBox>
                    {isMobileOnly && (
                        <Styled.Tax__Label>
                            {taxInfo}
                        </Styled.Tax__Label>
                    )}
                </Styled.PriceContentsHolder>
            </Tooltip>
        </>

    )
}
export default Pricing