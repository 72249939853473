import {Local_Settings_Values} from "../PageConfig";
import {includes, isEmpty, toNumber} from "lodash-es";
import {useDebouncedCallback} from "use-debounce";
import {useContext, useEffect, useState} from "react";
import {promiseRequest} from "../RequestBuilder";
import {CONTENT_SERVICE_URL, TRIP_SEARCH_CONFIG_SECTIONS} from "../Configs/RequestConfig";
import useHandleOnlineRequestError from "./useHandleOnlineRequestError";
import {
    DURATION_DEFAULT,
    PARTICIPANTS_DEFAULT,
    TO_FIELD,
    XCITY_FIELD,
    XCODE_FIELD
} from "../DefaultFormikValue/DefaultSearchFormValues";
import {highlightedAdvertCountriesList} from "../configValue";
import {useTranslation} from "react-i18next";
import useAutoSuggest from "../../Components/DestinationPicker/AutoSuggestFunctionality";
import useGoogleAnalitics from "../GoogleAnalytics/useGoogleAnalitics";
import {Local_Data} from "../Context/Context";
import {getPreferences} from "../Routes";
import useStorage from "./useStorage";

const useConfigureSite = () => {

    const [localData, setLocalData] = useContext<any>(Local_Data)

    const [localSettings, setLocalSettings] = useStorage<any>('localStorage', 'LocalSettings', Local_Settings_Values)

    const [localParticipants, setLocalParticipants] = useStorage<any>('localStorage', 'LocalParticipants', PARTICIPANTS_DEFAULT)

    const [localDuration, setLocalDuration] = useStorage('localStorage', 'LocalDuration', DURATION_DEFAULT)

    const [errorRespond, setErrorRespond] = useState<string | null>(null)

    const userData = getPreferences(localSettings)

    const [offersWithLabels, setOffersWithLabels] = useStorage<any>('sessionStorage', 'offersWithLabels', {language: localSettings?.SelectedLanguage ?? userData.SelectedLanguage})

    const {getNamesForValues} = useAutoSuggest(localSettings?.SelectedLanguage ?? userData.SelectedLanguage)


    const {t} = useTranslation();

    const {sendToAnalytics} = useGoogleAnalitics()

    const {getWebContentServiceError} = useHandleOnlineRequestError()


    //Passive functions

    const checkDestinationLabels = (SearchFormValues: any) => {
        const allCodes: any[] = []
        const countriesRegions = SearchFormValues[TO_FIELD]
        const hotels = SearchFormValues[XCODE_FIELD]
        const cities = SearchFormValues[XCITY_FIELD]

        if (!isEmpty(countriesRegions)) {
            countriesRegions?.map((id: string) => allCodes.push(id))
        }
        if (!isEmpty(hotels)) {
            hotels.map((code: any) => allCodes.push(code))
        }
        if (!isEmpty(cities)) {
            allCodes.push(cities)
        }

        const listLenght = !isEmpty(offersWithLabels?.list) ? offersWithLabels?.list?.filter((item: any) => includes(allCodes, item.value)).length : 0
        const language = localSettings?.SelectedLanguage ?? userData.SelectedLanguage
        if (language !== offersWithLabels.language || allCodes.length !== listLenght) {
            getNamesForValues(
                offersWithLabels,
                setOffersWithLabels,
                SearchFormValues[TO_FIELD],
                SearchFormValues,
                allCodes,
            )
        }
    }


    const onStateChange = (name: string, value: any) => {
        setLocalSettings((state: any) => ({
            ...state,
            [name]: value
        }))
    }

    //Active Functions

    const onCookiesChangeHandler = (type: 'reject' | 'accept') => {
        setLocalSettings((state: any) => ({
            ...state,
            Cookies: type
        }))
    }

    const onAdvertisemenetChangeHandler = (value: any) => {
        onStateChange('AdvertisementLocation', value.value)
    }

    const onAutomaticLowDataModeChangeHandler = () => {
        onStateChange('AutomaticLowDataMode', !!!localSettings?.AutomaticLowDataMode)
    }

    const onTylerToggleChangeHandler = () => {
        onStateChange('hideTylerToggle', !!!localSettings?.hideTylerToggle)
    }

    const onThemeChangeHandler = () => {
        sendToAnalytics({
            event: 'Theme_Change',
            eventCategory: 'BUTTON_CLICK',
            eventAction: 'Theme_Change',
            eventLabel: !localSettings?.isLightTheme ? 'dark' : 'bright' as string
        })
        onStateChange('isLightTheme', !localSettings?.isLightTheme)
    }

    const onLowDataModeChangeHandler = () => {
        onStateChange('LowDataMode', !localSettings?.LowDataMode)
    }

    const onLanguageChangeHandler = (value: any) => {
        if (value.value !== localSettings.SelectedLanguage) {
            sendToAnalytics({
                event: 'Language_Change',
                eventCategory: 'BUTTON_CLICK',
                eventAction: 'Language_Change'
            })
            onStateChange('SelectedLanguage', value.value)
            window.location.reload();
        }
    }

    const onPreferredCountrySelectChangeHandler = (value: any) => {
        onStateChange('PreferredCountry', value.value)
    }

    const onCurrencyChangeHandler = (value: any) => {
        sendToAnalytics({
            event: 'Currency_Change',
            eventCategory: 'BUTTON_CLICK',
            eventAction: 'Currency_Change'
        })
        onStateChange('PreferredCurrency', value.value)
    }

    const onLocalParticipantsChange = (name: any, value: any) => {
        setLocalParticipants(value)
    }

    const onLocalDurationChangeHandler = (name: any, value: any) => {
        setLocalDuration(value)
    }

    const configBookBrd = () => {
        let newValue: any = localData
        let configValue: any = !isEmpty(newValue['SiteConfig']) ? newValue['SiteConfig'] : {}
        configValue['highlightDestination'] = ['11:', '5:', '6:', '15:', '59:', '10:', '29:']
        configValue['highlightedAdvertCountries'] = highlightedAdvertCountriesList
        newValue['SiteConfig'] = configValue
        setLocalData(newValue)
    }

    let isOnline = true
    const searchBaseDebuoncer = useDebouncedCallback(() => {
        searchBaseRequest()
    }, 200)

    const searchBaseRequest = async () => {
        if (isOnline) {
            try {
                setLocalData((state: any) => ({
                    ...state,
                    isLoading: true
                }))
                const getPayload = JSON.stringify({
                    Sections: ['SearchBase'],
                    OnlyFields: TRIP_SEARCH_CONFIG_SECTIONS,
                    ExternalDataSource: 'MDS',
                    Language: localSettings?.SelectedLanguage ? localSettings?.SelectedLanguage : window.navigator.language.split('-')[0],
                })
                const request = await promiseRequest(`${CONTENT_SERVICE_URL}SearchBase/${getPayload}`, null, 3);
                const {Sections} = request;
                const {SearchBase} = Sections;
                setLocalData((state: any) => ({
                    ...state,
                    ['SearchBaseData']: SearchBase
                }))
            } catch (e: any) {
                setErrorRespond(getWebContentServiceError(e))
            } finally {
                setLocalData((state: any) => ({
                    ...state,
                    isLoading: false
                }))
            }
        }
        setLocalData((state: any) => ({
            ...state,
            isLoading: false
        }))
    }

    const comingSoonFunc = () => {
        setLocalData((state: any) => ({
            ...state,
            isLoading: false
        }))
    }

    useEffect(() => {
        const scrollPosition = sessionStorage.getItem('lastPosition') ? JSON.parse(sessionStorage.getItem('lastPosition') as string) : 0
        window?.scrollTo(0, toNumber(scrollPosition))
    }, [])

    return ({
        localSettings,
        showCookiesDrawer: !localSettings?.Cookies,
        onLocalDurationChangeHandler,
        onAdvertisemenetChangeHandler,
        localParticipants,
        localDuration,
        errorType: errorRespond,
        userData,
        checkDestinationLabels,
        setErrorRespond,
        onCookiesChangeHandler,
        onTylerToggleChangeHandler,
        onAutomaticLowDataModeChangeHandler,
        onLowDataModeChangeHandler,
        onCurrencyChangeHandler,
        onThemeChangeHandler,
        onPreferredCountrySelectChangeHandler,
        onLanguageChangeHandler,
        onLocalParticipantsChange,
        searchBaseDebuoncer,
        configBookBrd,
        comingSoonFunc,
        Language: localSettings?.SelectedLanguage,
        isLightTheme: localSettings?.isLightTheme
    })
}

export default useConfigureSite