import {Styled} from "./TripOfferList.styles";
import {useTranslation} from "react-i18next";

const EmptyOffers = () => {

    const {t} = useTranslation();

    return (
        <Styled.No_Offers_Found_Holder>
            <Styled.No_Offers_Found_Main_Label>
                {t('lbl_no_offers_found')}
            </Styled.No_Offers_Found_Main_Label>
            <Styled.No_Offers_Found_Desc_Label>
                {t('lbl_no_offers_found_desc')}
            </Styled.No_Offers_Found_Desc_Label>
        </Styled.No_Offers_Found_Holder>
    )
}

export default EmptyOffers