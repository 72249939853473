import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Styled} from "./UserAccountTap.styles"
import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";
import {Modal} from "../../lib/UIBookBrd/Modal/ModalsTypes";
import usePopperPositioning from "../../lib/UseHooksBookBrd/usePopperPositioning";
import {isEmpty} from "lodash-es";
import {faUserSecret} from "@fortawesome/pro-solid-svg-icons";

type UserAccountTapProps = {
    countryImage: string
    setCloseClientDrawer: () => void
    setOpenClientDrawer: () => void
    isSmallWidth: boolean
    listOfOptions: any
    accountDropDown: any
    logged: boolean
    image?: string
    accountText: string
    userBackgroundColor: string
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void
    style?: React.CSSProperties
}
const defaultProps = {
    userBackgroundColor: 'red',
    image: undefined,
    onClick: undefined,
    onMouseOver: undefined,
    onMouseLeave: undefined,
    children: undefined,
}
type TextsProps = {
    icon: any
    onClick: any
    label: string
    navigation: string
}
const Texts = ({label, navigation, onClick, icon}: TextsProps) => {
    const {t} = useTranslation();

    const onClickChangeHandler = () => {
        onClick(navigation)
    }
    return (
        <Styled.Texts
            onClick={onClickChangeHandler}
        >
            <Styled.Icon icon={icon}/>
            {t(`${label}`)}
        </Styled.Texts>
    )
}

const UserAccountTap = (
    {
        userBackgroundColor,
        countryImage,
        setOpenClientDrawer,
        setCloseClientDrawer,
        image,
        isSmallWidth,
        logged,
        accountText,
        accountDropDown,
        listOfOptions,
        ...props
    }: UserAccountTapProps) => {


    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const inputElement = useRef<HTMLDivElement>();

    const {styles, attributes} = usePopperPositioning({
        targetElementRef: inputElement,
        popperElementRef: modalElement,
        applyMaxSizeEnabled: true,
        zIndex: 9999,
        padding: 15,
    });

    const {t} = useTranslation();

    useOnClickOutside(modalElement, () => !isSmallWidth && setCloseClientDrawer());

    const onCLickFunc = () => {
        if (accountDropDown) {
            setCloseClientDrawer()
        } else {
            setOpenClientDrawer()
        }
    }

    return (
        <>

            <Styled.UserAccountTap
            >
                <Styled.TopLayer
                    ref={inputElement as any}
                    onClick={onCLickFunc}>
                    <Styled.CustomText>
                        {logged ? `${accountText}` : `${t('NoAccount')}`}
                    </Styled.CustomText>
                    {logged ? (
                        <>
                            {isEmpty(image) ? (
                                <Styled.No__Image__User
                                    backgroundColor={userBackgroundColor}
                                >
                                    <Styled.No__Image__User__Label>
                                        {accountText[0].toUpperCase()}
                                    </Styled.No__Image__User__Label>
                                </Styled.No__Image__User>
                            ) : (
                                <Styled.Image
                                    alt={''}
                                    src={image}
                                />
                            )}
                        </>
                    ) : (
                        <Styled.No__Image__User
                            backgroundColor={undefined}
                        >
                            <Styled.Not__User__Icon
                                icon={faUserSecret}
                            />
                        </Styled.No__Image__User>
                    )}

                </Styled.TopLayer>
                <Modal
                    ref={setModalElement}
                    width={200}
                    showOverlay={false}
                    isOpen={accountDropDown && !isSmallWidth}
                    positionedByPopper
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <Styled.DropDown>
                        {listOfOptions?.map((item: any) => {
                            return (
                                <Texts
                                    key={`account_option_${item.navigate}`}
                                    icon={item.icon}
                                    onClick={item.onClick}
                                    label={item.label}
                                    navigation={item.navigate}
                                />
                            )
                        })}
                    </Styled.DropDown>
                </Modal>
            </Styled.UserAccountTap>

        </>
    )
}
UserAccountTap.defaultProps = defaultProps;

export default UserAccountTap