import {Styled} from "../../ManageAccountPage.styles";
import {faArrowLeft} from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import Text from "../../../../lib/UIBookBrd/Text";
import React from "react";
import {useTranslation} from "react-i18next";

type GoBackToSettings = {
    onClick: () => void
}

const GoBackToSettings = (
    {
        onClick
    }: GoBackToSettings
) => {

    const {t} = useTranslation()

    return (
        <Styled.Go__Back__Holder
            onClick={onClick}
        >
            <Styled.Go__Back__Icon
                icon={faArrowLeft}
            />
            <Text>
                {t('lbl_back_to_settings')}
            </Text>
        </Styled.Go__Back__Holder>
    )
}

export default GoBackToSettings