import {Styled} from "./LenghtBasicPicker.styles"

type LenghtElementProps = {
    onClick: (value: string | number) => void
    label: string
    value: number | string
    isIncluded: boolean
    isSelected: boolean
}

const LengthElement = (
    {
        onClick,
        label,
        value,
        isIncluded,
        isSelected,
    }: LenghtElementProps) => {
    const onClickHandler = () => {
        onClick(value)
    }

    return (
        <Styled.LengthElement
            onClick={onClickHandler}
            isSelected={isSelected}
            isIncluded={isIncluded}
        >
            <Styled.ElementText>
                {label}
            </Styled.ElementText>
        </Styled.LengthElement>
    )
}

export default LengthElement