import {Styled} from "../Offer.styles";
import {linkTypes} from "../../../lib/Types";
import useBookBrdPageFunctions from "../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";

type SEOLinksProps = {
    offerData: any
}


const SEOLinks = (
    {
        offerData
    }: SEOLinksProps) => {


    const {
        createLinkPathForOfferDetails,
        SEOLinkForOffer
    } = useBookBrdPageFunctions()


    return (
        <>
            <Styled.Offer__Link
                href={createLinkPathForOfferDetails(window.location.pathname, linkTypes.link, offerData)}
            >
                {SEOLinkForOffer(offerData)}
            </Styled.Offer__Link>
        </>
    )
}

export default SEOLinks