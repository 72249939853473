import PageHolder from "../../../../../lib/UIBookBrd/PageHolder";
import TopButtons from "../TopButtons";
import {Styled} from "../../TripSearchResultPage.styles";
import TripOfferList from "./TripOfferList";
import React from "react";


const OffersListHolder = () => {


    return (
        <PageHolder>
            <TopButtons/>
            <Styled.TripSearchResults>
                <TripOfferList/>
            </Styled.TripSearchResults>
        </PageHolder>
    )

}

export default OffersListHolder