import {Styled} from "./ManageContent.styles"
import Offer from "./Components/Offer";
import Header from "./Components/Header";
import ViewTypeSelect from "./Components/ViewTypeSelect";
import Views from "./Components/Views";

const ManageMainContent = () => {

    return (
        <Styled.Manage__Content__Holder>
            <Header/>
            <Offer/>
            <ViewTypeSelect/>
            <Views/>
        </Styled.Manage__Content__Holder>
    )
}

export default ManageMainContent