import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Content__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 10px;
  flex-wrap: nowrap;
`
const Drawer__Title = styled(Text)`
  font-size: 16px;
  padding: 5px;
  font-weight: bold;
  margin: auto 0;
`
const Cookie__Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  padding: 5px 0 5px 5px;
  color: ${({theme}) => theme.color};
`

const Close__Icon = styled(FontAwesomeIcon)`
  width: 12.5px;
  height: 20px;
  padding: 5px;
  color: ${({theme}) => theme.color};
  cursor: pointer;
`
const Cookies__Description = styled(Text)`
  padding: 5px 5px 10px 5px;
`
const Header = styled(FlexBox)`
  padding: 0 5px;
  border-bottom: 1px solid ${({theme}) => theme.color};
`
const Globe__Icon = styled(FontAwesomeIcon)`
  height: 20px;
  width: 20px;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: auto;
  cursor: pointer;
  color: ${({theme}) => theme.color};

  &:hover {
    transform: scale(1.05);
  }
`

export const Styled = {
    Content__Holder,
    Header,
    Drawer__Title,
    Cookie__Icon,
    Close__Icon,
    Cookies__Description,
    Globe__Icon
}