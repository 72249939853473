import {Styled} from "../../Offer.styles"
import SkeletonBox from "../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";

const SkeletonOffer = () => {


    return (
        <Styled.Offer>
            <SkeletonBox
                maxWidth={'unset'}
                margin={'0'}
                height={'190px'}
                style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                    borderBottomLeftRadius: '0',
                    borderBottomRightRadius: '0'
                }}
            />
            <SkeletonBox
                minHeight={'20px'}
                margin={'5px'}
                maxWidth={'70%'}
            />
            <SkeletonBox
                minHeight={'16px'}
                margin={'10px 5px 5px 5px'}
                maxWidth={'unset'}
                width={`${Math.floor(Math.random() * (80 - 30 + 1)) + 30}%`}
            />
            <SkeletonBox
                minHeight={'16px'}
                margin={' 8px 5px'}
                maxWidth={'unset'}
                width={`${Math.floor(Math.random() * (80 - 30 + 1)) + 30}%`}
            />
            <SkeletonBox
                minHeight={'16px'}
                margin={' 8px 5px'}
                maxWidth={'unset'}
                width={`${Math.floor(Math.random() * (80 - 30 + 1)) + 30}%`}
            />
            <SkeletonBox
                minHeight={'16px'}
                margin={' 8px 5px'}
                maxWidth={'unset'}
                width={`${Math.floor(Math.random() * (80 - 30 + 1)) + 30}%`}
            />
            <SkeletonBox
                minHeight={'24px'}
                margin={' 8px 5px  8px auto'}
                maxWidth={'unset'}
                width={`${Math.floor(Math.random() * (40 - 30 + 1)) + 30}%`}
            />
            <SkeletonBox
                minHeight={'32px'}
                margin={'auto 5px'}
                maxWidth={'unset'}
                width={'calc(100% - 10px)'}
                style={{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px'
                }}
            />
        </Styled.Offer>
    )
}

export default SkeletonOffer