import {Styled} from "./OfferComparisonSection.styles"
import ImagesGaleryFullPage from "../../../../../../Components/ImagesGalery/ImagesGaleryFullPage";
import {useEffect, useRef, useState} from "react";
import {isEmpty} from "lodash-es";
import {faChevronLeft, faChevronRight, faQuestion} from "@fortawesome/pro-solid-svg-icons";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faCameraSlash} from "@fortawesome/pro-light-svg-icons";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";
import {useSwipeable} from "react-swipeable";

type HotelImageProps = {
    thumbUrl?: string
    customIcon?: IconProp
    showIconWhenEmpty?: boolean
    customIconStyles?: React.CSSProperties
    setClickOutsideIgnoreElements?: any
    isEmptySection: boolean
    isLoadingData: boolean
    pictures: any[]
}
const defaultProps = {
    thumbUrl: '',
    customIconStyles: undefined,
    customIcon: undefined,
    showIconWhenEmpty: false,
    isEmptySection: false
}

const HotelImage = (
    {
        thumbUrl,
        customIconStyles,
        customIcon,
        showIconWhenEmpty,
        setClickOutsideIgnoreElements,
        pictures,
        isEmptySection,
        isLoadingData,
    }: HotelImageProps
) => {

    const [isOpen, setIsOpen] = useState(false)

    const [displayedImage, setDisplayedImage] = useState<string>(!isEmpty(pictures) ? pictures[0] : '')

    const imagesListRef = useRef<any>(null)

    useEffect(() => {
        if (isEmpty(displayedImage) && !isEmpty(pictures)) {
            setDisplayedImage(pictures[0])
        }
    }, [pictures || displayedImage])

    const navigateInList = (index: number) => {
        const elementWidth = 104
        imagesListRef?.current?.scrollTo(elementWidth * (index - 1), 0)
    }

    const navigateFunction = (actionType: 'previous' | 'next') => {

        const currentImageIndex = pictures.indexOf(displayedImage)

        const arrayLength = pictures?.length

        switch (actionType) {
            case "next":
                if (currentImageIndex + 1 === arrayLength) {
                    setDisplayedImage(pictures[0])
                    navigateInList(0)
                } else {
                    setDisplayedImage(pictures[currentImageIndex + 1])
                    navigateInList(currentImageIndex + 1)
                }
                break
            case "previous":
                if (currentImageIndex === 0) {
                    setDisplayedImage(pictures[arrayLength - 1])
                    navigateInList(arrayLength - 1)
                } else {
                    setDisplayedImage(pictures[currentImageIndex - 1])
                    navigateInList(currentImageIndex - 1)
                }
                break
        }
    }

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            const {dir} = eventData
            if (dir === 'Left') {
                navigateFunction('next')
            }
            if (dir === 'Right') {
                navigateFunction('previous')
            }
        },
        preventScrollOnSwipe: true,
    });

    useEffect(() => {
        if (setClickOutsideIgnoreElements) {
            if (isOpen) {
                setClickOutsideIgnoreElements(['Open'])
            } else {
                setClickOutsideIgnoreElements(undefined)
            }
        }
    }, [isOpen])

    return (
        <Styled.HotelImages__Holder>
            <>
                <FlexBox style={{flexWrap: 'nowrap'}}>
                    {!isEmpty(pictures) && !isLoadingData ? (
                        <>
                            {Array.from({length: 5}).map((picture: any, index: number) => (
                                <Styled.Main___Image
                                    showIconWhenEmpty={showIconWhenEmpty}
                                    customIcon={customIcon}
                                    customIconStyles={customIconStyles ?? {
                                        width: '50px',
                                        height: "50px"
                                    }}
                                    disableLoader={isEmptySection}
                                    isFocused={index === 0}
                                    src={(!isLoadingData && !isEmpty(pictures)) ? pictures[pictures.indexOf(displayedImage) + index] : thumbUrl}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {isLoadingData ? (
                                <SkeletonBox
                                    style={{
                                        minHeight: '150px',
                                        maxHeight: '150px',
                                        width: '100%',
                                        borderRadius: '5px',
                                        minWidth: '100%',
                                        margin: '10px 0'
                                    }}
                                />
                            ) : (
                                <Styled.Empty__Main__Image
                                >
                                    {!isLoadingData && !isEmptySection && (
                                        <Styled.Empty__Main__Image__Icon
                                            icon={faCameraSlash}
                                        />
                                    )}
                                    {!isLoadingData && isEmptySection && (
                                        <Styled.Empty__Main__Image__Icon
                                            icon={faQuestion}
                                        />
                                    )}
                                </Styled.Empty__Main__Image>
                            )}
                        </>
                    )}
                    {!isEmpty(pictures) && !isLoadingData && (
                        <Styled.Hotel__Main__Image__Controls__Holder
                            {...handlers}
                        >
                            <Styled.Navigate__Button__Holder
                                onClick={() => {
                                    navigateFunction('previous')
                                }}
                            >
                                <Styled.Navigation__Icon
                                    icon={faChevronLeft}
                                />
                            </Styled.Navigate__Button__Holder>
                            <Styled.Open__FullScreen__Holder
                                onClick={() => setIsOpen(true)}
                            />
                            <Styled.Navigate__Button__Holder
                                onClick={() => {
                                    navigateFunction('next')
                                }}
                            >
                                <Styled.Navigation__Icon
                                    icon={faChevronRight}
                                />
                            </Styled.Navigate__Button__Holder>
                        </Styled.Hotel__Main__Image__Controls__Holder>
                    )}
                </FlexBox>
                <Styled.SmallImagesHolder
                    ref={imagesListRef}
                    isLoadingData={isLoadingData || isEmpty(pictures)}
                >
                    {!isLoadingData ? (
                        <>
                            {isEmpty(pictures) ? (
                                <>
                                    {Array.from({length: 6}).map(() => (
                                        <Styled.HotelImages__Small__Empty__Filler/>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {pictures?.map((image: any) => {
                                        const isDisplayedImage = image === displayedImage
                                        const onClick = () => {
                                            setDisplayedImage(image)
                                        }
                                        return (
                                            <Styled.HotelImages__Small
                                                onClick={onClick}
                                                image={image}
                                                isDisplayed={isDisplayedImage}
                                            />
                                        )
                                    })}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {Array.from({length: 8}).map((image: any) => {
                                const isDisplayedImage = image === displayedImage
                                return (
                                    <SkeletonBox
                                        style={{
                                            minHeight: '50px',
                                            maxHeight: '50px',
                                            maxWidth: '100px',
                                            minWidth: '100px',
                                            margin: '0',
                                            borderRadius: '5px'
                                        }}
                                    />
                                )
                            })}
                        </>
                    )}
                </Styled.SmallImagesHolder>
                <ImagesGaleryFullPage
                    setDisplayedImage={setDisplayedImage}
                    navigateFunction={navigateFunction}
                    pictures={pictures}
                    displayedImage={displayedImage}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            </>
        </Styled.HotelImages__Holder>
    )

}

HotelImage.defaultProps = defaultProps;

export default HotelImage