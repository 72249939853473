import TylerQuickDocumentationInModal from "../../Pages/TylerDocumentation/QuickDocuInModal";
import useTylerBot from "./Handlers/Hook";

const AdditionalModals = () => {

    const {
        state,
        toggleTylerQuickStart,

    } = useTylerBot()

    return (
        <>
            <TylerQuickDocumentationInModal
                toggleModal={toggleTylerQuickStart}
                isOpen={state.isQuickStartOpen}
            />

        </>
    )
}

export default AdditionalModals