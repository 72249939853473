import {Styled} from "./BotControls.styles";
import {faEllipsis, faInfo, faTrashCan} from "@fortawesome/pro-solid-svg-icons";
import useTylerBot from "../Handlers/Hook";
import {Modal} from "../../../lib/UIBookBrd/Modal/ModalsTypes";
import {useRef, useState} from "react";
import usePopperPositioning from "../../../lib/UseHooksBookBrd/usePopperPositioning";
import useOnClickOutside from "../../../lib/UseHooksBookBrd/useOnClickOutside";
import {useTranslation} from "react-i18next";
import {faFaceGlasses, faFaceTongueMoney} from "@fortawesome/pro-light-svg-icons";

const MoreOptions = () => {

    const [isOpenOptionModal, setIsOpenOptionModal] = useState<boolean>(false)

    const optionsIconRef = useRef<HTMLDivElement>();

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const {styles, attributes} = usePopperPositioning({
        targetElementRef: optionsIconRef,
        popperElementRef: modalElement,
        applyMaxSizeEnabled: true,
        placement: 'top-start',
        zIndex: 0,
        padding: 15,
    });

    const {t} = useTranslation()

    const onClose = () => {
        setIsOpenOptionModal(false)
    }

    useOnClickOutside(modalElement, onClose);

    const {
        devOnlyCleaner,
        toggleNerdMode,
        isNerdMode,
        toggleTylerQuickStart
    } = useTylerBot()

    const optionsList: any = [
        {
            text: t('lbl_clean_tyler_conversation'),
            icon: faTrashCan,
            action: devOnlyCleaner
        },
        {
            text: t('lbl_how_to_use_tyler'),
            icon: faInfo,
            action: () => toggleTylerQuickStart(true)
        },
        {
            text: isNerdMode ? 'CHAD MODE' : 'NERD MODE',
            icon: isNerdMode ? faFaceTongueMoney : faFaceGlasses,
            action: toggleNerdMode
        }
    ]

    return (
        <>
            <Modal
                ref={setModalElement}
                width={180}
                showOverlay={false}
                isOpen={isOpenOptionModal}
                style={styles.popper}
                {...attributes.popper}
            >
                {optionsList.map((option: any) => {

                    const onClick = () => {
                        option.action()
                        onClose()
                    }

                    return (
                        <Styled.More__Options__Option__Holder
                            onClick={onClick}
                        >
                            <Styled.More__Options__Option__Icon
                                icon={option.icon}
                            />
                            <Styled.More__Options__Option__Label>
                                {option.text}
                            </Styled.More__Options__Option__Label>

                        </Styled.More__Options__Option__Holder>
                    )
                })}
            </Modal>
            <Styled.More__Option__Icon
                ref={optionsIconRef as any}
                onClick={() => {
                    setIsOpenOptionModal(true)
                }}
                icon={faEllipsis}
            />
        </>
    )
}

export default MoreOptions