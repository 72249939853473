import Select from "../../lib/UIBookBrd/SelectBookBrd/Select";
import React from "react";
import {Styled} from "./RatingPicker.styles";
import {faStar as notFilled} from "@fortawesome/pro-light-svg-icons";
import {faStar as filed} from "@fortawesome/pro-solid-svg-icons";
import {RATING_FIELD} from "../../lib/DefaultFormikValue/DefaultSearchFormValues";
import {useTranslation} from "react-i18next";
import {isEmpty, isUndefined} from "lodash-es";
import CheckBox from "../../lib/UIBookBrd/CheckBox";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";

type RatingPickerProps = {
    filterFormValue: any
    isSelectMode?: boolean
    setFilterValue: (name: string | string[], values: string | any) => void
}

const RatingPicker = (
    {
        isSelectMode,
        filterFormValue,
        setFilterValue
    }: RatingPickerProps
) => {

    const getOptions = () => {
        const maxNumber = 6
        const ratingOptionsList: any[] = []
        for (let i = 0; i < maxNumber; i++) {
            ratingOptionsList?.push({
                label: (
                    <Styled.StarsHolder
                        isSelectMode={!!isSelectMode}
                    >
                        {Array.from({length: maxNumber - i}).map(() => (
                            <Styled.StarIcon
                                isSelectMode={!!isSelectMode}
                                isFilled={true}
                                icon={filed}
                            />
                        ))}
                        {isSelectMode && Array.from({length: i}).map(() => (
                            <Styled.StarIcon
                                isSelectMode={!!isSelectMode}
                                icon={notFilled}
                            />
                        ))}
                    </Styled.StarsHolder>
                ),
                value: maxNumber - i
            })
        }
        return ratingOptionsList
    }

    const options = getOptions()

    const {t} = useTranslation();

    const onClick = (value: any) => {
        setFilterValue(RATING_FIELD, isEmpty(value) ? {} : {min: value.value, max: 7})
    }

    return !isSelectMode ? (
        <Styled.Stars__Holder>
            <CheckBox
                isCheckedProp={isUndefined(filterFormValue)}
                onChange={() => {
                    onClick(undefined)
                }}
                title={(
                    <Text style={{
                        margin: '4.5px 0'
                    }}
                    >
                        {t('lbl_rating_any')}
                    </Text>
                )}
            />
            {options.map((option: any) => {

                const content = (
                    <FlexBox>
                        <Text
                            style={{
                                marginTop: "5px",
                                marginRight: "4px"
                            }}
                        >
                            {t('lbl_from')}:
                        </Text>
                        {option.label}
                    </FlexBox>
                )

                return (
                    <CheckBox
                        key={`Rating_checkbox_${option.value}`}
                        isCheckedProp={filterFormValue?.min === option?.value}
                        onChange={() => {
                            onClick({value: option.value})
                        }}
                        title={content}
                    />
                )
            })}
        </Styled.Stars__Holder>

    ) : (
        <Select
            title={t('lbl_rating')}
            setAppliedList={onClick}
            placeholder={t('lbl_rating_any')}
            appliedList={options.filter((item: any) => item.value === filterFormValue?.min)}
            isMulti={false}
            options={options}
        />
    )

}
export default RatingPicker