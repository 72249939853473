import {promiseRequest} from "../RequestBuilder";
import {CONTENT_SERVICE_URL} from "../Configs/RequestConfig";
import {isEmpty} from "lodash-es";

const useWCSRequests = () => {

    const sendPicturesRequest = async (
        offer: any,
        offerId: string,
        setImages: (imagesList: string[]) => void,
        setIsLoading?: any
    ) => {
        try {
            if (setIsLoading) {
                setIsLoading(true)
            }
            const request = await promiseRequest(`${CONTENT_SERVICE_URL}Pictures`, {
                Sections: ['Pictures'],
                Language: "PL",
                HotelXCode: offer?.Base?.XCode?.Id,
                Operator: offer?.Base?.Operator,
                offerId: offerId,
                HotelCode: `${offer?.Accommodation?.Code}`,
                PictureSizes: "full"
            }, 3);
            const {Sections} = request
            const {Pictures} = Sections
            const {PictureUrlFull} = Pictures
            if (!isEmpty(PictureUrlFull)) {
                setImages(PictureUrlFull)
            }
            return PictureUrlFull
        } catch (e) {
        } finally {

            if (setIsLoading) {
                setIsLoading(false)
            }
        }
    };

    return (
        {
            sendPicturesRequest
        }
    )
}
export default useWCSRequests