import StatusMainContent from "../../../StatusContent/StatusMainContent";

const Status = () => {

    return (
        <StatusMainContent
            isFullDetails={true}
        />
    )
}

export default Status