import {Styled} from "../../Offer.styles";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";

export enum buttonType {
    prev = 'previous',
    next = 'next'
}

type NavigationButtonProps = {
    type: buttonType
    showButton: boolean
    onClick: (event: any, type: buttonType) => void
}


const NavigationButton = (
    {
        type,
        showButton,
        onClick
    }: NavigationButtonProps
) => {

    const icon: any = {
        [buttonType.prev]: faChevronLeft,
        [buttonType.next]: faChevronRight
    }

    const onClickHandler = (event: any) => onClick(event, type)

    return (
        <Styled.Navigation__Button
            showButton={showButton}
            onClick={onClickHandler}
            type={type}
        >
            <Styled.Button__Icon
                icon={icon[type]}
            />
        </Styled.Navigation__Button>
    )
}
export default NavigationButton