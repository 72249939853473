import styled from "styled-components";
import Text from "../../../lib/UIBookBrd/Text";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Button from "../../../lib/UIBookBrd/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const News__Seller__Title__Label = styled(Text)`
  user-select: none;
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
`
const News__Seller__Desc__Label = styled(Text)`
  user-select: none;
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
`
const News_Seller__Holder = styled(FlexBox)<{ newHeight: number }>`
  max-width: 1100px;
  width: calc(100% - 20px);
  min-height: ${({newHeight}) => newHeight}px;
  border-bottom: 1px solid ${({theme}) => theme.borderColor};
  border-top: 1px solid ${({theme}) => theme.borderColor};
  padding: 20px 10px;
  flex-direction: column;
`
const Insert_Mail_Input_Holder = styled(FlexBox)`
  flex-direction: column;
  background-color: ${({theme}) => theme.backgroundColor};
  flex-grow: 1;
  width: 100%;
  margin-left: auto;
  max-width: 300px;
  border-radius: 5px;
`
const Controls__News__Seller = styled(FlexBox)`
  width: 100%;
  flex-grow: 1;
  flex-wrap: nowrap;
`
const Sign_Up_Button = styled(Button)`
  margin-top: auto;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 34px;
  min-width: 120px;
  margin-right: auto;
`
const Error__Message__Label = styled(Text)`
  height: 14px;
  color: ${({theme}) => theme.errorMessageBackgroundColor};
  font-size: 10px;
`
const Loader__Holder = styled(FlexBox)`
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Loading__Label = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin: 0 auto 20px auto;
`
const Approved__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.isCheckedSwitchGreen};
  margin: 10px auto;
  height: 50px;
  width: 50px;
`
const News__Seller__Input__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 20px auto;
  max-width: 1100px;
  width: 100%;
`
const Controls__UnSubscribe__Label = styled(Text)`
  margin: 0 auto;
  padding-top: 15px;
  text-decoration: underline;
  cursor: pointer;
`

export const Styled = {
    Controls__UnSubscribe__Label,
    News__Seller__Input__Holder,
    News__Seller__Title__Label,
    News__Seller__Desc__Label,
    News_Seller__Holder,
    Insert_Mail_Input_Holder,
    Controls__News__Seller,
    Sign_Up_Button,
    Error__Message__Label,
    Loader__Holder,
    Loading__Label,
    Approved__Icon
}