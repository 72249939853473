import React, {MouseEventHandler} from 'react';

import {Styled} from "./Calendar.styles";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";

type NavButtonProps = {
    disabled?: boolean
    onClick?: MouseEventHandler
}

const defaultProps = {
    disabled: false,
    onClick: undefined,
};

const NextBtn = ({disabled, onClick}: NavButtonProps) => (
    <Styled.NextButton
        onClick={onClick}
    >
        <Styled.FontAwesomeIcons
            icon={faArrowRight}
        />
    </Styled.NextButton>
);

const PrevBtn = ({disabled, onClick}: NavButtonProps) => (
    <Styled.PrevButton
        onClick={onClick}
    >
        <Styled.FontAwesomeIcons
            icon={faArrowLeft}
        />
    </Styled.PrevButton>
);

NextBtn.defaultProps = defaultProps;
PrevBtn.defaultProps = defaultProps;

export {
    PrevBtn,
    NextBtn,
};