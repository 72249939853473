import {Styled} from "../../../TripSearchBookingPage"
import CardNumberInput from "./Inputs/CardNumberInput";
import CardDateAndCVCInput from "./Inputs/CardDateAndCVCInput";
import CardHolderInput from "./Inputs/CardHolderInput";

const CardInputsHolder = () => {

    return (

        <Styled.Card__Inputs__Holder>
            <CardNumberInput/>
            <CardDateAndCVCInput/>
            <CardHolderInput/>
        </Styled.Card__Inputs__Holder>
    )
}
export default CardInputsHolder