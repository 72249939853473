import {Styled} from "./AboutView.styles"
import {useTranslation} from "react-i18next";
import {faStar} from "@fortawesome/pro-solid-svg-icons";
import FlexBox from "../../../../lib/UIBookBrd/FlexBox";
import GeneralValue from "./GeneralValue";

type AboutViewProps = {
    About: any
}


const AboutView = (
    {
        About
    }: AboutViewProps
) => {

    const {t} = useTranslation()

    const {
        Country,
        OperatingCountries,
        Description,
        BrdRating,
        EstablishmentDate,
        FavouriteMarkets

    } = About

    const {Ratings, Summary} = BrdRating

    return (
        <Styled.About__Page__Holder>
            <Styled.Part__Title>
                {t('lbl_general_operator_info')}
            </Styled.Part__Title>
            <Styled.General__Important__Information>
                <GeneralValue
                    title={t('lbl_establish_date')}
                    value={EstablishmentDate.split('-').reverse().join('.')}
                />
                <GeneralValue
                    title={t('lbl_country_of_orgin')}
                    value={Country}
                />
                <GeneralValue
                    title={t('lbl_operator_operating_countries')}
                    value={OperatingCountries?.join(', ')}
                />
                <GeneralValue
                    title={t('lbl_favourite_destinations')}
                    value={FavouriteMarkets?.slice(0, 5)?.map((item: any) => item?.name)?.join(', ')}
                />

            </Styled.General__Important__Information>
            <Styled.Desc__Review__Holder>
                <FlexBox style={{flexDirection: "column"}}>
                    <Styled.Part__Title>
                        {t('lbl_operator_description')}
                    </Styled.Part__Title>
                    <Styled.Description>
                        {Description}
                    </Styled.Description>
                </FlexBox>
                <FlexBox style={{flexDirection: "column", flexGrow: '1'}}>
                    <Styled.Part__Title>
                        {t('lbl_OfferReviewsRatingDetails')}
                    </Styled.Part__Title>
                    <Styled.Rating__Holder>
                        <Styled.Rating__Values__Holder>
                            <Styled.Rating__Names__Holder>
                                {Object.keys(Ratings).map((name: string) => (
                                    <Styled.Review__Type__Label
                                        key={`rating_${name}`}
                                    >
                                        {t(`lbl_rating_${name}`)}:
                                    </Styled.Review__Type__Label>
                                ))}
                            </Styled.Rating__Names__Holder>
                            <Styled.Rating__Lines___Holder>
                                {Object.keys(Ratings).map((name: string) => {
                                    const value = Ratings[name]
                                    return (
                                        <Styled.Rating__Box
                                            key={`rating_values_${name}`}
                                        >
                                            <Styled.Rating__Line__Number__Holder>
                                                <Styled.Rating__Number>
                                                    {value}
                                                </Styled.Rating__Number>
                                                <Styled.Rating__Line__Holder>
                                                    <Styled.Rating__Line
                                                        width={value * 10}
                                                    />
                                                    <Styled.Missing__Rating__Line
                                                        width={100 - value * 10}
                                                    />
                                                </Styled.Rating__Line__Holder>
                                            </Styled.Rating__Line__Number__Holder>
                                        </Styled.Rating__Box>
                                    )
                                })}
                            </Styled.Rating__Lines___Holder>
                        </Styled.Rating__Values__Holder>
                        <Styled.Summary__Rating__Holder>
                            <Styled.Summary__Number>
                                {t('lbl_summary')}
                            </Styled.Summary__Number>
                            <Styled.Summary__Number__Value>
                                {Summary}
                            </Styled.Summary__Number__Value>
                            <Styled.Rating__Star
                                icon={faStar}
                                style={{
                                    height: '18px',
                                    width: '18px',
                                    marginTop: '1px'
                                }}
                            />
                        </Styled.Summary__Rating__Holder>
                    </Styled.Rating__Holder>
                </FlexBox>
            </Styled.Desc__Review__Holder>
        </Styled.About__Page__Holder>
    )
}
export default AboutView