import useConfirmationPage from "../../../useConfirmationPage";
import {OrderStatusEnum} from "../../../../../lib/Types";
import {useTranslation} from "react-i18next";
import Approved from "./StatusTypes/Approved";
import NotFound from "./StatusTypes/NotFound";

type OrderStatusProps = {
    isFullDetails: boolean
}

const OrderStatus = (
    {
        isFullDetails
    }: OrderStatusProps
) => {

    const {
        orderNumber,
        orderStatus
    } = useConfirmationPage()

    const {t} = useTranslation()

    if (orderStatus as OrderStatusEnum === OrderStatusEnum.approved) {
        return (
            <Approved
                isFullDetails={isFullDetails}
            />
        )
    }

    if (orderStatus as OrderStatusEnum === OrderStatusEnum.notFound) {
        return (
            <NotFound/>
        )
    }


    return (
        <>
            {orderNumber}
        </>
    )
}
export default OrderStatus