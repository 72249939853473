import AlertBookBrd from "../../../lib/UIBookBrd/AlertBookBrd";
import React, {useLayoutEffect, useRef} from "react";
import {isMobileOnly} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {faWifiSlash} from "@fortawesome/pro-solid-svg-icons";
import {Styled} from "./AlertPositioner.styles";
import useNotifications from "../../../lib/UseHooksBookBrd/useNotifications";
import useLocalData from "../../../lib/UseHooksBookBrd/useSearchBaseData";


const AlertPositioner = () => {

    const {t} = useTranslation();

    const {isOnline} = useLocalData()

    const alertPositionerRef = useRef<any>(null)

    const {
        notifications,
        deleteNotification,
        getValidTopValue
    } = useNotifications()

    const scrollHandler = () => {
        alertPositionerRef.current.style.top = `${getValidTopValue(window)}px`
        alertPositionerRef.current.style.maxHeight = `calc(100vh - ${getValidTopValue(window)}px)`
    }

    useLayoutEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    return !isMobileOnly ? (
        <Styled.Alert__Positioner__Value__Holder
            getValidTopValue={getValidTopValue}
            ref={alertPositionerRef}
        >
            {!isOnline && (
                <AlertBookBrd
                    disableAnimation={true}
                    customIcon={faWifiSlash}
                    title={t('lbl_offline')}
                    type={'Warning'}
                    description={t('offline_message')}
                />
            )}
            {window.location.host === 'bookbrd.com' && (
                <AlertBookBrd
                    disableAnimation={true}
                    title={'BETA version'}
                    type={'Error'}
                    description={'Please be informed that following page is beta version, many features are disabled'}
                />
            )}
            {notifications?.notificationsList?.map((item: any, index: number) => {

                const onClose = () => {
                    deleteNotification()
                }

                return (
                    <AlertBookBrd
                        onClose={onClose}
                        customIcon={item?.customIcon}
                        title={item?.title}
                        type={item?.type}
                        description={item?.description}
                    />
                )
            })}


        </Styled.Alert__Positioner__Value__Holder>

    ) : null
}
export default AlertPositioner