import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Image from "../../lib/UIBookBrd/Image";

const Image__Gallery__Holder = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  user-select: none;
`

const Image__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
`
const All__Images__Holder = styled(FlexBox)`
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: column;
`
const MainImage = styled(Image)<{ isSmallHeight: boolean, isFocused: boolean }>`
  object-fit: cover;
  width: 100%;
  min-width: 100%;
  height: 600px;
  @media (max-width: 800px) {
    height: 75%;
  }
  display: ${({isFocused}) => isFocused ? 'flex' : "none"};
  height: ${({isSmallHeight}) => isSmallHeight ? `calc(100vh - 50px)` : 'undefined'};
`
const Controls__Holder = styled(FlexBox)<{ isShowSmallImages: boolean }>`
  flex-wrap: nowrap;
  width: 100%;
  min-width: 100%;
  margin-left: -100%;
  background-color: ${({isShowSmallImages}) => isShowSmallImages ? 'rgba(0, 0, 0, 0.60);' : ''};
`
const Control = styled(FlexBox)<{ type: 'previous' | 'next', isShowSmallImages: boolean }>`
  cursor: pointer;
  min-width: 10%;
  height: 100%;

  &:hover {
    background-color: ${({isShowSmallImages}) => !isShowSmallImages ? 'rgba(0, 0, 0, 0.60);' : ''};
  }
`
const navigation__Icon = styled(FontAwesomeIcon)<{ isShowSmallImages: boolean }>`
  margin: auto auto;
  width: 40px;
  height: 40px;
  color: ${({theme, isShowSmallImages}) => isShowSmallImages ? 'rgba(0, 0, 0, 0.60)' : theme.color};
`
const Close__Icon = styled(FontAwesomeIcon)`
  margin: 5px 5px 5px auto;
  width: 17.5px;
  color: ${({theme}) => theme.color};
  cursor: pointer;
  height: 30px;
`
const Small__Images__Holder = styled(FlexBox)<{ isSmallHeight: boolean, isShowSmallImages: boolean }>`
  margin-top: ${({isSmallHeight, isShowSmallImages}) => isSmallHeight ? isShowSmallImages ? '-110px' : 0 : 0};
  max-width: 100%;
  overflow-x: scroll;
  flex-wrap: nowrap;
  overflow-y: hidden;
  scroll-behavior: smooth;
  padding-top: ${({isSmallHeight}) => !isSmallHeight ? '5px' : '0'};
  height: ${({isSmallHeight, isShowSmallImages}) => isSmallHeight ? isShowSmallImages ? '108px' : 0 : '110px'};
`
const Small__Image = styled(Image)<{ isFocused: boolean }>`
  object-fit: cover;
  outline: ${({theme, isFocused}) => isFocused ? `1px solid ${theme.color}` : '0'};
  height: 100px;
  border-radius: 5px;
  margin: 4px 2px;
  min-width: 150px;
`
const Controls__Separator = styled(FlexBox)`
  width: 80%;
`
const Show__Small__Images__Button = styled(FlexBox)<{ isShowSmallImages: boolean }>`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid ${({theme}) => theme.color};
  padding: 5px 15px;
  margin: auto auto 0 auto;
  border-bottom: 0;
  background-color: ${({theme}) => theme.backgroundColor};
  margin-bottom: ${({isShowSmallImages}) => isShowSmallImages ? '110px' : 'undefined'};
`
const Show__Images__Control__Image = styled(FontAwesomeIcon)`
  width: 40px;
  height: 30px;
  color: ${({theme}) => theme.color};
`

export const Styled = {
    All__Images__Holder,
    Image__Holder,
    MainImage,
    Controls__Holder,
    Control,
    navigation__Icon,
    Close__Icon,
    Image__Gallery__Holder,
    Small__Images__Holder,
    Small__Image,
    Controls__Separator,
    Show__Small__Images__Button,
    Show__Images__Control__Image

}