import {Styled} from "../ManageContent.styles";
import {useTranslation} from "react-i18next";
import useConfirmationPage from "../../useConfirmationPage";

const Header = () => {

    const {t} = useTranslation()

    const {
        orderNumber,
    } = useConfirmationPage()

    return (
        <Styled.Manage__Content__Title>
            {`${t('lbl_order_code')}: ${orderNumber}`}
        </Styled.Manage__Content__Title>
    )
}
export default Header