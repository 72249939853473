import {Styled} from "../../../TripSearchBookingPage";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import {useTranslation} from "react-i18next";
import CardInputsHolder from "./CardInputsHolder";
import CardGraph from "./CardGraph";

const CardPayment = () => {

    const {t} = useTranslation();

    return (
        <FlexBox
            style={{
                maxWidth: '400px',
                padding: '0 10px',
                flexWrap: 'nowrap',
                width: 'calc(100% - 20px)',
                maxHeight: '600px',
                flexDirection: 'column'
            }}
        >
            <Styled.Section__Type__Label
                style={{
                    marginBottom: '18px'
                }}

            >
                {t('lbl_card_payment')}
            </Styled.Section__Type__Label>
            <CardGraph/>
            <CardInputsHolder/>
        </FlexBox>
    )
}
export default CardPayment
