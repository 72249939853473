import BrdLoader from "../../../../../lib/UIBookBrd/BrdLoader";
import {Styled} from "./LoadingResults.styles";
import {useTranslation} from "react-i18next";
import useBookingFunctions from "../../functions";
import {faCheckCircle, faCircleX} from "@fortawesome/pro-light-svg-icons";

const LoadingResults = () => {


    const {state} = useBookingFunctions()

    const {t} = useTranslation()


    if (state?.finalResult === 'approved') {
        return (
            <Styled.Loading__Results__Holder>
                <Styled.Loading__Title>
                    {t('lbl_transaction_approved')}
                </Styled.Loading__Title>
                <Styled.Approved__Result__Icon
                    icon={faCheckCircle}
                />
                <Styled.Loading__Desc>
                    {t('lbl_please_wait_redirect_transaction_complete')}
                </Styled.Loading__Desc>
            </Styled.Loading__Results__Holder>
        )
    }

    if (state?.finalResult === 'declined') {
        return (
            <Styled.Loading__Results__Holder>
                <Styled.Loading__Title>
                    {t('lbl_card_declined')}
                </Styled.Loading__Title>
                <Styled.Declined__Result__Icon
                    icon={faCircleX}
                />
                <Styled.Loading__Desc>
                    {t('lbl_declined_card_desc')}
                </Styled.Loading__Desc>
            </Styled.Loading__Results__Holder>
        )
    }
    
    if (state?.isFinalising) {
        return (
            <Styled.Loading__Results__Holder>
                <Styled.Loading__Title>
                    {t('lbl_progressing_data')}
                </Styled.Loading__Title>
                <BrdLoader
                    style={{
                        margin: '10px auto 10px auto'
                    }}
                />
                <Styled.Loading__Desc>
                    {t('lbl_interesting_fact_0')}
                </Styled.Loading__Desc>
            </Styled.Loading__Results__Holder>
        )
    }

    return null


}
export default LoadingResults