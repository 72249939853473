import Drawer from "../../../lib/UIBookBrd/Drawer";
import useTylerBot from "../Handlers/Hook";
import BotChatContent from "../BotChatContent";
import BotControls from "../BotControls";
import {useTranslation} from "react-i18next";

const DrawerHolder = () => {

    const {
        isOpen,
        toggleContainer
    } = useTylerBot()

    const {t} = useTranslation()

    return (
        <Drawer
            isOpen={isOpen}
            title={t('lbl_tyler_title')}
            onClose={toggleContainer}
            controls={<BotControls/>}
            contentStyles={{
                height: '100vh',
            }}
        >
            <BotChatContent/>
        </Drawer>
    )
}

export default DrawerHolder