import {
    faAirConditioner,
    faArrowRightToCity,
    faBicycle,
    faChampagneGlasses,
    faChildReaching,
    faCity,
    faDogLeashed,
    faDumbbell,
    faFamily,
    faFireplace,
    faGlobe,
    faGolfClub,
    faGolfFlagHole,
    faGrill,
    faMarsDouble,
    faMaskSnorkel,
    faMountain,
    faMountains,
    faParking,
    faPeopleSimple,
    faPersonRunning,
    faPersonSkiLift,
    faPersonSnowboarding,
    faPersonSwimming,
    faPersonWalking,
    faSailboat,
    faSkiBoot,
    faSpa,
    faTennisBall,
    faTowerControl,
    faTreePalm,
    faUmbrellaBeach,
    faUserCowboy,
    faVolleyball,
    faWashingMachine,
    faWater,
    faWaterArrowDown,
    faWaterLadder,
    faWheelchair,
    faWifi
} from "@fortawesome/pro-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {memo, useEffect, useRef, useState} from "react";
import {floor, includes, isEmpty, isEqual} from "lodash-es";
import {Styled} from "./AccommodationAttributes.styles";
import Tooltip from "../../lib/UIBookBrd/Tooltip";
import Text from "../../lib/UIBookBrd/Text";

type AccommodationAttributesProps = {
    attributes: string[],
    maxNumberOfRows?: number
}
const defaultProps = {
    maxNumberOfRows: null,

}

export const listOfAvailableAttributes = (t: any) => [
    {
        icon: faUmbrellaBeach,
        desc: t('lbl_directly_on_beach'),
        value: 'location_directly_on_the_beach'
    },
    {
        icon: faWifi,
        desc: t('lbl_free_wifi'),
        value: 'facility_free_wifi'
    },
    {
        icon: faTowerControl,
        desc: t('lbl_near_airport'),
        value: 'location_near_airport'
    },
    {
        icon: faParking,
        desc: t('lbl_parking'),
        value: 'facility_parking'
    },
    {
        icon: faFamily,
        desc: t('lbl_family_friendly'),
        value: 'facility_for_families_with_childrens'
    },
    {
        icon: faAirConditioner,
        desc: t('lbl_air_conditioner'),
        value: 'facility_air_conditioning'
    },
    {
        icon: faPersonRunning,
        desc: t('lbl_sports'),
        value: 'activity_sport'
    },
    {
        icon: faWaterLadder,
        desc: t('lbl_outdoor_pool'),
        value: 'facility_outdoor_pool'
    },
    {
        icon: faPersonSwimming,
        desc: t('lbl_indoor_pool'),
        value: 'facility_indoor_pool'
    },
    {
        icon: faDumbbell,
        desc: t('lbl_gym_fitness'),
        value: 'facility_fitness_or_gym'
    },
    {
        icon: faFireplace,
        desc: t('lbl_fireplace'),
        value: 'facility_fireplace'
    },
    {
        icon: faMarsDouble,
        desc: t('lbl_gay_friendly'),
        value: 'facility_gay_friendly'
    },
    {
        icon: faSpa,
        desc: t('lbl_wellness'),
        value: 'facility_wellness'
    },
    {
        icon: faPeopleSimple,
        desc: t('lbl_adult_only'),
        value: 'facility_for_adult'
    },
    {
        icon: faGrill,
        desc: t('lbl_barbecue'),
        value: 'facility_barbecue'
    },
    {
        icon: faArrowRightToCity,
        desc: t('lbl_near_to_city_center'),
        value: 'location_near_the_center'
    },
    {
        icon: faCity,
        desc: t('lbl_city_break'),
        value: 'location_city_breaks'
    },
    {
        icon: faMountains,
        desc: t('lbl_mountains'),
        value: 'location_mountains'
    },
    {
        icon: faMountain,
        desc: t('lbl_near_slope'),
        value: 'location_near_the_slope'
    },
    {
        icon: faTreePalm,
        desc: t('lbl_exotic'),
        value: 'location_exotic_offers'
    },
    {
        icon: faPersonSkiLift,
        desc: t('lbl_ski_resort'),
        value: 'location_ski_resorts'
    },
    {
        icon: faWater,
        desc: t('lbl_lakes'),
        value: 'location_lakes'
    },
    {
        icon: faWashingMachine,
        desc: t('lbl_home_appliances'),
        value: 'facility_home_appliances'
    },
    {
        icon: faChildReaching,
        desc: t('lbl_kinder_garden'),
        value: 'facility_kindergarten'
    },
    {
        icon: faWaterArrowDown,
        desc: t('lbl_water_slides'),
        value: 'facility_water_slides'
    },
    {
        icon: faWheelchair,
        desc: t('lbl_wheelchair_friendly'),
        value: 'facility_facilities_for_disabled'
    },
    {
        icon: faDogLeashed,
        desc: t('lbl_pet_friendly'),
        value: 'facility_pets'
    },
    {
        icon: faGlobe,
        desc: t('lbl_internet_access'),
        value: 'facility_internet_access'
    },
    {
        icon: faChampagneGlasses,
        desc: t('lbl_night_life'),
        value: 'facility_night_life'
    },
    {
        icon: faSkiBoot,
        desc: t('lbl_winter_equ_rental'),
        value: 'facility_winter_equipment_rental'
    },
    {
        icon: faBicycle,
        desc: t('lbl_bicycles'),
        value: 'activity_bicycles'
    },
    {
        icon: faTennisBall,
        desc: t('lbl_tennis'),
        value: 'activity_tennis'
    },
    {
        icon: faGolfClub,
        desc: t('lbl_golf'),
        value: 'activity_golf'
    },
    {
        icon: faGolfFlagHole,
        desc: t('lbl_mini_golf'),
        value: 'activity_minigolf'
    },
    {
        icon: faVolleyball,
        desc: t('lbl_water_sports'),
        value: 'activity_water_sports'
    },
    {
        icon: faMaskSnorkel,
        desc: t('lbl_diving'),
        value: 'activity_diving'
    },
    {
        icon: faUserCowboy,
        desc: t('lbl_riding'),
        value: 'activity_riding'
    },
    {
        icon: faPersonWalking,
        desc: t('lbl_nordic_walking'),
        value: 'activity_nordic_walking'
    },
    {
        icon: faSailboat,
        desc: t('lbl_sailing'),
        value: 'activity_sailing'
    },
    {
        icon: faPersonSnowboarding,
        desc: t('lbl_winter_sports'),
        value: 'activity_winter_sports'
    },


]


const AccommodationAttributes = (
    {
        attributes,
        maxNumberOfRows
    }: AccommodationAttributesProps
) => {

    const {t} = useTranslation();

    const [filteredAttributes, setFilteredAttributes] = useState<any>({visibleAttributes: listOfAvailableAttributes(t)?.filter((item: any) => includes(attributes, item.value))})

    const elementRef = useRef<any>(null)

    const getMaxTagsNumber = (width: number) => {
        const tagWidth = 34
        const numberOfRows = maxNumberOfRows !== null ? maxNumberOfRows as number : 3;
        const availableSpace = (width * numberOfRows) - tagWidth
        return floor(availableSpace / tagWidth) !== -1 ? floor(availableSpace / tagWidth) : 15
    }

    const changeElementValue = (list: any[], width: number) => {

        const maxTagNumber = getMaxTagsNumber(width)

        const visibleAttributes: any[] = []

        const hiddenAttributes: any[] = []

        list?.map((attribute: any, index: number) => {
            if (index + 1 < maxTagNumber) {
                visibleAttributes.push(attribute)
            } else {
                hiddenAttributes.push(attribute)
            }
        })
        if (!isEqual(visibleAttributes, filteredAttributes?.visibleAttributes) || !isEqual(hiddenAttributes, filteredAttributes?.hiddenAttributes)) {
            setFilteredAttributes({
                visibleAttributes,
                hiddenAttributes
            })
        }

    }


    useEffect(() => {
        const handleResize = () => {
            if (elementRef.current) {
                const width = elementRef?.current?.offsetWidth;
                const filteredList = listOfAvailableAttributes(t)?.filter((item: any) => includes(attributes, item.value))
                changeElementValue(filteredList, width)
            }
        };

        handleResize()
        window.addEventListener(('resize'), handleResize)
        return () => {
            window.removeEventListener(('resize'), handleResize)
        };
    }, [attributes])

    return (
        <Styled.Attributes__Component>
            <Styled.Attributes__Holder
                rowsNumber={maxNumberOfRows !== null ? maxNumberOfRows as number : 3}
                ref={elementRef}
            >
                {filteredAttributes.visibleAttributes?.map((attribute: any) => {
                    return (
                        <Tooltip
                            content={attribute?.desc}
                        >
                            <Styled.Attribute>
                                <Styled.Attribute__Icon
                                    icon={attribute?.icon as any}
                                />
                            </Styled.Attribute>
                        </Tooltip>
                    )
                })}
                {!isEmpty(filteredAttributes?.hiddenAttributes) && filteredAttributes?.hiddenAttributes?.length !== 0 && (
                    <Tooltip
                        content={
                            <Text style={{fontSize: '12px'}}>
                                {filteredAttributes?.hiddenAttributes?.map((item: any) => item.desc)?.join(', ')}
                            </Text>
                        }
                    >
                        <Styled.Attribute>
                            <Styled.Hidden_Attributes__Number>
                                {filteredAttributes?.hiddenAttributes?.length}+
                            </Styled.Hidden_Attributes__Number>
                        </Styled.Attribute>
                    </Tooltip>
                )}
            </Styled.Attributes__Holder>
        </Styled.Attributes__Component>
    )
}
AccommodationAttributes.defaultProps = defaultProps;
export default memo(AccommodationAttributes)
