import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import Backend from 'i18next-http-backend';
import {includes} from "lodash-es";
import {Available_Languages} from "../lib/PageConfig";


const loginLanguage = JSON.parse(localStorage.getItem('LocalSettings') as any)?.SelectedLanguage ?
    JSON.parse(localStorage.getItem('LocalSettings') as any)?.SelectedLanguage :
    includes(Available_Languages, window.navigator.language.split('-')[0]) ? window.navigator.language.split('-')[0] : 'en';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json`,
        },
        lng: loginLanguage,
        fallbackLng: false,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n