import React from "react";
import {Styled} from "./TypeOfSearchSelect.styles";
import {useTranslation} from "react-i18next";
import TypeOfSearchIcon from "./TypeOfSearchIcon";
import {useLocation} from "react-router-dom";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import useNavigation from "../../lib/UseHooksBookBrd/useNavigation";

type TypeOfSearchProps = {
    label: string
    navigation: string
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void
    ref?: any
    style?: any
    children?: React.ReactNode
}
const defaultProps = {
    onClick: undefined,
    onMouseOver: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    ref: undefined,
    children: undefined,
}

const TypeOfSearchSelect = ({label, navigation, ref, ...props}: TypeOfSearchProps) => {
    const {t} = useTranslation();
    const reRoute = useNavigation()
    const location = useLocation();

    return (
        <Styled.TypeOfSearchHolder
            isSelected={location.pathname === navigation}
            ref={ref}
            {...props}
        >
            <FlexBox
                style={{
                    flexWrap: 'nowrap'
                }}
                onClick={() => {
                    reRoute(navigation)
                }}>
                <TypeOfSearchIcon label={label}/>
                <Styled.Type__Label>
                    {t(`${label}`)}
                </Styled.Type__Label>

            </FlexBox>
        </Styled.TypeOfSearchHolder>
    )
}
TypeOfSearchSelect.defaultProps = defaultProps;

export default TypeOfSearchSelect