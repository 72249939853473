import {Styled} from "../OperatorDetails.styles";

type HeaderProps = {
    OperatorName: any
}

const Header = (
    {
        OperatorName
    }: HeaderProps
) => {


    return (
        <Styled.Operator__Header>
            <Styled.Operator__Name__Title>
                {OperatorName.split('_').join(' ')}
            </Styled.Operator__Name__Title>
        </Styled.Operator__Header>
    )
}
export default Header