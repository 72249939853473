import styled from "styled-components";
import Text from "../../../lib/UIBookBrd/Text";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Image from "../../../lib/UIBookBrd/Image";

const SearchTripLowerPartHolder = styled(FlexBox)`
  width: 100%;
  height: auto;
  flex-direction: column
`
const Main__Label = styled(Text)`
  padding: 5px 0;
  margin-top: 15px;
  margin-left: 10px;
  user-select: none;
  font-size: 22px;
  font-weight: bold;
`
const Recommended__Countries__Holder = styled.div`
  position: relative;
  width: calc(100% - 20px);
  padding: 0 10px;
  display: none;
  @media (max-width: 700px) {
    display: initial;
  }
`
const Next__Button = styled(FlexBox)`
  position: absolute;
  width: 50px;
  z-index: 101;
  height: 50px;
  border-radius: 50%;
  margin-top: 140px;
  border: 1px solid ${({theme}) => theme.borderColor};
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.optionHoverColor};
  }

  right: 0px;
  @media (min-width: calc(1100px + 2vw)) {
    margin-left: 1030px;
  }

`
const Previous__Button = styled(FlexBox)`
  position: absolute;
  width: 50px;
  z-index: 101;
  height: 50px;
  border-radius: 50%;
  margin-top: 140px;
  border: 1px solid ${({theme}) => theme.borderColor};
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.optionHoverColor};
  }

`
const Navigation__Icon = styled(FontAwesomeIcon)`
  width: 30px;
  height: 30px;
  margin: auto;
  color: ${({theme}) => theme.color};
`
const Country__Image = styled(Image)`
  user-select: none;
  -webkit-user-drag: none;
  min-width: 100%;
  transition: scale 200ms;
  height: 100%;
`

const Country__Advertisement = styled(FlexBox)`
  user-select: none;
  flex-wrap: nowrap;
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;


  &:hover ${Country__Image} {
    scale: 1.05;
  }
`
const Country__Label = styled(Text)`
  z-index: 100;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 21px;
  font-weight: bold;
  text-shadow: 2px 2px 2px ${({theme}) => theme.backgroundColor};;
`

const Country__Flag = styled.img`
  user-select: none;
  -webkit-user-drag: none;
  margin-right: 2px;
  height: 17px;
  width: 28px;
`
const Skeleton__Recommended__Offer = styled(FlexBox)`
  border-radius: 5px;
  flex-direction: column;
  margin: 2px 4px;
  box-shadow: 0px 0px 6px 0px ${({theme}) => theme.isDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.5)'};
  background-color: ${({theme}) => theme.backgroundColor};
  min-width: 300px;
  width: 300px;
  height: 340px;
`
const Advertisment__Holder = styled.div`
  width: calc(100% - 20px);
  margin: 0 10px;
`
const Wide__Screen__Content__Holder = styled(FlexBox)`
  flex-direction: initial;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0 10px;
  gap: 10px;
  margin-bottom: 10px;
  @media (max-width: 700px) {
    display: none;
  }
`
const Big__Country__Offer__Holder = styled(FlexBox)`
  width: calc(50vw - 17px);
  height: calc(25vw - 15px);
  @media (min-width: 1100px) {
    width: calc(50% - 8px);
    height: 260px
  }
`
const Regular__Country__Offer__Holder = styled(FlexBox)`
  width: calc(33vw - 11px);
  height: calc(16vw - 11px);;
  @media (min-width: 1100px) {
    width: calc(33% - 5px);
    height: 165px
  }
`
const Recommended__Offer__Types__Holder = styled.div`
  width: calc(100% - 20px);
  position: relative;
  margin: 0 10px;
  padding-top: 5px;
`
const Recommended__Offer__Type__Image = styled(Image)`
  width: 100%;
  height: 115px;
  overflow: hidden;
  border-radius: 5px;
  transition: 200ms;
`
const Recommended__Offer__Type__Label = styled(Text)`
  font-size: 15px;
  margin: 4px 0 6px 0;
  font-weight: bold;
  transition: 200ms;
`
const Recommended__Offer__Type__Price = styled(Text)`
  font-size: 12px;
  text-align: right;
  font-weight: bold;
  transition: 200ms;
`

const Recommended__Offer__Box = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  &:hover ${Recommended__Offer__Type__Image} {
    transform: scale(1.05);
  }
`
const Recommended__Hotel__Holder = styled.div`
  position: relative;
  width: calc(100% - 20px);
  margin: 0 10px;
  padding-top: 5px;
`


export const Styled = {
    Recommended__Hotel__Holder,
    Recommended__Offer__Type__Price,
    Recommended__Offer__Type__Label,
    Recommended__Offer__Type__Image,
    Recommended__Offer__Box,
    Recommended__Offer__Types__Holder,
    Regular__Country__Offer__Holder,
    Big__Country__Offer__Holder,
    Wide__Screen__Content__Holder,
    Advertisment__Holder,
    Skeleton__Recommended__Offer,
    SearchTripLowerPartHolder,
    Main__Label,
    Recommended__Countries__Holder,
    Previous__Button,
    Next__Button,
    Country__Advertisement,
    Navigation__Icon,
    Country__Label,
    Country__Image,
    Country__Flag
}