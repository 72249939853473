import {Styled} from "./CustomSections.styles"
import React from "react";
import {isEmpty} from "lodash-es";

type SectionHolderProps = {
    label?: string
    description?: string
    children?: React.ReactNode
}
const defaultProps = {
    label: '',
    description: '',
    children: undefined
}
const SectionHolder = (
    {
        label,
        description,
        children
    }: SectionHolderProps
) => {
    return (
        <Styled.ObjectFirstLook__Section__Holder>
            <Styled.SectionDescription__Texts__Holder>
                <Styled.SectionDescription__Label>
                    {label}
                </Styled.SectionDescription__Label>
                {!isEmpty(description) && (
                    <Styled.SectionDescription__Description>
                        {description}
                    </Styled.SectionDescription__Description>
                )}
            </Styled.SectionDescription__Texts__Holder>
            <Styled.SectionDescription__Children__Holder>
                {children}
            </Styled.SectionDescription__Children__Holder>
        </Styled.ObjectFirstLook__Section__Holder>
    )

}

SectionHolder.defaultProps = defaultProps;
export default SectionHolder