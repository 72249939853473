import {useEffect} from "react";
import useSupport from "../../Hook/useSupport";
import {Styled} from "../../ContactPage.styles";
import Option from "./Option";

const FaqContent = () => {

    const {
        faq,
        fetchFaqList
    } = useSupport()

    useEffect(() => {
        if (!faq?.requestSent) {
            fetchFaqList()
        }
    }, [])


    return (
        <Styled.Faq__Holder>
            {faq?.list?.map((option: any) => (
                    <Option
                        option={option}
                    />
                )
            )}
        </Styled.Faq__Holder>
    )
}

export default FaqContent