import {Styled} from "../../ContactPage.styles";
import {useTranslation} from "react-i18next";
import {supportPageType} from "../../../../lib/Types";

type HeaderProps = {
    type: supportPageType
}

const Header = (
    {
        type
    }: HeaderProps
) => {

    const {t} = useTranslation()

    const title: any = {
        [supportPageType.main]: 'lbl_support_title',
        [supportPageType.support]: 'lbl_support',
        [supportPageType.faq]: 'lbl_faq_full'
    }
    const description: any = {
        [supportPageType.main]: 'lbl_contact_description',
        [supportPageType.support]: 'lbl_support_description',
        [supportPageType.faq]: 'lbl_faq_description'
    }

    return (
        <>
            <Styled.Contact__Page__Title>
                {t(title[type])}
            </Styled.Contact__Page__Title>
            <Styled.Contact__Page__Title__Description>
                {t(description[type])}
            </Styled.Contact__Page__Title__Description>
        </>
    )
}

export default Header