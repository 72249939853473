import {useContext} from "react";
import {Customer_Support} from "../../../lib/Context/Context";


const dummyFaqList: any = [
    {
        question: 'What is BookBrd?',
        answer: 'BookBrd is advanced tool which makes booking your dream vacations much easier than before '
    },
    {
        question: 'Why is my offer non refundable?',
        answer: "Your offer is non-refundable because either due to its proximity to the outbound date or because it is inherently non-refundable."
    },
]

const dummyMailGuideList: any = [
    {
        title: 'Values',
        list: []
    }
]

const mailStructure = {
    subject: {
        value: 'Issue with changing data of participants',
        explanation: 'Make subject short but in point'
    },
    title: 'Dear BookBrd team',
    greetings: {
        greetings: "Best regards",
        title: 'Mr Chris Brown',
        explanation: "Introduce yourself so we will know who are we talking with"
    },
    instructions: {
        shortProblem: {
            value: "I'm having issue with managing my booking, when im trying to change participant value it's returning error 'Action unavailable for this booking'",
            explanation: 'Simple explanation of problem'
        },
        moreDetails: {
            value: "My order number is 0000000000 and I'm trying to change age of My son John from 11 years to 14 years because I have chosen wrong date.",
            explanation: 'Write us details so that we will be able to help'
        },
        solution: {
            value: "Can you please change the birthdate of my son John from 22.05.2013 to 22.05.2009?",
            explanation: "Suggest your solution for problem"
        },
    }

}

const useSupport = () => {

    const [state, setState] = useContext<any>(Customer_Support)


    const fetchFaqList = async () => {
        setState((state: any) => ({
            ...state,
            faq: {
                ...state?.faq,
                requestSent: true,
                isLoading: false,
                list: dummyFaqList
            }
        }))
    }

    const fetchMailGuide = async () => {
        if (!state.mailGuide.requestSent) {
            setState((state: any) => ({
                ...state,
                mailGuide: {
                    ...state?.mailGuide,
                    isLoading: true,
                    requestSent: true
                }
            }))
            setTimeout(() => {
                setState((state: any) => ({
                    ...state,
                    mailGuide: {
                        ...state?.mailGuide,
                        data: {
                            pointsList: dummyMailGuideList,
                            mailStructure: mailStructure
                        },
                        isLoading: false,
                    }
                }))
            }, 200)
        }

    }

    return {
        fetchMailGuide,
        fetchFaqList,
        mailGuide: state?.mailGuide,
        faq: state?.faq
    }
}


export default useSupport