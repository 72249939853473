import {useContext} from "react";
import {Local_Data} from "../Context/Context";

const useLocalData = () => {

    const [localDataValues, setLocalDataValues] = useContext<any>(Local_Data)

    const onIsLoadingChange = (bool: boolean) => {
        setLocalDataValues((state: any) => ({
            ...state,
            isLoading: bool
        }))
    }

    const toggleDisableScroll = (bool: boolean) => {
        setLocalDataValues((state: any) => ({
            ...state,
            disableScroll: bool
        }))
    }

    return ({
        disableScroll: localDataValues?.disableScroll,
        allData: localDataValues,
        isLoading: localDataValues?.isLoading,
        isOnline: localDataValues?.isOnline,
        searchBaseData: localDataValues?.SearchBaseData,
        toggleDisableScroll,
        onIsLoadingChange
    })
}


export default useLocalData