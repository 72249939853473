import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";
import Field from "../../lib/UIBookBrd/SelectBookBrd/Field/Field";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isMobileOnly} from "react-device-detect";
import CheckBox from "../../lib/UIBookBrd/CheckBox";

const Content = styled.div`
  min-height: 200px;
  max-width: 1000px;
`
const Row = styled.div`
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
`
const NoOptionsLabelHolder = styled.div`
  display: flex;
  align-items: center;
  min-height: 200px;
  justify-content: center;
  width: 100%;
`
const Column = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: ${(props: { width: any; }) => props.width}px;

`
const Option = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin-bottom: 6px;
`
const SubOptionsHolder = styled.div`
  margin-left: 18px;
`
const FavouriteIconHolder = styled.div`
  cursor: pointer;
  margin-right: 2px;
  width: auto;
  height: auto;
  color: ${(props: { isMobileOnly: any; theme: any }) => props.isMobileOnly ? props.theme.color : 'transparent'};
`
const OptionHolder = styled.div`
  display: flex;
  padding: 2px 0;

  &:hover ${FavouriteIconHolder} {
    transform: scale(1.05);
    color: ${(props: { theme: any; }) => props.theme.color};
  }

`
const OpenIconHolder = styled.div`
  margin-left: auto;
  color: ${(props: { theme: any; }) => props.theme.color};
`
const CheckBoxPosition = styled.div`
  width: calc(100% - 26px);
  max-width: calc(100% - 30px);
  padding: ${(props: { isMobileOnly: any; }) => props.isMobileOnly ? '2px 3px' : '0'}
`
const CustomCheckBox = styled(CheckBox)`
  max-width: 100%
`

const Header = styled.div`
  width: calc(100% - 20px);
  z-index: 1000;
  background-color: ${({theme}) => theme.backgroundColor};
  position: fixed;
  display: flex;
  flex-direction: ${isMobileOnly ? 'column' : 'initial'};
  padding: 5px 10px;
  border-bottom: 1px solid;
  border-color: ${({theme}) => theme.separatorColor};
`
const SearchInputHolder = styled.div`
  padding: 2px 0;
  display: flex;
  flex-grow: 1;
  max-width: ${(props: { isMobileOnly: any; }) => props.isMobileOnly ? undefined : '300px'}
`
const OptionsHolder = styled.div`
  padding-top: 55px;
  display: flex;
  flex-direction: column;
`
const FavouriteHolder = styled(FlexBox)`
  flex-direction: column;
  border-bottom: 1px solid ${(props: { theme: any; }) => props.theme.separatorColor};
`
const FavouriteLabel = styled(Text)`
  padding: 5px 10px;
  font-size: 16px;
`
const FavouriteOptions = styled.div`
  display: grid;
  gap: 0 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  border-bottom: 1px solid #dedede;
  margin-bottom: 0
`
const FieldForBasicPicker = styled(Field)`
  background-color: ${(props: { theme: any; }) => props.theme.backgroundColor} !important;
`

const FavouriteIcons = styled(FontAwesomeIcon)`
  height: 100%;
  margin-bottom: 1px;
  width: ${isMobileOnly ? '20px' : '15px'};
  color: ${(props: { theme: any; isFavouriteProp: any }) => props.isFavouriteProp ? props.theme.color : undefined};

`
const LoadingMode = styled(FlexBox)`
  min-height: ${isMobileOnly ? 'unset' : '300px'};
  height: ${isMobileOnly && '100%'};
`
const Contients__Holder = styled(FlexBox)`
  gap: 10px;
  padding: 5px;
`

const Continent__Holder = styled(FlexBox)<{ isOneBeforeLast: boolean, isLast: boolean }>`
  min-width: calc(100% / 4 - 30px / 4);
  max-width: calc(100% / 4 - 30px / 4);
  box-sizing: border-box;
  flex-wrap: nowrap;
  border: 1px solid ${({theme}) => theme.color};
  cursor: pointer;
  border-radius: 5px;
  height: 70px;
  padding: 5px;
  margin: ${({isOneBeforeLast, isLast}) => isOneBeforeLast ? '0 0 0 auto' : isLast ? '0 auto 0 0' : undefined};
  @media (max-width: 900px) {
    min-width: calc(100% / 3 - 20px / 3);
    max-width: calc(100% / 3 - 20px / 3);
  }
  @media (max-width: 600px) {
    min-width: calc(100% / 2 - 10px / 2);
    max-width: calc(100% / 2 - 10px / 2);
  }
  @media (max-width: 400px) {
    min-width: calc(100%);
  }

  &:hover {
    background-color: ${({theme}) => theme.optionHoverColor};
  }

`
const Contient__Image = styled.img`
  width: 60px;
  max-height: 60px;
`
const Continent__Label = styled(Text)`
  font-size: 15px;
  font-weight: bold;
  margin: auto 0 auto 10px;
`
const Continent__Section__Label = styled(Text)`
  font-weight: bold;
  margin-left: 5px;
  padding: 2px 0;

`
const Go__Back__Icon = styled(FontAwesomeIcon)`
  color: ${({theme}) => theme.color};
  margin-left: 5px;
  height: 18px;
  width: 14px;
`
const Go__Back__Label = styled(Text)`
  font-weight: bold;
`
const Contient__Section__Label = styled(Text)`
  margin: 0 auto;
  padding: 5px 0 15px 0;
  font-size: 20px;
  font-weight: bold;

`
export const Styled = {
    Continent__Holder,
    CustomCheckBox,
    LoadingMode,
    FieldForBasicPicker,
    FavouriteHolder,
    FavouriteOptions,
    FavouriteLabel,
    Row,
    Header,
    SearchInputHolder,
    OptionsHolder,
    NoOptionsLabelHolder,
    Column,
    Option,
    SubOptionsHolder,
    CheckBoxPosition,
    OptionHolder,
    FavouriteIconHolder,
    FavouriteIcons,
    OpenIconHolder,
    Contients__Holder,
    Content,
    Contient__Image,
    Continent__Label,
    Continent__Section__Label,
    Go__Back__Icon,
    Go__Back__Label,
    Contient__Section__Label

}