import {BOARD_FIELD, DURATION_FIELD, RATING_FIELD, TO_FIELD} from "../DefaultFormikValue/DefaultSearchFormValues";


export const CONTENT_SERVICE_URL_DEV = 'https://merlinx-cloud.com/wcs/'

export const CONTENT_SERVICE_URL = CONTENT_SERVICE_URL_DEV ?? 'https://webcsnmc.merlinx.pl/';


export const V5_SEARCH = 'https://mwsv5nmc.merlinx.pl/v5/data/travel/search'

export const V5_DETAILS = 'https://mwsv5nmc.merlinx.pl/v5/data/travel/details?Base.OfferId='

export const V5_PROD = 'https://mwsv5pro.merlinx.eu/v5/data/travel/search'

export const TRIP_SEARCH_CONFIG_SECTIONS = [
    TO_FIELD,
    DURATION_FIELD,
    BOARD_FIELD,
    RATING_FIELD
]

export const PROJECT_ID = 'bookbrd'

