import {Styled} from "../SearchTripLowerPart.styles";
import {useTranslation} from "react-i18next";
import OfferAlternatives
    from "../../../../Pages/TripSearch/TripOfferDetails/DedicatedComponent/OfferDetailsHolder/OfferAlternatives";


type RecommendedOfferProps = {
    customTitle?: string
    list: any[]
    isLoadingRecommendedOffers: boolean
}

const RecommendedOffer = (
    {
        customTitle,
        isLoadingRecommendedOffers,
        list,
    }: RecommendedOfferProps
) => {

    const {t} = useTranslation();
    return (
        <>
            <Styled.Main__Label>
                {t(customTitle ?? 'lbl_ourBestOffers')}
            </Styled.Main__Label>
            <Styled.Advertisment__Holder>
                <OfferAlternatives
                    data={{errors: 's', offers: list, isLoading: false}}
                />
            </Styled.Advertisment__Holder>
        </>
    )
}


export default RecommendedOffer