import React, {useEffect, useState} from "react";
import {Styled} from "./SignInUp.styles";
import {useTranslation} from "react-i18next";
import useBookBrdPageFunctions from "../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {faCircleCheck, faCircleQuestion, faCircleXmark} from "@fortawesome/pro-light-svg-icons";
import useNavigation from "../../lib/UseHooksBookBrd/useNavigation";
import {CREATE_ACCOUNT, LOGIN} from "../../lib/Routes";
import {faApple, faFacebook, faGoogle} from "@fortawesome/free-brands-svg-icons";

type SignInUpProps = {
    type: 'SignUp' | 'Login'
}

type styleType = 'default' | 'error' | 'success'

const signInOptions = [
    {
        icon: faGoogle,
        brand: 'Google'
    },
    {
        icon: faApple,
        brand: 'Apple'
    },
    {
        icon: faFacebook,
        brand: 'Facebook'
    }
]

const SignInUp = (
    {
        type
    }: SignInUpProps
) => {

    const [accountControls, setAccountControls] = useState<any>({})

    const [passwordConditions, setPasswordConditions] = useState<any>({})

    const [passwordValue, setPasswordValue] = useState<string>('')

    const [emailValue, setEmailValue] = useState<string>('')

    const {t} = useTranslation();

    const reRoute = useNavigation()

    const {checkIfMailValid} = useBookBrdPageFunctions()

    const GetTitle = () => {
        if (type === 'Login') {
            return t('lbl_login_title')
        }
        if (type === 'SignUp') {
            return t('lbl_create_account_title')
        }
    }

    const getIcon = (style: styleType) => {
        if (style === 'error') {
            return faCircleXmark
        }
        if (style === 'default') {
            return faCircleQuestion
        }
        return faCircleCheck
    }

    const getButtonLabel = () => {
        if (type === 'Login') {
            if (!accountControls?.isValidEmail) {
                return t('lbl_continue')
            } else {
                return t('lbl_login')
            }
        }
        if (type === 'SignUp') {
            if (!accountControls?.isValidEmail) {
                return t('lbl_continue')
            } else {
                return t('lbl_sign_up')
            }
        }
    }

    const onSwitchToCreateAccount = () => {
        reRoute(CREATE_ACCOUNT)
    }

    const onSwitchToLogin = () => {
        reRoute(LOGIN)
    }

    const forgotPasswordClickHandler = () => {
        alert('to bad :(')
    }

    const onEmailFocus = () => {
        setAccountControls((state: any) => ({
            ...state,
            isEmailError: false
        }))
    }

    const onContinueClickHandler = () => {
        const isEmailValid = checkIfMailValid(emailValue)
        if (!isEmailValid) {
            setAccountControls((state: any) => ({
                ...state,
                isEmailError: !isEmailValid
            }))
        } else {
            setAccountControls((state: any) => ({
                ...state,
                isEmailError: false,
                isValidEmail: true
            }))
        }
    }

    useEffect(() => {
        if (type === 'SignUp') {
            const containsCapitalLetter = passwordValue.match(/[A-Z]/)
            const containsSpecialCharacters = passwordValue.match(/[?#$@!&*]/)
            const containsNumber = passwordValue.match(/[0-9]/)
            setPasswordConditions({
                Capital: containsCapitalLetter ? 'success' : 'default',
                Special: containsSpecialCharacters ? 'success' : 'default',
                Number: containsNumber ? 'success' : 'default'
            })
        }
    }, [passwordValue])


    return (
        <Styled.SignInUp__Holder>
            <Styled.Sign__Type>
                {GetTitle()}
            </Styled.Sign__Type>
            <Styled.Error__Message__Label>
                {accountControls?.isEmailError ? t('lbl_news_seller_email_error') : null}
            </Styled.Error__Message__Label>
            <Styled.Email__Field
                onChange={(event: any) => {
                    setAccountControls((state: any) => ({
                        ...state,
                        isValidEmail: false
                    }))
                    setEmailValue(event?.target?.value)
                }}
                value={{label: emailValue}}
                showIcon={false}
                textFieldType={'email'}
                autoComplete={'email'}
                error={!!accountControls?.isEmailError}
                placeholder={'example@bookbrd.com'}
                isClearAble={false}
                onFocus={onEmailFocus}
            />
            {accountControls?.isValidEmail && (
                <>
                    <Styled.Password__Field
                        onChange={(event: any) => {
                            setPasswordValue(event?.target?.value)
                        }}
                        value={{label: passwordValue}}
                        showIcon={false}
                        autoComplete={'password'}
                        textFieldType={'password'}
                        error={!!accountControls?.isEmailError}
                        placeholder={t('lbl_enter_password')}
                        isClearAble={false}
                        onFocus={onEmailFocus}
                        style={{
                            marginTop: '10px'
                        }}
                    />
                    {type === 'Login' && (
                        <Styled.Forgot__Password__Label
                            onClick={forgotPasswordClickHandler}
                        >
                            {t('lbl_forgot_password_question')}
                        </Styled.Forgot__Password__Label>
                    )}
                    {type === 'SignUp' && (
                        <Styled.Conditions__Holder>
                            <Styled.Password__Must__Include__Label>
                                {t('lbl_password_must_include')}
                            </Styled.Password__Must__Include__Label>

                            <Styled.Password__Condition
                                type={passwordConditions?.Special}
                            >
                                <Styled.Condition__Icon
                                    icon={getIcon(passwordConditions?.Special)}
                                />
                                {`${t('lbl_special_characters')}: ?,#,@,!,&,$,*`}
                            </Styled.Password__Condition>
                            <Styled.Password__Condition
                                type={passwordConditions?.Number}
                            >
                                <Styled.Condition__Icon
                                    icon={getIcon(passwordConditions?.Number)}
                                />
                                {`${t('lbl_contains_numbers')}`}
                            </Styled.Password__Condition>
                            <Styled.Password__Condition
                                type={passwordConditions?.Capital}
                            >
                                <Styled.Condition__Icon
                                    icon={getIcon(passwordConditions?.Capital)}
                                />
                                {`${t('lbl_contains_capital_letter')}`}
                            </Styled.Password__Condition>
                        </Styled.Conditions__Holder>
                    )}
                </>
            )}
            <Styled.Continue__Submit__Button
                label={getButtonLabel()}
                onClick={onContinueClickHandler}
            />
            {type === 'SignUp' && (
                <Styled.Switch__To__Other__Type__Label>
                    {t('lbl_switch_to_login')}
                    <span
                        onClick={onSwitchToLogin}
                        style={{
                            marginLeft: '5px',
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                            cursor: 'pointer'
                        }}
                    >
                    {t('lbl_login')}
                </span>
                </Styled.Switch__To__Other__Type__Label>
            )}
            {type == 'Login' && (
                <Styled.Switch__To__Other__Type__Label>
                    {t('lbl_switch_to_sign_up')}
                    <span
                        onClick={onSwitchToCreateAccount}
                        style={{
                            marginLeft: '5px',
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                            cursor: 'pointer'
                        }}
                    >
                    {t('lbl_sign_up')}
                </span>
                </Styled.Switch__To__Other__Type__Label>
            )}
            <Styled.Other__Options__Separator>
                <Styled.Separator__Line/>
                <Styled.Separator__Label>
                    {t('lbl_or')}
                </Styled.Separator__Label>
                <Styled.Separator__Line/>
            </Styled.Other__Options__Separator>
            {signInOptions?.map((option: any) => (
                <Styled.Other__Options__Sign__Box>
                    <Styled.Brand__Icon_For__Sign
                        icon={option.icon}
                    />
                    <Styled.Brand__Label>
                        {t('lbl_continue_with', {brand: option.brand})}
                    </Styled.Brand__Label>
                </Styled.Other__Options__Sign__Box>
            ))}

        </Styled.SignInUp__Holder>
    )
}
export default SignInUp