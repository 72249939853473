import {Styled} from "../TripOffer.styles";
import {useTranslation} from "react-i18next";
import TransportDescription from "./TransportDescription";

type OfferBottomLayerProps = {
    SimilarOffersHandler: () => void
    addToCompareHandler: () => void
    onSeeOfferButtonHandler: () => void
    isOfferFocused: boolean
    offerData: any
}

const OfferBottomLayer = (
    {
        SimilarOffersHandler,
        addToCompareHandler,
        onSeeOfferButtonHandler,
        isOfferFocused,
        offerData
    }: OfferBottomLayerProps) => {

    const {t} = useTranslation();

    const {
        Base,
        Transport,
        Accommodation
    } = offerData


    return (
        <Styled.OfferBottomLayer
            isOfferFocused={isOfferFocused}
        >
            <TransportDescription
                transport={Transport}
            />
            <Styled.SeeOfferButtonPlacment>
                <Styled.SimilarOffersButton
                    label={t('lbl_see_similar_offers')}
                    onClick={SimilarOffersHandler}
                />
                <Styled.CompareOfferButton
                    onClick={addToCompareHandler}
                    label={t('lbl_compare_offer')}
                />
                <Styled.SeeOfferButton
                    onClick={onSeeOfferButtonHandler}
                    label={t('lbl_see_offer_button')}
                />
            </Styled.SeeOfferButtonPlacment>
        </Styled.OfferBottomLayer>
    )
}

export default OfferBottomLayer