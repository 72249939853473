import {Modal} from "../../lib/UIBookBrd/Modal/ModalsTypes";
import {useState} from "react";
import useOnClickOutside from "../../lib/UseHooksBookBrd/useOnClickOutside";
import {useTranslation} from "react-i18next";
import QuickStart from "./Content/QuickStart";

type TylerQuickDocumentationInModalProps = {
    isOpen: boolean
    toggleModal: any
}

const TylerQuickDocumentationInModal = (
    {
        isOpen,
        toggleModal
    }: TylerQuickDocumentationInModalProps
) => {

    const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);

    const {t} = useTranslation()

    const onClose = () => {
        toggleModal(false)
    }

    useOnClickOutside(modalElement, onClose)

    return (
        <Modal
            ref={setModalElement}
            title={t('lbl_quick_start_tyler')}
            onClose={onClose}
            width={1100}
            isOpen={isOpen}
        >
            <QuickStart/>
        </Modal>
    )
}

export default TylerQuickDocumentationInModal