import {Styled} from "../../../../ContactPage.styles";
import Text from "../../../../../../lib/UIBookBrd/Text";
import useSupport from "../../../../Hook/useSupport";

const MailSchema = () => {

    const {mailGuide} = useSupport()

    return (
        <Styled.Guide__Mail__Mail__Holder>
            <Styled.Guide__mail__Subject__Holder>
                <Styled.Guide__Mail__Section__Explanation>
                    {mailGuide?.data?.mailStructure?.subject?.explanation}
                </Styled.Guide__Mail__Section__Explanation>
                <Styled.Guide__Mail__Subject>
                    {mailGuide?.data?.mailStructure?.subject?.value}
                </Styled.Guide__Mail__Subject>
            </Styled.Guide__mail__Subject__Holder>
            <Styled.Guide__Mail__Structure__Holder>
                <Styled.Guide__Mail__Mail__Example>
                    <Styled.Guide__Mail__Title>
                        {mailGuide?.data?.mailStructure?.title}{','}
                    </Styled.Guide__Mail__Title>
                    <br/>
                    {Object.keys(mailGuide?.data?.mailStructure?.instructions ?? {}).map((type: any) => {
                        const {value, explanation} = mailGuide?.data?.mailStructure?.instructions[type]
                        return (
                            <>
                                <Styled.Guide__Mail__Section__Explanation>
                                    {explanation}
                                </Styled.Guide__Mail__Section__Explanation>
                                <Text>
                                    {value}
                                </Text>
                                <br/>
                            </>
                        )
                    })}
                    <Styled.Guide__Mail__Section__Explanation>
                        {mailGuide?.data?.mailStructure?.greetings?.explanation}
                    </Styled.Guide__Mail__Section__Explanation>
                    <Text style={{fontWeight: 'bold'}}>
                        {mailGuide?.data?.mailStructure?.greetings?.greetings},
                    </Text>
                    <Text>
                        {mailGuide?.data?.mailStructure?.greetings?.title}
                    </Text>
                </Styled.Guide__Mail__Mail__Example>
            </Styled.Guide__Mail__Structure__Holder>
        </Styled.Guide__Mail__Mail__Holder>
    )
}
export default MailSchema