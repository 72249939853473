import {createBrowserHistory} from "history";
import {isEmpty, isNull, isString} from "lodash-es";
import {Default_Search_Form_Values} from "../DefaultFormikValue/DefaultSearchFormValues";
import {SearchFormFromSeoList, searchFormToSeoList} from "../Configs/SEO";
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {getValueForSearchForm, getValueForSEOLink} from "../ProtoHashConfig/SeoValidation";
import {reduceUseLessContent} from "../ProtoHashConfig/protoValidation";
import {useLocation} from "react-router-dom";
import {isPageWithFlightSearch, isPageWithTripSearch} from "../RoutesHandler";

const getSEOSearchFormValues = (value: any) => {
    let newValue: string = ''
    let newAdditionalCommands: any = {}
    let newSearchFormValue: any = {}
    let newFilterFormValue: any = {}
    let newSimiliarOffer: any = {}
    let newOfferDetails: any = {}
    Object.keys(value).map((section: any) => {
        const sectionValue = value[section]
        switch (section) {
            case 'SearchFormValues':
                Object.keys(sectionValue).map((part: string) => {
                    const partValue = sectionValue[part]
                    const SEOTitle = searchFormToSeoList[part]
                    newSearchFormValue[SEOTitle ?? part] = getValueForSEOLink(partValue, SEOTitle)
                })
                break
            case 'FilterFormValues':
                Object.keys(sectionValue).map((part: string) => {
                    const partValue = sectionValue[part]
                    const SEOTitle = searchFormToSeoList[part]
                    newFilterFormValue[SEOTitle ?? part] = getValueForSEOLink(partValue, SEOTitle)
                })
                break
            case 'similarOffers':
                let newValue = ''
                Object.keys(sectionValue).map((item: string) => {
                    newValue = newValue + `${item}_${sectionValue[item]}/`
                })
                newSimiliarOffer = newValue
                break
            case 'offerDetails':
                newOfferDetails = sectionValue
                break
            case 'AdditionalCommands':
                newAdditionalCommands = Object.keys(sectionValue).map((key: any) => {
                    return key + ':' + sectionValue[key]
                }).join('/')
        }
    })

    const reduced = reduceUseLessContent(
        {
            Trip: reduceUseLessContent(newSearchFormValue),
            Filters: reduceUseLessContent(newFilterFormValue),
            ShownOffer: newOfferDetails,
            SimilarOffers: newSimiliarOffer,
            AdditionalCommands: newAdditionalCommands
        })
    Object.keys(reduced).map((section: string, index: number) => {
        const sectionValue = reduced[section]
        if (section === 'Trip' || section === 'Filters') {
            let newSectionValue: string = ''
            Object.keys(sectionValue).map((field: string) => {
                const fieldValue = sectionValue[field]
                newSectionValue = newSectionValue + `${field}_${fieldValue}/`
            })

            newValue = newValue + `${section}=${newSectionValue}&`
        } else if (section === 'ShownOffer') {
            newValue = newValue + `${section}=${sectionValue}&`
        } else if (section === 'SimilarOffers') {
            newValue = newValue + `${section}=${sectionValue}&`
        } else {
            newValue = newValue + `${section}=${JSON.stringify(sectionValue)}&`
        }
    })
    return newValue
}

const getSearchFromSEOFormatForTrip = (value: any) => {
    let newSearchFormValue: any = {}
    let newFilterFormValue: any = {}
    let readyValues: any = {}
    value.split('&').filter((item: string) => item).map((section: string) => {
        const [type, value] = section.split('=')
        const correctFormValues: any = {}
        if (type === 'Trip' || type === 'Filters') {
            value.split('/').filter((item: string) => item).map((item: any) => {
                const [field, fieldValue] = item.split('_')
                correctFormValues[field] = fieldValue
            })
            readyValues[type] = correctFormValues
        } else if (type === 'ShownOffer') {
            readyValues[type] = value
        } else if (type === 'SimilarOffers') {
            const newValue: any = {}
            value.split('/').map((item: any) => {
                const [name, value] = item.split('_')
                newValue[name] = value
            })
            readyValues[type] = newValue
        } else if (type === 'AdditionalCommands') {
            let additionalCommandsValue: any = {}
            const decodedValue = JSON.parse(decodeURIComponent(value)).split('/')
            decodedValue.map((field: any) => {
                const [fieldName, value] = field.split(':')
                additionalCommandsValue[fieldName] = JSON.parse(value)
            })
            readyValues[type] = additionalCommandsValue
        } else {
            readyValues[type] = JSON.parse(value)
        }

    })
    Object.keys(readyValues).map((section: string) => {
        const sectionValue = readyValues[section]
        switch (section) {
            case 'Trip':
                Object.keys(sectionValue).map((type: string) => {
                    const value = sectionValue[type]
                    const formType = SearchFormFromSeoList[type]
                    newSearchFormValue[formType ?? type] = getValueForSearchForm(value, formType)
                })
                break
            case'Filters':
                Object.keys(sectionValue).map((type: string) => {
                    const value = sectionValue[type]
                    const formType = SearchFormFromSeoList[type]
                    newFilterFormValue[formType ?? type] = getValueForSearchForm(value, formType)
                })
                break
            default:
                break
        }
    })

    return ({
        FilterFormValues: newFilterFormValue,
        offerDetails: readyValues['ShownOffer'],
        similarOffers: readyValues['SimilarOffers'],
        SearchFormValues: newSearchFormValue,
        AdditionalCommands: readyValues['AdditionalCommands']
    })
}

export const enCodeTripValues = (value: any) => {

    const reducedValue = reduceUseLessContent(value)

    const seoValue = getSEOSearchFormValues(reducedValue)

    return seoValue as any
}

export const deCode = (value: any, currentLocation: any) => {
    if (isPageWithTripSearch(currentLocation)) {
        if (typeof (value) === 'string' && value.includes('Trip')) {
            return getSearchFromSEOFormatForTrip(value)
        }
        return {
            SearchFormValues: Default_Search_Form_Values
        }
    }
    if (isPageWithFlightSearch(currentLocation)) {
        return {}
    }
    return {
        SearchFormValues: Default_Search_Form_Values
    }
}

type SetValue<T> = Dispatch<SetStateAction<T>>

function useHashForm(
    initialState: any,
    paramsName: string,
): [any, (state: any) => void] {
    const [state, setState] = useState(deCode(document.location.search.replace('?&search=', '') ?? '', document.location.pathname))
    const history = createBrowserHistory();
    const location = useLocation()


    useEffect(() => {
        if (isEmpty(document.location.search) && isPageWithTripSearch(window.location.pathname)) {
            const enCodedValue = enCodeTripValues(initialState)
            const pathname = document.location.pathname;
            const searchParams = new URLSearchParams(pathname);
            searchParams.set(paramsName, decodeURIComponent(enCodedValue));

            history.replace({search: decodeURIComponent(searchParams.toString()).replace(`${pathname}=`, '')});
        }
    }, [])

    useEffect(() => {
        if (isEmpty(state)) {
            setState(deCode(document.location.search.replace('?&search=', '') ?? '', document.location.pathname))
        }
    }, [location])

    const readValue = useCallback((): any => {
        // Prevent build error "window is undefined" but keep keep working
        if (typeof window === 'undefined') {
            return initialState;
        }

        try {
            return state;
        } catch (error) {
            return initialState;
        }
    }, [initialState, state]);

    const onChange: SetValue<any> = ((value?) => {
        // Prevent build error "window is undefined" but keeps working
        if (typeof window === 'undefined') {
            console.warn(
                `Tried setting key even though environment is not a client`,
            );
        }

        try {
            const newValue = value instanceof Function ? value(readValue()) : value;
            setState(newValue)
            const enCodedValue = enCodeTripValues(newValue)
            const pathname = document.location.pathname;
            const searchParams = new URLSearchParams(pathname);
            searchParams.set(paramsName, decodeURIComponent(enCodedValue));
            const newUrl = `${pathname}?&search=${decodeURIComponent(enCodedValue)}`;
            window.history.replaceState({}, '', newUrl)


            //sessionStorage
            const currentSearchType = `/${window.location.pathname.split('/')[2]}`
            let sessionStorageValue: any = {}
            if (!isNull(sessionStorage.getItem('searchValuesForAllTypes')) && isString(sessionStorage.getItem('searchValuesForAllTypes')) && !isEmpty(sessionStorage.getItem('searchValuesForAllTypes'))) {
                const parsed = JSON.parse(sessionStorage.getItem('searchValuesForAllTypes') as string) ?? {}
                sessionStorageValue = parsed
            }
            sessionStorageValue[currentSearchType] = enCodedValue
            sessionStorage.setItem('searchValuesForAllTypes', JSON.stringify(sessionStorageValue))
        } catch (error) {
            console.warn(`Error setting`, error);
        }
    });


    return [!isEmpty(state) ? state : deCode(document.location.search.replace('?&search=', '') ?? '', document.location.pathname) as any, onChange];
}

export default useHashForm