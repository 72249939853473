import {tylerMessagesDirectionTypes} from "../../../../../lib/Types";
import {Styled} from "../Messages.styles";
import TylerAvatar from "./TylerAvatar";
import TextMessage from "../MessagesTypes/TextMessage";

type MessageCreatorProps = {
    directionType: tylerMessagesDirectionTypes,
    value: any
    data: any
}


const MessageCreator = (
    {
        directionType,
        value,
        data,
    }: MessageCreatorProps
) => {


    return (
        <Styled.Messages__Creator__Holder
            type={directionType}
        >
            {directionType === tylerMessagesDirectionTypes.from && (
                <TylerAvatar/>
            )}
            <TextMessage
                type={directionType}
                data={data}
                value={value}
            />
        </Styled.Messages__Creator__Holder>
    )
}

export default MessageCreator