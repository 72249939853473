import {
    CONTACT_US,
    FIRST_STEP_TRIP_SEARCH,
    LOGIN,
    MANAGE_ACCOUNT,
    OPERATOR_LIST,
    ORDER_STATUS,
    TRAVEL__INFORMATION
} from "./Routes";

const protocol = `https://`

const domain = window.location.host

const TRIPS_FIRST_PAGE_LINK = `${protocol}${domain}${FIRST_STEP_TRIP_SEARCH}`

const LOGIN_LINK = `${protocol}${domain}${LOGIN}`
const MANAGE_ACCOUNT_LINK = `${protocol}${domain}${MANAGE_ACCOUNT}/Main`
const SUPPORT_MAIN_LINK = `${protocol}${domain}${CONTACT_US}`
const FIND_ORDER_STATUS = `${protocol}${domain}${ORDER_STATUS}`
const OPERATORS__LIST = `${protocol}${domain}${OPERATOR_LIST}`
const TRAVEL__REQUIERMENTS = `${protocol}${domain}${TRAVEL__INFORMATION}`


export {
    OPERATORS__LIST,
    TRAVEL__REQUIERMENTS,
    MANAGE_ACCOUNT_LINK,
    LOGIN_LINK,
    FIND_ORDER_STATUS,
    SUPPORT_MAIN_LINK,
    TRIPS_FIRST_PAGE_LINK
}