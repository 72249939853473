import {useContext, useEffect} from "react";
import {Bot__Data, Search_Proto} from "../../../lib/Context/Context";
import {includes, isEmpty} from "lodash-es";
import {tylerMessage, tylerMessagesDirectionTypes, tylerResponse} from "../../../lib/Types";
import {getDefaultTyler} from "../Context";
import {useTranslation} from "react-i18next";
import useLocalData from "../../../lib/UseHooksBookBrd/useSearchBaseData";
import axios from "axios";
import useTripHandler from "../../../lib/UseHooksBookBrd/useTripHandler";
import {SECOND_STEP_TRIP_SEARCH} from "../../../lib/Routes";
import {isMobileOnly} from "react-device-detect";
import useStorage from "../../../lib/UseHooksBookBrd/useStorage";
import {reduceUseLessContent} from "../../../lib/ProtoHashConfig/protoValidation";
import {Search_Filters_Fields, Search_Form_Fields} from "../../../lib/DefaultFormikValue/DefaultSearchFormValues";
import {Local_Settings_Values} from "../../../lib/PageConfig";

const formatDataFromTyler = (data: any) => {

    const searchForm: any = {}

    const filterForm: any = {}

    Object.keys(data).map((sectionName: string) => {
        let sectionValue = data[sectionName]

        switch (sectionName) {
            case 'Base.ComponentsCombinations':
                //TODO ADD FUNCTIOANLITY
                break
            case 'Base.DateRange':
                searchForm[sectionName] = {
                    startDate: sectionValue.startDate.split('-').reverse().join('.'),
                    endDate: sectionValue.endDate.split('-').reverse().join('.'),
                }
                break
            case 'Base.Duration':
                searchForm[sectionName] = {
                    min: Number(sectionValue.min),
                    max: Number(sectionValue.max)
                }
                break
            case 'Base.Participants':
                searchForm[sectionName] = {
                    adults: {
                        value: Number(sectionValue.adults.value),
                    },
                    kids: {
                        value: Number(sectionValue.child.value),
                        dates: sectionValue.child.dates.map((date: any) => date.split('-').reverse().join('.'))
                    }
                }
                break
            default:
                if (includes(Search_Form_Fields, sectionName)) {
                    searchForm[sectionName] = sectionValue
                }
                if (includes(Search_Filters_Fields, sectionName)) {
                    filterForm[sectionName] = sectionValue
                }
                break
        }

    })


    return {searchForm, filterForm}
}

const useTylerBot = () => {

    const [localSettings, _] = useStorage<any>('localStorage', 'LocalSettings', Local_Settings_Values)

    const [hashValue, setHashValue] = useContext<any>(Search_Proto)

    const [botState, setBotState, tylerIconRef, tylerContentRef] = useContext<any>(Bot__Data)

    const [isNerdMode, setIsNerdMode] = useStorage('localStorage', 'nerd_mode_for_tyler', false)

    const {onSearchTrip} = useTripHandler()

    const {toggleDisableScroll} = useLocalData()

    const scrollBottom = () => {
        if (tylerContentRef?.current) {
            tylerContentRef.current.scrollTop = 10000
        }
    }

    const getSearchForTyler = (state: any, data: any) => {

        const {SearchFormFields} = data ?? {}

        const reducedContent = reduceUseLessContent(SearchFormFields)

        const {filterForm, searchForm} = formatDataFromTyler(reducedContent)

        return {
            SearchFormValues: {
                ...state?.SearchFormValues,
                ...searchForm
            },
            FilterFormValues: {
                ...state?.FilterFormValues ?? {},
                ...filterForm
            }
        }
    }

    const toggleForceListRequest = (data: any) => {
        setHashValue((state: any) => ({
            ...state,
            ...getSearchForTyler(state, data),
            AdditionalCommands: {
                forceSecondStepRefreshList: true
            }
        }))
    }

    const toggleContainer = () => {
        if (isMobileOnly) {
            toggleDisableScroll(!botState?.isOpen)
        }
        setBotState((state: any) => ({
            ...state,
            isOpen: !botState?.isOpen
        }))
        scrollBottom()
    }

    const handleActions = (data: any) => {
        const sztucznecos = 'ShowOffer'
        if (sztucznecos === 'ShowOffer') {
            if (window.location.pathname === SECOND_STEP_TRIP_SEARCH) {
                toggleForceListRequest(data)
            } else {
                setHashValue((state: any) => ({
                    ...state,
                    ...getSearchForTyler(state, data),
                    similarOffers: {}
                }))
                onSearchTrip(true)
            }
        }

    }

    const tylerResponseHandler = (data: tylerResponse) => {

        const {responseType, responseMessage, sessionKey} = data

        const newMessage: tylerMessage = {
            text: responseMessage,
            request: data,
            directionType: tylerMessagesDirectionTypes.from,
        }

        switch (responseType) {
            case 'text':
                setBotState((state: any) => ({
                    ...state,
                    conversationList: [...state.conversationList, newMessage],
                }))
                setTimeout(() => {
                    scrollBottom()
                }, 10)
                break
            case "action":
                handleActions(data)
                setBotState((state: any) => ({
                    ...state,
                    sessionKey,
                    conversationList: [...state.conversationList, newMessage],
                }))
                setTimeout(() => {
                    scrollBottom()
                }, 10)
                break
            default:
                break
        }

    }

    const talkToTylerFunc = async (message: tylerMessage) => {
        try {
            setBotState((state: any) => ({
                ...state,
                error: '',
                isPending: true
            }))
            const params = reduceUseLessContent({
                sessionKey: botState?.sessionKey,
                request: message.text
            })
            const request = await axios.post('http://localhost:8080/process', params)
            const {data} = request
            tylerResponseHandler({...data, responseType: !isEmpty(data?.responseType) ? data?.responseType : 'action'})
        } catch (e) {
            setBotState((state: any) => ({
                ...state,
                error: 'Network',
                isPending: false
            }))
        } finally {
            setBotState((state: any) => ({
                ...state,
                isPending: false
            }))
        }
    }

    const submitMessage = () => {
        if (!isEmpty(botState.textInputValue) && botState.textInputValue.trim().length) {
            const message: tylerMessage = {
                directionType: tylerMessagesDirectionTypes.to,
                text: botState.textInputValue
            }
            setBotState((state: any) => ({
                ...state,
                conversationList: [...state.conversationList, message],
                textInputValue: '',
            }))
            talkToTylerFunc(message)
        }
    }

    const errorClickHandler = () => {
        const messagesList = botState.conversationList
        const lastMessage: tylerMessage = messagesList[messagesList.length - 1]
        talkToTylerFunc(lastMessage)
    }

    const textFieldChangeInput = (event: any) => {
        setBotState((state: any) => ({
            ...state,
            textInputValue: event.target.value
        }))

    }

    const {t} = useTranslation()

    const devOnlyCleaner = () => {
        setBotState({...getDefaultTyler(t), isOpen: true})
    }

    const toggleNerdMode = () => {
        setIsNerdMode(!isNerdMode)
    }

    const toggleTylerQuickStart = (bool: boolean) => {
        setBotState((state: any) => ({
            ...state,
            isQuickStartOpen: bool
        }))
        //window.open(`${window.location.origin}${TYLER_DOCUMENTATION}`)
    }


    useEffect(() => {
        if (botState?.isOpen) {
            setBotState((state: any) => ({
                ...state,
                notificationsCount: 0
            }))
        }
    }, [botState?.isOpen])

    return ({
        toggleTylerQuickStart,
        isIconHidden: localSettings?.hideTylerToggle,
        isNerdMode,
        toggleNerdMode,
        scrollBottom,
        errorClickHandler,
        devOnlyCleaner,
        state: botState,
        conversationList: botState.conversationList,
        isOpen: botState.isOpen,
        textFieldChangeInput,
        toggleContainer,
        submitMessage,
        tylerIconRef,
        tylerContentRef
    })
}

export default useTylerBot