import {Styled} from "./HeaderForManageAccount.styles";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import Text from "../../../lib/UIBookBrd/Text";
import {useTranslation} from "react-i18next";
import useOnClickOutside from "../../../lib/UseHooksBookBrd/useOnClickOutside";

type FullScreenProfilePhoto = {
    isOpen: boolean
    userImage: string
    deleteBackground: boolean
    userBackgroundColor: string
    onClose: () => void
}

const FullScreenProfilePhoto = (
    {
        userImage,
        deleteBackground,
        userBackgroundColor,
        onClose,
        isOpen
    }: FullScreenProfilePhoto
) => {

    const {t} = useTranslation()

    const [imageRef, setImageRef] = useState<any>(null)

    useOnClickOutside(imageRef, () => onClose())


    const closeMapBigButtonContent = (
        <Styled.Close__Profile__Button__Large__Screen__Content>
            <Text
                style={{
                    color: 'inherit',
                    transition: '0ms'
                }}
            >
                {t('lbl_close')}
            </Text>
            <Styled.Close__Profile__Icon__Big__Button
                icon={faTimes}
            />
        </Styled.Close__Profile__Button__Large__Screen__Content>
    )

    if (!isOpen) {
        return null
    }

    return (
        <Styled.Full__Screen__Profile__Photo>
            <Styled.Close__Profile__Button__Large__Screen
                buttonType={'custom'}
                label={closeMapBigButtonContent}
                onClick={onClose}
            />
            <Styled.Close__Profile__Button__Small_Screen
                buttonType={'icon'}
                label={faTimes}
                onClick={onClose}
            />
            <Styled.Full__Screen__Profile__Photo__Image
                ref={setImageRef}
                customColor={userBackgroundColor}
                deleteBackground={deleteBackground}
                src={userImage}
            />

        </Styled.Full__Screen__Profile__Photo>
    )
}

export default FullScreenProfilePhoto