import {rgba} from "polished";
import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Image from "../../../lib/UIBookBrd/Image";
import Text from "../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../../lib/UIBookBrd/Button";
import {isMobileOnly} from "react-device-detect";

const Main__Account_Information_Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`
const Main__Account__Image__Holder = styled(FlexBox)`
  margin-left: 20px;
  height: 200px;
  width: 200px;
  box-sizing: initial;
  overflow: hidden;
  z-index: 10;
  border-radius: 50%;
  border: 5px solid ${({theme}) => theme.backgroundColor};

  @media (max-width: 599px) {
    margin-left: auto;
    height: 150px;
    width: 150px;
    margin-right: auto;
  }
`
const Main_Account__Image = styled(Image)<{ deleteBackground: boolean, customColor: string }>`
  width: 100%;
  border: none;
  height: 100%;
  position: relative;
  cursor: pointer;
  z-index: 0;
  background-color: ${({deleteBackground, customColor}) => deleteBackground ? 'transparent' : customColor ?? 'red'};

  &:hover {
    z-index: 0;
    transition: 500ms;
    transform: ${isMobileOnly ? undefined : `scale(1.05)`};
  }
`

const Main__Account__Background__Image = styled(Image)`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border: 1px solid ${({theme}) => rgba(theme.color, 0.6)};
  border-top: none;
  @media (max-width: 599px) {
    border-left: none;
    border-right: none;
  }
`
const Main__Account__User__Main__Data = styled(FlexBox)`
  height: 210px;
  width: 100%;
  margin-top: -190px;
  @media (max-width: 599px) {
    margin-top: -140px;
  }
`
const Main__Account__No__Image = styled(FlexBox)<{ customColor: string }>`
  height: 100%;
  width: 100%;
  background-color: ${({customColor}) => customColor ?? 'red'};
`
const Main__Account__No__Image__Letter = styled(Text)`
  margin: auto;
  font-weight: bold;
  font-size: 92px;
`
const Main__Account__User__Name = styled(Text)`
  margin: auto 0 40px 20px;
  font-size: 25px;
  font-weight: bold;
  text-shadow: 2px 2px 2px ${({theme}) => theme.backgroundColor};

  @media (max-width: 599px) {
    display: none;
  }
`
const Main__Account__Header__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Main__Account__User__Name__Small__Width = styled(Text)`
  font-size: 25px;
  font-weight: bold;
  margin: -50px auto 0 auto;
  @media (min-width: 599px) {
    display: none;
  }
`
const Main__Account__Change__Profile__Photo = styled(FontAwesomeIcon)`
  padding: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 15px;
  margin-left: 160px;
  border: 1px solid ${({theme}) => theme.backgroundColor};
  color: ${({theme}) => theme.color};
  background-color: ${({theme}) => theme.backgroundColor};
  cursor: pointer;
  position: absolute;
  z-index: 10;
  @media (max-width: 599px) {
    margin-top: 10px;
    margin-left: 120px;
  }

  &:hover {
    border: 1px solid ${({theme}) => theme.color};
  }
`

const Main__Account__Not__Logged__User = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.brdExclusiveColor};
`
const Main__Account__Not__Logged__Icon = styled(FontAwesomeIcon)`
  width: 120px;
  height: 120px;
  color: ${({theme}) => theme.color};
  margin: auto;
  @media (max-width: 599px) {
    width: 80px;
    height: 80px;
  }
`
const Full__Screen__Profile__Photo = styled(FlexBox)`
  position: fixed;
  z-index: 11;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.6)};
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
`
const Full__Screen__Profile__Photo__Image = styled(Image)<{ deleteBackground: boolean, customColor: string }>`
  max-width: 600px;
  max-height: 600px;
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: contain;
  background-color: ${({deleteBackground, customColor}) => deleteBackground ? 'transparent' : customColor ?? 'red'};
`
const Close__Profile__Button__Large__Screen = styled(Button)`
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 10;
  padding: 0 10px;
  @media (max-width: 900px) {
    display: none;
  }
`
const Close__Profile__Button__Large__Screen__Content = styled(FlexBox)`
  color: inherit;
`
const Close__Profile__Icon__Big__Button = styled(FontAwesomeIcon)`
  color: inherit;
  height: 16px;
  width: 16px;
  margin-left: 2px;
  margin-top: 1px;
`
const Close__Profile__Button__Small_Screen = styled(Button)`
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 10;
  padding: 0 10px;
  @media (min-width: 900px) {
    display: none;
  }
`
const Switch__Holder = styled(FlexBox)`
  margin: 10px 0;
  flex-direction: initial;
  flex-wrap: nowrap;
`
const Switch__Label = styled(Text)`
  margin: auto 0 auto 5px;
`
export const Styled = {
    Switch__Label,
    Switch__Holder,
    Close__Profile__Button__Small_Screen,
    Close__Profile__Icon__Big__Button,
    Close__Profile__Button__Large__Screen__Content,
    Close__Profile__Button__Large__Screen,
    Full__Screen__Profile__Photo__Image,
    Full__Screen__Profile__Photo,
    Main__Account__Not__Logged__Icon,
    Main__Account__Not__Logged__User,
    Main__Account__Change__Profile__Photo,
    Main__Account__User__Name__Small__Width,
    Main__Account__Header__Holder,
    Main__Account__User__Name,
    Main__Account__No__Image__Letter,
    Main__Account__User__Main__Data,
    Main_Account__Image,
    Main__Account__Image__Holder,
    Main__Account_Information_Holder,
    Main__Account__No__Image,
    Main__Account__Background__Image
}