import PageHolder from "../../lib/UIBookBrd/PageHolder";
import ContextProvider from "./Context/ContextProvider";
import Content from "./Components/Content";
import {supportPageType} from "../../lib/Types";

type ContactPageCoreProps = {
    type: supportPageType
}

const ContactPageCore = (
    {
        type
    }: ContactPageCoreProps
) => {

    return (
        <PageHolder>
            <ContextProvider>
                <Content
                    type={type}
                />
            </ContextProvider>
        </PageHolder>
    )
}

export default ContactPageCore