import {Styled} from "../RegionsHolder.styles"
import {useTranslation} from "react-i18next";
import {Region} from "../../../../../lib/Types";

type RegionBoxProps = {
    data: Region
    hiddeDescription?: boolean
}

const RegionBox = (
    {
        data,
        hiddeDescription
    }: RegionBoxProps
) => {

    const {
        name,
        description,
        image,
        searchData,
        linkToArticle
    } = data

    const {t} = useTranslation()

    const onShowArticle = () => {
        window.open(linkToArticle, 'blank')
    }

    const onShowRegionOffers = () => {
//TODO ADD HANDLING
        alert('SHOW OFFERS FROM REGION')
    }

    return (
        <Styled.Region__Box>
            <Styled.Region__Image__Holder>
                <Styled.Region__Image
                    src={image}
                />
                <Styled.Region__Image__Hover__Content>
                    <Styled.Region__Name__Label>
                        {name}
                    </Styled.Region__Name__Label>
                    {!hiddeDescription && (
                        <Styled.Region__Quick__Content__Text>
                            {description}
                        </Styled.Region__Quick__Content__Text>
                    )}
                    <Styled.Region__Actions__Holder>
                        <Styled.Region__Action__Button
                            onClick={onShowArticle}
                            label={t('lbl_show_more_about_region')}
                        />
                        <Styled.Region__Action__Button
                            onClick={onShowRegionOffers}
                            label={t('lbl_show_offers')}
                        />
                    </Styled.Region__Actions__Holder>
                </Styled.Region__Image__Hover__Content>
            </Styled.Region__Image__Holder>
        </Styled.Region__Box>
    )
}

export default RegionBox