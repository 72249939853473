import styled from "styled-components";
import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import {rgba} from "polished";
import Text from "../../../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Loading__Results__Holder = styled(FlexBox)`
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  position: fixed;
  flex-direction: column;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.8)};
`
const Loading__Title = styled(Text)`
  margin: auto auto 10px auto;
  font-size: 18px;
  font-weight: bold;
`
const Loading__Desc = styled(Text)`
  text-align: center;
  margin: 10px auto auto auto;
`
const Approved__Result__Icon = styled(FontAwesomeIcon)`
  margin: 5px auto 5px auto;
  height: 47px;
  width: 47px;
  color: ${({theme}) => theme.isCheckedSwitchGreen};
`
const Declined__Result__Icon = styled(FontAwesomeIcon)`

  margin: 5px auto 5px auto;
  height: 47px;
  width: 47px;
  color: ${({theme}) => theme.errorMessageIconColor};
`


export const Styled = {
    Declined__Result__Icon,
    Approved__Result__Icon,
    Loading__Desc,
    Loading__Results__Holder,
    Loading__Title
}