import {Styled} from "../BookingComponent/BookingComponent.styles";
import StarIcons from "../../../../../../Components/StarIcons";
import {useTranslation} from "react-i18next";
import {faMapPin} from "@fortawesome/pro-solid-svg-icons";
import {forwardRef} from "react";
import Button from "../../../../../../lib/UIBookBrd/Button";
import PriceHolder from "../BookingComponent/PriceHolder";
import Tooltip from "../../../../../../lib/UIBookBrd/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGem} from "@fortawesome/pro-light-svg-icons";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import SkeletonBox from "../../../../../../lib/UIBookBrd/SkeletonBox/SkeletonBox";

type SmallWidthTitleProps = {
    onBookClick: () => void
    onShowMap: () => void
    showFixedTitle?: boolean
    showPrice?: boolean
    showBookButton?: boolean
    isLoadingOfferDetails: boolean
    offer: any
}

const SmallWidthTitle = forwardRef<HTMLDivElement, SmallWidthTitleProps>((
    {
        onBookClick,
        onShowMap,
        showFixedTitle,
        showPrice,
        isLoadingOfferDetails,
        offer,
        ...props
    }, ref) => {

    const {t} = useTranslation();


    const isExclusiveOffer = false

    return (
        <>
            {!isLoadingOfferDetails ? (
                <Styled.Title
                    showFixedTitle={showFixedTitle}
                    ref={ref}
                >
                    <Styled.Names__Holder>
                        <Styled.Title__Holder>
                            <Tooltip
                                disabled={!isExclusiveOffer}
                                content={
                                    <Styled.ToolTip__Exclusive__Label>{t('brd_exclusive_desc')}</Styled.ToolTip__Exclusive__Label>}
                            >
                                <Styled.Object__Title__Name
                                    isExclusiveOffer={isExclusiveOffer}
                                >
                                    {offer?.Base?.XCode?.Name}
                                    {isExclusiveOffer && (
                                        <FontAwesomeIcon
                                            icon={faGem}
                                            style={{
                                                fontSize: '12px',
                                                marginBottom: "15px"
                                            }}
                                        />
                                    )}
                                </Styled.Object__Title__Name>
                            </Tooltip>
                            <StarIcons
                                isLoading={isLoadingOfferDetails}
                                number={offer?.Accommodation?.Category}
                            />
                        </Styled.Title__Holder>
                        <Styled.Location__Label
                        >
                            <Styled.Pin__Icon
                                icon={faMapPin}
                            />
                            {!isLoadingOfferDetails ? (
                                <>
                                    {offer?.Base?.XCity?.Name} / {offer?.Base?.XCountry?.Name} -
                                </>
                            ) : <FlexBox>
                                <SkeletonBox
                                    width={'30px'}
                                    minWidth={'30px'}
                                    height={'20px'}
                                    minHeight={'20px'}
                                    margin={'auto 5px auto 5px'}
                                    style={{
                                        minWidth: '140px'
                                    }}

                                />
                                <SkeletonBox
                                    width={'40px'}
                                    minWidth={'40px'}
                                    height={'20px'}
                                    minHeight={'20px'}
                                    margin={'auto 5px auto 5px'}
                                />
                            </FlexBox>}
                            {!isLoadingOfferDetails && (
                                <Styled.Show__Map__Label
                                    onClick={onShowMap}
                                >
                                    {t('lbl_show_object_on_map')}
                                </Styled.Show__Map__Label>
                            )}
                        </Styled.Location__Label>
                    </Styled.Names__Holder>
                    <Styled.Price__Holder
                        showButton={showPrice}
                    >
                        <PriceHolder
                            showFixedTitle={true}
                            Base={offer?.Base}
                        />
                        <Button
                            label={t('lbl_book_trip')}
                            onClick={onBookClick}
                            style={{
                                minWidth: '125px'
                            }}
                        />
                    </Styled.Price__Holder>
                </Styled.Title>

            ) : (
                <Styled.Title
                    isLoading={true}
                >
                    <Styled.Title__Holder>
                        <Styled.Object__Title__Name__Skeleton/>
                        <StarIcons
                            isLoading={true}
                            number={offer?.Accommodation?.Category}
                        />
                    </Styled.Title__Holder>
                    <Styled.Location__Label
                        isLoading={true}
                    >
                        <Styled.Pin__Icon
                            isLoading={true}
                            icon={faMapPin}
                        />
                        <SkeletonBox
                            width={'30px'}
                            minWidth={'30px'}
                            height={'14px'}
                            minHeight={'14px'}
                            margin={'auto 5px auto 5px'}
                            style={{
                                minWidth: '140px'
                            }}

                        />
                        <SkeletonBox
                            width={'40px'}
                            minWidth={'40px'}
                            height={'14px'}
                            minHeight={'14px'}
                            margin={'auto 5px auto 5px'}
                        />
                        <Styled.Location__Label__Skeleton/>
                    </Styled.Location__Label>
                </Styled.Title>
            )}
        </>
    )
})
export default SmallWidthTitle