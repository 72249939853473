import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";
import Image from "../../../../../../lib/UIBookBrd/Image";
import {rgba} from "polished";

const Region__Info__Holder = styled(FlexBox)`
  padding: 20px 5px 10px 5px;
  flex-direction: column;
`
const Region__Country__Title = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`
const Flag__Image = styled.img`
  height: 18px;
  width: 30px;
  margin-right: 5px;
`
const Map__Image = styled(Image)`
  height: 200px;
  width: 200px;
  margin-right: 20px;
  @media (max-width: 599px) {
    margin-right: 0;
    width: 100%;
    object-fit: contain;
    margin-bottom: 10px;
  }
`
const Country__Title__Holder = styled(FlexBox)`
  padding-bottom: 10px;
`
const Country__Information = styled(FlexBox)`
  flex-wrap: nowrap;
  @media (max-width: 599px) {
    flex-wrap: wrap;
  }

`
const Country__Information__Positioner = styled(FlexBox)`
  padding-left: 20px;
  flex-direction: column;
  margin: auto 0;
  gap: 10px;

  &:first-of-type {
    padding-left: 0;
  }
`
const Country__Key__Label = styled(Text)`
  font-weight: bold;
  margin-right: 20px;
`
const Country__Value__Label = styled.span`
  color: ${({theme}) => theme.color};
  font-size: 14px !important;
`
const Caruzel__Region__Options = styled(FlexBox)<{ isLoadingSections: boolean }>`
  flex-wrap: nowrap;
  max-width: calc(98vw - 10px);
  padding: 10px 0;
  overflow: ${({isLoadingSections}) => isLoadingSections ? 'hidden' : 'scroll'};
`
const Description__Section__Tab = styled(Text)<{ isSelected: boolean }>`
  white-space: nowrap;
  user-select: none;
  padding: 10px 10px;
  margin: 5px 0;
  border-right: 1px solid ${({theme}) => theme.color};
  cursor: pointer;
  background-color: ${({theme, isSelected}) => isSelected ? rgba(theme.optionHoverColor, 0.6) : undefined};

  &:hover {
    background-color: ${({theme}) => rgba(theme.optionHoverColor, 0.9)};
  }

  &:last-of-type {
    border-right: 0;
  }
`
const Caruzel__Shadow = styled(FlexBox)`
  min-width: 10px;
  z-index: 2;
  height: 37px;
  margin: auto 0;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.6)};
`
const Description__Value__Holder = styled(Text)`
  min-height: 50px;
`
const Description__Title = styled(Text)`
  font-size: 18px;
  padding-bottom: 10px;
  font-weight: bold;
`
const Country__Description__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
`
const Description__Tab__Loader = styled(FlexBox)`
  margin: 5px 0;
  border-right: 1px solid ${({theme}) => theme.color};

  &:last-of-type {
    border-right: 0;
  }
`
const Loading__Description__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 6px;
`
const No__Region__Found__Label = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: auto;
`


export const Styled = {
    No__Region__Found__Label,
    Loading__Description__Holder,
    Description__Tab__Loader,
    Country__Description__Holder,
    Caruzel__Shadow,
    Caruzel__Region__Options,
    Region__Info__Holder,
    Region__Country__Title,
    Flag__Image,
    Country__Title__Holder,
    Country__Information,
    Country__Information__Positioner,
    Map__Image,
    Country__Key__Label,
    Country__Value__Label,
    Description__Section__Tab,
    Description__Value__Holder,
    Description__Title
}