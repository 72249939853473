import {accountPageType, userPageViews} from "../../../lib/Types";
import {Styled} from "./ViewsSwitch.styles";
import {useParams} from "react-router-dom";
import useNavigation from "../../../lib/UseHooksBookBrd/useNavigation";
import {MANAGE_ACCOUNT, SOMEONE_ACCOUNT} from "../../../lib/Routes";

type ViewsSwitchProps = {
    type: accountPageType
}


const ViewsSwitch = (
    {
        type
    }: ViewsSwitchProps
) => {

    const {pageType, userName} = useParams()

    const navigate = useNavigation()

    const meUser: any = [
        userPageViews.main,
        userPageViews.myTrips,
        userPageViews.reviews,
        userPageViews.about
    ]

    const otherUser: any = [
        userPageViews.main,
        userPageViews.reviews,
        userPageViews.about
    ]

    const routesList: any = {
        [accountPageType.own]: MANAGE_ACCOUNT,
        [accountPageType.other]: `${SOMEONE_ACCOUNT}/${userName}`
    }

    const listForType: any = {
        [accountPageType.own]: meUser,
        [accountPageType.other]: otherUser
    }

    const list = listForType[type]

    return (
        <Styled.Views__Switch__Holder>
            {list?.map((view: userPageViews, index: number) => {

                const onClick = () => {
                    navigate(`${routesList[type]}/${view}`)
                }

                return (
                    <Styled.Type__Tabs
                        key={`user_view_types_${view}`}
                        onClick={onClick}
                        isSelected={view === pageType}
                        isLast={index === list.length - 1}
                    >
                        {view}
                    </Styled.Type__Tabs>
                )
            })}
        </Styled.Views__Switch__Holder>
    )
}

export default ViewsSwitch