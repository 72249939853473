import {useContext, useState} from "react";
import {Local_Data} from "../Context/Context";
import {Local_Settings_Values} from "../PageConfig";
import {isEmpty, isUndefined} from "lodash-es";
import {OPERATOR_INFORMATION, THIRD_STEP_TRIP_SEARCH} from "../Routes";
import {Buffer} from "buffer";
import moment from "moment/moment";
import useGoogleAnalytics from "../GoogleAnalytics/useGoogleAnalitics";
import useRoutesHandler from "../RoutesHandler";
import {linkTypes} from "../Types";
import useStorage from "./useStorage";

export const getParticipantsFromHash = (hash: string | undefined) => {
    if (!isEmpty(hash) && !isUndefined(hash)) {

        const code = hash.split('|')[2]

        const decodedPayload = Buffer?.from(code, 'base64');

        const decodedString = decodedPayload.toString('utf8');

        const [adultsCount, encodedChildDates, encodedInfantDates] = decodedString.split('|');

        const childDates = encodedChildDates ? encodedChildDates.split(',').map((date) => moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')) : [];

        const infantDates = encodedInfantDates ? encodedInfantDates.split(',').map((date) => moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')) : [];

        return {
            adultsCount: parseInt(adultsCount),
            childDates,
            infantDates
        };

    }
    return {}
}

const useBookBrdPageFunctions = () => {


    // VALUES

    const [currentWindow, setCurrentWindow] = useState<Window | null>(null);

    const [localDataValues, setLocalDataValues] = useContext<any>(Local_Data)

    const [localSettings, setLocalSettings] = useStorage<any>('localStorage', 'LocalSettings', Local_Settings_Values)

    const {
        sendToAnalytics,
    } = useGoogleAnalytics()

    const {
        createLinkPathForOfferDetails
    } = useRoutesHandler()

    // FUNCTIONS

    const checkIfMailValid = (mail: string) => {
        if (!isEmpty(mail) && /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(mail)) {
            return true
        }
        return false
    }


    const createLinkForOperator = (operator: { name: string, code: string }) => {
        const origin = window.location.origin
        return `${origin}${OPERATOR_INFORMATION}/${operator.name.split(' ').join('_')}/${operator.code}`
    }

    const onTourOperatorClick = (operator: { name: string, code: string }) => {
        const contentWindowOptions = `width: ${window.screen.availWidth},  height: ${window.screen.availHeight},`

        if (currentWindow !== null && !currentWindow.closed) {
            currentWindow.close();
        }
        const contentWindow = window.open(createLinkForOperator(operator), 'TourOperator', contentWindowOptions);
        const x = (window.screenX || window.screenLeft || 0);
        const y = (window.screenY || window.screenTop || 0);

        contentWindow?.moveTo(x, y);
        contentWindow?.resizeTo(Math.min(1800, window.screen.availWidth), Math.min(1200, window.screen.availHeight));
        contentWindow?.focus();

        setCurrentWindow(contentWindow);
    }

    const createLinkForThirdStep = (value: any) => {
        const origin = window.location.origin
        return `${origin}${`${THIRD_STEP_TRIP_SEARCH}/${value?.name?.split(' ').join('_')}`}`
    }


    const showOfferHandler = (offer: any, openBlank: boolean = true) => {
        sendToAnalytics({
            event: 'Show_Offer',
            eventCategory: 'BUTTON_CLICK',
            eventAction: 'Show_Offer'
        })
        window.open(createLinkPathForOfferDetails(window.location.pathname, linkTypes.link, offer), openBlank ? '_blank' : '_self')
    }

    const onLocalStorageStateChange = (name: string, value: any) => {
        setLocalSettings((state: any) => ({
            ...state,
            [name]: value
        }))
    }

    const setOpenClientDrawer = () => {
        setLocalDataValues((state: any) => ({
                ...state,
                isOpenClientDrawer: true
            })
        )
    }

    const setCloseClientDrawer = () => {
        setLocalDataValues((state: any) => ({
                ...state,
                isOpenClientDrawer: false
            })
        )
    }

    const onOpenSettings = () => {
        setLocalDataValues((state: any) => ({
            ...state,
            showSettings: true
        }))
        setCloseClientDrawer()
    }

    const onCloseSettings = () => {
        setLocalDataValues((state: any) => ({
            ...state,
            showSettings: false
        }))
    }

    const onOpenCurrencyModal = () => {
        setLocalDataValues((state: any) => ({
            ...state,
            isOpenCurrencyModal: true,
            isOpenClientDrawer: false
        }))
    }

    const onCloseCurrencyModal = () => {
        setLocalDataValues((state: any) => ({
            ...state,
            isOpenCurrencyModal: false
        }))
    }

    const onOpenLanguageModal = () => {
        setLocalDataValues((state: any) => ({
            ...state,
            isOpenLanguageModal: true,
            isOpenClientDrawer: false
        }))
    }

    const onCloseLanguageModal = () => {
        setLocalDataValues((state: any) => ({
            ...state,
            isOpenLanguageModal: false
        }))
    }

    const onLanguageSelectChange = (value: any) => {
        if (value.value !== localSettings.SelectedLanguage) {
            onLocalStorageStateChange('SelectedLanguage', value.value)
            window.location.reload();
        }
    }

    const getValidCardDate = (value: string) => {
        const regex = /^(0[1-9]|1[0-2])(20\d{2})$/;

        if (!regex.test(value)) {
            return false;
        }

        const date = moment(value, 'MMYYYY');

        if (!date.isValid()) {
            return false
        }

        const today = moment();
        return date.isSameOrAfter(today, 'month');
    }

    const subscribeToNewsSeller = (mail: any, setSectionBehaviour: any) => {
        if (checkIfMailValid(mail)) {
            setSectionBehaviour((state: any) => ({
                ...state,
                isLoading: true
            }))
            setTimeout(() => {
                setSectionBehaviour((state: any) => ({
                    ...state,
                    actionType: 'approved',
                    isLoading: false
                }))
            }, 2000)
        } else {
            setSectionBehaviour((state: any) => ({
                ...state,
                isError: true
            }))
            console.log('jest zle ')
        }


        // TODO BASED ON RESPONSE CHANGING ACTION BETWEEN APPROVED /EXISTING /DECLINED

    }

    const unsubscribeNewsSeller = () => {
        alert('unsub')
    }

    const sendMessageToSupport = async (name: string, mail: string, message: string) => {
        alert(name + ' ' + mail)
    }

    const toggleFavouriteOptions = (bool: boolean) => {
        setLocalDataValues((state: any) => ({
            ...state,
            isFavouriteOffersOpen: bool
        }))
    }

    //TODO DELETE WHEN TOKEN READY
    const toggleTokenModal = (bool: boolean) => {
        setLocalDataValues((state: any) => ({
            ...state,
            isTokenModalOpen: bool
        }))
    }


    const convertToSize = (value: any) => {

        return null
    }

    const SEOLinkForOffer = (offer: any) => {

        return `link to ${offer?.Base?.XCode?.Name} Board: ${offer?.Accommodation?.XService?.Name} Room: ${offer?.Accommodation?.Room?.Name} Dates: ${offer?.Base?.StartDate} ->  ${offer?.Base?.ReturnDate} `
    }
    return ({
        toggleTokenModal,
        SEOLinkForOffer,
        unsubscribeNewsSeller,
        isModalTokenOpen: localDataValues?.isTokenModalOpen,
        createLinkForOperator,
        createLinkForThirdStep,
        convertToSize,
        sendMessageToSupport,
        getValidCardDate,
        checkIfMailValid,
        onTourOperatorClick,
        showOfferHandler,
        onOpenSettings,
        setOpenClientDrawer,
        onCloseSettings,
        toggleFavouriteOptions,
        onOpenCurrencyModal,
        onCloseCurrencyModal,
        setCloseClientDrawer,
        onOpenLanguageModal,
        onCloseLanguageModal,
        onLanguageSelectChange,
        subscribeToNewsSeller,
        createLinkPathForOfferDetails,
        isFavouriteOffersOpen: localDataValues?.isFavouriteOffersOpen,
        selectedCurrency: localSettings?.PreferredCurrency,
        isOpenCurrencyModal: localDataValues?.isOpenCurrencyModal,
        selectedLanguage: localSettings.SelectedLanguage,
        isOpenLanguageModal: localDataValues?.isOpenLanguageModal,
        isOpenClientDrawer: localDataValues?.isOpenClientDrawer,
        isSettingsOpen: localDataValues?.showSettings
    })
}


export default useBookBrdPageFunctions