import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";
import Button from "../../../lib/UIBookBrd/Button";

const Manage__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
`
const Manage__Content__Title = styled(Text)`
  margin: 40px auto 20px auto;
  font-size: 20px;
  font-weight: bold;
`
const Offer__Holder = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
`
const View__Type__Select = styled(FlexBox)`
  flex-direction: initial;
  max-width: 100%;
  width: 100%;
  flex-wrap: nowrap;
  overflow: scroll;
  margin: 10px 0;
`
const Type__Tabs = styled(Text)<{ isSelected: boolean, isLast: boolean }>`
  white-space: nowrap;
  user-select: none;
  padding: 10px 15px;
  margin: 5px 0;
  font-size: 19px;
  font-weight: ${({isSelected}) => isSelected ? 'bold' : undefined};
  border-right: 1px solid ${({theme, isLast}) => isLast ? 'transparent' : theme.color};
  cursor: pointer;
`
const Planner__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`
const Plan__Section__Title = styled(Text)`
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
`
const Participants__Holder = styled(FlexBox)`
  flex-direction: column;
`
const Participant__Holder = styled(FlexBox)`
  flex-direction: initial;
  padding: 15px 10px;
  border-top: 1px solid ${({theme}) => theme.color};
  flex-wrap: nowrap;

  &:last-of-type {
    border-bottom: 1px solid ${({theme}) => theme.color};

  }
`
const Participant__Name = styled(Text)`
  font-size: 18px;
  margin: auto 0;
  font-weight: bold;
`
const Change__User__Data__Button = styled(Button)`
  margin-left: auto;

`
const Refund__Holder = styled(FlexBox)`
  flex-direction: column;
`
const No__Refund__Label = styled(Text)`
  margin: 40px auto 20px auto;
  font-size: 18px;
  font-weight: bold;
`
const No__Refund__Description = styled(Text)`
  text-align: center;
  margin: 0 auto;

`
export const Styled = {
    No__Refund__Description,
    No__Refund__Label,
    Refund__Holder,
    Change__User__Data__Button,
    Participant__Name,
    Participant__Holder,
    Participants__Holder,
    Plan__Section__Title,
    Planner__Holder,
    Type__Tabs,
    Offer__Holder,
    View__Type__Select,
    Manage__Content__Holder,
    Manage__Content__Title
}