import LanguageModal from "./LanguageModal";
import AlertPositioner from "./AlertPositioner";
import React from "react";
import LocationModal from "./LocationModal";
import CookiesDrawer from "./CookiesDrawer";
import CurrencyModal from "./CurrencyModal";
import useLocalData from "../../lib/UseHooksBookBrd/useSearchBaseData";
import FavouriteOffers from "./FavouriteOffers";
import TokenModalDEVONLYDELTELATER from "./TokenModalDEVONLYDELTELATER";
import LoadingScreen from "../../Pages/LoadingScreen";

const BookBrdModulesHolder = () => {

    const {
        isLoading
    } = useLocalData()

    return (
        <>
            <LocationModal/>
            <LanguageModal/>
            <CurrencyModal/>
            <AlertPositioner/>
            <CookiesDrawer/>
            <FavouriteOffers/>
            <TokenModalDEVONLYDELTELATER/>
            <LoadingScreen
                isLoading={isLoading as boolean}
            />
        </>
    )
}
export default BookBrdModulesHolder