import {Styled} from "../../../../ContactPage.styles"
import {useTranslation} from "react-i18next";

const MailInformation = () => {

    const {t} = useTranslation()

    return (
        <>
            <Styled.Guide__Mail__Quick__Information__Title>
                {t('lbl_guide_mail_quick_information_title')}
            </Styled.Guide__Mail__Quick__Information__Title>
            <Styled.Guide__Mail__Quick__Information>
                {t('lbl_guide_mail_quick_information')}
            </Styled.Guide__Mail__Quick__Information>
        </>
    )
}
export default MailInformation