import React, {useContext} from "react";
import {ThemeContext} from "styled-components";
import {
    BOOK_STEP_TRIP_SEARCH,
    CONTACT_OUR,
    CONTACT_OWN,
    CONTACT_US,
    CREATE_ACCOUNT,
    FIRST_STEP_FLIGHT_SEARCH,
    FIRST_STEP_TRIP_SEARCH,
    LOGIN,
    MANAGE_ACCOUNT,
    OPERATOR_INFORMATION,
    ORDER_MANAGE,
    ORDER_STATUS,
    SECOND_STEP_TRIP_SEARCH,
    SOMEONE_ACCOUNT,
    THIRD_STEP_TRIP_SEARCH,
    TYLER_DOCUMENTATION
} from "../Routes";
import {isMobileOnly} from "react-device-detect";
import MainPage from "../../Pages/MainPage";
import SearchTripPanel from "../../Components/SearchTrip/SearchTripPanel";
import SearchTripLowerPart from "../../Components/SearchTrip/SearchTripLowerPart";
import SearchFlight from "../../Components/SearchFlight";
import TripOfferDetails from "../../Pages/TripSearch/TripOfferDetails";
import {AccountsPage} from "../../Pages/AccountsPage";
import SignInUp from "../../Pages/SignInUp";
import ManageAccount from "../../Pages/ManageAccount";
import TripSearchBookingPage from "../../Pages/TripSearch/TripSearchBookingPage";
import {Navigate} from "react-router-dom";
import useBookBrdComponentStates from "../UseHooksBookBrd/useBookBrdComponentStates";
import useConfigureSite from "../UseHooksBookBrd/useConfigureSite";
import {isEmpty} from "lodash-es";
import OperatorDetailPage from "../../Pages/OperatorDetailPage";
import {Local_Data} from "../Context/Context";
import TripResultsPage from "../../Pages/TripSearch/TripSearchResultPage/Core";
import ConfirmationPage from "../../Pages/ConfirmationPage/Core";
import {accountPageType, OrderPageTypeEnum, supportPageType} from "../Types";
import ContactPageCore from "../../Pages/ContanctPage/Core";
import {isPageWithTripSearch} from "../RoutesHandler";
import TylerDocumentationPage from "../../Pages/TylerDocumentation/Core";

//Trip Config Const
const Mobile_Dark_Trip_Image = 'https://media.tacdn.com/media/attractions-splice-spp-674x446/06/70/1e/d7.jpg'
const Mobile_Light_Trip_Image = 'https://media.cnn.com/api/v1/images/stellar/prod/230324090551-01-visiting-france-during-protests-what-to-know-top.jpg?c=16x9&q=h_720,w_1280,c_fill'
const DescTop_Dark_Trip_Image = 'https://www.spain.info/export/sites/segtur/.content/imagenes/cabeceras-grandes/madrid/calle-gran-via-madrid-s333961043.jpg_604889389.jpg'
const DescTop_Light_Trip_Image = 'https://www.travelandleisure.com/thmb/l4gMoI0BOszC3BZx2q1LYr3N3rE=/1600x1200/smart/filters:no_upscale()/madrid-spain-MADRIDTG0621-b2347a98186a4281a0874992b213ade0.jpg'
// Flight
const DescTop_Light_Flight_Image = 'https://images.unsplash.com/photo-1595865749889-b37a43c4eba4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2t5JTIwY2xvdWRzfGVufDB8fDB8fHww&w=1000&q=80'
const DescTop_Dark_Flight_Image = 'https://t3.ftcdn.net/jpg/01/24/77/32/360_F_124773288_bmIx5Y8F1baiBLyGUbVXNt1EzZ6J0SQR.jpg'

const usePagesConfigs = () => {

    const theme = useContext(ThemeContext)

    const [localData, setLocalData] = useContext<any>(Local_Data)

    const {
        SearchFormValues,
    } = useBookBrdComponentStates()

    const {
        searchBaseDebuoncer,
        configBookBrd,
        checkDestinationLabels,
    } = useConfigureSite()

    const routesTypes: any = {
        [FIRST_STEP_FLIGHT_SEARCH]: 'Flight',
        [FIRST_STEP_TRIP_SEARCH]: 'Trip'
    }

    const availableSearchPages: any = {
        Trip: {
            Navigate: FIRST_STEP_TRIP_SEARCH,
            backgroundImage: isMobileOnly ? theme.isDark ? Mobile_Dark_Trip_Image : Mobile_Light_Trip_Image : theme.isDark ? DescTop_Dark_Trip_Image : DescTop_Light_Trip_Image
        }
        ,
        Flight: {
            Navigate: FIRST_STEP_FLIGHT_SEARCH,
            backgroundImage: theme.isDark ? DescTop_Dark_Flight_Image : DescTop_Light_Flight_Image
        }
        ,
    }

    const currentlyShown = availableSearchPages[routesTypes[window.location.pathname]];

    const routesList: any = [
        {
            pathName: '/',
            priority: 0,
            content: (
                <Navigate to={FIRST_STEP_TRIP_SEARCH}/>
            )
        },
        {
            pathName: FIRST_STEP_TRIP_SEARCH,
            priority: 1.0,
            changeFreq: 'daily',
            content: (
                <MainPage
                    searchPanel={<SearchTripPanel/>}
                    advertBoxes={<SearchTripLowerPart/>}

                />
            )
        },
        {
            pathName: FIRST_STEP_FLIGHT_SEARCH,
            priority: 1.0,
            changeFreq: 'daily',
            content: (
                <MainPage
                    searchPanel={<SearchFlight/>}
                />
            ),

        },
        {
            pathName: SECOND_STEP_TRIP_SEARCH,
            priority: 0.9,
            changeFreq: 'always',
            content: (<TripResultsPage/>),

        },
        {
            pathName: `${THIRD_STEP_TRIP_SEARCH}/:Hotel/:From/:To/:Board/:Room/:Participants/:Departure/:offerId`,
            content: (<TripOfferDetails/>),

        },
        {
            pathName: CREATE_ACCOUNT,
            priority: 0.9,
            content: (
                <AccountsPage
                    pageContents={
                        <SignInUp
                            type={'SignUp'}
                        />
                    }
                />
            ),
        },
        {
            pathName: LOGIN,
            priority: 0.9,
            content: (
                <AccountsPage
                    pageContents={
                        <SignInUp
                            type={'Login'}
                        />
                    }
                />
            ),
        },
        {
            pathName: `${MANAGE_ACCOUNT}/:pageType`,
            priority: 0.9,
            content: (
                <AccountsPage
                    pageContents={
                        <ManageAccount
                            type={accountPageType.own}
                        />
                    }
                />
            ),
        },
        {
            pathName: `${SOMEONE_ACCOUNT}/:userName/:pageType`,
            priority: 0.9,
            content: (
                <AccountsPage
                    pageContents={
                        <ManageAccount
                            type={accountPageType.other}
                        />
                    }
                />
            ),
        },
        {
            pathName: BOOK_STEP_TRIP_SEARCH,
            content: (
                <TripSearchBookingPage/>
            )
        },
        {
            pathName: `${OPERATOR_INFORMATION}/:OperatorName/:OperatorCode`,
            content: (
                <OperatorDetailPage/>
            )
        },
        {
            pathName: `${ORDER_STATUS}`,
            priority: 0.9,
            content: (
                <ConfirmationPage
                    type={OrderPageTypeEnum.find}
                />
            )
        },
        {
            pathName: `${ORDER_STATUS}/:orderNumber`,
            content: (
                <ConfirmationPage
                    type={OrderPageTypeEnum.status}
                />
            )
        },
        {
            pathName: `${ORDER_MANAGE}/:orderNumber`,
            content: (
                <ConfirmationPage
                    type={OrderPageTypeEnum.manage}
                />
            )
        },
        {
            pathName: TYLER_DOCUMENTATION,
            content: (
                <TylerDocumentationPage/>
            )
        },
        {
            pathName: CONTACT_US,
            priority: 0.6,
            content: (
                <ContactPageCore
                    type={supportPageType.main}
                />
            )
        },
        {
            pathName: CONTACT_OWN,
            priority: 0.5,
            content: (
                <ContactPageCore
                    type={supportPageType.faq}
                />
            )
        },
        {
            pathName: CONTACT_OUR,
            priority: 0.5,
            content: (
                <ContactPageCore
                    type={supportPageType.support}
                />
            )
        },
        {
            pathName: CONTACT_OUR,
            priority: 0.5,
            content: (
                <ContactPageCore
                    type={supportPageType.support}
                />
            )
        },
    ]

    const getPageContent = () => {
        if (isPageWithTripSearch(document.location.pathname)) {
            if (isEmpty(localData?.SearchBaseData) && !document.location.pathname.includes(THIRD_STEP_TRIP_SEARCH) && !document.location.pathname.includes(BOOK_STEP_TRIP_SEARCH)) {
                searchBaseDebuoncer()
            } else {
                setLocalData((state: any) => ({
                    ...state,
                    isLoading: false
                }))
            }
            configBookBrd()
            checkDestinationLabels(SearchFormValues)
        }
        setLocalData((state: any) => ({
            ...state,
            isLoading: false
        }))
    }


    return {
        getPageContent,
        routesList,
        currentlyShown,
        availableSearchPages,
        theme
    }
}
export default usePagesConfigs