import {Bot__Data} from "../../lib/Context/Context";
import React, {useRef} from "react";
import {tylerMessage, tylerMessagesDirectionTypes} from "../../lib/Types";
import {useTranslation} from "react-i18next";
import useStorage from "../../lib/UseHooksBookBrd/useStorage";

type ContextProps = {
    children: React.ReactElement
}

export const getDefaultTyler = (t: any) => {

    const defaultTylerFirstMessage: tylerMessage = {
        directionType: tylerMessagesDirectionTypes.from,
        text: t('lbl_tyler_first_sentence')
    }

    return {
        notificationsCount: 1,
        isQuickStartOpen: false,
        isOpen: false,
        textInputValue: '',
        isPending: false,
        error: '',
        conversationList: [defaultTylerFirstMessage]
    }
}

const Context = (
    {
        children
    }: ContextProps
) => {

    const {t} = useTranslation()

    const [state, setState] = useStorage<any>('sessionStorage', 'tyler_session', getDefaultTyler(t))

    const tylerIconRef = useRef<HTMLDivElement>();

    const tylerContentRef = useRef<HTMLDivElement>();

    return (
        <Bot__Data.Provider value={[state, setState, tylerIconRef, tylerContentRef] as any}>
            {children}
        </Bot__Data.Provider>
    )
}

export default Context