import {Styled} from "../../../ContactPage.styles"
import {useTranslation} from "react-i18next";
import Button from "../../../../../lib/UIBookBrd/Button";
import useNotifications from "../../../../../lib/UseHooksBookBrd/useNotifications";
import MailGuideDrawer from "./MailGuide/MailGuideDrawer";
import {useState} from "react";

const TraditionalSupport = () => {

    const {
        addNotification
    } = useNotifications()

    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)

    const {t} = useTranslation()

    const mailSubject: string = 'Issue with BookBrd'

    const mailBody: string = 'Dear BookBrd team,'

    const onQuickGuideClickHandler = () => {
        setIsOpenDrawer(true)
    }

    const onQuickGuideCloseHandler = () => {
        setIsOpenDrawer(false)
    }

    const onCopyEmailClickHandler = () => {
        navigator.clipboard.writeText('support@bookbrd.com').then(function () {
            addNotification({title: t('lbl_success_mail_added_to_clipboard'), type: 'Success'})
        }, function (err) {
            addNotification({title: t('lbl_error_added_to_clipboard'), type: 'Error'})
        });
    }

    const onSendEmailClickHandler = () => {
        window.open(`mailto:support@bookbrd.com?&subject=${mailSubject}&body=${mailBody}`);
    }

    const supportInstructions: any = [
        {
            value: (
                <>
                    {t('lbl_first_step_support')}
                    {': '}
                    <a
                        href={`mailto:support@bookbrd.com?&subject=${mailSubject}&body=${mailBody}`}
                        type={'email'}
                    >
                        support@bookbrd.com
                    </a>
                </>
            )
        },
        {
            value: (
                <>
                    {t('lbl_second_step_support')}
                    {' '}
                    <span
                        onClick={onQuickGuideClickHandler}
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                    >
                        {t('lbl_quick_guide')}
                    </span>
                </>
            )
        },
        {
            value: (
                <>
                    {t('lbl_third_step_support')}
                </>
            )
        },
    ]

    return (
        <>
            <Styled.Traditional__Support__Holder>
                <Styled.Support__Half__Title>
                    {t('lbl_traditional_support')}
                </Styled.Support__Half__Title>
                {supportInstructions.map((item: any, index: number) => (
                    <Styled.Traditional__Support__Points>
                <span
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                {index + 1}.
                </span>
                        {' '}
                        {item.value}
                    </Styled.Traditional__Support__Points>
                ))}
                <Button
                    label={t('lbl_click_to_copy_email')}
                    onClick={onCopyEmailClickHandler}
                    style={{
                        margin: '4px 0'
                    }}
                />
                <Button
                    label={t('lbl_click_to_send_e-mail')}
                    onClick={onSendEmailClickHandler}
                    style={{
                        margin: '4px 0'
                    }}
                />
            </Styled.Traditional__Support__Holder>
            <MailGuideDrawer
                onClose={onQuickGuideCloseHandler}
                isOpen={isOpenDrawer}

            />
        </>
    )
}

export default TraditionalSupport