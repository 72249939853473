import {Styled} from "./OfferComparisonSection.styles"
import HotelImage from "./HotelImage";
import {isEmpty} from "lodash-es";
import {useTranslation} from "react-i18next";
import StarIcons from "../../../../../../Components/StarIcons";
import {faHotel} from "@fortawesome/pro-solid-svg-icons";
import Text from "../../../../../../lib/UIBookBrd/Text";
import useTripResultsValues from "../../../useTripResultsValues";

type OfferComparisonSectionProps = {
    isError: boolean
    setClickOutsideIgnoreElements: any
    selectedOffers: any
    section: any
    isLoadingData: boolean
}


const OfferComparisonSection = (
    {
        isError,
        setClickOutsideIgnoreElements,
        selectedOffers,
        section,
        isLoadingData
    }: OfferComparisonSectionProps
) => {

    const {t} = useTranslation();

    const {fetchValuesForCompareOffers} = useTripResultsValues()

    if (isError)
        return (
            <Styled.Section__Error__Holder>
                <Styled.Error__Box>
                    <Styled.Error__Title>
                        {t('lbl_error_with_network_request')}
                    </Styled.Error__Title>
                    <Text>
                        {t('lbl_error_with_network_request_desc')}
                    </Text>

                    <Styled.Section__Error__Button
                        label={t('lbl_retry')}
                        onClick={() => fetchValuesForCompareOffers()}
                    />
                </Styled.Error__Box>
            </Styled.Section__Error__Holder>
        )

    return (
        <Styled.Section__Element>
            {isLoadingData ? (
                <>
                    {selectedOffers?.map((item: any, index: number) => {
                        return (
                            <Styled.Section__Element__Part>
                                <Styled.Hotel__Name__Label
                                    isLoadingData={isLoadingData}
                                >
                                </Styled.Hotel__Name__Label>
                                <HotelImage
                                    isLoadingData={isLoadingData}
                                    pictures={[]}
                                />
                            </Styled.Section__Element__Part>
                        )
                    })}
                </>
            ) : (
                <Styled.Section__Element>
                    {Array.from({length: 2}).map((_: any, index: any) => {
                        const sectionValue = section && section[index]
                        const failedToLoad = !isEmpty(selectedOffers) && !isEmpty(selectedOffers[index]) && isEmpty(sectionValue)

                        const getText = () => {
                            if (failedToLoad) {
                                return t('lbl_failed_to_download_contents')
                            }
                            if (isEmpty(sectionValue)) {
                                return t('lbl_compare_offer_empty_slot')
                            }
                            return section[index]?.name
                        }

                        return (
                            <Styled.Section__Element__Part
                                style={{
                                    width: "50%",
                                    padding: '0'
                                }}
                            >
                                <Styled.Hotel__Name__Label>
                                    {getText()}
                                </Styled.Hotel__Name__Label>
                                <StarIcons
                                    number={!isEmpty(section) ? section[index]?.rating : 0}
                                />
                            </Styled.Section__Element__Part>
                        )
                    })}
                    {Array.from({length: 2}).map((_: any, index: any) => {
                        const sectionValue = !isEmpty(section) ? section[index] : ''
                        return (
                            <Styled.Section__Element__Part
                                style={{
                                    marginTop: '0'
                                }}

                            >
                                <HotelImage
                                    customIcon={faHotel}
                                    showIconWhenEmpty={true}
                                    setClickOutsideIgnoreElements={setClickOutsideIgnoreElements}
                                    isEmptySection={isEmpty(sectionValue)}
                                    isLoadingData={isEmpty(sectionValue) ? false : !sectionValue?.PictureUrlFull ? isEmpty(sectionValue?.PictureUrlFull) : isLoadingData}
                                    pictures={sectionValue?.PictureUrlFull ? sectionValue?.PictureUrlFull : []}
                                    thumbUrl={sectionValue ? sectionValue?.ThumbUrl : ''}
                                />
                            </Styled.Section__Element__Part>
                        )
                    })}
                </Styled.Section__Element>
            )}
        </Styled.Section__Element>
    )
}

export default OfferComparisonSection