import {Styled} from "../Messages.styles"
import {tylerMessagesDirectionTypes} from "../../../../../lib/Types";
import TylerAvatar from "../MessageCreator/TylerAvatar";
import {faCircle} from "@fortawesome/pro-solid-svg-icons";
import {useEffect, useState} from "react";
import useTylerBot from "../../../Handlers/Hook";

const LoadingMessage = () => {

    const [hasScrolled, setHasScrolled] = useState<boolean>(false)

    const {scrollBottom} = useTylerBot()

    const delayMap: any = {
        0: 0,
        1: 400,
        2: 800,
    }

    useEffect(() => {
        if (!hasScrolled) {
            scrollBottom()
            setHasScrolled(true)
        }
    }, [])
    return (
        <Styled.Messages__Creator__Holder
            type={tylerMessagesDirectionTypes.from}
        >
            <TylerAvatar/>
            <Styled.Text__Message__Holder
                type={tylerMessagesDirectionTypes.from}
            >
                {Array.from({length: 3}).map((_, index: number) => {
                    return (
                        <Styled.Dot__Loader
                            animationDelay={delayMap[index]}
                            icon={faCircle}
                        />
                    )
                })}

            </Styled.Text__Message__Holder>
        </Styled.Messages__Creator__Holder>
    )
}

export default LoadingMessage