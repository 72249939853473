import LenghtBasicPicker from "../LenghtBasicPicker";
import {toNumber} from "lodash-es";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {DURATION_FIELD} from "../../lib/DefaultFormikValue/DefaultSearchFormValues";

type DurationPickerProps = {
    setIsModalOpen?: any
    appendTo?: Element | null
    formikValue: any
    setFormikValues: any
}

const DurationPicker = (
    {
        appendTo,
        setIsModalOpen,
        formikValue,
        setFormikValues
    }: DurationPickerProps) => {

    const [value, setValue] = useState(formikValue)

    const {t} = useTranslation();

    const onApply = (value: { min: string, max: string }) => {
        setFormikValues(DURATION_FIELD, {min: toNumber(value.min), max: toNumber(value.max)})
    }

    const SelectOptions = [
        {
            label: '1-3 ',
            value: {min: 1, max: 3}
        },
        {
            label: '4-6 ',
            value: {min: 4, max: 6}
        },
        {
            label: '7-8 ',
            value: {min: 7, max: 8}
        },
        {
            label: '9-12 ',
            value: {min: 9, max: 12}
        },
        {
            label: '13-16 ',
            value: {min: 13, max: 16}
        },
        {
            label: '17-21 ',
            value: {min: 17, max: 21}
        },
    ]

    useEffect(() => {
        setValue(formikValue)
    }, [formikValue])

    return (
        <LenghtBasicPicker
            appendTo={appendTo}
            setIsModalOpen={setIsModalOpen}
            value={value}
            setValue={setValue}
            title={t('lbl_duration')}
            fieldPlaceholder={t('lbl_any_duration')}
            selectPlaceholder={t('lbl_choose_duration')}
            selectOptions={SelectOptions}
            hasSelect={true}
            onApply={onApply}
        />
    );

}
export default DurationPicker