import {Styled} from "../ManageContent.styles";
import TripOffer from "../../../TripSearch/TripSearchResultPage/Content/TripOfferList/TripOffer";
import useConfirmationPage from "../../useConfirmationPage";

const Offer = () => {
    const {
        offer,
        offerPictures,
    } = useConfirmationPage()

    return (
        <Styled.Offer__Holder>
            <TripOffer
                isDisabled={true}
                id={0}
                openFullImages={() => {
                }}
                selectedImage={0}
                pictures={offerPictures}
                navigateImagesHandler={() => {
                }}
                onOpenOffer={() => {
                }}
                onCloseOffer={() => {
                }}
                isOpen={false}
                index={0}
                SimilarOffersHandler={() => {
                }}
                compareOffersHandler={() => {
                }}
                offer={offer}
            />
        </Styled.Offer__Holder>
    )
}

export default Offer