import {Styled} from "./StarIcons.styles"
import {faStar} from "@fortawesome/pro-solid-svg-icons";
import {toNumber} from "lodash-es";
import {faStarHalf} from "@fortawesome/free-solid-svg-icons/faStarHalf";

type StarIconsProps = {
    isLoading?: boolean
    number: number | undefined
}
const defaultProps = {
    isLoading: false
}

const StarIcons = (
    {
        isLoading,
        number
    }: StarIconsProps
) => {

    const numberOfStars = isLoading ? Math.floor(Math.random() * 5 + 2.5) : number

    return (
        <Styled.StarIconsHolder>
            {Array.from({length: numberOfStars as number})?.map((star: any, index: number) => (
                <Styled.StarIcons
                    key={`star_${index}`}
                    isLoading={isLoading as boolean}
                    icon={faStar}
                />
            ))}
            {(toNumber(numberOfStars) * 2) % 2 === 1 && (
                <Styled.StarIcons
                    isLoading={isLoading as boolean}
                    icon={faStarHalf}
                />
            )}
        </Styled.StarIconsHolder>

    )
}
StarIcons.defaultProps = defaultProps;
export default StarIcons