import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import styled from "styled-components";
import Text from "../../../lib/UIBookBrd/Text";

const Content__Core__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  flex-grow: 1;
`
const Order__Code__Title = styled(Text)`
  margin: 40px auto 20px auto;
  font-size: 20px;
  font-weight: bold;
`


export const Styled = {
    Content__Core__Holder,
    Order__Code__Title
}