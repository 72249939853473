import {Styled} from "./BotChatContent"
import MessageCreator from "./Messages/MessageCreator/MessageCreator";
import {tylerMessage} from "../../../lib/Types";
import {useTranslation} from "react-i18next";
import useTylerBot from "../Handlers/Hook";
import LoadingMessage from "./Messages/MessagesTypes/LoadingMessage";
import {isEmpty} from "lodash-es";
import ErrorMessage from "./Messages/MessagesTypes/ErrorMessage";

const BotChatContent = () => {

    const {t} = useTranslation()

    const {
        conversationList,
        state,
        tylerContentRef,
    } = useTylerBot()

    return (
        <Styled.Content__Holder
            ref={tylerContentRef}
        >
            {conversationList.map((message: tylerMessage) => {
                return (
                    <MessageCreator
                        directionType={message.directionType}
                        data={message.request}
                        value={message.text}
                    />
                )

            })}
            {state.isPending && (
                <LoadingMessage/>
            )}
            {!isEmpty(state.error) && !state.isPending && (
                <ErrorMessage/>
            )}
        </Styled.Content__Holder>
    )
}

export default BotChatContent