import {Styled} from "../../ContactPage.styles"
import {useState} from "react";
import {faChevronDown, faChevronUp} from "@fortawesome/pro-solid-svg-icons";

type OptionProps = {
    option: any
}

const Option = (
    {
        option
    }: OptionProps
) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const {question, answer} = option

    return (
        <Styled.Faq__Option>
            <Styled.Faq__Question__Holder
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <Styled.Faq__Question__Label>
                    {question}
                </Styled.Faq__Question__Label>
                <Styled.Faq__Option__Status__Icon
                    icon={!isOpen ? faChevronDown : faChevronUp}
                />
            </Styled.Faq__Question__Holder>
            <Styled.Faq__Answer__Holder
                isOpen={isOpen}
            >
                <Styled.Faq__Answer__Label>
                    {answer}
                </Styled.Faq__Answer__Label>
            </Styled.Faq__Answer__Holder>
        </Styled.Faq__Option>
    )
}
export default Option