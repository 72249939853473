import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import Text from "../../../lib/UIBookBrd/Text";
import Button from "../../../lib/UIBookBrd/Button";

const Create__Account__Holder = styled(FlexBox)`
  flex-direction: column;
  padding: 25px 0 25px 0;
  border-top: 1px solid ${({theme}) => theme.color};
`
const Main__Title = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  padding: 0 10px;
  text-align: center;
  margin: 0 auto;
`
const Secondary__Title = styled(Text)`
  padding: 0 10px;
  font-size: 16px;
  text-align: center;
  max-width: 380px;
  margin: 0 auto;
  margin-top: 10px;
`
const Join__Community__Button = styled(Button)`
  margin: 15px auto 0 auto;
  padding: 0 15px;
`
export const Styled = {
    Join__Community__Button,
    Secondary__Title,
    Main__Title,
    Create__Account__Holder
}