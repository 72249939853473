import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FlexBox from "../../lib/UIBookBrd/FlexBox";

const StarIcon = styled(FontAwesomeIcon)<{ isFilled?: boolean, isSelectMode?: boolean }>`
  color: ${({theme, isFilled}) => isFilled ? theme.ratingStarColor : theme.color};
  height: ${({isSelectMode}) => !isSelectMode && '16px'};
`

const StarsHolder = styled(FlexBox)<{ isSelectMode: boolean }>`
  flex-wrap: nowrap;
  gap: ${({isSelectMode}) => isSelectMode ? '2px' : "4px"};
  margin: ${({isSelectMode}) => !isSelectMode && '5px 0'};
`
const Stars__Holder = styled(FlexBox)`
  flex-direction: column;
`

export const Styled = {
    Stars__Holder,
    StarIcon,
    StarsHolder
}