import PageHolder from "../../../lib/UIBookBrd/PageHolder";
import ContextProvider from "./ContextProvider";
import BookingPageContent from "./BookingPageContent";

type TripSearchBookingPageProps = {}

const TripSearchBookingPage = (
    {}: TripSearchBookingPageProps
) => {

    return (
        <>
            <PageHolder>
                <ContextProvider>
                    <BookingPageContent/>
                </ContextProvider>
            </PageHolder>
        </>
    )
}
export default TripSearchBookingPage