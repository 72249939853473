import Pricing
    from "../../../Pages/TripSearch/TripSearchResultPage/Content/TripOfferList/TripOffer/OfferTopLayer/TopLayerDescription/Pricing";
import {Styled} from "../Offer.styles";

type PriceProps = {
    offerData: any
}

const Price = (
    {
        offerData
    }: PriceProps
) => {

    const {Base} = offerData

    return (
        <Styled.Pricing__Holder>
            <Pricing
                isExlusive={false}
                pricing={Base.Price}
            />
        </Styled.Pricing__Holder>
    )
}
export default Price