import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";
import {rgba} from "polished";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Offer__Review__Holder = styled(FlexBox)`
  flex-direction: initial;
  width: 100%;
  min-width: 100%;
  flex-wrap: nowrap;
  flex-grow: 1;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`
const Rating__Data__Holder = styled(FlexBox)`
  width: 100%;
  padding: 10px;
    //border: 1px solid ${({theme}) => theme.color};
  border-radius: 5px;
  min-width: 380px;
  max-width: 380px;
  flex-wrap: nowrap;
  height: max-content;
  margin-top: 26px;
  @media (max-width: 800px) {
    min-width: unset;
    max-width: unset;
    border: 0 ;
    margin-top: 10px;
  }
`
const Labels__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 4px;
  padding-right: 20px;
`
const Ratings__Holder = styled(FlexBox)`
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
`
const Review__Type__Label = styled(Text)`
  padding: 2px 0;
`
const Rating__Line__Number__Holder = styled(FlexBox)`
  padding: 2px 0;
  flex-grow: 1;
  flex-wrap: nowrap;
`
const Rating__Number = styled(Text)`
  width: 21px;
  font-weight: bold;
  text-align: center;
  margin-right: 5px;
`
const Rating__Line__Holder = styled(FlexBox)`
  flex-grow: 1;
  margin: auto 0;
`
const Rating__Line = styled(FlexBox)<{ width: number }>`
  height: 6px;
  width: ${({width}) => width}%;
  background-color: ${({theme}) => theme.brdExclusiveColor};
`
const Missing__Rating__Line = styled(FlexBox)<{ width: number }>`
  height: 6px;
  width: ${({width}) => width}%;
  background-color: ${({theme}) => theme.missingRatingColor};
`
const Review__Data__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-grow: 1;
  margin-top: 26px;
`
const Review__Data__Title = styled(Text)`
  font-size: 16px;
  padding: 4px;
  font-weight: bold;
  @media (max-width: 800px) {
    margin: 10px 0;
  }
`
const Reviews__List__Holder = styled(FlexBox)`
  flex-direction: column;
  width: calc(100% - 10px);
  margin-left: 10px;
  gap: 8px;
  flex-wrap: nowrap;
  @media (max-width: 800px) {
    margin-left: 0;
    width: calc(100% - 2px);
  }
`
const Review__Box = styled(FlexBox)`
  padding: 8px;
  width: calc(100% - 16px);
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};
  flex-direction: column;
`
const User__Data__Holder = styled(FlexBox)`
  flex-direction: initial;
`
const User__Icon = styled(FlexBox)`
  height: 38px;
  width: 38px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};
  background-color: rebeccapurple;
  border-radius: 50%;
`
const Name__User__Data__Holder = styled(FlexBox)`
  margin-left: 5px;
  flex-direction: column;
`
const User__Name = styled(Text)`
  cursor: pointer;
  font-size: 15px;
  margin-bottom: auto;

  &:hover {
    text-decoration: underline;
  }
`
const Title__User__Data__Label = styled(Text)`
  font-size: 13px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const User__Data__Span = styled.span`
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const User__Rating__Holder = styled(FlexBox)`
  margin-left: 40px;
  padding: 5px 0;
`
const Rating__Number__Holder = styled(Text)`
  font-weight: bold;
    // color: ${({theme}) => theme.brdExclusiveColor};
`
const Rating__Star = styled(FontAwesomeIcon)`
  height: 14px;
  width: 14px;
  margin-top: 2px;
  margin-left: 2px;
  color: ${({theme}) => theme.brdExclusiveColor};
`
const When__Label = styled(Text)`
  margin-left: 10px;
  color: ${({theme}) => rgba(theme.color, 0.6)};
`
const Rating__Value = styled(FlexBox)`
  cursor: pointer;
`
const TollTip__Content__Holder = styled(FlexBox)`
  width: 100vw;
  max-width: 350px;
`
const Review__Data = styled(Text)<{ isOpen: boolean }>`
  margin-left: 40px;
  max-height: ${({isOpen}) => isOpen ? 'unset' : "95px"};
  overflow: hidden;
`
const Review__Items__holder = styled(FlexBox)`
  flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;
`
const Offer__Ratings__Holder = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: nowrap;
  @media (max-width: 800px) {
    width: calc(100% - 20px);
  }
`
const Blur__Over__Flow = styled(FlexBox)`
  margin-top: -10px;
  background-color: ${({theme}) => rgba(theme.backgroundColor, 0.9)};
  box-shadow: 0px 0px 15px 15px ${({theme}) => rgba(theme.backgroundColor, 0.95)};
  height: 10px;
  width: 100%;
`
const Summary__Number = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: auto;
`
const Summare__Number__Value = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`
const Skeleton__Review__Box = styled(FlexBox)`

  padding: 8px;
  width: calc(100% - 16px);
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};
  flex-direction: column;
`


export const Styled = {
    Skeleton__Review__Box,
    Summare__Number__Value,
    Summary__Number,
    Blur__Over__Flow,
    Offer__Ratings__Holder,
    Review__Items__holder,
    Review__Data,
    Rating__Value,
    When__Label,
    Rating__Star,
    Rating__Number__Holder,
    User__Rating__Holder,
    User__Data__Span,
    Title__User__Data__Label,
    User__Name,
    Name__User__Data__Holder,
    User__Icon,
    User__Data__Holder,
    Reviews__List__Holder,
    Review__Data__Title,
    Review__Data__Holder,
    Missing__Rating__Line,
    Rating__Line,
    Rating__Line__Holder,
    Rating__Line__Number__Holder,
    Review__Type__Label,
    Labels__Holder,
    Ratings__Holder,
    Offer__Review__Holder,
    Rating__Data__Holder,
    Rating__Number,
    Review__Box,
    TollTip__Content__Holder
}