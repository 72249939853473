import {Styled} from "../TripOffer.styles";
import TopLayerDescription from "./TopLayerDescription";
import Image from "./Image";
import Title from "./Title";

type OfferTopLayerProps = {
    isLoadingImages?: boolean
    openFullImages: () => void
    selectedImage: number
    navigateImagesHandler: (type: 'previous' | 'next') => void
    isExlusive?: boolean
    pictures: any
    isOfferFocused: boolean
    offerData: any
    onOfferCloseHandler: () => void
    onFavouriteClickHandler: () => void
    isFavourite?: boolean
}


const OfferTopLayer = (
    {
        isLoadingImages,
        isFavourite,
        onFavouriteClickHandler,
        openFullImages,
        selectedImage,
        navigateImagesHandler,
        isExlusive,
        pictures,
        offerData,
        isOfferFocused,
        onOfferCloseHandler
    }: OfferTopLayerProps) => {

    return (
        <Styled.OfferTopLayer>
            <Title
                isFavourite={isFavourite}
                onFavouriteClickHandler={onFavouriteClickHandler}
                isExlusive={!!isExlusive}
                offerData={offerData}
                isOfferFocused={isOfferFocused}
                onOfferCloseHandler={onOfferCloseHandler}
            />
            <Styled.ImageAndDescHolder>
                <Image
                    isLoadingImages={isLoadingImages}
                    openFullImages={openFullImages}
                    selectedImage={selectedImage}
                    navigateImagesHandler={navigateImagesHandler}
                    pictures={pictures}
                    isOfferFocused={isOfferFocused}
                />
                <TopLayerDescription
                    isExlusive={!!isExlusive}
                    isOfferFocused={isOfferFocused}
                    offerData={offerData}
                />

            </Styled.ImageAndDescHolder>
        </Styled.OfferTopLayer>
    )
}
export default OfferTopLayer