import {
    BOARD_FIELD,
    DATE_RANGE_FIELD,
    DURATION_FIELD,
    ORIGIN_FIELD,
    PARTICIPANTS_FIELD,
    PRICE_FIELD,
    RATING_FIELD,
    TO_FIELD,
    TRANSPORT_FIELD,
    XCITY_FIELD,
    XCODE_FIELD
} from "../DefaultFormikValue/DefaultSearchFormValues";
//field
export const SEODates = 'Dates'
export const SEODestination = 'Destination'
export const SEODuration = 'Duration'
export const SEOFrom = 'From'
export const SEOParticipants = 'Participants'
export const SEOPrice = 'Price-per-person'
export const SEOCity = 'City'
export const SEOHotels = 'Hotels'
export const SEOTransport = 'Transport'
export const SEOBoard = 'Board'
export const SEORating = 'Rating'
//Transport_Options
export const SEOFlightType = 'Flight'
export const SEOBusType = 'Bus'
export const SEOOwnType = 'Own'


const FLIGHT_TRANSPORT = 'transport.flight'
const BUS_TRANSPORT = 'transport.bus'
const OWN_TRANSPORT = 'transport.*'

export const DEFAULT_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/bookbrd.appspot.com/o/BookBrdLinkImage.png?alt=media&token=7d39f0d4-4f57-416c-9daa-88b8335a762f'
export const DEFAULT_KEYWORDS = 'BookBrd, search trips, find vacations, search holiday, trip finder, all-inclusive vacation search, best vacation search, summer vacation search, winter getaway finder'
export const DEFAULT_DESCRIPTION = "Unlock the world's wonders with ease. Discover, compare, and effortlessly book your dream vacations through BookBrd. Your gateway to global adventures and dreams awaits."
export const DEFAULT_TITLE = 'BookBrd'


export const SEOTransportList: any = {
    [FLIGHT_TRANSPORT]: SEOFlightType,
    [BUS_TRANSPORT]: SEOBusType,
    [OWN_TRANSPORT]: SEOOwnType
}

export const FormTransportList: any = {
    [SEOFlightType]: FLIGHT_TRANSPORT,
    [SEOBusType]: BUS_TRANSPORT,
    [SEOOwnType]: OWN_TRANSPORT
}

export const SearchFormFromSeoList: any = {
    [SEODates]: DATE_RANGE_FIELD,
    [SEODestination]: TO_FIELD,
    [SEODuration]: DURATION_FIELD,
    [SEOFrom]: ORIGIN_FIELD,
    [SEOParticipants]: PARTICIPANTS_FIELD,
    [SEOPrice]: PRICE_FIELD,
    [SEOCity]: XCITY_FIELD,
    [SEOHotels]: XCODE_FIELD,
    [SEOTransport]: TRANSPORT_FIELD,
    [SEOBoard]: BOARD_FIELD,
    [SEORating]: RATING_FIELD
}

export const searchFormToSeoList: any = {
    [DATE_RANGE_FIELD]: SEODates,
    [TO_FIELD]: SEODestination,
    [DURATION_FIELD]: SEODuration,
    [ORIGIN_FIELD]: SEOFrom,
    [PARTICIPANTS_FIELD]: SEOParticipants,
    [PRICE_FIELD]: SEOPrice,
    [XCITY_FIELD]: SEOCity,
    [XCODE_FIELD]: SEOHotels,
    [TRANSPORT_FIELD]: SEOTransport,
    [BOARD_FIELD]: SEOBoard,
    [RATING_FIELD]: SEORating
}