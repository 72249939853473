import {Styled} from "../../ContactPage.styles";
import {faClipboardQuestion, faMessagesQuestion} from "@fortawesome/pro-light-svg-icons";
import {useTranslation} from "react-i18next";
import useNavigation from "../../../../lib/UseHooksBookBrd/useNavigation";
import {CONTACT_OUR, CONTACT_OWN} from "../../../../lib/Routes";

const Main = () => {

    const {t} = useTranslation()

    const navigate = useNavigation()

    const onOwnClick = () => {
        navigate(CONTACT_OWN)
    }
    const onOurClick = () => {
        navigate(CONTACT_OUR)
    }

    return (
        <Styled.Contact__Page__Main__Holder>
            <Styled.Contact__Page__Main__Half__Option
                onClick={onOwnClick}
            >
                <Styled.Contact__Page__Option__Icon
                    icon={faClipboardQuestion}
                />
                <Styled.Contact__Page__Option__Title>
                    {t('lbl_faq')}
                </Styled.Contact__Page__Option__Title>
            </Styled.Contact__Page__Main__Half__Option>
            <Styled.Contact__Page__Main__Half__Option
                onClick={onOurClick}
            >
                <Styled.Contact__Page__Option__Icon
                    icon={faMessagesQuestion}
                />
                <Styled.Contact__Page__Option__Title>
                    {t('lbl_contact_platform')}
                </Styled.Contact__Page__Option__Title>
            </Styled.Contact__Page__Main__Half__Option>
        </Styled.Contact__Page__Main__Holder>
    )
}

export default Main