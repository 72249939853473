import OverViewSection from "../OverViewSection";
import {useTranslation} from "react-i18next";
import CloseAbleSection from "./CloseAbleSection";
import {isEmpty} from "lodash-es";
import {Styled} from "../CompareOffersDrawer.styles";

type DetailsSectionProps = {
    isLoadingData: boolean
    accommodationDetails: any
    transportDetails: any
    transportDetailsState: any
    accommodationDetailsState: any
}

const DetailsSection = (
    {
        isLoadingData,
        accommodationDetails,
        transportDetails,
        transportDetailsState,
        accommodationDetailsState
    }: DetailsSectionProps
) => {

    const {t} = useTranslation();

    const isEmptyAccommodation = isEmpty(accommodationDetails)


    const isEmptyTransport = isEmpty(transportDetails)

    const onAccommodationSectionClickHandler = () => {

        if (!isEmptyAccommodation) {
            if (accommodationDetailsState.boolean) {
                accommodationDetailsState.setState(false)
            } else {
                accommodationDetailsState.setState(true)
            }
        }
    }
    const onTransportSectionClickHandler = () => {
        if (!isEmptyTransport) {
            if (transportDetailsState.boolean) {
                transportDetailsState.setState(false)
            } else {
                transportDetailsState.setState(true)
            }
        }
    }


    return (
        <>
            <>
                <CloseAbleSection
                    disabledSection={isEmptyAccommodation}
                    isOpen={!!accommodationDetailsState.boolean}
                    onClick={onAccommodationSectionClickHandler}
                    sectionLabel={t('lbl_compare_accommodation_details')}
                />
                {!isLoadingData && accommodationDetailsState.boolean && (
                    <OverViewSection
                        isLoadingData={false}
                        section={accommodationDetails}
                    />
                )}
                <Styled.Separator__For__Sections
                    isOpen={!accommodationDetailsState.boolean}
                />
            </>
            <>
                <CloseAbleSection
                    disabledSection={isEmptyTransport}
                    isOpen={!!transportDetailsState.boolean}
                    isLast={true}
                    onClick={onTransportSectionClickHandler}

                    sectionLabel={t('lbl_compare_transport_details')}
                />
                {!isLoadingData && transportDetailsState.boolean && (
                    <OverViewSection
                        isLoadingData={false}
                        section={transportDetails}
                    />
                )}
            </>
        </>
    )

}
export default DetailsSection