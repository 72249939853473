import React from "react";
import {Styled} from "./Footer.styles";
import NewsSeller from "./NewsSeller";
import SocialMedia from "./SocialMedia";
import Links from "./Links";
import HoldingsInformation from "./HoldingsInformation";
import CreateAccount from "./CreateAccount";


const Footer = () => {

    return (
        <Styled.Footer>
            <CreateAccount/>
            <NewsSeller/>
            <SocialMedia/>
            <Links/>
            <HoldingsInformation/>
        </Styled.Footer>
    )

}

export default Footer