import Drawer from "../../../../../lib/UIBookBrd/Drawer";
import useSimilarOffersHandler from "../../../../../lib/UseHooksBookBrd/useSimilarOffersHandler";
import {useTranslation} from "react-i18next";
import FlexBox from "../../../../../lib/UIBookBrd/FlexBox";
import React, {forwardRef, useEffect, useRef, useState} from "react";
import useSearchFormValues from "../../../../../lib/UseHooksBookBrd/useSearchFormValues";
import useFilterFormValue from "../../../../../lib/UseHooksBookBrd/useFilterFormValue";
import useV5OfferListRequest from "../../../../../lib/UseHooksBookBrd/useV5OfferListRequest";
import {includes, isEmpty} from "lodash-es";
import PageHolder from "../../../../../lib/UIBookBrd/PageHolder";
import AccommodationDetails from "./AccommodationDetails";
import {Styled} from "./SimilarOffersDrawer.styles";
import SimilarOffer from "./SimilarOffer";
import SkeletonOffer from "./SimilarOffer/SkeletonOffer";
import useTripResultsValues from "../../useTripResultsValues";
import useBookBrdPageFunctions from "../../../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import {CellMeasurer, CellMeasurerCache, List} from "react-virtualized";
import useRefWithReset from "../../../../../lib/UseHooksBookBrd/useRefWithReset";
import {MeasuredCellParent} from "react-virtualized/dist/es/CellMeasurer";
import {isMobileOnly} from "react-device-detect";
import {WindowSizeType} from "../../../../../lib/Types";
import BrdLoader from "../../../../../lib/UIBookBrd/BrdLoader";
import useStorage from "../../../../../lib/UseHooksBookBrd/useStorage";

type SimilarOffersDrawerProps = {}

const containerStyle = {width: '100%', maxWidth: '100%'};

const style = {width: '100%', scrollBehavior: 'smooth'} as React.CSSProperties;

const getWindowType = () => {
    if (window.innerWidth > 700) {
        return WindowSizeType.small
    }
    return WindowSizeType.wide
}

const SimilarOffersDrawer = forwardRef<HTMLDivElement, SimilarOffersDrawerProps>((
    {
        ...props
    }, ref) => {

    const [favouriteOffersRawList, setFavouriteOffersRawList] = useStorage<any>('localStorage', 'rawFavouriteOffers', {trips: []})

    const [focusedElement, setFocusedElement] = useState<number | undefined>(undefined)

    const [openList, setOpenList] = useState<number[]>([])

    const [similarOfferDetails, setSimilarOffersDetails] = useState<any>({})

    const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(true)

    const [isLoadingMoreList, setIsLoadingMoreList] = useState<boolean>(false)

    const [isLoadingList, setIsLoadingList] = useState<boolean>(true)

    const [accommodationValues, setAccommodationValues] = useState<any>({})

    const [isLoadingImagesAndDescription, setIsLoadingImagesAndDescription] = useState<boolean>(true)

    const [windowType, setWindowType] = useState(getWindowType())

    const {searchFormValue} = useSearchFormValues()

    const {filterFormValue} = useFilterFormValue()

    const {
        state,
        addToCompareOffers,
        similarState,
        setSimilarState,
    } = useTripResultsValues()

    const {
        showOfferHandler
    } = useBookBrdPageFunctions()


    const {SendRequestForOfferList} = useV5OfferListRequest(searchFormValue, filterFormValue)

    const {
        onCloseSimilarOffers,
        isDisplay,
        sendRequestsForSimilarOffers,
        sendWCSRequest
    } = useSimilarOffersHandler(SendRequestForOfferList)

    const {t} = useTranslation();

    const cache = useRefWithReset<CellMeasurerCache>(() => new CellMeasurerCache({
        defaultHeight: 62,
        fixedWidth: true,
    }), [similarState?.items, isDisplay]);

    const listRef = useRef<any>(null);

    let sentListRequest = false

    let sentMoreListRequest = false

    const refreshListHeights = (index: number) => {
        cache.current.clear(index, 0);
        listRef.current?.recomputeRowHeights();
    }

    const onWidthChange = () => {
        const isSameSizeType = windowType === getWindowType()
        if (!isSameSizeType) {
            setWindowType(getWindowType())
            refreshListHeights(0)
        }
    }

    const send = () => {
        sendRequestsForSimilarOffers(
            setIsLoadingDetails,
            similarOfferDetails,
            setSimilarOffersDetails,
            setSimilarState,
            setIsLoadingList,
            similarState
        )
    }

    const onClose = () => {
        onCloseSimilarOffers()
        setOpenList([])
        setIsLoadingMoreList(false)
        setIsLoadingDetails(true)
        setIsLoadingList(true)
        setSimilarOffersDetails({})
        setAccommodationValues({})
        setSimilarState({})
    }

    const onOpen = (index: number) => {
        if (includes(openList, index)) {
            setFocusedElement(undefined)
        } else {
            setFocusedElement(index)
        }

        setOpenList((state: any) => ([
            ...includes(state, index) ? state.filter((value: number) => value !== index) : [...state, index]
        ]))
        refreshListHeights(index)
    }

    const onFavouriteChangeHandler = (offerId: string, index: number) => {
        setFavouriteOffersRawList((state: any) => ({
            ...state,
            trips: includes(favouriteOffersRawList.trips, offerId) ? favouriteOffersRawList.trips.filter((item: string) => item !== offerId) : [...favouriteOffersRawList.trips, offerId]
        }))
        refreshListHeights(index)
    }

    const renderRow = (
        {
            index,
            parent,
            style,
        }: { index: number, parent: MeasuredCellParent, style: React.CSSProperties }) => {


        const compareChangeHandler = () => {
            addToCompareOffers({
                v5Data: {
                    Operator: value.offer.Base.Operator,
                    offerId: value.offer.Base.OfferId,
                    HotelCode: `${value.offer.Accommodation.Code}`
                },
                frontData: {
                    firstImage: value.offer.Base.ThumbUrl,
                    name: value.offer.Accommodation.Name
                }
            })
        }

        const showChangeHandler = () => {
            showOfferHandler(value.offer)
        }

        const value = similarState?.items[index] ?? {}

        const isLast = index === similarState?.items?.items?.length - 1

        if (!value?.isFinished) {

            return (
                <CellMeasurer
                    key={index}
                    cache={cache.current}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                >
                    {index === 0 ? (
                        <div
                            style={style}
                        >
                            <AccommodationDetails
                                isFixed={false}
                                enableObserver={true}
                                requestReRender={() => refreshListHeights(index)}
                                isLoadingDetails={isLoadingDetails}
                                pictures={accommodationValues?.pictures}
                                description={accommodationValues?.description?.Descriptions}
                                isLoadingImagesAndDescription={isLoadingImagesAndDescription}
                                offer={similarOfferDetails?.offer}
                                offerDetails={similarOfferDetails?.offer?.offer}
                            />
                        </div>
                    ) : (
                        <div style={{
                            ...style,
                            margin: '0 5px',
                            width: 'calc(100% - 10px)',
                            marginBottom: isLast ? isMobileOnly ? '40px' : '10px' : "unset"
                        }}>
                            <SimilarOffer
                                index={index}
                                onFavouriteChangeHandler={onFavouriteChangeHandler}
                                isOpen={includes(openList, index)}
                                isFavourite={includes(favouriteOffersRawList.trips, value?.offer?.Base?.OfferId)}
                                setIsOpen={(bool: any) => onOpen(index)}
                                enableObserver={true}
                                requestReRender={() => refreshListHeights(index)}
                                compareChangeHandler={compareChangeHandler}
                                showChangeHandler={showChangeHandler}
                                value={value}
                            />
                        </div>
                    )}
                </CellMeasurer>
            )
        } else {
            return (
                <CellMeasurer
                    key={index}
                    cache={cache.current}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                >
                    <div style={{...style, marginBottom: isLast ? isMobileOnly ? '40px' : '20px' : "unset"}}>
                        <Styled.No_More_Offer_Found>
                            <Styled.No_More_Offer_Found_Title>
                                {t('lbl_no_more_offers_found_title')}
                            </Styled.No_More_Offer_Found_Title>
                            <Styled.No_More_Offer_Found_Description>
                                {t('lbl_no_more_similar_offers_found_desc')}
                            </Styled.No_More_Offer_Found_Description>
                        </Styled.No_More_Offer_Found>
                    </div>
                </CellMeasurer>
            )
        }
    }

    const onItemsRender = (object: any) => {
        const lastVisible = object.stopIndex
        if (lastVisible === similarState?.items?.length - 1 && similarState.more) {
            sentMoreListRequest = true
            SendRequestForOfferList(setIsLoadingMoreList, setSimilarState, "SimilarOffers", undefined, 'SimilarOffers', similarState).then(() => {
                sentMoreListRequest = false
            })
        }
    }


    const listContent = !isEmpty(similarState?.items) ? (
        <List
            scrollToAlignment={'center'}
            scrollToIndex={focusedElement}
            ref={listRef}
            id="similar-offer-list"
            width={1}
            isScrollingOptOut
            overscanRowCount={1}
            onRowsRendered={onItemsRender}
            height={state?.innerHeight - 51}
            deferredMeasurementCache={cache.current}
            rowHeight={cache.current.rowHeight}
            rowRenderer={renderRow}
            rowCount={similarState?.items?.length}
            containerStyle={containerStyle}
            style={style}
            data={[{isOffer: true}, ...similarState?.items ?? []]}
        />
    ) : null

    const content = isLoadingList ? (
        <Styled.Similar__List__Offer__Holder>
            {Array.from({length: 20}).map(() => (
                <SkeletonOffer/>
            ))}
        </Styled.Similar__List__Offer__Holder>
    ) : (
        <>
            {listContent}
            {isLoadingMoreList && similarState?.more && (
                <FlexBox style={{
                    position: 'absolute',
                    zIndex: '9999',
                    bottom: '0px',
                    minWidth: '100%',
                    width: '100%',
                    padding: '10px 0'
                }}>
                    <BrdLoader
                        style={{
                            margin: 'auto'
                        }}
                    />
                </FlexBox>
            )}
        </>
    )


    useEffect(() => {
        if (isDisplay && !sentListRequest) {
            send()
            sentListRequest = true
        }
    }, [isDisplay])

    useEffect(() => {
        if (!isEmpty(similarOfferDetails?.offer)) {
            setIsLoadingDetails(false)
            sendWCSRequest(
                setIsLoadingImagesAndDescription,
                similarOfferDetails?.offer.offer,
                similarOfferDetails?.offerId,
                setAccommodationValues
            )
        }
    }, [similarOfferDetails])


    useEffect(() => {
        refreshListHeights(0)
    }, [accommodationValues?.pictures])


    useEffect(() => {
        window.addEventListener('resize', onWidthChange)
        return () => {
            window.removeEventListener('resize', onWidthChange)
        }
    }, [])


    return (
        <>
            <Drawer
                showOverlay={false}
                position={"bottom"}
                isOpen={isDisplay}
                top={57}
                onClose={onClose}
                isRefForContent={true}
                ref={ref}
                contentStyles={{height: '100vh', overflow: isLoadingList ? "hidden" : ''}}
                title={t('lbl_similar_offers_title')}
            >
                <PageHolder>
                    <FlexBox style={{flexDirection: "column", padding: '0 10px'}}>
                        <AccommodationDetails
                            retryFunc={send}
                            isFixed={isLoadingDetails ? false : true}
                            isLoadingDetails={isLoadingDetails}
                            pictures={accommodationValues?.pictures}
                            description={accommodationValues?.description?.Descriptions}
                            isLoadingImagesAndDescription={isLoadingImagesAndDescription}
                            offer={similarOfferDetails?.offer}
                            offerDetails={similarOfferDetails?.offer?.offer}
                        />

                        {content}
                    </FlexBox>
                </PageHolder>
            </Drawer>
        </>
    )

})
export default SimilarOffersDrawer
