import styled from "styled-components";
import FlexBox from "../../../../lib/UIBookBrd/FlexBox";
import Image from "../../../../lib/UIBookBrd/Image";
import Text from "../../../../lib/UIBookBrd/Text";
import {rgba} from "polished";
import {isMobileOnly} from "react-device-detect";
import Button from "../../../../lib/UIBookBrd/Button";

const Regions__Holder = styled(FlexBox)`
  width: calc(100% - 20px);
  padding-top: 5px;
  margin: 0 10px;
`
const Grande_Region__Box__Holder = styled(FlexBox)`
  width: calc(50vw);
  height: calc(30vw - 10px);
  flex-shrink: 0;

  @media (min-width: 1100px) {
    width: 550px;
    height: 318px;
  }
  @media (max-width: 1000px) {
    width: calc(100vw - 20px);
    height: calc(50vw - 20px);
  }
`


const Region__Box = styled(FlexBox)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`
const Region__Image = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
`

const Region__Image__Hover__Content = styled(FlexBox)`
  position: absolute;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  background-color: transparent;
  transition: 500ms;
  top: calc(100% - 36px)

`

const Region__Image__Holder = styled(FlexBox)`
  flex-grow: 1;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  &:hover ${Region__Image__Hover__Content} {
    background-color: ${({theme}) => rgba(theme.backgroundColor, 0.65)};
    top: 0px
  }
`
const Region__Name__Label = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  margin-top: 5px;
  margin-left: 10px

`
const Region__Quick__Content__Text = styled(Text)`
  margin-top: 10px;
  font-size: 14px;
  margin-left: 10px;
  max-height: available;
  overflow: auto;
`
const Region__Actions__Holder = styled(FlexBox)`
  gap: 8px;
  padding: 5px 10px;
  margin-top: auto;
  margin-left: auto;
  width: ${isMobileOnly ? 'calc(100% - 20px)' : 'auto'};;
  flex-direction: ${isMobileOnly ? 'column' : 'initial'};
`
const Region__Action__Button = styled(Button)`
  padding: 0 4px;
  width: ${isMobileOnly ? 'calc(100% - 8px)' : 'auto'};;
`
const Wide__Screen__Content__Holder = styled(FlexBox)`
  flex-wrap: nowrap;
  width: 100%;
  gap: 10px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    display: none;
  }
`
const Non_Priority__Boxes__Holder = styled(FlexBox)`
  flex-grow: 1;
  gap: 10px;
`
const Medium__Size__Box = styled(FlexBox)`
  width: calc(25vw - 20px);
  height: calc(15vw - 10px);
  @media (min-width: 1100px) {
    width: 255px;
    height: 153px;
  }
  @media (max-width: 1000px) {
    width: calc(50vw - 15px);
    height: calc(25vw) ;
  }
`
const Small__Width__Content__Holder = styled(FlexBox)`
  flex-direction: column;
  display: none;
  flex-wrap: nowrap;
  gap: 10px;
  @media (max-width: 600px) {
    display: flex;
  }
`

export const Styled = {
    Small__Width__Content__Holder,
    Medium__Size__Box,
    Non_Priority__Boxes__Holder,
    Wide__Screen__Content__Holder,
    Region__Action__Button,
    Region__Actions__Holder,
    Region__Quick__Content__Text,
    Region__Image__Hover__Content,
    Region__Name__Label,
    Region__Image__Holder,
    Region__Image,
    Grande_Region__Box__Holder,
    Regions__Holder,
    Region__Box
}
