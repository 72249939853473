import {useTranslation} from "react-i18next";

export type WCSErrors = 'INVALID_TOKEN' | 'NO_HEALTHY_UPSTREAM' | 'UNKNOWN' | 'NO_TOKEN' | 'EXPIRED_TOKEN'

export type V5Errors = 'Network_Issue' | 'UNKNOWN' | 'Something_Went_Wrong'

const useHandleOnlineRequestError = () => {

    const {t} = useTranslation();

    const getWebContentServiceError = (error: any) => {

        const errorValue = error?.response?.data?.Error

        const isHealthy = !(error?.response?.data === 'no healthy upstream')

        if (isHealthy) {
            switch (errorValue) {
                case 'AUTHENTICATION ERROR - INVALID TOKEN':
                    return 'INVALID_TOKEN' as WCSErrors
                case 'AUTHENTICATION ERROR - TOKEN EXPIRED':
                    return 'EXPIRED_TOKEN' as WCSErrors
                default:
                    return 'UNKNOWN'
            }
        } else {
            return 'NO_HEALTHY_UPSTREAM' as WCSErrors
        }
        return "UNKNOWN" as WCSErrors
    }

    const getV5Error = (error: any): V5Errors => {
        const {message} = error
        switch (message) {
            case 'Network Error':
                return 'Network_Issue'
            case 'Request failed with status code 404':
                return 'Something_Went_Wrong'
            default:
                return 'UNKNOWN'
        }
    }


    const getDescriptionForRequests = (message: V5Errors | WCSErrors) => {
        switch (message) {
            case "Something_Went_Wrong":
                return t('lbl_please_retry_request')
            case "Network_Issue":
                return t('lbl_error_with_network_request_desc')
            default:
                return t('lbl_please_retry_request')
        }
    }
    const getTitleForRequests = (message: V5Errors | WCSErrors) => {
        switch (message) {
            case "Something_Went_Wrong":
                return t('lbl_error_with_send_request')
            case "Network_Issue":
                return t('lbl_error_with_network_request')
            default:
                return t('lbl_error_with_send_request')
        }
    }

    return (
        {
            getDescriptionForRequests,
            getTitleForRequests,
            getWebContentServiceError,
            getV5Error
        }
    )

}

export default useHandleOnlineRequestError