import {Styled} from "../Footer.styles";
import {faFacebook, faInstagram, faTiktok, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";
import useSocialMediaRedirect from "../../../lib/SocialMediaFunctionality/redirect";

const SocialMedia = () => {

    const {t} = useTranslation();

    const {
        onInstagramClickHandler,
        onTwitterClickHandler,
        onFacebookClickHandler,
        onTikTokClickHandler
    } = useSocialMediaRedirect()


    const listOfSocialMedia = [
        {
            label: 'Instagram',
            icon: faInstagram,
            onClickHandler: onInstagramClickHandler
        }, {
            label: 'Twitter',
            icon: faXTwitter,
            onClickHandler: onTwitterClickHandler
        }, {
            label: 'Facebook',
            icon: faFacebook,
            onClickHandler: onFacebookClickHandler
        }, {
            label: 'TikTok',
            icon: faTiktok,
            onClickHandler: onTikTokClickHandler
        },
    ]

    return (
        <Styled.Social__Media__Holder>
            <Styled.Social__Media__Title>
                {t('lbl_social_media_title')}
            </Styled.Social__Media__Title>
            <Styled.Social__Media__Values>
                {listOfSocialMedia?.map((item: any) => (
                    <Styled.Social__Media_Type__label
                        key={`social_media_${item.label}`}
                        onClick={item?.onClickHandler}
                    >
                        <Styled.Brand__Icon
                            icon={item?.icon}
                        />
                    </Styled.Social__Media_Type__label>

                ))}
            </Styled.Social__Media__Values>
        </Styled.Social__Media__Holder>
    )
}
export default SocialMedia