import {Styled} from "../BasicPickerForCountry.styles";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {isMobileOnly} from "react-device-detect";
import {checkBoxType} from "../../../lib/Types";

type OptionProps = {
    type: string
    hasSubOptions?: boolean
    onChange: (label: string | undefined, value: string | undefined, type: string) => void
    openChangeHandler?: (value: string | undefined) => void
    isParentOption?: boolean
    isCheckedProp: boolean
    isOpen?: boolean
    label: string
    value: string

}
const defaultProps = {
    hasSubOptions: true,
    openChangeHandler: undefined,
    isParentOption: false,
    isOpen: false
}


const Option = ({
                    hasSubOptions,
                    isCheckedProp,
                    isOpen,
                    openChangeHandler,
                    onChange,
                    isParentOption,
                    label,
                    type,
                    value,
                }: OptionProps) => {
    const setOpen = () => {
        if (openChangeHandler) {
            openChangeHandler(value)
        }
    }

    const onCheckBoxChange = (label: string, value: string) => {
        onChange(label, value, type)
    }

    return (
        <>
            <Styled.OptionHolder>
                <Styled.CheckBoxPosition
                    isMobileOnly={isMobileOnly}
                >
                    <Styled.CustomCheckBox
                        type={checkBoxType.square}
                        title={label}
                        value={value}
                        onChange={onCheckBoxChange}
                        isCheckedProp={isCheckedProp}
                    />
                </Styled.CheckBoxPosition>

                <Styled.OpenIconHolder>
                    {isParentOption && hasSubOptions && (
                        <FontAwesomeIcon
                            onClick={setOpen}
                            icon={isOpen ? faChevronUp : faChevronDown}
                            style={{
                                width: '12px',
                                height: '100%',
                                marginLeft: '2px',
                                marginBottom: '1px',

                            }}
                        />
                    )}
                </Styled.OpenIconHolder>

            </Styled.OptionHolder>
        </>
    )
}
Option.defaultProps = defaultProps;
export default Option