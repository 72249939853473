import {useEffect, useRef, useState} from "react";
import {Styled} from "../SimilarOffersDrawer.styles";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Tooltip from "../../../../../../lib/UIBookBrd/Tooltip";
import Pricing from "../../TripOfferList/TripOffer/OfferTopLayer/TopLayerDescription/Pricing";
import Button from "../../../../../../lib/UIBookBrd/Button";
import {GET_TRANSPORT_COMPONENTS, GET_TRANSPORT_TYPE} from "../../../TripSearchFunctions";
import {useTranslation} from "react-i18next";
import {faClose} from "@fortawesome/pro-solid-svg-icons/faClose";
import TransportDescription from "../../TripOfferList/TripOffer/OfferBottomLayer/TransportDescription";
import CustomText from "../../TripOfferList/TripOffer/OfferTopLayer/TopLayerDescription/CustomText";
import useBookBrdPageFunctions from "../../../../../../lib/UseHooksBookBrd/useBookBrdPageFunctions";
import useElementResize from "../../../../../../lib/UseHooksBookBrd/useElementResize";
import {faHeart} from "@fortawesome/pro-solid-svg-icons";
import {faHeart as lightHeart} from "@fortawesome/pro-light-svg-icons";

type SimilarOfferProps = {
    index?: number
    isFavourite?: boolean
    isExlusive?: boolean
    onFavouriteChangeHandler?: (value: string, index: number) => void
    enableObserver?: boolean
    requestReRender?: () => void
    value: any
    isOpen: boolean
    setIsOpen: (bool: boolean) => void
    compareChangeHandler: () => void
    showChangeHandler: () => void
}

const SimilarOffer = (
    {
        index,
        isExlusive,
        isFavourite,
        onFavouriteChangeHandler,
        isOpen,
        setIsOpen,
        enableObserver,
        requestReRender,
        compareChangeHandler,
        showChangeHandler,
        value
    }: SimilarOfferProps
) => {

    const type = GET_TRANSPORT_TYPE(value?.offer?.Transport)

    const {onTourOperatorClick} = useBookBrdPageFunctions()

    const values = GET_TRANSPORT_COMPONENTS(value?.offer?.Transport, value?.offer?.Base, type)

    const containerRef = useRef<any>(null)

    const [elementHeight, setElementHeight] = useState(containerRef?.current?.clientHeight ?? 0)

    const {heightObserver} = useElementResize()

    const {t} = useTranslation();

    useEffect(() => {
        if (enableObserver) {
            heightObserver(containerRef, elementHeight, setElementHeight, requestReRender)
        }
    }, [elementHeight]);

    return (
        <Styled.Similar__Offer
            onClick={() => {
                if (!isOpen) {
                    setIsOpen(true)
                }
            }}
        >
            <FlexBox>
                <Tooltip
                    content={values.toolTip as any}
                >
                    <Styled.Transport__Label>
                        {values.value as any}
                    </Styled.Transport__Label>
                </Tooltip>
                <Styled.Heart__Icon
                    isOpen={isOpen}
                    onClick={(event: any) => {
                        event.stopPropagation();
                        if (onFavouriteChangeHandler) {
                            onFavouriteChangeHandler(value?.offer?.Base?.OfferId as string ?? '', index as number)
                        }
                    }}
                    isExlusive={!!isExlusive}
                    isFavourite={!!isFavourite}
                    icon={!!isFavourite ? faHeart : lightHeart}
                />
                {isOpen && (
                    <Styled.Close__Offer__Icon
                        icon={faClose}
                        onClick={() => {
                            setIsOpen(false)
                        }}
                    />
                )}
            </FlexBox>
            {/*REST OF THE CONTENT*/}

            <CustomText
                type={t('lbl_standard')}
                value={`${value?.offer?.Accommodation?.Room?.Name} • ${value?.offer?.Accommodation?.XService?.Name}`}
            />
            <CustomText
                onValueClick={(event: any) => {
                    event.stopPropagation()
                    onTourOperatorClick({name: value?.offer?.Base?.OperatorDesc, code: value?.offer?.Base?.Operator})
                }}
                type={t('lbl_operator')}
                value={`${value?.offer?.Base?.OperatorDesc}`}
            />
            <FlexBox
            >
                <Pricing
                    pricing={value.offer.Base.Price}
                />
            </FlexBox>
            {isOpen && (
                <FlexBox
                    style={{
                        marginTop: '10px',
                        alignContent: "flex-end",
                        alignItems: 'flex-end',
                        gap: '5px'
                    }}
                >
                    <TransportDescription
                        transport={value?.offer?.Transport}
                    />
                    <FlexBox style={{marginLeft: "auto", gap: '5px'}}>
                        <Button
                            style={{
                                minWidth: "125px"
                            }}
                            label={t('lbl_compare_offer')}
                            onClick={compareChangeHandler}
                        />
                        <Button
                            style={{
                                minWidth: "125px"
                            }}
                            label={t('lbl_see_offer_button')}
                            onClick={showChangeHandler}
                        />
                    </FlexBox>
                </FlexBox>
            )}
        </Styled.Similar__Offer>
    )
}
export default SimilarOffer