import styled from "styled-components";
import FlexBox from "../../../lib/UIBookBrd/FlexBox";

const Alert__Positioner__Value__Holder = styled(FlexBox)<{ getValidTopValue: (window: any) => number }>`
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: hidden;
  gap: 5px 0;
  max-width: 400px;
  max-height: calc(100vh - ${({getValidTopValue}) => getValidTopValue(window)}px);
  position: fixed;
  z-index: 9000;
  top: ${({getValidTopValue}) => getValidTopValue(window)}px;
  left: 10px;
`


export const Styled = {
    Alert__Positioner__Value__Holder
}