import {includes, isEmpty} from "lodash-es";
import {FIRST_STEP_TRIP_SEARCH, getLangForLink, SECOND_STEP_TRIP_SEARCH, THIRD_STEP_TRIP_SEARCH} from "./Routes";
import {Available_Languages} from "./PageConfig";
import usePagesConfigs from "./PagesConfigs";
import useNavigation from "./UseHooksBookBrd/useNavigation";
import {MANDATORY__TRIP__DETAILS__FIELDS} from "./Consts";
import {FieldsForOfferDetailsParams, linkTypes} from "./Types";
import {getParticipantsFromHash} from "./UseHooksBookBrd/useBookBrdPageFunctions";

const isPageWithTripSearch = (currentLocation: string) => {
    return includes([FIRST_STEP_TRIP_SEARCH, SECOND_STEP_TRIP_SEARCH], currentLocation)
}

const isPageWithFlightSearch = (currentLocation: string) => {
    return false
}


export {
    isPageWithFlightSearch,
    isPageWithTripSearch
}

const useRoutesHandler = () => {

    const {routesList} = usePagesConfigs()

    const navigate = useNavigation()

    const validateLanguageInLink = () => {
        const languageInLink = window.location.pathname.split('/')[1]
        const validLanguage = getLangForLink()
        if (!includes(Available_Languages, languageInLink)) {
            return false
        }
        return languageInLink === validLanguage
    }

    const createParamsMap = (valuesArray: any[]) => {
        const mapFields: any = {}
        valuesArray.map((item: string) => {
            const [key, value] = item?.split('=')
            mapFields[key] = decodeURIComponent(value).split('_').join(' ')
        })
        return mapFields
    }

    const validationForFieldsInThirdStep = (valuesArray: any[]) => {
        const valuesMap = createParamsMap(valuesArray)
        return MANDATORY__TRIP__DETAILS__FIELDS.filter((field) => isEmpty(valuesMap[field]) || valuesMap[field] === 'not-specified');
    }

    const createLinkPathForOfferDetails = (path: string, type: linkTypes = linkTypes.pathname, offer: any = {}, hotelOnly: boolean = false) => {
        const origin = window.location.origin
        // VALUE FROM OFFER
        if (!isEmpty(offer)) {
            const fieldsForFullOffer: any = {
                [FieldsForOfferDetailsParams.hotel]: offer?.Base?.XCode?.Name?.split(' ')?.join('_'),
                [FieldsForOfferDetailsParams.from]: offer?.Base?.StartDate,
                [FieldsForOfferDetailsParams.to]: offer?.Base?.ReturnDate,
                [FieldsForOfferDetailsParams.board]: offer?.Accommodation?.XService?.Name,
                [FieldsForOfferDetailsParams.room]: offer?.Accommodation?.Room?.Name,
                [FieldsForOfferDetailsParams.participants]: getParticipantsFromHash(offer?.Base?.OfferId)?.adultsCount,
                [FieldsForOfferDetailsParams.departure]: offer?.Base?.DepartureLocation?.Name,
                [FieldsForOfferDetailsParams.offerId]: offer?.Base?.OfferId
            }
            const fieldsForHotelOnly: any = {
                [FieldsForOfferDetailsParams.hotel]: offer?.Base?.XCode?.Name?.split(' ')?.join('_'),
                [FieldsForOfferDetailsParams.offerId]: offer?.Base?.OfferId
            }

            if (type === linkTypes.link) {
                if (!hotelOnly) {
                    return origin + THIRD_STEP_TRIP_SEARCH + MANDATORY__TRIP__DETAILS__FIELDS.map((field: FieldsForOfferDetailsParams) => `/${field}=${fieldsForFullOffer[field]}`)?.join('')
                } else {
                    return origin + THIRD_STEP_TRIP_SEARCH + MANDATORY__TRIP__DETAILS__FIELDS.map((field: FieldsForOfferDetailsParams) => `/${field}=${fieldsForHotelOnly[field] ?? 'not-specified'}`)?.join('')
                }
            }

            return THIRD_STEP_TRIP_SEARCH + MANDATORY__TRIP__DETAILS__FIELDS.map((field: FieldsForOfferDetailsParams) => `/${field}=${fieldsForFullOffer[field]}`)?.join('')
        } else {
            const offerParams = path.replace(`${THIRD_STEP_TRIP_SEARCH}/`, '').split('/')
            const values = createParamsMap(offerParams)
            let newPath = THIRD_STEP_TRIP_SEARCH
            MANDATORY__TRIP__DETAILS__FIELDS.map((field: string) => {
                const value = values[field]
                newPath += `/${field}=${value?.split(' ')?.join('_') ?? 'not-specified'}`
            })
            if (type === linkTypes.link) {
                return `${origin}${newPath}`
            }
            return newPath
        }

    }

    const routingForThirdTripStep = (route: string) => {
        const offerParams = route.replace(`${THIRD_STEP_TRIP_SEARCH}/`, '').split('/')
        console.log({offerParams})
        const invalidFields = validationForFieldsInThirdStep(offerParams)
        console.log({invalidFields})
        const newPathNme = createLinkPathForOfferDetails(route, linkTypes.pathname)
        if (includes(invalidFields, FieldsForOfferDetailsParams.hotel)) {
            navigate(FIRST_STEP_TRIP_SEARCH)
        }

        if (newPathNme !== route) {
            navigate(newPathNme)
        }


        return true
    }

    const validateRoute = (route: string) => {
        if (route.includes(THIRD_STEP_TRIP_SEARCH)) {
            return isEmpty(validationForFieldsInThirdStep(route.replace(`${THIRD_STEP_TRIP_SEARCH}/`, '').split('/')))
        }
        const routes = routesList.map((route: any) => route.pathName)

        return includes(routes, route)
    }

    const routerController = () => {
        // navigate from default
        if (window.location.pathname === '/') {
            navigate(FIRST_STEP_TRIP_SEARCH)
            return
        }
        // validate language


        if (!validateLanguageInLink()) {
            const languageInLink = window.location.pathname.split('/')[1]
            const validLanguage = getLangForLink()
            if (validateRoute(window.location.pathname.replace(languageInLink, validLanguage))) {
                navigate(window.location.pathname.replace(languageInLink, validLanguage))
            } else if (window.location.pathname.includes(THIRD_STEP_TRIP_SEARCH)) {
                routingForThirdTripStep(window.location.pathname.replace(languageInLink, validLanguage))
            } else {
                navigate(FIRST_STEP_TRIP_SEARCH)
            }
            return
        }

        if (window.location.pathname.includes(THIRD_STEP_TRIP_SEARCH)) {
            routingForThirdTripStep(window.location.pathname)
        }
    }

    return ({
        createParamsMap,
        createLinkPathForOfferDetails,
        routerController
    })
}
export default useRoutesHandler