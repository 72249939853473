import {css} from "styled-components";


const inputStyles = css`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: ${({theme}) => theme.color};
    -webkit-box-shadow: 0 0 0px 1000px ${({theme}) => theme.backgroundColor} inset;
    transition: background-color 5000s ease-in-out 0s;
  }`
export default inputStyles;