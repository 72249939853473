import styled from "styled-components";
import FlexBox from "../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../lib/UIBookBrd/Text";

const Offer__Details__Holder = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
`
const Section__Data__Holder = styled(FlexBox)`
  width: calc(100% - 20px);
  padding: 0 10px;
  flex-direction: column;
`
const Additioonal__Information__Label = styled(Text)`
  font-weight: bold;
  font-size: 15px;
  padding: 10px 0 10px 0

`
const Part__Title__Label = styled(Text)`
  font-size: 18px;
  padding: 15px 0;
  font-weight: bold;
`


export const Styled = {
    Section__Data__Holder,
    Offer__Details__Holder,
    Additioonal__Information__Label,
    Part__Title__Label
}