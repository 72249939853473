import FlexBox from "../../../../../../../../lib/UIBookBrd/FlexBox";
import Text from "../../../../../../../../lib/UIBookBrd/Text";
import {getLuggageInfo, getTransferInfo} from "../../../../../TripSearchFunctions";
import {useTranslation} from "react-i18next";

type AdditionalValueProps = {
    offerType: string
    offer: any
}

const AdditionalValue = (
    {
        offerType,
        offer
    }: AdditionalValueProps
) => {

    const {t} = useTranslation();

    const {Base, Transport} = offer

    switch (offerType) {
        case 'Flight':
            return (
                <FlexBox style={{flexDirection: "column"}}>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            padding: '10px 0 10px 0'
                        }}
                    >
                        {t('lbl_flight_additional_data')}:
                    </Text>
                    <FlexBox style={{flexDirection: 'column', gap: '4px'}}>
                        <Text>
                            {t('lbl_airline_type')}
                            {':'}
                            <span
                                style={{
                                    fontWeight: 'bold'
                                }}
                            >
                    {` ${offer?.Transport[offerType]?.AirlineType}`}
                        </span>
                        </Text>
                        <Text>
                            {t('lbl_luggage_registered')}
                            {':'}
                            <span
                                style={{
                                    fontWeight: 'bold'
                                }}
                            >
                    {` ${getLuggageInfo(Transport[offerType]?.Luggage, t)}`}
                        </span>
                        </Text>
                        {offerType === 'Flight' && (
                            <Text>
                                {t('lbl_transfer')}
                                {':'}
                                <span
                                    style={{
                                        fontWeight: 'bold'
                                    }}
                                >
                                          {` ${getTransferInfo(Base?.Transfer, t)}`}
                                         </span>
                            </Text>
                        )}
                    </FlexBox>
                </FlexBox>
            )
        default :
            return (
                <>

                </>
            )
    }

}
export default AdditionalValue