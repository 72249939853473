import {Local_Data} from "./Context";
import useBookBrdComponentStates from "../UseHooksBookBrd/useBookBrdComponentStates";

type LocalDataProviderProps = {
    children: React.ReactNode
}

const LocalDataProvider = (
    {
        children
    }: LocalDataProviderProps
) => {

    const {
        localDataArray
    } = useBookBrdComponentStates()


    return (
        <Local_Data.Provider value={localDataArray as any}>
            {children}
        </Local_Data.Provider>


    )

}

export default LocalDataProvider
