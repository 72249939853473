import {Similar_Offers} from "../../../../lib/Context/Context";
import {useState} from "react";


type SimilarOfferContextProps = {
    children: React.ReactElement
}

const SimilarOfferContext = (
    {
        children
    }: SimilarOfferContextProps
) => {

    const [state, setState] = useState<any>({})

    return (
        <Similar_Offers.Provider value={[state, setState]}>
            {children}
        </Similar_Offers.Provider>
    )
}
export default SimilarOfferContext