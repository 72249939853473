import {Default_Search_Form_Values} from "../DefaultFormikValue/DefaultSearchFormValues";
import {deCode} from "../UseHooksBookBrd/useHashForm";
import {isEmpty} from "lodash-es";
import {isPageWithTripSearch} from "../RoutesHandler";


const isDefaultLoading = () => {
    if (isPageWithTripSearch(window.location.pathname)) {
        return true
    }
    return false
}

const getDefaultProto = () => {
    const oldSameValue = deCode(document.location.search.replace('?&search=', '') ?? '', document.location.pathname)
    if (!isEmpty(oldSameValue)) {
        return oldSameValue
    }
    return {
        SearchFormValues: Default_Search_Form_Values,
        FilterFormValues: {},
        compareOffersValue: []
    }
}


export const Proto_Hash_Default_Values = {
    SearchFormValues: Default_Search_Form_Values,
    FilterFormValues: {},
    compareOffersValue: []
} ?? getDefaultProto()


export const Local_Data_Default_Values = {
    isLoading: isDefaultLoading(),
    isOnline: true,
    userData: {
        isLogged: true,
        image: 'https://www.illumination.com/wp-content/uploads/2020/02/YoungGru.png',
        backGroundImage: 'https://static.independent.co.uk/s3fs-public/thumbnails/image/2021/06/02/20/istock-833264986.jpg',
        deleteBackground: true,
        backgroundColor: '#9d1abe',
        name: 'Mks Brd'
    }
}