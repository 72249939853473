import {Customer_Support} from "../../../lib/Context/Context";
import React, {useState} from "react";

type ContextProviderProps = {
    children: React.ReactElement
}

const ContextProvider = (
    {
        children
    }: ContextProviderProps
) => {

    const defaultValue: any = {
        mailGuide: {
            isLoading: true,
            data: {
                pointsList: [],
                mailStructure: {}
            },
            requestSent: false
        }
    }

    const [state, setState] = useState<any>(defaultValue)

    return (
        <Customer_Support.Provider value={[state, setState]}>
            {children}
        </Customer_Support.Provider>
    )
}
export default ContextProvider