import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import {PROJECT_ID} from "../../Configs/RequestConfig";
import {APIKEY} from "../../Configs/gglApiKey";

type MapProps = {
    zoom?: number
    viewType?: 'satellite' | 'roadmap' | 'hybrid' | 'terrain'
    styles?: React.CSSProperties,
    locations: google.maps.LatLng[] | google.maps.LatLngLiteral[];
}
const defaultProps = {
    viewType: 'satellite',
    styles: {},
    zoom: 17
}

const Map = (
    {
        viewType,
        styles,
        zoom,
        locations
    }: MapProps
) => {

    const {isLoaded} = useJsApiLoader({
        id: PROJECT_ID,
        googleMapsApiKey: APIKEY
    })

    const options = {
        fullscreenControl: false,
        mapTypeId: viewType
    };

    return isLoaded ? (
        <GoogleMap
            options={{
                ...options
            }}
            mapContainerStyle={styles}
            center={locations[0] ?? {lng: 0, lat: 0}}
            zoom={zoom}
        >
            {locations.map((option) => (
                <Marker
                    position={option}
                />
            ))}

        </GoogleMap>
    ) : null
}
Map.defaultProps = defaultProps;
export default Map