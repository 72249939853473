import {Styled} from "./Settings.styles";
import Tooltip from "../../lib/UIBookBrd/Tooltip";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {isMobile} from "react-device-detect";

type SettingsSectionProps = {
    isToolTip?: boolean
    toolTipContent?: string | React.ReactNode
    sectionLabel: string
    sectionIcon?: any
    children: React.ReactNode
}
const defaultProps = {
    toolTipContent: undefined,
    isToolTip: false
}

const SettingsSection = (
    {
        isToolTip,
        sectionLabel,
        sectionIcon,
        toolTipContent,
        children
    }: SettingsSectionProps
) => {

    const content = (
        <>
            <Styled.Settings__Type__Label
                isToolTip={isToolTip}
            >
                {sectionIcon && (
                    <Styled.Setting__Icon
                        icon={sectionIcon as any}
                    />
                )}
                <span>
                {sectionLabel}
                    {':'}
                    </span>
            </Styled.Settings__Type__Label>
            {!isMobile && isToolTip && (
                <Styled.InformationIcon
                    icon={faInfoCircle}
                />
            )}
        </>
    )

    return (
        <Styled.Settings__Section__Holder>
            <Tooltip
                disabled={!isToolTip || isMobile}
                content={toolTipContent as any}
            >
                {!isMobile ? (
                    <Styled.Content__With__ToolTip>
                        {content}
                    </Styled.Content__With__ToolTip>
                ) : (
                    <Styled.Content__Without__ToolTip>
                        {content}
                        <Styled.ToolTip__Value__Label>
                            {toolTipContent}
                        </Styled.ToolTip__Value__Label>
                    </Styled.Content__Without__ToolTip>
                )}
            </Tooltip>
            <Styled.Children__Holder>
                {children}
            </Styled.Children__Holder>
        </Styled.Settings__Section__Holder>
    )

}
SettingsSection.defaultProps = defaultProps;

export default SettingsSection