import {createGlobalStyle} from 'styled-components';
import DatePickerStyles from './DatePicker';
import Tooltip from "./ToolTipStyles";
import inputStyles from "./input";

const GlobalStyle = createGlobalStyle`
  h1 {
    padding: 0;
    color: ${({theme}) => theme.color};
  }

  p {
    margin: 0;
    font-weight: normal;
    font-size: inherit;
  }

  span {
    font-size: inherit;
  }

  strong {
    font-weight: normal;
    font-size: inherit;
  }

  ul {
    gap: 4px 0;
    color: inherit;
  }

  a {
    color: inherit;
  }

  li {
    margin: 2px 0;
    color: inherit;
  }

  body {
    background-color: ${(props: { theme: any; }) => props.theme.backgroundColor};
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  ${inputStyles}
  ${DatePickerStyles}
  ${Tooltip}
`;

export default GlobalStyle;
